import React, { useState } from 'react';

interface Props {
  url: string
  classes?: string
}
const CustomImage = ({ url, classes }: Props) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleImageLoad = (event: any) => {
    const { naturalWidth, naturalHeight } = event.target;
    setWidth(naturalWidth);
    setHeight(naturalHeight);
  };
  return (
    <img
      // src={`${url}`}
      src={`${url}?w=480`}
      onLoad={handleImageLoad}
      alt=""
      loading="lazy"
      className={`w-full h-full ${width === 0 ? 'opacity-0' : 'opacity-[1]'} ${width > height ? 'object-cover' : 'object-contain'} ${classes || ''}`}
    />
  );
};

export default CustomImage;