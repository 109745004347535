import { getListingByChannelId, getListingDetailByChannelId, importAirbnb } from '../../api/integration'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setToast } from '../../redux/slices/common'
import { setImportRequestId } from '../../redux/slices/listing'
import { getNameByCountryCode, handleErrorMessage } from '../../utils/common'
import LoadingSection from '../common/LoadingSection'
import LoadingThreeDots from '../common/LoadingThreeDots'
import BasicButton from '../ui/BasicButton'
import BasicCheckbox from '../ui/BasicCheckbox'
import BasicDialog from '../ui/BasicDialog'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
  isOpen: boolean
  onClose: () => void
  afterSubmit: () => void
}

const AirbnbChooseListingChannex: React.FC<Props> = ({ isOpen, onClose }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { airbnbChannelId } = useAppSelector((state) => state.listing)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [listings, setListings] = useState<Array<any>>([])
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false)
  const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false)
  const [isLoadingSubmmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [listingDetails, setListingDetails] = useState<any>({})
  const [isEmpty, setIsEmpty] = useState<boolean>(false)

  useEffect(() => {
    async function fetchListingDetail(channelId: string, ids: string[]) {
      try {
        setIsLoadingDetail(true)
        const res = await Promise.all(ids.map((id) => getListingDetailByChannelId(channelId, id)))
        const details: any = {}
        res.forEach((item) => {
          const data = item.data
          details[data.id] = data
        })
        setListingDetails(details)
      } catch (err: any) {
        console.log(err)
      } finally {
        setIsLoadingDetail(false)
      }
    }
    async function fetchListingAirbnb(channelId: string) {
      try {
        setIsLoadingList(true)
        const res = await getListingByChannelId(channelId)
        setIsLoadingList(false)
        if (Array.isArray(res.data) && res.data.length > 0) {
          setListings(res.data)
          setIsEmpty(false)
          const ids = res.data.map((item: any) => item.id)
          await fetchListingDetail(channelId, ids)
        } else {
          setListings([])
          setIsEmpty(true)
        }
      } catch (err: any) {
        handleErrorMessage(err)
        setIsLoadingList(false)
      }
    }

    if (isOpen && airbnbChannelId) {
      fetchListingAirbnb(airbnbChannelId)
    }
  }, [isOpen, airbnbChannelId])

  const handleClose = () => {
    // clear data before close popup
    setSelectedIds([])
    setListingDetails({})
    setIsEmpty(false)
    onClose()
  }

  const onClickListingItem = (listingId: string) => {
    setSelectedIds((prevState) => {
      if (prevState.includes(listingId)) {
        return prevState.filter((id) => id !== listingId)
      } else {
        return [...prevState, listingId]
      }
    })
  }

  const onSubmit = async () => {
    try {
      setIsLoadingSubmit(true)
      const res = await importAirbnb({ selectedIds })
      onClose() // close popup
      dispatch(setImportRequestId(res.data?.requestId))
      dispatch(
        setToast({
          show: true,
          message: 'Your listings are being imported. If any issues occur, please contact us at support@dtravel.com.',
          type: 'loading',
        })
      )
      // afterSubmit()
      navigate('/listings')
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const renderThumbImage = (listingData: any) => {
    if (listingData) {
      const images = listingData.images
      if (Array.isArray(images) && images.length > 0) {
        return (
          <img
            src={images[0].thumbnail_url}
            alt={images[0].caption}
            className={'object-cover w-[32px] h-[32px] rounded-[8px]'}
          />
        )
      }
      return ''
    }
    return ''
  }

  const renderAddress = (listingData: any) => {
    if (listingData) {
      const { state, country_code } = listingData
      const countryName = getNameByCountryCode(country_code)
      return [state, countryName || country_code].join(', ')
    }
    return ''
  }

  const isHasScroll = listings.length > 7

  return (
    <BasicDialog isOpen={isOpen} onClose={handleClose} title={'Choose listing to add to Dtravel'}>
      <div
        className={clsx('relative flex flex-col border border-grayscale-300 rounded-[16px] overflow-y-auto', {
          'h-[512px]': isHasScroll && !isLoadingList,
          'h-auto': !isHasScroll && !isLoadingList,
          'h-[100px]': isLoadingList || (Array.isArray(listings) && listings.length === 0),
        })}
      >
        <LoadingSection isLoading={isLoadingList} size={30} />

        {isEmpty && (
          <div className={'font-maison-neue text-14-20 text-neutral-600 p-[40px] text-center'}>
            No property found in your Airbnb account
          </div>
        )}

        {Array.isArray(listings) &&
          listings.map((item, index) => {
            const isSelected = Array.isArray(selectedIds) && selectedIds.includes(item.id)
            const isLastItem = index === listings.length - 1
            return (
              <button
                key={item.id}
                className={clsx('flex gap-[12px] items-center p-[16px]  border-[#00000026]', {
                  'bg-neutral-100': isSelected,
                  'border-b-[0.5px]': !isLastItem,
                })}
                onClick={() => onClickListingItem(item.id)}
              >
                <BasicCheckbox color={'black'} checked={isSelected} onClick={() => onClickListingItem(item.id)} />
                <div className={'w-[32px] h-[32px] rounded-[8px] ml-[12px]'}>
                  {isLoadingDetail ? <LoadingThreeDots color={'#292926'} /> : renderThumbImage(listingDetails[item.id])}
                </div>
                <div className={'text-left'}>
                  <p className={'font-maison-neue-medium text-14-18 text-neutral-800'}>{item.title}</p>
                  <p className={'font-maison-neue text-14-18 text-neutral-600'}>
                    {isLoadingDetail ? <LoadingThreeDots color={'#6A6A68'} /> : renderAddress(listingDetails[item.id])}
                  </p>
                </div>
              </button>
            )
          })}
      </div>

      <div className={'mt-[24px]'}>
        <BasicButton
          variant={'contained'}
          color={'red'}
          size={'xl'}
          isRadius100={true}
          clases={'w-full'}
          disabled={selectedIds.length === 0}
          onClick={onSubmit}
          loading={isLoadingSubmmit}
        >
          {isLoadingSubmmit ? 'Importing' : 'Import'}
        </BasicButton>
      </div>
    </BasicDialog>
  )
}

export default AirbnbChooseListingChannex
