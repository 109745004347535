import common from './common'
import listing from './listing'
import user from './user'
import { combineReducers } from 'redux'

const rootReducer: any = combineReducers({
  common,
  user,
  listing,
})

export default rootReducer
