import { useAppSelector } from '../../redux/hooks'
import { checkIsNewHost } from '../../utils/user'
import LayoutV2 from '../layout/LayoutV2'
import DetailActivities from '../property/DetailActivities'
import HomeReservations from './HomeReservations'
import LearnMoreDtravelSmartWallet from './LearnMoreDtravelSmartWallet'
import Performance from './Performance'
import RewardsProgramBanner from './RewardsProgramBanner'
import StripeWarningBanner from './StripeWarningBanner'
import React from 'react'

interface Props {}

const Home: React.FC<Props> = () => {
  const { profile } = useAppSelector((state) => state.user)
  const isNewUser = checkIsNewHost(profile?.user)

  return (
    <LayoutV2 showDefaultHeader>
      <StripeWarningBanner position={'home'} />
      <RewardsProgramBanner />

      {isNewUser ? <LearnMoreDtravelSmartWallet /> : <></>}

      <div className={`${isNewUser ? 'mt-[40px]' : ''}`}>
        <Performance />
      </div>

      <div className={'mt-[40px] flex flex-col md:flex-row justify-between items-start gap-[40px] '}>
        <div className={'w-full md:w-1/2'}>
          <HomeReservations />
        </div>
        <div className={'w-full md:w-1/2'}>
          <DetailActivities />
        </div>
      </div>
    </LayoutV2>
  )

  // return (
  //   <LayoutV2 showDefaultHeader>
  //     <div className={'w-full mt-[40px]'}>
  //       <div className={'mb-[40px]'}>
  //         <p className={'font-maison-neue text-28-36 text-neutral-800 tracking-[-0.28px]'}>Welcome home</p>
  //       </div>
  //       <div className="flex flex-col xl:flex-row w-full gap-[40px]">
  //         <div className="w-full xl:w-2/3 xl:pr-[16px] flex flex-col">
  //           <Performance />
  //           <HomeReservations />
  //           <DetailActivities />
  //         </div>
  //         <div className="w-full xl:w-1/3">
  //           <Assets />
  //         </div>
  //       </div>
  //     </div>
  //   </LayoutV2>
  // )
}

export default Home
