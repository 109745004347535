import { updateHostSettings } from '../../../api/user'
import { ONBOARDING_STEP } from '../../../contants/user'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { handleNextOnboardingStep, updateOnboardingData } from '../../../redux/slices/user'
import { handleErrorMessage } from '../../../utils/common'
import BasicButton from '../../ui/BasicButton'
import BasicRadio from '../../ui/BasicRadio'
import LayoutOnboarding from '../LayoutOnboarding'
import RightContentPmsCm from './RightContentPmsCm'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

const OPTIONS = [
  {
    value: 'manual',
    title: 'I want to create listings from scratch',
    desc: 'Create and manage directly on Dtravel; sync with other OTAs via iCal',
  },
  {
    value: 'airbnb_hospitable',
    title: 'I want to import listings from Airbnb',
    desc: "Import and sync listings from your Airbnb account, whether or not it's currently connected to PMS software.",
  },
  {
    value: 'dtravel_cm',
    title: 'I want to import listings from my PMS',
    desc: 'Import and sync listings from one of our supported PMS or Channel Manager partners',
  },
  {
    value: 'unsure',
    title: 'I need some advice',
    desc: "We can help if you're unsure about which option is best for you",
  },
]

const OnboardingListingSource = () => {
  const dispatch = useAppDispatch()
  const [value, setValue] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { onboardingData } = useAppSelector((state) => state.user)

  useEffect(() => {
    if (onboardingData.listingSource) {
      setValue(onboardingData.listingSource)
    }
  }, [onboardingData?.listingSource])

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      let nextStep = ''
      if (value === 'manual') {
        nextStep = ONBOARDING_STEP.NATIVE_LISTING
      } else if (value === 'airbnb_hospitable') {
        nextStep = ONBOARDING_STEP.AIRBNB_IMPORT
      } else if (value === 'dtravel_cm') {
        nextStep = ONBOARDING_STEP.PMS_SELECT
      } else if (value === 'unsure') {
        nextStep = ONBOARDING_STEP.NEED_SUPPORT
      }
      // update typeform survey
      await updateHostSettings({ listingSource: value })
      dispatch(updateOnboardingData({ listingSource: value })) // save data to state
      dispatch(handleNextOnboardingStep(nextStep))
    } catch (err) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const renderSubmitBtn = () => {
    return (
      <BasicButton
        variant={'contained'}
        isRadius100={true}
        color={'red'}
        size={'xl'}
        onClick={onSubmit}
        disabled={!value}
        loading={isLoading}
      >
        Continue
      </BasicButton>
    )
  }

  return (
    <LayoutOnboarding headerTitle={'Listings'} rightContent={<RightContentPmsCm />}>
      <div className={'mt-[38px] lg:mt-[70px] w-full mx-auto md:max-w-[524px] pb-[112px] lg:pb-0'}>
        <h2 className={'font-maison-neue text-24-36 text-neutral-800 mb-[4px]'}>
          Great, let&apos;s create your listings
        </h2>
        <p className={'font-maison-neue text-16-24 text-neutral-800 mb-[16px]'}>How would you like to manage them?</p>

        <div className={'flex flex-col gap-[16px] mt-[16px]'}>
          {OPTIONS.map((item) => {
            return (
              <label
                key={item.value}
                className={clsx(
                  'flex justify-between items-center gap-[16px] rounded-[16px] cursor-pointer bg-white p-[24px]',
                  'border border-neutral-300',
                  {
                    'border-[2px] border-neutral-800': value === item.value,
                  }
                )}
                htmlFor={`pms_usage_${item.value}`}
              >
                <div className={'flex flex-col gap-[4px]'}>
                  <span className={'font-maison-neue text-16-24 text-neutral-800'}>{item.title}</span>
                  <span className={'font-maison-neue text-14-20 text-neutral-500'}>{item.desc}</span>
                </div>
                <BasicRadio
                  id={`pms_usage_${item.value}`}
                  type={'radio'}
                  classes={'w-[24px] h-[24px] cursor-pointer'}
                  name={'pms_usage'}
                  value={item.value}
                  checked={value === item.value}
                  onChange={(event) => setValue(event.target.value)}
                />
              </label>
            )
          })}
        </div>

        <div className={'mt-[24px] hidden lg:block'}>{renderSubmitBtn()}</div>
      </div>

      <div
        className={clsx(
          'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
          'border-t border-neutral-300 px-4 md:px-8',
          'shadow-footer'
        )}
      >
        {renderSubmitBtn()}
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingListingSource
