import { FormError } from '../../interfaces'
import Error from '../common/Error'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import Tooltip from '@mui/material/Tooltip'
import clsx from 'clsx'
import React, { HTMLInputTypeAttribute, InputHTMLAttributes, forwardRef, useEffect, useState, useRef } from 'react'
import ic_checked from 'src/assets/icons/ic_check_big.svg'
import ic_copy from 'src/assets/icons/ic_copy_2.svg'
import ic_edit_pencil from 'src/assets/icons/ic_edit_pencil_01.svg'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  isRequired?: boolean
  tooltip?: string
  classes?: string
  showIconPassword?: boolean
  showPasswordFirstTime?: boolean
  error?: FormError
  endAdornment?: any
  placeholderClasses?: string
  showIconCopy?: boolean
  onCopy?: () => void
  showCount?: boolean
  showIconEdit?: boolean
}

const BasicInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      isRequired,
      tooltip,
      classes,
      showIconCopy,
      onCopy,
      showIconPassword,
      showPasswordFirstTime,
      type,
      error,
      endAdornment,
      placeholderClasses,
      showCount,
      showIconEdit,
      ...props
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [isShowPassword, setIsShowPassword] = useState<boolean>(false)
    const [htmlType, setHtmlType] = useState<HTMLInputTypeAttribute | undefined>('text')
    const [isEdit, setIsEdit] = useState<boolean>(!showIconEdit)
    const [readOnly, setReadOnly] = useState<boolean>(!!props.readOnly)

    useEffect(() => {
      if (showPasswordFirstTime) {
        setHtmlType('text')
      } else {
        setHtmlType(type)
      }
      setIsShowPassword(!!showPasswordFirstTime)
    }, [type, showPasswordFirstTime])

    useEffect(() => {
      setReadOnly(props.readOnly || (!props.readOnly && !!showIconEdit))
    }, [showIconEdit, props.readOnly])

    const onTogglePasswod = (isShow: boolean) => {
      setIsShowPassword(isShow)
      setHtmlType(isShow ? 'text' : 'password')
    }

    const onToggleEdit = () => {
      setIsEdit((prevState) => {
        setReadOnly(prevState)
        if (inputRef && inputRef.current) {
          inputRef.current.focus()
        }
        return !prevState
      })
    }

    return (
      <div className={'w-full'}>
        <label className="inputLabel block relative w-full">
          {label && (
            <p className={'block font-maison-neue-medium text-14-18 text-neutral-800 mb-[8px]'}>
              {label}
              &nbsp;
              {isRequired && <span className={'text-red'}>*</span>}
              &nbsp;
              {tooltip && (
                <Tooltip placement="top" title={tooltip} sx={{ fontSize: 16 }} arrow>
                  <HelpOutlineIcon />
                </Tooltip>
              )}
            </p>
          )}
          <input
            ref={ref || inputRef}
            className={clsx(
              'w-full h-[48px] text-16-24 px-[16px] py-3 rounded-[12px] text-neutral-800',
              `placeholder:${placeholderClasses ? placeholderClasses : 'text-neutral-500'}`,
              'shadow-xs',
              'bg-white',
              'focus:outline-none',
              'disabled:cursor-not-allowed disabled:text-neutral-500',
              'disabled:bg-neutral-50',
              {
                ['bg-white']: !readOnly,
                ['bg-neutral-50']: readOnly,
              },
              classes,
              {
                'border border-red-700 hover:border-red-700 focus:border-red-700': error?.show,
                'border border-neutral-300 hover:text-neutral-900 hover:border-neutral-800 focus:border-neutral-800':
                  !error?.show,
                'pr-[42px]': (type === 'password' && showIconPassword && !showIconCopy) || showIconEdit || showIconCopy,
                'pr-[82px]': type === 'password' && showIconPassword && showIconCopy,
              }
            )}
            type={htmlType}
            {...props}
            readOnly={readOnly}
          />
          {type === 'password' && showIconPassword && (
            <button
              className={`absolute ${showIconCopy ? 'right-[48px]' : 'right-[12px]'} bottom-[0px] -translate-y-1/2`}
              onClick={() => onTogglePasswod(!isShowPassword)}
            >
              {isShowPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
            </button>
          )}
          {type !== 'password' && showIconEdit && (
            <button
              className={`${isEdit ? 'ic_checked' : 'ic_edit'
                } btnEditable absolute right-[12px] bottom-[4px] -translate-y-1/2`}
              onClick={() => onToggleEdit()}
            >
              <img
                src={isEdit ? ic_checked : ic_edit_pencil}
                alt={'ic_edit_checked'}
                style={{ width: '20px', height: '20px' }}
              />
            </button>
          )}
          {showIconCopy && (
            <button className={'absolute right-[12px] bottom-[4px] -translate-y-1/2'} onClick={onCopy}>
              <img src={ic_copy} alt={'ic_copy'} style={{ width: '20px', height: '20px' }} />
            </button>
          )}
          {endAdornment}
        </label>

        {error && <Error {...error} />}

        {showCount && props.maxLength && (
          <p className={'font-maison-neue text-14-18 text-neutral-500 mt-[8px]'}>
            {props.value ? String(props.value).length : 0}/{props.maxLength} characters
          </p>
        )}
      </div>
    )
  }
)

export default BasicInput
