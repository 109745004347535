import NativeListingBackAndContinue from './NativeListingBackAndContinue'
import NativeListingLocationConfirm from './NativeListingLocationConfirm'
import Popover from '@mui/material/Popover'
import axios from 'axios'
import clsx from 'clsx'
import queryString from 'query-string'
import React, { useEffect, useState, useRef } from 'react'
import Map, { MapSourceDataEvent } from 'react-map-gl'
import { useLocation, useNavigate } from 'react-router-dom'
import { getSuggestedMapbox } from 'src/api/native-listing'
import ic_location from 'src/assets/icons/ic_location.svg'
import { NATIVE_LISTING_MENU } from 'src/contants/common'
import { PROPERTY_STATUS } from 'src/contants/native'
import { isEmpty } from 'src/utils/common'
import { v4 as uuidv4 } from 'uuid'

interface Props {
  listingData: any
  fetchStatusStep: any
}
// search api https://docs.mapbox.com/api/search/search-box/
let cancelToken: any
let anchorWidth: number = 0
const NativeListingLocation = ({ listingData, fetchStatusStep }: Props) => {
  const mapRef: any = useRef(null)
  const navigate = useNavigate()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const propertyId = parseQuery.id ? String(parseQuery.id) : null
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const isEdit = !isEmpty(propertyId)
  const isDraft = listingData?.status === PROPERTY_STATUS.DRAFT || isEmpty(parseQuery?.id)
  const mapboxAccessToken =
    process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ||
    'pk.eyJ1IjoiZHRyYXZlbCIsImEiOiJjbGN0enZjM2YwenRhM3ZudzQwOHBqNGRwIn0.aAG5VZns5U5dt7eciHSOew'
  const [isConfirm, setConfirm] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  const [suggestions, setSuggestions] = useState<any[]>([])
  const [suggestionSelected, setSuggestionSelected] = useState<any>({})
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleOpen = (e: any) => {
    let anchor = e.currentTarget
    setAnchorEl(anchor)
    const rect = anchor.getBoundingClientRect()
    anchorWidth = rect?.width
  }
  const handleClose = () => {
    setAnchorEl(null)
    anchorWidth = 0
  }
  const fetchData = async () => {
    try {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('request canceled')
      }
      cancelToken = axios.CancelToken.source()
      const newUuid = uuidv4()
      const res: any = await getSuggestedMapbox(
        {
          q: searchText,
          access_token: mapboxAccessToken,
          session_token: newUuid,
          // types: ['country', 'city', 'district', 'place', 'street', 'address'].join(',')
        },
        { cancelToken: cancelToken.token }
      )
      if (res?.suggestions) setSuggestions(res?.suggestions)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (!isEmpty(searchText.trim())) fetchData()
    else setSuggestions([])
  }, [searchText])
  useEffect(() => {
    if (!isEmpty(propertyId) || isEditBulk) setConfirm(true)
  }, [propertyId])
  const handleFitbounds = () => {
    ;(mapRef as any)?.current?.fitBounds(
      [
        [-180, 0],
        [180, 0],
      ],
      {
        padding: { top: 0, bottom: 0, left: 0, right: 0 },
        duration: 1000,
        maxZoom: 10,
      }
    )
  }
  const renderSearchBox = () => {
    return (
      <>
        <div
          className={clsx(
            'absolute top-4 left-4 w-[calc(100%_-_32px)] z-10 h-[48px] flex text-16-24 px-[16px] py-3 rounded-[12px] text-neutral-900',
            `placeholder:text-neutral-6 bg-white`,
            // 'border border-[0.5px] border-[#00000026] hover:border-neutral-900',
            // 'focus:border-[1px] focus:border-neutral-900 focus:outline-none',
            'shadow-basic gap-2'
          )}
          role="presentation"
          onClick={handleOpen}
        >
          <img src={ic_location} alt="" style={{ width: 24, height: 24 }} />
          <input
            className="w-full focus:outline-none"
            placeholder="Enter the property address..."
            value={searchText}
            onChange={(e: any) => {
              setSearchText(e?.target.value)
            }}
          />
        </div>
      </>
    )
  }
  if (isConfirm) {
    return (
      <NativeListingLocationConfirm
        suggestionSelected={suggestionSelected}
        listingData={listingData}
        fetchStatusStep={fetchStatusStep}
        handleBack={() => {
          if (!isEdit) setConfirm(false)
          else navigate(`/native-listing/${NATIVE_LISTING_MENU.PROPERTY}` + search)
        }}
      />
    )
  }
  const open = Boolean(anchorEl)
  return (
    <>
      <div className="flex flex-col">
        <p className="text-28-36 text-neutral-800 font-maison-neue tracking-[-0.01em] mb-4">
          {isEditBulk ? 'Where are your properties located?' : 'Where is the property located?'}
        </p>
        <div className="w-full h-[520px] relative rounded-[16px] border border-neutral-300 shadow[0px_1px_2px_rgba(0,0,0,0.05)] mapCustomContainer">
          {renderSearchBox()}
          <Map
            ref={mapRef}
            mapboxAccessToken={mapboxAccessToken}
            initialViewState={{
              zoom: 12,
            }}
            reuseMaps
            onSourceData={(propsSourceData: MapSourceDataEvent) => {
              if (propsSourceData?.isSourceLoaded) handleFitbounds()
            }}
            style={{ width: '100%', height: '100%', borderRadius: 16 }}
            mapStyle="mapbox://styles/mapbox/light-v10"
          ></Map>
        </div>
      </div>
      <NativeListingBackAndContinue
        isEdit={!isDraft}
        onSubmit={() => setConfirm(true)}
        onBack={() => navigate(`/native-listing/${NATIVE_LISTING_MENU.PROPERTY}` + search)}
      />
      <Popover
        id={'native-location'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        disableAutoFocus
        disableEnforceFocus
        sx={{
          '& .MuiPaper-root': {
            width: anchorWidth,
            height: 'auto',
            boxShadow: '0px 8px 16px -4px rgba(0, 0, 0, 0.06), 0px 4px 6px -2px rgba(0, 0, 0, 0.04)',
            borderRadius: '16px',
            backgroundColor: '#FFFFFF',
            padding: '0px',
            marginTop: '8px',
          },
        }}
      >
        <div className="flex flex-col text-16-24 py-3 text-neutral-900 gap-3 max-h-[290px] overflow-auto">
          {!isEmpty(suggestions) &&
            suggestions.map((el: any, idx: number) => {
              return (
                <div
                  className="flex items-start gap-2 border-b border-b-grayscale-200 w-full pb-3 px-4 cursor-pointer"
                  key={idx}
                  role="presentation"
                  onClick={() => {
                    setConfirm(true)
                    setSuggestionSelected(el)
                    handleClose()
                  }}
                >
                  <img src={ic_location} alt="" style={{ width: 24, height: 24 }} />
                  <div className="flex flex-col">
                    <p className="text-16-20 text-neutral-900 font-maison-neue">{el?.name || el?.place_formatted}</p>
                    <p className="text-14-18 text-neutral-600 font-maison-neue">
                      {[
                        el?.context?.place?.name,
                        el?.context?.locality?.name,
                        el?.context?.region?.name,
                        el?.context?.country?.name,
                      ]
                        .filter((v) => v)
                        .join(', ')}
                    </p>
                  </div>
                </div>
              )
            })}
          <div
            className="flex items-start w-full px-4 cursor-pointer"
            role="presentation"
            onClick={() => {
              setConfirm(true)
              handleClose()
            }}
          >
            <div className="flex flex-col ml-8">
              <p className="text-14-18 text-neutral-900 font-maison-neue-medium">Enter address manually...</p>
            </div>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default NativeListingLocation
