import { CustomFeeAndDiscount } from '../interfaces/reservations'

export const getCustomFeeAppliedTax = (customFee: CustomFeeAndDiscount[], taxeName: string) => {
  let result: CustomFeeAndDiscount | null = null
  if (Array.isArray(customFee) && customFee.length > 0) {
    for (let i = 0; i < customFee.length; i++) {
      const { configFee, isPercent } = customFee[i]
      if (!isPercent && configFee) {
        const flatFeeApplyOnTaxes = configFee.flatFeeApplyOnTaxes
        if (flatFeeApplyOnTaxes.includes(taxeName)) {
          result = customFee[i]
          break
        }
      }
    }
  }
  return result
}

export const renderNumberOfGuest = (adults: number, children: number, infants: number, pets: number) => {
  const result = [
    `${adults > 0 ? `${adults} adult${adults > 1 ? 's' : ''}` : ''}`,
    `${children > 0 ? `${children} ${children > 1 ? 'children' : 'child'}` : ''}`,
    `${infants > 0 ? `${infants} infant${infants > 1 ? 's' : ''}` : ''}`,
    `${pets > 0 ? `${pets} pet${pets > 1 ? 's' : ''}` : ''}`,
  ]
  return result.filter((v) => v !== '').join(', ')
}
