import BasicDialog from '../ui/BasicDialog'
import React from 'react'
import ic_circle_warning from 'src/assets/icons/ic_circle_warning.svg'
import ic_mail_open from 'src/assets/icons/ic_mail_open.svg'

interface Props {
  open: boolean
  handleClose?: any
  email: string
}

const ConfirmEmail = (props: Props) => {
  const { open, email } = props
  const renderContent = () => {
    return (
      <div className={'flex flex-col'}>
        <p className="text-16-24 font-maison-neue text-neutral-800 text-center px-8">
          We emailed an authentication link to
        </p>
        <p className="text-16-24 font-maison-neue text-neutral-800 text-center px-8">{email}.</p>
        <p className="text-16-24 font-maison-neue text-neutral-800 text-center px-0 md:px-4">
          Tap the button in that email to log in or sign up.
        </p>
        <div className="flex bg-red-1 p-4 rounded-xl mt-6">
          <img src={ic_circle_warning} alt="" style={{ width: 24, height: 24 }} />
          <div className="pl-2">
            <p className="text-14-20 font-maison-neue-medium text-red-6 mb-[2px]">Don’t close this tab!</p>
            <p className="text-12-16 font-maison-neue text-neutral-7">
              You will be automatically logged in after clicking the authentication link.
            </p>
          </div>
        </div>
      </div>
    )
  }
  const renderIconTitle = () => {
    return (
      <div className="w-full flex items-center justify-center mb-4">
        <img src={ic_mail_open} alt="" style={{ width: 48, height: 48 }} />
      </div>
    )
  }
  return (
    <>
      <BasicDialog
        isOpen={open}
        onClose={() => {}}
        title={'Confirm your Email'}
        titleAlign={'center'}
        hideClose
        iconTitle={renderIconTitle()}
      >
        {renderContent()}
      </BasicDialog>
    </>
  )
}

export default ConfirmEmail
