import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setToast } from '../../redux/slices/common'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import * as React from 'react'
import { isMobile } from 'react-device-detect'

const BasicToast = () => {
  const dispatch = useAppDispatch()
  const { toast } = useAppSelector((state) => state.common)
  const { show, message, title, type = 'error', duration = 15000 } = toast

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(setToast({ show: false, message: '' }))
  }

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )

  return (
    <Snackbar
      open={show}
      autoHideDuration={duration}
      onClose={handleClose}
      message={message}
      action={action}
      anchorOrigin={{ vertical: isMobile ? 'top' : 'bottom', horizontal: 'right' }}
      sx={{ '& .MuiSnackbar-anchorOriginBottomRight': { borderRadius: '16px' } }}
    >
      <div
        className={'bg-white rounded-[16px] flex p-[16px] min-w-[280px] max-w-[768px] w-auto items-center gap-[12px]'}
        style={{ boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.12)' }}
      >
        <div className={'w-[24px]'}>
          {show && (
            <IconButton>
              {type === 'loading' ? (
                <CircularProgress size={20} />
              ) : type === 'error' ? (
                <ErrorIcon color={'error'} />
              ) : (
                <CheckCircleRoundedIcon color={'success'} />
              )}
            </IconButton>
          )}
        </div>
        <div className={'grow'}>
          <p className={'text-neutral-700 text-12-16'}>{title}</p>
          <p className={'text-neutral-600 text-12-16'}>
            {Array.isArray(message) && typeof message[0] === 'string'
              ? message.map((mess, index) => <p key={index}>{mess}</p>)
              : typeof message === 'string'
              ? message
              : 'Something went wrong!'}
          </p>
        </div>
        <button className={'w-[24px]'} onClick={handleClose}>
          <CloseIcon />
        </button>
      </div>
    </Snackbar>
  )
}

export default BasicToast
