import { IcBuilding04, IcChervonDown, IconChecked } from '../common/Icons'
import Popover from '@mui/material/Popover'
import clsx from 'clsx'
import React, { useState } from 'react'

interface Props {
  listingId: string
  listingName: string
  images: any[]
  mergeType?: string
  onSelectType: (listingId: string, mergeType: 'add_new' | 'merge') => void
  disabled?: boolean
}

const PopoverSelectMergeListing: React.FC<Props> = ({
  listingId,
  listingName,
  images,
  mergeType,
  onSelectType,
  disabled,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'mergeListingPopover' : undefined

  const renderThumbImage = (images: any) => {
    if (Array.isArray(images) && images.length > 0) {
      return (
        <img
          src={images[0]?.thumbnail_url || images[0]?.url || images[0]}
          alt={images[0].caption}
          className={'object-cover w-full h-full rounded-[8px]'}
        />
      )
    }
    return ''
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <button
        className={clsx(
          'border-[0.5px] border-black border-opacity-[0.15] h-[48px]',
          'rounded-[12px] p-[8px]',
          'flex items-center justify-start gap-[12px]',
          'w-full text-left'
        )}
        onClick={(event) => setAnchorEl(event.currentTarget as HTMLButtonElement)}
        disabled={disabled}
      >
        {mergeType === 'add_new' ? (
          <>
            <span
              className={'w-[32px] min-w-[32px] h-[32px] rounded-[8px] bg-neutral-200 flex items-center justify-center'}
            >
              <IcBuilding04 />
            </span>
            <span className={'font-maison-neue text-14-18 text-neutral-800'}>Add as a new listing</span>
            <span className={'ml-auto w-[24px] h-[24px] min-w-[24px] flex items-center justify-center'}>
              <IcChervonDown color={'#8C8C88'} width={12} height={12} />
            </span>
          </>
        ) : (
          <>
            <span className={'w-[32px] min-w-[32px] h-[32px] rounded-[8px]'}>{renderThumbImage(images)}</span>
            <span className={'text-left'}>
              <p className={'font-maison-neue text-14-18 text-neutral-800 line-clamp-1'}>{listingName}</p>
            </span>
            <span className={'ml-auto w-[24px] h-[24px] min-w-[24px] flex items-center justify-center'}>
              <IcChervonDown color={'#8C8C88'} width={12} height={12} />
            </span>
          </>
        )}
      </button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            width: '282px',
            height: 'auto',
            boxShadow: '0px 20px 24px -4px rgba(0, 0, 0, 0.06), 0px 8px 8px -4px rgba(0, 0, 0, 0.03)',
            borderRadius: '12px',
            backgroundColor: '#FFFFFF',
            padding: '8px',
            border: 'solid 0.5px rgba(0, 0, 0, 0.15)',
          },
        }}
      >
        <div className={'flex flex-col'}>
          <button
            className={'flex items-center gap-[12px] p-[12px]'}
            onClick={() => {
              onSelectType(listingId, 'merge')
              handleClose()
            }}
          >
            <span className={'w-[32px] min-w-[32px] h-[32px] rounded-[8px]'}>{renderThumbImage(images)}</span>
            <span className={'text-left'}>
              <p className={'font-maison-neue text-14-18 text-neutral-800 line-clamp-1'}>{listingName}</p>
            </span>
            <span
              className={clsx('ml-auto min-w-[20px]', {
                'opacity-0': mergeType !== 'merge',
              })}
            >
              <IconChecked color={'#292926'} />
            </span>
          </button>

          <button
            className={'flex items-center gap-[12px] p-[12px]'}
            onClick={() => {
              onSelectType(listingId, 'add_new')
              handleClose()
            }}
          >
            <span className={'w-[32px] h-[32px] rounded-[8px] bg-neutral-200 flex items-center justify-center'}>
              <IcBuilding04 />
            </span>
            <span className={'font-maison-neue text-14-18 text-neutral-800'}>Add as a new listing</span>
            <span
              className={clsx('ml-auto min-w-[20px] flex items-center justify-center', {
                'opacity-0': mergeType !== 'add_new',
              })}
            >
              <IconChecked color={'#292926'} width={20} height={20} />
            </span>
          </button>
        </div>
      </Popover>
    </>
  )
}

export default PopoverSelectMergeListing
