import React from 'react'

interface Props {
  color?: string
}

const LoadingThreeDots: React.FC<Props> = ({ color = '#FFFFFF' }) => {
  return (
    <div className={'flex gap-[4px] items-center h-full'}>
      <div
        className="p-[2px] w-[1px] h-[1px] rounded-full animate-bounce"
        style={{
          animationDelay: '100ms',
          backgroundColor: color,
        }}
      />
      <div
        className="p-[2px] w-[1px] h-[1px] rounded-full animate-bounce"
        style={{
          animationDelay: '300ms',
          backgroundColor: color,
        }}
      />
      <div
        className="p-[2px] w-[1px] h-[1px] rounded-full animate-bounce"
        style={{
          animationDelay: '500ms',
          backgroundColor: color,
        }}
      />
    </div>
  )
}

export default LoadingThreeDots
