import { checkStatusHospitable, connectHospitable, getChannexConnectionLink } from '../../api/integration'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setIsOpenPopupInfoAirbnb, setAirbnbChannelId } from '../../redux/slices/listing'
import { handleErrorMessage } from '../../utils/common'
import BasicButton from '../ui/BasicButton'
import BasicDialog from '../ui/BasicDialog'
import React, { useState, useEffect, useRef } from 'react'

const NOTES = [
  "Dtravel uses Hospitable Connect to import and sync your Airbnb account, which works even if you're connected to other channel management software.",
  'You can only import active listings from Airbnb.',
  'Co-hosting is not yet supported. If you are a co-host, you will need the listing owner to connect.',
  'Dtravel will automatically sync your rates and availability from Airbnb.',
]

// eslint-disable-next-line
const NOTES_CHANNEX = [
  "Dtravel uses Channex Connect to import and sync your Airbnb account, which works even if you're connected to other channel management software.",
  'You can only import active listings from Airbnb.',
  'Co-hosting is not yet supported. If you are a co-host, you will need the listing owner to connect.',
  'Dtravel will automatically sync your rates and availability from Airbnb.',
]

interface Props {
  afterSubmit?: () => void
  isHasAirbnbViaChannex?: boolean
}

const AirbnbImportInfo: React.FC<Props> = ({ afterSubmit }) => {
  const dispatch = useAppDispatch()
  const intervalRef = useRef<any>()
  const intervalCount = useRef<number>(0)
  const { isOpenPopupInfoAirbnb } = useAppSelector((state) => state.listing)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // eslint-disable-next-line
  const [url, setUrl] = useState<string>('')

  // eslint-disable-next-line
  async function fetchConnectionLinkChannex(openPopupChooseListing?: boolean, intervalId?: number) {
    try {
      setIsLoading(true)
      const res = await getChannexConnectionLink()
      if ((intervalId || intervalRef.current) && intervalCount.current >= 100) {
        clearInterval(intervalRef.current) // clear interval if isConnected
        clearInterval(intervalId)
        setIsLoading(false)
        intervalCount.current = 0
      }
      if (res.success && res.data) {
        if (!openPopupChooseListing) {
          setIsLoading(false)
        }
        if (res.data.isConnected && res.data.channelId) {
          dispatch(setAirbnbChannelId(res.data.channelId))
          setUrl('')
          clearInterval(intervalRef.current) // clear interval if isConnected
          clearInterval(intervalId)
          if (openPopupChooseListing) {
            setIsLoading(false)
            // dispatch(setIsOpenPopupChooseListingAirbnb(true))
            if (typeof afterSubmit === 'function') {
              afterSubmit()
            }
            onClose() // close current popup
          }
        } else {
          dispatch(setAirbnbChannelId(''))
          // setUrl(res.data.returnUrl)
          setUrl(res.data.url)
        }
      }
    } catch (err: any) {
      handleErrorMessage(err)
      setIsLoading(false)
    }
  }

  async function fetchConnectionLinkHospitable(openPopupChooseListing?: boolean, intervalId?: number) {
    try {
      setIsLoading(true)
      const resConnect = await connectHospitable()
      if (resConnect.data && resConnect.success) {
        const connectionId = resConnect.data.id
        const resCheckStatus = await checkStatusHospitable(
          connectionId,
          `/handle-connect-hospitable?from=listings&connectionId=${connectionId}`
        )
        if (!openPopupChooseListing) {
          setIsLoading(false)
        }
        setUrl(resCheckStatus.data.returnUrl)
        if ((intervalId || intervalRef.current) && intervalCount.current >= 100) {
          clearInterval(intervalRef.current) // clear interval if isConnected
          clearInterval(intervalId)
          setIsLoading(false)
          intervalCount.current = 0
        }
      }
    } catch (err: any) {
      handleErrorMessage(err)
      setIsLoading(false)
    }
  }

  // useEffect(() => {
  //   if (isOpenPopupInfoAirbnb) {
  //     isHasAirbnbViaChannex ? fetchConnectionLinkChannex() : fetchConnectionLinkHospitable()
  //   }
  // }, [isOpenPopupInfoAirbnb, isHasAirbnbViaChannex])

  useEffect(() => {
    if (isOpenPopupInfoAirbnb) {
      fetchConnectionLinkHospitable()
    }
  }, [isOpenPopupInfoAirbnb])

  useEffect(() => {
    return () => {
      clearAllIntervals()
    }
  }, [])

  const clearAllIntervals = () => {
    for (let i = 1; i < 99999; i++) {
      window.clearInterval(i)
    }
  }

  const onClose = () => {
    dispatch(setIsOpenPopupInfoAirbnb(false))
    clearAllIntervals()
  }

  const onOk = async () => {
    if (url) {
      // if not Connected: create interval to subcrible channelId
      intervalCount.current = 0
      const oldIntervalId = intervalRef.current
      intervalRef.current = setInterval(() => {
        fetchConnectionLinkHospitable(true, oldIntervalId)
        intervalCount.current++
      }, 3000)
      // open airbnb link to new tab
      window.location.href = url
    }
    const resConnect = await connectHospitable()
    if (resConnect.data && resConnect.success) {
      const connectId = resConnect.data.id
      const resCheckStatus = await checkStatusHospitable(connectId, '/handle-connect-hospitable?from=listings')
      window.location.href = resCheckStatus.data.returnUrl
    }
  }

  // const notes = isHasAirbnbViaChannex ? NOTES_CHANNEX : NOTES

  return (
    <BasicDialog
      isOpen={isOpenPopupInfoAirbnb}
      onClose={onClose}
      title={'Import your Airbnb listings'}
      extraTitle={'Easily import your property details as they appear on Airbnb'}
    >
      <div className={'font-maison-neue text-14-20 text-neutral-800'}>
        <p className={'pb-[4px]'}>Note:</p>
        <ul className={'list-disc pl-[24px]'}>
          {NOTES.map((item, index) => (
            <li className={'pb-[4px]'} key={index}>
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className={'mt-[24px]'}>
        <BasicButton
          variant={'contained'}
          size={'xl'}
          color={'red'}
          clases={'w-full'}
          isRadius100={true}
          loading={isLoading}
          onClick={onOk}
        >
          OK
        </BasicButton>
      </div>
    </BasicDialog>
  )
}

export default AirbnbImportInfo
