import { getPmsIntegrated } from '../../api/integration'
import { setIsSurveySubmitted } from '../../redux/slices/common'
import LayoutV2 from '../layout/LayoutV2'
import OnboardingDistributionChannels from './distribution/OnboardingDistributionChannels'
import OnboardingCreateAccount from './identity/OnboardingCreateAccount'
import OnboardingInterest from './identity/OnboardingInterest'
import OnboardingPersona from './identity/OnboardingPersona'
import OnboardingCustomizeSite from './landing-page/OnboardingCustomizeSite'
import OnboardingLandingPage from './landing-page/OnboardingLandingPage'
import OnboardingNativeListing from './native-listing/OnboardingNativeListing'
import OnboardingNeedSupport from './need-support/OnboardingNeedSupport'
import OnboardingAirbnbChooseListingChannex from './pms-cm/OnboardingAirbnbChooseListingChannex'
import OnboardingAirbnbChooseListingHospitable from './pms-cm/OnboardingAirbnbChooseListingHospitable'
import OnboardingAirbnbConnectError from './pms-cm/OnboardingAirbnbConnectError'
import OnboardingAirbnbConnectSuccess from './pms-cm/OnboardingAirbnbConnectSuccess'
import OnboardingAirbnbEditPrompt from './pms-cm/OnboardingAirbnbEditPrompt'
import OnboardingAirbnbImport from './pms-cm/OnboardingAirbnbImport'
import OnboardingListingSource from './pms-cm/OnboardingListingSource'
import OnboardingNotSupported from './pms-cm/OnboardingNotSupported'
import OnboardingPmsConnectSuccess from './pms-cm/OnboardingPmsConnectSuccess'
import OnboardingPmsSelect from './pms-cm/OnboardingPmsSelect'
import OnboardingSiteDetailsContactContainer from './site-details/OnboardingSiteDetailsContactContainer'
import OnboardingSiteDetailsCurrenciesContainer from './site-details/OnboardingSiteDetailsCurrenciesContainer'
import OnboardingSiteDetailsFeesContainer from './site-details/OnboardingSiteDetailsFeesContainer'
import OnboardingSiteDetailsPaymentMethodsContainer from './site-details/OnboardingSiteDetailsPaymentMethodsContainer'
import OnboardingSiteDetailsSiteNameContainer from './site-details/OnboardingSiteDetailsSiteNameContainer'
import React, { useEffect, useState } from 'react'
import { getHostSettings, getUserProfile, updateUserProfile } from 'src/api/user'
import { ONBOARDING_STEP } from 'src/contants/user'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setOnboardingStep, setOnboardingData, setProfile, setSettingData } from 'src/redux/slices/user'
import { getUserInfo, saveUserInfo } from 'src/utils/user'

const Onboarding = () => {
  const dispatch = useAppDispatch()
  const { onboardingStep } = useAppSelector((state) => state.user)
  const [step, setStep] = useState<string>('')
  const [airbnbEditListings, setAirbnbEditListings] = useState<any[]>([])
  const [listIdImporting, setListIdImporting] = useState<string[]>([])
  const [isHasAirbnbViaChannex, setIsHasAirbnbViaChannex] = useState<boolean>(false)

  useEffect(() => {
    fetchPmsIntegrated()
  }, [])

  async function fetchPmsIntegrated() {
    try {
      const res = await getPmsIntegrated()
      if (res.data) {
        const airbnbViaChannex = res.data.airbnbViaChannex
        setIsHasAirbnbViaChannex(!!(airbnbViaChannex && airbnbViaChannex.airbnbChannelId))
      }
    } catch (err: any) {
      setIsHasAirbnbViaChannex(false)
    }
  }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await getUserProfile()
        const resSetting = await getHostSettings()
        const user = getUserInfo()
        const profile = { ...user, ...res.data }
        saveUserInfo(profile)
        dispatch(setProfile(profile))
        dispatch(setSettingData(resSetting.data || {}))
        if (profile && profile.gettingStarted && profile.gettingStarted.stepOnboarding) {
          dispatch(setOnboardingStep(profile.gettingStarted.stepOnboarding))
        }

        // onboarding data
        let onboardingData = {
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile?.user?.email,
          walletId: profile?.user?.walletId || '',
          persona: resSetting.data.persona,
          inventory: resSetting.data.inventory,
          usedPms: resSetting.data.usedPms,
          listingSource: resSetting.data.listingSource || '',
          pmsUsing: resSetting.data.pmsUsing,
        }
        dispatch(setOnboardingData(onboardingData))
      } catch (err) {
        console.log(err)
      }
    }
    fetchUserData()
  }, [])

  useEffect(() => {
    const arrSteps = onboardingStep.split(', ')
    const length = arrSteps.length
    setStep(arrSteps[length - 1])
  }, [onboardingStep])

  const isStepInValid = (_step: string) => {
    const listStep = []
    for (let key in ONBOARDING_STEP) {
      listStep.push(key)
    }
    return !listStep.includes(_step)
  }

  const handleFinishOnboarding = async () => {
    const user: any = getUserInfo()
    let dataProfileDTO: any = { gettingStarted: { ...user?.gettingStarted, isSurveySubmitted: true } }
    await updateUserProfile(dataProfileDTO)
    const res: any = await getUserProfile()
    saveUserInfo({ ...user, ...res.data })
    dispatch(setIsSurveySubmitted(true))
  }

  const updateEditListings = (newListings: any[]) => {
    setAirbnbEditListings(newListings)
  }

  const updateListIdImporting = (newIds: string[]) => {
    setListIdImporting(newIds)
  }

  return (
    <LayoutV2 showDefaultHeader isOnboarding>
      <>
        {(step === ONBOARDING_STEP.CREATE_ACCOUNT || isStepInValid(step)) && <OnboardingCreateAccount />}
        {step === ONBOARDING_STEP.PERSONA && <OnboardingPersona />}
        {step === ONBOARDING_STEP.INTEREST && <OnboardingInterest />}
        {step === ONBOARDING_STEP.PMS_SELECT && <OnboardingPmsSelect />}
        {step === ONBOARDING_STEP.LISTING_SOURCE && <OnboardingListingSource />}
        {step === ONBOARDING_STEP.AIRBNB_IMPORT && (
          <OnboardingAirbnbImport isHasAirbnbViaChannex={isHasAirbnbViaChannex} />
        )}
        {step === ONBOARDING_STEP.AIRBNB_CHOOSE_LISTINGS && (
          <>
            {isHasAirbnbViaChannex ? (
              <OnboardingAirbnbChooseListingChannex />
            ) : (
              <OnboardingAirbnbChooseListingHospitable
                editListings={airbnbEditListings}
                updateEditListings={updateEditListings}
                updateListIdImporting={updateListIdImporting}
              />
            )}
          </>
        )}
        {step === ONBOARDING_STEP.AIRBNB_EDIT_PROMPT && (
          <OnboardingAirbnbEditPrompt
            editListings={airbnbEditListings}
            updateEditListings={updateEditListings}
            listIdImporting={listIdImporting}
            updateListIdImporting={updateListIdImporting}
          />
        )}
        {step === ONBOARDING_STEP.AIRBNB_CONNECT_SUCCESS && <OnboardingAirbnbConnectSuccess />}
        {step === ONBOARDING_STEP.AIRBNB_CONNECT_ERROR && <OnboardingAirbnbConnectError />}
        {step === ONBOARDING_STEP.NATIVE_LISTING && <OnboardingNativeListing />}
        {step === ONBOARDING_STEP.PMS_NOT_SUPPORTED && <OnboardingNotSupported />}
        {step === ONBOARDING_STEP.PMS_CONNECT_SUCCESS && <OnboardingPmsConnectSuccess />}
        {step === ONBOARDING_STEP.NEED_SUPPORT && <OnboardingNeedSupport />}
        {step === ONBOARDING_STEP.LANDING_PAGE && <OnboardingLandingPage />}
        {step === ONBOARDING_STEP.CUSTOMIZE_SITE && <OnboardingCustomizeSite />}
        {step === ONBOARDING_STEP.SITE_DETAILS_SITE_NAME && <OnboardingSiteDetailsSiteNameContainer />}
        {step === ONBOARDING_STEP.SITE_DETAILS_CONTACT_INFO && <OnboardingSiteDetailsContactContainer />}
        {step === ONBOARDING_STEP.SITE_DETAILS_PAYMENT_METHODS && <OnboardingSiteDetailsPaymentMethodsContainer />}
        {step === ONBOARDING_STEP.SITE_DETAILS_FEES && <OnboardingSiteDetailsFeesContainer />}
        {step === ONBOARDING_STEP.SITE_DETAILS_CURRENCIES && <OnboardingSiteDetailsCurrenciesContainer />}
        {step === ONBOARDING_STEP.DISTRIBUTION_CHANNELS && (
          <OnboardingDistributionChannels handleFinish={handleFinishOnboarding} />
        )}
      </>
    </LayoutV2>
  )
}

export default Onboarding
