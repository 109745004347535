import { getRateCurrency } from '../../../api/integration'
import {
  updateProperty,
  getFeeSettings,
  deleteFeeById,
  createCustomFee,
  updateCustomFee,
  updateBulkProperties,
} from '../../../api/native-listing'
import { NATIVE_LISTING_MENU } from '../../../contants/common'
import { AMOUNT_TYPE_VALUE, PROPERTY_STATUS } from '../../../contants/native'
import { FormError } from '../../../interfaces'
import { PropertyDetailProps } from '../../../interfaces/listing'
import { useAppSelector } from '../../../redux/hooks'
import { setLoading, setRateObjBaseOnUsd, setRatesBaseOnUsd, setToastSuccess } from '../../../redux/slices/common'
import { convertPriceByCurrency, handleErrorMessage, isEmpty, numberWithCommas } from '../../../utils/common'
import NativeListingBackAndContinue from '../NativeListingBackAndContinue'
import CustomFees from './CustomFees'
import CustomTaxes from './CustomTaxes'
import RenderCleaningFee from './RenderCleaningFee'
import RenderExtraGuestFee from './RenderExtraGuestFee'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import DisabledSectionOnEdit from 'src/components/common/DisabledSectionOnEdit'
import { setBulkListingData, setBulkListingSection } from 'src/redux/slices/listing'

interface Props {
  propertyId: string
  listingData: PropertyDetailProps | null
  fetchStatusStep: any
}

const MIN_GUEST = 1

const MAP_APPLIED_PER: any = {
  per_night: 'night',
  per_stay: 'reservation',
  night: 'night',
  reservation: 'reservation',
}
const defaultCleaningFee = {
  id: 0,
  type: 'cleaningFee',
  value: undefined,
  appliedPer: 'reservation',
}
const defaultExtraGuestFee = {
  type: 'extraGuestFee',
  value: undefined,
  appliedPer: 'night',
  additionalGuestAfter: 1,
}
const NativeListingFees: React.FC<Props> = ({ propertyId, listingData, fetchStatusStep }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isOnboarding = parseQuery?.f === 'onboarding'
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const isDraft = listingData?.status === PROPERTY_STATUS.DRAFT
  const currency = listingData?.propertyPrice?.currency || (parseQuery?.currency as string) || 'USD' // parseQuery?.currency is currency for bulk edit listing
  const { rateObjBaseOnUsd } = useAppSelector((state) => state.common)
  const { bulkListingData, bulkListingSection } = useAppSelector((state) => state.listing)
  const [cleaningFee, setCleaningFee] = useState<{
    id: number
    type: string
    value: number | string | undefined
    appliedPer: string
  }>(defaultCleaningFee)
  const [extraGuestFee, setExtraGuestFee] = useState<{
    type: string
    value: number | string | undefined
    appliedPer: string
    additionalGuestAfter: number
  }>(defaultExtraGuestFee)
  const [errors, setErrors] = useState<{ cleaningFee: FormError; extraGuestFee: FormError }>({
    cleaningFee: { message: '', show: false },
    extraGuestFee: { message: '', show: false },
  })
  const [isEnable, setIsEnable] = useState<{ cleaningFee: boolean; extraGuestFee: boolean }>({
    cleaningFee: false,
    extraGuestFee: false,
  })
  const [maxGuest, setMaxGuest] = useState<number>(100)
  const [toggleEdit, setToggleEdit] = useState<any>({ cleaningFee: false, extraGuestFee: false, fees: false, taxes: false }) // use for edit bulk listing
  const [fees, setFees] = useState<any>(null) // use for edit bulk listing
  const [taxes, setTaxes] = useState<any>(null)// use for edit bulk listing
  useEffect(() => {
    async function fetchRateCurrency() {
      try {
        dispatch(setLoading(true))
        const res = await getRateCurrency()
        if (Array.isArray(res.data)) {
          dispatch(setRatesBaseOnUsd(res.data))
          const rateObj: any = {}
          for (let item of res.data) {
            rateObj[item.key] = item
          }
          dispatch(setRateObjBaseOnUsd(rateObj))
        }
      } finally {
        dispatch(setLoading(false))
      }
    }

    fetchRateCurrency()
  }, [])

  useEffect(() => {
    // for extra guest fee
    if (listingData && listingData.propertyPrice) {
      const propertyPrice = listingData.propertyPrice
      const { extraPersonFee, guestsIncludedInRegularFee } = propertyPrice
      setIsEnable((prevState) => ({ ...prevState, extraGuestFee: Number(extraPersonFee) > 0 }))
      setExtraGuestFee({
        type: 'extraGuestFee',
        value: Number(extraPersonFee) === 0 ? '' : Number(extraPersonFee), // do not show 0 in case disabled
        appliedPer: 'night',
        additionalGuestAfter: Number(guestsIncludedInRegularFee) || 1,
      })
    }
    if (listingData && listingData.personCapacity) {
      setMaxGuest(listingData.personCapacity)
    }
  }, [listingData])
  useEffect(() => {
    if (isEditBulk) {
      setCleaningFee(bulkListingData?.cleaningFee || defaultCleaningFee)
      setExtraGuestFee(bulkListingData?.extraGuestFee || { ...defaultExtraGuestFee, additionalGuestAfter: -1 })
      setFees(bulkListingData?.fees || null)
      setTaxes(bulkListingData?.taxes || null)
    }
  }, [bulkListingData])
  useEffect(() => {
    // only for cleaning fee

    if (propertyId) {
      fetchCleaningFee(propertyId)
    }
  }, [propertyId])

  async function fetchCleaningFee(_propertyId: number | string) {
    const res = await getFeeSettings(_propertyId)
    if (res.success && Array.isArray(res.data)) {
      const data = res.data
      // cleaning fee
      const _cleaningFee = data.find((item: any) => item.feeType === 'cleaningFee')
      setIsEnable((prevState) => ({ ...prevState, cleaningFee: !!_cleaningFee }))
      if (_cleaningFee) {
        const appliedPer = MAP_APPLIED_PER[_cleaningFee.feeAppliedPer] || _cleaningFee.feeAppliedPer
        setCleaningFee({
          id: _cleaningFee.id,
          type: 'cleaningFee',
          value: Number(_cleaningFee.amount),
          appliedPer: appliedPer,
        })
      }
    }
  }

  const getConvertedFee = (_curr: string, price: number) => {
    return convertPriceByCurrency(rateObjBaseOnUsd, 'USD', _curr, price)
  }

  const onChangeExtraGuest = (type: 'plus' | 'minus') => {
    if (type === 'plus') {
      setExtraGuestFee((prevState) => {
        const currenVal = prevState.additionalGuestAfter
        const newVal = currenVal === maxGuest ? maxGuest : currenVal === -1 ? 1 : currenVal + 1
        return { ...prevState, additionalGuestAfter: newVal }
      })
    } else {
      setExtraGuestFee((prevState) => {
        const currenVal = prevState.additionalGuestAfter
        const newVal = currenVal === MIN_GUEST ? MIN_GUEST : currenVal - 1
        return { ...prevState, additionalGuestAfter: newVal }
      })
    }
  }

  const onChangeCleaningFee = (field: 'value' | 'appliedPer', value: number | string | undefined) => {
    setCleaningFee((prevState) => ({ ...prevState, [field]: value }))
    if (field === 'value') {
      setErrors((prevState) => ({ ...prevState, cleaningFee: { show: false, message: '' } }))
    }
  }

  const onChangeExtraGuestFee = (field: 'value' | 'appliedPer', value: number | string | undefined) => {
    setExtraGuestFee((prevState) => ({ ...prevState, [field]: value }))
    if (field === 'value') {
      setErrors((prevState) => ({ ...prevState, extraGuestFee: { show: false, message: '' } }))
    }
  }

  const onOnOffFee = (type: 'cleaningFee' | 'extraGuestFee', checked: boolean) => {
    setIsEnable((prevState) => ({ ...prevState, [type]: checked }))
    setErrors((prevState) => ({ ...prevState, [type]: { show: false, message: '' } }))
  }

  const handleSubmit = async (isExit?: boolean) => {
    let isValid = true
    let _errors = { cleaningFee: { message: '', show: false }, extraGuestFee: { message: '', show: false } }
    if (isEnable.cleaningFee) {
      if (!cleaningFee.value) {
        _errors.cleaningFee = { show: true, message: 'Please enter valid value' }
        isValid = false
      }
      if (cleaningFee.value && Number(cleaningFee.value) < 1) {
        _errors.cleaningFee = { show: true, message: 'Value must be equal or greater than 1' }
        isValid = false
      }
      const isInteger = Number.isInteger(Number(cleaningFee.value))
      const minFee = getConvertedFee(currency, 1)
      const maxFee = getConvertedFee(currency, 1000)
      if (!isInteger || Number(cleaningFee.value) < minFee || Number(cleaningFee.value) > maxFee) {
        _errors.cleaningFee = {
          show: true,
          message: `Fee must be an integer value between ${numberWithCommas(minFee)} and ${numberWithCommas(maxFee)}`,
        }
        isValid = false
      }
    }
    if (isEnable.extraGuestFee) {
      if (!extraGuestFee.value) {
        _errors.extraGuestFee = { show: true, message: 'Please enter valid discount' }
        isValid = false
      }
      if (extraGuestFee.value && Number(extraGuestFee.value) < 1) {
        _errors.extraGuestFee = { show: true, message: 'Value must be equal or greater than 1' }
        isValid = false
      }
      const isInteger = Number.isInteger(Number(extraGuestFee.value))
      const minFee = getConvertedFee(currency, 1)
      const maxFee = getConvertedFee(currency, 500)
      if (!isInteger || Number(extraGuestFee.value) < minFee || Number(extraGuestFee.value) > maxFee) {
        _errors.extraGuestFee = {
          show: true,
          message: `Fee must be an integer value between ${numberWithCommas(minFee)} and ${numberWithCommas(maxFee)}`,
        }
        isValid = false
      }
    }
    if (!isValid) {
      setErrors(_errors)
      return
    }

    try {
      dispatch(setLoading(true))
      const bodyData: any = {
        price: {
          priceForExtraPerson: 0,
          guestsIncludedInRegularFee: 1,
        },
      }
      if (isDraft) {
        bodyData.settingSteps = 10
      }
      if (isEnable.extraGuestFee) {
        bodyData.price = {
          priceForExtraPerson: extraGuestFee.value,
          guestsIncludedInRegularFee: extraGuestFee.additionalGuestAfter === -1 ? null : extraGuestFee.additionalGuestAfter,
        }
      }
      // update Extra guest fee
      if (propertyId) {
        await updateProperty(propertyId, bodyData)
      }

      if (isEditBulk) {
        if (toggleEdit.cleaningFee || toggleEdit.extraGuestFee || toggleEdit.fees || toggleEdit.taxes) {
          let dataDTO: any = {}
          if (toggleEdit.cleaningFee) {
            let cleaningFeeData: any = null
            if (isEnable.cleaningFee) {
              cleaningFeeData = {
                feeTitle: 'Cleaning fee',
                amountType: AMOUNT_TYPE_VALUE.FLAT,
                feeAppliedPer: cleaningFee.appliedPer,
                amount: Number(cleaningFee.value),
                feeType: 'cleaningFee',
                isMandatory: true,
                isQuantitySelectable: false,
              }
            }
            dataDTO = { ...dataDTO, cleaningFee: cleaningFeeData }
          }
          if (toggleEdit.extraGuestFee) {
            dataDTO = {
              ...dataDTO,
              price: {
                priceForExtraPerson: isEnable.extraGuestFee ? extraGuestFee.value : null,
                guestsIncludedInRegularFee: isEnable.extraGuestFee ? (extraGuestFee.additionalGuestAfter === -1 ? null : extraGuestFee.additionalGuestAfter) : null,
              }
            }
          }
          if (toggleEdit.fees) {
            dataDTO = { ...dataDTO, feeSettings: !isEmpty(fees) ? [...fees].map(v => ({ ...v, isMandatory: true, isQuantitySelectable: false })) : null }
          }
          if (toggleEdit.taxes) {
            dataDTO = { ...dataDTO, taxSettings: !isEmpty(taxes) ? [...taxes] : null }
          }
          const propertyIds = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
          dispatch(setBulkListingData({ ...bulkListingData, cleaningFee, extraGuestFee, fees, taxes }))
          await updateBulkProperties({ propertyIds, ...dataDTO })
          setToggleEdit({ cleaningFee: false, extraGuestFee: false, fees: false, taxes: false })
          dispatch(setToastSuccess({ message: 'Your changes have been applied.' }))
        }
      } else {
        // update cleaning fee
        if (isEnable.cleaningFee) {
          const cleaningFeeData = {
            feeTitle: 'Cleaning fee',
            amountType: AMOUNT_TYPE_VALUE.FLAT,
            feeAppliedPer: cleaningFee.appliedPer,
            amount: Number(cleaningFee.value),
            feeType: 'cleaningFee',
            isMandatory: true,
            isQuantitySelectable: false,
          }
          if (cleaningFee.id) {
            await updateCustomFee(propertyId, cleaningFee.id, cleaningFeeData)
          } else {
            await createCustomFee(propertyId, cleaningFeeData)
            await fetchCleaningFee(propertyId) // trigger get cleaning fee ( for case instance update )
          }
        } else {
          // delete cleaning fee if existed
          if (cleaningFee.id > 0) {
            await deleteFeeById(propertyId, cleaningFee.id)
            setCleaningFee((prevState) => ({ ...prevState, id: 0 }))
          }
        }
      }
      if (isExit) {
        navigate(isOnboarding ? '/' : `/listings`)
      } else if (listingData?.status === PROPERTY_STATUS.DRAFT) {
        navigate(`/native-listing/${NATIVE_LISTING_MENU.DISCOUNTS}` + search)
      } else {
        if (!isEditBulk) dispatch(setToastSuccess({ message: 'Your changes have been applied.' }))
      }
      fetchStatusStep()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      dispatch(setLoading(false))
    }
  }

  return (
    <>
      <div className="flex flex-col">
        <h2 className="text-28-36 text-neutral-800 font-maison-neue tracking-[-0.28px] mb-8">Add extra fees and taxes</h2>

        {/* ---Cleaning fee--- */}
        <DisabledSectionOnEdit
          title="Cleaning fee"
          subTitle='Add cleaning fee to guest reservations'
          openEdit={toggleEdit.cleaningFee}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, cleaningFee: value })}
        >
          <RenderCleaningFee
            isEnable={isEnable.cleaningFee}
            toggleFee={(checked) => onOnOffFee('cleaningFee', checked)}
            cleaningFee={cleaningFee}
            onChangeCleaningFee={onChangeCleaningFee}
            currency={currency}
            error={errors.cleaningFee}
            hiddenTitle
          />
        </DisabledSectionOnEdit>
        {/* ---Extra guest fee--- */}
        <DisabledSectionOnEdit
          title="Extra guest fee"
          subTitle='Charge for each additional guest past the default guest amount'
          openEdit={toggleEdit.extraGuestFee}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, extraGuestFee: value })}
        >
          <RenderExtraGuestFee
            isEnable={isEnable.extraGuestFee}
            toggleFee={(checked) => onOnOffFee('extraGuestFee', checked)}
            extraGuestFee={extraGuestFee}
            onChangeFee={onChangeExtraGuestFee}
            onChangeExtraGuest={onChangeExtraGuest}
            currency={currency}
            error={errors.extraGuestFee}
            maxGuest={maxGuest}
            hiddenTitle
          />
        </DisabledSectionOnEdit>

        {/* ---Custom fees--- */}
        <DisabledSectionOnEdit
          title="Custom fees"
          subTitle='Add custom additional charges'
          openEdit={toggleEdit.fees}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, fees: value })}
        >
          <CustomFees
            propertyId={propertyId}
            currency={currency}
            hiddenTitle
            fetchStatusStep={fetchStatusStep}
            feeSettings={fees}
            updateFees={(newFees: any[]) => setFees(newFees)}
          />
        </DisabledSectionOnEdit>

        {/* ---Custom taxes--- */}
        <DisabledSectionOnEdit
          title="Custom taxes"
          subTitle='Add additional taxes to your fees.'
          openEdit={toggleEdit.taxes}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, taxes: value })}
        >
          <CustomTaxes
            propertyId={propertyId}
            currency={currency}
            hiddenTitle
            fetchStatusStep={fetchStatusStep}
            taxSettings={taxes}
            updateTaxes={(newTaxes: any[]) => setTaxes(newTaxes)}
          />
        </DisabledSectionOnEdit>
      </div>

      {/*--- Footer: Back and Preview ---*/}
      <NativeListingBackAndContinue
        onSubmit={handleSubmit}
        isEdit={!isDraft}
        isDisabledSubmit={isEditBulk ? !(toggleEdit.cleaningFee || toggleEdit.extraGuestFee || toggleEdit.fees || toggleEdit.taxes) : false}
      />
      <div
        id={`${NATIVE_LISTING_MENU.FEES}_save_exit`}
        className="hidden"
        onClick={() => handleSubmit(true)}
        role="presentation"
      />
      <div
        id={`${NATIVE_LISTING_MENU.FEES}_save`}
        className="hidden"
        onClick={() => {
          if (isEditBulk) {
            if (isEmpty(bulkListingSection) && (toggleEdit.cleaningFee || toggleEdit.extraGuestFee || toggleEdit.fees || toggleEdit.taxes)) {
              let result: string[] = []
              if (toggleEdit.cleaningFee) result.push('Cleaning fee')
              if (toggleEdit.extraGuestFee) result.push('Extra guest fee')
              if (toggleEdit.fees) result.push('Custom fees')
              if (toggleEdit.taxes) result.push('Custom taxes')
              dispatch(setBulkListingSection(result.join(', ')))
            } else handleSubmit()
          } else handleSubmit()
        }}
        role="presentation"
      />
    </>
  )
}

export default NativeListingFees
