import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'

interface Props {
  isLoading?: boolean
  size?: number
}

const LoadingSection: React.FC<Props> = ({ isLoading, size = 50 }) => {
  if (isLoading)
    return (
      <div
        className={
          'absolute w-full h-full top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center opacity-30 cursor-not-allow'
        }
      >
        <CircularProgress size={size} style={{ color: '#0F0F0E' }} />
      </div>
    )
  return null
}

export default LoadingSection
