import CustomImage from '../common/CustomImage'
import BasicButton from '../ui/BasicButton'
import NativeListingBackAndContinue from './NativeListingBackAndContinue'
import Popover from '@mui/material/Popover'
import clsx from 'clsx'
import queryString from 'query-string'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { savePropertyImage, updateProperty, uploadPropertyImage } from 'src/api/native-listing'
import ic_more_circle from 'src/assets/icons/ic_more_circle.svg'
import { NATIVE_LISTING_MENU, PMS_TYPE } from 'src/contants/common'
import { PROPERTY_STATUS } from 'src/contants/native'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setLoading, setToastSuccess } from 'src/redux/slices/common'
import { handleErrorMessage, isEmpty, validateWidthHeightImage } from 'src/utils/common'

interface Props {
  propertyImages: any[]
  listingData: any
  fetchStatusStep: any
}

const MAX_IMAGES = 200
const MIN_IMAGES = 4

let sortOrderSelected: number = -1
let interval: any = null
const NativeListingPhotos = ({ propertyImages, listingData, fetchStatusStep }: Props) => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const dispatch = useAppDispatch()
  const { isLoading } = useAppSelector((state) => state.common)
  const parseQuery = queryString.parse(search)
  const isOnboarding = parseQuery?.f === 'onboarding'
  const isDraft = listingData?.status === PROPERTY_STATUS.DRAFT
  let inputRef: any = React.useRef(null)
  const [dragActive, setDragActive] = React.useState<boolean>(false)
  const [photos, setPhotos] = React.useState<any[]>([])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [loadingUpload, setLoadingUpload] = React.useState<boolean>(false)
  const isHospitable = listingData?.pmsType === PMS_TYPE.HOSPITABLE

  useEffect(() => {
    setPhotos(propertyImages)
  }, [propertyImages])
  useEffect(() => {
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [])

  const handleOpen = (e: any, sortOrder: number) => {
    setAnchorEl(e.currentTarget)
    sortOrderSelected = sortOrder
  }
  const handleClose = () => {
    setAnchorEl(null)
    sortOrderSelected = -1
  }

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      handleUploadImages(e.dataTransfer.files)
    }
  }
  const handleChange = (e: any) => {
    // e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      // at least one file has been selected so do something
      handleUploadImages(e.target.files)
    }
    // e.target.value = null
  }
  const handleUploadImages = async (files: File[]) => {
    if (!isEmpty(files)) {
      if ([...photos, ...files].length > MAX_IMAGES) {
        const numberUpload = MAX_IMAGES - photos.length
        handleErrorMessage({
          message: `You can only upload ${numberUpload} more photo${numberUpload > 1 ? 's' : ''}.`,
        })
        return
      }
      try {
        let arrServices: any = []
        let errorFiles: any = []
        const fileList = [...files]
        for (let file of files) {
          const imageSizeIntoMB = file.size / (1000 * 1000)
          if (!file?.type.startsWith('image/') || imageSizeIntoMB > 25) errorFiles.push(file)
          else {
            const isValid = await validateWidthHeightImage(file, 1024, 683)
            if (isValid) {
              let formData = new FormData()
              formData.append('file', file)
              arrServices.push(uploadPropertyImage(Number(parseQuery.id), formData))
            } else errorFiles.push(file)
          }
        }
        if (!isEmpty(errorFiles)) {
          handleErrorMessage({
            message: `${errorFiles.length} photo${errorFiles.length > 1 ? 's do' : ' does'
              } not meet the photo requirements.`,
          })
        }
        if (interval) clearInterval(interval)
        interval = setInterval(async () => {
          if (!isEmpty(arrServices) && arrServices.length + errorFiles.length === fileList.length) {
            try {
              // dispatch(setLoading(true))
              setLoadingUpload(true)
              clearInterval(interval)
              const res: any[] = await Promise.all(arrServices)
              const result = (res || [])
                .filter((v) => v.success)
                .map((v: any, idx: number) => ({
                  caption: '',
                  url: v.data,
                  sortOrder: photos.length + idx + 1,
                }))
              const newPhotos = [...photos, ...result]
              setPhotos(newPhotos)
            } catch (error: any) {
              handleErrorMessage(error)
            } finally {
              // dispatch(setLoading(false))
              setLoadingUpload(false)
            }
          }
        }, 300)
      } catch (error: any) {
        // dispatch(setLoading(false))
        setLoadingUpload(false)
        handleErrorMessage(error)
      }
    }
  }
  const handleNext = () => {
    navigate(`/native-listing/${NATIVE_LISTING_MENU.AMENITIES}` + search)
  }
  const handleSubmit = async (isExit?: boolean) => {
    if (photos.length < 4 && !isDraft) {
      const numberUpload = 4 - photos.length
      handleErrorMessage({
        message: `Add at least ${numberUpload} more photo${numberUpload > 1 ? 's' : ''} to publish your listing.`,
      })
      return
    }
    if (!isEmpty(photos)) {
      try {
        dispatch(setLoading(true))
        const res: any = await savePropertyImage(Number(parseQuery.id), {
          images: photos,
          settingSteps: listingData?.settingSteps > 4 ? listingData?.settingSteps : 4,
        })
        if (!listingData?.settingSteps || listingData?.settingSteps < 4) {
          // update settings steps
          await updateProperty(Number(parseQuery.id), { settingSteps: 4 })
        }
        if (res?.success) {
          fetchStatusStep()
          if (isExit) navigate(isOnboarding ? '/' : `/listings`)
          else if (isDraft) navigate(`/native-listing/${NATIVE_LISTING_MENU.AMENITIES}` + search)
          else dispatch(setToastSuccess({ message: 'Your changes have been applied.' }))
        }
      } catch (err: any) {
        handleErrorMessage(err)
      } finally {
        dispatch(setLoading(false))
      }
    } else {
      handleNext()
    }
  }
  const handleReOrder = () => {
    if (sortOrderSelected === -1) return
    const prev = [...photos].filter((v: any) => v.sortOrder === sortOrderSelected)
    const next = [...photos].filter((v: any) => v.sortOrder !== sortOrderSelected)
    setPhotos([...prev, ...next]?.map((v: any, idx: number) => ({ ...v, sortOrder: idx + 1 })))
    handleClose()
  }
  const handleDelete = () => {
    if (sortOrderSelected === -1) return
    setPhotos(
      [...photos]
        .filter((v: any) => v.sortOrder !== sortOrderSelected)
        ?.map((v: any, idx: number) => ({ ...v, sortOrder: idx + 1 }))
    )
    handleClose()
  }
  const renderUploadArea = () => {
    return (
      <form
        id="form-file-upload"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
        className="w-full h-full"
      >
        <input
          type="file"
          ref={inputRef}
          id="input-file-upload"
          multiple={true}
          className="hidden"
          onChange={handleChange}
        />
        <div
          id="label-file-upload"
          className={clsx(
            `flex flex-col items-center justify-center h-full shadow-[0px_1px_3px_rgba(0,0,0,0.04)]`,
            `bg-white rounded-[16px] border`,
            dragActive ? 'border-black' : 'border-grayscale-200'
          )}
        >
          <BasicButton
            variant="contained"
            size={!isEmpty(photos) ? 'sm' : 'lg'}
            color={!isEmpty(photos) ? 'white' : 'black'}
            clases={clsx(
              `w-auto rounded-[100px] flex justify-center border shadow-[0px_1px_2px_rgba(0,0,0,0.05)]`,
              loadingUpload ? 'cursor-not-allowed opacity-60' : 'cursor-pointer opacity-100',
              !isEmpty(photos) ? 'h-[32px] border-neutral-300' : 'border-black h-[44px]'
            )}
            onClick={() => {
              if (inputRef) inputRef?.current?.click()
            }}
            loading={loadingUpload}
            disabled={loadingUpload}
          >
            <span className={`${!isEmpty(photos) ? 'text-14-18' : 'text-16-20'} font-maison-neue-medium`}>
              Upload photos
            </span>
          </BasicButton>
          {dragActive && (
            <div
              id="drag-file-element"
              className="absolute w-full h-full rounded-[16px] top-0 left-0 right-0 bottom-0"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            />
          )}
          <p className="mt-4 text-14-20 font-maison-neue text-neutral-800">{'or drag & drop them here'}</p>
        </div>
      </form>
    )
  }
  const open = Boolean(anchorEl)
  return (
    <>
      <div className="flex flex-col lg:relative">
        <p className="text-28-36 text-neutral-800 font-maison-neue tracking-[-0.01em] mb-1">
          Add photos to your listing
        </p>
        <p className="mb-4 text-neutral-600 text-14-20 font-maison-neue">Show off your property</p>
        <div className="flex flex-wrap gap-4">
          {photos.map((el: any, idx: number) => {
            const isCoverImage = idx === 0
            return (
              <div
                key={idx}
                className={clsx('rounded-[16px] ', isCoverImage ? 'w-full' : 'w-full md:w-[calc(50%_-_8px)]')}
              >
                <div
                  className={`relative w-full rounded-[16px] mb-3 bg-neutral-300 ${isCoverImage ? 'h-[358px]' : 'h-[206px]'
                    }`}
                >
                  <CustomImage url={el?.url} classes="rounded-[16px]" />
                  {!isHospitable && (
                    <div
                      className="w-[36px] h-[36px] rounded-[50%] absolute top-3 right-3 z-[1] cursor-pointer"
                      role="presentation"
                      onClick={(e: any) => handleOpen(e, el.sortOrder)}
                    >
                      <img src={ic_more_circle} alt={'ic_plus'} />
                    </div>
                  )}

                  <div className="w-auto h-[24px] rounded-[4px] absolute bottom-3 left-3 z-[1] px-2 py-1 bg-white flex items-center justify-center">
                    <span className="text-black text-12-16 font-maison-neue">
                      {idx + 1}/{photos.length}
                      {isCoverImage ? ' - Cover image' : ''}
                    </span>
                  </div>
                </div>
                <input
                  placeholder="Add a caption..."
                  className="focus:outline-none border-none text-14-20 font-maison-neue placeholder:text-grayscale-500 text-black w-full bg-transparent"
                  value={el.caption}
                  onChange={(e) => {
                    const val = e?.target?.value
                    const newPhotos = [...photos].map((v: any) => {
                      if (v.sortOrder === el.sortOrder) return { ...v, caption: val }
                      return v
                    })
                    setPhotos(newPhotos)
                  }}
                />
              </div>
            )
          })}
          {!isHospitable && photos.length < MAX_IMAGES && (
            <div
              className={clsx(
                'rounded-[16px] bg-neutral-300',
                isEmpty(photos) ? 'w-full h-[358px]' : 'w-full md:w-[calc(50%_-_8px)] h-[206px]'
              )}
            >
              {renderUploadArea()}
            </div>
          )}
        </div>
        <div className="w-full lg:w-[296px] rounded-[16px] bg-neutral-200 p-4 mt-8 lg:mt-0 lg:absolute z-[1] top-[76px] right-[-324px]">
          <p className="text-16-20 font-maison-neue-medium text-black mb-4">Photo requirements</p>
          <ul className="flex flex-col gap-1 list-disc ml-6">
            <li className="text-14-18 font-maison-neue text-black">{MIN_IMAGES} photos minimum</li>
            <li className="text-14-18 font-maison-neue text-black">1024x683px minimum resolution</li>
            <li className="text-14-18 font-maison-neue text-black">Max {MAX_IMAGES} images per property</li>
            <li className="text-14-18 font-maison-neue text-black">25MB maximum</li>
          </ul>
        </div>
      </div>
      <NativeListingBackAndContinue
        isEdit={!isDraft}
        onSubmit={() => handleSubmit()}
        isLoading={isLoading}
        isDisabledSubmit={photos.length > MAX_IMAGES}
      // isDisabledSubmit={imageList.length < 4 || imageList.length > 50}
      />
      <div
        id={`${NATIVE_LISTING_MENU.PHOTOS}_save_exit`}
        className="hidden"
        onClick={() => handleSubmit(true)}
        role="presentation"
      />
      <div
        id={`${NATIVE_LISTING_MENU.PHOTOS}_save`}
        className="hidden"
        onClick={() => handleSubmit()}
        role="presentation"
      />
      <Popover
        id={'simple-popover'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          '& .MuiPaper-root': {
            width: 'auto',
            height: 'auto',
            boxShadow: '0px 8px 16px -4px rgba(0, 0, 0, 0.06), 0px 4px 6px -2px rgba(0, 0, 0, 0.04)',
            borderRadius: '16px',
            backgroundColor: '#FFFFFF',
            padding: '0px',
            marginTop: '8px',
          },
        }}
      >
        <div className={'flex flex-col py-2'}>
          <span
            className={'font-maison-neue text-14-18 text-black py-2 px-4 cursor-pointer'}
            onClick={handleReOrder}
            role="presentation"
          >
            Use as cover
          </span>
          <span
            className={'font-maison-neue text-14-18 text-black py-2 px-4 cursor-pointer'}
            onClick={handleDelete}
            role="presentation"
          >
            Delete
          </span>
        </div>
      </Popover>
    </>
  )
}

export default NativeListingPhotos
