import React from 'react'
import ic_spinner from 'src/assets/icons/ic_bars_rotate_fade.svg'
import no_image from 'src/assets/images/no_image_listing.jpg'
import { IconChecked } from 'src/components/common/Icons'
import LoadingSection from 'src/components/common/LoadingSection'
import RenderPrice from 'src/components/common/RenderPrice'
import { SYNC_STATUS } from 'src/contants/property'

interface Props {
  listings: any[]
  loading?: boolean
  total: number
  isEmpty?: boolean
}

const ShowImportListing: React.FC<Props> = ({ listings, loading, total, isEmpty }) => {
  const remainCount = total - listings.length
  return (
    <div className={'w-full md:max-w-[524px] mx-auto relative'}>
      <LoadingSection isLoading={loading} />
      {!isEmpty && (
        <div className={'flex flex-col bg-white rounded-[16px] shadow-sm'}>
          <div className={`${listings.length === 0 ? 'min-h-[140px]' : ''} `}>
            {listings.map((item) => {
              const isHasPrice = item.price !== undefined && item.price !== null
              return (
                <div key={item.id} className={'flex gap-[16px] p-[16px]'}>
                  <div className={'w-[40px] h-[40px] min-w-[40px] rounded-[8px] bg-neutral-200'}>
                    <img
                      src={item.thumbnailUrl || no_image}
                      alt={item.name}
                      className={'w-full h-full rounded-[8px] object-cover'}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = no_image
                      }}
                    />
                  </div>
                  <div>
                    <p className={'font-maison-neue-medium text-14-18 text-neutral-800 line-clamp-1'}>{item.name}</p>
                    <p className={'font-maison-neue text-14-18 text-neutral-600 mt-[4px] line-clamp-1'}>
                      {item.address}
                    </p>
                  </div>
                  <div className={'ml-auto flex gap-[16px] items-center justify-end'}>
                    {isHasPrice && (
                      <div>
                        <p className={'font-maison-neue text-12-16 text-neutral-800 whitespace-nowrap text-right'}>
                          Starting from
                        </p>
                        <p
                          className={'font-maison-neue-medium text-14-20 text-neutral-800 whitespace-nowrap text-right'}
                        >
                          <RenderPrice
                            price={item.price}
                            currency={item.currencyCode}
                            isUseSymbol={true}
                            isRounded={true}
                          />
                          &nbsp;
                        </p>
                      </div>
                    )}
                    <div className={'w-[24px] h-[24px] min-w-[24px]'}>
                      {item.status === SYNC_STATUS.PENDING ? (
                        <img src={ic_spinner} alt={'ic_spinner'} width={'24px'} height={'24px'} />
                      ) : (
                        <IconChecked color={'#8C8C88'} />
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          {remainCount > 0 && (
            <div className={'py-[24px]'}>
              <p className={'font-maison-neue-medium text-14-18 text-neutral-500 text-center'}>+ {remainCount} more</p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ShowImportListing
