import LayoutOnboarding from '../LayoutOnboarding'
import clsx from 'clsx'
import React from 'react'
import ic_chat from 'src/assets/icons/ic_chat.svg'
import ic_file_text from 'src/assets/icons/ic_file_text.svg'
import onboarding_need_support from 'src/assets/images/onboarding_need_support.png'
import BasicButton from 'src/components/ui/BasicButton'
import { ONBOARDING_STEP } from 'src/contants/user'
import { useAppDispatch } from 'src/redux/hooks'
import { handleNextOnboardingStep } from 'src/redux/slices/user'

const OnboardingNeedSupport = () => {
  const dispatch = useAppDispatch()
  const renderRightContent = () => {
    return (
      <div className="h-full flex pt-[300px] overflow-hidden">
        <img src={onboarding_need_support} alt="" className="h-[868px] w-[400px]" />
      </div>
    )
  }
  const onSubmit = async () => {
    dispatch(handleNextOnboardingStep(ONBOARDING_STEP.SITE_DETAILS_SITE_NAME))
  }
  const renderFooterBtn = () => {
    return (
      <BasicButton
        size={'xl'}
        variant={'contained'}
        clases={'w-auto h-[48px] rounded-[100px_!important] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]'}
        color="red"
        onClick={() => onSubmit()}
        // disabled={loading}
      >
        Skip
      </BasicButton>
    )
  }
  return (
    <LayoutOnboarding rightContent={renderRightContent()} headerTitle="Listings">
      <div className="pb-[136px] lg:pb-[64px] pt-[38px] lg:pt-[64px] max-w-[524px] mx-auto overflow-auto">
        <p className="mb-6 text-24-36 text-neutral-800">Let us help you get setup:</p>
        <div className="flex flex-col gap-6">
          <div className="w-full flex gap-4">
            <div
              className="w-[calc(50%_-_8px)] h-[152px]"
              onClick={() => window.open('https://dtravel.com/help', '_blank')}
              role="presentation"
            >
              <div
                className={clsx(
                  'w-full h-full flex flex-col gap-2 items-center justify-center',
                  'border border-neutral-400 rounded-[16px] cursor-pointer bg-white',
                  'shadow-[0px_1px_2px_0px_rgba(0,0,0,0.04),0px_1px_3px_0px_rgba(0,0,0,0.04)]'
                )}
              >
                <img src={ic_file_text} alt="" className="h-[24px] w-[24px]" />
                <p className="text-16-24 font-maison-neue text-neutral-800">Help articles</p>
              </div>
            </div>
            <div
              className={clsx(
                'w-[calc(50%_-_8px)] h-[152px] flex flex-col gap-2 items-center justify-center',
                'border border-neutral-400 rounded-[16px] cursor-pointer bg-white',
                'shadow-[0px_1px_2px_0px_rgba(0,0,0,0.04),0px_1px_3px_0px_rgba(0,0,0,0.04)]'
              )}
              onClick={() => {
                const HubSpotConversations = (window as any).HubSpotConversations
                if (HubSpotConversations) {
                  HubSpotConversations.widget.open()
                } else {
                  console.error('HubSpotConversations does not define')
                }
              }}
              role="presentation"
            >
              <img src={ic_chat} alt="" className="h-[24px] w-[24px]" />
              <p className="text-16-24 font-maison-neue text-neutral-800">Chat support</p>
            </div>
          </div>
          <div
            className={clsx(
              'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
              'border-t border-neutral-300 px-4 md:px-8',
              'shadow-[0px_-2px_4px_-2px_rgba(0,0,0,0.08),0px_-4px_8px_-2px_rgba(0,0,0,0.06)]'
            )}
          >
            {renderFooterBtn()}
          </div>
          <div className="hidden lg:block">{renderFooterBtn()}</div>
        </div>
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingNeedSupport
