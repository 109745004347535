import { handleErrorMessage } from '../../../utils/common'
import Popover from '@mui/material/Popover'
import React, { useEffect, useState } from 'react'
import { ColorResult, SketchPicker } from 'react-color'
import { updateHostSettings } from 'src/api/user'
import ic_close from 'src/assets/icons/ic_close_md.svg'
import BasicButton from 'src/components/ui/BasicButton'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { setSettingData } from 'src/redux/slices/user'

const DEFAULT_COLOR = '#0F0F0F'

const SettingSitePrimaryColor = () => {
  const dispatch = useAppDispatch()
  const { settingData } = useAppSelector((state) => state.user)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [color, setColor] = useState(DEFAULT_COLOR)

  useEffect(() => {
    setColor(settingData && settingData.primaryColor ? settingData.primaryColor : DEFAULT_COLOR)
  }, [settingData?.primaryColor])

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const onChangeComplete = (_color: ColorResult) => {
    if (_color) {
      setColor(_color.hex)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClear = () => {
    setColor(settingData && settingData.primaryColor ? settingData.primaryColor : DEFAULT_COLOR)
  }

  const handleSave = async () => {
    try {
      const _primaryColor = color.toUpperCase() === '#FFFFFF' ? '#0F0F0F' : color
      const res = await updateHostSettings({ primaryColor: _primaryColor })
      if (res.success) {
        dispatch(setToastSuccess({ message: 'Update success!' }))
        dispatch(setSettingData({ ...settingData, ...res.data }))
        handleClose()
      }
    } catch (err: any) {
      handleErrorMessage(err)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const dontChangeColor = color === (settingData?.primaryColor || DEFAULT_COLOR)

  return (
    <div className={'flex flex-col md:flex-row justify-between items-start gap-[12px]'}>
      <div>
        <p className={'font-maison-neue-medium text-neutral-800 text-16-20'}>Primary Color</p>
        <p className={'font-maison-neue text-neutral-600 text-14-18 mt-[4px]'}>
          Customize the primary color of your booking site, used for calls-to-action, buttons, and more.
        </p>
        <div className={'mt-[12px] flex items-center gap-[8px]'}>
          <div
            className={'w-[20px] h-[20px] rounded-[4px]'}
            style={{
              border: '1px solid rgba(0, 0, 0, 0.15)',
              backgroundColor: settingData?.primaryColor || DEFAULT_COLOR,
            }}
          />
          <span className={'font-maison-neue text-16-20 text-neutral-800'}>
            {settingData?.primaryColor || DEFAULT_COLOR}
          </span>
        </div>
      </div>
      <BasicButton size={'md'} variant={'outlined'} clases={'w-full md:w-auto'} isRadius100 onClick={handleOpen}>
        Update
      </BasicButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            transform: 'translateY(8px)',
            padding: 0,
            boxShadow: '0px 10px 25px -5px rgba(0, 0, 0, 0.06), 0px 20px 50px -12px rgba(0, 0, 0, 0.1)',
            borderRadius: '12px',
            width: '280px',
            backgroundColor: '#F7F7F4',
          },
        }}
      >
        <div className={'bg-white flex items-center justify-between h-[68px] p-[24px]'}>
          <span>Primary Color</span>
          <button onClick={handleClose}>
            <img src={ic_close} alt={'ic_close'} />
          </button>
        </div>
        <div className={'p-[8px] flex items-center justify-center'}>
          <SketchPicker color={color} onChangeComplete={onChangeComplete} width={'278px'} />
        </div>
        <div className={'bg-white flex items-center justify-between h-[76px] px-[24px]'}>
          <BasicButton size={'md'} isRadius100={true} disabled={dontChangeColor} onClick={handleClear}>
            Clear
          </BasicButton>
          <BasicButton variant={'outlined'} size={'md'} isRadius100={true} onClick={handleSave}>
            Apply
          </BasicButton>
        </div>
      </Popover>
    </div>
  )
}

export default SettingSitePrimaryColor
