import ic_check_only_black from '../../assets/icons/ic_check_only_black.svg'
import { renderPropertyInfo } from '../../utils/property'
import RenderPrice from '../common/RenderPrice'
import BasicButton from '../ui/BasicButton'
import BasicInput from '../ui/BasicInput'
import NativeListingBackAndContinue from './NativeListingBackAndContinue'
import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getHostApproved, publishProperty, updateProvideUrl } from 'src/api/native-listing'
import { NATIVE_LISTING_MENU } from 'src/contants/common'
import { PROPERTY_STATUS } from 'src/contants/native'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setLoading } from 'src/redux/slices/common'
import { setShowPopupReviewing } from 'src/redux/slices/listing'
import { handleErrorMessage, isEmpty, isURL } from 'src/utils/common'
import queryString from 'query-string'

interface Props {
  listingData: any
}

const NativeListingPublish = ({ listingData }: Props) => {
  if (isEmpty(listingData)) return null
  // const defaultImage = 'https://static.dtravel.com/guest/hero-image.webp'
  const { externalName, propertyPrice, amenities, status } = listingData
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isOnboarding = parseQuery?.f === 'onboarding'

  const { isLoading } = useAppSelector((state) => state.common)
  const [showMore, setShowMore] = useState<boolean>(false)
  const [provideUrl, setProvideUrl] = useState<string>('')
  const [isErrorUrl, setErrorUrl] = useState<boolean>(false)
  const [hostApproved, setHostApproved] = useState<any>(null)
  const fetchHostApproved = async () => {
    try {
      const res: any = await getHostApproved()
      setHostApproved(res?.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchHostApproved()
  }, [])
  useEffect(() => {
    setProvideUrl(listingData?.provideUrl)
  }, [listingData])
  const handleNext = () => {
    navigate(isOnboarding ? '/' : `/listings`)
  }
  const handleSubmit = async () => {
    try {
      dispatch(setLoading(true))
      let dataDTO: any = {}
      if (!isEmpty(provideUrl)) dataDTO = { ...dataDTO, provideUrl }
      const res: any = await publishProperty(listingData?.id, dataDTO)
      if (res?.success) {
        handleNext()
        if (!hostApproved?.isHostApproved && !isOnboarding) dispatch(setShowPopupReviewing(true))
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      dispatch(setLoading(false))
    }
  }
  const renderIncludesItem = (text: string) => {
    return (
      <div className="flex items-center gap-2 w-full md:w-[calc(50%_-_8px)]">
        <img src={ic_check_only_black} alt={'ic_check_black'} className="w-4 h-4 min-w-4" />
        <span className="text-neutral-800 text-16-24 font-maison-neue">{text}</span>
      </div>
    )
  }

  const handleUpdateExit = async () => {
    if (!isEmpty(provideUrl) && isURL(provideUrl)) {
      try {
        dispatch(setLoading(true))
        const res: any = await updateProvideUrl(listingData?.id, provideUrl)
        if (res?.success) handleNext()
      } catch (error: any) {
        handleErrorMessage(error)
      } finally {
        dispatch(setLoading(false))
      }
    } else handleNext()
  }
  const defaultNumberAmenities = 9
  const hasMore = (amenities || [])?.length > defaultNumberAmenities
  const propertyImage = listingData?.propertyImages || []
  const imagePropertyUrl = propertyImage.length >= 0 ? propertyImage[0]?.url : null
  return (
    <div className="flex flex-col">
      <p className="text-28-36 text-neutral-800 font-maison-neue tracking-[-0.01em] mb-4">
        {hostApproved?.isHostApproved ? 'Publish your listing' : 'Request access to the beta'}
      </p>
      <p className="mb-4 text-neutral-600 text-14-20 font-maison-neue">
        {`Here's the information that will show on this listing on your direct booking website. To edit, press the Back button and go back to the section you'd like to edit.`}
      </p>
      <div className="bg-white w-full mb-8 rounded-[16px]">
        <div
          className="w-full h-[358px] flex flex-col items-center justify-center rounded-tl-[16px] rounded-tr-[16px]"
          style={
            imagePropertyUrl
              ? {
                backgroundImage: `url(${imagePropertyUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
              }
              : {
                background: '#E3E3DE',
              }
          }
        >
          {propertyImage.length < 4 && (
            <div
              className={`rounded-[16px] flex flex-col items-center justify-center p-6 ${imagePropertyUrl ? 'text-neutral-300' : 'text-neutral-500'
                }`}
              style={
                imagePropertyUrl
                  ? {
                    backdropFilter: 'blur(25px)',
                  }
                  : {}
              }
            >
              <p className="py-4 text-16-20 font-maison-neue-medium cursor-pointer text-center">
                {imagePropertyUrl
                  ? `Add at least ${4 - propertyImage.length} more photo${4 - propertyImage.length > 1 ? 's' : ''
                  } to publish your listing`
                  : `Add at least 4 photos to publish your listing`}
              </p>
              <div>
                <BasicButton
                  size="sm"
                  variant={'outlined'}
                  clases="w-auto rounded-[100px] bg-white cursor-pointer"
                  onClick={() => {
                    navigate(`/native-listing/${NATIVE_LISTING_MENU.PHOTOS}?id=${listingData?.id}`)
                  }}
                >
                  Back to Photos
                </BasicButton>
              </div>
            </div>
          )}
        </div>
        <div className="p-6">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2 mb-6">
            <div className="flex flex-col">
              <p className="text-20-32 text-neutral-800 font-maison-neue">{externalName}</p>
              <p className="text-16-24 text-neutral-800 font-maison-neue">{renderPropertyInfo(listingData)}</p>
            </div>
            <div className="flex flex-col">
              <p className="text-12-16 text-neutral-800 font-maison-neue md:text-right whitespace-nowrap">
                Starting from
              </p>
              <p className="text-20-32 text-neutral-800 font-maison-neue md:text-right whitespace-nowrap">
                <RenderPrice
                  price={Number(propertyPrice?.basePrice || 0)}
                  currency={propertyPrice?.currency || 'USD'}
                  isRounded
                />
                /night
              </p>
            </div>
          </div>
          <div>
            {!isEmpty(amenities) ? (
              <>
                <p className="text-neutral-800 text-16-24 font-maison-neue mb-1">Includes:</p>
                <div className="w-full flex items-center gap-x-4 gap-y-1 flex-wrap">
                  {!isEmpty(amenities) &&
                    amenities.map((el: any, idx: number) => {
                      if (!showMore && idx > defaultNumberAmenities - 1) return null
                      return <Fragment key={idx}>{renderIncludesItem(el?.name)}</Fragment>
                    })}
                  {hasMore && (
                    <div
                      className="flex items-center gap-2 w-full md:w-[calc(50%_-_8px)] cursor-pointer"
                      onClick={() => setShowMore(!showMore)}
                      role="presentation"
                    >
                      <span className="text-neutral-800 text-16-24 font-maison-neue underline">
                        {showMore ? 'show less' : `and ${amenities.length - defaultNumberAmenities} more`}
                      </span>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="flex flex-col items-center">
                <p className="py-4 text-grayscale-500 text-16-20 font-maison-neue cursor-pointer text-center">
                  Add at least one amenity to publish your listing
                </p>
                <div>
                  <BasicButton
                    size="sm"
                    variant={'outlined'}
                    clases="w-auto rounded-[100px]"
                    onClick={() => {
                      navigate(`/native-listing/${NATIVE_LISTING_MENU.AMENITIES}?id=${listingData?.id}`)
                    }}
                  >
                    Back to Amenities
                  </BasicButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!hostApproved?.isHostApproved && (
        <>
          <p className="mb-2 text-neutral-800 text-14-18 font-maison-neue-medium">
            Provide a URL to this listing from an existing platform (Airbnb, VRBO, etc...)
          </p>
          <BasicInput
            placeholder="Add a URL..."
            value={provideUrl}
            onChange={(e: any) => {
              // const newValue = e?.target.value.trim()
              setProvideUrl(e?.target.value.trim())
              setErrorUrl(false)
            }}
            onBlur={() => {
              if (!isEmpty(provideUrl) && isURL(provideUrl)) return
              setErrorUrl(true)
            }}
          />
          {isErrorUrl && <span className={'font-inter-400 text-red mt-1 text-12-16'}>Please provide a valid url.</span>}
          <p className="mt-2 text-neutral-500 text-14-18 font-maison-neue mb-[56px]">
            We will use it to determine your eligibility for the beta.
          </p>
          <p className="text-center text-neutral-500 text-14-20 font-maison-neue">
            {`We're currently working with select property managers while this feature is in beta.`}
            <br />
            You can request to be part of the beta and we will review your request.
          </p>
        </>
      )}
      {status !== PROPERTY_STATUS.PENDING_REVIEW && (
        <NativeListingBackAndContinue
          onSubmit={handleSubmit}
          isLoading={isLoading}
          submitText={hostApproved?.isHostApproved ? 'Publish' : 'Request beta access'}
          isDisabledSubmit={
            !hostApproved?.isHostApproved && (isEmpty(provideUrl) || (!isEmpty(provideUrl) && !isURL(provideUrl))) ||
            (amenities || []).length < 1 ||
            propertyImage.length < 4
          }
        // isDisabledSubmit={isEmpty(provideUrl) || !isURL(provideUrl)}
        />
      )}
      <div
        id={`${NATIVE_LISTING_MENU.PUBLISH}_save_exit`}
        className="hidden"
        onClick={handleUpdateExit}
        role="presentation"
      />
    </div>
  )
}

export default NativeListingPublish
