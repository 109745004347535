// import { useAppSelector } from 'src/redux/hooks'
import { getBusinessUsers } from '../../../api/user'
import { BUSINESS_USER_ROLE, SETTINGS_SIDE_BAR } from '../../../contants/common'
// import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import AddEditUser from './AddEditUser'
import TableListingUsers from './TableListingUsers'
import React, { useEffect, useState } from 'react'
import { Paging } from 'src/interfaces'
import { useAppSelector } from 'src/redux/hooks'

const SettingAccountUsers = () => {
  // const dispatch = useAppDispatch()
  const { profile } = useAppSelector((state) => state.user)
  const [users, setUsers] = useState<any>([])
  const [paging, setPaging] = useState<Paging>({ page: 1, pageSize: 15, total: 0, totalPages: 0 })
  const isEditor = profile?.editorProfile?.type === BUSINESS_USER_ROLE.EDITOR

  const fetchBusinessUsers = async (param?: any) => {
    try {
      let params = param || {}
      const res: any = await getBusinessUsers({ page: paging.page, pageSize: paging.pageSize, ...params })
      setUsers(res?.data)
      setPaging(res?.paging)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchBusinessUsers()
  }, [])

  return (
    <>
      <div className="flex items-center justify-between gap-4 mb-4">
        <div>
          <h2
            id={SETTINGS_SIDE_BAR.ACCOUNT_INFORMATION}
            className={'font-maison-neue text-neutral-800 text-20-24 capitalize'}
          >
            Account users
          </h2>
          <p className={'font-maison-neue text-neutral-500 text-14-18 mt-[4px]'}>
            Manage who can access and manage your business
          </p>
        </div>
        {!isEditor && <AddEditUser fetchData={fetchBusinessUsers} />}
      </div>

      <div>
        <TableListingUsers users={users} paging={paging} fetchData={fetchBusinessUsers} />
      </div>
    </>
  )
}

export default SettingAccountUsers
