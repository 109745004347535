import { ServerResponse } from '../interfaces'
import { ParamsReservationList } from '../interfaces/reservations'
import Api from './index'
import { PMS_TYPE } from 'src/contants/common'

export const getReservations = (params: ParamsReservationList): Promise<ServerResponse> => {
  // T=ServerResponse
  // R=AxiosResponse<ServerResponse>
  // Promise<R> = Promise<AxiosResponse<ServerResponse>>
  return Api.get<ServerResponse>('reservation-service/reservation/host', {
    params: params,
  }).then((axiosRes) => axiosRes.data)
}

export const getReservationDetail = (reservationId: string): Promise<ServerResponse> => {
  return Api.get<ServerResponse>('reservation-service/reservation/host/' + reservationId).then(
    (axiosRes) => axiosRes.data
  )
}
export const cancelManualReservation = (reservationId: string): Promise<ServerResponse> => {
  return Api.post<ServerResponse>('reservation-service/reservation/manual/cancel/' + reservationId).then(
    (axiosRes) => axiosRes.data
  )
}
export const connectHostaway = ({ clientId, clientSecret }: { clientId: string; clientSecret: string }) => {
  return Api.post<ServerResponse>('integration-service/pms/connect', {
    clientId: Number(clientId),
    clientSecret,
    type: 'hostaway',
  }).then((axiosRes) => axiosRes.data)
}
export const connectGS = ({ clientId }: { clientId: string }) => {
  return Api.post<ServerResponse>('integration-service/pms/connect', {
    clientId,
    type: 'guestsmiles',
  }).then((axiosRes) => axiosRes.data)
}

export const connectPms = (bodyData: { clientId: string | number; clientSecret: string; type: string }) => {
  let newBody = { ...bodyData }
  if (bodyData.clientId && bodyData?.type === PMS_TYPE.HOSTAWAY) newBody.clientId = Number(bodyData.clientId)
  return Api.post<ServerResponse>('integration-service/pms/connect', {
    ...newBody,
  }).then((axiosRes) => axiosRes.data)
}

export const disconnectHostaway = () => {
  return Api.post<ServerResponse>('integration-service/pms/hostaway-disconnect').then((axiosRes) => axiosRes.data)
}

export const getAllPms = () => {
  return Api.get<ServerResponse>('integration-service/pms/get-all').then((axiosRes) => axiosRes.data)
}

export const getPmsIntegrated = () => {
  return Api.get<ServerResponse>('integration-service/pms/integrations').then((axiosRes) => axiosRes.data)
}

export const getStripeUrl = () => {
  // https://api.dataismist.com/paygate-service/stripe/host/connect
  return Api.get<ServerResponse>('/paygate-service/stripe/host/connect').then((axiosRes) => axiosRes.data)
}

export const connectStripe = (auth_code: string) => {
  return Api.post<ServerResponse>('/paygate-service/stripe/host/connect', { auth_code }).then(
    (axiosRes) => axiosRes.data
  )
}

export const getStripeStatus = () => {
  return Api.get<ServerResponse>('/paygate-service/stripe/host/status').then((axiosRes) => axiosRes.data)
}

export const disconnectStripe = (data: any) => {
  return Api.post<ServerResponse>('/paygate-service/stripe/host/disconnect', data).then((axiosRes) => axiosRes.data)
}

export const paymentMethodStripe = (status: boolean) => {
  return Api.post<ServerResponse>('/paygate-service/stripe/host/payment-method', { status }).then(
    (axiosRes) => axiosRes.data
  )
}

export const getPaymentMethodHost = () => {
  return Api.get<ServerResponse>('/paygate-service/payment/method/host').then((axiosRes) => axiosRes.data)
}

export const changePaymentMethodHost = (data: { id: string; status: boolean }) => {
  return Api.post<ServerResponse>('/paygate-service/payment/method', data).then((axiosRes) => axiosRes.data)
}

export const getPerformanceInfo = (listingId?: string | number) => {
  return Api.get<ServerResponse>('/activity-service/performance/reservation', { params: { listingId } }).then(
    (axiosRes) => axiosRes.data
  )
}

export const getTokenContract = (chainId: string | number) => {
  return Api.get<ServerResponse>(`/sc-service/token/${chainId}`).then((axiosRes) => axiosRes.data)
}

export const getConvertCurrency = (value: number | string, base: string, targets = 'usd') => {
  return Api.get<ServerResponse>('/paygate-service/currency/convert', {
    params: { value, base, targets },
  }).then((axiosRes) => axiosRes.data)
}

export const getPayoutReservation = (reservationId: string) => {
  return Api.post<ServerResponse>(`/reservation-service/reservation/payout/${reservationId}`).then(
    (axiosRes) => axiosRes.data
  )
}

export const disconnectPMS = (data: any) => {
  return Api.post<ServerResponse>('/integration-service/pms/disconnect', data).then((axiosRes) => axiosRes.data)
}

export const disconnectHospitable = (data: any) => {
  return Api.post<ServerResponse>('/integration-service/v1/hospitable/disconnect', data).then(
    (axiosRes) => axiosRes.data
  )
}

export const disconnectChannex = (data: any) => {
  return Api.post<ServerResponse>('integration-service/channex/v1/channex/disconnect', data).then(
    (axiosRes) => axiosRes.data
  )
}

export const getFeeOptions = () => {
  return Api.get<ServerResponse>('/paygate-service/payment/fee-options').then((axiosRes) => axiosRes.data)
}

export const updateFeeOptions = (isGuestPayFee: boolean) => {
  return Api.post<ServerResponse>('/paygate-service/payment/fee-options', { isGuestPayFee }).then(
    (axiosRes) => axiosRes.data
  )
}

// https://api.dataismist.com/integration-service/pms/request-pms
export const getRequestedPms = () => {
  return Api.get<ServerResponse>('/integration-service/pms/request-pms').then((axiosRes) => axiosRes.data)
}

export const updateRequestPms = (data: { name: string; email: string; requestedPms: string[] }) => {
  return Api.post<ServerResponse>('/integration-service/pms/request-pms', data).then((axiosRes) => axiosRes.data)
}

export const getRateCurrency = () => {
  return Api({
    method: 'get',
    url: 'paygate-service/currency/rates',
  }).then((res) => res.data)
}

export const getChannexConnectionLink = () => {
  return Api.get('/integration-service/channex/v1/channex/connection-link').then((res) => res.data)
}

export const connectHospitable = async (redirectPathName?: string) => {
  const payload: any = {}
  if (redirectPathName) {
    payload.redirectUrl = (window.location.origin + process.env.REACT_APP_BASE_PATH + redirectPathName).replace(
      'http://localhost:3000',
      'https://app.dataismist.com'
    )
  }
  let res = await Api.post('/integration-service/v1/hospitable/connect', payload)
  return await res.data
}
export const checkStatusHospitable = async (connectId: string | number, redirectPathName: string) => {
  const redirectUrl = (window.location.origin + process.env.REACT_APP_BASE_PATH + redirectPathName).replace(
    'http://localhost:3000',
    'https://app.dataismist.com'
  )
  let res = await Api.post('/integration-service/v1/hospitable/check-status', {
    id: Number(connectId),
    redirectUrl,
  })
  return await res.data
}

//https://api.dataismist.com/integration-service//v1/hospitable/connect

export const getListingByChannelId = async (channelId: string) => {
  const res = await Api.get(`/integration-service/channex/v1/channex/${channelId}/listings`)
  return res.data
}

export const getListingDetailByChannelId = async (channelId: string, id: string) => {
  const res = await Api.get(`/integration-service/channex/v1/channex/${channelId}/${id}/detail`)
  return res.data
}

// //https://api.dataismist.com/integration-service/v1/hospitable/properties-all
export const getListingHospitable = async (channelId?: string) => {
  const res = await Api.get('/integration-service/v1/hospitable/properties-all', {
    params: channelId ? { channelId } : {},
  })
  return res.data
}

// 'https://api.dataismist.com/integration-service/channex/v1/channex/d3c98e1a-b059-4b10-a13b-326b15ef3952/908260652784857102/detail'
// https://api.dataismist.com/integration-service/v1/hospitable/import
export const importHospitableAirbnb = async (data?: { selectedIds: Array<any>; channelId: string }) => {
  let axiosRes = await Api.post<ServerResponse>('/integration-service/v1/hospitable/import', data)
  return axiosRes.data
}

export const importAirbnb = ({ selectedIds }: { selectedIds: Array<string> }) => {
  return Api.post<ServerResponse>('/integration-service/channex/v1/channex/import-airbnb', {
    selectedIds,
  }).then((axiosRes) => axiosRes.data)
}

export const getChannexChannels = () => {
  return Api.get('/integration-service/channex/v1/channels').then((res) => res.data)
}
export const activeChannel = ({ channel, status }: { channel: string; status: boolean }) => {
  return Api.post<ServerResponse>('/integration-service/channex/v1/channels/active', {
    channel,
    status,
  }).then((axiosRes) => axiosRes.data)
}
export const registerHubspot = () => {
  return Api.post<ServerResponse>('/analysis-service/hubspot/visitor/create').then((axiosRes) => axiosRes.data)
}
export const makeDefaultSripe = (stripeId: string) => {
  return Api.post<ServerResponse>(`paygate-service/stripe/host/make-default/${stripeId}`).then(
    (axiosRes) => axiosRes.data
  )
}
