import { CurrencyItem } from '../../interfaces'
import { ListingItem } from '../../interfaces/listing'
import { RootState } from '../store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import { FocusedInputShape } from 'react-dates'

export interface CommonState {
  isLoading: boolean
  triggerTime: undefined | number
  walletAddress: string
  connecting: boolean
  toast: {
    duration?: number
    type?: string
    show: boolean
    title?: string
    message: string
  }
  triggerFetchListingProperty: number
  updateStripePayment: boolean
  isOpenFilterListingMobile: boolean
  checkIn: string | null
  checkOut: string | null
  adults: number
  children: number
  infants: number
  pets: number
  disabledDatesMap: any
  calendarDatesMap: any
  datePickerFocusedId: FocusedInputShape | null
  isOpenDatePicker: boolean
  isOpenSelectGuest: boolean
  minimumStay: number
  maximumStay: number
  listingData: ListingItem | null
  dateTime: moment.Moment | null
  durationHour: number
  expirationType: 'datetime' | 'duration'
  supportedCurrencies: CurrencyItem[]
  isSurveySubmitted: boolean
  ratesBaseOnUsd: any[]
  rateObjBaseOnUsd: any
  isOpenPopupViewDtravelApi: boolean
  connectDtravelApiType: string
  countPropertyFromHospitable: number
  isFinishProperyAllHospitable: boolean
}

const initialState: CommonState = {
  isLoading: false,
  triggerTime: 0,
  walletAddress: '',
  connecting: false,
  toast: {
    duration: 6000,
    type: 'error',
    show: false,
    title: 'Title',
    message: '',
  },
  triggerFetchListingProperty: 0,
  updateStripePayment: false,
  isOpenFilterListingMobile: false,
  checkIn: null,
  checkOut: null,
  adults: 0,
  children: 0,
  infants: 0,
  pets: 0,
  disabledDatesMap: new Map(),
  calendarDatesMap: new Map(),
  isOpenDatePicker: false,
  isOpenSelectGuest: false,
  datePickerFocusedId: 'startDate',
  minimumStay: 1,
  maximumStay: 365,
  listingData: null,
  dateTime: null,
  durationHour: 0,
  expirationType: 'datetime',
  supportedCurrencies: [],
  isSurveySubmitted: false,
  ratesBaseOnUsd: [],
  rateObjBaseOnUsd: {},
  isOpenPopupViewDtravelApi: false,
  connectDtravelApiType: '',
  countPropertyFromHospitable: 0,
  isFinishProperyAllHospitable: false,
}

export const commonSlices = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<any>) => {
      state.isLoading = action.payload
    },
    setTriggerTime: (state: RootState, action: PayloadAction<any>) => {
      state.triggerTime = action.payload
    },
    updateWalletAddress: (state: RootState, action: PayloadAction<string>) => {
      state.walletAddress = action.payload
    },
    setConnecting: (state, action: PayloadAction<boolean>) => {
      state.connecting = action.payload
    },
    setToast: (
      state,
      action: PayloadAction<{ type?: string; title?: string; duration?: number; show: boolean; message: string }>
    ) => {
      state.toast = action.payload
    },
    setToastError: (state, action: PayloadAction<{ title?: string; duration?: number; message: string }>) => {
      state.toast = { ...action.payload, type: 'error', show: true }
    },
    setToastSuccess: (state, action: PayloadAction<{ title?: string; duration?: number; message: string }>) => {
      state.toast = { ...action.payload, type: 'success', show: true }
    },
    setTriggerListingProperty: (state) => {
      state.triggerFetchListingProperty = new Date().getTime()
    },
    setUpdateStripePayment: (state, action: PayloadAction<boolean>) => {
      state.updateStripePayment = action.payload
    },
    setIsOpenFilterListingMobile: (state, action: PayloadAction<boolean>) => {
      state.isOpenFilterListingMobile = action.payload
    },

    // manual reservation
    setCheckIn: (state, action: PayloadAction<string | null>) => {
      state.checkIn = action.payload
    },
    setCheckOut: (state, action: PayloadAction<string | null>) => {
      state.checkOut = action.payload
    },
    setAdults: (state, action: PayloadAction<number>) => {
      state.adults = action.payload
    },
    setChildren: (state, action: PayloadAction<number>) => {
      state.children = action.payload
    },
    setInfants: (state, action: PayloadAction<number>) => {
      state.infants = action.payload
    },
    setPets: (state, action: PayloadAction<number>) => {
      state.pets = action.payload
    },
    setDisabledDatesMap: (state, action: PayloadAction<any>) => {
      state.disabledDatesMap = action.payload
    },
    setCalendarDatesMap: (state, action: PayloadAction<any>) => {
      state.calendarDatesMap = action.payload
    },
    setDatePickerFocusedId: (state, action: PayloadAction<FocusedInputShape | null>) => {
      state.datePickerFocusedId = action.payload
    },
    setIsOpenDatePicker: (state, action: PayloadAction<boolean>) => {
      state.isOpenDatePicker = action.payload
    },
    setIsOpenSelectGuest: (state, action: PayloadAction<boolean>) => {
      state.isOpenSelectGuest = action.payload
    },
    setMinimumStay: (state, action: PayloadAction<number>) => {
      state.minimumStay = action.payload
    },
    setMaximumStay: (state, action: PayloadAction<number>) => {
      state.maximumStay = action.payload
    },
    setListingData: (state, action: PayloadAction<ListingItem>) => {
      state.listingData = action.payload
    },
    setDateTime: (state, action: PayloadAction<moment.Moment | null>) => {
      state.dateTime = action.payload
    },
    setDurationHour: (state, action: PayloadAction<number>) => {
      state.durationHour = action.payload
    },
    setExpirationType: (state, action: PayloadAction<'datetime' | 'duration'>) => {
      state.expirationType = action.payload
    },
    setSupportedCurrencies: (state, action: PayloadAction<CurrencyItem[]>) => {
      state.supportedCurrencies = action.payload
    },
    setIsSurveySubmitted: (state, action: PayloadAction<boolean>) => {
      state.isSurveySubmitted = action.payload
    },
    setRatesBaseOnUsd: (state, action: PayloadAction<any[]>) => {
      state.ratesBaseOnUsd = action.payload
    },
    setRateObjBaseOnUsd: (state, action: PayloadAction<any>) => {
      state.rateObjBaseOnUsd = action.payload
    },
    setIsOpenPopupViewDtravelApi: (state, action: PayloadAction<boolean>) => {
      state.isOpenPopupViewDtravelApi = action.payload
    },
    setConnectDtravelApiType: (state, action: PayloadAction<string>) => {
      state.connectDtravelApiType = action.payload
    },
    setCountPropertyFromHospitable: (state, action: PayloadAction<number>) => {
      state.countPropertyFromHospitable = action.payload
    },
    setIsFinishProperyAllHospitable: (state, action: PayloadAction<boolean>) => {
      state.isFinishProperyAllHospitable = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setTriggerTime,
  updateWalletAddress,
  setConnecting,
  setToast,
  setToastError,
  setToastSuccess,
  setTriggerListingProperty,
  setUpdateStripePayment,
  setIsOpenFilterListingMobile,
  setCheckIn,
  setCheckOut,
  setAdults,
  setChildren,
  setInfants,
  setPets,
  setDisabledDatesMap,
  setCalendarDatesMap,
  setDatePickerFocusedId,
  setIsOpenDatePicker,
  setIsOpenSelectGuest,
  setMinimumStay,
  setMaximumStay,
  setListingData,
  setDateTime,
  setDurationHour,
  setExpirationType,
  setSupportedCurrencies,
  setIsSurveySubmitted,
  setRatesBaseOnUsd,
  setRateObjBaseOnUsd,
  setIsOpenPopupViewDtravelApi,
  setConnectDtravelApiType,
  setCountPropertyFromHospitable,
  setIsFinishProperyAllHospitable,
} = commonSlices.actions

export default commonSlices.reducer
