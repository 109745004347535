import { getMinimumStay, updateMinimumStay } from '../../../api/listings'
import { handleErrorMessage, isEmpty } from '../../../utils/common'
import AdvanceNotice from './AdvanceNotice'
import AvailabilityWindow from './AvailabilityWindow'
import MinimumStayDateRange from './MinimumStayDateRange'
import MinimumStayGlobal from './MinimumStayGlobal'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { updateBulkProperties, updateProperty } from 'src/api/native-listing'
import DisabledSectionOnEdit from 'src/components/common/DisabledSectionOnEdit'
import NativeListingBackAndContinue from 'src/components/native-listing/NativeListingBackAndContinue'
import BlockUnavailableDates from 'src/components/native-listing/availability/BlockUnavailableDates'
import { NATIVE_LISTING_MENU, PMS_TYPE, TYPE_EDIT_LISTINGS } from 'src/contants/common'
import { MIN_NIGHTS, MAX_NIGHTS, PROPERTY_STATUS } from 'src/contants/native'
import { PropertyDetailProps } from 'src/interfaces/listing'
import { useAppSelector } from 'src/redux/hooks'
import { setLoading, setToastSuccess } from 'src/redux/slices/common'
import { setBulkListingData, setBulkListingSection } from 'src/redux/slices/listing'
import { isChannexAirbnb, isPropertyAirbnb } from 'src/utils/property'

interface Props {
  propertyId: string
  listingData: PropertyDetailProps | null
  currentMinNights?: number
  currentMaxNights?: number
  fetchStatusStep: any
}

const NativeListingAvailability: React.FC<Props> = ({ propertyId, listingData, fetchStatusStep }) => {
  const isDraft = listingData?.status === PROPERTY_STATUS.DRAFT
  const isAirbnb = isPropertyAirbnb(listingData)
  const isChannex = isChannexAirbnb(listingData)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isOnboarding = parseQuery?.f === 'onboarding'
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const { isLoading } = useAppSelector((state) => state.common)
  const { bulkListingData, bulkListingSection } = useAppSelector((state) => state.listing)
  const [minNights, setMinNights] = useState<number>(1)
  const [dateRanges, setDateRanges] = useState<any[]>([])
  const [dateRangesBlock, setDateRangesBlock] = useState<any[]>([])
  const [advanceNotice, setAdvanceNotice] = useState<number | null | undefined>(0)
  const [availabilityWindow, setAvailabilityWindow] = useState<number | null | undefined>(0)
  const [toggleEdit, setToggleEdit] = useState<any>({ availability: false, minimumStay: false, blockDates: false })
  const isHospitable = listingData?.pmsType === PMS_TYPE.HOSPITABLE
  useEffect(() => {
    if (propertyId) {
      fetchMinimumStay(propertyId)
    }
  }, [propertyId])

  useEffect(() => {
    if (listingData) {
      if (isHospitable) {
        setAdvanceNotice(listingData.advanceNotice)
        setAvailabilityWindow(listingData.availabilityWindow)
      } else {
        setAdvanceNotice(listingData.advanceNotice ?? 0)
        setAvailabilityWindow(listingData.availabilityWindow ?? 730)
      }
    }
  }, [listingData, isHospitable])

  useEffect(() => {
    if (isEditBulk) {
      setMinNights(bulkListingData?.minNights || -1)
      setDateRanges(bulkListingData?.dateRanges || [])
      setAdvanceNotice(bulkListingData?.advanceNotice || -1)
      setAvailabilityWindow(bulkListingData?.availabilityWindow || -1)
    }
  }, [bulkListingData])

  const updateDateRangesBlock = (newDateRanges: any[]) => {
    setDateRangesBlock(newDateRanges)
  }

  async function fetchMinimumStay(_propertyId: string) {
    try {
      const res = await getMinimumStay(_propertyId)
      if (res.success) {
        setMinNights(res.data.minNights)
        setDateRanges(Array.isArray(res.data.dateRanges) ? res.data.dateRanges : [])
      }
    } catch (err: any) {
      console.log(err)
    }
  }

  const handleSubmit = async (isExit?: boolean) => {
    try {
      dispatch(setLoading(true))
      // update minNights global
      if (!isHospitable && !isAirbnb && !isEditBulk && !isChannex) {
        await updateMinimumStay({ listingId: Number(propertyId), minNights })
      }
      let res: any = null
      if (isEditBulk) {
        if (toggleEdit.availability || toggleEdit.minimumStay || toggleEdit.blockDates) {
          const propertyIds = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
          let dataDTO: any = { propertyIds }
          if (toggleEdit.availability) {
            dataDTO = {
              ...dataDTO,
              propertyInfo: {
                advanceNotice: advanceNotice === -1 ? null : advanceNotice,
                availabilityWindow: availabilityWindow === -1 ? null : availabilityWindow,
              },
            }
          }
          if (isEditBulk && toggleEdit.minimumStay && minNights !== -1) {
            const insertItem = !isEmpty(dateRanges) ? dateRanges[0] : null
            let minNightInfo: any = { minNights, dateRanges: !isEmpty(dateRanges) ? dateRanges : [] }
            if (insertItem) minNightInfo = { ...minNightInfo, insert: insertItem }
            dataDTO = { ...dataDTO, minNightInfo }
          }
          dispatch(setBulkListingData({ ...bulkListingData, minNights, dateRanges, advanceNotice, availabilityWindow }))
          res = await updateBulkProperties(dataDTO)
          setToggleEdit({ availability: false, minimumStay: false, blockDates: false })
        }
      } else {
        let bodyData: any = { maxNights: 90, advanceNotice, availabilityWindow }
        if (isDraft) bodyData.settingSteps = 8
        res = await updateProperty(propertyId, bodyData)
      }
      if (res?.data) {
        if (isExit) navigate(isOnboarding ? '/' : `/listings`)
        else if (isDraft) navigate(`/native-listing/${NATIVE_LISTING_MENU.ICAL}` + search)
        else dispatch(setToastSuccess({ message: 'Your changes have been applied.' }))
        fetchStatusStep()
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const onChangeMinNights = (type: 'plus' | 'minus') => {
    if (type === 'plus') {
      setMinNights((prevState) => (prevState === MAX_NIGHTS ? MAX_NIGHTS : prevState === -1 ? 1 : prevState + 1))
    } else {
      setMinNights((prevState) => (prevState === MIN_NIGHTS ? MIN_NIGHTS : prevState - 1))
    }
  }

  const onChangeAdvanceNotice = (type: 'plus' | 'minus') => {
    if (type === 'plus') {
      setAdvanceNotice((prevState) => {
        if (prevState !== null && prevState !== undefined) {
          return prevState === -1 ? 1 : prevState + 1
        }
        return 1
      })
    } else {
      setAdvanceNotice((prevState) => {
        if (prevState !== null && prevState !== undefined) {
          return prevState === 0 ? 0 : prevState - 1
        }
        return 0
      })
    }
  }

  const onChangeAvailabilityWindow = (value: number) => {
    setAvailabilityWindow(Number(value))
  }

  return (
    <>
      <div className="flex flex-col gap-8">
        <p className="text-28-36 text-neutral-800 font-maison-neue tracking-[-0.01em]">Availability & Length of Stay</p>
        <DisabledSectionOnEdit
          title="Availability"
          isDisable={isHospitable}
          openEdit={toggleEdit.availability}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, availability: value })}
        >
          <div className={'p-[24px] bg-white w-full rounded-[16px]'}>
            {/*---Availability window---*/}
            <AvailabilityWindow
              availabilityWindow={availabilityWindow}
              onChange={onChangeAvailabilityWindow}
              isHospitable={isHospitable}
            />
            <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
            {/*---Advance notice---*/}
            <AdvanceNotice advanceNotice={advanceNotice} onChange={onChangeAdvanceNotice} isHospitable={isHospitable} />
          </div>
        </DisabledSectionOnEdit>

        <DisabledSectionOnEdit
          title="Length of stay"
          subTitle="Define the minimum number of nights guest have to stay"
          isDisable={isHospitable}
          disabledEdit={isChannex || (isEditBulk && parseQuery?.native === TYPE_EDIT_LISTINGS.CHANNEX)}
          openEdit={toggleEdit.minimumStay}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, minimumStay: value })}
        >
          {!isHospitable && !isAirbnb && (
            <div className={'p-[24px] bg-white w-full rounded-[16px]'}>
              <MinimumStayGlobal minNights={minNights} onChangeMinNights={onChangeMinNights} />
              <div className="w-full h-[1px] bg-neutral-300 my-6" />
              <MinimumStayDateRange
                propertyId={propertyId}
                dateRanges={dateRanges}
                updateDateRanges={(newDateRanges: any[]) => {
                  setDateRanges(newDateRanges)
                }}
                fetchMinimumStay={fetchMinimumStay}
                globalMinNights={minNights}
                fetchStatusStep={fetchStatusStep}
                handleCloseEdit={() => setToggleEdit({ availability: false, minimumStay: false, blockDates: false })}
              />
            </div>
          )}
        </DisabledSectionOnEdit>
        <DisabledSectionOnEdit
          title="Block unavailable dates"
          subTitle="Keep your calendar updated and avoid double bookings by blocking dates manually. You can also do this later."
          isDisable={isHospitable}
          openEdit={toggleEdit.blockDates}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, blockDates: value })}
        >
          <div className="p-6 bg-white w-full rounded-[16px]">
            <BlockUnavailableDates
              dateRanges={dateRangesBlock}
              updateDateRanges={updateDateRangesBlock}
              propertyId={propertyId}
              hasPaddingItem16px
              fetchStatusStep={fetchStatusStep}
              handleCloseEdit={() => setToggleEdit({ availability: false, minimumStay: false, blockDates: false })}
            />
          </div>
        </DisabledSectionOnEdit>
      </div>

      {/*--- Footer: Back and Preview ---*/}
      <NativeListingBackAndContinue
        isLoading={isLoading}
        onSubmit={handleSubmit}
        isEdit={!isDraft}
        isDisabledSubmit={isEditBulk ? !(toggleEdit.availability || toggleEdit.minimumStay || toggleEdit.blockDates) : false}
      />
      <div
        id={`${NATIVE_LISTING_MENU.AVAILABILITY}_save_exit`}
        className="hidden"
        onClick={() => handleSubmit(true)}
        role="presentation"
      />
      <div
        id={`${NATIVE_LISTING_MENU.AVAILABILITY}_save`}
        className="hidden"
        onClick={() => {
          if (isEditBulk) {
            if (
              isEmpty(bulkListingSection) &&
              (toggleEdit.availability || toggleEdit.minimumStay || toggleEdit.blockDates)
            ) {
              let result: string[] = []
              if (toggleEdit.availability) result.push('Availability')
              if (toggleEdit.minimumStay) result.push('Length of stay')
              if (toggleEdit.blockDates) result.push('Block unavailable dates')
              dispatch(setBulkListingSection(result.join(', ')))
            } else handleSubmit()
          } else handleSubmit()
        }}
        role="presentation"
      />
    </>
  )
}

export default NativeListingAvailability
