import { SYNC_STATUS } from '../../contants/property'
import { getNameByCountryCode } from '../../utils/common'
import { IcChervonRight } from '../common/Icons'
import CircularProgress from '@mui/material/CircularProgress'
import clsx from 'clsx'
import React from 'react'
import ic_checked_active_24_24 from 'src/assets/icons/ic_checked_active_24_24.svg'

interface Props {
  item: any
  onClickEdit?: (data: any) => void
}

const RenderListingItem: React.FC<Props> = ({ item, onClickEdit }) => {
  const renderAddress = (address: any) => {
    if (address) {
      if (typeof address === 'string') {
        return address
      }
      const { state, country_code } = address
      const countryName = getNameByCountryCode(country_code)
      return [state, countryName || country_code].join(', ')
    }
    return ''
  }
  const renderThumbImage = (images: any) => {
    if (Array.isArray(images) && images.length > 0) {
      return (
        <img
          src={images[0]?.thumbnail_url || images[0]?.url || images[0]}
          alt={images[0].caption}
          className={'object-cover w-full h-full rounded-[8px]'}
        />
      )
    }
    return ''
  }

  const renderEditBtn = (item: any) => {
    if (!item.status || item.status === SYNC_STATUS.PENDING) {
      return <CircularProgress size={20} style={{ color: '#0B2336' }} />
    }
    if (item.status === SYNC_STATUS.FAIL || item.status === SYNC_STATUS.IGNORED) {
      return <span className={'uppercase'}>{item.status}</span>
    }
    if (item.status === SYNC_STATUS.DONE) {
      return (
        <>
          <div className={`min-w-[24px] ${item.isSaved ? '' : 'hidden'}`}>
            <img src={ic_checked_active_24_24} alt={'ic_checked'} />
          </div>
          <button
            className={clsx(
              'h-[36px] flex items-center gap-[8px] bg-white',
              'border border-neutral-300',
              'px-[14px] rounded-[100px]',
              'hover:bg-neutral-200'
            )}
            onClick={() => {
              if (typeof onClickEdit === 'function') {
                onClickEdit(item)
              }
            }}
          >
            <span>Edit</span>
            <span>
              <IcChervonRight />
            </span>
          </button>
        </>
      )
    }
    return <></>
  }

  return (
    <div className={clsx('flex items-center gap-[12px] p-[16px] bg-white', 'border-b border-neutral-300')}>
      <div className={'w-[40px] h-[40px] rounded-[8px]'}>{renderThumbImage(item.images)}</div>
      <div className={'text-left'}>
        <p className={'font-maison-neue-medium text-16-20 text-neutral-800 line-clamp-1'}>
          {item.publicName || item.privateName}
        </p>
        <p className={'font-maison-neue text-14-18 text-neutral-600'}>{renderAddress(item.address)}</p>
      </div>
      {typeof onClickEdit === 'function' && (
        <div className={'ml-auto flex items-center gap-[12px]'}>{renderEditBtn(item)}</div>
      )}
    </div>
  )
}

export default RenderListingItem
