import React from 'react'

const LoadingSpinner = () => {
  // return (
  //   <div className="lds-spinner">
  //     <div></div>
  //     <div></div>
  //     <div></div>
  //     <div></div>
  //     <div></div>
  //     <div></div>
  //     <div></div>
  //     <div></div>
  //     <div></div>
  //     <div></div>
  //     <div></div>
  //     <div></div>
  //   </div>
  // )
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      width={20}
      height={20}
      style={{
        shapeRendering: 'auto',
        display: 'block',
        background: 'transparent',
      }}
    >
      <g>
        <g transform="rotate(0 50 50)">
          <rect fill="#ffffff" height={27} width={6} ry="5.94" rx={3} y="0.5" x={47}>
            <animate
              repeatCount="indefinite"
              begin="-1.234567901234568s"
              dur="1.3888888888888888s"
              keyTimes="0;1"
              values="1;0"
              attributeName="opacity"
            />
          </rect>
        </g>
        <g transform="rotate(40 50 50)">
          <rect fill="#ffffff" height={27} width={6} ry="5.94" rx={3} y="0.5" x={47}>
            <animate
              repeatCount="indefinite"
              begin="-1.080246913580247s"
              dur="1.3888888888888888s"
              keyTimes="0;1"
              values="1;0"
              attributeName="opacity"
            />
          </rect>
        </g>
        <g transform="rotate(80 50 50)">
          <rect fill="#ffffff" height={27} width={6} ry="5.94" rx={3} y="0.5" x={47}>
            <animate
              repeatCount="indefinite"
              begin="-0.925925925925926s"
              dur="1.3888888888888888s"
              keyTimes="0;1"
              values="1;0"
              attributeName="opacity"
            />
          </rect>
        </g>
        <g transform="rotate(120 50 50)">
          <rect fill="#ffffff" height={27} width={6} ry="5.94" rx={3} y="0.5" x={47}>
            <animate
              repeatCount="indefinite"
              begin="-0.771604938271605s"
              dur="1.3888888888888888s"
              keyTimes="0;1"
              values="1;0"
              attributeName="opacity"
            />
          </rect>
        </g>
        <g transform="rotate(160 50 50)">
          <rect fill="#ffffff" height={27} width={6} ry="5.94" rx={3} y="0.5" x={47}>
            <animate
              repeatCount="indefinite"
              begin="-0.617283950617284s"
              dur="1.3888888888888888s"
              keyTimes="0;1"
              values="1;0"
              attributeName="opacity"
            />
          </rect>
        </g>
        <g transform="rotate(200 50 50)">
          <rect fill="#ffffff" height={27} width={6} ry="5.94" rx={3} y="0.5" x={47}>
            <animate
              repeatCount="indefinite"
              begin="-0.462962962962963s"
              dur="1.3888888888888888s"
              keyTimes="0;1"
              values="1;0"
              attributeName="opacity"
            />
          </rect>
        </g>
        <g transform="rotate(240 50 50)">
          <rect fill="#ffffff" height={27} width={6} ry="5.94" rx={3} y="0.5" x={47}>
            <animate
              repeatCount="indefinite"
              begin="-0.308641975308642s"
              dur="1.3888888888888888s"
              keyTimes="0;1"
              values="1;0"
              attributeName="opacity"
            />
          </rect>
        </g>
        <g transform="rotate(280 50 50)">
          <rect fill="#ffffff" height={27} width={6} ry="5.94" rx={3} y="0.5" x={47}>
            <animate
              repeatCount="indefinite"
              begin="-0.154320987654321s"
              dur="1.3888888888888888s"
              keyTimes="0;1"
              values="1;0"
              attributeName="opacity"
            />
          </rect>
        </g>
        <g transform="rotate(320 50 50)">
          <rect fill="#ffffff" height={27} width={6} ry="5.94" rx={3} y="0.5" x={47}>
            <animate
              repeatCount="indefinite"
              begin="0s"
              dur="1.3888888888888888s"
              keyTimes="0;1"
              values="1;0"
              attributeName="opacity"
            />
          </rect>
        </g>
        <g />
      </g>
      {/* [ldio] generated by https://loading.io */}
    </svg>
  )
}

export default LoadingSpinner
