import no_image from '../../assets/images/no_image_listing.jpg'
import { DATE_FORMAT, MONTH_FORMAT, PMS_TYPE } from '../../contants/common'
import { CalendarItem, ListingItem } from '../../interfaces/listing'
import { convertPriceWithCurrency } from '../../utils/common'
import { renderChannelLogo } from '../../utils/property'
import RenderSyncStatus from './RenderSyncStatus'
import TablePaging from './TablePaging'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import IconButton from '@mui/material/IconButton'
import clsx from 'clsx'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  properties: ListingItem[]
  paging: { page: number; pageSize: number; total: number; totalPages: number }
  handleChangeSearchAndPaging: (field: string, value: string) => void
  handleResetFilters: () => void
  currentMonth: string
}

const WEEKDAYS = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']

const TableListingCalendar: React.FC<Props> = ({ paging, handleChangeSearchAndPaging, properties, currentMonth }) => {
  const headTableRef = useRef<any>(null)
  const bodyTableRef = useRef<any>(null)
  const [calendarData, setCalendarData] = useState<any>({})

  const isCalendarBlocked = (calendarItem: CalendarItem, isHostaway?: boolean) => {
    if (calendarItem) {
      const { status, reason } = calendarItem
      return (
        status === 'blocked' ||
        (status === 'unavailable' && reason !== 'reservation' && !isHostaway) ||
        (status === 'unavailable' && reason !== 'reserved' && isHostaway)
      )
    }
    return false
  }

  const isCalendarBooked = (calendarItem: CalendarItem, isHostaway?: boolean) => {
    if (calendarItem) {
      const { status, reason } = calendarItem
      return (
        status === 'reserved' ||
        (status === 'unavailable' && reason === 'reservation') ||
        (isHostaway && status === 'unavailable' && reason === 'reserved')
      )
    }
    return false
  }

  useEffect(() => {
    let obj: any = {}
    if (Array.isArray(properties) && properties.length > 0) {
      for (let property of properties) {
        const calendar = property.calendar || []
        const isHostaway = property.pmsType === PMS_TYPE.HOSTAWAY
        const calendarObj: any = {}
        for (let i = 0; i < calendar.length; i++) {
          const cal: any = { ...calendar[i] }
          const isBlocked = isCalendarBlocked(cal, isHostaway)
          const isBooked = isCalendarBooked(cal, isHostaway)
          let isStartBlocked = false
          let isEndBlocked = false
          let isStartBooked = false
          let isEndBooked = false
          if (isBlocked || isBooked) {
            // check before 1 day this date
            if (i === 0) {
              isStartBlocked = true
              isStartBooked = true
            } else {
              const calendarBefore1Day = calendar[i - 1]
              const isBefore1DayBlocked = isCalendarBlocked(calendarBefore1Day, isHostaway)
              const isBefore1DayBooked = isCalendarBooked(calendarBefore1Day, isHostaway)
              if (isBlocked) {
                isStartBlocked = !isBefore1DayBlocked
              }
              if (isBooked) {
                isStartBooked = !isBefore1DayBooked
              }
            }
            // check after 1 day this date
            if (i === calendar.length - 1) {
              isEndBlocked = true
              isEndBooked = true
            } else {
              const calendarAfter1Day = calendar[i + 1]
              const isAfter1DayBlocked = isCalendarBlocked(calendarAfter1Day, isHostaway)
              const isAfter1DayBooked = isCalendarBooked(calendarAfter1Day, isHostaway)
              if (isBlocked) {
                isEndBlocked = !isAfter1DayBlocked
              }
              if (isBooked) {
                isEndBooked = !isAfter1DayBooked
              }
            }
          }
          cal.isBlocked = isBlocked
          cal.isBooked = isBooked
          cal.isStartBlocked = isStartBlocked
          cal.isStartBooked = isStartBooked
          cal.isEndBlocked = isEndBlocked
          cal.isEndBooked = isEndBooked
          calendarObj[cal.date] = cal
        }
        obj[property.id.toString()] = {
          id: property.id,
          name: property.externalName,
          calendarByDate: calendarObj,
        }
      }
    }
    setCalendarData(obj)
  }, [properties, currentMonth])

  const getDaysByMonth = (monthStr: string) => {
    const isCurrentMonth = moment().format(MONTH_FORMAT) === monthStr
    const startDate = isCurrentMonth ? moment() : moment(monthStr, MONTH_FORMAT).startOf('month')
    const endDate = isCurrentMonth ? moment().add(1, 'month') : moment(monthStr, MONTH_FORMAT).endOf('month')
    const diffDays = endDate.diff(startDate, 'days')
    let arr = []
    for (let i = 0; i <= diffDays; i++) {
      const momentDate = moment(startDate).add(i, 'days').startOf('days')
      arr.push({
        dateFormated: momentDate.format(DATE_FORMAT),
        momentDate,
        isPastDay: momentDate.isBefore(moment().startOf('days')),
      })
    }
    return arr
  }

  // eslint-disable-next-line
  const handleHeaderScrollX = (event: any) => {
    const element = event.target as HTMLDivElement
    bodyTableRef.current.scrollLeft = element.scrollLeft
  }

  const handleBodyScrollX = (event: any) => {
    const element = event.target as HTMLDivElement
    headTableRef.current.scrollLeft = element.scrollLeft
  }

  return (
    <div className={clsx('font-maison-neue-medium text-14-18 text-neutral-600 relative', '')}>
      <div
        className={clsx(
          'flex items-center justify-between gap-[16px]',
          'w-full h-[52px] px-[16px]',
          'bg-neutral-50 rounded-tl-[16px] rounded-tr-[16px]',
          'border-b-[0.5px] border-[#00000026]',
          'sticky top-[80px] bg-white z-[13]'
        )}
      >
        <div className={'flex items-center gap-[8px]'}>
          <IconButton
            size={'small'}
            disabled={currentMonth === moment().format(MONTH_FORMAT)}
            onClick={() => {
              handleChangeSearchAndPaging(
                'month',
                moment(currentMonth, MONTH_FORMAT).add(-1, 'month').format(MONTH_FORMAT)
              )
              bodyTableRef.current.scrollLeft = 0
            }}
          >
            <KeyboardArrowLeftIcon fontSize={'small'} />
          </IconButton>
          <span>{moment(currentMonth, MONTH_FORMAT).add(-1, 'month').format('MMMM YYYY')}</span>
        </div>
        <div className={'flex items-center gap-[8px]'}>
          <span>{moment(currentMonth, MONTH_FORMAT).add(1, 'month').format('MMMM YYYY')}</span>
          <IconButton
            size={'small'}
            onClick={() => {
              handleChangeSearchAndPaging(
                'month',
                moment(currentMonth, MONTH_FORMAT).add(1, 'month').format(MONTH_FORMAT)
              )
              bodyTableRef.current.scrollLeft = 0
            }}
          >
            <KeyboardArrowRightIcon fontSize={'small'} />
          </IconButton>
        </div>
      </div>

      {/* ---Header--- */}
      <div className={'flex bg-white sticky top-[132px] z-[13] h-[52px]'}>
        <div className={'w-[320px] lg:w-[472px] min-w-[320px] lg:min-w-[472px]'}>
          <div
            className={clsx(
              'h-[52px] px-[16px] flex items-center w-full shadow-drop',
              'border-b-[0.5px] border-[#00000026]',
              'customStickyColumn'
            )}
          >
            <span className={'font-maison-neue-medium text-12-16 text-neutral-600'}>Listing name</span>
          </div>
        </div>
        <div
          ref={headTableRef}
          // className={clsx('flex box-border', 'overflow-x-auto', 'mb-[-8px]')}
          className={clsx('flex box-border', 'overflow-x-auto', 'hidden-scroll-bar')}
          onScroll={handleHeaderScrollX}
        >
          {getDaysByMonth(currentMonth).map((item) => {
            return (
              <div
                key={item.dateFormated}
                className={clsx(
                  'w-[88px] min-w-[88px] h-full',
                  'flex flex-col items-center justify-center',
                  'border-b-[0.5px] border-[#00000026]',
                  ''
                )}
              >
                <span className={'font-maison-neue text-12-16 text-neutral-500 tracking-[4%]'}>
                  {WEEKDAYS[item.momentDate.weekday()]}
                </span>
                <span className={'font-maison-neue-medium text-14-18 text-neutral-600'}>
                  {item.momentDate.format('MMM DD')}
                </span>
              </div>
            )
          })}
        </div>
      </div>

      {/* ---Container--- */}
      <div className={'flex bg-white'}>
        <div className={'w-[320px] lg:w-[472px] min-w-[320px] lg:min-w-[472px]'}>
          {properties.map((property) => {
            return (
              <div
                key={property.id}
                className={clsx(
                  'flex items-center gap-[16px] px-[16px] h-[80px]',
                  'border-b-[0.5px] border-[#00000026]',
                  'customStickyColumn'
                )}
              >
                <Link to={`/listings/${property.id}`} className={'w-auto flex-1'}>
                  <div className={'flex items-center gap-[12px]'}>
                    <div>
                      <img
                        src={property?.thumbnail || no_image}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src = no_image
                        }}
                        alt="thumb"
                        className="rounded-[8px] min-w-[40px] w-[40px] h-[40px] object-cover"
                      />
                    </div>
                    <div>
                      <div
                        className={`hover:underline line-clamp-2 font-maison-neue-medium text-neutral-900 text-14-18`}
                        data-hover={property.externalName}
                      >
                        {property.externalName}
                      </div>
                      <div className={'hover:underline line-clamp-1 text-14-18'}>{property.internalName}</div>
                    </div>
                  </div>
                </Link>

                <div className="flex items-center gap-1">
                  <div className="w-4 h-4 rounded-[50%] flex items-center justify-center">
                    <img src={renderChannelLogo(property.channel)} alt="" className="w-full h-full" />
                  </div>
                  <div className={'mt-[2px] flex gap-[8px] items-center'}>
                    {/*<div>{renderSourceType(property.channel)}</div>*/}
                    <div className={''}>
                      <RenderSyncStatus property={property} />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        {/* ---Right Content: Calendar--- */}
        <div ref={bodyTableRef} className={'overflow-x-auto'} onScroll={handleBodyScrollX}>
          {properties.map((property) => {
            const calendarByDate = calendarData[property.id]?.calendarByDate
            if (!calendarByDate) {
              return <></>
            }
            return (
              <div key={property.id} className={'flex h-[80px] box-border'}>
                {getDaysByMonth(currentMonth).map((item) => {
                  const thisDate = calendarByDate[item.dateFormated]
                  if (thisDate) {
                    const { isBlocked, isBooked, isStartBlocked, isStartBooked, isEndBlocked, isEndBooked } = thisDate
                    return (
                      <div
                        key={item.dateFormated}
                        className={clsx(
                          'w-[88px] min-w-[88px] h-full flex flex-col items-center justify-center',
                          'border-b-[0.5px] border-[#00000026]',
                          {
                            'pl-[16px]': (isStartBlocked && !isEndBlocked) || (isStartBooked && !isEndBooked),
                            'pr-[16px]': (!isStartBlocked && isEndBlocked) || (!isStartBooked && isEndBooked),
                            'px-[8px]': (isStartBlocked && isEndBlocked) || (isStartBooked && isEndBooked),
                          }
                        )}
                      >
                        {isBlocked && (
                          <div
                            className={clsx(
                              'w-full h-[52px] flex items-center justify-center',
                              'bg-neutral-200 font-maison-neue text-neutral-500',
                              {
                                'rounded-l-[8px]': isStartBlocked,
                                'rounded-r-[8px]': isEndBlocked,
                              }
                            )}
                          >
                            {isStartBlocked && 'Blocked'}
                          </div>
                        )}
                        {isBooked && (
                          <div
                            className={clsx(
                              'w-full h-[52px] flex items-center justify-center',
                              'bg-ocean-50 font-maison-neue text-ocean-700',
                              {
                                'rounded-l-[8px]': isStartBooked,
                                'rounded-r-[8px]': isEndBooked,
                              }
                            )}
                          >
                            {isStartBooked && 'Booked'}
                          </div>
                        )}
                        {!isBlocked && !isBooked && (
                          <div className={'font-maison-neue text-neutral-600'}>
                            {thisDate && (
                              <>{convertPriceWithCurrency(thisDate.price, property.propertyPrice?.currency, true)}</>
                            )}
                          </div>
                        )}
                      </div>
                    )
                  }
                  // case calendar NULL
                  return (
                    <div
                      key={item.dateFormated}
                      className={clsx(
                        'w-[88px] min-w-[88px] h-full flex flex-col items-center justify-center',
                        'border-b-[0.5px] border-[#00000026]'
                      )}
                    />
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>

      <TablePaging
        total={paging.total}
        page={paging.page}
        pageSize={paging.pageSize}
        totalPages={paging.totalPages}
        handleChangeSearchAndPaging={handleChangeSearchAndPaging}
      />
    </div>
  )
}

export default TableListingCalendar
