import { setToastSuccess } from '../../redux/slices/common'
import { getCountryCodeFromName, handleErrorMessage, isEmailValid, isPostalCode } from '../../utils/common'
import { countries } from '../../utils/country'
import BasicSelect from '../ui/BasicSelect'
import React, { useEffect, useRef, useState } from 'react'
import { superHogCreateListing } from 'src/api/listings'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import BasicInput from 'src/components/ui/BasicInput'
import { FormError } from 'src/interfaces'
import { Address, SuperHogCreateListing } from 'src/interfaces/listing'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'

interface Props {
  propertyId: string
  isOpen: boolean
  onClose: () => void
  externalName: string
  address: Address
  superHogStatus: string
  onChangeStatus: (_status: string) => void
}

interface FieldsError {
  friendlyName?: FormError
  addressLine1?: FormError
  town: FormError
  postcode: FormError
  countryIso?: FormError
  hostFirstName: FormError
  hostLastName: FormError
  hostEmail: FormError
}

const REQUIRED_FIELDS = ['town', 'postcode', 'hostFirstName', 'hostLastName', 'hostEmail']

const FIELDS: Array<{
  label: string
  field: keyof SuperHogCreateListing
  editable: boolean
  placeholder?: string
}> = [
  {
    label: 'Listing name',
    field: 'friendlyName',
    editable: false,
  },
  {
    label: 'Listing’s address',
    field: 'addressLine1',
    editable: false,
  },
  {
    label: 'City',
    field: 'town',
    editable: true,
    placeholder: 'Please enter your listing’s city',
  },
  {
    label: 'Postal code',
    field: 'postcode',
    editable: true,
    placeholder: 'Please enter your listing’s postal code',
  },
  {
    label: 'Country',
    field: 'countryIso',
    editable: true,
    placeholder: 'Please enter your listing’s country',
  },
  {
    label: 'Your first name',
    field: 'hostFirstName',
    editable: true,
    placeholder: 'Please enter your last name',
  },
  {
    label: 'Your last name',
    field: 'hostLastName',
    editable: true,
    placeholder: 'Please enter your first name',
  },
  {
    label: 'Email',
    field: 'hostEmail',
    editable: true,
    placeholder: 'Please enter your email',
  },
]

const PopupInfoSupertHog: React.FC<Props> = ({
  propertyId,
  isOpen,
  onClose,
  externalName,
  address,
  superHogStatus,
  onChangeStatus,
}) => {
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector((state) => state.user)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<SuperHogCreateListing>({
    listingId: Number(propertyId),
    friendlyName: '',
    hostFirstName: '',
    hostLastName: '',
    hostEmail: '',
    addressLine1: '',
    addressLine2: '',
    town: '',
    postcode: '',
    countryIso: '',
    superHogStatus: superHogStatus,
  })
  const [errors, setErrors] = useState<FieldsError>({
    hostFirstName: { show: false, message: '' },
    hostLastName: { show: false, message: '' },
    hostEmail: { show: false, message: '' },
    postcode: { show: false, message: '' },
    town: { show: false, message: '' },
  })
  const refCity = useRef<HTMLInputElement>(null)
  const refPostcode = useRef<HTMLInputElement>(null)
  const refCountryIso = useRef<HTMLSelectElement>(null)
  const refFirstName = useRef<HTMLInputElement>(null)
  const refLastName = useRef<HTMLInputElement>(null)
  const refEmail = useRef<HTMLInputElement>(null)
  // const isCityVisible = useOnScreen(refCity)
  // const isPostcodeVisible = useOnScreen(refPostcode)
  // const isCountryIsoVisible = useOnScreen(refCountryIso)
  // const isFirstNameVisible = useOnScreen(refFirstName)
  // const isLastNameVisible = useOnScreen(refLastName)
  // const isEmailVisible = useOnScreen(refEmail)

  useEffect(() => {
    const countryIso = address.countryCode || getCountryCodeFromName(address.country)
    setData((prevState) => ({
      ...prevState,
      friendlyName: externalName,
      addressLine1: address.address,
      countryIso: countryIso,
      postcode: address.zipcode,
      town: address.city,
      hostFirstName: profile?.firstName || '',
      hostLastName: profile?.lastName || '',
      hostEmail: profile?.user.email || profile?.email || '',
      superHogStatus: superHogStatus,
    }))
    setTimeout(() => {
      handleClickEditIcon(refCity.current, address.city)
      handleClickEditIcon(refPostcode.current, address.zipcode)
      handleClickEditIcon(refCountryIso.current, countryIso)
      handleClickEditIcon(refFirstName.current, profile?.firstName)
      handleClickEditIcon(refLastName.current, profile?.lastName)
      handleClickEditIcon(refEmail.current, profile?.user.email || profile?.email)
    }, 800)
  }, [externalName, address, profile, superHogStatus, isOpen])

  // useEffect(() => {}, [
  //   isCityVisible,
  //   isPostcodeVisible,
  //   isCountryIsoVisible,
  //   isFirstNameVisible,
  //   isLastNameVisible,
  //   isEmailVisible,
  //   address,
  // ])

  const handleClickEditIcon = (element: HTMLElement | null, value: string | number) => {
    if (element && !value) {
      const parentLabel = element.closest('.inputLabel')
      const btnEdit = parentLabel?.querySelector('.btnEditable') as HTMLButtonElement
      if (btnEdit.classList.contains('ic_edit')) {
        btnEdit?.click()
      }
    }
  }

  const onChangeData = (field: string, value: string) => {
    setData((prevState) => ({ ...prevState, [field]: value }))
    if (REQUIRED_FIELDS.includes(field)) {
      setErrors((prevState) => ({ ...prevState, [field]: { show: false, message: '' } }))
    }
  }

  const handleClose = () => {
    onClose()
    // clear errors
    setErrors({
      hostFirstName: { show: false, message: '' },
      hostLastName: { show: false, message: '' },
      hostEmail: { show: false, message: '' },
      postcode: { show: false, message: '' },
      town: { show: false, message: '' },
    })
  }

  const onConfirm = async () => {
    let isValid: boolean = true
    REQUIRED_FIELDS.forEach((field: string) => {
      // @ts-ignore
      if (!data[field] || !data[field].trim()) {
        setErrors((prevState) => ({ ...prevState, [field]: { show: true, message: 'Please enter a value' } }))
        isValid = false
      }
      if (field === 'postcode') {
        if (!isPostalCode(data[field])) {
          setErrors((prevState) => ({
            ...prevState,
            [field]: { show: true, message: 'Please enter a valid postcode' },
          }))
          isValid = false
        }
      }
      if (field === 'hostEmail') {
        if (!isEmailValid(data[field])) {
          setErrors((prevState) => ({ ...prevState, [field]: { show: true, message: 'Please enter a valid email' } }))
          isValid = false
        }
      }
    })
    if (!isValid) return
    try {
      setIsLoading(true)
      await superHogCreateListing(data)
      dispatch(setToastSuccess({ message: 'Success!' }))
      onChangeStatus(data.superHogStatus)
      onClose() // close popup
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const renderReference = (field: keyof SuperHogCreateListing) => {
    switch (field) {
      case 'town':
        return refCity
      case 'postcode':
        return refPostcode
      // case 'countryIso':
      //   return refCountryIso
      case 'hostFirstName':
        return refFirstName
      case 'hostLastName':
        return refLastName
      case 'hostEmail':
        return refEmail
      default:
        return null
    }
  }

  return (
    <BasicDialog
      isOpen={isOpen}
      onClose={handleClose}
      title={'Confirm your information'}
      extraTitle={'Your information below will be submitted to SUPERHOG.'}
    >
      {FIELDS.map((item, index) => {
        return (
          <div key={item.field} className={index === 0 ? '' : 'mt-[24px]'}>
            {item.field === 'countryIso' ? (
              <BasicSelect
                ref={refCountryIso}
                label={item.label}
                options={countries.map((v: any) => ({ ...v, label: v.name, value: v.iso }))}
                value={data.countryIso}
                onChange={(event) => onChangeData(item.field, event.target.value)}
                showIconEdit={item.editable}
                error={errors[item.field as keyof FieldsError]}
                placeholder={item.placeholder}
              />
            ) : (
              <BasicInput
                ref={renderReference(item.field)}
                id={`superhog_info_${data[item.field]}`}
                label={item.label}
                value={data[item.field]}
                onChange={(event) => onChangeData(item.field, event.target.value)}
                readOnly={true}
                showIconEdit={item.editable}
                error={errors[item.field as keyof FieldsError]}
                placeholder={item.placeholder}
              />
            )}
          </div>
        )
      })}

      <div className={'mt-[24px]'}>
        <BasicButton
          variant={'contained'}
          color={'red'}
          isRadius100={true}
          clases={'w-full'}
          onClick={onConfirm}
          loading={isLoading}
        >
          Confirm
        </BasicButton>
      </div>
    </BasicDialog>
  )
}

export default PopupInfoSupertHog
