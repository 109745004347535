import { ListingItem } from '../../interfaces/listing'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import ic_chevron_down from 'src/assets/icons/ic_chevron_down_neutral_600.svg'
import SelectDates from 'src/components/common/SelectDates'
import SelectGuests from 'src/components/common/SelectGuests'
import SelectTime from 'src/components/common/SelectTime'
import SearchProperty from 'src/components/reservation-manual/SearchProperty'
import { START_DATE } from 'src/contants/common'
import { GuestObject } from 'src/interfaces'
import { useAppSelector } from 'src/redux/hooks'
import { setDatePickerFocusedId, setIsOpenDatePicker, setIsOpenSelectGuest } from 'src/redux/slices/common'
import { convertAmPm, showDateRange } from 'src/utils/common'
import { isPropertyDtravel } from 'src/utils/property'

interface Props {
  hostId: string
  checkInDate: string | null
  checkOutDate: string | null
  onSaveDates: (_checkIn: string | null, _checkOut: string | null) => void
  guest: GuestObject
  onSaveGuest: (data: GuestObject) => void
  checkInTime: number
  onSaveCheckInTime: (hour: number) => void
  checkOutTime: number
  onSaveCheckOutTime: (hour: number) => void
  timezone?: string
  onSelectProperty: (data: ListingItem) => void
}

const ReservationTripInformation: React.FC<Props> = ({
  hostId,
  checkInDate,
  checkOutDate,
  checkInTime,
  checkOutTime,
  onSaveDates,
  guest,
  onSaveGuest,
  onSaveCheckInTime,
  onSaveCheckOutTime,
  timezone,
  onSelectProperty,
}) => {
  const datesRef = useRef<HTMLButtonElement | any>(null)
  const guestRef = useRef<HTMLButtonElement | any>(null)
  const dispatch = useDispatch()
  const [anchorElementDatePicker, setAnchorElementDatePicker] = useState<HTMLButtonElement | null>(null)
  const [anchorElementSelectGuest, setAnchorElementSelectGuest] = useState<HTMLButtonElement | null>(null)
  const [anchorElementCheckInTime, setAnchorElementCheckInTime] = useState<HTMLButtonElement | null>(null)
  const [anchorElementCheckOutTime, setAnchorElementCheckOutTime] = useState<HTMLButtonElement | null>(null)
  const [isOpenCheckInTime, setIsOpenCheckInTime] = useState<boolean>(false) // for mobile
  const [isOpenCheckOutTime, setIsOpenCheckOutTime] = useState<boolean>(false) // for mobile
  const { listingData } = useAppSelector((state) => state.common)

  const handleOpenDatePicker = (focusedId?: 'startDate' | 'endDate' | null) => {
    setAnchorElementDatePicker(datesRef.current)
    dispatch(setDatePickerFocusedId(focusedId || START_DATE))
    dispatch(setIsOpenDatePicker(true))
  }

  const handleOpenSelectGuest = () => {
    setAnchorElementSelectGuest(guestRef.current)
    dispatch(setIsOpenSelectGuest(true))
  }

  const onCloseCheckInTime = () => {
    setIsOpenCheckInTime(false) // mobile
    setAnchorElementCheckInTime(null) // desktop
  }

  const onCloseCheckOutTime = () => {
    setIsOpenCheckOutTime(false) // mobile
    setAnchorElementCheckOutTime(null)
  }

  const countGuest = () => guest.adults + guest.children + guest.infants

  const isNativeListing = isPropertyDtravel(listingData)

  return (
    <div>
      <h3 className={'font-maison-neue text-20-24 text-neutral-900'}>Trip information</h3>

      <div className={'grid grid-cols-2 gap-[12px] mt-[16px]'}>
        <div className={'col-span-2 w-full'}>
          <SearchProperty onSelectProperty={onSelectProperty} />
        </div>

        <button
          className={
            'w-full bg-white rounded-[16px] flex justify-between items-center p-[24px] col-span-2 lg:col-span-1'
          }
          ref={datesRef}
          onClick={() => handleOpenDatePicker(START_DATE)}
        >
          <div>
            <p className={'font-maison-neue-medium text-12-16 text-neutral-600 mb-[2px] text-left'}>Dates</p>
            <p className={'font-maison-neue text-16-20 text-neutral-900'}>
              {showDateRange(checkInDate, checkOutDate)}&nbsp;
            </p>
          </div>
          <div>
            <img src={ic_chevron_down} alt={'ic_chevron_down'} />
          </div>
        </button>
        <button
          className={
            'w-full bg-white rounded-[16px] flex justify-between items-center p-[24px] col-span-2 lg:col-span-1'
          }
          ref={guestRef}
          onClick={handleOpenSelectGuest}
        >
          <div>
            <p className={'font-maison-neue-medium text-12-16 text-neutral-600 mb-[2px] text-left'}>Guests</p>
            <p className={'font-maison-neue text-16-20 text-neutral-900'}>
              {countGuest()} {countGuest() > 1 ? 'guests' : 'guest'}
            </p>
          </div>
          <div>
            <img src={ic_chevron_down} alt={'ic_chevron_down'} />
          </div>
        </button>

        <button
          className={
            'w-full bg-white rounded-[16px] flex justify-between items-center p-[24px] col-span-2 lg:col-span-1'
          }
          onClick={(event) => {
            setAnchorElementCheckInTime(event.currentTarget)
            setIsOpenCheckInTime(true)
          }}
        >
          <div className={'text-left'}>
            <p className={'font-maison-neue-medium text-12-16 text-neutral-600 mb-[2px] text-left'}>Check-in time</p>
            <p className={'font-maison-neue text-16-20 text-neutral-900'}>
              {checkInTime ? (
                <>
                  {convertAmPm(checkInTime, true)} {timezone ? `(${timezone})` : ''}
                </>
              ) : (
                <>&nbsp;</>
              )}
            </p>
          </div>
          <div>
            <img src={ic_chevron_down} alt={'ic_chevron_down'} />
          </div>
        </button>
        <button
          className={
            'w-full bg-white rounded-[16px] flex justify-between items-center p-[24px] col-span-2 lg:col-span-1'
          }
          onClick={(event) => {
            setAnchorElementCheckOutTime(event.currentTarget)
            setIsOpenCheckOutTime(true)
          }}
        >
          <div className={'text-left'}>
            <p className={'font-maison-neue-medium text-12-16 text-neutral-600 mb-[2px]'}>Check-out time</p>
            <p className={'font-maison-neue text-16-20 text-neutral-900'}>
              {checkOutTime ? (
                <>
                  {convertAmPm(checkOutTime, true)} {timezone ? `(${timezone})` : ''}
                </>
              ) : (
                <>&nbsp;</>
              )}
            </p>
          </div>
          <div>
            <img src={ic_chevron_down} alt={'ic_chevron_down'} />
          </div>
        </button>
      </div>

      <SelectDates
        anchorElement={anchorElementDatePicker}
        hostId={hostId}
        listingId={listingData ? listingData.id.toString() : ''}
        transformOrigin={{ vertical: -4, horizontal: 'left' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onSaveDates={onSaveDates}
        isNativeListing={isNativeListing}
      />

      <SelectGuests
        anchorElement={anchorElementSelectGuest}
        transformOrigin={{ vertical: -4, horizontal: 'right' }}
        personCapacity={listingData ? listingData.personCapacity : 0}
        maxPet={listingData ? listingData.maxPet : 0}
        onSaveGuest={onSaveGuest}
      />

      <SelectTime
        anchorElement={anchorElementCheckInTime}
        isOpen={isOpenCheckInTime}
        onClose={onCloseCheckInTime}
        value={checkInTime}
        onSaveTime={onSaveCheckInTime}
      />

      <SelectTime
        anchorElement={anchorElementCheckOutTime}
        isOpen={isOpenCheckOutTime}
        onClose={onCloseCheckOutTime}
        value={checkOutTime}
        onSaveTime={onSaveCheckOutTime}
      />
    </div>
  )
}

export default ReservationTripInformation
