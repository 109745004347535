import React from 'react'
import no_image from 'src/assets/images/no_image_listing.jpg'
import RenderPrice from 'src/components/common/RenderPrice'
import { FEES_AND_DISCOUNT } from 'src/contants/common'
import { AddOn, CustomFeeAndDiscount, PriceReservation } from 'src/interfaces/reservations'
import { useAppSelector } from 'src/redux/hooks'

interface Props {
  loading?: boolean
  priceData: PriceReservation | null
  nights: number
  currency: string
  tax: CustomFeeAndDiscount[]
  fee: CustomFeeAndDiscount[]
  discount: CustomFeeAndDiscount[]
  addOns: AddOn[]
  totalTax: number
  totalFee: number
  totalDiscount: number
  totalAddOn: number
  finalPrice: number
}

const ReservationSummary: React.FC<Props> = ({
  priceData,
  nights,
  currency,
  tax,
  fee,
  discount,
  totalTax,
  totalFee,
  totalDiscount,
  finalPrice,
}) => {
  const { listingData } = useAppSelector((state) => state.common)

  const renderNote = () => {
    return (
      <p className={'font-maison-neue text-14-20 text-neutral-600'}>
        Please note: this is not yet a confirmed reservation. These dates will only become unavailable when your guest
        successfully submits a payment.
      </p>
    )
  }

  const renderTitle = () => {
    if (listingData) {
      const { address, contact } = listingData
      const cityPart = address?.city || address?.country ? `in ${address?.city || address?.country}` : ''
      const hostedBy = contact?.contactName ? 'hosted by ' + contact?.contactName : ''
      return listingData ? `${nights} ${nights > 1 ? 'nights' : 'night'} ${cityPart} ${hostedBy}` : ''
    }
    return ''
  }

  return (
    <div className={'bg-white p-[32px] rounded-[16px] sticky top-[102px]'}>
      <p className={'font-maison-neue text-20-24 text-neutral-900'}>Booking summary</p>
      <div className={'my-[24px] w-full h-[0.5px] bg-[#00000026]'} />

      {listingData ? (
        <div className={'relative'}>
          <div className={'flex gap-[24px] justify-between items-center'}>
            <p className={'font-maison-neue-medium text-20-24 text-neutral-900'}>{renderTitle()}</p>
            <div className={'w-[64px] h-[64px] min-w-[64px]'}>
              <img
                src={listingData?.thumbnail || no_image}
                alt={'thumbnail'}
                className={'w-full h-full rounded-[12px]'}
                style={{
                  boxShadow: '0px 8px 16px -4px rgba(0, 0, 0, 0.06), 0px 4px 6px -2px rgba(0, 0, 0, 0.04)',
                }}
              />
            </div>
          </div>
          {/*---Seprate line---*/}
          <div className={'my-[24px] w-full h-[0.5px] bg-[#00000026]'} />

          {renderNote()}
          {priceData && nights > 0 && (
            <>
              <div className={'mt-[24px] flex flex-col gap-[8px]'}>
                <p className={'flex justify-between items-center'}>
                  <span className={'font-maison-neue text-14-18 text-neutral-900'}>
                    <RenderPrice price={Number(priceData.basePricePerNight)} currency={currency} isUseSymbol /> x{' '}
                    {nights} {nights > 1 ? 'nights' : 'night'}
                  </span>
                  <span className={'font-maison-neue-medium text-14-18 text-neutral-900'}>
                    <RenderPrice price={Number(priceData.price)} currency={currency} isUseSymbol />
                  </span>
                </p>

                {/* ---Discount--- */}
                {totalDiscount < 0 && (
                  <p className={'flex justify-between items-center'}>
                    <span className={'font-maison-neue text-14-18 text-neutral-900'}>Discounts</span>
                    <span className={'font-maison-neue-medium text-14-18 text-forest-700'}>
                      -
                      <RenderPrice price={Number(totalDiscount)} currency={currency} isUseSymbol />
                    </span>
                  </p>
                )}
                {discount
                  .filter((item) => item.isIncludedInTotalPrice)
                  .map((item, index) => {
                    return (
                      <p key={`taxAndFee${index}`} className={'flex justify-between items-center pl-[8px]'}>
                        <span className={'font-maison-neue text-14-18 text-neutral-600'}>
                          <span>&#x2022;</span>
                          <span className={'pl-[8px]'}>{item.title || FEES_AND_DISCOUNT[item.name]}</span>
                        </span>
                        <span className={'font-maison-neue-medium text-14-18 text-forest-700'}>
                          -<RenderPrice price={Number(item.value)} currency={currency} isUseSymbol />
                        </span>
                      </p>
                    )
                  })}

                {/* ---Fees--- */}
                {totalFee > 0 && (
                  <p className={'flex justify-between items-center'}>
                    <span className={'font-maison-neue text-14-18 text-neutral-900'}>Fees</span>
                    <span className={'font-maison-neue-medium text-14-18 text-neutral-900'}>
                      <RenderPrice price={Number(totalFee)} currency={currency} isUseSymbol />
                    </span>
                  </p>
                )}
                {fee
                  .filter((item) => item.isIncludedInTotalPrice)
                  .map((item, index) => {
                    return (
                      <p key={`taxAndFee${index}`} className={'flex justify-between items-center pl-[8px]'}>
                        <span className={'font-maison-neue text-14-18 text-neutral-600'}>
                          <span>&#x2022;</span>
                          <span className={'pl-[8px]'}>
                            {item.title || item.feeTitle || FEES_AND_DISCOUNT[item.name]}
                          </span>
                        </span>
                        <span className={'font-maison-neue-medium text-14-18 text-neutral-900'}>
                          <RenderPrice price={Number(item.value)} currency={currency} isUseSymbol />
                        </span>
                      </p>
                    )
                  })}

                {/* ---Taxes--- */}
                {totalTax > 0 && (
                  <p className={'flex justify-between items-center'}>
                    <span className={'font-maison-neue text-14-18 text-neutral-900'}>Taxes</span>
                    <span className={'font-maison-neue-medium text-14-18 text-neutral-900'}>
                      <RenderPrice price={Number(totalTax)} currency={currency} isUseSymbol />
                    </span>
                  </p>
                )}
                {tax
                  .filter((item) => item.isIncludedInTotalPrice)
                  .map((item, index) => {
                    return (
                      <p key={`taxAndFee${index}`} className={'flex justify-between items-center pl-[8px]'}>
                        <span className={'font-maison-neue text-14-18 text-neutral-600'}>
                          <span>&#x2022;</span>
                          <span className={'pl-[8px]'}>{item.title || FEES_AND_DISCOUNT[item.name]}</span>
                        </span>
                        <span className={'font-maison-neue-medium text-14-18 text-neutral-900'}>
                          <RenderPrice price={Number(item.value)} currency={currency} isUseSymbol />
                        </span>
                      </p>
                    )
                  })}
              </div>

              {/*Seprate line*/}
              <div className={'my-[24px] w-full h-[0.5px] bg-[#00000026]'} />

              <p className={'flex justify-between items-center'}>
                <span className={'font-maison-neue-medium text-20-24 text-neutral-900'}>Total</span>
                <span className={'font-maison-neue-medium text-20-24 text-neutral-900'}>
                  <RenderPrice price={finalPrice >= 0 ? Number(finalPrice) : 0} currency={currency} isUseSymbol />
                </span>
              </p>

              {priceData && !priceData.isAvail && (
                <p className="font-maison-neue text-red-6 text-12-16 bg-red-1 rounded-[12px] p-3 mt-4">
                  Unfortunately the requested time slot is not available for reservation. Change your selected time to
                  book this.
                </p>
              )}
            </>
          )}
        </div>
      ) : (
        renderNote()
      )}
    </div>
  )
}

export default ReservationSummary
