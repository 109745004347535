import { SETTINGS_SIDE_BAR, SETUP_GUIDE } from '../../../contants/common'
import { handleErrorMessage, isEmpty, removeDuplicate } from '../../../utils/common'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { changePaymentMethodHost, getPaymentMethodHost, paymentMethodStripe } from 'src/api/integration'
import ic_busd from 'src/assets/icons/ic_busd.svg'
import ic_stripe from 'src/assets/icons/ic_stripe.svg'
import ic_usdc from 'src/assets/icons/ic_usdc.svg'
import ic_usdt from 'src/assets/icons/ic_usdt.svg'
import BasicSwitch from 'src/components/ui/BasicSwitch'
import BasicTooltip from 'src/components/ui/BasicTooltip'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setUpdateStripePayment } from 'src/redux/slices/common'

const SettingPaymentOptions = () => {
  const dispatch = useAppDispatch()
  const { updateStripePayment } = useAppSelector((state) => state.common)
  const { isConnectedStripe, profile } = useAppSelector((state) => state.user)
  const hasWallet = profile?.user?.walletId
  const [paymentMethod, setPaymentMethod] = useState<Array<any>>([])

  useEffect(() => {
    fetchPaymentMethodHost()
  }, [])
  // }, [profile])

  useEffect(() => {
    if (updateStripePayment) fetchPaymentMethodHost()
  }, [updateStripePayment])

  const connectFiatPayment = async (value: boolean) => {
    try {
      await paymentMethodStripe(value)
      fetchPaymentMethodHost()
    } catch (err: any) {
      handleErrorMessage(err)
    }
  }

  const fetchPaymentMethodHost = async () => {
    try {
      const res = await getPaymentMethodHost()
      if (res.success) {
        const result = res.data.sort((a: any, b: any) => a.order - b.order)
        setPaymentMethod(result)
      }
      dispatch(setUpdateStripePayment(false))
    } catch (error: any) {
      console.log(error)
    } finally {
      // setLoading(false)
    }
  }

  const handleChangePaymentMethodHost = async (key: string, status: boolean) => {
    const ids = paymentMethod.filter((v: any) => v.key === key).map((v: any) => v.id) || []
    try {
      // const res = await changePaymentMethodHost({ id, status })
      let idsServices: any = []
      if (!isEmpty(ids)) {
        ids.forEach((id: string) => {
          idsServices.push(changePaymentMethodHost({ id, status }))
        })
        await Promise.all(idsServices)
        fetchPaymentMethodHost()
      }
    } catch (error: any) {
      handleErrorMessage(error)
    }
  }

  const getStripeStatus = () => {
    if (Array.isArray(paymentMethod) && paymentMethod.length > 0) {
      const stripePayment = paymentMethod.find((item) => item.key === 'STRIPE')
      return stripePayment ? stripePayment.status : false
    }
    return false
  }
  const paymentMethodData = removeDuplicate(paymentMethod || [], 'key')
  return (
    <div id={SETUP_GUIDE.ADD_PAYMENT_OPTIONS}>
      <h2 id={SETTINGS_SIDE_BAR.PAYMENT_METHODS} className={'font-maison-neue-medium text-20-32 text-neutral-800'}>
        Payment Methods
      </h2>
      <p className={'font-maison-neue text-16-20 text-neutral-500 mt-[4px]'}>
        Choose the payment methods that your guests will be able to book with
      </p>
      {/*---FIAT PAYMENT---*/}
      <p className="font-maison-neue text-neutral-800 text-20-24 mt-[16px]">Fiat Payment</p>
      <div className={'flex justify-between items-center rounded-[16px] p-[24px] bg-white shadow-basic mt-[8px]'}>
        <div className="flex items-center gap-[12px]">
          <img src={ic_stripe} alt={'stripe'} width={42} height={42} className={'rounded-full'} />
          <div>
            <p className={'font-maison-neue-medium text-16-20 text-neutral-8'}>Stripe</p>
            <p className={'font-maison-neue text-14-18 text-neutral-6 hidden md:block mt-[4px]'}>
              Accept all major credit/debit cards and fiat currencies. Includes 3-4% transaction fees.
            </p>
          </div>
        </div>
        {isConnectedStripe ? (
          <BasicSwitch
            checked={getStripeStatus()}
            onChange={() => {
              connectFiatPayment(!getStripeStatus())
            }}
            checkedColor="#292926"
          />
        ) : (
          <BasicTooltip
            placement="top"
            title={'Please connect to your Stripe account first.'}
            sx={{ '& .MuiTooltip-tooltip': { color: '#FBFAFA', fontSize: '12px', fontWeight: '16px' } }}
            arrow
          >
            <div className={'w-[36px] h-[20px] rounded-full bg-neutral-300 p-[2px] cursor-not-allowed'}>
              <div
                className={'w-[16px] h-[16px] bg-white rounded-full'}
                style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)' }}
              />
            </div>
          </BasicTooltip>
        )}
      </div>

      {/*---CRYPTO PAYMENT---*/}
      <p className="font-maison-neue text-neutral-800 text-20-24 mt-[16px]">Crypto Payments</p>
      <div className={'w-full bg-white rounded-[16px] shadow-basic px-[24px] mt-[8px]'}>
        {paymentMethodData.map((item: any, index: number) => {
          if (item.type !== 'CRYPTO') return null
          let icon =
            item.key === 'BUSD' ? ic_busd : item.key === 'USDC' ? ic_usdc : item.key === 'USDT' ? ic_usdt : null
          const isFirstItem = index === 0
          // const isTrvl = item.key === 'TRVL' || item.currency === 'TRVL'
          return (
            <div
              key={item.id}
              className={clsx('flex justify-between items-center py-[24px]', {
                ['border-t border-neutral-300 ']: !isFirstItem,
              })}
            >
              <div className="flex items-center gap-[12px]">
                <img src={item.icon || icon} alt={'stripe'} width={42} height={42} className={'rounded-full'} />
                <div>
                  <p className={'flex flex-col md:flex-row justify-start gap-[4px] md:gap-[8px]'}>
                    <span className={'font-maison-neue-medium text-16-20 text-neutral-8'}>{item.key}</span>
                  </p>
                  <p className={'font-maison-neue text-14-18 text-neutral-6 hidden md:block mt-[4px]'}>{item.name}</p>
                </div>
              </div>
              <>
                {hasWallet ? (
                  // isTrvl ? (
                  //   <BasicTooltip
                  //     placement="left"
                  //     title={'Coming soon to Polygon'}
                  //     sx={{ '& .MuiTooltip-tooltip': { color: '#FBFAFA', fontSize: '12px', fontWeight: '16px' } }}
                  //     arrow
                  //   >
                  //     <div className={'w-[36px] h-[20px] rounded-full bg-neutral-300 p-[2px] cursor-not-allowed'}>
                  //       <div
                  //         className={'w-[16px] h-[16px] bg-white rounded-full'}
                  //         style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)' }}
                  //       />
                  //     </div>
                  //   </BasicTooltip>
                  // ) : (
                  <BasicSwitch
                    checked={item.status}
                    onChange={() => {
                      handleChangePaymentMethodHost(item.key, !item.status)
                    }}
                    checkedColor="#292926"
                  />
                  // )
                ) : (
                  // <BasicSwitch
                  //   checked={item.status}
                  //   onChange={() => {
                  //     handleChangePaymentMethodHost(item.key, !item.status)
                  //   }}
                  //   checkedColor="#292926"
                  // />
                  <BasicTooltip
                    placement="top"
                    title={'Please connect to your wallet first'}
                    sx={{ '& .MuiTooltip-tooltip': { color: '#FBFAFA', fontSize: '12px', fontWeight: '16px' } }}
                    arrow
                  >
                    <div className={'w-[36px] h-[20px] rounded-full bg-neutral-300 p-[2px] cursor-not-allowed'}>
                      <div
                        className={'w-[16px] h-[16px] bg-white rounded-full'}
                        style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)' }}
                      />
                    </div>
                  </BasicTooltip>
                )}
              </>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SettingPaymentOptions
