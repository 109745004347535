import { AVAILABILITY_WINDOW_OPTIONS } from '../../../contants/native'
import BasicSelect from '../../ui/BasicSelect'
import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { isEmpty } from 'src/utils/common'

interface Props {
  availabilityWindow: number | null | undefined
  onChange: (val: number) => void
  isHospitable?: boolean
}

const AvailabilityWindow: React.FC<Props> = ({ availabilityWindow, onChange, isHospitable }) => {
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isEditBulk = !isEmpty(parseQuery?.ids)
  return (
    <div className={'flex flex-col md:flex-row justify-between items-center gap-[8px] md:gap-[32px] flex-1'}>
      <div>
        <p className={'font-maison-neue text-16-24 text-neutral-800'}>Availability window</p>
        <p className={'font-maison-neue text-14-20 text-neutral-600'}>
          Define how far in advance guests can book a reservation
        </p>
      </div>
      <div className={'w-full md:w-auto min-w-[140px]'}>
        <BasicSelect
          options={
            isHospitable || isEditBulk
              ? [
                {
                  value: '',
                  label: '',
                },
                ...AVAILABILITY_WINDOW_OPTIONS,
              ]
              : AVAILABILITY_WINDOW_OPTIONS
          }
          placeholder={'Select'}
          value={String(availabilityWindow)}
          onChange={(event) => onChange(Number(event.target.value))}
        />
      </div>
    </div>
  )
}

export default AvailabilityWindow
