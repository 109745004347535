import { handleErrorMessage, isEmpty } from '../../../utils/common'
import React, { useEffect, useState } from 'react'
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import { addContactPhone, getUserProfile } from 'src/api/user'
import InputPhoneNumber from 'src/components/common/InputPhoneNumber'
import BasicButton from 'src/components/ui/BasicButton'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setLoading, setToastSuccess } from 'src/redux/slices/common'
import { getUserInfo, saveUserInfo } from 'src/utils/user'

interface Props {
  onClose?: () => void
}

const SettingContactPhoneWithoutVerify: React.FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch()
  const { profile: data } = useAppSelector((state) => state.user)
  const { isLoading } = useAppSelector((state) => state.common)
  const [isDisabled, setIsDisabled] = useState(false)
  const isEditPhone = data?.phone

  const [errorMsg, setErrorMsg] = useState({ phone: '', otp: '' })
  const [phone, setPhone] = useState<string>('')

  const fetchUserProfile = async () => {
    try {
      const res = await getUserProfile()
      const { data } = res
      const user = getUserInfo()
      saveUserInfo({ ...user, ...data })
    } catch (err) {
      console.log(err)
    }
  }

  const initValue = () => {
    setPhone(data?.phone || '')
  }

  useEffect(() => {
    initValue()
  }, [data])

  useEffect(() => {
    const hasChange = !data || (!isEmpty(phone) && data?.phone !== phone)
    setIsDisabled(!hasChange)
  }, [data, phone])

  const onBlurPhone = () => {
    if (!isValidPhoneNumber(phone || '')) {
      setErrorMsg((prevState) => ({
        ...prevState,
        phone: 'Please enter a valid phone number.',
      }))
    }
  }

  const convertPhone = () => {
    if (phone.includes('+')) return phone
    return `+${phone}`
  }

  const onSave = async () => {
    try {
      dispatch(setLoading(true))
      await addContactPhone({ phone: convertPhone(), type: 'host' })
      dispatch(setToastSuccess({ message: `Contact phone ${isEditPhone ? 'edited' : 'added'}.` }))
      fetchUserProfile()
      if (typeof onClose === 'function') {
        onClose()
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      dispatch(setLoading(false))
    }
  }

  return (
    <>
      <div className={'mb-[24px]'}>
        <InputPhoneNumber
          phone={phone}
          defaultCountry={parsePhoneNumber(data?.phone || '')?.country}
          onChange={(phoneValue: any) => {
            setPhone(phoneValue)
            setErrorMsg((prevState) => ({ ...prevState, phone: '' }))
          }}
          handleBlur={onBlurPhone}
        />
        {errorMsg.phone && <span className={'text-red mt-2 ml-2 text-12-12'}>{errorMsg.phone}</span>}
      </div>
      <BasicButton
        variant={'contained'}
        color={'red'}
        isRadius100={true}
        clases={'w-full'}
        disabled={isDisabled}
        loading={isLoading}
        onClick={onSave}
      >
        Add
      </BasicButton>
    </>
  )
}

export default SettingContactPhoneWithoutVerify
