import { PropertyDetailProps } from '../../interfaces/listing'
import { setLoading } from '../../redux/slices/common'
import { setExternalNameNativeListing } from '../../redux/slices/listing'
import BasicButton from '../ui/BasicButton'
import EditBulkListingConfirmDialog from './EditBulkListingConfirmDialog'
import NativeListingAmenities from './NativeListingAmenities'
import NativeListingDescription from './NativeListingDescription'
import NativeListingGuestRooms from './NativeListingGuestRooms'
import NativeListingHouseRules from './NativeListingHouseRules'
import NativeListingLocation from './NativeListingLocation'
import NativeListingPhotos from './NativeListingPhotos'
import NativeListingProperty from './NativeListingProperty'
import NativeListingPublish from './NativeListingPublish'
import NativeListingSideBar from './NativeListingSideBar'
import NativeListingStepMobile from './NativeListingStepMobile'
import NativeListingAvailability from './availability/NativeListingAvailability'
import NativeListingICal from './availability/NativeListingICal'
import NativeListingDiscounts from './discounts/NativeListingDiscounts'
import NativeListingFees from './fees/NativeListingFees'
import NativeListingRates from './rates/NativeListingRates'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getPropertyDetail, getStatusProperties } from 'src/api/native-listing'
import ic_arrow_back from 'src/assets/icons/ic_arrow_left.svg'
import LayoutV2 from 'src/components/layout/LayoutV2'
import { NATIVE_LISTING_MENU } from 'src/contants/common'
import { PROPERTY_STATUS } from 'src/contants/native'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { isEmpty } from 'src/utils/common'

const NativeListing = () => {
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const { type } = useParams()
  const dispatch = useAppDispatch()
  const { externalNameNativeListing } = useAppSelector((state) => state.listing)
  const [data, setData] = useState<PropertyDetailProps | null>(null)
  const [statusStep, setStatusStep] = useState<any>(null)
  const propertyId = parseQuery.id ? String(parseQuery.id) : ''
  const isDraft = data?.status === PROPERTY_STATUS.DRAFT
  const ids = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const isOnboarding = parseQuery?.f === 'onboarding'
  const navigate = useNavigate()

  useEffect(() => {
    async function fetchPropertyDetail(id: string) {
      try {
        dispatch(setLoading(true))
        const res = await getPropertyDetail(id)
        setData(res.success && res.data ? res.data : null)
        dispatch(setExternalNameNativeListing(res.data?.externalName || ''))
      } catch (err: any) {
        console.log(err)
      } finally {
        dispatch(setLoading(false))
      }
    }
    if (parseQuery.id) {
      fetchPropertyDetail(String(parseQuery.id))
    } else {
      dispatch(setExternalNameNativeListing(''))
    }
  }, [search, type])

  useEffect(() => {
    if (parseQuery.id || !isEmpty(ids)) fetchStatusStep()
  }, [])

  const fetchStatusStep = async (idProperty?: string) => {
    try {
      const propertyIds = isEditBulk ? ids : parseQuery.id || idProperty ? [Number(parseQuery.id || idProperty)] : []
      if (!isEmpty(propertyIds)) {
        const res = await getStatusProperties(propertyIds)
        if (res.data) setStatusStep(res.data)
      }
    } catch (err: any) {
      console.log(err)
    }
  }

  return (
    <LayoutV2
      childrenHeader={
        <>
          <Link to={isOnboarding ? '/' : '/listings'} className="flex gap-6 items-center cursor-pointer">
            <button
              className={
                'rounded-full flex items-center justify-center bg-white w-[40px] h-[40px] hover:bg-neutral-200'
              }
            >
              <img alt={'ic_exit'} src={ic_arrow_back} />
            </button>
            <span className="text-16-20 font-maison-neue text-neutral-800">
              {isEditBulk
                ? `Edit: ${ids.length} listings`
                : !data || isDraft
                ? 'Add a new listing'
                : `${externalNameNativeListing ? `Edit: ${externalNameNativeListing}` : ''}`}
            </span>
          </Link>

          <BasicButton
            size="md"
            variant={'outlined'}
            clases="w-auto"
            onClick={() => {
              navigate(isOnboarding ? '/' : '/listings')
            }}
            isRadius100={true}
          >
            Exit
          </BasicButton>

          {/*{parseQuery.id && isDraft && (*/}
          {/*  <BasicButton*/}
          {/*    size="md"*/}
          {/*    variant={'outlined'}*/}
          {/*    clases="w-auto rounded-[100px]"*/}
          {/*    onClick={() => {*/}
          {/*      const saveExitEl = document.getElementById(`${type}_save_exit`)*/}
          {/*      if (saveExitEl) handleClickSave(saveExitEl)*/}
          {/*    }}*/}
          {/*    loading={isLoading}*/}
          {/*  >*/}
          {/*    Save & Exit*/}
          {/*  </BasicButton>*/}
          {/*)}*/}
          {/*{((parseQuery.id && !isDraft) || isEditBulk) && (*/}
          {/*  <BasicButton*/}
          {/*    size="md"*/}
          {/*    variant={'outlined'}*/}
          {/*    clases="w-auto"*/}
          {/*    onClick={() => {*/}
          {/*      const saveExitEl = document.getElementById(`${type}_save`)*/}
          {/*      if (saveExitEl) handleClickSave(saveExitEl)*/}
          {/*    }}*/}
          {/*    loading={isLoading}*/}
          {/*    isRadius100={true}*/}
          {/*  >*/}
          {/*    Save*/}
          {/*  </BasicButton>*/}
          {/*)}*/}
        </>
      }
    >
      <div className="flex gap-[12px]">
        {/* Left side (sidebar) */}
        <div className={'basis-1/4 min-w-[296px] hidden lg:block relative'}>
          <div className={'sticky top-[102px]'}>
            <NativeListingSideBar listingData={data} statusStep={statusStep} />
          </div>
        </div>

        {/* Right side (content) */}
        <div className="w-full lg:w-1/2 pb-[92px]">
          <div className="block lg:hidden">
            <NativeListingStepMobile listingData={data} statusStep={statusStep} />
          </div>

          {type === NATIVE_LISTING_MENU.PROPERTY && (
            <NativeListingProperty listingData={data} fetchStatusStep={fetchStatusStep} />
          )}
          {type === NATIVE_LISTING_MENU.LOCATION && (
            <NativeListingLocation listingData={data} fetchStatusStep={fetchStatusStep} />
          )}
          {type === NATIVE_LISTING_MENU.GUEST_ROOMS && (
            <NativeListingGuestRooms listingData={data} fetchStatusStep={fetchStatusStep} />
          )}

          {type === NATIVE_LISTING_MENU.PHOTOS && (
            <NativeListingPhotos
              propertyImages={data?.propertyImages || []}
              listingData={data}
              fetchStatusStep={fetchStatusStep}
            />
          )}
          {type === NATIVE_LISTING_MENU.AMENITIES && (
            <NativeListingAmenities propertyId={propertyId} listingData={data} fetchStatusStep={fetchStatusStep} />
          )}
          {type === NATIVE_LISTING_MENU.DESCRIPTION && (
            <NativeListingDescription propertyId={propertyId} listingData={data} fetchStatusStep={fetchStatusStep} />
          )}
          {type === NATIVE_LISTING_MENU.HOUSE_RULES && (
            <NativeListingHouseRules propertyId={propertyId} listingData={data} fetchStatusStep={fetchStatusStep} />
          )}
          {type === NATIVE_LISTING_MENU.AVAILABILITY && (
            <NativeListingAvailability
              propertyId={propertyId}
              listingData={data}
              currentMinNights={data?.minNights}
              currentMaxNights={data?.maxNights}
              fetchStatusStep={fetchStatusStep}
            />
          )}
          {type === NATIVE_LISTING_MENU.ICAL && (
            <NativeListingICal
              propertyId={propertyId}
              listingData={data}
              currentMinNights={data?.minNights}
              currentMaxNights={data?.maxNights}
              fetchStatusStep={fetchStatusStep}
            />
          )}
          {type === NATIVE_LISTING_MENU.FEES && (
            <NativeListingFees propertyId={propertyId} listingData={data} fetchStatusStep={fetchStatusStep} />
          )}
          {type === NATIVE_LISTING_MENU.DISCOUNTS && (
            <NativeListingDiscounts propertyId={propertyId} listingData={data} fetchStatusStep={fetchStatusStep} />
          )}
          {type === NATIVE_LISTING_MENU.RATES && (
            <NativeListingRates propertyId={propertyId} listingData={data} fetchStatusStep={fetchStatusStep} />
          )}
          {type === NATIVE_LISTING_MENU.PUBLISH && <NativeListingPublish listingData={data} />}
        </div>
      </div>
      <EditBulkListingConfirmDialog />
    </LayoutV2>
  )
}

export default NativeListing
