import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { TableCellProps } from '@mui/material/TableCell/TableCell'
import { styled } from '@mui/material/styles'
import React from 'react'

interface Props extends TableCellProps {
  mobilehidden?: boolean
  minwidth?: string
}

export const TableCellCustom: React.FC<Props> = styled(TableCell)(({ ...props }: Props) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: '12px',
    lineHeight: '16px',
    padding: '8px 16px',
    fontFamily: 'Maison Neue Medium',
    color: '#6A6A68',
    letterSpacing: '0.04em',
    boxSizing: 'border-box',
    backgroundColor: '#FFFFFF',
    '@media only screen and (max-width: 767px)': {
      display: props.mobilehidden ? 'none' : 'table-cell',
    },
    '@media only screen and (min-width: 768px)': {
      minWidth: props.minwidth,
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '14px',
    lineHeight: '18px',
    padding: '18.5px 16px',
    fontFamily: 'Maison Neue Regular',
    color: '#6A6A68',
    // borderBottom: '1px solid #EBE9E9',
    boxSizing: 'border-box',
    '@media only screen and (max-width: 767px)': {
      display: props.mobilehidden ? 'none' : 'table-cell',
    },
    '@media only screen and (min-width: 768px)': {
      minWidth: props.minwidth,
    },
  },
  [`&.${tableCellClasses.body} .shadow`]: {
    position: 'relative',
  },
}))

export default TableCellCustom
