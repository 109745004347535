import { updateMultiListing } from '../../api/listings'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { useAppDispatch } from '../../redux/hooks'
import { setToastSuccess, setTriggerListingProperty } from '../../redux/slices/common'
import { handleErrorMessage } from '../../utils/common'
import { handleShowListingStatusAfterImport } from '../../utils/property'
import { IconClose } from '../common/Icons'
import BasicButton from '../ui/BasicButton'
import BasicRadio from '../ui/BasicRadio'
import BasicSwipeDrawer from '../ui/BasicSwipeDrawer'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import React, { useState } from 'react'

interface Props {
  anchorEl: HTMLButtonElement | null
  onClose: () => void
  selectedIds: number[]
  afterChangeStatus?: (propertyIds: number[], newStatus: string) => void
  isSelectedAllPage?: boolean
}

const OPTIONS = [
  {
    value: 'on',
    label: 'Listed',
    desc: 'Guests can visit your direct booking page and book.',
  },
  {
    value: 'off',
    label: 'Unlisted',
    desc: 'Guests can visit your direct booking page, but cannot book.',
  },
]

const PropertyEditStatus: React.FC<Props> = ({
  anchorEl,
  onClose,
  selectedIds,
  isSelectedAllPage,
  afterChangeStatus,
}) => {
  const dispatch = useAppDispatch()
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const [value, setValue] = useState<string>('')

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setValue(value)
  }

  const handleUpdateStatus = async () => {
    setSubmitLoading(true)
    try {
      const status = value === 'on' ? 'active' : 'inactive'
      const res = await updateMultiListing({
        ids: selectedIds,
        publishedStatus: status,
        updateAll: isSelectedAllPage,
      })
      await handleShowListingStatusAfterImport(selectedIds.join(',')) // fetch status to show on import background
      dispatch(
        setToastSuccess({
          message: res.data.message,
        })
      )
      if (typeof afterChangeStatus === 'function') {
        afterChangeStatus(selectedIds, status)
      }
      dispatch(setTriggerListingProperty()) // to recall api
      onClose()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setSubmitLoading(false)
    }
  }

  const renderContent = () => {
    return (
      <div className={'flex flex-col justify-between h-full'}>
        <div className={'flex gap-[16px] items-start'}>
          <p
            className={
              'mb-[24px] font-maison-neue text-24-36 md:font-maison-neue-demibold md:text-14-18 md:tracking-[0.56px] text-neutral-800 md:uppercase text-center md:text-left flex-1'
            }
          >
            {isSelectedAllPage
              ? 'Edit all listings'
              : `Edit ${selectedIds.length} ${selectedIds.length > 1 ? 'listings' : 'listing'}`}
          </p>
          <span className={'block md:hidden'}>
            <IconButton onClick={onClose} sx={{ padding: 0, minWidth: 20, marginLeft: 'auto' }}>
              <IconClose />
            </IconButton>
          </span>
        </div>
        {/*Body*/}
        <div className={'flex flex-col gap-[24px] mb-[24px]'}>
          {OPTIONS.map((option) => {
            return (
              <label
                key={option.value}
                className={'flex justify-between items-center gap-[32px] rounded-[16px] cursor-pointer'}
                htmlFor={`property_status_${option.value}`}
              >
                <div className={'flex flex-col gap-[8px]'}>
                  <span
                    className={`px-[8px] pt-[2px] pb-[1px] font-maison-neue-medium text-12-16 tracking-[.04em] uppercase w-fit rounded-full ${
                      option.value === 'on' ? 'bg-forest-50 text-forest-700' : 'bg-red-50 text-red-700'
                    }`}
                  >
                    {option.label}
                  </span>
                  <span className={'font-maison-neue text-14-18 text-neutral-800'}>{option.desc}</span>
                </div>
                <BasicRadio
                  id={`property_status_${option.value}`}
                  type={'radio'}
                  classes={'w-[24px] h-[24px] cursor-pointer'}
                  name={`property_status`}
                  value={option.value}
                  checked={value === option.value}
                  onChange={onChangeValue}
                />
              </label>
            )
          })}
        </div>

        {/*Footer*/}
        <div className={''}>
          <BasicButton
            size={'md'}
            variant={'outlined'}
            color={'black'}
            onClick={handleUpdateStatus}
            loading={submitLoading}
            disabled={!value}
            isRadius100={true}
            clases={'w-full md:w-auto h-[44px] md:h-[40px]'}
          >
            Apply
          </BasicButton>
        </div>
      </div>
    )
  }
  return (
    <>
      {isMobile ? (
        <BasicSwipeDrawer isOpen={open} onClose={onClose} padding={'32px 24px'}>
          {renderContent()}
        </BasicSwipeDrawer>
      ) : (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              transform: 'translateY(8px)',
              padding: '24px',
              boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)',
              borderRadius: '12px',
              width: '342px',
            },
          }}
        >
          {renderContent()}
        </Popover>
      )}
    </>
  )
}

export default PropertyEditStatus
