import { PMS_TYPE } from './common'

export const ACCOUNT_TYPE = {
  HOST: 'host',
  GUEST: 'guest',
  STAFF: 'staff',
}

export const ONBOARDING_STEP = {
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  PERSONA: 'PERSONA',
  INTEREST: 'INTEREST',
  PMS_USAGE: 'PMS_USAGE',
  PMS_SELECT: 'PMS_SELECT',
  LISTING_SOURCE: 'LISTING_SOURCE',
  AIRBNB_IMPORT: 'AIRBNB_IMPORT',
  AIRBNB_CHOOSE_LISTINGS: 'AIRBNB_CHOOSE_LISTINGS',
  AIRBNB_EDIT_PROMPT: 'AIRBNB_EDIT_PROMPT',
  AIRBNB_CONNECT_SUCCESS: 'AIRBNB_CONNECT_SUCCESS',
  AIRBNB_CONNECT_ERROR: 'AIRBNB_CONNECT_ERROR',
  NATIVE_LISTING: 'NATIVE_LISTING',
  PMS_NOT_SUPPORTED: 'PMS_NOT_SUPPORTED',
  PMS_CONNECT_SUCCESS: 'PMS_CONNECT_SUCCESS',
  NEED_SUPPORT: 'NEED_SUPPORT',
  LANDING_PAGE: 'LANDING_PAGE',
  CUSTOMIZE_SITE: 'CUSTOMIZE_SITE',
  SITE_DETAILS_SITE_NAME: 'SITE_DETAILS_SITE_NAME',
  SITE_DETAILS_PAYMENT_METHODS: 'SITE_DETAILS_PAYMENT_METHODS',
  DISTRIBUTION_CHANNELS: 'DISTRIBUTION_CHANNELS',
  SITE_DETAILS_FEES: 'SITE_DETAILS_FEES',
  SITE_DETAILS_CURRENCIES: 'SITE_DETAILS_CURRENCIES',
  SITE_DETAILS_CONTACT_INFO: 'SITE_DETAILS_CONTACT_INFO',
}

// #10 https://dtravelnetwork.atlassian.net/wiki/spaces/DDV/pages/416808961/Onboarding+v3
export const ONBOARDING_STEP_TO_NUMBER: any = {
  CREATE_ACCOUNT: 0,
  PERSONA: 0,
  INTEREST: 0,
  PMS_USAGE: 0,
  PMS_SELECT: 1,
  LISTING_SOURCE: 1,
  AIRBNB_IMPORT: 1,
  AIRBNB_CHOOSE_LISTINGS: 1,
  AIRBNB_CONNECT_SUCCESS: 2,
  AIRBNB_CONNECT_ERROR: 2,
  NATIVE_LISTING: 2,
  PMS_NOT_SUPPORTED: 2,
  NEED_SUPPORT: 2,
  LANDING_PAGE: 2,
  CUSTOMIZE_SITE: 2,
  SITE_DETAILS_SITE_NAME: 2,
  SITE_DETAILS_CONTACT_INFO: 2,
  SITE_DETAILS_CURRENCIES: 2,
  SITE_DETAILS_FEES: 2,
  SITE_DETAILS_PAYMENT_METHODS: 2,
  DISTRIBUTION_CHANNELS: 3,
}

export const CONFIG_PMS = {
  [PMS_TYPE.HOSTAWAY]: {
    title: 'Connect to Hostaway PMS',
    description: 'Sync with your PMS to automatically import and manage your listings.',
    link: 'https://dtravel.com/help/how-to-connect-dtravel-to-hostaway',
  },
  [PMS_TYPE.UPLISTING]: {
    title: 'Connect to Uplisting PMS',
    description: 'Sync with your PMS to automatically import and manage your listings.',
    link: 'https://dtravel.com/help/how-to-connect-dtravel-to-uplisting',
  },
  [PMS_TYPE.GUEST_SMILES]: {
    title: 'Connect to Track via GuestSmiles',
    description:
      'Track is available via GuestSmiles. Create a free account at GuestSmiles and import your API key to connect Track.',
    link: 'https://dtravel.com/help/how-to-connect-dtravel-to-track',
  },
  [PMS_TYPE.NEXTPAX]: {
    title: 'Connect to Nextpax via GuestSmiles',
    description:
      'Nextpax is available via GuestSmiles. Create a free account at GuestSmiles and import your API key to connect Nextpax.',
    link: 'https://dtravel.com/help/how-to-connect-dtravel-to-nextpax',
  },
  [PMS_TYPE.GUESTY]: {
    title: 'Connect to Guesty',
    description: 'Sync with your PMS to automatically import and manage your listings.',
    link: 'https://dtravel.com/help/how-to-connect-dtravel-to-guesty',
  },
  [PMS_TYPE.RENTAL_WISE]: {
    title: 'Connect to RentalWise',
    description: 'Sync with your PMS to automatically import and manage your listings.',
    link: 'https://dtravel.com/help/how-to-connect-dtravel-to-rentalwise',
  },
}
