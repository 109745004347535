import { createCustomUrl, updateCustomUrl } from '../../api/user'
import { setToastSuccess, setTriggerTime } from '../../redux/slices/common'
import { getDomain, isEmpty } from '../../utils/common'
import Error from '../common/Error'
import BasicButton from '../ui/BasicButton'
import BasicPopup from '../ui/BasicPopup'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

interface Props {
  userCustomUrl: any
  handleTriggerOtherPopup?: any
  handleSuccess?: any
}

const CustomUrlDtravelHosted: React.FC<Props> = (props) => {
  const { userCustomUrl, handleTriggerOtherPopup, handleSuccess } = props
  const dispatch = useDispatch()
  const inputRef = useRef<HTMLInputElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [errorClient, setErrorClient] = useState<string>('')
  const [errorApi, setErrorApi] = useState<string>('')
  const [subdomain, setSubdomain] = useState<string>('')
  const [customUrlId, setCustomUrlId] = useState<string>('')

  useEffect(() => {
    if (handleTriggerOtherPopup) handleTriggerOtherPopup(open)
  }, [open])
  const handleUpdateSubdomain = (sub: string) => {
    setSubdomain(sub)
  }
  const handleUpdateCustomUrlId = (customId: string) => {
    setCustomUrlId(customId)
  }

  useEffect(() => {
    if (!isEmpty(userCustomUrl)) {
      handleUpdateSubdomain(userCustomUrl?.customId as string)
      handleUpdateCustomUrlId(userCustomUrl?.id as string)
    } else {
      handleUpdateSubdomain('')
      handleUpdateCustomUrlId('')
    }
  }, [userCustomUrl, open])

  const clearError = () => {
    setErrorClient('')
    setErrorApi('')
  }

  const isHasChangeSubdomain = () => {
    if (!isEmpty(userCustomUrl)) {
      const customId = userCustomUrl?.customId
      return customId !== subdomain
    } else {
      return true
    }
  }

  const handleOpenPopup = () => {
    setOpen(true)
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 300)
  }

  const handleClosePopup = () => {
    setOpen(false)
    clearError()
  }

  const onChangeSubdomain = (value: string) => {
    clearError()
    handleUpdateSubdomain(value)
    if (!value || !value.trim()) {
      setErrorClient('This field is required')
      return
    }
    if (value && value.trim().length < 4) {
      setErrorClient('Minimum number of characters: 4')
      return
    }
    if (value && value.trim().length > 50) {
      setErrorClient('Maximum number of characters: 50')
      return
    }
    if (value && !/^[a-zA-Z0-9-]+$/.test(value)) {
      setErrorClient('Only alphanumeric characters and dashes are allowed')
      return
    }
    if (value.startsWith('-') || value.endsWith('-')) {
      setErrorClient('Subdomain cannot start or end with a dash.')
      return
    }
  }

  const onSubmit = async () => {
    try {
      setSubmitLoading(true)
      if (customUrlId) {
        await updateCustomUrl(customUrlId, subdomain)
        dispatch(setToastSuccess({ message: 'Update custom url success!' }))
      } else {
        await createCustomUrl(subdomain)
        dispatch(setToastSuccess({ message: 'Create custom url success!' }))
      }
      dispatch(setTriggerTime(+new Date())) // trigger to fetch profile again (function fetch profile in Settings.tsx)
      handleClosePopup()
      if (handleSuccess) handleSuccess()
    } catch (err: any) {
      setErrorApi(err.message)
    } finally {
      setSubmitLoading(false)
    }
  }

  return (
    <>
      <BasicButton
        size={'xl'}
        variant={'contained'}
        clases={'w-full h-[48px] rounded-[100px_!important]'}
        color="red"
        onClick={handleOpenPopup}
      >
        {!isEmpty(customUrlId) ? 'Update custom URL' : `Create custom URL`}
      </BasicButton>

      <BasicPopup
        isOpen={Boolean(open)}
        onClose={handleClosePopup}
        title={`${customUrlId ? 'Edit' : 'Confirm'} your custom URL`}
        extraTitle={'Personalize the URL for your Dtravel booking site.'}
      >
        <div>
          <p className={'font-maison-neue-medium text-16-24 text-neutral-8 mb-[8px]'}>Custom URL</p>
          <div
            className={`flex items-center gap-[4px] w-full h-[48px] rounded-[8px] px-[16px] py-[12px] border ${
              errorClient || errorApi ? 'border-red-6' : 'border-neutral-3'
            } `}
          >
            <div className={'font-maison-neue-medium text-16-24 text-neutral-5'}>https://</div>
            <input
              ref={inputRef}
              className={`focus:outline-none font-maison-neue-medium text-16-24 min-w-0 ${
                errorClient || errorApi ? 'text-red-6' : 'text-neutral-7'
              }`}
              value={subdomain}
              onChange={(event) => onChangeSubdomain(event.target.value)}
              minLength={4}
              maxLength={50}
            />
            <div className={'font-maison-neue-medium text-16-24 text-neutral-5 ml-auto'}>.{getDomain()}</div>
          </div>
          {errorClient && <Error show={true} message={errorClient} classes={'text-12-16'} />}
          {errorApi && (
            <div className={'p-4 bg-red-1 rounded-[12px] flex gap-[8px] mt-[8px]'}>
              <span>
                <ErrorOutlineOutlinedIcon sx={{ color: '#A32A30' }} />
              </span>
              <div className={'w-full break-all'}>
                <p className={'font-maison-neue-medium text-16-20 text-red-6'}>{errorApi}</p>
                <p className={'font-maison-neue text-12-16 text-neutral-7 '}>
                  Please remove your current custom URL then try again.
                </p>
              </div>
            </div>
          )}
        </div>

        <div className={'mt-[24px]'}>
          <BasicButton
            // color={'red'}
            variant={'contained'}
            size={'xl'}
            clases={'w-full h-[48px] rounded-[100px_!important]'}
            color="red"
            loading={submitLoading}
            onClick={onSubmit}
            disabled={!subdomain || !!errorClient || !!errorApi || !isHasChangeSubdomain()}
          >
            Submit
          </BasicButton>
        </div>

        {customUrlId && (
          <p className={'mt-[16px] font-maison-neue text-14-18 text-neutral-6 text-center'}>
            Note: Your previous URL will no longer be active, nor will it redirect to your new one, so please update
            sparingly.
          </p>
        )}
      </BasicPopup>
    </>
  )
}

export default CustomUrlDtravelHosted
