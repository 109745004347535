import ic_edit_pencil_line from '../../../assets/icons/ic_edit_pencil_line.svg'
import { setTriggerResetCalendar } from '../../../redux/slices/listing'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateICalCalendar } from 'src/api/native-listing'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import BasicInput from 'src/components/ui/BasicInput'
import { FormError } from 'src/interfaces'
import { setToastSuccess } from 'src/redux/slices/common'
import { getKeys, handleErrorMessage, isValidHttpUrl } from 'src/utils/common'

interface Props {
  propertyId: string
  id: string | number
  iCalUrl: string
  iCalName: string
  fetchICal?: (propertyId: string) => Promise<void>
}

const EditICalCalendar: React.FC<Props> = ({ id, iCalUrl, iCalName, propertyId, fetchICal }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState<{ name: string; url: string }>({ name: '', url: '' })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [errors, setErros] = useState<{ name: FormError; url: FormError }>({
    name: { show: false, message: '' },
    url: { show: false, message: '' },
  })

  useEffect(() => {
    setData((prevState) => ({ ...prevState, url: iCalUrl, name: iCalName }))
  }, [iCalUrl, iCalName, isOpen])

  const handleOpenPopup = () => {
    setIsOpen(true)
  }

  const handleClosePopup = () => {
    setIsOpen(false)
  }

  const onChangData = (field: string, value: string) => {
    setData((prevState) => ({ ...prevState, [field]: value }))
    setErros((prevState) => ({ ...prevState, [field]: { show: false, message: '' } }))
  }

  const onSubmit = async () => {
    if (!propertyId) {
      handleErrorMessage({ message: 'Missing property id' })
      return
    }
    let isError: boolean = false
    for (const field of getKeys(data)) {
      if (!data[field] || !data[field].trim()) {
        setErros((prevState) => ({ ...prevState, [field]: { show: true, message: 'Please enter a value' } }))
        isError = true
      }
      if (field === 'url' && !isValidHttpUrl(data[field])) {
        setErros((prevState) => ({ ...prevState, [field]: { show: true, message: 'Please enter a valid url' } }))
        isError = true
      }
    }

    if (isError) {
      return
    }
    try {
      setIsLoading(true)
      const bodyData = { ...data }
      await updateICalCalendar(propertyId, id, bodyData)
      dispatch(setToastSuccess({ message: 'Edit calendar success' }))
      dispatch(setTriggerResetCalendar(+new Date()))
      if (typeof fetchICal === 'function') {
        await fetchICal(propertyId)
      }
      handleClose()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    setData({ name: '', url: '' }) // clear data before close
    handleClosePopup()
  }

  return (
    <>
      <button
        className={clsx(
          'w-[36px] h-[36px] rounded-full border border-neutral-300 shadow-xs flex items-center justify-center',
          'hover:bg-neutral-200'
        )}
        onClick={() => handleOpenPopup()}
      >
        <img alt={'ic_edit_pencil_line'} src={ic_edit_pencil_line} />
      </button>

      <BasicDialog
        isOpen={isOpen}
        onClose={handleClose}
        title={'Edit a calendar'}
        extraTitle={
          <p className={'font-maison-neue text-14-20 text-neutral-600 text-center mt-[8px]'}>
            Keep your calendar updated by importing booked and unavailable dates from booking services and other
            calendars you use. Note that importing a calendar can take a few minutes.
          </p>
        }
        maxWidth={'md'}
      >
        <div className={''}>
          <BasicInput
            label={'iCal address (URL)'}
            placeholder={'Paste your URL...'}
            value={data.url}
            onChange={(event) => onChangData('url', event.target.value)}
            error={errors.url}
          />
        </div>
        <div className={'mt-[24px]'}>
          <BasicInput
            label={'Name'}
            placeholder={'Name this calendar...'}
            value={data.name}
            onChange={(event) => onChangData('name', event.target.value)}
            error={errors.name}
            maxLength={20}
          />
        </div>
        <div className={'mt-[24px]'}>
          <BasicButton
            variant={'contained'}
            size={'md'}
            color={'red'}
            isRadius100={true}
            clases={'w-full'}
            loading={isLoading}
            onClick={onSubmit}
            disabled={!data.url || !data.name}
          >
            Connect
          </BasicButton>
        </div>
      </BasicDialog>
    </>
  )
}

export default EditICalCalendar
