import { getChannexChannels } from '../../api/integration'
import { DATE_FORMAT, MONTH_FORMAT } from '../../contants/common'
import { PROPERTY_STATUS } from '../../contants/native'
import { Paging, ServerResponse } from '../../interfaces'
import { setOnBoardingTotalListing } from '../../redux/slices/user'
import LayoutV2 from '../layout/LayoutV2'
import AirbnbImportFlow from '../property-import-airbnb/AirbnbImportFlow'
import BasicButton from '../ui/BasicButton'
import BasicPopup from '../ui/BasicPopup'
import PopupAddListing from './PopupAddListing'
import PropertyFilters from './PropertyFilters'
import PropertySearch from './PropertySearch'
import PropertyTopSection from './PropertyTopSection'
import TableListing from './TableListing'
import TableListingCalendar from './TableListingCalendar'
import ThreeWaysAddListing from './ThreeWaysAddListing'
import axios from 'axios'
import clsx from 'clsx'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getListings, getSourcesListing } from 'src/api/listings'
import ic_warning_circle_white from 'src/assets/icons/ic_warning_circle_white.svg'
import { ListingItem, ListingParams, ListingView } from 'src/interfaces/listing'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setLoading } from 'src/redux/slices/common'
import { setShowPopupReviewing } from 'src/redux/slices/listing'
import { isEmpty, removeDuplicate } from 'src/utils/common'

const PAGE_SIZE_LISTING = 15

const PropertyList = () => {
  const intervalRef = useRef<any>(null)
  const cancelToken = useRef<any>(null)
  const isSearchingOrFiltering = useRef<boolean>(false)
  const idsBeforeSearchAndFilter = useRef<number[]>([])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { search, pathname } = useLocation()
  const { triggerFetchListingProperty, isLoading } = useAppSelector((state) => state.common)
  const { pmsTypeConnected, isConnectedAirbnb } = useAppSelector((state) => state.user)
  const { isShowPopupReviewing, airbnbChannelId } = useAppSelector((state) => state.listing)
  const [properties, setProperties] = useState<ListingItem[]>([])
  const [allProperties, setAllProperties] = useState<ListingItem[]>([]) // cache all property multiple page
  const [paging, setPaging] = useState<Paging>({ page: 1, pageSize: PAGE_SIZE_LISTING, total: 0, totalPages: 0 })
  const [isShowBanner, setIsShowBanner] = useState<boolean>(false)
  const [filters, setFilters] = useState<any>({
    page: 1,
    pageSize: PAGE_SIZE_LISTING,
  })
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [sourcesList, setSourcesList] = useState<any[]>([])
  const [channels, setChannels] = useState<any[]>([])
  const isEnableGVR = channels.find((v: any) => v?.channel === 'google')?.status
  const [view, setView] = useState<ListingView>(ListingView.LIST)

  useEffect(() => {
    async function fetchChannexChannels() {
      try {
        const res = await getChannexChannels()
        if (Array.isArray(res.data)) {
          setChannels(res.data)
        }
      } catch (err: any) {
        console.log(err)
      }
    }
    fetchChannexChannels()
  }, [])

  const fetchListingListedForHostOnboarding = async () => {
    const res: ServerResponse = await getListings({ status: PROPERTY_STATUS.LISTED, page: 1, pageSize: 1 })
    dispatch(setOnBoardingTotalListing(res?.paging?.total || 0))
  }

  useEffect(() => {
    async function fetchSources() {
      try {
        const res = await getSourcesListing()
        if (!isEmpty(res?.data)) {
          const _convert = res?.data.map((item: string) => (item === 'hospitable' ? 'airbnb' : item))
          setSourcesList([...new Set(_convert)])
        }
      } catch (err) {
        console.log(err)
      }
    }
    fetchSources()
  }, [])

  useEffect(() => {
    // for host onboarding: after create native listing
    fetchListingListedForHostOnboarding()
  }, [])

  const fetchtDataInit = async (_filters?: any) => {
    try {
      dispatch(setLoading(true))
      let params: any = { page: 1, pageSize: PAGE_SIZE_LISTING }
      if (_filters?.page) {
        params.page = _filters.page
      }
      if (_filters?.pageSize) {
        params.pageSize = _filters.pageSize
      }
      if (Array.isArray(_filters?.publishedStatus) && _filters?.publishedStatus.length > 0) {
        params.status = _filters.publishedStatus.join(',')
      }
      if (_filters?.fiat) {
        params.fiat = _filters.fiat === '1' ? 'true' : 'false'
      }
      if (_filters?.crypto) {
        params.crypto = _filters.crypto === '1' ? 'true' : 'false'
      }
      if (_filters?.search) {
        params.search = _filters.search
      }
      if (Array.isArray(_filters?.channel) && _filters?.channel.length > 0) {
        params.channel = _filters.channel.join(',')
      }
      if (_filters?.channelStatus) {
        params.channelStatus = _filters.channelStatus === '1' ? 'listed' : 'unlisted'
      }
      if (_filters?.damageProtection) {
        params.damageProtection = _filters.damageProtection === '1' ? 'true' : 'false'
      }
      if (_filters?.guestScreening) {
        params.guestScreening = _filters.guestScreening === '1' ? 'true' : 'false'
      }
      if (_filters?.month) {
        const isCurrentMonth = _filters?.month === moment().format(MONTH_FORMAT)
        const startDate = isCurrentMonth ? moment() : moment(_filters?.month, MONTH_FORMAT).startOf('month')
        const endDate = isCurrentMonth ? moment().add(1, 'month') : moment(_filters?.month, MONTH_FORMAT).endOf('month')
        params.startDate = startDate.format(DATE_FORMAT)
        params.endDate = endDate.format(DATE_FORMAT)
      }

      if (cancelToken.current) {
        cancelToken.current.cancel('request canceled')
      }
      cancelToken.current = axios.CancelToken.source()
      const res: ServerResponse = await getListings(params, cancelToken.current)
      if (res && Array.isArray(res?.data)) {
        const { data, paging } = res
        setPaging(paging)
        setProperties(data)
        setAllProperties(removeDuplicate([...properties, ...(data || [])]))
        return data
      } else {
        dispatch(setLoading(false))
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const fetchDataWithCustomPageSize = async (_filters: any) => {
    try {
      dispatch(setLoading(true))
      let params: any = { page: 1 }
      if (Array.isArray(_filters?.publishedStatus) && _filters?.publishedStatus.length > 0) {
        params.status = _filters.publishedStatus.join(',')
      }
      if (_filters?.fiat) {
        params.fiat = _filters.fiat === '1' ? 'true' : 'false'
      }
      if (_filters?.crypto) {
        params.crypto = _filters.crypto === '1' ? 'true' : 'false'
      }
      // if (_filters?.channel) {
      //   params.channel = _filters.channel
      // }
      if (Array.isArray(_filters?.channel) && _filters?.channel.length > 0) {
        params.channel = _filters.channel.join(',')
      }
      if (_filters?.channelStatus) {
        params.channelStatus = _filters.channelStatus === '1' ? 'listed' : 'unlisted'
      }
      if (_filters?.damageProtection) {
        params.damageProtection = _filters.damageProtection === '1' ? 'true' : 'false'
      }
      if (_filters?.guestScreening) {
        params.guestScreening = _filters.guestScreening === '1' ? 'true' : 'false'
      }
      if (_filters?.search) {
        params.search = _filters.search
      }
      if (_filters?.pageSize) {
        params.pageSize = _filters.pageSize
        // params.pageSize = 10000
      }
      return await getListings(params)
    } catch (err) {
      return { data: [] }
    } finally {
      dispatch(setLoading(false))
    }
  }

  const countFilter = () => {
    let count = 0
    for (let key in filters) {
      if (
        filters[key] &&
        ['fiat', 'crypto', 'search', 'guestScreening', 'damageProtection', 'channelStatus'].includes(key)
      )
        count++
    }
    if (Array.isArray(filters.channel) && filters.channel.length > 0) {
      count++
    }
    if (Array.isArray(filters.publishedStatus) && filters.publishedStatus.length > 0) {
      count++
    }
    return count
  }

  useEffect(() => {
    if (Array.isArray(properties) && properties.length > 0) {
      const doNotAnyPaymentMethod = properties.every((item) => !item.fiatPaymentEnabled && !item.cryptoPaymentEnabled)
      setIsShowBanner(doNotAnyPaymentMethod && countFilter() === 0)
    }
  }, [properties, filters])

  // get params from url params set to Filters
  useEffect(() => {
    const params: ListingParams = queryString.parse(search)
    const _filters: any = {
      page: 1,
      pageSize: PAGE_SIZE_LISTING,
    }
    if (params.page) {
      _filters.page = Number(params.page)
    }
    if (params.pageSize) {
      _filters.pageSize = Number(params.pageSize)
    }
    if (params.publishedStatus) {
      _filters.publishedStatus = params.publishedStatus.split(',')
    } else {
      _filters.publishedStatus = []
    }
    if (params.fiat) {
      _filters.fiat = params.fiat
    }
    if (params.crypto) {
      _filters.crypto = params.crypto
    }
    if (params.search) {
      _filters.search = params.search
    }
    if (params.channel) {
      _filters.channel = params.channel.split(',')
    } else {
      _filters.channel = []
    }
    if (params.channelStatus) {
      _filters.channelStatus = params.channelStatus
    }
    if (params.guestScreening) {
      _filters.guestScreening = params.guestScreening
    }
    if (params.damageProtection) {
      _filters.damageProtection = params.damageProtection
    }
    if (params.month) {
      _filters.month = params.month
    }
    setFilters(_filters)
  }, [search])

  useEffect(() => {
    fetchtDataInit(filters)
  }, [
    triggerFetchListingProperty,
    filters.page,
    filters.pageSize,
    filters.search,
    filters.publishedStatus,
    filters.fiat,
    filters.crypto,
    filters.channel,
    filters.channelStatus,
    filters.guestScreening,
    filters.damageProtection,
    filters.month,
  ])

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  const resetSelectedIdsBeforeSearch = () => {
    // reset ids before search (test case)
    isSearchingOrFiltering.current = false
    idsBeforeSearchAndFilter.current = []
  }

  // old filters
  const handleChangeSearchAndPaging = async (field: string, value: string | any) => {
    // value any support field payment (crypto and fiat)
    if (!isSearchingOrFiltering.current) {
      idsBeforeSearchAndFilter.current = selectedIds // store selectedIds before filter (to keep selected ids after clear filter)
      // setSelectedIdsBeforeFilter(selectedIds)
    }
    const parsed = queryString.parse(search)
    let newFilters: any = { ...parsed }
    if (field === 'payment') {
      newFilters = { ...newFilters, ...value } // with field is payment, value is {crypto: '0'/'1', fiat: '0'/'1'}
    } else {
      newFilters = { ...newFilters, [field]: value }
    }
    if (['search', 'fiat', 'crypto', 'publishedStatus', 'channel', 'payment', 'channelStatus'].includes(field)) {
      delete newFilters.page
    }
    if (newFilters.search === '') {
      delete newFilters.search
    }
    // handle case clear search
    if (field === 'search' && value === '') {
      isSearchingOrFiltering.current = false
      setSelectedIds((prevState) =>
        idsBeforeSearchAndFilter.current.length > 0 ? idsBeforeSearchAndFilter.current : prevState
      )
    } else {
      isSearchingOrFiltering.current = true
      // fetch all data filters, then get ids save to idSelected
      const res: ServerResponse = await fetchDataWithCustomPageSize({ ...newFilters, pageSize: 10000 })
      const { data } = res
      // set selected ids with data after filter
      setSelectedIds(() => {
        const ids = data.map((item: any) => item.id)
        return idsBeforeSearchAndFilter.current.filter((id) => ids.includes(id))
      })
    }

    // navigate with params
    const stringified = queryString.stringify(newFilters, { skipEmptyString: true, skipNull: true })
    navigate(pathname + stringified ? '?' + stringified : '')
  }

  const handleApplyFilters = async (_currentFilters: any) => {
    if (!isSearchingOrFiltering.current) {
      idsBeforeSearchAndFilter.current = selectedIds // store selectedIds before filter (to keep selected ids after clear filter)
      // setSelectedIdsBeforeFilter(selectedIds)
    }
    const parsed = queryString.parse(search)
    let newFilters: any = { ...parsed, ..._currentFilters }
    // if (['search', 'fiat', 'crypto', 'publishedStatus', 'channel', 'payment', 'channelStatus'].includes(field)) {
    //   delete newFilters.page
    // }
    delete newFilters.page
    if (newFilters.search === '') {
      delete newFilters.search
    }
    // handle case clear search
    isSearchingOrFiltering.current = true
    // fetch all data filters, then get ids save to idSelected
    const res: ServerResponse = await fetchDataWithCustomPageSize({ ...newFilters, pageSize: 10000 })
    const { data } = res
    // set selected ids with data after filter
    setSelectedIds(() => {
      const ids = data.map((item: any) => item.id)
      return idsBeforeSearchAndFilter.current.filter((id) => ids.includes(id))
    })
    // navigate with params
    const stringified = queryString.stringify(newFilters, {
      skipEmptyString: true,
      skipNull: true,
      arrayFormat: 'comma',
    })
    navigate(pathname + stringified ? '?' + stringified : '')
  }

  const handleResetFilters = () => {
    // restore selected ids before filters
    setSelectedIds((prevState) =>
      idsBeforeSearchAndFilter.current.length > 0 ? idsBeforeSearchAndFilter.current : prevState
    )
    // navigate with params
    navigate(pathname)
  }

  const afterChangeStatus = (propertyIds: number[], newStatus: string) => {
    setProperties((prevState) => {
      return prevState.map((item) => {
        if (propertyIds.includes(item.id)) {
          if (newStatus === 'active' && !item.cryptoPaymentEnabled && !item.fiatPaymentEnabled) {
            // nothihng
          } else {
            if (item.status !== PROPERTY_STATUS.DRAFT && item.status !== PROPERTY_STATUS.PENDING_REVIEW) {
              item.publishedStatus = newStatus
              item.isActive = newStatus === 'active'
            }
          }
        }
        return item
      })
    })
    setAllProperties((prevState) => {
      return prevState.map((item) => {
        if (propertyIds.includes(item.id)) {
          if (newStatus === 'active' && !item.cryptoPaymentEnabled && !item.fiatPaymentEnabled) {
            // nothihng
          } else {
            if (item.status !== PROPERTY_STATUS.DRAFT && item.status !== PROPERTY_STATUS.PENDING_REVIEW) {
              item.publishedStatus = newStatus
              item.isActive = newStatus === 'active'
            }
          }
        }
        return item
      })
    })
  }

  const afterUpdateSuperhog = (propertyIds: number[], newSuperhogStatus: string) => {
    setProperties((prevState) => {
      return prevState.map((item) => {
        if (propertyIds.includes(item.id)) {
          item.superhogStatus = newSuperhogStatus
        }
        return item
      })
    })
    setAllProperties((prevState) => {
      return prevState.map((item) => {
        if (propertyIds.includes(item.id)) {
          item.superhogStatus = newSuperhogStatus
        }
        return item
      })
    })
  }

  const onSelectedOneRow = (propertyId: number, isChecked: boolean) => {
    resetSelectedIdsBeforeSearch()
    if (isChecked) {
      setSelectedIds((prevState: any) => [...prevState, propertyId])
    } else {
      setSelectedIds((prevState: any) => {
        return prevState.filter((item: number) => item !== propertyId)
      })
    }
  }

  const onSlectedAllRow = (isChecked: boolean) => {
    resetSelectedIdsBeforeSearch()
    setSelectedIds((prevState) => {
      const propertyIds = properties.map((item) => item.id)
      if (isChecked) {
        const allowIds = propertyIds.filter((id) => !prevState.includes(id))
        return [...prevState, ...allowIds]
      } else {
        return prevState.filter((id) => !propertyIds.includes(id))
      }
    })
  }

  const toggleSelectedAllPage = async (isSelect: boolean) => {
    if (isSelect) {
      // fetch all data from api with current filters, page = 1, pageSize = total items
      const res: ServerResponse = await fetchDataWithCustomPageSize({ ...filters, pageSize: paging.total })
      const { data } = res
      setSelectedIds(data.map((item: any) => item.id))
    } else {
      // clear all ids selected
      setSelectedIds([])
    }
  }

  const onChangeView = (view: ListingView) => {
    setView(view)
  }

  const isConnectedPms = pmsTypeConnected || isConnectedAirbnb || airbnbChannelId
  const isShowConnectPms =
    !isConnectedPms &&
    properties.length === 0 &&
    countFilter() === 0 &&
    (!filters.page || filters.page === 1) &&
    !isLoading

  return (
    <LayoutV2 showDefaultHeader={true} isFullWidth={true}>
      <div className={'w-full px-0 md:px-[24px] lg:px-[32px] mb-[20px]'}>
        <div className={isShowConnectPms ? 'hidden' : 'block'}>
          <PropertyTopSection total={paging.total} view={view} onChangeView={onChangeView} />
        </div>

        {/* --Filters and Search--*/}
        <div className={'flex items-center justify-between w-full px-[16px] md:px-0 mb-[16px]'}>
          <PropertyFilters filters={filters} handleApply={handleApplyFilters} sourcesList={sourcesList} />
          <PropertySearch filters={filters} handleChangeSearchAndPaging={handleChangeSearchAndPaging} />
        </div>

        {isShowBanner && (
          <div className={'flex items-center h-[40px] gap-4 bg-red-6 px-[16px]'}>
            <span>
              <img src={ic_warning_circle_white} alt={'ic_warning_circle_white'} />
            </span>
            <span className={'font-maison-neue-medium text-12-16 text-white'}>
              Add a payment method to activate your listings
            </span>
          </div>
        )}

        <div className={'text-16-24 mt-0'}>
          {isShowConnectPms ? (
            <div
              className={clsx(
                'px-[16px] md:px-[32px] lg:px-[48px] xl:px-0 xl:max-w-[1136px] 2xl:max-w-[1284px] m-auto'
              )}
            >
              <ThreeWaysAddListing />
            </div>
          ) : view === ListingView.LIST ? (
            <TableListing
              isEnableGVR={isEnableGVR}
              properties={properties}
              allProperties={allProperties}
              paging={paging}
              afterChangeStatus={afterChangeStatus}
              afterUpdateSuperhog={afterUpdateSuperhog}
              countFilter={countFilter()}
              filters={filters}
              handleChangeSearchAndPaging={handleChangeSearchAndPaging}
              handleResetFilters={handleResetFilters}
              onSelectedOneRow={onSelectedOneRow}
              onSlectedAllRow={onSlectedAllRow}
              selectedIds={selectedIds}
              toggleSelectedAllPage={toggleSelectedAllPage}
            />
          ) : (
            <TableListingCalendar
              properties={properties}
              paging={paging}
              handleChangeSearchAndPaging={handleChangeSearchAndPaging}
              handleResetFilters={handleResetFilters}
              currentMonth={filters.month || moment().format(MONTH_FORMAT)}
            />
          )}
        </div>
      </div>
      <BasicPopup
        isOpen={isShowPopupReviewing}
        onClose={() => dispatch(setShowPopupReviewing(false))}
        title={'We’re reviewing your request'}
        extraTitle={
          <p className={'font-maison-neue text-16-24 text-black text-center mt-[8px] whitespace-pre-line'}>
            Your beta access request is pending review by Dtravel. You’ll be notified if you’re approved to be part of
            the beta.
          </p>
        }
      >
        <div className={'mt-2'}>
          <BasicButton
            variant={'contained'}
            color={'red'}
            clases={'w-full'}
            onClick={() => {
              dispatch(setShowPopupReviewing(false))
            }}
            isRadius100
          >
            <span>Dismiss</span>
          </BasicButton>
        </div>
      </BasicPopup>

      <PopupAddListing />

      <AirbnbImportFlow />
    </LayoutV2>
  )
}

export default PropertyList
