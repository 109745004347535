import App from './App'
import { store } from './redux/store'
import reportWebVitals from './reportWebVitals'
import { Buffer } from 'buffer'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import ThirdwebWalletProvider from './components/thirdweb/ThirdwebWalletProvider'
// import SmartWalletThirdWeb from './components/thirdweb/SmartWalletThirdWeb'

window.Buffer = window.Buffer || Buffer

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  // <SmartWalletThirdWeb>
  <Provider store={store}>
    <BrowserRouter basename={process.env.REACT_APP_BASE_PATH || ''}>
      <ThirdwebWalletProvider>
        <App />
      </ThirdwebWalletProvider>
    </BrowserRouter>
  </Provider>
  // </SmartWalletThirdWeb>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
modifyHubSport()

// eslint-disable-next-line
function modifyHubSport() {
  let countInterval = 0
  let checkElementInterval = setInterval(() => {
    const hsIframe = document.getElementById('hubspot-messages-iframe-container')
    if (hsIframe) {
      const isMobile = window.innerWidth < 768
      if (isMobile) {
        hsIframe.style.width = '0px'
        hsIframe.style.height = '0px'
        const iframe = hsIframe.querySelector('iframe')
        if (iframe) {
          hsIframe.removeChild(iframe)
        }
      }
      hsIframe.style.zIndex = '98'

      clearInterval(checkElementInterval)
    } else if (countInterval === 20) {
      clearInterval(checkElementInterval)
    } else {
      countInterval++
    }
  }, 1500)
}
