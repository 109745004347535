import BasicButton from '../ui/BasicButton'
import BasicDialog from '../ui/BasicDialog'
import BasicTooltip from '../ui/BasicTooltip'
import RenderListingItem from './RenderListingItem'
import React from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  onSkip: () => void
  onClickEdit: (data: any) => void
  listings: any[]
  updateListings: (newListings: any[]) => void
}

const AirbnbPopupEditPrompt: React.FC<Props> = ({ isOpen, onClose, onClickEdit, listings, onSubmit, onSkip }) => {
  const isHasSaved = listings.some((item) => item.isSaved)

  return (
    <BasicDialog
      isOpen={isOpen}
      onClose={onClose}
      title={'Add missing info to your Dtravel listing(s)'}
      extraTitle={
        'Dtravel wasn’t able to import all content so please fill in the missing info to complete your listing(s).'
      }
      maxWidth={'md'}
    >
      <div className={'rounded-[16px] bg-neutral-100 border border-neutral-300 h-[543px] overflow-y-auto'}>
        {Array.isArray(listings) &&
          listings.map((item, index) => {
            return <RenderListingItem key={item.id || index} item={item} onClickEdit={onClickEdit} />
          })}
      </div>
      <div className={'flex items-center justify-end gap-[16px] mt-[24px]'}>
        {isHasSaved ? (
          <BasicButton
            variant={'contained'}
            size={'md'}
            isRadius100={true}
            color={'red'}
            disabled={!isHasSaved}
            onClick={onSubmit}
          >
            Finish
          </BasicButton>
        ) : (
          <BasicTooltip
            title={
              <div className={'text-12-18 font-inter-600'}>
                You can always modify individual listing details later on
              </div>
            }
            placement={'left'}
            arrow={true}
            sx={{
              '& .MuiTooltip-tooltip': { padding: '8px 12px', borderRadius: '8px', backgroundColor: '#0F0F0E' },
            }}
          >
            <div>
              <BasicButton variant={'outlined'} size={'md'} isRadius100={true} onClick={onSkip}>
                Skip
              </BasicButton>
            </div>
          </BasicTooltip>
        )}
      </div>
    </BasicDialog>
  )
}

export default AirbnbPopupEditPrompt
