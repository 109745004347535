import React, { useState } from 'react'
import { deleteBusinessUser } from 'src/api/user'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import { useAppDispatch } from 'src/redux/hooks'
import { setToastError, setToastSuccess } from 'src/redux/slices/common'

interface Props {
  fetchData: () => void
  userIds: string[]
}

const DeleteUsers = ({ fetchData, userIds }: Props) => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleDeleteUsers = async () => {
    try {
      setLoading(true)
      await deleteBusinessUser(userIds)
      fetchData()
      handleClose()
      dispatch(setToastSuccess({ message: `Delete user successfully.` }))
    } catch (error: any) {
      dispatch(setToastError({ message: error?.message }))
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <BasicButton
        onClick={handleOpen}
        size="sm"
        variant={'outlined'}
        isRadius100={true}

      >
        Delete users
      </BasicButton>
      <BasicDialog
        isOpen={open}
        title={'Delete users?'}
        extraTitle={'Are you sure you want to delete users?'}
        onClose={handleClose}
        allowBackdropClick
        titleAlign={'center'}
      >
        <div className={'flex flex-col gap-[8px]'}>
          <BasicButton variant={'contained'} color={'red'} isRadius100={true} disabled={loading} onClick={handleDeleteUsers}>
            Confirm
          </BasicButton>
          <BasicButton variant={'outlined'} isRadius100={true} onClick={handleClose}>
            Cancel
          </BasicButton>
        </div>
      </BasicDialog>
    </>
  )
}

export default DeleteUsers
