import { getRateCurrency } from '../../../api/integration'
import { DEFULT_ERROR_DAY_OF_WEEK } from '../../../contants/property'
import { FormError } from '../../../interfaces'
import { useAppSelector } from '../../../redux/hooks'
import { convertPriceByCurrency, handleErrorMessage, isEmpty, numberWithCommas } from '../../../utils/common'
import MarkupRate from './MarkupRate'
import clsx from 'clsx'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getRates, updateBulkProperties, updateProperty, updateRates } from 'src/api/native-listing'
import DisabledSectionOnEdit from 'src/components/common/DisabledSectionOnEdit'
import NativeListingBackAndContinue from 'src/components/native-listing/NativeListingBackAndContinue'
import RateBase from 'src/components/native-listing/rates/RateBase'
import RateCustom from 'src/components/native-listing/rates/RateCustom'
import RatePerDayOfWeek from 'src/components/native-listing/rates/RatePerDayOfWeek'
import { DAY_OF_WEEK, NATIVE_LISTING_MENU, PMS_TYPE, TYPE_EDIT_LISTINGS } from 'src/contants/common'
import { PROPERTY_STATUS } from 'src/contants/native'
import { ErrorDayOfWeek, PropertyDetailProps } from 'src/interfaces/listing'
import { setLoading, setRateObjBaseOnUsd, setRatesBaseOnUsd, setToastSuccess } from 'src/redux/slices/common'
import { setBulkListingData, setBulkListingSection } from 'src/redux/slices/listing'

interface Props {
  propertyId: string
  listingData: PropertyDetailProps | null
  fetchStatusStep: any
}

const MIN_VALUE = 0
const MAX_VALUE = 10000

const NativeListingRates: React.FC<Props> = ({ propertyId, listingData, fetchStatusStep }) => {
  const isDraft = listingData?.status === PROPERTY_STATUS.DRAFT
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()
  const isHospitable = listingData?.pmsType === PMS_TYPE.HOSPITABLE
  const parseQuery = queryString.parse(search)
  const isOnboarding = parseQuery?.f === 'onboarding'
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const typeBulkEdit = parseQuery.type // type bulk edit listings
  const { rateObjBaseOnUsd } = useAppSelector((state) => state.common)
  const { bulkListingData, bulkListingSection } = useAppSelector((state) => state.listing)
  const defaultBulkCurrency: string = (parseQuery?.currency as string) || ''
  const [currency, setCurrency] = useState<string>(isHospitable || isEditBulk ? defaultBulkCurrency : 'USD')
  const [basePrice, setBasePrice] = useState<number | undefined>(isHospitable || isEditBulk ? undefined : 100)
  const [arrDayOfWeek, setArrDayOfWeek] = useState<any[]>([])
  const [dateRanges, setDateRanges] = useState<any[]>([])
  const [errorBasePrice, setErrorBasePrice] = useState<FormError>({ show: false, message: '' })
  const [errorDayOfWeek, setErrorDayOfWeek] = useState<ErrorDayOfWeek>(DEFULT_ERROR_DAY_OF_WEEK)
  const [isDisabledSubmit, setIsDisabledSubmit] = useState<boolean>(false)
  const [isEnableMarkup, setIsEnableMarkup] = useState<boolean>(false)
  const [markupPrice, setMarkupPrice] = useState<string | number | undefined>()
  const [markupType, setMarkupType] = useState<'add' | 'remove'>('add')
  const [errorMarkup, setErrorMarkup] = useState<FormError>({ show: false, message: '' })
  const [toggleEdit, setToggleEdit] = useState<any>({
    baseRates: false,
    adjustRate: false,
    customRates: false,
    syncedRates: false,
  })

  async function fetchRates(id: string) {
    const res = await getRates(id)
    if (res.success && res.data) {
      const { dateRanges, pricePerNight, weekday } = res.data
      // setDataFromDb(res.data)
      setDateRanges(Array.isArray(dateRanges) ? dateRanges : [])
      // setBasePrice(pricePerNight.price || 100)
      setBasePrice(pricePerNight.price)
      setCurrency(pricePerNight.currency || 'USD')
      if (weekday) {
        setArrDayOfWeek(
          DAY_OF_WEEK.map((day) => {
            const isEnabled = Object.prototype.hasOwnProperty.call(weekday, day)
            return {
              day: day,
              isEnabled: isEnabled,
              price: weekday[day],
            }
          })
        )
      }
    }
  }
  function updatePriceToNewCurrency(newCurrency: string) {
    setBasePrice((prevState) => {
      if (typeof prevState === 'number') {
        const newPrice = convertPriceByCurrency(rateObjBaseOnUsd, currency, newCurrency, prevState)
        const basePriceIsNotInteger = !Number.isInteger(Number(newPrice))
        const minPrice = getMinPrice(newCurrency)
        const maxPrice = getMaxPrice(newCurrency)

        if (basePriceIsNotInteger || Number(newPrice) < minPrice || Number(newPrice) > maxPrice) {
          setErrorBasePrice({
            show: true,
            message: `The base rate must be an integer value between ${numberWithCommas(
              minPrice
            )} and ${numberWithCommas(maxPrice)}`,
            showTooltip: true,
          })
        }
        return newPrice
      }
      return prevState
    })
    setArrDayOfWeek((prevState) => {
      return prevState.map((item) => {
        const cloneItem = { ...item }
        if (item.price) {
          cloneItem.price = convertPriceByCurrency(rateObjBaseOnUsd, currency, newCurrency, item.price)
        }
        return cloneItem
      })
    })

    if (Array.isArray(dateRanges)) {
      const newDateRanges = dateRanges.map((item) => {
        const weekday: any = item.weekday
        const newWeekDay: any = {}
        for (let key in weekday) {
          if (weekday[key]) {
            const oldPrice = Number(weekday[key])
            // newWeekDay[key] = Math.round(Number(weekday[key]) * newRate)
            newWeekDay[key] = convertPriceByCurrency(rateObjBaseOnUsd, currency, newCurrency, oldPrice)
          }
        }
        return { ...item, weekday: newWeekDay }
      })
      setDateRanges(newDateRanges)
    }
  }

  useEffect(() => {
    if (!isEditBulk) {
      if (!isEmpty(propertyId)) {
        fetchRates(propertyId)
      } else {
        setCurrency('USD')
      }
    }
  }, [propertyId, isEditBulk])

  useEffect(() => {
    if (listingData) {
      setMarkupType(
        listingData.markupPrice !== null && listingData.markupPrice !== undefined && listingData.markupPrice < 1
          ? 'remove'
          : 'add'
      )
      setMarkupPrice(convertMarkupToPercent(listingData.markupPrice))
      setIsEnableMarkup(listingData.markupPrice !== null && listingData.markupPrice !== undefined)
    }
  }, [listingData])
  useEffect(() => {
    if (!isEmpty(bulkListingData) && isEditBulk) {
      setCurrency(bulkListingData?.currency || (isHospitable || isEditBulk ? defaultBulkCurrency : 'USD'))
      setBasePrice(bulkListingData?.basePrice || (isHospitable || isEditBulk ? undefined : 100))
      setArrDayOfWeek(bulkListingData?.arrDayOfWeek || [])
      setDateRanges(bulkListingData?.dateRanges || [])
    }
  }, [bulkListingData])

  useEffect(() => {
    // create default rate per day of week
    setArrDayOfWeek(
      DAY_OF_WEEK.map((day) => {
        return {
          day: day,
          isEnabled: false,
          price: undefined,
        }
      })
    )
  }, [])

  useEffect(() => {
    // get rate to convert price when change currency
    async function fetchRateCurrency() {
      try {
        dispatch(setLoading(true))
        const res = await getRateCurrency()
        if (Array.isArray(res.data)) {
          dispatch(setRatesBaseOnUsd(res.data))
          const rateObj: any = {}
          for (let item of res.data) {
            rateObj[item.key] = item
          }
          dispatch(setRateObjBaseOnUsd(rateObj))
        }
      } finally {
        dispatch(setLoading(false))
      }
    }

    fetchRateCurrency()
  }, [])

  useEffect(() => {
    let isDisabled: boolean = false
    if (errorBasePrice.show) {
      isDisabled = true
    }
    for (let key in errorDayOfWeek) {
      // @ts-ignore
      if (errorDayOfWeek[key].show) {
        // @ts-ignore
        isDisabled = true
        break
      }
    }

    setIsDisabledSubmit(isDisabled)
  }, [errorBasePrice, errorDayOfWeek])

  const getMinPrice = (_curr: string) => {
    return convertPriceByCurrency(rateObjBaseOnUsd, 'USD', _curr, MIN_VALUE)
  }
  const getMaxPrice = (_curr: string) => {
    return convertPriceByCurrency(rateObjBaseOnUsd, 'USD', _curr, MAX_VALUE)
  }

  const onChangeCurrency = (_curr: string) => {
    updatePriceToNewCurrency(_curr)
    setCurrency(_curr)
  }

  const onChangeBasePrice = (price?: string) => {
    setBasePrice(price && !isNaN(Number(price)) ? Math.abs(Number(price)) : undefined)
    setErrorBasePrice({ show: false, message: '' })
  }

  const onBlurBasePrice = (event: any) => {
    const input = event.currentTarget as HTMLInputElement
    const value = input.value.replaceAll(',', '')
    const basePriceIsNotInteger = !Number.isInteger(Number(value))
    if (value === '') {
      onChangeBasePrice('100')
    }
    const minPrice = getMinPrice(currency)
    const maxPrice = getMaxPrice(currency)
    if (value !== '' && (basePriceIsNotInteger || Number(value) < minPrice || Number(value) > maxPrice)) {
      setErrorBasePrice({
        show: true,
        message: `The base rate must be an integer value between ${numberWithCommas(minPrice)} and ${numberWithCommas(
          maxPrice
        )}`,
        showTooltip: true,
      })
      return false
    }
  }

  const updateDayOfWeek = (newData: any[], index: number, field: string) => {
    setArrDayOfWeek(newData)
    const item = newData[index]
    if (item) {
      const day = item.day
      setErrorDayOfWeek((prevState) => ({ ...prevState, [day]: { show: false, message: '', showTooltip: true } }))
      if (field === 'isEnabled' && item.isEnabled && item.price !== undefined) {
        onBlurDayOfWeek(day, item.price)
      }
    }
  }

  const updateDateRanges = (newDateRanges: any[]) => {
    setDateRanges(newDateRanges)
  }

  const onBlurDayOfWeek = (day: string, inputValue: string) => {
    const value = inputValue.replaceAll(',', '')
    const basePriceIsNotInteger = !Number.isInteger(Number(value))
    const minPrice = getMinPrice(currency)
    const maxPrice = getMaxPrice(currency)
    if (value !== '' && (basePriceIsNotInteger || Number(value) < minPrice || Number(value) > maxPrice)) {
      setErrorDayOfWeek((prevState) => {
        const clone = { ...prevState }
        // @ts-ignore
        clone[day] = {
          show: true,
          message: `The base rate must be an integer value between ${numberWithCommas(minPrice)} and ${numberWithCommas(
            maxPrice
          )}`,
          showTooltip: true,
        }
        return clone
      })
      return false
    }
  }

  const convertWeekDay = (_dayOfWeek: any[]) => {
    const weekday: any = {}
    for (let item of _dayOfWeek) {
      if (item.isEnabled && typeof item.price !== 'undefined') {
        weekday[item.day] = Number(item.price)
      }
    }
    return weekday
  }

  const onOnOffMarkup = (check: boolean) => {
    setIsEnableMarkup(check)
  }

  const onChangeMarkup = (value: string | number | undefined) => {
    setMarkupPrice(value)
    setErrorMarkup({ show: false, message: '' })
  }

  const onChangeMarkupType = (type: 'add' | 'remove') => {
    setMarkupType(type)
  }

  const convertPercentToMarkup = (percent: number, type: 'add' | 'remove') => {
    if (percent === null || percent === undefined) {
      return percent
    }
    if (type === 'remove') {
      return 1 - percent / 100
    } else {
      return percent / 100 + 1
    }
  }

  const convertMarkupToPercent = (markup: number) => {
    if (markup === null || markup === undefined) {
      return markup
    }
    if (markup >= 1) {
      return Number((markup - 1).toFixed(2)) * 100
    }
    return Number((1 - markup).toFixed(2)) * 100
  }

  const handleSubmit = async (isExit?: boolean) => {
    try {
      dispatch(setLoading(true))
      if (isEditBulk) {
        if (toggleEdit.baseRates || toggleEdit.adjustRate || toggleEdit.customRates || toggleEdit.syncedRates) {
          const propertyIds = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
          let dataDTO: any = { rate: {} }
          if (typeBulkEdit === TYPE_EDIT_LISTINGS.AIRBNB && toggleEdit.syncedRates) {
            dataDTO = {
              ...dataDTO,
              markupPrice: isEnableMarkup ? convertPercentToMarkup(Number(markupPrice), markupType) : null,
            }
            await updateBulkProperties({ propertyIds, ...dataDTO })
          }
          if (typeBulkEdit === TYPE_EDIT_LISTINGS.NATIVE && (toggleEdit.baseRates || toggleEdit.adjustRate || toggleEdit.customRates)) {
            if (toggleEdit.baseRates) {
              dataDTO.rate.pricePerNight = {
                price: basePrice,
                currency: currency,
              }
            }
            if (toggleEdit.adjustRate) {
              const weekday = convertWeekDay(arrDayOfWeek)
              dataDTO.rate.weekday = !isEmpty(weekday) ? weekday : null
            }
            if (toggleEdit.customRates) {
              dataDTO.rate.dateRanges = !isEmpty(dateRanges) ? [...dateRanges] : null
            }
            await updateBulkProperties({ propertyIds, ...dataDTO })
            setToggleEdit({ baseRates: false, adjustRate: false, customRates: false, syncedRates: false })
          }
          dispatch(setBulkListingData({ ...bulkListingData, currency, basePrice, arrDayOfWeek, dateRanges }))
          dispatch(setToastSuccess({ message: 'Your changes have been applied.' }))
          if (isExit) navigate(`/listings`)
          fetchStatusStep()
        }
      } else {
        const bodyData: any = {
          listingId: Number(propertyId),
          weekday: convertWeekDay(arrDayOfWeek),
          pricePerNight: {
            price: basePrice,
            currency: currency || 'USD',
          },
          dateRanges: [...dateRanges],
        }
        if (!isHospitable) {
          await updateRates(bodyData)
        }

        const payloadUpdateProperty: any = {}
        if (isHospitable || isDraft) {
          if (isDraft) {
            payloadUpdateProperty.settingSteps = 12
          }
          if (isHospitable) {
            // && !Number.isNaN(Number(markupPrice))
            if (isEnableMarkup) {
              payloadUpdateProperty.markupPrice = convertPercentToMarkup(Number(markupPrice), markupType)
            } else {
              payloadUpdateProperty.markupPrice = null
            }
          }
          await updateProperty(propertyId, payloadUpdateProperty)
        }

        if (isExit) navigate(isOnboarding ? '/' : `/listings`)
        else if (listingData?.status === PROPERTY_STATUS.DRAFT) {
          navigate(`/native-listing/${NATIVE_LISTING_MENU.PUBLISH}` + search)
        } else dispatch(setToastSuccess({ message: 'Your changes have been applied.' }))
        fetchStatusStep()
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const renderAirbnbListingRates = () => {
    return (
      <>
        <p className="text-28-36 text-neutral-800 font-maison-neue tracking-[-0.01em] mb-[32px]">Adjust your rates</p>
        <DisabledSectionOnEdit
          isDisable={!isHospitable}
          title="Synced rates modifier"
          subTitle="Dtravel will automatically add or subtract this % to the rates synced from Airbnb"
          openEdit={toggleEdit.syncedRates}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, syncedRates: value })}
        >
          <div
            className={clsx('p-[24px] bg-white w-full mb-[32px] rounded-[16px]', {
              // hidden: !isHospitable,
            })}
          >
            <MarkupRate
              value={markupPrice}
              onChangeValue={onChangeMarkup}
              isEnable={isEnableMarkup}
              onChangeEnable={onOnOffMarkup}
              error={errorMarkup}
              markupType={markupType}
              onChangeMarkupType={onChangeMarkupType}
            />
          </div>
        </DisabledSectionOnEdit>
      </>
    )
  }

  const renderNativeListingRates = () => {
    return (
      <>
        <p className="text-28-36 text-neutral-800 font-maison-neue tracking-[-0.01em] mb-[32px]">
          Let&apos;s set the rates
        </p>
        <DisabledSectionOnEdit
          title="Base rates"
          openEdit={toggleEdit.baseRates}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, baseRates: value })}
        >
          <div className="p-[24px] bg-white w-full mb-[32px] rounded-[16px]">
            <div className={'mb-[24px]'}>
              <RateBase
                currency={currency}
                onChangeCurrency={onChangeCurrency}
                basePrice={basePrice}
                onChangeBasePrice={onChangeBasePrice}
                onBlurBasePrice={onBlurBasePrice}
                error={errorBasePrice}
              />
            </div>
          </div>
        </DisabledSectionOnEdit>
        <DisabledSectionOnEdit
          title="Adjust rate per day of the week"
          subTitle="Override your base rate for certain days"
          openEdit={toggleEdit.adjustRate}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, adjustRate: value })}
        >
          <div className="p-[24px] bg-white w-full mb-[32px] rounded-[16px]">
            <div>
              <RatePerDayOfWeek
                data={arrDayOfWeek}
                updateDayOfWeek={updateDayOfWeek}
                currency={currency}
                errors={errorDayOfWeek}
                onBlurDayOfWeek={onBlurDayOfWeek}
              />
            </div>
          </div>
        </DisabledSectionOnEdit>
        <DisabledSectionOnEdit
          isDisable={isHospitable}
          title="Custom rates"
          subTitle="Override your base rate for specific date ranges"
          openEdit={toggleEdit.customRates}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, customRates: value })}
        >
          <div className="p-[24px] bg-white w-full mb-[32px] rounded-[16px]">
            <RateCustom
              propertyId={propertyId}
              currency={currency}
              basePrice={basePrice}
              dateRanges={dateRanges}
              updateDateRanges={updateDateRanges}
              fetchRates={fetchRates}
              priceDayOfWeek={convertWeekDay(arrDayOfWeek)}
              minPrice={getMinPrice(currency)}
              maxPrice={getMaxPrice(currency)}
              fetchStatusStep={fetchStatusStep}
              handleCloseEdit={() =>
                setToggleEdit({ baseRates: false, adjustRate: false, customRates: false, syncedRates: false })
              }
            />
          </div>
        </DisabledSectionOnEdit>
      </>
    )
  }
  return (
    <>
      <div className="flex flex-col">
        {isHospitable || (isEditBulk && typeBulkEdit === TYPE_EDIT_LISTINGS.AIRBNB)
          ? renderAirbnbListingRates()
          : renderNativeListingRates()}
      </div>
      {/*--- Footer: Back and Preview ---*/}
      <NativeListingBackAndContinue
        onSubmit={handleSubmit}
        isEdit={!isDraft}
        isDisabledSubmit={isEditBulk ? !(toggleEdit.baseRates || toggleEdit.adjustRate || toggleEdit.customRates || toggleEdit.syncedRates) : isDisabledSubmit}
      />
      <div
        id={`${NATIVE_LISTING_MENU.RATES}_save_exit`}
        className="hidden"
        onClick={() => handleSubmit(true)}
        role="presentation"
      />
      <div
        id={`${NATIVE_LISTING_MENU.RATES}_save`}
        className="hidden"
        onClick={() => {
          if (isEditBulk) {
            if (
              isEmpty(bulkListingSection) &&
              (toggleEdit.baseRates || toggleEdit.adjustRate || toggleEdit.customRates || toggleEdit.syncedRates)
            ) {
              let result: string[] = []
              if (toggleEdit.baseRates) result.push('Base rates')
              if (toggleEdit.adjustRate) result.push('Adjust rate per day of the week')
              if (toggleEdit.customRates) result.push('Custom rates')
              if (toggleEdit.syncedRates) result.push('Synced rates modifier')
              dispatch(setBulkListingSection(result.join(', ')))
            } else handleSubmit()
          } else handleSubmit()
        }}
        role="presentation"
      />
    </>
  )
}

export default NativeListingRates
