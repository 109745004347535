import RenderPrice from '../common/RenderPrice'
import TooltipClick from '../ui/TooltipClick'
import AddNewAddOn from './AddNewAddOn'
import ReservationCustomSwitch from './ReservationCustomSwitch'
import IconButton from '@mui/material/IconButton'
import React, { useState } from 'react'
import ic_add_plus from 'src/assets/icons/ic_add_plus_16_16.svg'
import ic_edit_pencil_line from 'src/assets/icons/ic_edit_pencil_line.svg'
import ic_trash_full from 'src/assets/icons/ic_trash_full.svg'
import { AddOn } from 'src/interfaces/reservations'

interface Props {
  currency: string
  data: AddOn[]
  onChange: (newData: AddOn[]) => void
}

const ReservationAddOn: React.FC<Props> = ({ currency, data, onChange }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [editItemData, setEditItemData] = useState<AddOn | null>(null)
  const [editIndex, setEditIndex] = useState<number>(-1)

  const onOpenPopup = () => {
    setIsOpen(true)
  }

  const onClosePopup = () => {
    setIsOpen(false)
    setEditItemData(null)
    setEditIndex(-1)
  }

  const onConfirmAddNew = (addOnData: AddOn, index?: number) => {
    const itemData = { ...addOnData }
    // case Edit
    if (typeof index === 'number' && index > -1) {
      const newData = data.map((item, _index) => {
        if (_index === index) return itemData
        return item
      })
      onChange([...newData])
    } else {
      onChange([...data, itemData])
    }
  }

  const onEdit = (index: number, _itemData: AddOn) => {
    setEditIndex(index)
    setEditItemData(_itemData)
    setIsOpen(true)
  }

  const onDelete = (index: number) => {
    const newData = [...data]
    newData.splice(index, 1)
    onChange(newData)
  }

  const onToggleOnOff = (index: number, isChecked: boolean) => {
    const newData = [...data]
    newData[index]['isIncludedInTotalPrice'] = isChecked
    onChange(newData)
  }

  const renderApplyPer = (applyPer: string) => {
    if (applyPer) {
      return applyPer.split('_').join(' ')
    }
    return ''
  }

  return (
    <div>
      <div className={'flex justify-between items-center mb-[16px]'}>
        <span className={'font-maison-neue text-20-24 text-neutral-900'}>Optional Add-Ons</span>
        <TooltipClick
          placement="bottom"
          maxWidth={294}
          title={
            <>
              Add-ons are ways to enhance the guest experience while making additional revenue. Upon checkout, your
              guests will have the option to select and pay for available add-ons. For example, if your calendar allows
              for it, your guest could pay extra for an early check-in. <br /> Please note: it is your responsibility to
              carry out the add-on service so we only recommend taking advantage of this feature if you&apos;re able to
              fulfill them.
            </>
          }
        >
          <span className={'font-maison-neue-medium text-14-14 text-neutral-6'}>What are add-ons?</span>
        </TooltipClick>
      </div>

      <div className={'bg-white px-[24px] rounded-[16px]'} style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)' }}>
        {Array.isArray(data) &&
          data.map((item, index) => {
            return (
              <div
                key={index}
                className={`flex justify-between gap-[24px] py-[24px] ${
                  index === 0 ? '' : 'border-t border-neutral-300'
                }`}
              >
                <div className={'font-maison-neue-medium text-neutral-800'}>
                  <p className={'text-16-20 mb-[4px]'}>{item.name}</p>
                  <p className={'text-14-18 mb-[8px]'}>
                    <RenderPrice price={item.price} currency={currency} /> {renderApplyPer(item.applyPer)}
                  </p>
                  <p className={'font-maison-neue text-neutral-600 text-14-20 mb-[16px]'}>{item.description}</p>
                  <div className={'flex flex-wrap gap-[12px]'}>
                    {item.images.map((image, index) => (
                      <div key={index} className={'w-[48px] h-[48px] rounded-[8px]'}>
                        <img
                          src={image.slug}
                          alt={''}
                          className={'rounded-[8px] object-cover'}
                          style={{ width: '48px', height: '48px' }}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div className={'flex gap-[24px]'}>
                  <div className={'min-w-[34px]'}>
                    <IconButton size={'small'} onClick={() => onEdit(index, item)}>
                      <img src={ic_edit_pencil_line} alt={'ic_edit_pencil_line'} />
                    </IconButton>
                  </div>
                  <div className={'min-w-[34px]'}>
                    <IconButton size={'small'} onClick={() => onDelete(index)}>
                      <img src={ic_trash_full} alt={'ic_trash_full'} />
                    </IconButton>
                  </div>

                  <div className={'mt-[5px]'}>
                    <ReservationCustomSwitch
                      checked={item.isIncludedInTotalPrice}
                      onChange={(event) => onToggleOnOff(index, event.target.checked)}
                    />
                  </div>
                </div>
              </div>
            )
          })}
      </div>

      <div className={'mt-[16px] font-maison-neue-medium text-12-12 text-neutral-800'}>
        <button
          className={'flex justify-between items-center gap-[4px] h-[32px] pl-[12px] pr-[8px] rounded-[100px] bg-white'}
          style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)' }}
          onClick={onOpenPopup}
        >
          <span>New add-on</span>
          <span>
            <img src={ic_add_plus} alt={'ic_add_plus'} />
          </span>
        </button>
      </div>

      <AddNewAddOn
        isOpen={isOpen}
        onClose={onClosePopup}
        currency={currency}
        editIndex={editIndex}
        editData={editItemData}
        onConfirm={onConfirmAddNew}
        addOns={data}
      />
    </div>
  )
}

export default ReservationAddOn
