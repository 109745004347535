import { updateHostSettings } from '../../../api/user'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { setToastSuccess } from '../../../redux/slices/common'
import { setSettingData } from '../../../redux/slices/user'
import { handleErrorMessage } from '../../../utils/common'
import React, { useEffect, useState } from 'react'
import BasicSwitch from 'src/components/ui/BasicSwitch'

interface Props {
  isOnboarding?: boolean
}

const SettingDisplayLandingPage: React.FC<Props> = ({ isOnboarding }) => {
  const dispatch = useAppDispatch()
  const { settingData } = useAppSelector((state) => state.user)
  const [checked, setCheced] = useState<boolean>(false)

  useEffect(() => {
    setCheced(settingData.status === 'active')
  }, [settingData?.status])

  const onChangeSetting = async (isChecked: boolean) => {
    try {
      const res = await updateHostSettings({ status: isChecked ? 'active' : 'inactive' })
      dispatch(setSettingData({ ...settingData, ...res.data }))
      if (!isOnboarding) {
        dispatch(setToastSuccess({ message: 'Update success!' }))
      }
    } catch (err: any) {
      handleErrorMessage(err)
    }
  }

  return (
    <div className={`flex justify-between ${isOnboarding ? 'items-start' : 'items-center'}  gap-[12px]`}>
      <div>
        <p className={'font-maison-neue-medium text-16-20 text-neutral-800 mb-[4px] capitalize'}>
          Display Landing Page
        </p>
        <p className={'font-maison-neue text-14-18 text-neutral-600'}>
          Display a landing page to your visitors when guests land on your site. If turned off, we’ll display your
          listings instead.
        </p>
      </div>
      <div>
        <BasicSwitch checked={checked} onChange={(event) => onChangeSetting(event.target.checked)} />
      </div>
    </div>
  )
}

export default SettingDisplayLandingPage
