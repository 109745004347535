export const countries = [
  {
    id: 1,
    name: 'Afghanistan',
    iso: 'AF',
    phoneCode: '93',
  },
  {
    id: 243,
    name: 'Aland Islands',
    iso: 'AX',
    phoneCode: '358',
  },
  {
    id: 2,
    name: 'Albania',
    iso: 'AL',
    phoneCode: '355',
  },
  {
    id: 3,
    name: 'Algeria',
    iso: 'DZ',
    phoneCode: '213',
  },
  {
    id: 4,
    name: 'American Samoa',
    iso: 'AS',
    phoneCode: '1684',
  },
  {
    id: 5,
    name: 'Andorra',
    iso: 'AD',
    phoneCode: '376',
  },
  {
    id: 6,
    name: 'Angola',
    iso: 'AO',
    phoneCode: '244',
  },
  {
    id: 7,
    name: 'Anguilla',
    iso: 'AI',
    phoneCode: '1264',
  },
  {
    id: 8,
    name: 'Antarctica',
    iso: 'AQ',
    phoneCode: '0',
  },
  {
    id: 9,
    name: 'Antigua and Barbuda',
    iso: 'AG',
    phoneCode: '1268',
  },
  {
    id: 10,
    name: 'Argentina',
    iso: 'AR',
    phoneCode: '54',
  },
  {
    id: 11,
    name: 'Armenia',
    iso: 'AM',
    phoneCode: '374',
  },
  {
    id: 12,
    name: 'Aruba',
    iso: 'AW',
    phoneCode: '297',
  },
  {
    id: 13,
    name: 'Australia',
    iso: 'AU',
    phoneCode: '61',
  },
  {
    id: 14,
    name: 'Austria',
    iso: 'AT',
    phoneCode: '43',
  },
  {
    id: 15,
    name: 'Azerbaijan',
    iso: 'AZ',
    phoneCode: '994',
  },
  {
    id: 16,
    name: 'Bahamas',
    iso: 'BS',
    phoneCode: '1242',
  },
  {
    id: 17,
    name: 'Bahrain',
    iso: 'BH',
    phoneCode: '973',
  },
  {
    id: 18,
    name: 'Bangladesh',
    iso: 'BD',
    phoneCode: '880',
  },
  {
    id: 19,
    name: 'Barbados',
    iso: 'BB',
    phoneCode: '1246',
  },
  {
    id: 20,
    name: 'Belarus',
    iso: 'BY',
    phoneCode: '375',
  },
  {
    id: 21,
    name: 'Belgium',
    iso: 'BE',
    phoneCode: '32',
  },
  {
    id: 22,
    name: 'Belize',
    iso: 'BZ',
    phoneCode: '501',
  },
  {
    id: 23,
    name: 'Benin',
    iso: 'BJ',
    phoneCode: '229',
  },
  {
    id: 24,
    name: 'Bermuda',
    iso: 'BM',
    phoneCode: '1441',
  },
  {
    id: 25,
    name: 'Bhutan',
    iso: 'BT',
    phoneCode: '975',
  },
  {
    id: 26,
    name: 'Bolivia',
    iso: 'BO',
    phoneCode: '591',
  },
  {
    id: 244,
    name: 'Bonaire, Sint Eustatius and Saba',
    iso: 'BQ',
    phoneCode: '599',
  },
  {
    id: 27,
    name: 'Bosnia and Herzegovina',
    iso: 'BA',
    phoneCode: '387',
  },
  {
    id: 28,
    name: 'Botswana',
    iso: 'BW',
    phoneCode: '267',
  },
  {
    id: 29,
    name: 'Bouvet Island',
    iso: 'BV',
    phoneCode: '0',
  },
  {
    id: 30,
    name: 'Brazil',
    iso: 'BR',
    phoneCode: '55',
  },
  {
    id: 31,
    name: 'British Indian Ocean Territory',
    iso: 'IO',
    phoneCode: '246',
  },
  {
    id: 32,
    name: 'Brunei',
    iso: 'BN',
    phoneCode: '673',
  },
  {
    id: 33,
    name: 'Bulgaria',
    iso: 'BG',
    phoneCode: '359',
  },
  {
    id: 34,
    name: 'Burkina Faso',
    iso: 'BF',
    phoneCode: '226',
  },
  {
    id: 35,
    name: 'Burundi',
    iso: 'BI',
    phoneCode: '257',
  },
  {
    id: 36,
    name: 'Cambodia',
    iso: 'KH',
    phoneCode: '855',
  },
  {
    id: 37,
    name: 'Cameroon',
    iso: 'CM',
    phoneCode: '237',
  },
  {
    id: 38,
    name: 'Canada',
    iso: 'CA',
    phoneCode: '1',
  },
  {
    id: 39,
    name: 'Cape Verde',
    iso: 'CV',
    phoneCode: '238',
  },
  {
    id: 40,
    name: 'Cayman Islands',
    iso: 'KY',
    phoneCode: '1345',
  },
  {
    id: 41,
    name: 'Central African Republic',
    iso: 'CF',
    phoneCode: '236',
  },
  {
    id: 42,
    name: 'Chad',
    iso: 'TD',
    phoneCode: '235',
  },
  {
    id: 43,
    name: 'Chile',
    iso: 'CL',
    phoneCode: '56',
  },
  {
    id: 44,
    name: 'China',
    iso: 'CN',
    phoneCode: '86',
  },
  {
    id: 45,
    name: 'Christmas Island',
    iso: 'CX',
    phoneCode: '61',
  },
  {
    id: 46,
    name: 'Cocos (Keeling) Islands',
    iso: 'CC',
    phoneCode: '672',
  },
  {
    id: 47,
    name: 'Colombia',
    iso: 'CO',
    phoneCode: '57',
  },
  {
    id: 48,
    name: 'Comoros',
    iso: 'KM',
    phoneCode: '269',
  },
  {
    id: 49,
    name: 'Congo',
    iso: 'CG',
    phoneCode: '242',
  },
  {
    id: 51,
    name: 'Cook Islands',
    iso: 'CK',
    phoneCode: '682',
  },
  {
    id: 52,
    name: 'Costa Rica',
    iso: 'CR',
    phoneCode: '506',
  },
  {
    id: 53,
    name: "Cote D'Ivoire",
    iso: 'CI',
    phoneCode: '225',
  },
  {
    id: 54,
    name: 'Croatia',
    iso: 'HR',
    phoneCode: '385',
  },
  {
    id: 55,
    name: 'Cuba',
    iso: 'CU',
    phoneCode: '53',
  },
  {
    id: 245,
    name: 'Curacao',
    iso: 'CW',
    phoneCode: '599',
  },
  {
    id: 56,
    name: 'Cyprus',
    iso: 'CY',
    phoneCode: '357',
  },
  {
    id: 57,
    name: 'Czech Republic',
    iso: 'CZ',
    phoneCode: '420',
  },
  {
    id: 58,
    name: 'Denmark',
    iso: 'DK',
    phoneCode: '45',
  },
  {
    id: 59,
    name: 'Djibouti',
    iso: 'DJ',
    phoneCode: '253',
  },
  {
    id: 60,
    name: 'Dominica',
    iso: 'DM',
    phoneCode: '1767',
  },
  {
    id: 61,
    name: 'Dominican Republic',
    iso: 'DO',
    phoneCode: '1809',
  },
  {
    id: 50,
    name: 'DR Congo',
    iso: 'CD',
    phoneCode: '242',
  },
  {
    id: 62,
    name: 'Ecuador',
    iso: 'EC',
    phoneCode: '593',
  },
  {
    id: 63,
    name: 'Egypt',
    iso: 'EG',
    phoneCode: '20',
  },
  {
    id: 64,
    name: 'El Salvador',
    iso: 'SV',
    phoneCode: '503',
  },
  {
    id: 65,
    name: 'Equatorial Guinea',
    iso: 'GQ',
    phoneCode: '240',
  },
  {
    id: 66,
    name: 'Eritrea',
    iso: 'ER',
    phoneCode: '291',
  },
  {
    id: 67,
    name: 'Estonia',
    iso: 'EE',
    phoneCode: '372',
  },
  {
    id: 68,
    name: 'Ethiopia',
    iso: 'ET',
    phoneCode: '251',
  },
  {
    id: 69,
    name: 'Falkland Islands (Malvinas)',
    iso: 'FK',
    phoneCode: '500',
  },
  {
    id: 70,
    name: 'Faroe Islands',
    iso: 'FO',
    phoneCode: '298',
  },
  {
    id: 71,
    name: 'Fiji',
    iso: 'FJ',
    phoneCode: '679',
  },
  {
    id: 72,
    name: 'Finland',
    iso: 'FI',
    phoneCode: '358',
  },
  {
    id: 73,
    name: 'France',
    iso: 'FR',
    phoneCode: '33',
  },
  {
    id: 74,
    name: 'French Guiana',
    iso: 'GF',
    phoneCode: '594',
  },
  {
    id: 75,
    name: 'French Polynesia',
    iso: 'PF',
    phoneCode: '689',
  },
  {
    id: 76,
    name: 'French Southern Territories',
    iso: 'TF',
    phoneCode: '0',
  },
  {
    id: 77,
    name: 'Gabon',
    iso: 'GA',
    phoneCode: '241',
  },
  {
    id: 78,
    name: 'Gambia',
    iso: 'GM',
    phoneCode: '220',
  },
  {
    id: 79,
    name: 'Georgia',
    iso: 'GE',
    phoneCode: '995',
  },
  {
    id: 80,
    name: 'Germany',
    iso: 'DE',
    phoneCode: '49',
  },
  {
    id: 81,
    name: 'Ghana',
    iso: 'GH',
    phoneCode: '233',
  },
  {
    id: 82,
    name: 'Gibraltar',
    iso: 'GI',
    phoneCode: '350',
  },
  {
    id: 83,
    name: 'Greece',
    iso: 'GR',
    phoneCode: '30',
  },
  {
    id: 84,
    name: 'Greenland',
    iso: 'GL',
    phoneCode: '299',
  },
  {
    id: 85,
    name: 'Grenada',
    iso: 'GD',
    phoneCode: '1473',
  },
  {
    id: 86,
    name: 'Guadeloupe',
    iso: 'GP',
    phoneCode: '590',
  },
  {
    id: 87,
    name: 'Guam',
    iso: 'GU',
    phoneCode: '1671',
  },
  {
    id: 88,
    name: 'Guatemala',
    iso: 'GT',
    phoneCode: '502',
  },
  {
    id: 246,
    name: 'Guernsey',
    iso: 'GG',
    phoneCode: '44',
  },
  {
    id: 89,
    name: 'Guinea',
    iso: 'GN',
    phoneCode: '224',
  },
  {
    id: 90,
    name: 'Guinea-Bissau',
    iso: 'GW',
    phoneCode: '245',
  },
  {
    id: 91,
    name: 'Guyana',
    iso: 'GY',
    phoneCode: '592',
  },
  {
    id: 92,
    name: 'Haiti',
    iso: 'HT',
    phoneCode: '509',
  },
  {
    id: 93,
    name: 'Heard Island and Mcdonald Islands',
    iso: 'HM',
    phoneCode: '0',
  },
  {
    id: 94,
    name: 'Holy See (Vatican City State)',
    iso: 'VA',
    phoneCode: '39',
  },
  {
    id: 95,
    name: 'Honduras',
    iso: 'HN',
    phoneCode: '504',
  },
  {
    id: 96,
    name: 'Hong Kong',
    iso: 'HK',
    phoneCode: '852',
  },
  {
    id: 97,
    name: 'Hungary',
    iso: 'HU',
    phoneCode: '36',
  },
  {
    id: 98,
    name: 'Iceland',
    iso: 'IS',
    phoneCode: '354',
  },
  {
    id: 99,
    name: 'India',
    iso: 'IN',
    phoneCode: '91',
  },
  {
    id: 100,
    name: 'Indonesia',
    iso: 'ID',
    phoneCode: '62',
  },
  {
    id: 101,
    name: 'Iran',
    iso: 'IR',
    phoneCode: '98',
  },
  {
    id: 102,
    name: 'Iraq',
    iso: 'IQ',
    phoneCode: '964',
  },
  {
    id: 103,
    name: 'Ireland',
    iso: 'IE',
    phoneCode: '353',
  },
  {
    id: 247,
    name: 'Isle of Man',
    iso: 'IM',
    phoneCode: '44',
  },
  {
    id: 104,
    name: 'Israel',
    iso: 'IL',
    phoneCode: '972',
  },
  {
    id: 105,
    name: 'Italy',
    iso: 'IT',
    phoneCode: '39',
  },
  {
    id: 106,
    name: 'Jamaica',
    iso: 'JM',
    phoneCode: '1876',
  },
  {
    id: 107,
    name: 'Japan',
    iso: 'JP',
    phoneCode: '81',
  },
  {
    id: 248,
    name: 'Jersey',
    iso: 'JE',
    phoneCode: '44',
  },
  {
    id: 108,
    name: 'Jordan',
    iso: 'JO',
    phoneCode: '962',
  },
  {
    id: 109,
    name: 'Kazakhstan',
    iso: 'KZ',
    phoneCode: '7',
  },
  {
    id: 110,
    name: 'Kenya',
    iso: 'KE',
    phoneCode: '254',
  },
  {
    id: 111,
    name: 'Kiribati',
    iso: 'KI',
    phoneCode: '686',
  },
  {
    id: 249,
    name: 'Kosovo',
    iso: 'XK',
    phoneCode: '381',
  },
  {
    id: 114,
    name: 'Kuwait',
    iso: 'KW',
    phoneCode: '965',
  },
  {
    id: 115,
    name: 'Kyrgyzstan',
    iso: 'KG',
    phoneCode: '996',
  },
  {
    id: 116,
    name: 'Laos',
    iso: 'LA',
    phoneCode: '856',
  },
  {
    id: 117,
    name: 'Latvia',
    iso: 'LV',
    phoneCode: '371',
  },
  {
    id: 118,
    name: 'Lebanon',
    iso: 'LB',
    phoneCode: '961',
  },
  {
    id: 119,
    name: 'Lesotho',
    iso: 'LS',
    phoneCode: '266',
  },
  {
    id: 120,
    name: 'Liberia',
    iso: 'LR',
    phoneCode: '231',
  },
  {
    id: 121,
    name: 'Libya',
    iso: 'LY',
    phoneCode: '218',
  },
  {
    id: 122,
    name: 'Liechtenstein',
    iso: 'LI',
    phoneCode: '423',
  },
  {
    id: 123,
    name: 'Lithuania',
    iso: 'LT',
    phoneCode: '370',
  },
  {
    id: 124,
    name: 'Luxembourg',
    iso: 'LU',
    phoneCode: '352',
  },
  {
    id: 125,
    name: 'Macao',
    iso: 'MO',
    phoneCode: '853',
  },
  {
    id: 127,
    name: 'Madagascar',
    iso: 'MG',
    phoneCode: '261',
  },
  {
    id: 128,
    name: 'Malawi',
    iso: 'MW',
    phoneCode: '265',
  },
  {
    id: 129,
    name: 'Malaysia',
    iso: 'MY',
    phoneCode: '60',
  },
  {
    id: 130,
    name: 'Maldives',
    iso: 'MV',
    phoneCode: '960',
  },
  {
    id: 131,
    name: 'Mali',
    iso: 'ML',
    phoneCode: '223',
  },
  {
    id: 132,
    name: 'Malta',
    iso: 'MT',
    phoneCode: '356',
  },
  {
    id: 133,
    name: 'Marshall Islands',
    iso: 'MH',
    phoneCode: '692',
  },
  {
    id: 134,
    name: 'Martinique',
    iso: 'MQ',
    phoneCode: '596',
  },
  {
    id: 135,
    name: 'Mauritania',
    iso: 'MR',
    phoneCode: '222',
  },
  {
    id: 136,
    name: 'Mauritius',
    iso: 'MU',
    phoneCode: '230',
  },
  {
    id: 137,
    name: 'Mayotte',
    iso: 'YT',
    phoneCode: '269',
  },
  {
    id: 138,
    name: 'Mexico',
    iso: 'MX',
    phoneCode: '52',
  },
  {
    id: 139,
    name: 'Micronesia',
    iso: 'FM',
    phoneCode: '691',
  },
  {
    id: 140,
    name: 'Moldova',
    iso: 'MD',
    phoneCode: '373',
  },
  {
    id: 141,
    name: 'Monaco',
    iso: 'MC',
    phoneCode: '377',
  },
  {
    id: 142,
    name: 'Mongolia',
    iso: 'MN',
    phoneCode: '976',
  },
  {
    id: 242,
    name: 'Montenegro',
    iso: 'ME',
    phoneCode: '382',
  },
  {
    id: 143,
    name: 'Montserrat',
    iso: 'MS',
    phoneCode: '1664',
  },
  {
    id: 144,
    name: 'Morocco',
    iso: 'MA',
    phoneCode: '212',
  },
  {
    id: 145,
    name: 'Mozambique',
    iso: 'MZ',
    phoneCode: '258',
  },
  {
    id: 146,
    name: 'Myanmar',
    iso: 'MM',
    phoneCode: '95',
  },
  {
    id: 147,
    name: 'Namibia',
    iso: 'NA',
    phoneCode: '264',
  },
  {
    id: 148,
    name: 'Nauru',
    iso: 'NR',
    phoneCode: '674',
  },
  {
    id: 149,
    name: 'Nepal',
    iso: 'NP',
    phoneCode: '977',
  },
  {
    id: 150,
    name: 'Netherlands',
    iso: 'NL',
    phoneCode: '31',
  },
  {
    id: 151,
    name: 'Netherlands Antilles',
    iso: 'AN',
    phoneCode: '599',
  },
  {
    id: 152,
    name: 'New Caledonia',
    iso: 'NC',
    phoneCode: '687',
  },
  {
    id: 153,
    name: 'New Zealand',
    iso: 'NZ',
    phoneCode: '64',
  },
  {
    id: 154,
    name: 'Nicaragua',
    iso: 'NI',
    phoneCode: '505',
  },
  {
    id: 155,
    name: 'Niger',
    iso: 'NE',
    phoneCode: '227',
  },
  {
    id: 156,
    name: 'Nigeria',
    iso: 'NG',
    phoneCode: '234',
  },
  {
    id: 157,
    name: 'Niue',
    iso: 'NU',
    phoneCode: '683',
  },
  {
    id: 158,
    name: 'Norfolk Island',
    iso: 'NF',
    phoneCode: '672',
  },
  {
    id: 112,
    name: 'North Korea',
    iso: 'KP',
    phoneCode: '850',
  },
  {
    id: 159,
    name: 'Northern Mariana Islands',
    iso: 'MP',
    phoneCode: '1670',
  },
  {
    id: 160,
    name: 'Norway',
    iso: 'NO',
    phoneCode: '47',
  },
  {
    id: 161,
    name: 'Oman',
    iso: 'OM',
    phoneCode: '968',
  },
  {
    id: 162,
    name: 'Pakistan',
    iso: 'PK',
    phoneCode: '92',
  },
  {
    id: 163,
    name: 'Palau',
    iso: 'PW',
    phoneCode: '680',
  },
  {
    id: 165,
    name: 'Panama',
    iso: 'PA',
    phoneCode: '507',
  },
  {
    id: 166,
    name: 'Papua New Guinea',
    iso: 'PG',
    phoneCode: '675',
  },
  {
    id: 167,
    name: 'Paraguay',
    iso: 'PY',
    phoneCode: '595',
  },
  {
    id: 168,
    name: 'Peru',
    iso: 'PE',
    phoneCode: '51',
  },
  {
    id: 169,
    name: 'Philippines',
    iso: 'PH',
    phoneCode: '63',
  },
  {
    id: 170,
    name: 'Pitcairn',
    iso: 'PN',
    phoneCode: '0',
  },
  {
    id: 171,
    name: 'Poland',
    iso: 'PL',
    phoneCode: '48',
  },
  {
    id: 172,
    name: 'Portugal',
    iso: 'PT',
    phoneCode: '351',
  },
  {
    id: 173,
    name: 'Puerto Rico',
    iso: 'PR',
    phoneCode: '1787',
  },
  {
    id: 174,
    name: 'Qatar',
    iso: 'QA',
    phoneCode: '974',
  },
  {
    id: 175,
    name: 'Reunion',
    iso: 'RE',
    phoneCode: '262',
  },
  {
    id: 176,
    name: 'Romania',
    iso: 'RO',
    phoneCode: '40',
  },
  {
    id: 177,
    name: 'Russian Federation',
    iso: 'RU',
    phoneCode: '70',
  },
  {
    id: 178,
    name: 'Rwanda',
    iso: 'RW',
    phoneCode: '250',
  },
  {
    id: 250,
    name: 'Saint Barthelemy',
    iso: 'BL',
    phoneCode: '590',
  },
  {
    id: 179,
    name: 'Saint Helena',
    iso: 'SH',
    phoneCode: '290',
  },
  {
    id: 180,
    name: 'Saint Kitts and Nevis',
    iso: 'KN',
    phoneCode: '1869',
  },
  {
    id: 181,
    name: 'Saint Lucia',
    iso: 'LC',
    phoneCode: '1758',
  },
  {
    id: 251,
    name: 'Saint Martin',
    iso: 'MF',
    phoneCode: '590',
  },
  {
    id: 182,
    name: 'Saint Pierre and Miquelon',
    iso: 'PM',
    phoneCode: '508',
  },
  {
    id: 183,
    name: 'Saint Vincent and the Grenadines',
    iso: 'VC',
    phoneCode: '1784',
  },
  {
    id: 184,
    name: 'Samoa',
    iso: 'WS',
    phoneCode: '684',
  },
  {
    id: 185,
    name: 'San Marino',
    iso: 'SM',
    phoneCode: '378',
  },
  {
    id: 186,
    name: 'Sao Tome and Principe',
    iso: 'ST',
    phoneCode: '239',
  },
  {
    id: 187,
    name: 'Saudi Arabia',
    iso: 'SA',
    phoneCode: '966',
  },
  {
    id: 188,
    name: 'Senegal',
    iso: 'SN',
    phoneCode: '221',
  },
  {
    id: 240,
    name: 'Serbia',
    iso: 'RS',
    phoneCode: '381',
  },
  {
    id: 190,
    name: 'Seychelles',
    iso: 'SC',
    phoneCode: '248',
  },
  {
    id: 191,
    name: 'Sierra Leone',
    iso: 'SL',
    phoneCode: '232',
  },
  {
    id: 192,
    name: 'Singapore',
    iso: 'SG',
    phoneCode: '65',
  },
  {
    id: 252,
    name: 'Sint Maarten',
    iso: 'SX',
    phoneCode: '1',
  },
  {
    id: 193,
    name: 'Slovakia',
    iso: 'SK',
    phoneCode: '421',
  },
  {
    id: 194,
    name: 'Slovenia',
    iso: 'SI',
    phoneCode: '386',
  },
  {
    id: 195,
    name: 'Solomon Islands',
    iso: 'SB',
    phoneCode: '677',
  },
  {
    id: 196,
    name: 'Somalia',
    iso: 'SO',
    phoneCode: '252',
  },
  {
    id: 197,
    name: 'South Africa',
    iso: 'ZA',
    phoneCode: '27',
  },
  {
    id: 198,
    name: 'South Georgia and the South Sandwich Islands',
    iso: 'GS',
    phoneCode: '0',
  },
  {
    id: 113,
    name: 'South Korea',
    iso: 'KR',
    phoneCode: '82',
  },
  {
    id: 253,
    name: 'South Sudan',
    iso: 'SS',
    phoneCode: '211',
  },
  {
    id: 199,
    name: 'Spain',
    iso: 'ES',
    phoneCode: '34',
  },
  {
    id: 200,
    name: 'Sri Lanka',
    iso: 'LK',
    phoneCode: '94',
  },
  {
    id: 164,
    name: 'State Of Palestine',
    iso: 'PS',
    phoneCode: '970',
  },
  {
    id: 201,
    name: 'Sudan',
    iso: 'SD',
    phoneCode: '249',
  },
  {
    id: 202,
    name: 'Suriname',
    iso: 'SR',
    phoneCode: '597',
  },
  {
    id: 203,
    name: 'Svalbard and Jan Mayen',
    iso: 'SJ',
    phoneCode: '47',
  },
  {
    id: 204,
    name: 'Swaziland',
    iso: 'SZ',
    phoneCode: '268',
  },
  {
    id: 205,
    name: 'Sweden',
    iso: 'SE',
    phoneCode: '46',
  },
  {
    id: 206,
    name: 'Switzerland',
    iso: 'CH',
    phoneCode: '41',
  },
  {
    id: 207,
    name: 'Syria',
    iso: 'SY',
    phoneCode: '963',
  },
  {
    id: 208,
    name: 'Taiwan',
    iso: 'TW',
    phoneCode: '886',
  },
  {
    id: 209,
    name: 'Tajikistan',
    iso: 'TJ',
    phoneCode: '992',
  },
  {
    id: 210,
    name: 'Tanzania',
    iso: 'TZ',
    phoneCode: '255',
  },
  {
    id: 126,
    name: 'TFYR Macedonia',
    iso: 'MK',
    phoneCode: '389',
  },
  {
    id: 211,
    name: 'Thailand',
    iso: 'TH',
    phoneCode: '66',
  },
  {
    id: 212,
    name: 'Timor-Leste',
    iso: 'TL',
    phoneCode: '670',
  },
  {
    id: 213,
    name: 'Togo',
    iso: 'TG',
    phoneCode: '228',
  },
  {
    id: 214,
    name: 'Tokelau',
    iso: 'TK',
    phoneCode: '690',
  },
  {
    id: 215,
    name: 'Tonga',
    iso: 'TO',
    phoneCode: '676',
  },
  {
    id: 216,
    name: 'Trinidad and Tobago',
    iso: 'TT',
    phoneCode: '1868',
  },
  {
    id: 217,
    name: 'Tunisia',
    iso: 'TN',
    phoneCode: '216',
  },
  {
    id: 218,
    name: 'Turkey',
    iso: 'TR',
    phoneCode: '90',
  },
  {
    id: 219,
    name: 'Turkmenistan',
    iso: 'TM',
    phoneCode: '7370',
  },
  {
    id: 220,
    name: 'Turks and Caicos Islands',
    iso: 'TC',
    phoneCode: '1649',
  },
  {
    id: 221,
    name: 'Tuvalu',
    iso: 'TV',
    phoneCode: '688',
  },
  {
    id: 222,
    name: 'Uganda',
    iso: 'UG',
    phoneCode: '256',
  },
  {
    id: 223,
    name: 'Ukraine',
    iso: 'UA',
    phoneCode: '380',
  },
  {
    id: 224,
    name: 'United Arab Emirates',
    iso: 'AE',
    phoneCode: '971',
  },
  {
    id: 225,
    name: 'United Kingdom',
    iso: 'GB',
    phoneCode: '44',
  },
  {
    id: 226,
    name: 'United States',
    iso: 'US',
    phoneCode: '1',
  },
  {
    id: 227,
    name: 'United States Minor Outlying Islands',
    iso: 'UM',
    phoneCode: '1',
  },
  {
    id: 228,
    name: 'Uruguay',
    iso: 'UY',
    phoneCode: '598',
  },
  {
    id: 229,
    name: 'Uzbekistan',
    iso: 'UZ',
    phoneCode: '998',
  },
  {
    id: 230,
    name: 'Vanuatu',
    iso: 'VU',
    phoneCode: '678',
  },
  {
    id: 231,
    name: 'Venezuela',
    iso: 'VE',
    phoneCode: '58',
  },
  {
    id: 232,
    name: 'Viet Nam',
    iso: 'VN',
    phoneCode: '84',
  },
  {
    id: 233,
    name: 'Virgin Islands, British',
    iso: 'VG',
    phoneCode: '1284',
  },
  {
    id: 234,
    name: 'Virgin Islands, U.s.',
    iso: 'VI',
    phoneCode: '1340',
  },
  {
    id: 235,
    name: 'Wallis and Futuna',
    iso: 'WF',
    phoneCode: '681',
  },
  {
    id: 236,
    name: 'Western Sahara',
    iso: 'EH',
    phoneCode: '212',
  },
  {
    id: 237,
    name: 'Yemen',
    iso: 'YE',
    phoneCode: '967',
  },
  {
    id: 238,
    name: 'Zambia',
    iso: 'ZM',
    phoneCode: '260',
  },
  {
    id: 239,
    name: 'Zimbabwe',
    iso: 'ZW',
    phoneCode: '263',
  },
]
