import clsx from 'clsx'
import React from 'react'

interface Props {
  title?: string
  extraTitle?: string | React.ReactNode | React.ReactElement
  children?: any
  wrapperClass?: string
  noBG?: boolean
}

const BasicCardV2: React.FC<Props> = ({ title, extraTitle, wrapperClass, children, noBG }) => {
  return (
    <div className={'mb-[40px] ' + `${wrapperClass ? wrapperClass : ''}`}>
      <div className={'flex justify-between items-center mb-[16px]'}>
        <div className={'font-maison-neue text-20-24 text-neutral-800'}>{title}</div>
        <div>{extraTitle}</div>
      </div>
      <div
        className={clsx(
          'font-maison-neue text-16-24 text-neutral-700',
          noBG ? '' : 'p-[16px] md:p-[24px] rounded-[16px] bg-white shadow-xs'
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default BasicCardV2
