import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Tooltip from '@mui/material/Tooltip'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import BasicCardV2 from 'src/components/common/BasicCardV2'

interface Props { }

const DetailPayoutAndFees: React.FC<Props> = () => {
  const [openTooltipPayout, setOpenTooltipPayout] = useState<boolean>(false)
  const [openTooltipFee, setOpenTooltipFee] = useState<boolean>(false)
  return (
    <>
      <BasicCardV2 title={'Payout and Fees'}>
        <div className={'mb-6'}>
          <p className={'text-neutral-700 text-16-24'}>
            With Dtravel, you’re contributing to the community treasury, which goes toward funding community
            initiatives. As a TRVL holder, you get to directly influence how the community treasury is used through
            voting. You also have stakeholder ownership so that as your business grows, you receive even more value from
            Dtravel.
          </p>
        </div>

        <div className={'mb-6 grid grid-cols-1 md:grid-cols-2 gap-x-[12px] gap-y-[16px]'}>
          <div className="col-span-1">
            <p className="text-neutral-700 text-16-20 mb-2">
              <span>Payout to your wallet&nbsp;</span>
              <Tooltip
                placement="top"
                title={
                  <a
                    href={'https://dtravel.notion.site/Host-Payouts-Fees-and-Rewards-ea943be334b84c748a39fcfd904dcddf'}
                    target={'_blank'}
                    rel={'noreferrer'}
                    className={'underline text-12-16 font-maison-neue'}
                  >
                    How payouts work
                  </a>
                }
                arrow
                sx={{ fontSize: 16 }}
                open={openTooltipPayout}
                onOpen={() => setOpenTooltipPayout(true)}
                onClose={() => setOpenTooltipPayout(false)}
                disableHoverListener={isMobile}
              // leaveDelay={3000}
              // leaveTouchDelay={1000}
              >
                <HelpOutlineIcon
                  onClick={() => {
                    setOpenTooltipPayout((prevState) => !prevState)
                  }}
                />
              </Tooltip>
            </p>
            <div className={'payout-select-item bg-sand-1 rounded-xl px-4 py-3 border border-solid border-sand-3'}>
              <span className="text-forest-6 text-16-24">+97%</span>
            </div>
          </div>
          <div className="col-span-1">
            <p className="text-neutral-700 text-16-20 mb-2  overflow-hidden text-ellipsis whitespace-nowrap">
              <span>Fee to Community Treasury&nbsp;</span>
              <Tooltip
                placement="top"
                title={
                  <a
                    href={'https://dtravel.notion.site/Host-Payouts-Fees-and-Rewards-ea943be334b84c748a39fcfd904dcddf'}
                    target={'_blank'}
                    rel={'noreferrer'}
                    className={'underline text-12-16 font-maison-neue'}
                  >
                    How funds are used in the Community Treasury
                  </a>
                }
                arrow
                sx={{ fontSize: 16 }}
                open={openTooltipFee}
                onOpen={() => setOpenTooltipFee(true)}
                onClose={() => setOpenTooltipFee(false)}
                disableHoverListener={isMobile}
              >
                <HelpOutlineIcon
                  onClick={() => {
                    setOpenTooltipFee(true)
                  }}
                />
              </Tooltip>
            </p>
            <div className={'bg-sand-1 rounded-xl px-4 py-3 border border-solid border-sand-3'}>
              <span className="text-red-6 text-16-24">-3%</span>
            </div>
          </div>
        </div>
      </BasicCardV2>
    </>
  )
}

export default DetailPayoutAndFees
