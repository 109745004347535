import Api from './index'
import { SuggestedMapboxParams } from 'src/interfaces/listing'

export const getPropertyTypes = async () => {
  return Api.get('/listing-service/v1/native-property/property-types', {}).then((res) => res.data)
}

export const getPropertyAmenities = async () => {
  return Api.get('/listing-service/v1/native-property/amenities', {}).then((res) => res.data)
}

export const getPropertyBedTypes = async () => {
  return Api.get('/listing-service/v1/native-property/bed-types', {}).then((res) => res.data)
}

export const getPropertyDetail = async (id: string | number) => {
  return Api.get(`/listing-service/v1/native-property/${id}`, {}).then((res) => res.data)
}

export const createProperty = async (data: any) => {
  return Api.post('/listing-service/v1/native-property', data).then((res) => res.data)
}

export const updateProperty = async (id: string | number, data: any) => {
  return Api.post('/listing-service/v1/native-property/' + id, data).then((res) => res.data)
}

export const getPropertyImage = async (id: string | number) => {
  return Api.get(`/listing-service/v1/native-property/${id}/images`, {}).then((res) => res.data)
}

export const savePropertyImage = async (id: string | number, data: any) => {
  return Api.post(`/listing-service/v1/native-property/${id}/images`, data).then((res) => res.data)
}

export const uploadPropertyImage = async (id: string | number, data: any) => {
  return Api.post(`/listing-service/v1/native-property/${id}/upload`, data).then((res) => res.data)
}
export const publishProperty = async (id: string | number, data: any) => {
  return Api.post(`/listing-service/v1/native-property/${id}/publish`, data).then((res) => res.data)
}

export const getSuggestedMapbox = async (param: SuggestedMapboxParams, cancelParams?: any) => {
  let p: any = { params: { ...param } }
  if (cancelParams) p = { ...p, ...cancelParams }
  return Api.get(`https://api.mapbox.com/search/searchbox/v1/suggest`, p).then((res) => res?.data)
  // return Api.get(`https://api.mapbox.com/search/searchbox/v1/suggest`, p).then((res) => res?.data)
}
export const getLatLongMapbox = async (address: string, param: any, cancelParams?: any) => {
  let p: any = { params: { ...param } }
  if (cancelParams) p = { ...p, ...cancelParams }
  return Api.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json`, p).then(
    (res) => res?.data
  )
}

export const importICalCalendar = async (propertyId: string | number, data: any) => {
  return Api.post(`/listing-service/v1/native-property/${propertyId}/ical`, data).then((res) => res.data)
}

export const updateICalCalendar = async (propertyId: string | number, iCalId: string | number, data: any) => {
  return Api.post(`/listing-service/v1/native-property/${propertyId}/ical/${iCalId}`, data).then((res) => res.data)
}

export const getICalCalendar = async (id: string | number) => {
  return Api.get(`/listing-service/v1/native-property/${id}/ical`).then((res) => res.data)
}

export const deleteICalCalendar = async (id: string | number, iCalId: string | number) => {
  return Api.post(`/listing-service/v1/native-property/${id}/ical/${iCalId}/delete`).then((res) => res.data)
}

export const downloadICalCalendar = async (id: string | number, icalKey: string | number) => {
  return Api.get(`/listing-service/v1/native-property/${icalKey}/${id}/ical`).then((res) => res.data)
}

export const updateV2Calendar = async (bodyData: any) => {
  return Api.post('/listing-service/v2/calendar', bodyData).then((res) => res.data)
}

//https://api.dataismist.com/listing-service/v2/calendar/availability?id=2787
export const getAvailability = async (id: string | number) => {
  return Api.get('/listing-service/v2/calendar/availability', {
    params: {
      id,
    },
  }).then((res) => res.data)
}

// 'https://api.dataismist.com/listing-service/v2/calendar/availability
export const updateAvailability = async (bodyData: any) => {
  return Api.post('/listing-service/v2/calendar/availability', bodyData).then((res) => res.data)
}

// https://api.dataismist.com/listing-service/v2/calendar/rate?id=1212
export const getRates = async (id: string | number) => {
  return Api.get('/listing-service/v2/calendar/rate', {
    params: {
      id,
    },
  }).then((res) => res.data)
}
// https://api.dataismist.com/listing-service/v2/calendar
export const updateRates = (bodyData: any) => {
  return Api.post('/listing-service/v2/calendar', bodyData).then((res) => res.data)
}
export const updateProvideUrl = (id: string | number, provideUrl: string) => {
  return Api.post(`/listing-service/v1/native-property/${id}/provide-url`, { provideUrl }).then((res) => res.data)
}

export const getHostApproved = () => {
  return Api.get(`/listing-service/v1/native-property/host-approved`).then((res) => res.data)
}

// https://api.dataismist.com/listing-service/v1/native-property/111/fee-setting'
export const getFeeSettings = (propertyId: string | number) => {
  return Api.get(`/listing-service/v1/native-property/${propertyId}/fee-setting`).then((res) => res.data)
}

// https://api.dataismist.com/listing-service/v1/native-property/3180/discount-setting
export const updateDiscountSetting = (id: string | number, data: any) => {
  return Api.post(`/listing-service/v1/native-property/${id}/discount-setting`, data).then((res) => res.data)
}

// https://api.dataismist.com/listing-service//v1/native-property/3713/fee-setting
export const createCustomFee = (propertyId: string | number, feeData: any) => {
  return Api.post(`/listing-service/v1/native-property/${propertyId}/fee-setting`, {
    feeSettings: [feeData],
  }).then((res) => res.data)
}

export const updateCustomFee = (propertyId: string | number, feeId: string | number, feeData: any) => {
  return Api.post(`/listing-service/v1/native-property/${propertyId}/fee-setting/${feeId}`, feeData).then(
    (res) => res.data
  )
}

export const deleteFeeById = (propertyId: string | number, feeId: string | number) => {
  return Api.post(`/listing-service/v1/native-property/${propertyId}/fee-setting/${feeId}/delete`).then(
    (res) => res.data
  )
}

export const getTaxSettings = (propertyId: string | number) => {
  return Api.get(`/listing-service/v1/native-property/${propertyId}/tax-setting`).then((res) => res.data)
}

export const createCustomTax = (propertyId: string | number, taxData: any) => {
  return Api.post(`/listing-service/v1/native-property/${propertyId}/tax-setting`, {
    taxSettings: [taxData],
  }).then((res) => res.data)
}

export const updateCustomTax = (propertyId: string | number, taxId: string | number, taxData: any) => {
  return Api.post(`/listing-service/v1/native-property/${propertyId}/tax-setting/${taxId}`, taxData).then(
    (res) => res.data
  )
}

export const deleteTaxById = (propertyId: string | number, taxId: string | number) => {
  return Api.post(`/listing-service/v1/native-property/${propertyId}/tax-setting/${taxId}/delete`).then(
    (res) => res.data
  )
}

export const updateBulkProperties = (data: any) => {
  return Api.post(`/listing-service/v1/native-property/bulk-update`, data).then((res) => res.data)
}

export const getStatusProperties = (propertyIds: Array<number>) => {
  return Api.post(`/listing-service/v1/native-property/bulk/status-of-fields`, { propertyIds }).then((res) => res.data)
}
