import { getUserProfile } from '../../api/user'
import { GuestObject } from '../../interfaces'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import ic_close_md from 'src/assets/icons/ic_close_md.svg'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

interface Props {
  reservationId: string
  isOpen: boolean
  onClose: () => void
  listingId: string
  checkInDate: string | null
  checkOutDate: string | null
  guest: GuestObject
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SCREEN_TYPES = [
  {
    type: 'mobile',
  },
  {
    type: 'tablet',
  },
  {
    type: 'desktop',
  },
]

const PreviewReservation: React.FC<Props> = ({
  isOpen,
  onClose,
  listingId,
  checkInDate,
  checkOutDate,
  guest,
  reservationId,
}) => {
  const [selectedType, setSelectedType] = useState<string>('desktop')
  const [screenTypes, setScreenTypes] = useState<any[]>(SCREEN_TYPES)
  const { width: innerWidth } = useWindowDimensions()
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [userId, setUserId] = useState<string>('')

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        const res = await getUserProfile()
        const { data } = res
        setUserId(data.userId)
      } catch (err) {
        console.log(err)
      }
    }
    fetchUserProfile()
  }, [])

  const getPreviewUrl = () => {
    let url = `${process.env.REACT_APP_GUEST_DOMAIN}/${userId}/booking/${listingId}`
    url += `?check_in=${checkInDate}&check_out=${checkOutDate}&adults=${guest.adults}&children=${guest.children}&infants=${guest.infants}&pets=${guest.pets}&reservationId=${reservationId}`
    return url
  }

  useEffect(() => {
    if (innerWidth < 640) {
      // mobile
      setScreenTypes(() => SCREEN_TYPES.filter((item) => item.type === 'mobile'))
      setSelectedType('mobile')
    }
    if (640 <= innerWidth && innerWidth < 1024) {
      // tablet
      setScreenTypes(() => SCREEN_TYPES.filter((item) => item.type === 'mobile' || item.type === 'tablet'))
      setSelectedType((prevState) => (['mobile', 'tablet'].includes(prevState) ? prevState : 'tablet'))
    }
    if (1024 <= innerWidth) {
      // desktop
      setScreenTypes(SCREEN_TYPES)
    }
  }, [innerWidth])

  const onChangeType = (_type: string) => {
    setSelectedType(_type)
  }

  const renderWidthClass = () => {
    if (selectedType === 'mobile') {
      return 'w-[390px]'
    }
    if (selectedType === 'tablet') {
      return 'w-[768px]'
    }
    return 'w-full'
  }

  const renderBackGroundClass = () => {
    if (selectedType === 'mobile') {
      return `${innerWidth <= 390 ? 'rounded-[24px]' : 'pt-[40px] rounded-[16px]'} `
    }
    if (selectedType === 'tablet') {
      return `${innerWidth === 768 ? 'rounded-[24px]' : 'pt-[40px] rounded-[16px]'}`
    }
    if (selectedType === 'desktop') {
      if (innerWidth >= 1024) {
        return 'rounded-[24px]'
      }
    }
  }

  return (
    <Dialog
      fullScreen={true}
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: '#F0F0EC',
          padding: '0px 24px 24px 24px',
          // position: 'relative',
        },
      }}
    >
      <div className={'h-[80px] min-h-[80px] flex justify-between items-center gap-[2px]'}>
        <button
          className={'w-[40px] h-[40px] rounded-full bg-white z-10 flex justify-center items-center'}
          onClick={onClose}
        >
          <img src={ic_close_md} alt="ic_close_md" />
        </button>
        <div>
          {screenTypes.map((item) => (
            <button
              key={item.type}
              className={clsx(
                'h-[40px] px-[16px] rounded-[100px] font-maison-neue-medium text-12-12 text-neutral-600 capitalize',
                'hover:bg-[#8c8c8826]',
                {
                  ['bg-[#8c8c8826]']: item.type === selectedType,
                }
              )}
              onClick={() => onChangeType(item.type)}
            >
              {item.type}
            </button>
          ))}
        </div>
        <div />
      </div>

      <div className={clsx('bg-neutral-300 h-full w-full flex justify-center', renderBackGroundClass())}>
        <div className={clsx('h-full rounded-[24px]', renderWidthClass())}>
          <iframe
            ref={iframeRef}
            height="100%"
            width="100%"
            src={getPreviewUrl()}
            allow="fullscreen"
            title={'preview reservation'}
            className={'rounded-[24px] hidden-scroll-bar'}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default PreviewReservation
