import { handleShowListingStatusAfterImport } from '../../utils/property'
import BasicButton from '../ui/BasicButton'
import BasicDialog from '../ui/BasicDialog'
import { nativeMenu } from './NativeListingSideBar'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setBulkListingSection } from 'src/redux/slices/listing'
import { isEmpty } from 'src/utils/common'

const EditBulkListingConfirmDialog = () => {
  const { search } = useLocation()
  const dispatch = useAppDispatch()
  // const navigate = useNavigate()
  const { bulkListingSection } = useAppSelector((state) => state.listing)
  const parseQuery = queryString.parse(search)
  const { type } = useParams()
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const ids = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))

  useEffect(() => {
    setOpenConfirm(!isEmpty(bulkListingSection))
  }, [bulkListingSection])

  const handleClose = () => {
    setOpenConfirm(false)
    dispatch(setBulkListingSection(''))
  }

  const triggerCallStatusForImportHc = async () => {
    // trigger call api status for import HC
    const parseQuery = queryString.parse(search)
    const propertyId = parseQuery.id ? String(parseQuery.id) : ''
    const ids = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
    const listId = ids.join(',')
    await handleShowListingStatusAfterImport(listId || propertyId, true) // fetch status to show on import background
  }

  const item = nativeMenu.find((v: any) => v.id === type)

  return (
    <BasicDialog
      isOpen={openConfirm}
      onClose={handleClose}
      title={`Are you sure to want to apply these changes to ${ids?.length} listings?`}
      extraTitle={`Once confirmed, the following section will be updated: ${item?.name || ''}`}
      titleAlign={'center'}
      hiddenTitleDivider
    >
      <div className={'flex flex-col md:flex-row justify-end gap-4'}>
        <BasicButton
          size="md"
          variant={'outlined'}
          clases="w-auto !rounded-[100px]"
          onClick={() => {
            handleClose()
            // navigate(`/listings`)
          }}
        >
          Undo changes
        </BasicButton>
        <BasicButton
          size="md"
          variant={'contained'}
          clases="w-auto"
          color="red"
          onClick={async () => {
            const saveExitEl = document.getElementById(`${type}_save`)
            if (saveExitEl) saveExitEl.click()
            // trigger call api status for import HC
            triggerCallStatusForImportHc()
            handleClose()
          }}
          isRadius100={true}
        >
          Confirm
        </BasicButton>
      </div>
    </BasicDialog>
  )
}

export default EditBulkListingConfirmDialog
