import { createThirdwebClient } from 'thirdweb'
import { base, baseSepolia, polygon, polygonAmoy } from 'thirdweb/chains'

export const useClientThirdweb = () => {
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production'
  const clientID = isProduction ? '47f2c2f9c22e3d504fe35e2b3755ef54' : '2b5aa6073b4a783fd571a599a7b12431'

  return createThirdwebClient({ clientId: clientID })
}
export const useDefaultActiveChain = () => {
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production'
  return isProduction ? base : baseSepolia
}

export const useFactoryAddressThirdweb = () => {
  // mainnet
  const mainnetFactoryAddress = '0xdC96E4229CaC5f1cc4D17c91FbDb23882333888a'

  // Testnet
  const testnetFactoryAddress = '0x3ad29BA92f2aED2f5f1a7eB6e2366BB1519e5bBD'
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production'

  const factoryAddress = isProduction ? mainnetFactoryAddress : testnetFactoryAddress

  return factoryAddress
}

export const useSupportedTokens = () => {
  const supportedTokens = {
    [baseSepolia.id]: [
      {
        address: '0xB22f463e8CA0552c97616c8675A0b8Fa86c4C020',
        name: 'Tether USD',
        symbol: 'USDT',
        icon: 'https://static.dtravel.com/coin-logo/usdt.svg',
      },
      {
        address: '0x10C4B4fA9cA7af772452FF9F0c27A59C0029917E',
        name: 'TRVL',
        symbol: 'TRVL',
        icon: 'https://static.dtravel.com/coin-logo/trvl-logo.svg',
      },
      {
        address: '0x942646328104CA3dd7000050811ad54B5c43B28B',
        name: 'USD Coin',
        symbol: 'USDC',
        icon: 'https://static.dtravel.com/coin-logo/usdc.svg',
      },
    ],
    [polygonAmoy.id]: [
      {
        address: '0xcc15AE37CfD6CA7804BdD30574b6244a6F52Dd3A',
        name: 'Tether USD',
        symbol: 'USDT',
        icon: 'https://static.dtravel.com/coin-logo/usdt.svg',
      },
      {
        address: '0x3b2e37b4A77CAc65B16a9BF4908aC160c1ee768f',
        name: 'TRVL',
        symbol: 'TRVL',
        icon: 'https://static.dtravel.com/coin-logo/trvl-logo.svg',
      },
      {
        address: '0xC469496afaa75EBdDD8a43408609a29e4c063A37',
        name: 'USD Coin',
        symbol: 'USDC',
        icon: 'https://static.dtravel.com/coin-logo/usdc.svg',
      },
    ],
    [base.id]: [
      {
        address: '0x74aa9bB52B36a378a6E641B86d7acb76Dc9b3940',
        name: 'TRVL',
        symbol: 'TRVL',
        icon: 'https://static.dtravel.com/coin-logo/trvl-logo.svg',
      },
      {
        address: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
        name: 'USD Coin',
        symbol: 'USDC',
        icon: 'https://static.dtravel.com/coin-logo/usdc.svg',
      },
    ],
    [polygon.id]: [
      {
        address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
        name: 'Tether USD',
        symbol: 'USDT',
        icon: 'https://static.dtravel.com/coin-logo/usdt.svg',
      },
      {
        address: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
        name: 'USD Coin',
        symbol: 'USDC',
        icon: 'https://static.dtravel.com/coin-logo/usdc.svg',
      },
    ],
  }
  return supportedTokens
}
