import { FormError } from '../../../interfaces'
import BasicInputPrice from '../../ui/BasicInputPrice'
import BasicSwitch from '../../ui/BasicSwitch'
import React from 'react'

interface Props {
  currency: string
  data: any
  updateDayOfWeek: (data: any[], index: number, field: string) => void
  onBlurDayOfWeek: (day: string, valeu: string) => void
  errors: {
    monday: FormError
    tuesday: FormError
    wednesday: FormError
    thursday: FormError
    friday: FormError
    saturday: FormError
    sunday: FormError
  }
}

const RatePerDayOfWeek: React.FC<Props> = ({ currency, data, updateDayOfWeek, onBlurDayOfWeek, errors }) => {
  const handleChangeData = (index: number, field: string, value?: string | number | boolean) => {
    const clone = [...data]
    clone[index] = { ...(clone[index] || {}), [field]: value }
    updateDayOfWeek(clone, index, field)
  }

  return (
    <>
      <p className="mb-1 text-neutral-800 text-20-32 font-maison-neue">Set custom rates for any day of the week</p>
      <p className="mb-6 text-neutral-600 text-14-20 font-maison-neue">Override your base rate for certain days</p>
      <div className={'flex flex-col gap-[8px]'}>
        {Array.isArray(data) &&
          data.map((item, index) => {
            return (
              <div className="w-full flex items-start justify-between py-[0]" key={item.day}>
                <div className="flex items-center gap-4 min-h-[48px]">
                  <BasicSwitch
                    checked={!!item?.isEnabled}
                    onChange={(event) => handleChangeData(index, 'isEnabled', event.target.checked)}
                    checkedColor="#292926"
                  />
                  <span className={'capitalize'}>{item.day}</span>
                </div>
                <div className="text-16-20 font-maison-neue flex items-center gap-4">
                  {item?.isEnabled && (
                    <div className="w-[140px] min-w-[140px] md:w-[200px] md:min-w-[200px]">
                      <BasicInputPrice
                        currency={currency}
                        value={item.price}
                        onValueChange={(values) => handleChangeData(index, 'price', values.value)}
                        onBlur={(event) => {
                          const input = event.currentTarget as HTMLInputElement
                          onBlurDayOfWeek(item.day, input.value)
                        }}
                        // @ts-ignore
                        error={errors[item.day]}
                      />
                    </div>
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}

export default RatePerDayOfWeek
