import React from 'react'
import BasicRadio from 'src/components/ui/BasicRadio'

interface Props {
  value: string
  onChange: (type: string) => void
}

const ApplyPercentageFeeOn: React.FC<Props> = ({ value, onChange }) => {
  return (
    <div className={'flex flex-col gap-[8px]'}>
      {[
        { key: 'BASE_PRICE', label: 'Apply on base price' },
        { key: 'ENTIRE_RESERVATION', label: 'Apply on entire reservation' },
      ].map((item) => (
        <label key={item.key} htmlFor={item.key} className={'flex items-center gap-[8px]'}>
          <BasicRadio
            id={item.key}
            name={'percentageFeeApplyOn'}
            isWidthHeight20px
            checked={value === item.key}
            value={item.key}
            onChange={(event) => onChange(event.target.value)}
          />
          <span>{item.label}</span>
        </label>
      ))}
    </div>
  )
}

export default ApplyPercentageFeeOn
