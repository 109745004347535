import { createPromotion, getSupportCurrencies } from '../../../api/reservation'
import { PROMO_CODE_TYPE } from '../../../contants/common'
import { SUPPORTED_CURRENCIES } from '../../../contants/property'
import { FormError } from '../../../interfaces'
import { setToastSuccess } from '../../../redux/slices/common'
import { handleErrorMessage, numberWithCommas } from '../../../utils/common'
import Error from '../../common/Error'
import { IconCalendar } from '../../common/Icons'
import SelectDateSingle from '../../common/SelectDateSingle'
import BasicButton from '../../ui/BasicButton'
import BasicCheckbox from '../../ui/BasicCheckbox'
import BasicDialog from '../../ui/BasicDialog'
import BasicInput from '../../ui/BasicInput'
import BasicInputPrice from '../../ui/BasicInputPrice'
import BasicSelect from '../../ui/BasicSelect'
import clsx from 'clsx'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

interface Props {
  isOpen: boolean
  onClose: () => void
  fetchPromoCode?: () => void
}

const DEFAULT_DATA = {
  code: '',
  name: '',
  type: '', // default: fixed percentage/fixed
  value: null,
  currency: 'USD',
  maxDiscountAmount: null,
  maxUsage: null,
  validFrom: null,
  validTo: null,
}

const DEFAULT_ERRORS = {
  code: { show: false, message: '' },
  name: { show: false, message: '' },
  type: { show: false, message: '' },
  value: { show: false, message: '' },
  validFrom: { show: false, message: '' },
  validTo: { show: false, message: '' },
  maxUsage: { show: false, message: '' },
  maxDiscountAmount: { show: false, message: '' },
}

const AddNewPromoCode: React.FC<Props> = ({ isOpen, onClose, fetchPromoCode }) => {
  const dispatch = useDispatch()
  const [dateFromElement, setDateFromElement] = useState<HTMLButtonElement | null>(null)
  const [dateToElement, setDateToElement] = useState<HTMLButtonElement | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLimitNumberOfUsed, setIsLimitNumberOfUsed] = useState<boolean>(false)
  const [data, setData] = useState<any>(DEFAULT_DATA)
  const [errors, setErrors] = useState<{
    code: FormError
    name: FormError
    type: FormError
    value: FormError
    validFrom: FormError
    validTo: FormError
    maxUsage: FormError
    maxDiscountAmount: FormError
  }>(DEFAULT_ERRORS)

  const [currencyOptions, setCurrencyOptions] = useState<Array<{ value: string; label: string }>>([])

  useEffect(() => {
    async function fetchSupportedCurrencies() {
      const options = SUPPORTED_CURRENCIES.map((item) => ({ label: item.key, value: item.key }))
      try {
        const res = await getSupportCurrencies()
        if (Array.isArray(res.data)) {
          setCurrencyOptions(
            res.data
              .filter((item: any) => item.type === 'FIAT')
              .map((item: any) => ({ label: item.key, value: item.key }))
          )
        } else {
          setCurrencyOptions(options)
        }
      } catch (err: any) {
        setCurrencyOptions(options)
      }
    }
    fetchSupportedCurrencies()
  }, [])

  const onCloseDateFrom = () => {
    setDateFromElement(null)
  }

  const onCloseDateTo = () => {
    setDateToElement(null)
  }

  const onDateFromChange = (date: Moment | null) => {
    setData((prevState: any) => ({ ...prevState, validFrom: date ? date : null }))
    setErrors((prevState) => ({ ...prevState, validFrom: { show: false, message: '' } }))
    onCloseDateFrom()
  }

  const onDateToChange = (date: Moment | null) => {
    setData((prevState: any) => ({ ...prevState, validTo: date ? date : null }))
    setErrors((prevState) => ({ ...prevState, validTo: { show: false, message: '' } }))
    onCloseDateTo()
  }

  const onChangeData = (field: string, value: string | number) => {
    const convertValue = field === 'code' ? String(value).toUpperCase() : value
    setData((prevState: any) => ({ ...prevState, [field]: convertValue }))
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      if (field === 'type') {
        setErrors((prevState) => ({
          ...prevState,
          type: { show: false, message: '' },
          value: { show: false, message: '' },
        }))
      } else {
        setErrors((prevState) => ({ ...prevState, [field]: { show: false, message: '' } }))
      }
    }
  }

  const onChangeLimitNumberOfUsed = (checked: boolean) => {
    if (!checked) {
      setData((prevState: any) => ({ ...prevState, maxUsage: null }))
    }
    setIsLimitNumberOfUsed(checked)
  }

  const handleClose = () => {
    // clear data
    setData(DEFAULT_DATA)
    setErrors(DEFAULT_ERRORS)
    setIsLimitNumberOfUsed(false)
    onClose()
  }

  const validateForm = () => {
    const MAX_VALUE = 1000000000
    let isValid: boolean = true
    const requiredFields = ['code', 'value', 'type']
    requiredFields.forEach((field) => {
      if (!data[field] || (typeof data[field] === 'string' && !data[field].trim())) {
        setErrors((prevState) => ({
          ...prevState,
          [field]: {
            show: true,
            message: field === 'type' ? 'Please select discount type' : `Please enter valid ${field}`,
          },
        }))
        isValid = false
      }
      if (field === 'code' && data.code) {
        // if (data[field].length < 5 || data[field].length > 15) {
        if (data[field].length < 5) {
          setErrors((prevState) => ({
            ...prevState,
            [field]: {
              show: true,
              message: 'Please enter minimum 5 characters',
            },
          }))
          isValid = false
        }
        const regEx = /^[0-9a-zA-Z]+$/ //alphanumeric format
        if (!regEx.test(data[field])) {
          setErrors((prevState) => ({
            ...prevState,
            [field]: {
              show: true,
              message: 'Code must be alphanumeric format',
            },
          }))
          isValid = false
        }
      }
      if (field === 'value' && data.value) {
        if (!Number(data.value) || Number(data.value) < 1) {
          setErrors((prevState) => ({
            ...prevState,
            [field]: {
              show: true,
              message: 'Discount value must be equal or greater than 1',
            },
          }))
          isValid = false
        }
        if (Number(data.value) > MAX_VALUE) {
          setErrors((prevState) => ({
            ...prevState,
            [field]: {
              show: true,
              message: `Discount value must be less than or equal ${numberWithCommas(MAX_VALUE)}`,
            },
          }))
          isValid = false
        }
        if (data.type === PROMO_CODE_TYPE.PERCE && Number(data.value) > 100) {
          setErrors((prevState) => ({
            ...prevState,
            [field]: {
              show: true,
              message: 'Percentage discount value must be less than or equal 100',
            },
          }))
          isValid = false
        }
      }
    })
    if (data.type === PROMO_CODE_TYPE.PERCE && data.maxDiscountAmount) {
      if (Number(data.maxDiscountAmount) < 0) {
        setErrors((prevState) => ({
          ...prevState,
          maxDiscountAmount: {
            show: true,
            message: 'Capped at must be positive number',
          },
        }))
        isValid = false
      }
      if (Number(data.maxDiscountAmount) > MAX_VALUE) {
        setErrors((prevState) => ({
          ...prevState,
          maxDiscountAmount: {
            show: true,
            message: `Capped at must be less than or equal ${numberWithCommas(MAX_VALUE)}`,
          },
        }))
        isValid = false
      }
    }

    if (data.validFrom && data.validTo) {
      if (moment(data.validTo).endOf('days').isSameOrBefore(moment(data.validFrom).startOf('days'))) {
        setErrors((prevState) => ({
          ...prevState,
          validTo: {
            show: true,
            message: 'End date must be greater than start date',
          },
        }))
        isValid = false
      }
    }

    if (isLimitNumberOfUsed) {
      if (!data.maxUsage) {
        setErrors((prevState) => ({
          ...prevState,
          maxUsage: {
            show: true,
            message: 'Please enter maximum discount uses',
          },
        }))
        isValid = false
      }
      if (Number(data.maxUsage) < 0) {
        setErrors((prevState) => ({
          ...prevState,
          maxUsage: {
            show: true,
            message: 'Maximum discount uses must be greater than 0',
          },
        }))
        isValid = false
      }
      if (Number(data.maxUsage) > MAX_VALUE) {
        setErrors((prevState) => ({
          ...prevState,
          maxUsage: {
            show: true,
            message: `Maximum discount uses must be less than or equal ${numberWithCommas(MAX_VALUE)}`,
          },
        }))
        isValid = false
      }
    }

    return isValid
  }

  const onSubmit = async () => {
    const isValid = validateForm()
    if (isValid) {
      try {
        setIsLoading(true)
        const bodyData = { ...data }
        if (!bodyData.name) delete bodyData.name
        if (!bodyData.validFrom) delete bodyData.validFrom
        if (!bodyData.validTo) delete bodyData.validTo
        if (!bodyData.maxDiscountAmount) delete bodyData.maxDiscountAmount
        if (!bodyData.maxUsage) delete bodyData.maxUsage
        if (bodyData.validTo && !bodyData.validFrom) {
          bodyData.validFrom = moment.utc().startOf('days').format()
        }
        if (bodyData.validFrom) {
          bodyData.validFrom = moment.utc(bodyData.validFrom).startOf('days').toISOString()
        }
        if (bodyData.validTo) {
          bodyData.validTo = moment.utc(bodyData.validTo).endOf('days').toISOString()
        }
        if (bodyData.type === PROMO_CODE_TYPE.FIXED) {
          delete bodyData.maxDiscountAmount
        }
        await createPromotion(bodyData)
        dispatch(setToastSuccess({ message: 'Add a promo code successfully' }))
        handleClose()
        if (typeof fetchPromoCode === 'function') {
          fetchPromoCode()
        }
      } catch (err: any) {
        handleErrorMessage(err)
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <BasicDialog
      isOpen={isOpen}
      onClose={handleClose}
      title={'Add promo code'}
      extraTitle={'Create promo codes to offer discounts on your direct booking site.'}
    >
      <div className={'flex flex-col gap-[24px]'}>
        <div>
          <BasicInput
            label={'Name (Optional)'}
            placeholder={'Enter name'}
            error={errors.name}
            maxLength={100}
            value={data.name}
            onChange={(event) => onChangeData('name', event.target.value)}
            onBlur={() => {
              setData((prevState: any) => ({ ...prevState, name: prevState.name?.trim() }))
            }}
          />
        </div>
        <div>
          <BasicInput
            label={'Code'}
            placeholder={'Enter code'}
            error={errors.code}
            minLength={5}
            maxLength={15}
            value={data.code}
            onChange={(event) => onChangeData('code', event.target.value)}
            onBlur={() => {
              setData((prevState: any) => ({ ...prevState, code: prevState.code?.trim() }))
            }}
          />
        </div>
        <div>
          <BasicSelect
            label={'Discount type'}
            options={[
              { value: '', label: 'Choose an option' },
              { value: PROMO_CODE_TYPE.FIXED, label: 'Fixed amount' },
              { value: PROMO_CODE_TYPE.PERCE, label: 'Percentage' },
            ]}
            value={data.type}
            onChange={(event) => onChangeData('type', event.target.value)}
            error={errors.type}
          />
        </div>

        {/* Fixed: currency and value */}
        {data.type === PROMO_CODE_TYPE.FIXED && (
          <div>
            <p className={'font-maison-neue-medium text-14-18 text-neutral-800 mb-[8px]'}>Value</p>
            <div className={'flex gap-[8px]'}>
              <BasicSelect
                options={currencyOptions}
                classes={'min-w-[132px]'}
                value={data.currency}
                onChange={(event) => onChangeData('currency', event.target.value)}
              />
              <BasicInputPrice
                placeholder={'Enter value'}
                value={data.value || undefined}
                onValueChange={(values) => onChangeData('value', Number(values.value))}
                error={errors.value}
              />
            </div>
          </div>
        )}

        {/* Percentage: Value and Capped at */}
        {data.type === PROMO_CODE_TYPE.PERCE && (
          <>
            <div className={'relative'}>
              <BasicInput
                label={'Value'}
                placeholder={'Enter value'}
                value={data.value || ''}
                onChange={(event) => onChangeData('value', Number(event.target.value))}
                error={errors.value}
                type={'number'}
                classes={'inputNumberHideArrow'}
                min={1}
                max={100}
                endAdornment={
                  <span
                    className={
                      'absolute right-[12px] bottom-[4px] -translate-y-1/2 font-maison-neue text-16-20 text-neutral-900'
                    }
                  >
                    %
                  </span>
                }
              />
            </div>

            <div>
              <p className={'font-maison-neue-medium text-14-18 text-neutral-800 mb-[8px]'}>Capped at (Optional)</p>
              <div className={'flex gap-[8px] items-start'}>
                <BasicSelect
                  options={currencyOptions}
                  classes={'min-w-[132px]'}
                  value={data.currency}
                  onChange={(event) => onChangeData('currency', event.target.value)}
                />
                <BasicInputPrice
                  placeholder={'Enter capped amount'}
                  value={data.maxDiscountAmount || undefined}
                  onValueChange={(values) => onChangeData('maxDiscountAmount', Number(values.value))}
                  error={errors.maxDiscountAmount}
                />
              </div>
            </div>
          </>
        )}

        <div>
          <p className={'font-maison-neue-medium text-14-18 text-neutral-800'}>Expiration date (Optional)</p>
          <div className={'mt-[8px] flex gap-[16px] items-start'}>
            <div className={'w-full'}>
              <button
                className={clsx(
                  'h-[48px] rounded-[12px] px-[16px] shadow-xs border-[1px] md:border-[0.5px] border-[#00000026] hover:border-neutral-900',
                  'focus:border-[1px] focus:border-neutral-900 focus:outline-none',
                  'w-full',
                  'flex items-center gap-[12px]',
                  {
                    'border border-red-600 hover:border-red-600 focus:border-red-600': errors.validFrom?.show,
                  }
                )}
                onClick={(event) => setDateFromElement(event.currentTarget)}
              >
                <span>
                  <IconCalendar />
                </span>
                <span>{data.validFrom ? moment(data.validFrom).format('MM/DD/YYYY') : 'mm/dd/yyyy'}</span>
              </button>
              <Error {...errors.validFrom} />
            </div>

            <div className={'w-auto pt-[16px]'}>to</div>

            <div className={'w-full'}>
              <button
                className={clsx(
                  'h-[48px] rounded-[12px] px-[16px] shadow-xs border-[1px] md:border-[0.5px] border-[#00000026] hover:border-neutral-900',
                  'focus:border-[1px] focus:border-neutral-900 focus:outline-none',
                  'w-full',
                  'flex items-center gap-[12px]',
                  {
                    'border border-red-600 hover:border-red-600 focus:border-red-600': errors.validTo?.show,
                  }
                )}
                onClick={(event) => setDateToElement(event.currentTarget)}
              >
                <span>
                  <IconCalendar />
                </span>
                <span>{data.validTo ? moment(data.validTo).format('MM/DD/YYYY') : 'mm/dd/yyyy'}</span>
              </button>
              <Error {...errors.validTo} />
            </div>
          </div>
          <SelectDateSingle
            date={data.validFrom ? moment(data.validFrom) : null}
            onDateChange={onDateFromChange}
            anchorElement={dateFromElement}
            onClose={onCloseDateFrom}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 496, horizontal: 'left' }}
          />
          <SelectDateSingle
            date={data.validTo ? moment(data.validTo) : null}
            onDateChange={onDateToChange}
            anchorElement={dateToElement}
            onClose={onCloseDateTo}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 496, horizontal: 'right' }}
          />
        </div>

        <div>
          <label
            htmlFor={'limitNumberOfTime'}
            className={clsx('flex  gap-[24px] font-maison-neue-medium text-16-20 text-neutral-900', {
              ['opacity-50 cursor-not-allowed']: false,
            })}
          >
            <BasicCheckbox
              id={'limitNumberOfTime'}
              color={'black'}
              checked={isLimitNumberOfUsed}
              onChange={(event) => onChangeLimitNumberOfUsed(event.target.checked)}
            />
            <span>Limit number of times this discount can be used in total</span>
          </label>
        </div>

        {isLimitNumberOfUsed && (
          <div>
            <BasicInput
              label={'Maximum discount uses'}
              placeholder={'Enter number of maximum discount uses'}
              value={data.maxUsage || ''}
              onChange={(event) => onChangeData('maxUsage', Number(event.target.value))}
              type={'number'}
              classes={'inputNumberHideArrow'}
              error={errors.maxUsage}
            />
          </div>
        )}

        <BasicButton
          variant={'contained'}
          color={'red'}
          size={'xl'}
          isRadius100={true}
          clases={'w-full'}
          loading={isLoading}
          onClick={onSubmit}
        >
          Confirm
        </BasicButton>
      </div>
    </BasicDialog>
  )
}

export default AddNewPromoCode
