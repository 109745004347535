import HomeContainer from '../components/home/HomeContainer'
import HandleRedirectFromHospitable from '../components/property-import-airbnb/HandleRedirectFromHospitable'
import CreateReservation from '../components/reservation-manual/CreateReservation'
import NotFound from 'src/components/404/NotFound'
import BlankLayout from 'src/components/layout/BlankLayout'
import NativeListing from 'src/components/native-listing/NativeListing'
import PropertyDetail from 'src/components/property/PropertyDetail'
import PropertyList from 'src/components/property/PropertyList'
import ReservationDetail from 'src/components/reservation/ReservationDetail'
import ReservationList from 'src/components/reservation/ReservationList'
import Settings from 'src/components/settings/Settings'
import SignUp from 'src/components/sign-up/SignUp'
import Survey from 'src/components/survey/Survey'
import { RouterProps } from 'src/interfaces'

const routes: Array<RouterProps> = [
  {
    path: '/',
    name: 'Home',
    component: HomeContainer,
    exact: true,
    layout: BlankLayout,
  },
  {
    path: '/listings',
    name: 'PropertyList',
    component: PropertyList,
    exact: true,
    layout: BlankLayout,
    isFullPage: true,
  },
  {
    path: '/listings/:propertyId',
    name: 'PropertyDetail',
    component: PropertyDetail,
    exact: true,
    layout: BlankLayout,
  },
  {
    path: '/create-reservation',
    name: 'CreateReservation',
    component: CreateReservation,
    exact: true,
    layout: BlankLayout,
  },
  {
    path: '/reservations',
    name: 'ReservationList',
    component: ReservationList,
    exact: true,
    layout: BlankLayout,
  },
  {
    path: '/reservations/:reservationId',
    name: 'ReservationDetail',
    component: ReservationDetail,
    layout: BlankLayout,
  },
  // {
  //   path: '/help',
  //   name: 'Help',
  //   component: Help,
  //   exact: true,
  //   layout: BlankLayout,
  // },
  {
    path: '/survey',
    name: 'Survey',
    component: Survey,
    exact: true,
    layout: BlankLayout,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    exact: true,
    layout: BlankLayout,
  },
  {
    path: '/native-listing/:type',
    name: 'NativeListing',
    component: NativeListing,
    exact: true,
    layout: BlankLayout,
  },
  {
    path: '/login',
    name: 'Signup',
    component: SignUp,
    exact: true,
    layout: BlankLayout,
  },
  {
    // path: '/connect-hospitable-success',
    path: '/handle-connect-hospitable',
    name: 'HandleRedirectFromHospitable',
    component: HandleRedirectFromHospitable,
    exact: true,
    layout: BlankLayout,
  },
  // 404
  {
    path: '*',
    name: '404',
    exact: true,
    component: NotFound,
    layout: BlankLayout,
  },
]

export default routes
