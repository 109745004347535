import { setToastSuccess, setTriggerTime } from '../../redux/slices/common'
import { handleErrorMessage } from '../../utils/common'
import BasicButton from '../ui/BasicButton'
import BasicPopup from '../ui/BasicPopup'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteCustomUrl } from 'src/api/user'

interface Props {
  disabled: boolean
  id: string
  handleTriggerOtherPopup?: any
  handleSuccess?: any
}

const RemoveUrl: React.FC<Props> = ({ disabled, id, handleTriggerOtherPopup, handleSuccess }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [errorApi, setErrorApi] = useState<string>('')

  useEffect(() => {
    if (handleTriggerOtherPopup) handleTriggerOtherPopup(open)
  }, [open])
  const clearError = () => {
    setErrorApi('')
  }

  const handleOpenPopup = () => {
    setOpen(true)
  }

  const handleClosePopup = () => {
    setOpen(false)
    clearError()
  }

  const onSubmit = async () => {
    try {
      setSubmitLoading(true)
      await deleteCustomUrl(id)
      dispatch(setToastSuccess({ message: 'Remove custom URL success!' }))
      dispatch(setTriggerTime(+new Date())) // trigger to fetch profile again (function fetch profile in Settings.tsx)
      handleClosePopup()
      if (handleSuccess) handleSuccess()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setSubmitLoading(false)
    }
  }
  return (
    <>
      <BasicButton
        variant={'outlined'}
        size={'md'}
        clases={'w-full whitespace-nowrap h-[48px] rounded-[100px_!important]'}
        onClick={handleOpenPopup}
        disabled={disabled}
      >
        Remove
      </BasicButton>

      <BasicPopup
        isOpen={Boolean(open)}
        onClose={handleClosePopup}
        title={`Remove your custom URL`}
        extraTitle={
          'Are you sure you want to remove this domain? Your URL will no longer be able to find the website through the link.'
        }
      >
        <div>
          {errorApi && (
            <div className={'p-4 bg-red-1 rounded-[12px] flex items-center gap-[8px] mt-[8px]'}>
              <span>
                <ErrorOutlineOutlinedIcon sx={{ color: '#A32A30' }} />
              </span>
              <p className={'w-full break-all font-maison-neue-medium text-16-20 text-red-6'}>{errorApi}</p>
            </div>
          )}
        </div>

        <div>
          <BasicButton
            variant={'contained'}
            size={'xl'}
            clases={'w-full h-[48px] rounded-[100px_!important]'}
            color="red"
            loading={submitLoading}
            onClick={onSubmit}
          >
            Confirm
          </BasicButton>
        </div>
      </BasicPopup>
    </>
  )
}

export default RemoveUrl
