import { ONBOARDING_STEP } from '../../../contants/user'
import { handleNextOnboardingStep } from '../../../redux/slices/user'
import { getUserInfo, saveUserInfo } from '../../../utils/user'
import InputPhoneNumber from '../../common/InputPhoneNumber'
import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import { addContactPhone, getUserProfile, updateHostSettings, updateUserProfile } from 'src/api/user'
import BasicButton from 'src/components/ui/BasicButton'
import BasicInput from 'src/components/ui/BasicInput'
import { FormError } from 'src/interfaces'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { handleErrorMessage, isEmailValid, isEmpty } from 'src/utils/common'

interface Props {
  handleUpdatePreview: any
  fetchHostSettings: any
}
const OnboardingSiteDetailsContact = ({ handleUpdatePreview, fetchHostSettings }: Props) => {
  const dispatch = useAppDispatch()
  const { profile, settingData } = useAppSelector((state) => state.user)
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [whatsApp, setWhatsApp] = useState<string>('')
  const [telegram, setTelegram] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorEmail, setErrorEmail] = useState<FormError>({ show: false, message: '' })
  const [errorPhone, setErrorPhone] = useState<FormError>({ show: false, message: '' })
  const [errorWhatsApp, setErrorWhatsApp] = useState<FormError>({ show: false, message: '' })
  // const [errorTelegram, setErrorTelegram] = useState<FormError>({ show: false, message: '' })
  const isDisabled: boolean =
    isLoading ||
    errorEmail?.show ||
    errorPhone?.show ||
    errorWhatsApp?.show ||
    (isEmpty(email) && isEmpty(phone) && isEmpty(whatsApp) && isEmpty(telegram))

  useEffect(() => {
    setWhatsApp(settingData?.whatsapp || '')
    setTelegram(settingData?.telegram || '')
  }, [settingData])
  useEffect(() => {
    setEmail(profile?.email || '')
    setPhone(profile?.phone || '')
  }, [profile])
  useEffect(() => {
    handleUpdatePreview({ email, phone, whatsApp, telegram })
  }, [email, phone, whatsApp, telegram])

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
    setErrorEmail({ show: false, message: '' })
  }
  const onBlurEmail = () => {
    if (!isEmpty(email.trim()) && !isEmailValid(email || '')) {
      setErrorEmail({ show: true, message: 'Please enter a valid email number.' })
    }
  }
  // const onChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setPhone(event.target.value)
  //   setErrorPhone({ show: false, message: '' })
  // }
  const onBlurPhone = () => {
    if (!isEmpty(phone.trim()) && !isValidPhoneNumber(phone || '')) {
      setErrorPhone({ show: true, message: 'Please enter a valid phone number.' })
    }
  }
  const onChangeWhatsApp = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWhatsApp(event.target.value)
    setErrorWhatsApp({ show: false, message: '' })
  }
  const onBlurWhatsApp = () => {
    if (!isEmpty(whatsApp.trim()) && !isValidPhoneNumber(whatsApp || '')) {
      setErrorWhatsApp({ show: true, message: 'Please enter a valid WhatsApp number.' })
    }
  }
  const onChangeTelegram = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTelegram(event.target.value)
    // setErrorTelegram({ show: false, message: '' })
  }
  const convertPhone = () => {
    if (phone.includes('+')) return phone
    return `+${phone}`
  }

  const handleNextStep = () => {
    dispatch(handleNextOnboardingStep(ONBOARDING_STEP.SITE_DETAILS_PAYMENT_METHODS))
  }

  const handleSave = async () => {
    setIsLoading(true)
    try {
      let dataHostSetting: any = {}
      if (!isEmpty(whatsApp)) dataHostSetting = { ...dataHostSetting, whatsapp: whatsApp }
      if (!isEmpty(telegram)) dataHostSetting = { ...dataHostSetting, telegram }
      if (!isEmpty(dataHostSetting)) {
        await updateHostSettings(dataHostSetting)
        fetchHostSettings()
      }
      let dataProfile: any = {}
      if (!isEmpty(phone)) await addContactPhone({ phone: convertPhone(), type: 'host' })
      if (!isEmpty(email)) dataProfile = { ...dataProfile, email }
      // const user: any = getUserInfo()
      // let dataProfileDTO: any = { gettingStarted: { ...user?.gettingStarted, isSurveySubmitted: true } }
      if (!isEmpty(dataProfile)) {
        // dataProfileDTO = { ...dataProfileDTO, ...dataProfile }
        await updateUserProfile(dataProfile)
        const user: any = getUserInfo()
        const res: any = await getUserProfile()
        saveUserInfo({ ...user, ...res.data })
      }
      handleNextStep()
      // await updateUserProfile(dataProfileDTO)
      // const res: any = await getUserProfile()
      // saveUserInfo({ ...user, ...res.data })

      // dispatch(setIsSurveySubmitted(true))
      // dispatch(handleNextOnboardingStep(ONBOARDING_STEP.SITE_DETAILS_CONTACT_INFO))
      // dispatch(setToastSuccess({ message: 'success' }))
    } catch (err) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const renderFooterBtn = () => {
    return (
      <div className="flex items-center gap-4">
        <BasicButton
          size={'xl'}
          variant={'contained'}
          clases={'w-auto h-[48px] rounded-[100px_!important] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]'}
          color="red"
          onClick={handleSave}
          disabled={isDisabled}
        >
          Continue
        </BasicButton>
        <BasicButton
          size={'xl'}
          variant={'contained'}
          clases={
            'w-auto h-[48px] rounded-[100px_!important] border border-neutral-300 shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]'
          }
          color="white"
          onClick={handleNextStep}
        >
          Skip
        </BasicButton>
      </div>
    )
  }

  return (
    <>
      <p className="mb-1 text-24-36 text-neutral-800">Let guests know how to contact you:</p>
      <p className="mb-6 text-16-24 text-neutral-800">
        We require at least one contact method and recommend adding as many as possible.
      </p>
      <div className="flex flex-col gap-6">
        <div className="w-full flex flex-col gap-4">
          <div>
            <BasicInput
              label={'Email'}
              placeholder={'E.g. contact@mauiproperties.com'}
              value={email}
              onChange={onChangeEmail}
              onBlur={onBlurEmail}
              error={errorEmail}
            />
          </div>
          <div>
            <p className={'block font-maison-neue-medium text-14-18 text-neutral-800 mb-[8px]'}>Phone</p>
            <InputPhoneNumber
              phone={phone}
              defaultCountry={parsePhoneNumber(phone || '')?.country}
              onChange={(phoneValue: any) => {
                setPhone(phoneValue)
                setErrorPhone({ show: false, message: '' })
              }}
              handleBlur={onBlurPhone}
              error={errorPhone}
            />
            {/*<Error {...errorPhone} />*/}
          </div>
          <div>
            <BasicInput
              label={'WhatsApp'}
              placeholder={'E.g. +12345678910'}
              value={whatsApp}
              onChange={onChangeWhatsApp}
              onBlur={onBlurWhatsApp}
              error={errorWhatsApp}
            />
          </div>
          <div>
            <BasicInput
              label={'Telegram'}
              placeholder={'E.g. dtravelcommunity'}
              value={telegram}
              onChange={onChangeTelegram}
              // onBlur={() => setTelegram((prevState) => prevState.trim())}
              // error={errorTelegram}
            />
          </div>
        </div>
        <div
          className={clsx(
            'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
            'border-t border-neutral-300 px-4 md:px-8',
            'shadow-[0px_-2px_4px_-2px_rgba(0,0,0,0.08),0px_-4px_8px_-2px_rgba(0,0,0,0.06)]'
          )}
        >
          {renderFooterBtn()}
        </div>
        <div className="hidden lg:block">{renderFooterBtn()}</div>
      </div>
    </>
  )
}

export default OnboardingSiteDetailsContact
