export const RENTAL_TYPE = {
  ENTIRE_PLACE: 'Entire Place',
  PRIVATE_ROOM: 'Private room',
  SHARED_ROOM: 'Shared room',
}
export const PROPERTY_STATUS = {
  LISTED: 'listed',
  UNLISTED: 'unlisted',
  DELETED: 'deleted',
  DRAFT: 'draft',
  PENDING_REVIEW: 'pending_review',
  INCOMPLETE: 'incomplete',
}
export const PAYMENT_FILTER = {
  FIAT: 'Fiat only',
  CRYPTO: 'Crypto only',
  BOTH: 'Fiat and Crypto',
  NONE: 'None',
}

export const PROPERTY_SOURCE = {
  DTRAVEL: 'native',
  HOSTAWAY: 'hostaway',
  UPLISTING: 'uplisting',
  GUEST_SMILES: 'guestsmiles',
  NEXTPAX: 'nextpax',
  RENTAL_WISE: 'rentalwise',
  GUESTY: 'guesty',
  BOOSTLY: 'boostly',
  OTHER: 'other',
  AIRBNB: 'airbnb',
  HOSPITABLE: 'hospitable',
}

export const MIN_NIGHTS = 1
export const MAX_NIGHTS = 90

export const AMOUNT_TYPE_VALUE = {
  PERCENT: 'percent',
  FLAT: 'flat',
}
export const FEE_APPLIED_PER = ['reservation', 'person', 'night', 'person_per_night']
export const AMOUNT_TYPE = [AMOUNT_TYPE_VALUE.PERCENT, AMOUNT_TYPE_VALUE.FLAT]

export const AVAILABILITY_WINDOW_OPTIONS = [
  {
    value: '0',
    label: '0 day',
  },
  {
    value: '90',
    label: '3 months',
    // label: '3 months (90 days)',
  },
  {
    value: '180',
    label: '6 months',
  },
  {
    value: '270',
    label: '9 months',
  },
  {
    value: '365',
    label: '1 year',
  },
  {
    value: '730',
    label: '2 years',
  },
]

export const MAP_FEES_APPLIED_PER: any = {
  per_night: 'night',
  per_stay: 'reservation',
  night: 'night',
  reservation: 'reservation',
}
