import Api from './index'
import { ReservationAvailabilityData } from 'src/interfaces/reservations'

/**
 * Returns price breakdown.
 *
 * @param {object} bodyData The body data
 * @return {Promise}
 */

export const getPriceReservation = (bodyData: ReservationAvailabilityData) => {
  return Api({
    method: 'post',
    url: '/listing-service/calendar/price',
    data: bodyData,
  }).then((res) => res.data)
}

//https://api.dataismist.com/listing-service/calendar/host/price
export const getCalendarHostPrice = (bodyData: any) => {
  return Api({
    method: 'post',
    url: '/listing-service/v2/calendar/host/price',
    data: bodyData,
  }).then((res) => res.data)
}

export const createManualReservation = (bodyData: any) => {
  return Api({
    method: 'post',
    url: '/reservation-service/reservation/manual',
    data: bodyData,
  }).then((res) => res.data)
}

export const getSupportCurrencies = () => {
  return Api({
    method: 'get',
    url: '/paygate-service/currency/support',
  }).then((res) => res.data)
}

// https://api.dataismist.com/reservation-service/reservation/upload-img-addon
export const uploadAddOnImages = (bodyData: any) => {
  return Api({
    method: 'post',
    url: '/reservation-service/reservation/upload-img-addon',
    data: bodyData,
  }).then((res) => res.data)
}

export const getCancelPrice = (reservationId: string, walletId: string) => {
  return Api({
    method: 'get',
    url: `/reservation-service/reservation/calculate-refund/${reservationId}`,
    params: { walletId },
  }).then((res) => res.data)
}

// https://api.dataismist.com/reservation-service/reservation/cancel/46839315114860
export const cancelFiatBooking = (reservationId: string) => {
  return Api({
    method: 'post',
    url: `/reservation-service/reservation/cancel-fiat/${reservationId}`,
  }).then((res) => res.data)
}
export const cancelCryptoBooking = (reservationId: string, transactionHash?: string) => {
  let data: any = { reservationId }
  if (transactionHash) data = { ...data, transactionHash }
  return Api({
    method: 'post',
    url: `reservation-service/reservation/cancel-crypto-reservation`,
    data,
  }).then((res) => res.data)
}
export const getReservationConnectionStatus = () => {
  return Api({
    method: 'get',
    url: `/integration-service/v1/hospitable/connection-status`,
  }).then((res) => res.data)
}

// /reservation-service/promotion
export const createPromotion = (bodyData: any) => {
  return Api({
    method: 'post',
    url: '/reservation-service/promotion',
    data: bodyData,
  }).then((res) => res.data)
}

export const deletePromotionCode = (promoCodeId: number) => {
  return Api({
    method: 'post',
    url: '/reservation-service/promotion/update',
    data: {
      status: 'deleted',
      id: promoCodeId,
    },
  }).then((res) => res.data)
}

export const updateStatusPromotionCode = (promoCodeId: number, status: 'active' | 'inactive') => {
  return Api({
    method: 'post',
    url: '/reservation-service/promotion/update',
    data: {
      status,
      id: promoCodeId,
    },
  }).then((res) => res.data)
}

export const getPromotionCode = (params?: any) => {
  return Api({
    method: 'get',
    url: '/reservation-service/promotion',
    params: params ? { ...params } : {},
  }).then((res) => res.data)
}
export const getCustomerInfoFiatPayment = (reservationId: string | number) => {
  return Api({
    method: 'get',
    url: `paygate-service/stripe/host/customer-info/${reservationId}`,
  }).then((res) => res.data)
}
