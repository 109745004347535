import { checkStatusHospitable } from '../../api/integration'
import { ONBOARDING_STEP } from '../../contants/user'
import { useAppDispatch } from '../../redux/hooks'
import { setAirbnbChannelId, setIsOpenChooseListingHospitable } from '../../redux/slices/listing'
import { handleNextOnboardingStep } from '../../redux/slices/user'
import { handleErrorMessage } from '../../utils/common'
import { fetchIntegratedConfig } from '../../utils/property'
import CircularProgress from '@mui/material/CircularProgress'
import queryString from 'query-string'
import React, { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const HandleRedirectFromHospitable = () => {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const navigate = useNavigate()
  const intervalRef = useRef<any>()
  const intervalCount = useRef<number>(0)

  async function handleRedirectHospitable() {
    try {
      const params: any = queryString.parse(search)
      const from = params.from || '/'
      const connectionId = params.connectionId || ''
      if (connectionId) {
        const res = await checkStatusHospitable(
          connectionId,
          `/handle-connect-hospitable?from=${from}&connectionId=${connectionId}`
        )
        intervalCount.current++
        if (res.success && res.data) {
          if (res.data.isConnected && res.data.channelId) {
            clearInterval(intervalRef.current) // clear interval if isConnected
            fetchIntegratedConfig()
            dispatch(setAirbnbChannelId(res.data.channelId))
            dispatch(setIsOpenChooseListingHospitable(true))
            if (from === '/') {
              dispatch(handleNextOnboardingStep(ONBOARDING_STEP.AIRBNB_CHOOSE_LISTINGS))
            }
            handleNavigate(from)
          } else {
            if (intervalCount.current >= 5) {
              clearInterval(intervalRef.current)
              handleNavigate(from)
            }
          }
        }
      }
    } catch (err: any) {
      handleErrorMessage(err)
    }
  }

  const handleNavigate = (from?: string) => {
    if (from === 'settings') {
      navigate('/settings?tab=pms_and_channels')
    } else {
      navigate(`/${from}`)
    }
  }

  useEffect(() => {
    intervalRef.current = setInterval(handleRedirectHospitable, 3000)
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  return (
    <div className={'w-screen h-screen flex items-center justify-center'}>
      <CircularProgress size={50} style={{ color: '#0B2336' }} />
    </div>
  )
}

export default HandleRedirectFromHospitable
