import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import ic_building from 'src/assets/icons/ic_building.svg'
import ic_building_red from 'src/assets/icons/ic_building_red.svg'
import ic_calendar_check from 'src/assets/icons/ic_calendar_check.svg'
import ic_calendar_check_red from 'src/assets/icons/ic_calendar_check_red.svg'
import ic_home from 'src/assets/icons/ic_home.svg'
import ic_home_red from 'src/assets/icons/ic_home_red.svg'
import ic_menu_red from 'src/assets/icons/ic_menu_red.svg'
import ic_settings from 'src/assets/icons/ic_settings_32_32.svg'
import { IconHome, IconBuilding, IconCalendarChecked, IconSettings } from 'src/components/common/Icons'

interface Props {}

const menuMobile = [
  { path: '/', label: 'Home', icon: ic_home, iconActive: ic_home_red },
  { path: '/listings', label: 'Listings', icon: ic_building, iconActive: ic_building_red },
  { path: '/reservations', label: 'Reservations', icon: ic_calendar_check, iconActive: ic_calendar_check_red },
  { path: '/settings', label: 'Settings', icon: ic_settings, iconActive: ic_menu_red },
]

const MenuBarMobile: React.FC<Props> = () => {
  const location = useLocation()

  const renderIcon = (path: string, isActive?: boolean) => {
    if (path === '/') return <IconHome width={32} height={32} color={isActive ? '#A32A30' : '#6A6A68'} />
    if (path === '/listings') return <IconBuilding width={32} height={32} color={isActive ? '#A32A30' : '#6A6A68'} />
    if (path === '/reservations')
      return <IconCalendarChecked width={32} height={32} color={isActive ? '#A32A30' : '#6A6A68'} />
    if (path === '/settings') return <IconSettings width={32} height={32} color={isActive ? '#A32A30' : '#6A6A68'} />
  }

  if (location.pathname.includes('/native-listing')) return null

  return (
    <div
      className={'h-[72px] fixed bottom-0 left-0 z-[99] bg-white pt-4 w-full block lg:hidden'}
      style={{ boxShadow: '0px -1px 0px rgba(0, 0, 0, 0.08)' }}
    >
      <ul className={'w-full max-w-[400px] flex justify-center mx-auto'}>
        {menuMobile.map((item) => {
          const isActived =
            (location.pathname.includes('/listings/') && item.path === '/listings') ||
            (location.pathname.includes('/native-listing') && item.path === '/listings') ||
            (location.pathname.includes('/reservations/') && item.path === '/reservations') ||
            location.pathname === item.path

          return (
            <li key={item.path} className={`w-1/4`}>
              <Link className={'block'} to={item.path}>
                <div className="w-full flex flex-col items-center justify-center">
                  {renderIcon(item.path, isActived)}
                  <span className={`text-12-16 ${isActived ? 'text-red' : 'text-black-400'}`}> {item.label}</span>
                </div>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default MenuBarMobile
