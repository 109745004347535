import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getPerformanceInfo } from 'src/api/integration'
import BasicCardV2 from 'src/components/common/BasicCardV2'
import PerformanceInfo from 'src/components/common/PerformanceInfo'
import { convertCurrency } from 'src/utils/common'

const DetailPerformance = () => {
  const { propertyId } = useParams()
  const [performanceData, setPerformanceData] = useState<any>({})

  useEffect(() => {
    const fetchPerformance = async (id: string) => {
      try {
        const res = await getPerformanceInfo(id)
        setPerformanceData(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    if (propertyId) {
      fetchPerformance(propertyId)
    }
  }, [propertyId])

  return (
    <BasicCardV2 title={'Listing performance'}>
      <div className={'grid grid-cols-3 gap-3'}>
        <PerformanceInfo
          title={'Page Views'}
          percent={performanceData?.view || 0}
          // growPercent={'+0.0%'}
          styles={{ background: '#F9F0F1' }}
        />
        <PerformanceInfo
          title={'Completed Bookings'}
          percent={performanceData?.booking || 0}
          // growPercent={'+0.0%'}
          styles={{ background: '#F8F3EB' }}
        />
        <PerformanceInfo
          title={'Realized Earnings'}
          percent={`$${convertCurrency(
            Number(performanceData?.earn || 0)
              .toFixed(2)
              .toString()
          )}`}
          // growPercent={'+0.0%'}
          styles={{ background: '#EFF7F3' }}
        />
      </div>
    </BasicCardV2>
  )
}

export default DetailPerformance
