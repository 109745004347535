import { useAppSelector } from '../../../redux/hooks'
import { OPTIONS as PERSONA_OPTIONS } from './OnboardingPersona'
import clsx from 'clsx'
import React from 'react'
import { IconBuilding, IconEmail, IconEthereum } from 'src/components/common/Icons'
import { shorterAddress } from 'src/utils/common'

interface Props {
  // firstName?: string
  // lastName?: string
  // email?: string
  // walletId?: string
  // persona?: string
  // inventory?: number
}

const RightContentIdentity: React.FC<Props> = () => {
  const { onboardingData } = useAppSelector((state) => state.user)
  const { firstName, lastName, email, walletId, persona, inventory } = onboardingData || {}

  const convertPersonaTitle = () => {
    const option = PERSONA_OPTIONS.find((item) => item.value === persona)
    if (option) return option.title
    return ''
  }

  return (
    <div className={'rounded-[16px] min-w-[420px]'}>
      <div className={'p-[24px] bg-neutral-200  rounded-t-[16px] w-full'}>
        {firstName || lastName ? (
          <div className={'font-maison-neue-medium font-24-28 text-neutral-800'}>
            {(firstName || '') + ' ' + (lastName || '')}
          </div>
        ) : (
          <div className={'h-[28px] rounded-[12px] bg-white w-[216px]'}></div>
        )}

        <div
          className={clsx(
            'flex gap-[16px] items-center mt-[12px] font-maison-neue-demibold text-12-16 text-neutral-800 tracking-[0.48px]'
          )}
        >
          <span
            className={clsx('rounded-[12px] px-[12px] py-[6px] bg-white uppercase', '', {
              'w-[148px] h-[28px]': !persona,
              'w-auto': persona,
            })}
          >
            {convertPersonaTitle()}
          </span>
          {persona && (
            <span className={'flex items-center gap-[8px]'}>
              <IconBuilding color={'#292926'} />
              <span>{inventory ? inventory : '?'}</span>
            </span>
          )}
        </div>
      </div>
      <div className={'px-[24px] py-[32px] bg-white rounded-b-[16px]'}>
        <div className={'flex gap-[16px] items-center'}>
          <span className={'min-w-[14px]'}>
            <IconEmail />
          </span>
          {email ? <span>{email}</span> : <span className={'h-[24px] rounded-[32px] bg-neutral-200 w-[208px]'}></span>}
        </div>
        {walletId && (
          <div className={'flex gap-[16px] items-center mt-[16px]'}>
            <span className={'min-w-[14px]'}>
              <IconEthereum />
            </span>
            <span>{shorterAddress(walletId)}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default RightContentIdentity
