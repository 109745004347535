import { cancelFiatBooking } from '../../api/reservation'
import React, { useEffect, useState } from 'react'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import { PAYMENT_METHOD } from 'src/contants/common'
import useWallet from 'src/hooks/useWallet'
import { useAppDispatch } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { handleErrorMessage, isPolygon } from 'src/utils/common'
import { cancelCryptoBookingByHost, getCurrentAccount, switchToPolygonNetwork, switchToBaseNetwork } from 'src/utils/ether'

interface Props {
  isOpen: boolean
  onClose: () => void
  reservationId: string
  paymentMethod: string
  hostWallet: string
  guestWallet: string
  propertyContract: string
  reservationChainId: string
  handleChangeStatus: (callback?: () => void) => void
}

const CancelReservation: React.FC<Props> = ({
  isOpen,
  onClose,
  reservationId,
  paymentMethod,
  hostWallet,
  propertyContract,
  handleChangeStatus,
  reservationChainId,
}) => {
  const dispatch = useAppDispatch()
  const [curentWallet, setCurrentWallet] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { chainId, ethereum } = useWallet()

  useEffect(() => {
    if (ethereum)
      ethereum.on('accountsChanged', (accounts: string[]) => {
        setCurrentWallet(accounts.length > 0 ? accounts[0] : '')
      })
  }, [ethereum])

  useEffect(() => {
    async function fetchMetamaskAccount() {
      const account = await getCurrentAccount()
      setCurrentWallet(account)
    }
    if (isOpen) {
      fetchMetamaskAccount()
    }
  }, [isOpen])

  const handleCancelReservation = async () => {
    if (paymentMethod === PAYMENT_METHOD.FIAT) {
      await handleCancelFiatBooking()
    }
    if (paymentMethod === PAYMENT_METHOD.CRYPTO) {
      // await handleCancelCryptoBooking()
    }
  }
  // eslint-disable-next-line
  const handleCancelCryptoBooking = async () => {
    if (curentWallet.toLowerCase() !== hostWallet.toLowerCase()) {
      handleErrorMessage({ message: 'Please connect your wallet' })
      return
    }
    if (`${chainId}` !== `${reservationChainId}`) {
      if (isPolygon(reservationChainId)) await switchToPolygonNetwork()
      else await switchToBaseNetwork()
      return
    }
    try {
      setIsLoading(true)
      await cancelCryptoBookingByHost(reservationId, hostWallet, propertyContract)
      dispatch(setToastSuccess({ message: `Your reservation ${reservationId} is being cancelled.` }))
      setIsLoading(false)
      onClose() // close popup
      handleChangeStatus(() => {
        dispatch(setToastSuccess({ message: `Your reservation ${reservationId} has been cancelled.` }))
      })
    } catch (err: any) {
      handleErrorMessage(err)
      setIsLoading(false)
    }
  }

  const handleCancelFiatBooking = async () => {
    try {
      setIsLoading(true)
      await cancelFiatBooking(reservationId)
      handleChangeStatus(() => {
        dispatch(setToastSuccess({ message: `Your reservation ${reservationId} has been cancelled.` }))
        setIsLoading(false)
        onClose() // close popup
      })
    } catch (err: any) {
      if (err?.error?.code === 'PAYMENT_REFUND_FAILED') {
        dispatch(
          setToastSuccess({
            message: `Your reservation ${reservationId} has been cancelled but refund failed due to your insufficient balance.`,
          })
        )
        onClose() // close popup
      } else {
        handleErrorMessage(err)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <BasicDialog
      isOpen={isOpen}
      onClose={onClose}
      title={'Cancel this reservation'}
      extraTitle={
        paymentMethod === PAYMENT_METHOD.CRYPTO
          ? 'Please contact guest to manually cancel and refund the booking.'
          : 'Are you sure you want to cancel this reservation?'
      }
    >
      {paymentMethod === PAYMENT_METHOD.CRYPTO ? (
        <BasicButton
          variant={'contained'}
          color={'red'}
          isRadius100={true}
          clases={'w-full'}
          onClick={onClose}
          loading={isLoading}
        >
          Ok
        </BasicButton>
      ) : (
        <BasicButton
          variant={'contained'}
          color={'red'}
          isRadius100={true}
          clases={'w-full'}
          onClick={handleCancelReservation}
          loading={isLoading}
        >
          Confirm
        </BasicButton>
      )}
    </BasicDialog>
  )
}

export default CancelReservation
