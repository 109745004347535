import { uploadAddOnImages } from '../../api/reservation'
import { convertFileToBase64, getWidthHeightImage, handleErrorMessage } from '../../utils/common'
import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import clsx from 'clsx'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import CurrencyFormat, { Values } from 'react-currency-format'
import ic_media_20_20 from 'src/assets/icons/ic_media_20_20.svg'
import ic_media_32_32 from 'src/assets/icons/ic_media_32_32.svg'
import ic_trash_empty from 'src/assets/icons/ic_trash_empty.svg'
import Error from 'src/components/common/Error'
import BasicDialog from 'src/components/ui/BasicDialog'
import { ADDON_NAMES_SUGGESTION, ADDON_PRICE_STRUCTURE } from 'src/contants/common'
import { FormError } from 'src/interfaces'
import { AddOn } from 'src/interfaces/reservations'

interface Props {
  editIndex?: number
  editData?: AddOn | null
  currency: string
  isOpen: boolean
  onClose: () => void
  onConfirm: (data: AddOn, index?: number) => void
  addOns: AddOn[]
}

const MAX_NUMBER_OF_IMAGE = 5

const AddNewAddOn: React.FC<Props> = ({ isOpen, onClose, currency, onConfirm, editIndex, editData, addOns }) => {
  const inputPhotos = useRef<HTMLInputElement | null>(null)
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [price, setPrice] = useState<string>()
  const [fileList, setFileList] = useState<{ file?: File; dataUrl?: string; slug?: string }[]>([])
  const [error, setError] = useState<{ name: FormError; price: FormError; images: FormError }>({
    name: { show: false, message: '' },
    price: { show: false, message: '' },
    images: { show: false, message: '' },
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [hoverIndex, setHoverIndex] = useState<number>(-1)
  const [addOnOptions, setAddOnOptions] = useState<{ label: string }[]>([])

  useEffect(() => {
    setAddOnOptions(ADDON_NAMES_SUGGESTION.map((name) => ({ label: name })))
  }, [])

  useEffect(() => {
    if (editData) {
      setName(editData.name)
      setDescription(editData.description)
      setPrice(String(Math.abs(Number(editData.price))))
      setFileList(editData.images.map((img) => ({ slug: img.slug })))
    } else {
      clearData()
    }
  }, [editData, isOpen])

  const clearData = () => {
    setName('')
    setDescription('')
    setPrice('')
    setFileList([])
  }

  const onChangeName = (value: string) => {
    setName(value)
    setError((prevState) => ({ ...prevState, name: { show: false, message: '' } }))
  }

  const onChangeDescription = (value: string) => {
    setDescription(value)
  }

  const onChangePrice = (values: Values) => {
    setPrice(values.value)
    setError((prevState) => ({ ...prevState, price: { show: false, message: '' } }))
  }

  const validateImages = async (files: File[]) => {
    let isValid: boolean = true
    if (files.length > MAX_NUMBER_OF_IMAGE) {
      setError((prevState) => ({
        ...prevState,
        images: {
          show: true,
          message: 'Photos must be less than 5',
        },
      }))
      return false
    }
    for (let file of files) {
      const sizeInMegaBytes = file.size / (1000 * 1000)
      if (sizeInMegaBytes > 5) {
        isValid = false
        setError((prevState) => ({
          ...prevState,
          images: {
            show: true,
            message: `Photos (${file.name}) cannot exceed 5 MB`,
          },
        }))
        break
      }
      const obj: any = await getWidthHeightImage(file)
      if (obj.width > 2480 || obj.height > 2480) {
        isValid = false
        setError((prevState) => ({
          ...prevState,
          images: {
            show: true,
            message: `Photos (${file.name}) cannot bigger than 2480x2480`,
          },
        }))
        break
      }
    }
    if (isValid) {
      setError((prevState) => ({
        ...prevState,
        images: {
          show: false,
          message: '',
        },
      }))
    }
    return isValid
  }

  const onInputFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      try {
        const files = Array.from(event.target.files)
        const isValid = await validateImages(files)
        if (!isValid) return
        setLoading(true)
        const convertedBase64: any[] = await Promise.all(files.map((item) => convertFileToBase64(item)))
        const newFiles: any = files.map((item, index) => {
          return {
            file: item,
            dataUrl: convertedBase64[index],
          }
        })
        setFileList((prevState) => [...prevState, ...newFiles])
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
  }

  const onHoverImage = (index: number) => {
    setHoverIndex(index)
  }

  const onDeleteImage = (ind: number) => {
    setFileList((prevState) => {
      return prevState.filter((_, _index) => _index !== ind)
    })
  }

  const handleUploadImages = async () => {
    const imgsNeedUpload = fileList.filter((item) => item.dataUrl)
    const imgsOld = fileList.filter((item) => item.slug)
    if (Array.isArray(imgsNeedUpload) && imgsNeedUpload.length > 0) {
      setLoading(true)
      const formData = new FormData()
      for (let item of fileList) {
        if (item.file) {
          formData.append('files', item.file)
        }
      }
      const res = await uploadAddOnImages(formData)
      const imgsUploaded = Array.isArray(res.data) ? res.data.map((fullUrl: string) => ({ slug: fullUrl })) : []
      setLoading(false)
      return [...imgsOld, ...imgsUploaded]
    } else {
      return [...imgsOld]
    }
  }

  const handleClose = () => {
    onClose()
    // clear error
    setError({
      name: { show: false, message: '' },
      price: { show: false, message: '' },
      images: { show: false, message: '' },
    })
  }

  const handleConfirm = async () => {
    // Validate name:
    const nameExisted = addOns.filter((_, index) => index !== editIndex).map((item) => item.name.trim())
    if (name && nameExisted.includes(name.trim())) {
      setError((prevState) => ({ ...prevState, name: { show: true, message: 'This add-on name already exists.' } }))
      return
    }
    // Validate amount:
    // For Flat amount type, the value range 0 < x < unlimited (security/tech consult), 2 decimals supported
    // For Percentage type, the value range 0 < x <= 100%
    if (price && Number(price) <= 0) {
      setError((prevState) => ({ ...prevState, price: { show: true, message: 'Price must be greater than 0.' } }))
      return
    }
    const amountSplit = String(price).split('.')
    if (amountSplit[1] && amountSplit[1].length > 2) {
      setError((prevState) => ({ ...prevState, price: { show: true, message: 'Only 2 decimal digits are allowed.' } }))
      return
    }
    try {
      const images = await handleUploadImages()
      if (typeof onConfirm === 'function') {
        onConfirm(
          {
            name: name.trim(),
            price: Number(price) || 0,
            description,
            images: images,
            isIncludedInTotalPrice: editData ? editData.isIncludedInTotalPrice : true,
            applyPer: ADDON_PRICE_STRUCTURE.PER_RESERVATION,
          },
          editIndex
        )
      }
      handleClose()
    } catch (err: any) {
      setLoading(false)
      handleErrorMessage(err)
    }
  }

  const isDisabledConfirm = () => {
    return !name || !description || !price || fileList.length > 5
  }

  const imgLength = fileList.length

  return (
    <BasicDialog
      isOpen={isOpen}
      onClose={handleClose}
      title={<p className={'font-maison-neue text-24-28 text-grayscale-800 text-center'}>New Add-on</p>}
    >
      <div className={'flex flex-col gap-[24px]'}>
        <div>
          <label
            htmlFor={'addon_name'}
            className={'inline-block mb-[8px] font-maison-neue-medium text-14-18 text-neutral-800'}
          >
            Name
          </label>
          <Autocomplete
            disablePortal
            // open={true}
            id="combo-box-demo"
            options={addOnOptions}
            fullWidth
            clearIcon={null}
            value={{ label: name }}
            onChange={(event, value) => {
              onChangeName(value ? value.label : '')
            }}
            PaperComponent={({ children }) => (
              <Paper
                style={{
                  background: '#FFFFFF',
                  padding: 0,
                  marginTop: '8px',
                  borderRadius: '16px',
                  boxShadow: '0px 16px 48px rgba(0, 0, 0, 0.16)',
                }}
              >
                {children}
              </Paper>
            )}
            sx={{
              '& + .MuiAutocomplete-popper .MuiAutocomplete-listbox': {
                padding: '0 24px',
              },
              '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
                padding: 0,
                height: '64px',
                display: 'flex',
                alignItems: 'center',
                boxShadow: '0px 1px 0px #E5E5E0',
                fontFamily: 'Maison Neue Regular',
                fontSize: '16px',
                lineHeight: '20px',
                color: '#0F0F0E', // neutral 900
              },
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  value={name}
                  onChange={(event) => onChangeName(event.target.value)}
                  sx={{
                    '& .MuiInputBase-root': {
                      borderRadius: '12px',
                      height: '48px',
                      fontSize: '16px',
                      lineHeight: '20px',
                      padding: '0px 16px',
                      color: '#0F0F0E',
                      borderWidth: '1px',
                      borderColor: error.name.show ? '#A32A30' : 'rgba(0, 0, 0, 0.05)',
                      display: 'flex',
                      alignItems: 'center',
                      outline: 'none',
                      outlineOffset: '2px',
                    },
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: error.name.show ? '1px solid #A32A30' : '1px solid #0F0F0E',
                    },
                  }}
                />
              )
            }}
          />

          {error.name && <Error {...error.name} classes={'text-14-20'} />}
        </div>

        <div className={'relative'}>
          <label
            htmlFor={'addon_price'}
            className={'inline-block mb-[8px] font-maison-neue-medium text-14-18 text-neutral-800'}
          >
            Price
          </label>
          <CurrencyFormat
            className={clsx(
              { 'pl-[60px]': currency !== 'USD' },
              { 'pl-[32px]': currency === 'USD' },
              'w-full h-[48px] text-16-20 px-[16px] rounded-[12px] text-neutral-900',
              'placeholder:text-neutral-500',
              'focus:border-neutral-900 focus:outline-none',
              'inputNumberHideArrow',
              {
                ['border border-red-6 hover:border-red-6 focus:border-red-6']: error.price.show, //error?.show,
                ['border border-black-0.05 hover:border-neutral-900']: !error.price.show,
              }
            )}
            placeholder={'Add your price here'}
            thousandSeparator={true}
            inputMode="numeric"
            value={price}
            onValueChange={(values) => onChangePrice(values)}
          />
          <span className={clsx('absolute left-[16px] top-[40px] font-maison-neue text-16-20 text-neutral-900')}>
            {currency === 'USD' ? '$' : currency}
          </span>

          {error.price && <Error {...error.price} classes={'text-14-20'} />}
        </div>

        <div>
          <label
            htmlFor={'addon_description'}
            className={'inline-block mb-[8px] font-maison-neue-medium text-14-18 text-neutral-800'}
          >
            Description
          </label>
          <textarea
            id={'addon_description'}
            placeholder={'Enter description...'}
            className={clsx(
              'w-full text-16-24 px-4 py-3 rounded-xl resize-none ',
              'placeholder:text-neutral-500',
              'border border-black-0.05 hover:border-neutral-900',
              'focus:border-neutral-900 focus:outline-none'
            )}
            rows={4}
            maxLength={100}
            value={description}
            onChange={(event) => onChangeDescription(event.target.value)}
          />
        </div>

        <div>
          <p className={'mb-[8px] font-maison-neue-medium text-14-18 text-neutral-800 '}>Photos (optional)</p>
          <div className={'flex flex-wrap gap-[12px]'}>
            {fileList.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`min-w-[48px] rounded-[8px] relative ${index >= 5 ? 'border border-red-6' : ''}`}
                  onFocus={() => onHoverImage(index)}
                  onMouseOver={() => onHoverImage(index)}
                  onMouseLeave={() => onHoverImage(-1)}
                >
                  <img
                    src={item.dataUrl || item.slug}
                    alt={item.file ? item.file.name : ''}
                    className={'rounded-[8px] object-cover'}
                    style={{ width: '48px', height: '48px' }}
                  />
                  <div
                    className={
                      'flex items-center justify-center bg-[#000000] absolute opacity-60 absolute top-0 w-[48px] h-[48px] rounded-[8px] ' +
                      `${hoverIndex === index ? '' : 'hidden'}`
                    }
                  >
                    <IconButton size={'small'} onClick={() => onDeleteImage(index)}>
                      <img src={ic_trash_empty} alt={'ic_trash_empty'} />
                    </IconButton>
                  </div>
                </div>
              )
            })}
            {imgLength < 5 && (
              <button
                className={clsx(
                  'flex justify-center items-center border border-dashed border-neutral-800 ',
                  {
                    ['w-[48px] h-[48px] rounded-[8px]']: imgLength > 0,
                  },
                  {
                    ['w-full h-[112px] rounded-[12px]']: imgLength === 0,
                  }
                )}
                onClick={() => {
                  const inputEle = inputPhotos.current as HTMLInputElement
                  inputEle.click()
                }}
              >
                <img src={imgLength === 0 ? ic_media_32_32 : ic_media_20_20} alt={'ic_media'} className={''} />
              </button>
            )}
          </div>
          <input
            ref={inputPhotos}
            type={'file'}
            multiple={true}
            accept={'image/*'}
            className={'opacity-0 w-full h-full z-50 hidden'}
            onChange={onInputFileChange}
            onClick={() => {
              const element = inputPhotos.current as HTMLInputElement
              if (element) element.value = ''
            }}
          />

          {error.images && <Error show={error.images.show} message={error.images.message} classes={'text-14-20'} />}
          {fileList.length > MAX_NUMBER_OF_IMAGE && (
            <Error show={true} message={'Photos must be less than 5.'} classes={'text-14-20'} />
          )}
        </div>

        <div>
          <button
            className={clsx(
              'w-full flex justify-center items-center h-[48px]',
              'bg-red-700 rounded-[100px]',
              'font-maison-neue-medium text-16-16 text-white',
              'disabled:opacity-50 disabled:cursor-not-allowed'
            )}
            disabled={isDisabledConfirm()}
            style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)' }}
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
      </div>

      {loading && (
        <div
          className={
            'absolute w-full h-full top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center opacity-30 bg-neutral-200 cursor-not-allow'
          }
        >
          <CircularProgress size={50} style={{ color: '#0B2336' }} />
        </div>
      )}
    </BasicDialog>
  )
}

export default AddNewAddOn
