import ic_copy from '../../assets/icons/ic_copy_2.svg'
import { setToastSuccess, setTriggerTime } from '../../redux/slices/common'
import BasicButton from '../ui/BasicButton'
import BasicDialog from '../ui/BasicDialog'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { verifyCustomUrl } from 'src/api/user'
import ic_warning_circle_red from 'src/assets/icons/ic_warning_circle_red.svg'
import { handleErrorMessage, hasSubdomain, isEmpty } from 'src/utils/common'

interface Props {
  selfHostedDomain: string
  disabled: boolean
  handleSuccess: any
  handleTriggerOtherPopup?: any
}
const TYPE_POPUP = {
  MANUALLY: 'manually',
  AUTO: 'auto',
}

const CustomUrlSelfHosted: React.FC<Props> = ({
  selfHostedDomain,
  disabled,
  handleSuccess,
  handleTriggerOtherPopup,
}) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [errorApi, setErrorApi] = useState<string>('')
  const [typePopup, setTypePopup] = useState<string>('')
  const [ips, setIps] = useState<any>({ domainIp: '', dtravelIp: '', cName: '' })
  const isSubdomain = hasSubdomain(selfHostedDomain)

  useEffect(() => {
    if (handleTriggerOtherPopup) handleTriggerOtherPopup(open)
  }, [open])
  const handleVerify = async (type: string) => {
    try {
      await verifyCustomUrl(selfHostedDomain)
      dispatch(setToastSuccess({ message: 'This domain is successfully connected!' }))
      dispatch(setTriggerTime(+new Date())) // trigger to fetch profile again (function fetch profile in Settings.tsx)
      handleSuccess()
    } catch (error: any) {
      handleOpenPopup(type)
      if (error?.code !== 'EDNS1001') {
        handleErrorMessage(error)
      }
      setIps({ domainIp: error.currentIp, dtravelIp: error.dtravelIp, cName: error.dtravelCName })
    }
  }

  const clearError = () => {
    setErrorApi('')
  }

  const handleOpenPopup = (type: string) => {
    setOpen(true)
    setTypePopup(type)
  }

  const handleClosePopup = () => {
    setOpen(false)
    setTypePopup('')
    clearError()
  }

  const onSubmit = async () => {
    try {
      setSubmitLoading(true)
      await verifyCustomUrl(selfHostedDomain)
      dispatch(setToastSuccess({ message: 'This domain is successfully connected!' }))
      dispatch(setTriggerTime(+new Date())) // trigger to fetch profile again (function fetch profile in Settings.tsx)
      handleClosePopup()
      handleSuccess()
    } catch (error: any) {
      handleErrorMessage(error)
      setIps({ domainIp: error.currentIp, dtravelIp: error.dtravelIp, cName: error.dtravelCName })
    } finally {
      setSubmitLoading(false)
    }
  }
  const onCopy = (text: string) => {
    navigator.clipboard.writeText(text)
    dispatch(setToastSuccess({ message: `Copied!`, duration: 2000 }))
  }
  const isAuto = typePopup === TYPE_POPUP.AUTO

  return (
    <>
      <BasicButton
        variant={'outlined'}
        size={'md'}
        clases={'w-full md:w-1/2 whitespace-nowrap h-[48px] rounded-[100px_!important]'}
        onClick={() => handleVerify(TYPE_POPUP.MANUALLY)}
        disabled={disabled}
        id="connect_manually_btn"
      >
        {`Connect manually`}
        <div
          className="none"
          id="connect_auto_btn"
          role="presentation"
          onClick={(e: any) => {
            handleVerify(TYPE_POPUP.AUTO)
            e.stopPropagation()
          }}
        />
      </BasicButton>

      <BasicDialog
        isOpen={Boolean(open)}
        onClose={handleClosePopup}
        title={`Connect URL ${isAuto ? 'Automatically' : 'Manually'}`}
        extraTitle={
          isAuto ? (
            'It could take up to 01 hour for your domain to connect. Select Verify connection to know if your domain is successfully connected.'
          ) : (
            <p className={'font-maison-neue text-16-24 text-neutral-700 text-center mt-[8px] whitespace-pre-line'}>
              Log into your domain provider, then copy and paste the values as below to your DNS settings. For more
              details, please check our instruction&nbsp;
              <a
                href="https://dtravel.com/help/setting-a-self-hosted-domain-name"
                target="_blank"
                rel="noreferrer"
                className="text-ocean-700"
              >
                here
              </a>
              .
            </p>
          )
        }
      >
        {isAuto ? (
          <>
            <div className={`flex items-start rounded-[12px] p-[16px] bg-red-100 mt-[-8px]`}>
              <img src={ic_warning_circle_red} alt={'ic_warning_circle_red'} />
              <div className={'flex flex-col pl-2'}>
                <p className="text-red-700 text-14-20 mb-[2px] font-maison-neue-medium">Warning</p>
                <p className="text-neutral-700 text-12-16 font-maison-neue">
                  Please DO NOT close this popup or reload the page until the domain is successfully connected.
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              {[
                // { name: 'Domain IP', value: ips.domainIp },
                { name: 'Dtravel IP', value: ips.dtravelIp },
                { name: 'CNAME', value: ips.cName },
              ].map((el: any, idx: number) => {
                if (isSubdomain && el.name === 'Dtravel IP') return null
                return (
                  <React.Fragment key={idx}>
                    <p className={'font-maison-neue-medium text-16-24 text-neutral-8 mb-[8px] mt-[24px]'}>{el.name}</p>
                    <div
                      className={
                        'flex items-center justify-between w-full mb-[8px] border border-neutral-3 bg-neutral-1 rounded-[12px] px-[16px] py-[12px] font-maison-neue-medium text-16-24 text-neutral-600'
                      }
                    >
                      {el.value}
                      {!isEmpty(el.value) && (
                        <div
                          className="cursor-pointer min-w-[24px] ml-[8px]"
                          onClick={() => onCopy(el.value)}
                          role="presentation"
                        >
                          <img src={ic_copy} alt={'ic_external_link'} className={'w-[24px] h-[24px] min-w-[24px]'} />
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                )
              })}

              {errorApi && (
                <div className={'p-4 bg-red-1 rounded-[12px] flex items-center gap-[8px] mt-[8px]'}>
                  <span>
                    <ErrorOutlineOutlinedIcon sx={{ color: '#A32A30' }} />
                  </span>
                  <p className={'w-full break-all font-maison-neue-medium text-16-20 text-red-6'}>{errorApi}</p>
                </div>
              )}
            </div>
            <p className="text-center font-maison-neue text-neutral-700 text-16-24 mt-[24px]">
              {`It could take up to 24 hours for your domain to connect. Select Verify connection to know if your domain is successfully connected.`}
            </p>
          </>
        )}

        <div className={'mt-[24px]'}>
          <BasicButton
            variant={'contained'}
            size={'xl'}
            clases={'w-full h-[48px] rounded-[100px_!important]'}
            color="red"
            loading={submitLoading}
            onClick={onSubmit}
          >
            Verify connection
          </BasicButton>
        </div>
      </BasicDialog>
    </>
  )
}

export default CustomUrlSelfHosted
