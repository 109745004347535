import IconButton from '@mui/material/IconButton'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { DayPickerRangeController, FocusedInputShape, isInclusivelyAfterDay, ModifiersShape } from 'react-dates'
import { useDispatch } from 'react-redux'
import ic_arrow_back from 'src/assets/icons/ic_arrow_back.svg'
import ic_arrow_forward from 'src/assets/icons/ic_arrow_forward.svg'
import BasicButton from 'src/components/ui/BasicButton'
import BasicTooltip from 'src/components/ui/BasicTooltip'
import { DATE_FORMAT, END_DATE, START_DATE } from 'src/contants/common'
import { useAppSelector } from 'src/redux/hooks'
import {
  setCheckIn,
  setCheckOut,
  setDatePickerFocusedId,
  setIsOpenDatePicker,
  setMaximumStay,
  setMinimumStay,
} from 'src/redux/slices/common'
import { getNearestInValidDate, loadCalendarNextAndPrev } from 'src/utils/common'

interface Props {
  isMobile?: boolean
  startDate: Moment | null
  endDate: Moment | null
  hostId?: string
  listingId?: string
  // eslint-disable-next-line no-unused-vars
  afterDatesChange?: (_startDate: string | null, _endDate: string | null) => void
  isDisabledAllDate?: boolean
  isKeepPopupAfterDatesChange?: boolean
  isNativeListing?: boolean
}

moment.locale('en')

const BasicDateTimePicker: React.FC<Props> = ({
  isMobile,
  startDate,
  endDate,
  hostId,
  listingId,
  afterDatesChange,
  isDisabledAllDate,
  isKeepPopupAfterDatesChange,
  isNativeListing,
}) => {
  const dispatch = useDispatch()
  const [currentMonthOnCalendar, setCurrentMonthOnCalendar] = useState<Moment>(
    moment(startDate || moment()).startOf('month')
  )
  const [listMonth, setListMonth] = useState<string[]>([
    moment(startDate || moment())
      .startOf('month')
      .format(DATE_FORMAT),
    moment(startDate || moment())
      .add(1, 'month')
      .startOf('month')
      .format(DATE_FORMAT),
  ])
  const [nearestInValidDate, setNearestInValidDate] = useState<string | null>(null)
  const { datePickerFocusedId, calendarDatesMap, disabledDatesMap, minimumStay, maximumStay } = useAppSelector(
    (state) => state.common
  )
  const [showTooltip, setShowTooltip] = useState<any>({})

  useEffect(() => {
    // remove tooltip when user click clear dates
    if (!startDate && !endDate) {
      setShowTooltip({})
    }
  }, [startDate, endDate])

  const onFocusChange = (focusedInput: FocusedInputShape | null) => {
    if (focusedInput) {
      dispatch(setDatePickerFocusedId(focusedInput))
    } else {
      if (isMobile || isKeepPopupAfterDatesChange) {
        dispatch(setDatePickerFocusedId(START_DATE))
      } else {
        dispatch(setDatePickerFocusedId(null))
        dispatch(setIsOpenDatePicker(false))
      }
    }
  }

  const onDatesChange = ({ startDate, endDate }: { startDate: Moment | null; endDate: Moment | null }) => {
    // new logic 2022-07-19: get minimumStay and maximumStay from calendar by checkin date
    if (startDate) {
      const keyMonth = startDate.format('YYYY-MM')
      const datesInMonth = calendarDatesMap.get(keyMonth)
      const dataStartDate =
        Array.isArray(datesInMonth) && datesInMonth.find((item) => item.date === startDate.format(DATE_FORMAT))
      if (dataStartDate) {
        dispatch(setMinimumStay(dataStartDate.minimumStay))
        dispatch(setMaximumStay(dataStartDate.maximumStay))
      }
      setNearestInValidDate(getNearestInValidDate(startDate, calendarDatesMap))
    }
    if (startDate && endDate) {
      const diff = endDate.diff(startDate, 'days')
      if (isNativeListing) {
        let isMinNightValid = true
        let minNightValid = 1
        let isMaxNightValid = true
        // check min night
        for (let i = 0; i < diff; i++) {
          const date = moment(startDate).add(i, 'days')
          const keyMonth = date.format('YYYY-MM')
          const dataMonth = calendarDatesMap.get(keyMonth)
          const dataDate = Array.isArray(dataMonth)
            ? dataMonth.find((item: any) => item.date === moment(date).format(DATE_FORMAT))
            : null
          if (dataDate) {
            const minNightThrought = dataDate.minimumStayThrough
            if (minNightValid < minNightThrought) minNightValid = minNightThrought
            if (diff < minNightValid) {
              isMinNightValid = false
            }
          }
        }
        // check max night
        const keyMonth = startDate.format('YYYY-MM')
        const dataMonth = calendarDatesMap.get(keyMonth)
        const dataDate = Array.isArray(dataMonth)
          ? dataMonth.find((item: any) => item.date === moment(startDate).format(DATE_FORMAT))
          : null
        const maxNights = dataDate ? dataDate.maximumStay : 90
        if (diff > maxNights) {
          isMaxNightValid = false
        }
        if (isMinNightValid && isMaxNightValid) {
          dispatch(setCheckIn(startDate.format(DATE_FORMAT)))
          dispatch(setCheckOut(endDate.format(DATE_FORMAT)))
        } else {
          dispatch(setCheckIn(startDate.format(DATE_FORMAT)))
          dispatch(setCheckOut(null))
          dispatch(setDatePickerFocusedId(END_DATE))
        }
      } else {
        const isLessThanMinNight = 0 < diff && diff < minimumStay
        const isGreaterMaxNight = 0 < diff && maximumStay < diff
        if (startDate.format(DATE_FORMAT) === endDate.format(DATE_FORMAT) || isLessThanMinNight || isGreaterMaxNight) {
          dispatch(setCheckIn(startDate.format(DATE_FORMAT)))
          dispatch(setCheckOut(null))
          dispatch(setDatePickerFocusedId(END_DATE))
        } else {
          dispatch(setCheckIn(startDate.format(DATE_FORMAT)))
          dispatch(setCheckOut(endDate.format(DATE_FORMAT)))
        }
      }
    } else {
      dispatch(setCheckIn(startDate ? startDate.format(DATE_FORMAT) : null))
      dispatch(setCheckOut(endDate ? endDate.format(DATE_FORMAT) : null))
    }

    if (typeof afterDatesChange === 'function') {
      afterDatesChange(startDate ? startDate.format(DATE_FORMAT) : null, endDate ? endDate.format(DATE_FORMAT) : null)
    }
  }

  const onNextPrevMonthClick = (type: 'next' | 'prev') => async (newMonth: moment.Moment) => {
    setCurrentMonthOnCalendar(newMonth.startOf('month'))
    if (hostId && listingId) {
      await loadCalendarNextAndPrev({
        currentDate: newMonth,
        type,
        currentCalendardMap: calendarDatesMap,
        currentDisabledMap: disabledDatesMap,
        hostId,
        listingId,
      })
    }
  }

  const onNextPrevMonthMobileClick = async (type: 'next' | 'prev') => {
    const firstItem = moment(listMonth[0], DATE_FORMAT)
    const lastItem = moment(listMonth[listMonth.length - 1], DATE_FORMAT)
    const newList =
      type === 'prev'
        ? [
            moment(firstItem).add(-2, 'month').format(DATE_FORMAT),
            moment(firstItem).add(-1, 'month').format(DATE_FORMAT),
            ...listMonth,
          ]
        : [
            ...listMonth,
            moment(lastItem).add(1, 'month').format(DATE_FORMAT),
            moment(lastItem).add(2, 'month').format(DATE_FORMAT),
          ]
    setListMonth(newList)
    if (hostId && listingId) {
      await loadCalendarNextAndPrev({
        currentDate: type === 'prev' ? firstItem : lastItem,
        type,
        currentCalendardMap: calendarDatesMap,
        currentDisabledMap: disabledDatesMap,
        hostId,
        listingId,
      })
    }
  }

  const isDayBlocked = (day: Moment) => {
    if (isDisabledAllDate) {
      return true
    }
    if (day.startOf('day').isBefore(moment().startOf('day'))) {
      return true
    }
    if (datePickerFocusedId === END_DATE && day.startOf('day').isSameOrBefore(startDate)) {
      return true
    }
    if (
      datePickerFocusedId === END_DATE &&
      nearestInValidDate &&
      day.startOf('day').isSameOrAfter(moment(nearestInValidDate, DATE_FORMAT).startOf('day'))
    ) {
      return true
    }
    const key = moment(day).format('YYYY-MM')
    const disabledList = disabledDatesMap.get(key)
    const dataMonth = calendarDatesMap.get(key)
    // Update 30-05-24: /DTRAV-4193
    // Calendar dates available even when there is no data for these dates
    const dayInMonth = Array.isArray(dataMonth)
      ? dataMonth.find((item) => item.date === moment(day).format('YYYY-MM-DD'))
      : null
    return !dayInMonth || (disabledList && disabledList.has(moment(day).format(DATE_FORMAT)))
  }

  const renderDayClass = (day: Moment, modifiers: Set<string>) => {
    if (
      (modifiers.has('blocked-out-of-range') || modifiers.has('blocked-calendar')) &&
      !modifiers.has('selected-start') &&
      !modifiers.has('selected-end')
    ) {
      return 'line-through'
    }
    if (modifiers.has('selected-start') || modifiers.has('selected-end')) {
      return 'rounded-full w-full h-full flex items-center justify-center bg-sand-8 text-white'
    }
    if (modifiers.has('hovered')) {
      return 'rounded-full w-full h-full flex items-center justify-center bg-white border-2 border-solid border-sand-8 text-neutral-700'
    }
    return 'text-neutral-700'
  }

  const renderDayInMinMaxNight = (day: moment.Moment, modifiers: ModifiersShape, title: string) => {
    if (isMobile) {
      const dateFormated = day.format(DATE_FORMAT)
      return (
        <BasicTooltip title={title} placement={'top'} open={!!showTooltip[dateFormated]} arrow>
          <div
            role={'button'}
            tabIndex={0}
            onKeyUp={(event) => {
              event.stopPropagation()
              setShowTooltip(() => ({ [dateFormated]: true }))
            }}
            onClick={(event) => {
              event.stopPropagation()
              setShowTooltip(() => ({ [dateFormated]: true }))
            }}
            className={
              renderDayClass(day, modifiers) +
              ' ' +
              'cursor-not-allowed opacity-60  w-full h-full flex items-center justify-center'
            }
          >
            {day.format('D')}
          </div>
        </BasicTooltip>
      )
    }
    return (
      <BasicTooltip title={title} placement={'top'}>
        <div
          role={'button'}
          tabIndex={0}
          onKeyUp={(event) => event.stopPropagation()}
          onClick={(event) => event.stopPropagation()}
          className={
            renderDayClass(day, modifiers) +
            ' ' +
            'cursor-not-allowed opacity-60  w-full h-full flex items-center justify-center'
          }
        >
          {day.format('D')}
        </div>
      </BasicTooltip>
    )
  }

  const renderDayInMinNightThrought = (day: moment.Moment, modifiers: ModifiersShape) => {
    let isMinNightValid = true
    let isMaxNightValid = true
    let minNightValid = 1
    let maxNightValid = 90
    if (startDate && day.isAfter(startDate)) {
      // check min night
      const nights = day.diff(startDate, 'days')
      for (let i = 0; i < nights; i++) {
        const date = moment(startDate).add(i, 'days')
        const keyMonth = date.format('YYYY-MM')
        const dataMonth = calendarDatesMap.get(keyMonth)
        const dataDate = Array.isArray(dataMonth)
          ? dataMonth.find((item: any) => item.date === moment(date).format(DATE_FORMAT))
          : null
        if (dataDate) {
          const minNightThrought = dataDate.minimumStayThrough
          if (minNightValid < minNightThrought) minNightValid = minNightThrought
          if (nights < minNightValid) {
            isMinNightValid = false
          }
        }
      }
      // check max night
      const keyMonth = day.format('YYYY-MM')
      const dataMonth = calendarDatesMap.get(keyMonth)
      const dataDate = Array.isArray(dataMonth)
        ? dataMonth.find((item: any) => item.date === moment(day).format(DATE_FORMAT))
        : null
      const maxNights = dataDate ? dataDate.maximumStay : 90
      if (nights > maxNights) {
        isMaxNightValid = false
        maxNightValid = maxNights
      }
    }

    if (isMinNightValid && isMaxNightValid) {
      return (
        <div
          className={
            'w-full h-full flex items-center justify-center font-inter-500 ' + ' ' + renderDayClass(day, modifiers)
          }
        >
          {day.format('D')}
        </div>
      )
    }
    const title = isMaxNightValid ? `${minNightValid}-night minimum` : `${maxNightValid}-night maximum`
    return (
      <BasicTooltip title={title} placement={'top'}>
        <div
          onClick={(event) => event.stopPropagation()}
          className={
            renderDayClass(day, modifiers) +
            ' ' +
            'cursor-not-allowed opacity-60  w-full h-full flex items-center justify-center'
          }
          role={'presentation'}
        >
          {day.format('D')}
        </div>
      </BasicTooltip>
    )
  }

  const renderDayCheckInCheckOutOnly = (day: Moment, modifiers: ModifiersShape) => {
    const isSelected = modifiers.has('selected-start') || modifiers.has('selected-end')
    if (isMobile) {
      const dateFormated = day.format(DATE_FORMAT)
      return (
        <BasicTooltip title={'Check-out Only'} placement={'top'} open={!!showTooltip[dateFormated]} arrow>
          <div
            role={'button'}
            tabIndex={0}
            onKeyUp={(event) => {
              event.stopPropagation()
              setShowTooltip(() => ({ [dateFormated]: true }))
              setTimeout(() => {
                setShowTooltip(() => ({ [dateFormated]: false }))
              }, 3000)
            }}
            onClick={(event) => {
              event.stopPropagation()
              setShowTooltip(() => ({ [dateFormated]: true }))
              setTimeout(() => {
                setShowTooltip(() => ({ [dateFormated]: false }))
              }, 3000)
            }}
            className={
              renderDayClass(day, modifiers) +
              `${isSelected ? '' : ' cursor-not-allowed opacity-60'}` +
              '  w-full h-full flex items-center justify-center'
            }
          >
            {day.format('D')}
          </div>
        </BasicTooltip>
      )
    }
    return (
      <BasicTooltip title={'Check-out Only'} placement={'top'}>
        <div
          role={'button'}
          tabIndex={0}
          onKeyUp={(event) => event.stopPropagation()}
          onClick={(event) => event.stopPropagation()}
          className={
            renderDayClass(day, modifiers) +
            `${isSelected ? '' : ' cursor-not-allowed opacity-60'}` +
            '  w-full h-full flex items-center justify-center'
          }
        >
          {day.format('D')}
        </div>
      </BasicTooltip>
    )
  }

  const renderDayContent = (day: moment.Moment, modifiers: ModifiersShape) => {
    // check min night, max night
    const diff = day.diff(startDate, 'day')
    const isLessThanMinNight = 0 < diff && diff < minimumStay
    const isGreaterMaxNight = 0 < diff && maximumStay < diff
    const title = isLessThanMinNight ? `${minimumStay}-night minimum` : `${maximumStay}-night maximum`
    // check close on checkin, close on checkout
    const key = moment(day).format('YYYY-MM')
    const dataMonth = calendarDatesMap.get(key)
    const dataDate = Array.isArray(dataMonth)
      ? dataMonth.find((item: any) => item.date === moment(day).format(DATE_FORMAT))
      : null
    const isCheckoutOnly = !!(dataDate && dataDate.closedOnCheckin && !dataDate.closedOnCheckout)
    const isShowCheckoutOnly = isCheckoutOnly && datePickerFocusedId !== END_DATE
    if (day) {
      if (isShowCheckoutOnly) {
        return renderDayCheckInCheckOutOnly(day, modifiers)
      }
      if (isNativeListing && datePickerFocusedId === END_DATE) {
        return renderDayInMinNightThrought(day, modifiers)
      }
      if (!isNativeListing && datePickerFocusedId === END_DATE && (isLessThanMinNight || isGreaterMaxNight)) {
        return renderDayInMinMaxNight(day, modifiers, title)
      }
      return (
        <div
          className={
            'w-full h-full flex items-center justify-center font-inter-500 ' + ' ' + renderDayClass(day, modifiers)
          }
        >
          {day.format('D')}
        </div>
      )
    }
    return <></>
  }

  const isOutsideRange = (day: Moment) => {
    return !isInclusivelyAfterDay(day, moment())
  }

  return (
    <DayPickerRangeController
      navPosition={'navPositionTop'}
      orientation={isMobile ? 'verticalScrollable' : 'horizontal'}
      startDate={startDate}
      endDate={endDate}
      focusedInput={datePickerFocusedId}
      onFocusChange={onFocusChange}
      isDayBlocked={isDayBlocked}
      onDatesChange={onDatesChange}
      hideKeyboardShortcutsPanel={true}
      numberOfMonths={2}
      isOutsideRange={isOutsideRange}
      noBorder={true}
      daySize={45}
      withPortal={false}
      initialVisibleMonth={null}
      minimumNights={0}
      navPrev={
        isMobile ? (
          <BasicButton
            disabled={moment(listMonth[0], DATE_FORMAT).isSameOrBefore(moment().startOf('month'))}
            variant={'outlined'}
            clases={`w-full ${
              moment(listMonth[0], DATE_FORMAT).isSameOrBefore(moment().startOf('month')) ? 'hidden' : ''
            }`}
            onClick={(e) => {
              e.preventDefault()
              onNextPrevMonthMobileClick('prev')
            }}
          >
            Load more dates
          </BasicButton>
        ) : (
          <IconButton
            classes={{ root: 'absolute top-[16px] left-[24px]' }}
            disabled={currentMonthOnCalendar.isSameOrBefore(moment().startOf('month'))}
          >
            <img src={ic_arrow_back} alt={'ic_arrow_back'} />
          </IconButton>
        )
      }
      navNext={
        isMobile ? (
          <BasicButton
            variant={'outlined'}
            clases={'w-full mt-6'}
            onClick={(e) => {
              e.preventDefault()
              onNextPrevMonthMobileClick('next')
            }}
          >
            Load more dates
          </BasicButton>
        ) : (
          <IconButton classes={{ root: 'absolute top-[16px] right-[24px]' }}>
            <img src={ic_arrow_forward} alt={'ic_arrow_forward'} />
          </IconButton>
        )
      }
      onPrevMonthClick={onNextPrevMonthClick('prev')}
      onNextMonthClick={onNextPrevMonthClick('next')}
      renderDayContents={renderDayContent}
    />
  )
}

export default BasicDateTimePicker
