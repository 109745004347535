import BasicButton from '../ui/BasicButton'
import clsx from 'clsx'
import React from 'react'

const RewardsProgramBanner = () => {
  return (
    <div
      className={clsx(
        'w-full px-[24px] py-[16px]',
        'rounded-[8px]',
        'flex items-center justify-between gap-[24px]',
        'flex-col md:flex-row',
        'font-maison-neue text-white',
        'mb-[40px]',
        `bg-[url('https://dtrav-dev.s3.ap-southeast-1.amazonaws.com/host/rewards_program_bg_banner.webp')]`,
        'bg-center bg-no-repeat bg-cover'
        // 'bg-black opacity-30'
      )}
    >
      <div className={'flex flex-col gap-[4px]'}>
        <p className={'text-20-24'}>Grow the ecosystem. Earn TRVL.</p>
        <p className={'text-14-20'}>
          Sign up for the Dtravel Rewards Program and earn rewards by adding new listings, referring hosts, and more.
        </p>
      </div>
      <div className={'w-full md:w-auto'}>
        <BasicButton
          variant={'contained'}
          size={'md'}
          color={'white'}
          isRadius100={true}
          clases={'w-full'}
          href={'https://www.dtravel.com/token/rewards-program'}
          target={'_blank'}
        >
          More info
        </BasicButton>
      </div>
    </div>
  )
}

export default RewardsProgramBanner
