import { checkStatusHospitable, connectHospitable } from '../../../api/integration'
import { useAppSelector } from '../../../redux/hooks'
import { handleErrorMessage } from '../../../utils/common'
import DisconnectAirbnb from '../../connect-pms/DisconnectAirbnb'
import AirbnbImportFlow from '../../property-import-airbnb/AirbnbImportFlow'
import BasicButton from '../../ui/BasicButton'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

interface Props {
  countHospitable: any[]
  countAirbnb: number
}

const ConnectAirbnbViaHospitable: React.FC<Props> = ({ countHospitable, countAirbnb }) => {
  const { airbnbViaHospitable, airbnbViaChannex } = useAppSelector((state) => state.user)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [countHospitableByChannel, setCountHospitableByChannel] = useState<any>({})

  useEffect(() => {
    if (Array.isArray(countHospitable)) {
      let obj: any = {}
      for (let item of countHospitable) {
        obj[item.channelId] = item.count
      }
      setCountHospitableByChannel(obj)
    }
  }, [countHospitable])

  const handleClickAddAccount = async () => {
    try {
      setIsLoading(true)
      const resConnect = await connectHospitable()
      if (resConnect.data && resConnect.success) {
        const connectionId = resConnect.data.id
        const resCheckStatus = await checkStatusHospitable(
          connectionId,
          `/handle-connect-hospitable?from=settings&connectionId=${connectionId}`
        )
        window.location.href = resCheckStatus.data.returnUrl
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <div className={'flex flex-col md:flex-row items-start md:items-center justify-between gap-[16px]'}>
        <div className={'flex items-center gap-4'}>
          <span>
            <img
              src={'https://dtrav-dev.s3.ap-southeast-1.amazonaws.com/logo/airbnb-logo.svg'}
              alt={'airbnb-logo'}
              width={'48px'}
              height={'48px'}
            />
          </span>
          <p className={'font-maison-neue-medium text-16-20 text-neutral-800'}>Airbnb (via Hospitable Connect)</p>
        </div>
        <BasicButton
          variant={'outlined'}
          isRadius100={true}
          clases={'w-full md:w-auto'}
          size={'md'}
          onClick={handleClickAddAccount}
          loading={isLoading}
        >
          {Array.isArray(airbnbViaHospitable) && airbnbViaHospitable.length > 0
            ? 'Add another account'
            : 'Add an account'}
        </BasicButton>
      </div>

      {Array.isArray(airbnbViaHospitable) && airbnbViaHospitable.length > 0 && (
        <div className={'flex flex-col gap-[16px] pl-0 md:pl-[64px] mt-[24px]'}>
          {airbnbViaHospitable.map((item, index) => {
            const count = countHospitableByChannel[item.channelId] || 0
            return (
              <div
                key={item.id}
                className={'flex flex-col md:flex-row items-start md:items-center justify-between gap-[8px]'}
              >
                <div>
                  <p className={'flex items-center gap-[8px] mb-[2px]'}>
                    <span className={'font-maison-neue text-16-20 text-neutral-800'}>
                      {item.name || `Account ${index + 1}`}
                    </span>
                    <span className="font-maison-neue-medium text-forest-6 uppercase text-12-16 bg-forest-1 px-2 py-[1px] rounded-[100px] tracking-[.04em]">
                      CONNECTED
                    </span>
                  </p>
                </div>
                <div className={'flex items-center justify-between gap-4 w-full md:w-auto'}>
                  <span
                    className={clsx(
                      'flex items-center font-maison-neue text-14-18 text-neutral-600 h-[40px]',
                      'w-full md:w-auto'
                    )}
                  >
                    <>
                      {count} {count > 1 ? 'properties' : 'property'}
                    </>
                  </span>
                  <DisconnectAirbnb channelId={item.channelId} />
                </div>
              </div>
            )
          })}
        </div>
      )}

      {airbnbViaChannex && (
        <div className={'flex flex-col md:flex-row items-start md:items-center justify-between gap-[16px] mt-[24px]'}>
          <div className={'flex items-center gap-4'}>
            <span>
              <img
                src={'https://dtrav-dev.s3.ap-southeast-1.amazonaws.com/logo/airbnb-logo.svg'}
                alt={'airbnb-logo'}
                width={'48px'}
                height={'48px'}
              />
            </span>
            <p className={'font-maison-neue-medium text-16-20 text-neutral-800'}>Airbnb (via Channex Connect)</p>
          </div>
          <div className={'flex items-center justify-between gap-4 w-full md:w-auto'}>
            <span
              className={clsx(
                'flex items-center font-maison-neue text-14-18 text-neutral-600 h-[40px]',
                'w-full md:w-auto'
              )}
            >
              <>
                {countAirbnb} {countAirbnb > 1 ? 'properties' : 'property'}
              </>
            </span>
            <DisconnectAirbnb />
          </div>
        </div>
      )}
      <AirbnbImportFlow />
    </div>
  )
}

export default ConnectAirbnbViaHospitable
