import ic_checked_black from '../../../assets/icons/ic_checked_black.svg'
import ic_checked_minus from '../../../assets/icons/ic_checked_minus.svg'
import ic_un_checked from '../../../assets/icons/ic_un_checked.svg'
import AddEditUser from './AddEditUser'
import DeleteUsers from './DeleteUsers'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useState } from 'react'
import TableCellCustom from 'src/components/property/TableCellCustom'
import TablePaging from 'src/components/property/TablePaging'
import { BUSINESS_USER_ROLE } from 'src/contants/common'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { Paging } from 'src/interfaces'
import { useAppSelector } from 'src/redux/hooks'
import { isEmpty } from 'src/utils/common'

interface Props {
  users: any[]
  paging: Paging
  fetchData: (param?: any) => void
}

// const TableCellStickyStyle: any = {
//   position: 'sticky',
//   left: '0',
//   background: '#fff',
//   zIndex: '10',
//   '@media only screen and (min-width: 768px) and (max-width: 1480px)': {
//     '&::before': {
//       content: '""',
//       height: '100%',
//       top: 0,
//       position: 'absolute',
//       width: '12px',
//       right: '-12px',
//       background: 'linear-gradient(to left, #F5F5F500, #E9E9E9)',
//     },
//   },
// }

const TableListingUsers: React.FC<Props> = ({ users, paging, fetchData }) => {
  // const dispatch = useAppDispatch()
  const { profile } = useAppSelector((state) => state.user)
  const isEditor = profile?.editorProfile?.type === BUSINESS_USER_ROLE.EDITOR
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const adminUser = {
    adminUserId: profile?.userId,
    email: profile?.user?.email,
    id: profile?.editorProfile?.id,
    name: [profile?.firstName, profile?.lastName].join(' '),
    role: BUSINESS_USER_ROLE.ADMIN,
  }

  const handleChangePagination = (field: string, value: string | any) => {
    fetchData({ ...paging, [field]: value })
  }

  const handleSelectAllItem = () => {
    const isChecked = !isEmpty(users) && selectedIds.length === users.length
    if (isChecked) setSelectedIds([])
    else setSelectedIds([...users].map((v: any) => v.id))
  }
  const handleSelectItem = (userId: string) => {
    const isChecked = selectedIds.includes(userId)
    if (isChecked) setSelectedIds([...selectedIds].filter((v: string) => v !== userId))
    else setSelectedIds([...selectedIds, userId])
  }

  const renderCheckAll = () => {
    const isCheckedAll = !isEmpty(users) && selectedIds.length === users.length
    const isUnCheckedAll = !isEmpty(users) && selectedIds.length === 0
    const isCheckedMinus = !isEmpty(users) && !isCheckedAll && !isEmpty(selectedIds)
    return (
      <div
        className="flex items-center justify-center cursor-pointer"
        onClick={() => handleSelectAllItem()}
        role="presentation"
      >
        {isCheckedAll && <img src={ic_checked_black} alt="" className="w-4 h-4 min-w-[16px]" />}
        {isUnCheckedAll && <img src={ic_un_checked} alt="" className="w-4 h-4 min-w-[16px]" />}
        {isCheckedMinus && <img src={ic_checked_minus} alt="" className="w-4 h-4 min-w-[16px]" />}
      </div>
    )
  }
  const renderChecked = (userId: string) => {
    const isChecked = selectedIds.includes(userId)
    return (
      <div
        className="flex items-center justify-center cursor-pointer"
        onClick={() => handleSelectItem(userId)}
        role="presentation"
      >
        {isChecked ? (
          <img src={ic_checked_black} alt="" className="w-4 h-4 min-w-[16px]" />
        ) : (
          <img src={ic_un_checked} alt="" className="w-4 h-4 min-w-[16px]" />
        )}
      </div>
    )
  }
  const renderRole = (role: string) => {
    return (
      <div className="bg-neutral-100 rounded-[16px] py-[2px] px-[10px] h-[20px]">
        <span className="text-neutral-700 uppercase text-12-16 font-maison-neue-demibold">{role}</span>
      </div>
    )
  }

  const fields: any[] = [
    {
      key: 'name',
      title: 'Name',
      width: '20%',
      minWidth: '240px',
      align: 'left',
      renderTitle: () => {
        return (
          <div className={'py-[12px] lg:py-[6px] gap-[12px] flex items-center'}>
            {!isEditor && renderCheckAll()}
            <span className="font-maison-neue-medium text-12-16 text-neutral-6">Name</span>
          </div>
        )
      },
      render: (item: any) => {
        const isAdmin = item?.role === BUSINESS_USER_ROLE.ADMIN
        return (
          <div className={'flex items-center gap-4'}>
            {!isEditor && (isAdmin ? <div className="w-4 h-4" /> : renderChecked(item.id))}
            <div className={'flex items-center gap-4'}>
              <div className="flex items-center justify-center">
                <div className="w-10 h-10 p-[10px] rounded-[100px] border border-neutral-300 flex items-center justify-center">
                  <span className="uppercase text-14-18 font-maison-neue-medium text-red-700 text-center">
                    {item.name
                      .split(' ')
                      .filter((v: string, idx: number) => idx < 2)
                      .map((v: string) => v?.substring(0, 1))
                      .join('')}
                  </span>
                </div>
              </div>
              <div
                className={`line-clamp-2 font-maison-neue-medium text-neutral-900 text-14-18`}
                data-hover={item.name}
              >
                {item.name}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      key: 'role',
      title: 'Role',
      tooltip: '',
      width: '8.5%',
      minWidth: '120px',
      mobileHidden: '1',
      renderTitle: () => {
        return <p className="font-maison-neue-medium text-12-16 text-neutral-6 text-center">Role</p>
      },
      render: (user: any) => {
        return <div className="flex items-center justify-center">{renderRole(user.role)}</div>
      },
    },
    {
      key: 'email',
      title: 'Email',
      width: '8.5%',
      minWidth: '120px',
      mobileHidden: '1',
      align: 'center',
      render: (user: any) => {
        return <div>{user.email}</div>
      },
    },
    {
      key: 'action',
      title: '',
      width: '8.5%',
      render: (user: any) => {
        const isAdmin = user?.role === BUSINESS_USER_ROLE.ADMIN
        if ((isAdmin || (isEditor && user?.userId !== profile?.editorProfile?.userId)) && !isMobile) return <></>
        return (
          <>
            <div className="flex items-center justify-center gap-4">
              {isMobile && renderRole(user.role)}
              {!isAdmin && <AddEditUser fetchData={fetchData} user={user} />}
              {isAdmin && isMobile && <div className="w-[54px]" />}
            </div>
          </>
        )
      },
    },
  ]
  const data = [adminUser, ...users]
  return (
    <div
      className="rounded-[16px]"
      style={{ boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.04), 0px 8px 16px -4px rgba(0, 0, 0, 0.06)' }}
    >
      <div
        className={
          'w-full h-[52px] flex items-center px-[16px] gap-[16px] bg-neutral-50 rounded-tl-[16px] rounded-tr-[16px]'
        }
        style={{ boxShadow: '0px 0.5px 0px 0px rgba(0, 0, 0, 0.15)' }}
      >
        {!isEditor && (
          <div className="flex items-center gap-4">
            <span className="text-14-18 font-maison-neue-medium text-neutral-900">Edit:</span>
            {!isEmpty(selectedIds) ? (
              <DeleteUsers
                fetchData={() => {
                  setSelectedIds([])
                  fetchData()
                }}
                userIds={selectedIds}
              />
            ) : (
              <p className="text-14-18 font-maison-neue-medium text-neutral-400">Select one or more users to edit</p>
            )}
          </div>
        )}
        <div className={'ml-auto'}>
          {isEmpty(selectedIds) && (
            <TablePaging
              total={paging.total + 1}
              page={paging.page}
              pageSize={paging.pageSize}
              totalPages={paging.totalPages}
              handleChangeSearchAndPaging={handleChangePagination}
            />
          )}
        </div>
      </div>
      <div className={'w-full h-[1px] bg-neutral-3'} /> {/* border top */}
      {/* <div className={'shadow-3xl overflow-x-auto'} id={'table-listing'}> */}
      <div id={'table-listing-user'}>
        <TableContainer>
          <Table aria-label="simple table" classes={{ root: 'w-full' }}>
            <TableHead>
              <TableRow>
                {fields.map((item) => {
                  return (
                    <TableCellCustom
                      key={item.key}
                      align={item.align || 'left'}
                      style={{ width: item.width }}
                      mobilehidden={item.mobileHidden}
                      minwidth={item.minWidth}
                      // sx={item.key === 'name' ? { '&.MuiTableCell-root': { ...TableCellStickyStyle } } : {}}
                      className={'customColumn'}
                    >
                      {typeof item.renderTitle === 'function' ? item.renderTitle() : item.title}
                    </TableCellCustom>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((user: any, idx: number) => {
                const isChangeBackground = selectedIds.includes(user.id)
                const isLastRow = idx === data.length - 1
                return (
                  <TableRow
                    key={user.id}
                    sx={{
                      '&.MuiTableRow-root': {
                        backgroundColor: isChangeBackground ? '#F6F6F4' : '#FFFFFF',
                      },
                    }}
                  >
                    {fields.map((item) => {
                      return (
                        <TableCellCustom
                          key={item.key}
                          scope="row"
                          align={item.align || 'left'}
                          mobilehidden={item.mobileHidden}
                          sx={{
                            '&.MuiTableCell-root': {
                              background: 'white',
                              borderBottom: isLastRow ? 'none' : 'solid 1px #EBE9E9',
                              borderBottomLeftRadius: isLastRow && item?.key === 'name' ? '16px' : '0px',
                              borderBottomRightRadius: isLastRow && item?.key === 'action' ? '16px' : '0px',
                            },
                          }}
                        >
                          {typeof item.render === 'function'
                            ? // @ts-ignore
                              item.render(user, user[item.key])
                            : // @ts-ignore
                              user[item.key]}
                        </TableCellCustom>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/*  Check all button for mobile */}
      {/* {users.length > 0 && (
        <div className={'fixed lg:hidden z-50 bottom-[88px] left-[16px]'}>
          {renderCheckAll()}
        </div>
      )} */}
    </div>
  )
}

export default TableListingUsers
