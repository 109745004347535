import { PMS_TYPE } from '../../contants/common'
import React, { useEffect, useState } from 'react'
import { disconnectPMS } from 'src/api/integration'
import BasicButton from 'src/components/ui/BasicButton'
import BasicPopup from 'src/components/ui/BasicPopup'
import BasicTextarea from 'src/components/ui/BasicTextarea'
import { useAppDispatch } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { setPmsTypeConnected } from 'src/redux/slices/user'
import { handleErrorMessage, isEmpty } from 'src/utils/common'

interface Props {
  isOpen: boolean
  handleClose: () => void
  type: string
}

const reasons = [
  { type: 'default', content: "I'm switching to another Dtravel-connected PMS", id: 1 },
  { type: 'default', content: 'I no longer wish to use Dtravel', id: 2 },
  { type: 'other', content: 'Other', id: 3 },
]

const ContactSupportTeam: React.FC<Props> = ({ isOpen, handleClose, type }) => {
  const dispatch = useAppDispatch()
  const [pmsName, setPmsName] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [reason, setReason] = useState<Array<any>>([])

  useEffect(() => {
    if (isOpen) setReason([])
  }, [isOpen])

  const handleDisconnect = async () => {
    if (!loading) {
      try {
        setLoading(true)
        await disconnectPMS({
          reason: [...reason].map((v) => {
            return { type: v.type, content: v.content }
          }),
          type,
        })
        dispatch(setPmsTypeConnected(''))
        const mess = `${
          type === PMS_TYPE.GUEST_SMILES
            ? 'Track via GuestSmiles'
            : type === PMS_TYPE.NEXTPAX
            ? 'Nextpax via GuestSmiles'
            : pmsName
        } disconnected`
        dispatch(setToastSuccess({ message: mess }))
        handleClose()
      } catch (err: any) {
        handleErrorMessage(err)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (type === PMS_TYPE.HOSTAWAY) {
      setPmsName('Hostaway')
    }
    if (type === PMS_TYPE.UPLISTING) {
      setPmsName('Uplisting')
    }
    if (type === PMS_TYPE.GUEST_SMILES) {
      setPmsName('Track')
    }
    if (type === PMS_TYPE.NEXTPAX) {
      setPmsName('Nextpax')
    }
  }, [type])

  const other = reason.find((v) => v.type === 'other')
  return (
    <BasicPopup
      isOpen={isOpen}
      onClose={handleClose}
      title={`Disconnect ${pmsName}`}
      extraTitle={`Why would you like to disconnect your ${pmsName} account?`}
    >
      <div className={'flex flex-col'}>
        {reasons.map((el: any, idx: number) => {
          const isChecked = reason.find((v) => v.id === el.id)
          return (
            <div
              key={idx}
              className="flex items-center mb-2 cursor-pointer"
              onClick={() => {
                if (isChecked) setReason([])
                else setReason([{ ...el, content: el.type === 'other' ? '' : el.content }])
              }}
              role="presentation"
            >
              <div
                className={`flex items-center justify-center w-4 h-4 min-w-4 rounded-3xl bg-white border ${
                  isChecked ? 'border-red-6' : 'border-neutral-7'
                }`}
              >
                <div className={`w-[10px] h-[10px] min-w-[10px] rounded-3xl ${isChecked ? 'bg-red-6' : 'bg-white'}`} />
              </div>
              <span className="text-grey-700 text-16-24 pl-3">{el?.content}</span>
            </div>
          )
        })}
      </div>
      {other && (
        <>
          <BasicTextarea
            label={''}
            placeholder={'Write your reason here'}
            rows={5}
            maxLength={200}
            value={other.content}
            onChange={(e) => {
              let temp = [...reason].map((v) => {
                if (v.type === 'other') return { ...v, content: e.target.value }
                return v
              })
              setReason(temp)
            }}
          />
          <span className="mt-3 text-12-16 font-maison-neue text-neutral-6">
            {other.content.length || 0}/200 characters
          </span>
        </>
      )}
      <div className={'flex flex-col py-8'}>
        <BasicButton
          variant={'outlined'}
          size={'xl'}
          color={'black'}
          onClick={handleClose}
          clases={'w-full flex justify-center'}
          isRadius100={true}
        >
          Cancel
        </BasicButton>
        <div className="w-full h-[16px]" />
        <BasicButton
          variant="contained"
          size={'xl'}
          color={'red'}
          clases={'w-full flex justify-center'}
          onClick={handleDisconnect}
          loading={loading}
          disabled={isEmpty(reason) || (other && reason.length === 1 && isEmpty(other.content.trim()))}
          isRadius100={true}
        >
          Disconnect
        </BasicButton>
      </div>
    </BasicPopup>
  )
}

export default ContactSupportTeam
