import { getPmsIntegrated } from '../api/integration'
import { getListingsStatus, updateListingHospitable } from '../api/listings'
import { DATE_FORMAT, NATIVE_LISTING_MENU, PMS, PMS_TYPE } from '../contants/common'
import { AirbnbViaChannex } from '../interfaces/user'
import {
  setImportingPropertyStatus,
  setImportRequestIdAirbnb,
  setIsOpenPopupInfoAirbnb,
  setIsOpenPopupSelectAccountAirbnb,
} from '../redux/slices/listing'
import {
  setAirbnbPmsType,
  setIsConnectedAirbnb,
  setLoadingPms,
  setPmsSyncAt,
  setPmsTypeConnected,
  setAirbnbViaHospitable,
  setAirbnbViaChannex,
} from '../redux/slices/user'
import moment from 'moment'
import { PROPERTY_SOURCE } from 'src/contants/native'
import { Address } from 'src/interfaces/listing'
import { store } from 'src/redux/store'
import { getKeys, handleErrorMessage } from 'src/utils/common'

export const renderLocation = (address: Address) => {
  let location = ''
  const keys = ['city', 'state', 'country']
  for (let key of getKeys(address)) {
    if (keys.includes(key) && address[key]) {
      location += (location === '' ? '' : ', ') + address[key]
    }
  }
  return location
}

export const convertSyncedTimeAgo = (syncedTime: Date, status?: string) => {
  const totalMinutes = moment(moment()).diff(syncedTime, 'minutes', true)
  const minutes = Math.round(totalMinutes % 60)
  const hours = Math.floor(totalMinutes / 60)
  if (hours < 24) {
    return `Synced ${status ? status + ' ' : ''}${hours > 0 ? hours + `${hours > 1 ? ' hours' : ' hour'}` : ''} ${
      minutes > 0 ? minutes + ` ${minutes > 1 ? 'mins' : 'min'}` : '0 min'
    } ago`
  } else {
    const days = Math.floor(totalMinutes / 60 / 24)
    if (days > 7) {
      return `Synced ${status ? status + ' ' : ''}7+ days ago`
    }
    if (days > 31) {
      return `Synced  ${status ? status + ' ' : ''}1+ month ago`
    }
    if (days > 365) {
      return `Synced  ${status ? status + ' ' : ''}1+ year ago`
    }
    return `Synced ${status ? status + ' ' : ''}${days} ${days >= 2 ? ' days' : 'day'} ago`
  }
}

export const getAllDatesInRange = (fromDate: string, toDate: string) => {
  const start = moment(fromDate, DATE_FORMAT)
  const end = moment(toDate, DATE_FORMAT)
  const diffDay = end.diff(start, 'days')
  const datesInRange: string[] = []
  for (let i = 0; i <= diffDay; i++) {
    const date = moment(start).add(i, 'days')
    datesInRange.push(date.format(DATE_FORMAT))
  }
  return datesInRange
}

export const removeEmptyAttribute = (obj: any) => {
  // eslint-disable-next-line no-unused-vars
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
}

export const getValidWeekdayBetweenStartAndEndDate = (weekdayData: any, startDate: string, endDate: string) => {
  let newWeekday: any = {}
  if (weekdayData) {
    const validDays: any = new Set()
    const dateFrom = moment(startDate, DATE_FORMAT)
    const dateTo = moment(endDate, DATE_FORMAT)
    if (dateFrom && dateTo) {
      const diffDay = dateTo.diff(dateFrom, 'days')
      for (let i = 0; i <= diffDay; i++) {
        const momentDay = moment(dateFrom).add(i, 'days')
        const dayOfWeekName = momentDay.isoWeekday(momentDay.isoWeekday()).format('dddd').toLowerCase() // sunday, monday, tuesday, wednesday, thursday, friday, saturday
        validDays.add(dayOfWeekName)
      }
    }
    for (let dayName in weekdayData) {
      if (validDays.has(dayName) && weekdayData[dayName] !== undefined) {
        newWeekday[dayName] = weekdayData[dayName]
      }
    }
  }
  return newWeekday
}

export const getAndSortDaysOfWeek = (weekday: any) => {
  const SORT_DAYS: any = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  }
  const listDay = weekday ? Object.keys(weekday) : []
  listDay.sort((day1: string, day2: string) => SORT_DAYS[day1] - SORT_DAYS[day2])
  return listDay
}

export const renderPropertyInfo = ({ bedrooms, beds, bathrooms, personCapacity, livingrooms, kitchens }: any) => {
  let result: string[] = []
  if (personCapacity > 0 && personCapacity <= 16) result.push(`${personCapacity} guest${personCapacity > 1 ? 's' : ''}`)
  if (personCapacity > 16) result.push(`16+ guests`)
  if (bedrooms > 0) result.push(`${bedrooms} bedroom${bedrooms > 1 ? 's' : ''}`)
  if (beds > 0) result.push(`${beds} bed${beds > 1 ? 's' : ''}`)
  if (bathrooms > 0) result.push(`${bathrooms} bathroom${bathrooms > 1 ? 's' : ''}`)
  if (livingrooms > 0) result.push(`${livingrooms} living room${livingrooms > 1 ? 's' : ''}`)
  if (kitchens > 0) result.push(`${kitchens} kitchen${kitchens > 1 ? 's' : ''}`)
  return result.join(' • ')
}
export const renderSourceType = (source: string) => {
  if (source === PROPERTY_SOURCE.DTRAVEL) return 'Dtravel'
  if (source === PROPERTY_SOURCE.HOSTAWAY) return 'Hostaway'
  if (source === PROPERTY_SOURCE.UPLISTING) return 'Uplisting'
  if (source === PROPERTY_SOURCE.GUEST_SMILES) return 'GuestSmiles'
  if (source === PROPERTY_SOURCE.RENTAL_WISE) return 'RentalWise'
  if (source === PROPERTY_SOURCE.GUESTY) return 'Guesty'
  if (source === PROPERTY_SOURCE.BOOSTLY) return 'Boostly'
  if (source === PROPERTY_SOURCE.AIRBNB) return 'Airbnb'
  if (source === PROPERTY_SOURCE.HOSPITABLE) return 'Airbnb'
  if (source === PROPERTY_SOURCE.NEXTPAX) return 'GuestSmiles'
  // if (source === PROPERTY_SOURCE.NEXTPAX) return 'Nextpax'
  return 'Other'
}

export const convertTaxFeeAppliedPer = (appliedPer: string) => {
  if (appliedPer === 'reservation') {
    return 'per stay'
  }
  if (appliedPer === 'person') {
    return 'per person'
  }
  if (appliedPer === 'night') {
    return 'per night'
  }
  if (appliedPer === 'person_per_night') {
    return 'per person per night'
  }
  return ''
}

export const fetchIntegratedConfig = async () => {
  try {
    store.dispatch(setLoadingPms(true))
    const res = await getPmsIntegrated()
    const data = res.data
    const { airbnbViaChannex, pmsConnectors, airbnbViaHospitable } = data || {}
    if (Array.isArray(pmsConnectors) && pmsConnectors.length > 0) {
      pmsConnectors.forEach((item) => {
        store.dispatch(setPmsTypeConnected(item.type))
        store.dispatch(setPmsSyncAt(item.syncAt))
      })
    }
    store.dispatch(setAirbnbViaHospitable(airbnbViaHospitable))
    store.dispatch(setAirbnbViaChannex(airbnbViaChannex))
    if (Array.isArray(airbnbViaHospitable) && airbnbViaHospitable.length > 0) {
      store.dispatch(setAirbnbPmsType(PMS_TYPE.HOSPITABLE))
      store.dispatch(setIsConnectedAirbnb(true))
    }
    if (airbnbViaChannex && airbnbViaChannex.airbnbChannelId) {
      store.dispatch(setAirbnbPmsType(PMS_TYPE.AIRBNB))
      store.dispatch(setIsConnectedAirbnb(true))
    }
  } catch (err) {
    console.log(err)
  } finally {
    store.dispatch(setLoadingPms(false))
  }
}

export const renderChannelLogo = (channel: string) => {
  let _channel = channel === PROPERTY_SOURCE.NEXTPAX ? PROPERTY_SOURCE.GUEST_SMILES : channel
  if (_channel === PROPERTY_SOURCE.HOSPITABLE) {
    _channel = PROPERTY_SOURCE.AIRBNB
  }
  if (
    [
      PROPERTY_SOURCE.HOSTAWAY,
      PROPERTY_SOURCE.UPLISTING,
      PROPERTY_SOURCE.GUEST_SMILES,
      PROPERTY_SOURCE.RENTAL_WISE,
      PROPERTY_SOURCE.GUESTY,
      PROPERTY_SOURCE.BOOSTLY,
      PROPERTY_SOURCE.AIRBNB,
      PROPERTY_SOURCE.NEXTPAX,
    ].includes(_channel)
  ) {
    return `https://static.dtravel.com/logo/${_channel}.png`
  }
  return 'https://static.dtravel.com/logo/dtravel.png'
}

export const saveDataImportAirbnb = (data: {
  requestId: string
  listProperty: any[]
  channelId: string
  isToggleOnPopup?: boolean
}) => {
  const { store } = require('src/redux/store')
  store.dispatch(setImportRequestIdAirbnb(data.requestId))
  localStorage.setItem('import_airbnb_data', JSON.stringify(data))
}

export const getDataImportAirbnb = () => {
  const currentData = localStorage.getItem('import_airbnb_data')
  if (currentData) {
    return JSON.parse(currentData)
  }
  return null
}

export const clearDataImportAirbnb = () => {
  const { store } = require('src/redux/store')
  store.dispatch(setImportRequestIdAirbnb(''))
  localStorage.removeItem('import_airbnb_data')
}

export const isPropertyAirbnb = (item: any) => {
  return item?.pmsType === PMS_TYPE.HOSPITABLE || item?.channel === PMS_TYPE.HOSPITABLE // without channex
}
export const isChannexAirbnb = (item: any) => {
  return item?.channel === PMS_TYPE.AIRBNB // edit listing as native listing
}
export const isPropertyDtravel = (item: any) => {
  return (
    (item?.pmsType === PMS.DTRAVEL && item?.source === PROPERTY_SOURCE.DTRAVEL) ||
    (item?.pmsType === PMS.DTRAVEL && item?.channel === PROPERTY_SOURCE.DTRAVEL)
  )
}

export const handleShowListingStatusAfterImport = async (ids: string, isUpdateToListed?: boolean) => {
  try {
    if (isUpdateToListed) {
      const localData = getDataImportAirbnb()
      const localProperties = localData?.listProperty
      // has data -> open popup import
      if (Array.isArray(localProperties) && localProperties.length > 0) {
        const idsToListed = localProperties
          .map((item) => String(item.dtravelPropertyId))
          .filter((id) => ids.includes(id))
        const payloadData: any[] = idsToListed.map((id) => ({ id: Number(id) }))
        if (payloadData.length > 0) {
          await updateListingHospitable(payloadData)
        }
      }
    }
    const res = await getListingsStatus(ids)
    if (res.success && Array.isArray(res.data)) {
      const objById: any = {}
      for (let item of res.data) {
        objById[item.id] = item
      }
      store.dispatch(setImportingPropertyStatus(objById))
    }
  } catch (err: any) {
    handleErrorMessage(err)
  }
}

export const checkCompletedStep = (step: string, statusStep: any) => {
  if (step === NATIVE_LISTING_MENU.PROPERTY) return !!statusStep?.property
  if (step === NATIVE_LISTING_MENU.LOCATION) return !!statusStep?.location
  if (step === NATIVE_LISTING_MENU.GUEST_ROOMS) return !!statusStep?.guestAndRooms
  if (step === NATIVE_LISTING_MENU.PHOTOS) return !!statusStep?.photos
  if (step === NATIVE_LISTING_MENU.AMENITIES) return !!statusStep?.amenities
  if (step === NATIVE_LISTING_MENU.DESCRIPTION) return !!statusStep?.description
  if (step === NATIVE_LISTING_MENU.HOUSE_RULES) return !!statusStep?.houseRules
  if (step === NATIVE_LISTING_MENU.AVAILABILITY) return !!statusStep?.losAndAvailability
  if (step === NATIVE_LISTING_MENU.ICAL) return !!statusStep?.iCal
  if (step === NATIVE_LISTING_MENU.FEES) return !!statusStep?.taxAndFee
  if (step === NATIVE_LISTING_MENU.DISCOUNTS) return !!statusStep?.discounts
  if (step === NATIVE_LISTING_MENU.RATES) return !!statusStep?.rates
  return false
}

export const joinChannexAndHospitableChannel = (airbnbViaHospitable: any[], airbnbViaChannex: AirbnbViaChannex) => {
  let _listChannel: any[] = []
  if (Array.isArray(airbnbViaHospitable)) {
    _listChannel = [...airbnbViaHospitable]
  }
  if (airbnbViaChannex && airbnbViaChannex.airbnbChannelId) {
    _listChannel.push({
      ...airbnbViaChannex,
      channelId: airbnbViaChannex.airbnbChannelId,
      name: 'Airbnb via Channex',
      type: 'channex',
    })
  }
  return _listChannel
}

export const handleLogicSelectAccountAirbnb = (airbnbViaHospitable: any[], airbnbViaChannex: AirbnbViaChannex) => {
  const hasAccount =
    (airbnbViaChannex && airbnbViaChannex.airbnbChannelId) ||
    (Array.isArray(airbnbViaHospitable) && airbnbViaHospitable.length > 0)
  if (hasAccount) {
    store.dispatch(setIsOpenPopupSelectAccountAirbnb(true)) // open popup select account
  } else {
    store.dispatch(setIsOpenPopupInfoAirbnb(true)) // open popup airbnb info
  }
}
