import SettingChannelManagement from './SettingChannelManagement'
import SettingPmsIntegration from './SettingPmsIntegration'
import React from 'react'

const SettingPmsAndChannel = () => {
  return (
    <>
      <div>
        <SettingPmsIntegration />
      </div>

      <div className={'mt-[48px]'}>
        <SettingChannelManagement />
      </div>
    </>
  )
}

export default SettingPmsAndChannel
