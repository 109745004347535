import BasicInput from '../../ui/BasicInput'
import React, { useState, useRef, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { getDNSTypeCustomUrl, updateCustomUrlPrimary, verifyCustomUrl } from 'src/api/user'
import ic_copy from 'src/assets/icons/ic_copy_2.svg'
import CustomUrlDtravelHosted from 'src/components/setting-custom-url/CustomUrlDtravelHosted'
import CustomUrlSelfHosted from 'src/components/setting-custom-url/CustomUrlSelfHosted'
import RemoveUrl from 'src/components/setting-custom-url/RemoveUrl'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import BasicRadio from 'src/components/ui/BasicRadio'
import { SETUP_GUIDE } from 'src/contants/common'
import { DnsTypeData } from 'src/interfaces/user'
import { useAppSelector } from 'src/redux/hooks'
import { setToastSuccess, setTriggerTime } from 'src/redux/slices/common'
import { convertDomain, getDomain, handleErrorMessage, isEmpty } from 'src/utils/common'

interface Props {}
export const DOMAIN_TYPE = {
  CUSTOM: 'custom',
  SELF_HOSTED: 'self_hosted',
}

let interval: any = null

const SettingSiteCustomUrl: React.FC<Props> = () => {
  const { profile } = useAppSelector((state) => state.user)
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const userCustomUrl: any = (profile?.user?.userCustomUrl || []).find((v: any) => v.isPrimary)
  const selfHostedDomainData: any = (profile?.user?.userCustomUrl || []).find(
    (v: any) => v?.type === DOMAIN_TYPE.SELF_HOSTED
  )
  const dtravelDomainData: any = (profile?.user?.userCustomUrl || []).find((v: any) => v?.type === DOMAIN_TYPE.CUSTOM)
  const subdomain = dtravelDomainData?.customId
  const dispatch = useDispatch()
  const [isSelfHosted, setIsSelfHosted] = useState<boolean>(userCustomUrl?.type === DOMAIN_TYPE.SELF_HOSTED)
  const [showConnectSelfHosted, setShowConnectSelfHosted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingPrimary, setLoadingPrimary] = useState<boolean>(false)
  const [selfHostedDomain, setSelfHostedDomain] = useState<string>('')
  const [dnsInfo, setDnsInfo] = useState<DnsTypeData>()
  const [isEditSelfHosted, setEditSelfHosted] = useState<boolean>(true)
  const [isErrorDomain, setErrorDomain] = useState<boolean>(false)
  const [loadingConnectAuto, setLoadingConnectAuto] = useState<boolean>(false)
  const [loadingVerifyConnection, setLoadingVerifyConnection] = useState<boolean>(false)
  const [showOtherPopup, setShowOtherPopup] = useState<boolean>(false)

  const handleOpen = () => {
    setIsOpenPopup(true)
    setIsSelfHosted(userCustomUrl?.type === DOMAIN_TYPE.SELF_HOSTED)
  }
  const handleClose = () => {
    setIsOpenPopup(false)
  }

  const handleTriggerOtherPopup = (value: boolean) => {
    setShowOtherPopup(value)
  }

  useEffect(() => {
    setIsSelfHosted(userCustomUrl?.type === DOMAIN_TYPE.SELF_HOSTED)
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(selfHostedDomainData)) {
      setSelfHostedDomain(convertDomain(selfHostedDomainData?.customId))
      setEditSelfHosted(false)
    } else {
      setSelfHostedDomain('')
      setEditSelfHosted(true)
    }
  }, [selfHostedDomainData])

  const getFullLink = () => {
    if (!isEmpty(dtravelDomainData)) {
      return `https://${subdomain}.${getDomain()}`
    }
    return ''
  }

  const onCopyLink = (text?: string) => {
    navigator.clipboard.writeText(text || getFullLink())
    dispatch(setToastSuccess({ message: `Copied!`, duration: 2000 }))
  }
  const handleSetPrimaryUrl = async () => {
    try {
      setLoadingPrimary(true)
      await updateCustomUrlPrimary(isSelfHosted ? selfHostedDomainData?.id : dtravelDomainData?.id)
      dispatch(setToastSuccess({ message: 'Update custom url primary success!' }))
      dispatch(setTriggerTime(+new Date())) // trigger to fetch profile again (function fetch profile in Settings.tsx)
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setLoadingPrimary(false)
    }
  }
  const handleCheckAuto = async () => {
    try {
      setLoading(true)
      const res: any = await getDNSTypeCustomUrl(selfHostedDomain)
      setDnsInfo(res.data)
      setErrorDomain(false)
      setShowConnectSelfHosted(true)
      setEditSelfHosted(!isEditSelfHosted)
    } catch (err: any) {
      setErrorDomain(true)
      if (err?.code !== 'EDNS1002') {
        // setShowConnectSelfHosted(true)
        setEditSelfHosted(!isEditSelfHosted)
      }
      handleErrorMessage(err)
    } finally {
      setLoading(false)
    }
  }
  const handleVerifySuccess = () => {
    setShowConnectSelfHosted(false)
    setEditSelfHosted(!isEditSelfHosted)
  }
  const handleVerify = async () => {
    try {
      setLoadingVerifyConnection(true)
      await verifyCustomUrl(selfHostedDomain)
      dispatch(setToastSuccess({ message: 'This domain is successfully connected!' }))
      dispatch(setTriggerTime(+new Date())) // trigger to fetch profile again (function fetch profile in Settings.tsx)
      handleVerifySuccess()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setLoadingVerifyConnection(false)
    }
  }

  const renderRadioItem = (type: 'dtravel' | 'self') => {
    const isDtravelHosted = type === 'dtravel'
    const isChecked = (isDtravelHosted && !isSelfHosted) || (type === 'self' && isSelfHosted)
    const isPrimary =
      (isDtravelHosted && userCustomUrl?.type === DOMAIN_TYPE.CUSTOM) ||
      (!isDtravelHosted && userCustomUrl?.type === DOMAIN_TYPE.SELF_HOSTED)
    const isPendingCertificate = type === 'self' && selfHostedDomainData?.status === 'ssl_pending'
    const isFailedCertificate = type === 'self' && selfHostedDomainData?.status === 'ssl_failed'
    const isConnectedSelfHosted = type === 'self' && !isEmpty(selfHostedDomainData) && !selfHostedDomainData?.isPrimary
    return (
      <div
        className={
          'p-[24px] rounded-[16px] flex gap-[4px] items-center justify-between w-full cursor-pointer border ' +
          `${isChecked ? 'border-[2px] border-neutral-8' : 'border-neutral-3'}`
        }
        onClick={() => {
          if (type === 'self') {
            setSelfHostedDomain(convertDomain(selfHostedDomainData?.customId))
            setEditSelfHosted(isEmpty(convertDomain(selfHostedDomainData?.customId)))
            setErrorDomain(false)
          }
          setShowConnectSelfHosted(false)
          setIsSelfHosted(type === 'self')
        }}
        role="presentation"
      >
        <div>
          <p className={`font-maison-neue-medium text-16-20 capitalize text-neutral-8 flex items-center`}>
            {type} Hosted
            {isPrimary && (
              <span className="font-maison-neue-medium text-12-16 text-ocean-6 bg-ocean-1 tracking-[0.04em] uppercase py-[1px] px-[8px] rounded-[100px] ml-[8px]">
                PRIMARY
              </span>
            )}
            {isPendingCertificate && (
              <span className="font-maison-neue-medium text-12-16 text-sun-6 bg-sun-1 tracking-[0.04em] uppercase py-[1px] px-[8px] rounded-[100px] ml-[8px]">
                PENDING CERTIFICATE ISSUANCE
              </span>
            )}
            {isFailedCertificate && (
              <span className="font-maison-neue-medium text-12-16 text-red-6 bg-red-1 tracking-[0.04em] uppercase py-[1px] px-[8px] rounded-[100px] ml-[8px]">
                CERTIFICATE ISSUANCE FAILED
              </span>
            )}
            {isConnectedSelfHosted && !isPendingCertificate && !isFailedCertificate && !isPrimary && (
              <span className="font-maison-neue-medium text-12-16 text-forest-6 bg-forest-1 tracking-[0.04em] uppercase py-[1px] px-[8px] rounded-[100px] ml-[8px]">
                CONNECTED
              </span>
            )}
          </p>
          <p className={'font-maison-neue text-14-18 text-neutral-6 mt-[4px]'}>
            {isDtravelHosted ? `Choose a .${process.env.REACT_APP_DOMAIN} domain name` : 'Use your own domain'}
          </p>
        </div>
        <div>
          <BasicRadio id={`setting_${type}_hosted`} name={type} checked={isChecked} readOnly />
        </div>
      </div>
    )
  }

  const renderCustomDomain = () => {
    return (
      <div className={'flex flex-col gap-[8px] w-full'}>
        {/* <p className="text-neutral-800 text-14-18 font-maison-neue-medium">Enter your custom URL</p> */}
        {!isSelfHosted ? (
          <>
            {!isEmpty(dtravelDomainData) ? (
              <div
                className={
                  'flex items-center w-full h-[48px] border border-neutral-3 bg-neutral-1 rounded-[8px] px-[20px] py-[12px] text-center font-maison-neue-medium text-14-18 text-neutral-600 truncate'
                }
              >
                {`https://${subdomain}.${getDomain()}`}
                <span className="ml-auto cursor-pointer" onClick={() => onCopyLink()} role="presentation">
                  <img src={ic_copy} alt={'ic_external_link'} className={'w-[18px] h-[18px]'} />
                </span>
              </div>
            ) : (
              <div
                className={
                  'flex gap-[4px] w-full h-[48px] border border-neutral-3 bg-neutral-1 rounded-[8px] px-[20px] py-[12px]'
                }
                style={{ outline: 'none' }}
              >
                <span className={'font-maison-neue-medium text-14-18 text-neutral-600'}>https://</span>
                <span className={'font-maison-neue-medium text-14-18 text-neutral-600 ml-auto'}>.{getDomain()}</span>
              </div>
            )}
          </>
        ) : (
          // self hosted domain
          <div
            className={`flex items-center gap-[4px] w-full h-[48px] border bg-neutral-1 rounded-[8px] px-[20px] py-[12px] ${
              isErrorDomain ? 'border-red-6' : 'border-neutral-3'
            }`}
            style={{ outline: 'none' }}
          >
            <span className={'font-maison-neue-medium text-14-18 text-neutral-600'}>https://</span>
            <input
              ref={inputRef}
              type="text"
              value={selfHostedDomain}
              onChange={(e: any) => setSelfHostedDomain(e?.target?.value.trim())}
              onBlur={() => {
                setSelfHostedDomain(convertDomain(selfHostedDomain))
              }}
              onFocus={() => setErrorDomain(false)}
              readOnly={!isEditSelfHosted}
              minLength={4}
              className={`w-full focus:outline-none bg-neutral-1 font-maison-neue-medium text-14-18 ml-[-4px] focus:text-neutral-800 ${
                isErrorDomain ? 'text-red-700' : 'text-neutral-600'
              }`}
            />
            {!isEmpty(selfHostedDomain) && (
              <span className="ml-auto cursor-pointer" onClick={() => onCopyLink(selfHostedDomain)} role="presentation">
                <img src={ic_copy} alt={'ic_external_link'} className={'w-[18px] h-[18px] min-w-[18px]'} />
              </span>
            )}
          </div>
        )}

        <div className={'whitespace-nowrap'}>
          {isSelfHosted ? (
            <>
              <BasicButton
                size={'xl'}
                variant={'contained'}
                clases={'w-full h-[48px] rounded-[100px_!important]'}
                color="red"
                onClick={() => {
                  if (isEditSelfHosted) {
                    handleCheckAuto()
                  } else {
                    setEditSelfHosted(!isEditSelfHosted)
                    setShowConnectSelfHosted(false)
                    setTimeout(() => {
                      if (inputRef.current) {
                        inputRef.current.focus()
                      }
                    }, 300)
                  }
                }}
                disabled={isEmpty(selfHostedDomain) || loading}
                loading={loading}
              >
                {`${isEditSelfHosted ? 'Connect' : 'Update'} custom URL`}
              </BasicButton>
            </>
          ) : (
            <CustomUrlDtravelHosted
              userCustomUrl={dtravelDomainData}
              handleTriggerOtherPopup={handleTriggerOtherPopup}
              handleSuccess={handleClose}
            />
          )}
        </div>
      </div>
    )
  }

  const getCustomUrl = () => {
    if (!isEmpty(userCustomUrl)) {
      return userCustomUrl?.type === DOMAIN_TYPE.SELF_HOSTED
        ? `https://${convertDomain(selfHostedDomainData?.customId)}`
        : `https://${subdomain}.${getDomain()}`
    }
    return ''
  }

  const onCopy = () => {
    navigator.clipboard.writeText(getCustomUrl())
    dispatch(setToastSuccess({ message: `Copied!`, duration: 2000 }))
  }

  return (
    <div>
      <div
        className={'flex flex-col md:flex-row justify-between items-start gap-[12px]'}
        id={SETUP_GUIDE.SET_WEBSITE_URL}
      >
        <div>
          <p className={'font-maison-neue-medium text-neutral-800 text-16-20'}>Site Domain</p>
          <p className={'font-maison-neue text-neutral-600 text-14-18 mt-[4px]'}>
            The URL of your direct booking site. Use your own domain or personalize a Dtravel-hosted one.
          </p>
          {/*<p className={'font-maison-neue text-neutral-600 text-14-18'}>*/}
          {/*  {!isEmpty(userCustomUrl)*/}
          {/*    ? userCustomUrl?.type === DOMAIN_TYPE.SELF_HOSTED*/}
          {/*      ? `https://${convertDomain(selfHostedDomainData?.customId)}`*/}
          {/*      : `https://${subdomain}.${getDomain()}`*/}
          {/*    : 'Use your own domain or personalize your Dtravel-hosted domain. We recommend something relatively short and easy to read.'}*/}
          {/*</p>*/}
        </div>
        <BasicButton size={'md'} variant={'outlined'} clases={'w-full md:w-auto'} isRadius100 onClick={handleOpen}>
          {!isEmpty(userCustomUrl) ? 'Update' : 'Add'}
        </BasicButton>

        <BasicDialog
          isOpen={isOpenPopup}
          title={'Site Domain'}
          extraTitle={
            'This is the link you will share with guests to book with you directly. Choose the one you want to set primary'
          }
          onClose={handleClose}
          allowBackdropClick
          titleAlign={'center'}
          classContainer={showOtherPopup ? 'opacity-[0]' : 'opacity-[1]'}
        >
          <div className={'flex flex-col justify-between gap-[12px] rounded-[16px]'}>
            {renderRadioItem('dtravel')}
            {renderRadioItem('self')}
          </div>
          <div className={'flex flex-col gap-[8px] items-center mt-[24px]'}>
            {renderCustomDomain()}

            <div className={'flex flex-col md:flex-row justify-between gap-[8px] ml-auto whitespace-nowrap w-full'}>
              <BasicButton
                variant={'outlined'}
                size={'md'}
                clases={'w-full h-[48px] rounded-[100px_!important]'}
                disabled={
                  loadingPrimary ||
                  (isSelfHosted &&
                    (userCustomUrl?.type === DOMAIN_TYPE.SELF_HOSTED ||
                      isEmpty(selfHostedDomainData) ||
                      selfHostedDomainData?.status === 'ssl_pending')) ||
                  (!isSelfHosted && (userCustomUrl?.type === DOMAIN_TYPE.CUSTOM || isEmpty(dtravelDomainData)))
                }
                onClick={handleSetPrimaryUrl}
                loading={loadingPrimary}
              >
                Set as primary
              </BasicButton>
              <RemoveUrl
                disabled={
                  (isSelfHosted && isEmpty(selfHostedDomainData)) || (!isSelfHosted && isEmpty(dtravelDomainData))
                }
                id={isSelfHosted ? selfHostedDomainData?.id : dtravelDomainData?.id}
                handleTriggerOtherPopup={handleTriggerOtherPopup}
                handleSuccess={handleClose}
              />
            </div>
          </div>
          {showConnectSelfHosted && (
            <div className="flex flex-col items-center border-t border-t-neutral-300 mt-[24px] pt-[24px]">
              <p className="w-full font-maison-neue text-14-18 text-neutral-600 mb-[12px] lg:mb-6 pr-0 lg:pr-[32px]">
                You can connect your domain to your Dtravel booking site manually or automatically. The automatic
                connection is only applicable for certain domain providers.
              </p>
              <div className="w-full flex flex-col justify-between gap-[8px]">
                <BasicButton
                  size={'md'}
                  variant={'contained'}
                  clases={'w-full lg:w-auto whitespace-nowrap h-[48px] rounded-[100px_!important]'}
                  onClick={handleVerify}
                  loading={loadingVerifyConnection}
                >
                  {`Verify connection`}
                </BasicButton>
                <div className="w-full flex flex-col md:flex-row justify-between gap-[8px]">
                  <CustomUrlSelfHosted
                    selfHostedDomain={selfHostedDomain}
                    disabled={
                      selfHostedDomain === convertDomain(selfHostedDomainData?.customId) || isEmpty(selfHostedDomain)
                    }
                    handleSuccess={handleVerifySuccess}
                    handleTriggerOtherPopup={handleTriggerOtherPopup}
                  />
                  <BasicButton
                    variant={'outlined'}
                    size={'md'}
                    clases={'w-full md:w-1/2 whitespace-nowrap h-[48px] rounded-[100px_!important]'}
                    disabled={!dnsInfo?.isSupportAutoConnect || loadingConnectAuto}
                    loading={loadingConnectAuto}
                    onClick={() => {
                      if (dnsInfo?.applyUrl) {
                        setLoadingConnectAuto(true)
                        const popup = window.open(
                          dnsInfo?.applyUrl,
                          '_blank',
                          `height=${isMobile ? '480' : '750'},width=${
                            isMobile ? '360' : '750'
                          },modal=yes,alwaysRaised=yes,toolbar=0,location=0,menubar=0`
                        )
                        if (popup) {
                          if (interval) clearInterval(interval)
                          interval = setInterval(() => {
                            if (popup.closed) {
                              clearInterval(interval)
                              setLoadingConnectAuto(false)
                              const connectManuallyEL = document.getElementById('connect_auto_btn')
                              if (connectManuallyEL) connectManuallyEL.click()
                            }
                          }, 500)
                        }
                      }
                    }}
                  >
                    Connect automatically
                  </BasicButton>
                </div>
              </div>
            </div>
          )}
        </BasicDialog>
      </div>
      {!isEmpty(userCustomUrl) && (
        <div className={'mt-[24px]'}>
          <BasicInput value={getCustomUrl()} disabled={true} showIconCopy={true} onCopy={onCopy} />
        </div>
      )}
    </div>
  )
}

export default SettingSiteCustomUrl
