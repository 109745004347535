import { renderNumberOfGuest } from '../../utils/reservation'
import { checkIsNewHost } from '../../utils/user'
import { IconCalendarCheck } from '../common/Icons'
import RenderCryptoCurrency from '../common/RenderCryptoCurrency'
import RenderReservationStatus from '../reservation/RenderReservationStatus'
import BasicButton from '../ui/BasicButton'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getReservations } from 'src/api/integration'
import ic_arrow_right from 'src/assets/icons/ic_arrow_right.svg'
import ic_chevron_right from 'src/assets/icons/ic_chevron_right.svg'
import reservation_thumb from 'src/assets/images/reservation_thumb.jpg'
import BasicCardV2 from 'src/components/common/BasicCardV2'
import { ServerResponse } from 'src/interfaces'
import { ReservationInfo } from 'src/interfaces/reservations'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setLoading } from 'src/redux/slices/common'
import { isEmpty, renderTimeMobileTimezone } from 'src/utils/common'

interface Props {}

const HomeReservations: React.FC<Props> = () => {
  const dispatch = useAppDispatch()
  const [data, setData] = useState<ReservationInfo[]>([])
  const { profile } = useAppSelector((state) => state.user)
  const isNewUser = checkIsNewHost(profile?.user)

  useEffect(() => {
    async function fetchData() {
      try {
        dispatch(setLoading(true))
        const res: ServerResponse = await getReservations({ page: 1, pageSize: 3 })
        const { data } = res
        setData(data)
        // group by today and months
      } catch (err) {
        console.log(err)
      } finally {
        dispatch(setLoading(false))
      }
    }
    fetchData()
  }, [])

  if (isNewUser)
    return (
      <BasicCardV2
        title={'Recent Reservations'}
        extraTitle={
          isEmpty(data) ? null : (
            <BasicButton
              endIcon={<img src={ic_arrow_right} alt="" style={{ width: 16, height: 16 }} />}
              size={'sm'}
              href={'/reservations'}
              isRadius100={true}
              style={{ height: '24px' }}
            >
              <span className={'font-maison-neue text-14-18 text-neutral-800'}>Show all</span>
            </BasicButton>
          )
        }
        wrapperClass={'mb-0'}
      >
        <>
          {isEmpty(data) ? (
            <div className={'w-full h-[228px] lg:h-[402px] flex items-center justify-center flex-col gap-[8px]'}>
              <IconCalendarCheck />
              <p className={'font-maison-neue text-16-20 text-neutral-400'}>No reservations yet</p>
            </div>
          ) : (
            <div className="flex flex-col md:gap-[12px]">
              {data.map((el: ReservationInfo, index: number) => {
                return (
                  <div
                    key={index}
                    className={`w-full ${
                      index === 0
                        ? 'pt-0 pb-[16px] md:p-0'
                        : index === data.length - 1
                        ? 'pt-[16px] pb-0 md:p-0'
                        : 'py-[16px] md:p-0'
                    } ${index !== data.length - 1 ? 'shadow-[0px_0.5px_0px_0px_rgba(0,0,0,0.15)] md:shadow-none' : ''}`}
                  >
                    <Link to={`/reservations/${el.reservationId}`} className={'flex items-start gap-[16px]'}>
                      <div className="w-[64px] md:w-[102px] min-w-[64px] md:min-w-[102px] h-[64px] md:h-[102px]">
                        <img
                          className={
                            'w-[64px] md:w-[102px] min-w-[64px] md:min-w-[102px] h-[64px] md:h-[102px] object-cover rounded-[16px]'
                          }
                          src={el.listingInfo.thumbnailUrl || reservation_thumb}
                          alt={el.listingInfo.name}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null // prevents looping
                            currentTarget.src = reservation_thumb
                          }}
                        />
                      </div>
                      <div className="flex flex-col gap-[8px] flex-1">
                        <div className={'flex justify-between w-full'}>
                          <p
                            className={
                              'font-maison-neue text-neutral-800 text-14-18 text-ellipsis overflow-hidden whitespace-nowrap md:overflow-auto md:whitespace-pre-wrap'
                            }
                          >
                            {renderTimeMobileTimezone(el.checkinDate, el.checkoutDate, el.timezone)}
                          </p>
                          <div className={'ml-auto line-clamp-1'}>
                            <RenderReservationStatus status={el.status} />
                          </div>
                        </div>
                        <div>
                          <p className={'font-maison-neue-medium text-16-20 text-neutral-800'}>
                            {el.listingInfo?.internalName}
                          </p>
                          <p className={'font-maison-neue text-14-18 text-neutral-800'}>
                            <span>{el?.guestInfo?.name ? el?.guestInfo?.name + ', ' : ''}</span>
                            <span>{renderNumberOfGuest(el.adults, el.children, el.infants, el.pets)}</span>
                          </p>
                        </div>
                        <div>
                          <p className="font-maison-neue text-14-18 text-neutral-800">
                            <RenderCryptoCurrency price={Number(el.finalPrice)} currency={el.currency} isDynamic />
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              })}
            </div>
          )}
        </>
      </BasicCardV2>
    )

  return (
    <BasicCardV2
      title={'Reservations'}
      extraTitle={
        isEmpty(data) ? null : (
          <BasicButton
            endIcon={<img src={ic_arrow_right} alt="" style={{ width: 16, height: 16 }} />}
            size={'sm'}
            href={'/reservations'}
            isRadius100={true}
          >
            <span className={'font-maison-neue text-14-18 text-neutral-600'}>Show all</span>
          </BasicButton>
        )
      }
    >
      <>
        {isEmpty(data) ? (
          <>
            <p className="text-16-24 text-grey mt-3 mb-6">
              You don’t have any reservations yet. Go to Discord for help getting your first booking.
            </p>
            <a href="https://discord.com/invite/dtravel" target="_blank" rel="noreferrer">
              <button className="text-16-18 px-4 py-3 rounded-xl border border-solid border-grey-700 w-full md:w-auto">
                Get help in Discord
              </button>
            </a>
          </>
        ) : (
          <div className="flex flex-col md:flex-row md:gap-[12px]">
            {data.map((el: ReservationInfo, index: number) => {
              return (
                <div
                  key={index}
                  className={`w-full md:w-1/3 p ${
                    index === 0
                      ? 'pt-0 pb-[16px] md:p-0'
                      : index === data.length - 1
                      ? 'pt-[16px] pb-0 md:p-0'
                      : 'py-[16px] md:p-0'
                  } ${index !== data.length - 1 ? 'shadow-[0px_0.5px_0px_0px_rgba(0,0,0,0.15)] md:shadow-none' : ''}`}
                >
                  <Link
                    to={`/reservations/${el.reservationId}`}
                    className={'flex items-center justify-items-start gap-[12px] md:flex-col md:items-start'}
                  >
                    <div className="w-[54px] min-w-[54px] h-[54px] md:w-full md:h-[140px] md:max-h-[140px]">
                      <img
                        className={
                          'w-[54px] min-w-[54px] h-[54px] md:w-full md:h-full md:max-h-[140px] object-cover rounded-2xl'
                        }
                        src={el.listingInfo.thumbnailUrl || reservation_thumb}
                        alt={el.listingInfo.name}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src = reservation_thumb
                        }}
                      />
                    </div>
                    <div className="flex flex-col gap-[2px] md:gap-[4px]">
                      <div className={'text-10-12'}>
                        <RenderReservationStatus status={el.status} />
                      </div>
                      <div>
                        <p
                          className={
                            'font-maison-neue text-neutral-700 text-16-20 text-ellipsis overflow-hidden whitespace-nowrap md:overflow-auto md:whitespace-pre-wrap'
                          }
                        >
                          {renderTimeMobileTimezone(el.checkinDate, el.checkoutDate, el.timezone)}
                        </p>
                        <p className="font-maison-neue text-12-16 text-neutral-600">
                          <RenderCryptoCurrency price={Number(el.finalPrice)} currency={el.currency} isDynamic />
                        </p>
                      </div>
                    </div>
                    <span className="pc-hidden flex-grow flex justify-end">
                      <img className={''} src={ic_chevron_right} alt="ic_chevron_right" style={{ minWidth: 24 }} />
                    </span>
                  </Link>
                </div>
              )
            })}
          </div>
        )}
      </>
    </BasicCardV2>
  )
}

export default HomeReservations
