import { getStepSettings, getUserProfile, updateUserProfile } from '../api/user'
import {
  MAPPING_SETUP_GUIDE_TO_SETTINGS_TAB_V2,
  SETUP_GUIDE_STEP_LIST_BY_INTEREST,
  SETUP_GUIDE_V2,
} from '../contants/common'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { setIsSurveySubmitted, setLoading } from '../redux/slices/common'
import { getUserInfo, saveUserInfo } from '../utils/user'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const useSetupGuide = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { profile, settingData } = useAppSelector((state) => state.user)
  const { isSurveySubmitted } = useAppSelector((state) => state.common)
  const [data, setData] = useState<any>()
  const [stepProgress, setStepProgress] = useState<any>({})
  const [stepList, setStepList] = useState<any[]>([])

  useEffect(() => {
    fetchStepSetting()
  }, [profile])

  useEffect(() => {
    if (settingData && data) {
      const interest = settingData.interest
      const isUseSearchEngine = interest === 'list_on_meta_search_engines'
      // Customize your landing page: step is completed when Hero image, Headline and Subheadline, About us, Photo and Bio are set up
      const { gallery, headline, subHeadline, bio, aboutUsImage } = settingData
      const isFinishLandingPage = !!gallery && !!headline && !!subHeadline && !!bio && !!aboutUsImage
      const progressDbs = {
        [SETUP_GUIDE_V2.IMPORT_LISTING]: data?.isImportedListing,
        [SETUP_GUIDE_V2.ADD_A_SITE_DETAIL]: data?.isHaveSiteName && data?.isHaveCustomUrl,
        [SETUP_GUIDE_V2.CUSTOMIZE_YOUR_LANDING_PAGE]: isFinishLandingPage,
        [SETUP_GUIDE_V2.CUSTOMIZE_YOUR_WEBSITE]: data?.isHavelogo,
        [SETUP_GUIDE_V2.ADD_CONTACT_INFO]: data?.isHaveContactInfo,
        [SETUP_GUIDE_V2.CONNECT_A_STRIPE_ACCOUNT]: data?.isConnectedStripe,
        [SETUP_GUIDE_V2.CREATE_A_DTRAVEL_SMARTWALLET]: data?.isConnectedWallet,
        [SETUP_GUIDE_V2.ADD_EXTERNAL_ACCOUNTS]: data?.isHaveSocialMedia,
        [SETUP_GUIDE_V2.CREATE_A_MANUAL_RESERVATION]: data?.isHaveReservationManual,
      }
      const progressSearchEngine = {
        [SETUP_GUIDE_V2.IMPORT_LISTING]: data?.isImportedListing,
        [SETUP_GUIDE_V2.ADD_A_SITE_DETAIL]: data?.isHaveSiteName && data?.isHaveCustomUrl,
        [SETUP_GUIDE_V2.CONNECT_A_STRIPE_ACCOUNT]: data?.isConnectedStripe,
        [SETUP_GUIDE_V2.CREATE_A_DTRAVEL_SMARTWALLET]: data?.isConnectedWallet,
        [SETUP_GUIDE_V2.ADD_CONTACT_INFO]: data?.isHaveContactInfo,
      }
      setStepList(
        isUseSearchEngine
          ? SETUP_GUIDE_STEP_LIST_BY_INTEREST.LIST_ON_META_SEARCH_ENGINES
          : SETUP_GUIDE_STEP_LIST_BY_INTEREST.CREATE_DIRECT_BOOKING
      )
      setStepProgress(isUseSearchEngine ? progressSearchEngine : progressDbs)
    }
  }, [settingData, data])

  const onClickStepItem = (key: string) => {
    if (key === SETUP_GUIDE_V2.IMPORT_LISTING) {
      navigate('/listings')
    } else if (key === SETUP_GUIDE_V2.CREATE_A_MANUAL_RESERVATION) {
      navigate('/create-reservation')
    } else {
      const tab = MAPPING_SETUP_GUIDE_TO_SETTINGS_TAB_V2[key.toUpperCase()]
      navigate('/settings?tab=' + `${tab}#${key}`)
    }
  }

  const onClickDismiss = async () => {
    try {
      dispatch(setLoading(true))
      const user: any = getUserInfo()
      await updateUserProfile({ gettingStarted: { ...user?.gettingStarted, isSurveySubmitted: true } })
      const res: any = await getUserProfile()
      saveUserInfo({ ...user, ...res.data })
      dispatch(setIsSurveySubmitted(true))
    } finally {
      dispatch(setLoading(false))
    }
  }

  const fetchStepSetting = async () => {
    try {
      const { data }: any = await getStepSettings()
      if (data) {
        setData(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {
    stepList: stepList,
    stepProgress,
    onClickStepItem,
    onClickDismiss,
    isSurveySubmitted,
  }
}

export default useSetupGuide
