import IconButton from '@mui/material/IconButton'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { updateHostSettings, uploadSettingImages } from 'src/api/user'
import ic_media_40_40 from 'src/assets/icons/ic_media_40_40.svg'
import ic_trash_empty from 'src/assets/icons/ic_trash_empty.svg'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import BasicTextarea from 'src/components/ui/BasicTextarea'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { setSettingData } from 'src/redux/slices/user'
import { handleErrorMessage, validateWidthHeightImage } from 'src/utils/common'

interface Props {}

const SettingAboutUs: React.FC<Props> = () => {
  const dispatch = useAppDispatch()
  const { settingData } = useAppSelector((state) => state.user)
  const inputPhoto = useRef<HTMLInputElement | null>(null)
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [bio, setBio] = useState<string>('')
  const [aboutUsImage, setAboutUsImage] = useState<string>('')
  const [isHover, setIsHover] = useState(false)

  useEffect(() => {
    setBio(settingData?.bio || '')
    setAboutUsImage(settingData?.aboutUsImage || '')
  }, [settingData?.bio, settingData?.aboutUsImage, isOpenPopup])

  const onChangeBio = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBio(event.target.value)
  }

  const handleOpen = () => {
    setIsOpenPopup(true)
  }

  const handleClose = () => {
    setIsOpenPopup(false)
  }

  const handleUpload = async (e: any) => {
    const file: File = e.target.files[0]
    const isValid = await validateWidthHeightImage(file, 1280, 720)
    if (!isValid) {
      handleErrorMessage({ message: 'The photo must be at least 1280x720.' })
      return
    }
    if (file) {
      try {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('file', file)
        const res = await uploadSettingImages('aboutUs', formData)
        if (res.success) {
          setAboutUsImage(res.data)
        }
      } catch (err: any) {
        handleErrorMessage(err)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleSave = async () => {
    setIsLoading(true)
    try {
      const data: any = { bio }
      if (aboutUsImage) {
        data.aboutUsImage = aboutUsImage
      }
      const res = await updateHostSettings(data)
      if (res.success && res.data) {
        dispatch(setToastSuccess({ message: 'Update success!' }))
        dispatch(setSettingData({ ...settingData, ...res.data }))
        handleClose()
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }
  const isHasData = settingData.bio || settingData.aboutUsImage

  const renderAddButton = () => (
    <BasicButton size={'md'} variant={'outlined'} clases={'w-full md:w-auto'} isRadius100 onClick={handleOpen}>
      {isHasData ? 'Update' : 'Add'}
    </BasicButton>
  )

  return (
    <>
      <div className={'flex flex-col md:flex-row justify-between items-start gap-[12px]'}>
        <div>
          <p className={'font-maison-neue-medium text-neutral-800 text-16-20'}>About Us</p>
          <p className={'font-maison-neue text-neutral-600 text-14-18 mt-[4px]'}>
            Tell your guests what makes you and your business unique. We recommend between 600-700 characters.
          </p>
        </div>
        <div className={'hidden md:block'}>{renderAddButton()}</div>

        <BasicDialog
          isOpen={isOpenPopup}
          title={'About Us'}
          extraTitle={'Telling Your Story'}
          onClose={handleClose}
          allowBackdropClick
          titleAlign={'center'}
        >
          <BasicTextarea
            label={'Bio'}
            placeholder={'Write your bio here'}
            value={bio}
            onChange={onChangeBio}
            onBlur={() => setBio((prevState) => prevState.trim())}
            maxLength={800}
            rows={6}
            showCount
          />

          <div className={'mt-[24px]'}>
            <p className={'font-maison-neue-medium text-14-18 text-neutral-800'}>Photo</p>
            {aboutUsImage ? (
              <div
                className={'relative w-[160px] h-[120px] mt-[8px]'}
                onFocus={() => setIsHover(true)}
                onMouseOver={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
              >
                <img
                  src={aboutUsImage}
                  alt="hero"
                  className="min-w-[160px] w-[160px] h-[120px] rounded-[8px] object-cover"
                />
                <div
                  className={
                    'flex items-center justify-center bg-[#000000] absolute opacity-60 absolute top-0 w-full h-full rounded-[8px] ' +
                    `${isHover ? '' : 'hidden'}`
                  }
                >
                  <IconButton size={'small'} onClick={() => setAboutUsImage('')}>
                    <img src={ic_trash_empty} alt={'ic_trash_empty'} />
                  </IconButton>
                </div>
              </div>
            ) : (
              <button
                className={clsx(
                  'flex justify-center items-center border border-dashed border-neutral-800 w-full h-[128px] rounded-[12px] shadow-basic mt-[8px]'
                )}
                style={{ border: '0.5px dashed rgba(0, 0, 0, 0.15)' }}
                onClick={() => {
                  const inputEle = inputPhoto.current as HTMLInputElement
                  inputEle.click()
                }}
              >
                <img src={ic_media_40_40} alt={'ic_media'} className={''} />
              </button>
            )}
            <input
              ref={inputPhoto}
              type={'file'}
              multiple={true}
              accept={'image/*'}
              className={'opacity-0 w-full h-full z-50 hidden'}
              onChange={handleUpload}
              onClick={() => {
                const element = inputPhoto.current as HTMLInputElement
                if (element) element.value = ''
              }}
            />
          </div>

          <BasicButton
            variant={'contained'}
            color={'red'}
            clases={'w-full mt-[24px]'}
            isRadius100={true}
            onClick={handleSave}
            loading={isLoading}
            disabled={!bio || !aboutUsImage}
          >
            Save
          </BasicButton>
        </BasicDialog>
      </div>

      {isHasData && (
        <div
          className={
            'mt-[12px] md:mt-[24px] bg-neutral-50 rounded-[16px] p-[16px] md:p-[24px] flex flex-col md:flex-row items-start md:items-center gap-[16px] md:gap-[24px]'
          }
        >
          <div>
            {settingData.aboutUsImage && (
              <img
                src={settingData.aboutUsImage}
                alt="aboutUsImage"
                className="min-w-[160px] w-[160px] h-[120px] rounded-[8px] object-cover"
              />
            )}
          </div>
          <div className={''}>
            <p className={'font-maison-neue text-neutral-600 text-14-18 break-all'}>{settingData.bio}</p>
          </div>
        </div>
      )}

      <div className={'block md:hidden w-full mt-[12px]'}>{renderAddButton()}</div>
    </>
  )
}

export default SettingAboutUs
