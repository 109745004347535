import LayoutV2 from '../layout/LayoutV2'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()

  const backHome = () => {
    navigate('/')
  }

  return (
    <LayoutV2 showDefaultHeader={true}>
      <div className={'w-full text-center'}>
        <p className={'mb-8 text-24-32 font-medium'}>404 Not found</p>
        <button className={'bg-black text-white rounded-lg px-4 py-2'} onClick={backHome}>
          Back Home
        </button>
      </div>
    </LayoutV2>
  )
}

export default NotFound
