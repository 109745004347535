import { PMS, PMS_TYPE } from '../../contants/common'
import BasicTooltip from '../ui/BasicTooltip'
import React from 'react'

interface Props {
  property: any
}

const RenderSyncStatus: React.FC<Props> = ({ property }) => {
  const isDtravelListing =
    property?.pmsType === PMS.DTRAVEL && property?.source === 'native' && property?.channel === 'native'
  if (!property || isDtravelListing) {
    return <></>
  }
  // Red dot:
  // - Channex listings
  // - Disconnected HC listings
  // DTRAV-4236
  const isConnectChannex = property?.channel === PMS_TYPE.AIRBNB

  if (
    isConnectChannex ||
    (property?.channel === PMS_TYPE.HOSPITABLE && (!property.isConnected || property.rawData?.lastSync === 'fail'))
  ) {
    return <div className={'w-[6px] h-[6px] bg-red-700 rounded-full'} />
  }

  return (
    <BasicTooltip
      title={'Sync connection active'}
      arrow={true}
      sx={{
        '& .MuiTooltip-tooltip': {
          color: '#FFFFFF',
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '18px',
          padding: '8px 12px',
          borderRadius: '8px',
        },
      }}
    >
      <div className={'w-[6px] h-[6px] bg-forest-700 rounded-full'} />
    </BasicTooltip>
  )
}

export default RenderSyncStatus
