import { getFeeOptions, updateFeeOptions } from '../../../api/integration'
import { SETTINGS_SIDE_BAR } from '../../../contants/common'
import { setToastSuccess } from '../../../redux/slices/common'
import { handleErrorMessage } from '../../../utils/common'
import BasicRadio from '../../ui/BasicRadio'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const SettingFeeOptions = () => {
  const dispatch = useDispatch()
  const [isGuestPayFee, setIsGuestPayFee] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    async function fetchFeeOptions() {
      try {
        setIsLoading(true)
        const res = await getFeeOptions()
        const { data } = res
        if (data) {
          setIsGuestPayFee(data.isGuestPayFee)
        } else {
          setIsGuestPayFee(true)
        }
      } finally {
        setIsLoading(false)
      }
    }

    fetchFeeOptions()
  }, [])

  const onChangeChargedFee = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const _isGuestPayFee = event.target.value === 'yes'
    setIsGuestPayFee(_isGuestPayFee)
    try {
      setIsLoading(true)
      await updateFeeOptions(_isGuestPayFee)
      dispatch(setToastSuccess({ message: 'Update fee options success!' }))
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <h2 id={SETTINGS_SIDE_BAR.FEE_OPTIONS} className={'font-maison-neue-medium text-20-32 text-neutral-800'}>
        Fee Options
      </h2>
      <p className={'font-maison-neue text-16-20 text-neutral-500 mt-[4px]'}>
        Charge guests an extra 2.9% + $0.30 fee for credit card payments? Please note: Stripe will deduct this fee from
        your payout regardless.
      </p>
      <div className={'relative'}>
        <div className={'flex flex-col lg:flex-row justify-between gap-[12px] rounded-[16px] mt-[16px]'}>
          <label
            className={
              'p-[24px] rounded-[16px] flex gap-[4px] items-center justify-between w-full cursor-pointer bg-white ' +
              `${isGuestPayFee ? 'border-[2px] border-neutral-800' : 'border-[1px] border-neutral-300'}`
            }
            htmlFor={'setting_fee_yes'}
          >
            <div>
              <p
                className={`font-maison-neue-medium text-16-20 ${isGuestPayFee ? 'text-neutral-8' : 'text-neutral-4'}`}
              >
                Yes
              </p>
              <p className={'font-maison-neue text-14-18 text-neutral-6 mt-[4px]'}>
                Guests will be charged an extra fee for credit/debit card payments.
              </p>
            </div>
            <div>
              <BasicRadio
                id={'setting_fee_yes'}
                name={'fee_options'}
                value={'yes'}
                checked={isGuestPayFee}
                onChange={onChangeChargedFee}
              />
            </div>
          </label>

          <label
            className={
              'p-[24px] rounded-[16px] flex gap-[4px] items-center justify-between w-full cursor-pointer bg-white ' +
              `${isGuestPayFee ? 'border-[1px] border-neutral-300' : 'border-[2px] border-neutral-800'}`
            }
            htmlFor={'setting_fee_no'}
          >
            <div>
              <p
                className={`font-maison-neue-medium text-16-20 ${isGuestPayFee ? 'text-neutral-4' : 'text-neutral-8'}`}
              >
                No
              </p>
              <p className={'font-maison-neue text-14-18 text-neutral-6 mt-[4px]'}>
                Guests will not be charged an extra fee for credit/debit card payments.
              </p>
            </div>
            <div>
              <BasicRadio
                id={'setting_fee_no'}
                name={'fee_options'}
                value={'no'}
                checked={!isGuestPayFee}
                onChange={onChangeChargedFee}
              />
            </div>
          </label>
        </div>
        {isLoading && (
          <div
            className={
              'absolute w-full h-full top-[30px] bottom-0 left-0 right-0 z-10 flex items-center justify-center opacity-30 cursor-not-allow'
            }
          >
            <CircularProgress size={50} style={{ color: '#0B2336' }} />
          </div>
        )}
      </div>
    </div>
  )
}

export default SettingFeeOptions
