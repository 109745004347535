import { updateBulkProperties, updateDiscountSetting, updateProperty } from '../../../api/native-listing'
import { NATIVE_LISTING_MENU } from '../../../contants/common'
import { PROPERTY_STATUS } from '../../../contants/native'
import { PropertyDetailProps } from '../../../interfaces/listing'
import { setLoading, setToastSuccess } from '../../../redux/slices/common'
import { handleErrorMessage, isEmpty } from '../../../utils/common'
import BasicInputPrice from '../../ui/BasicInputPrice'
import BasicSwitch from '../../ui/BasicSwitch'
import NativeListingBackAndContinue from '../NativeListingBackAndContinue'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormError } from 'src/interfaces'
import DisabledSectionOnEdit from 'src/components/common/DisabledSectionOnEdit'
import { useAppSelector } from 'src/redux/hooks'
import { setBulkListingData, setBulkListingSection } from 'src/redux/slices/listing'

interface Props {
  propertyId: string
  listingData: PropertyDetailProps | null
  fetchStatusStep: any
}

const NativeListingDiscounts: React.FC<Props> = ({ propertyId, listingData, fetchStatusStep }) => {
  const { bulkListingData, bulkListingSection } = useAppSelector((state) => state.listing)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isOnboarding = parseQuery?.f === 'onboarding'
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const isDraft = listingData?.status === PROPERTY_STATUS.DRAFT
  const [isEnable, setIsEnable] = useState<{ weekly: boolean; monthly: boolean }>({
    weekly: false,
    monthly: false,
  })
  const [weeklyDiscount, setWeeklyDiscount] = useState<number | string | undefined>()
  const [monthlyDiscount, setMonthlyDiscount] = useState<number | string | undefined>()
  const [errors, setErrors] = useState<{ weekly: FormError; monthly: FormError }>({
    weekly: { message: '', show: false },
    monthly: { message: '', show: false },
  })
  const [toggleEdit, setToggleEdit] = useState<any>({ weekly: false, monthly: false })

  // eslint-disable-next-line
  // const isHospitable = listingData?.pmsType === PMS_TYPE.HOSPITABLE

  useEffect(() => {
    if (listingData && listingData.propertyPrice) {
      const propertyPrice = listingData.propertyPrice
      const { weeklyPriceFactor, monthlyPriceFactor } = propertyPrice
      setIsEnable({ weekly: !!weeklyPriceFactor, monthly: !!monthlyPriceFactor })
      if (weeklyPriceFactor) {
        const reverseValue = ((1 - Number(weeklyPriceFactor)) * 100).toFixed(0)
        setWeeklyDiscount(Number(reverseValue))
      }
      if (monthlyPriceFactor) {
        const reverseValue = ((1 - Number(monthlyPriceFactor)) * 100).toFixed(0)
        setMonthlyDiscount(Number(reverseValue))
      }
    }
  }, [listingData])
  useEffect(() => {
    if (!isEmpty(bulkListingData) && isEditBulk) {
      setWeeklyDiscount(bulkListingData?.weeklyDiscount)
      setMonthlyDiscount(bulkListingData?.monthlyDiscount)
    }
  }, [bulkListingData])
  const handleSubmit = async (isExit?: boolean) => {
    try {
      let isValid = true
      let _errors = { weekly: { message: '', show: false }, monthly: { message: '', show: false } }
      if (isEnable.weekly) {
        if (!weeklyDiscount) {
          _errors.weekly = { show: true, message: 'Please enter valid discount' }
          isValid = false
        }
        const isInteger = Number.isInteger(Number(weeklyDiscount))
        if (!isInteger || Number(weeklyDiscount) < 0 || Number(weeklyDiscount) > 100) {
          _errors.weekly = { show: true, message: 'Discount must be an integer value between 1 and 100' }
          isValid = false
        }
      }
      if (isEnable.monthly) {
        if (!monthlyDiscount) {
          _errors.monthly = { show: true, message: 'Please enter valid discount' }
          isValid = false
        }
        const isInteger = Number.isInteger(Number(monthlyDiscount))
        if (!isInteger || Number(monthlyDiscount) < 0 || Number(monthlyDiscount) > 100) {
          _errors.weekly = { show: true, message: 'Discount must be an integer value between 1 and 100' }
          isValid = false
        }
      }
      if (!isValid) {
        setErrors(_errors)
        return
      }

      dispatch(setLoading(true))
      let bodyData: any = { discountSettings: [] }
      let res: any = null
      if (isEditBulk) {
        if (toggleEdit.weekly || toggleEdit.monthly) {
          const propertyIds = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
          if (toggleEdit.weekly) {
            bodyData.discountSettings.push({
              type: 'weekly',
              value: isEnable.weekly ? Number(weeklyDiscount) / 100 : null,
            })
          }
          if (toggleEdit.monthly) {
            bodyData.discountSettings.push({
              type: 'monthly',
              value: isEnable.monthly ? Number(monthlyDiscount) / 100 : null,
            })
          }
          dispatch(setBulkListingData({ ...bulkListingData, weeklyDiscount, monthlyDiscount }))
          res = await updateBulkProperties({ propertyIds, discountSettings: bodyData.discountSettings })
          setToggleEdit({ weekly: false, monthly: false })
        }
      } else {
        if (isEnable.weekly) {
          bodyData.discountSettings.push({
            type: 'weekly',
            value: Number(weeklyDiscount) / 100,
          })
        }
        if (isEnable.monthly) {
          bodyData.discountSettings.push({
            type: 'monthly',
            value: Number(monthlyDiscount) / 100,
          })
        }
        res = await updateDiscountSetting(propertyId, bodyData)
        if (isDraft) {
          await updateProperty(propertyId, { settingSteps: 11 })
        }
      }
      if (res?.data) {
        if (isExit) {
          navigate(isOnboarding ? '/' : `/listings`)
        } else if (listingData?.status === PROPERTY_STATUS.DRAFT) {
          navigate(`/native-listing/${NATIVE_LISTING_MENU.RATES}` + search)
        } else {
          dispatch(setToastSuccess({ message: 'Your changes have been applied.' }))
        }
        fetchStatusStep()
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const onOnOffDiscount = (type: 'weekly' | 'monthly', checked: boolean) => {
    setIsEnable((prevState) => ({ ...prevState, [type]: checked }))
    setErrors((prevState) => ({ ...prevState, [type]: { show: false, message: '' } }))
  }

  const onChangeDisount = (type: 'weekly' | 'monthly', value: number) => {
    let _val = Number.isNaN(value) ? '' : value
    if (_val > 100) {
      const str = String(_val).slice(0, -1) // prevent input > 100
      _val = Number(str)
    }
    if (type === 'weekly') {
      setWeeklyDiscount(_val)
    } else {
      setMonthlyDiscount(_val)
    }
    setErrors((prevState) => ({ ...prevState, [type]: { show: false, message: '' } }))
  }

  return (
    <>
      <div className="flex flex-col">
        <h2 className="text-28-36 text-neutral-800 font-maison-neue tracking-[-0.28px] mb-[32px]">
          Offer additional discounts
        </h2>

        <DisabledSectionOnEdit
          title="Weekly discount"
          subTitle='Applies to reservations that are 7+ nights'
          openEdit={toggleEdit.weekly}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, weekly: value })}
        >
          <div className="p-[24px] bg-white w-full mb-[32px] rounded-[16px]">
            <div className={'w-full flex flex-row gap-[16px] items-start justify-between'}>
              <div className={'w-full md:w-[58%]'}>
                <label htmlFor={'weekly_discount'} className={'flex items-center gap-[16px] h-[48px]'}>
                  <BasicSwitch
                    id={'weekly_discount'}
                    checkedColor="#292926"
                    checked={isEnable.weekly}
                    onChange={(event) => onOnOffDiscount('weekly', event.target.checked)}
                  />
                  <span className={'font-maison-neue text-16-20 text-neutral-800'}>Weekly discount</span>
                </label>
              </div>
              <div className={'text-right'}>
                <BasicInputPrice
                  value={weeklyDiscount}
                  onValueChange={(values) => onChangeDisount('weekly', values.floatValue)}
                  clases={'min-w-[120px] max-w-[120px] pr-[36px] text-right'}
                  disabled={!isEnable.weekly}
                  error={errors.weekly}
                  endAdornment={
                    <span
                      className={`absolute right-[12px] bottom-[4px] -translate-y-1/2 font-maison-neue text-16-20 text-neutral-500 ${isEnable.weekly ? '' : 'cursor-not-allowed'
                        }`}
                    >
                      %
                    </span>
                  }
                />
              </div>
            </div>
          </div>
        </DisabledSectionOnEdit>
        <DisabledSectionOnEdit
          title="Monthly discount"
          subTitle='Applies to reservations that are 28+ nights'
          openEdit={toggleEdit.monthly}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, monthly: value })}
        >
          <div className="p-[24px] bg-white w-full mb-[32px] rounded-[16px]">
            <div className={'w-full flex flex-row gap-[16px] items-start justify-between'}>
              <div className={'w-full md:w-[58%]'}>
                <label htmlFor={'monthly_discount'} className={'flex items-center gap-[16px] h-[48px]'}>
                  <BasicSwitch
                    id={'monthly_discount'}
                    checkedColor="#292926"
                    checked={isEnable.monthly}
                    onChange={(event) => onOnOffDiscount('monthly', event.target.checked)}
                  />
                  <span className={'font-maison-neue text-16-20 text-neutral-800'}>Monthly discount</span>
                </label>
              </div>
              <div className={'text-right'}>
                <BasicInputPrice
                  value={monthlyDiscount}
                  onValueChange={(values) => {
                    onChangeDisount('monthly', values.floatValue)
                  }}
                  clases={'min-w-[120px] max-w-[120px] pr-[36px] text-right'}
                  disabled={!isEnable.monthly}
                  error={errors.monthly}
                  endAdornment={
                    <span
                      className={`absolute right-[12px] bottom-[4px] -translate-y-1/2 font-maison-neue text-16-20 text-neutral-500 ${isEnable.weekly ? '' : 'cursor-not-allowed'
                        }`}
                    >
                      %
                    </span>
                  }
                />
              </div>
            </div>
          </div>
        </DisabledSectionOnEdit>
      </div>

      {/*--- Footer: Back and Preview ---*/}
      <NativeListingBackAndContinue
        onSubmit={handleSubmit}
        isEdit={!isDraft}
        isDisabledSubmit={isEditBulk ? !(toggleEdit.weekly || toggleEdit.monthly) : false}
      />
      <div
        id={`${NATIVE_LISTING_MENU.DISCOUNTS}_save_exit`}
        className="hidden"
        onClick={() => handleSubmit(true)}
        role="presentation"
      />
      <div
        id={`${NATIVE_LISTING_MENU.DISCOUNTS}_save`}
        className="hidden"
        onClick={() => {
          if (isEditBulk) {
            if (isEmpty(bulkListingSection) && (toggleEdit.weekly || toggleEdit.monthly)) {
              let result: string[] = []
              if (toggleEdit.weekly) result.push('Weekly discount')
              if (toggleEdit.monthly) result.push('Monthly discount')
              dispatch(setBulkListingSection(result.join(', ')))
            } else handleSubmit()
          } else handleSubmit()
        }}
        role="presentation"
      />
    </>
  )
}

export default NativeListingDiscounts
