import { updateHostSettings, updateUserProfile } from '../../../api/user'
import { UserProfile } from '../../../interfaces/user'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { setLoading, setToastSuccess } from '../../../redux/slices/common'
import { handleErrorMessage, isEmpty, isOnlyLetters } from '../../../utils/common'
import BasicButton from '../../ui/BasicButton'
import BasicDialog from '../../ui/BasicDialog'
import SettingPersonalInfoEmail from './SettingPersonalInfoEmail'
import React, { useEffect, useState } from 'react'
import ic_copy from 'src/assets/icons/ic_copy.svg'
import BasicInput from 'src/components/ui/BasicInput'
import { SETUP_GUIDE } from 'src/contants/common'

interface Props {
  data: UserProfile | null
  settingData: any | null
  fetchUserProfile: () => void
  fetchHostSettings: () => void
}
const defaultErrorMsg = { firstName: '', lastName: '', email: '', legalName: '' }

const SettingAccountlInformation = ({ data, settingData, fetchUserProfile, fetchHostSettings }: Props) => {
  const dispatch = useAppDispatch()
  const { isLoading } = useAppSelector((state) => state.common)
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [legalName, setLegalName] = useState<string>('')
  const [errorMsg, setErrorMsg] = useState(defaultErrorMsg)
  const [isDisabled, setIsDisabled] = useState(false)
  const [open, setOpen] = useState<'name' | 'legalName' | null>(null)

  const clearData = () => {
    setErrorMsg(defaultErrorMsg)
  }
  const handleClose = () => {
    setOpen(null)
    clearData()
  }

  useEffect(() => {
    setLegalName(settingData?.legalName || '')
  }, [settingData])

  useEffect(() => {
    setFirstName(data?.firstName || '')
    setLastName(data?.lastName || '')
    setEmail(data?.user?.email || '')
  }, [data])

  useEffect(() => {
    const hasChange = !data || data?.firstName !== firstName || data?.lastName !== lastName
    setIsDisabled(!hasChange || !firstName || !lastName || !isOnlyLetters(firstName) || !isOnlyLetters(lastName))
  }, [firstName, lastName, email, data])

  const onChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value)
    setErrorMsg((prevState) => ({
      ...prevState,
      firstName: '',
    }))
  }
  const onChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value)
    setErrorMsg((prevState) => ({
      ...prevState,
      lastName: '',
    }))
  }

  const onBlurFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isOnlyLetters(event.target.value)) {
      setErrorMsg((prevState) => ({
        ...prevState,
        firstName: 'Please enter letters only.',
      }))
    }
  }
  const onBlurLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isOnlyLetters(event.target.value)) {
      setErrorMsg((prevState) => ({
        ...prevState,
        lastName: 'Please enter letters only.',
      }))
    }
  }
  const onChangeLegalName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLegalName(event.target.value)
  }

  const onSave = async () => {
    try {
      dispatch(setLoading(true))
      let dataDTO = {}
      if (!isEmpty(firstName)) dataDTO = { ...dataDTO, firstName }
      if (!isEmpty(lastName)) dataDTO = { ...dataDTO, lastName }
      await updateUserProfile(dataDTO)
      setOpen(null)
      dispatch(setToastSuccess({ message: 'success' }))
      fetchUserProfile()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      dispatch(setLoading(false))
    }
  }
  const onSaveLegalName = async () => {
    try {
      dispatch(setLoading(true))
      await updateHostSettings({ legalName })
      setOpen(null)
      dispatch(setToastSuccess({ message: 'success' }))
      fetchHostSettings()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const renderContent = () => {
    return (
      <div className={'flex flex-col'}>
        {open === 'name' && (
          <>
            <div className={'mb-4'}>
              <BasicInput
                label={''}
                placeholder={'First name'}
                value={firstName}
                onChange={onChangeFirstName}
                onBlur={onBlurFirstName}
                maxLength={50}
              />
              {errorMsg.firstName && <span className={'text-red mt-2 ml-2 text-12-12'}>{errorMsg.firstName}</span>}
            </div>
            <div className={'mb-6'}>
              <BasicInput
                label={''}
                placeholder={'Last name'}
                value={lastName}
                onChange={onChangeLastName}
                onBlur={onBlurLastName}
                maxLength={50}
              />
              {errorMsg.lastName && <span className={'text-red mt-2 ml-2 text-12-12'}>{errorMsg.lastName}</span>}
            </div>
            <BasicButton
              disabled={isDisabled}
              onClick={onSave}
              loading={isLoading}
              color={'red'}
              variant={'contained'}
              isRadius100={true}
            >
              Save
            </BasicButton>
          </>
        )}
        {open === 'legalName' && (
          <>
            <div className={'mb-6'}>
              <BasicInput
                label={''}
                placeholder={'Business or Legal Name'}
                value={legalName}
                onChange={onChangeLegalName}
                // onBlur={onBlurLastName}
                // maxLength={50}
              />
              {errorMsg.legalName && <span className={'text-red mt-2 ml-2 text-12-12'}>{errorMsg.legalName}</span>}
            </div>
            <BasicButton
              disabled={
                (!isEmpty(settingData?.legalName) && settingData?.legalName === legalName.trim()) ||
                isEmpty(legalName.trim())
              }
              onClick={onSaveLegalName}
              loading={isLoading}
              color={'red'}
              variant={'contained'}
              isRadius100={true}
            >
              Save
            </BasicButton>
          </>
        )}
      </div>
    )
  }

  const renderRowItem = (title: string, subTitle: string, type: 'name' | 'legalName' | 'email' | 'wallet') => {
    const isName = type === 'name'
    const isLegalName = type === 'legalName'
    const isEmail = type === 'email'
    const isWallet = type === 'wallet'
    const isEdit = data?.firstName || data?.lastName
    // const isEditEmail = data?.user?.email
    const isEditLegalEmail = settingData?.legalName
    return (
      <>
        <div className="w-full flex gap-[12px] justify-between items-start ">
          <div className="pr-6">
            <p className="text-neutral-800 font-maison-neue-medium text-16-20">
              {title}
              &nbsp;
              {data?.user?.email && isEmail && (
                <span className="font-maison-neue-medium text-forest-6 uppercase text-12-16 bg-forest-1 px-2 py-[1px] rounded-[100px] tracking-[.04em]">
                  Verified
                </span>
              )}
              {data?.user?.walletId && isWallet && (
                <span className="font-maison-neue-medium text-forest-6 uppercase text-12-16 bg-forest-1 px-2 py-[1px] rounded-[100px] tracking-[.04em]">
                  CONNECTED
                </span>
              )}
            </p>
            <p className={'text-14-18 font-maison-neue mt-1 flex text-neutral-600 break-all'}>
              {subTitle}&nbsp;
              {isWallet && data?.user?.walletId && (
                <img
                  src={ic_copy}
                  alt=""
                  className="w-4 cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(data?.user?.walletId)
                    dispatch(setToastSuccess({ message: 'Copied' }))
                  }}
                  role="presentation"
                />
              )}
            </p>
          </div>
          {isName && (
            <BasicButton onClick={() => setOpen('name')} size={'md'} variant={'outlined'} clases={'w-auto'} isRadius100>
              <span className="font-maison-neue-medium text-14-18 text-neutral-900">{isEdit ? 'Edit' : 'Add'}</span>
            </BasicButton>
          )}
          {isLegalName && (
            <BasicButton
              onClick={() => setOpen('legalName')}
              size={'md'}
              variant={'outlined'}
              clases={'w-auto'}
              isRadius100
            >
              <span className="font-maison-neue-medium text-14-18 text-neutral-900">
                {isEditLegalEmail ? 'Edit' : 'Add'}
              </span>
            </BasicButton>
          )}
          {isEmail && <SettingPersonalInfoEmail data={data} fetchUserProfile={fetchUserProfile} />}
        </div>
      </>
    )
  }

  const renderContentPopup = () => {
    if (open === 'name') return { title: 'Name', subTitle: `So we know what to call you` }
    if (open === 'legalName')
      return {
        title: 'Business or Legal Name',
        subTitle: `This will be used to generate your Terms & Conditions and Privacy Policy.`,
      }
    return { title: '', subTitle: '' }
  }

  return (
    <>
      <div className={'w-full bg-white rounded-[16px] shadow-[0px_1px_2px_rgba(0,0,0,0.05)] p-[24px] mt-[16px]'}>
        {renderRowItem(
          'Name',
          data?.firstName || data?.lastName
            ? (((data?.firstName as string) + ' ' + data?.lastName) as string).trim()
            : 'So we know what to call you',
          'name'
        )}
        <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
        {renderRowItem(
          'Email Address',
          (data?.user?.email as string) || 'Used for login, account recovery, and important updates from Dtravel.',
          'email'
        )}
        <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} id={SETUP_GUIDE.ADD_EMAIL_LOGIN} />
        {renderRowItem(
          'Business or Legal Name',
          settingData?.legalName || 'This will be used to generate your Terms & Conditions and Privacy Policy.',
          'legalName'
        )}
      </div>

      <BasicDialog
        isOpen={Boolean(open)}
        onClose={handleClose}
        title={renderContentPopup().title}
        extraTitle={renderContentPopup().subTitle}
        titleAlign={'center'}
        allowBackdropClick
      >
        {renderContent()}
      </BasicDialog>
    </>
  )
}

export default SettingAccountlInformation
