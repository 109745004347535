import { FormError } from '../../interfaces'
import Error from '../common/Error'
import Popover from '@mui/material/Popover'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import chervon_down from 'src/assets/icons/ic_chevron_down_20_20.svg'
import BasicCheckbox from 'src/components/ui/BasicCheckbox'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

interface Props {
  propertyTax: any[]
  isCheckedFlatFeeApply: boolean
  taxesChecked: string[]
  onCheckedTax: (cheked: boolean, name: string) => void
  onCheckedFlatFeeApply: (cheked: boolean) => void
  error?: FormError
}

const ApplyFlatFeeOn: React.FC<Props> = ({
  propertyTax,
  taxesChecked,
  onCheckedTax,
  isCheckedFlatFeeApply,
  onCheckedFlatFeeApply,
  error,
}) => {
  const [percentageTaxes, setPercentageTaxes] = useState<any[]>([])
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768
  const open = Boolean(anchorEl)

  useEffect(() => {
    if (Array.isArray(propertyTax)) {
      setPercentageTaxes(propertyTax.filter((item) => item.amountType === 'percent'))
    } else {
      setPercentageTaxes([])
    }
  }, [propertyTax])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <label
        htmlFor={'applyTaxOnThisFee'}
        className={clsx('flex items-center gap-[24px] font-maison-neue-medium text-14-18 text-neutral-900', {
          ['opacity-50 cursor-not-allowed']: percentageTaxes.length === 0,
        })}
      >
        <BasicCheckbox
          id={'applyTaxOnThisFee'}
          checked={isCheckedFlatFeeApply}
          color={'black'}
          onChange={(event) => onCheckedFlatFeeApply(event.target.checked)}
          disabled={percentageTaxes.length === 0}
        />
        <span className={'mt-[8px]'}>Apply tax on this fee</span>
      </label>

      <button
        className={clsx(
          'flex items-center justify-between w-full h-[48px] rounded-[12px] bg-white  px-[14px] mt-[8px]',
          'font-maison-neue-medium text-16-20 text-neutral-900',
          'disabled:opacity-50 disabled:cursor-not-allowed',
          'shadow-basic',
          {
            ['border border-red-700 hover:border-red-700 focus:border-red-700']: error?.show,
            ['border border-neutral-300 hover:border-neutral-900']: !error?.show,
          }
          // {
          //   ['hidden']: !isCheckedFlatFeeApply,
          // }
        )}
        onClick={handleOpen}
        disabled={!isCheckedFlatFeeApply}
      >
        <span>
          {percentageTaxes.length === 0
            ? 'No tax available'
            : taxesChecked.length > 0
            ? `${taxesChecked.length} ${taxesChecked.length > 1 ? 'taxes' : 'tax'} selected`
            : 'Select taxes...'}
        </span>
        <span>
          <img src={chervon_down} alt={'chervon_down'} />
        </span>
      </button>
      {error && <Error {...error} classes={'text-14-20'} />}

      <Popover
        id={'simple-popover'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: isMobile ? 'top' : 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: isMobile ? 'bottom' : 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            transform: isMobile ? 'translateY(-4px)' : 'translateY(4px)',
            padding: '20px 24px',
            boxShadow: '0px 8px 16px -4px rgba(0, 0, 0, 0.06), 0px 4px 6px -2px rgba(0, 0, 0, 0.04)',
            borderRadius: '8px',
            border: '1px solid #F7F7F4',
            width: '380px',
          },
        }}
      >
        <div>
          {Array.isArray(percentageTaxes) &&
            percentageTaxes.map((item, index) => (
              <div key={item.id || index} className={'flex items-center h-[40px]'}>
                <label
                  htmlFor={`tax-${item.id || index}`}
                  className={'flex items-center gap-[28px] font-maison-neue-medium text-14-18 text-neutral-900 w-full'}
                >
                  <BasicCheckbox
                    id={`tax-${item.id || index}`}
                    checked={taxesChecked.includes(item.name)}
                    color={'black'}
                    onChange={(event) => onCheckedTax(event.target.checked, item.name)}
                  />
                  <span className={'mt-[4px]'}>{item.taxTitle}</span>
                </label>
              </div>
            ))}
        </div>
      </Popover>
    </>
  )
}

export default ApplyFlatFeeOn
