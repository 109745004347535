import React from 'react'

interface Props {
  children: React.ReactNode
  isDisable?: boolean
  bgColor?: string
}

const DisabledSection: React.FC<Props> = ({ children, isDisable, bgColor }) => {
  if (isDisable) {
    return (
      <div className={'relative'}>
        {children}
        <div
          className={
            'w-full h-full absolute top-0 bottom-0 left-0 right-0 opacity-60 z-[99] cursor-not-allowed bg-neutral-100'
          }
          style={bgColor ? { backgroundColor: bgColor } : {}}
        // style={{ boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.04)' }}
        />
      </div>
    )
  }

  return <>{children}</>
}

export default DisabledSection
