import LayoutOnboarding from '../LayoutOnboarding'
import RightContentLandingPage from '../landing-page/RightContentLandingPage'
import OnboardingSiteDetailsSiteName from './OnboardingSiteDetailsSiteName'
import React, { useState, useEffect } from 'react'
import { getHostSettings, getUserProfile } from 'src/api/user'
import { ONBOARDING_STEP } from 'src/contants/user'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { handleNextOnboardingStep, setSettingData } from 'src/redux/slices/user'
import { getUserInfo, saveUserInfo } from 'src/utils/user'

const OnboardingSiteDetailsSiteNameContainer = () => {
  const dispatch = useAppDispatch()
  const { settingData } = useAppSelector((state) => state.user)
  const [dataPreview, setDataPreview] = useState<any>({
    hostName: '',
    legalName: '',
  })
  const fetchHostSettings = async () => {
    try {
      const res = await getHostSettings()
      if (res.success) {
        dispatch(setSettingData(res.data || {}))
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchHostSettings()
  }, [])
  const fetchUserProfile = async () => {
    try {
      const res = await getUserProfile()
      const { data } = res
      const user = getUserInfo()
      saveUserInfo({ ...user, ...data })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchUserProfile()
  }, [])

  const handleNext = async () => {
    if (settingData.interest === 'list_on_meta_search_engines') {
      dispatch(handleNextOnboardingStep(ONBOARDING_STEP.SITE_DETAILS_CONTACT_INFO))
    } else {
      dispatch(handleNextOnboardingStep(ONBOARDING_STEP.LANDING_PAGE))
    }
  }

  return (
    <LayoutOnboarding headerTitle={'Website name'} rightContent={<RightContentLandingPage hostName={dataPreview.hostName} />}>
      <div className="pb-[136px] lg:pb-[64px] pt-[38px] lg:pt-[64px] max-w-[524px] mx-auto overflow-auto">
        <OnboardingSiteDetailsSiteName
          handleNext={handleNext}
          handleUpdatePreview={(values: any) => setDataPreview({ ...dataPreview, ...values })}
          fetchHostSettings={fetchHostSettings}
          fetchUserProfile={fetchUserProfile}
        />
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingSiteDetailsSiteNameContainer
