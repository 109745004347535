import { updateHostSettings } from '../../../api/user'
import { ONBOARDING_STEP } from '../../../contants/user'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { handleNextOnboardingStep, setSettingData, updateOnboardingData } from '../../../redux/slices/user'
import { handleErrorMessage } from '../../../utils/common'
import BasicButton from '../../ui/BasicButton'
import BasicRadio from '../../ui/BasicRadio'
import LayoutOnboarding from '../LayoutOnboarding'
import RightContentIdentity from './RightContentIdentity'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

const OPTIONS = [
  {
    value: 'create_direct_booking',
    title: 'To create a custom direct booking website',
  },
  {
    value: 'add_booking_widget',
    title: 'To add a booking widget to my existing website',
  },
  {
    value: 'list_on_meta_search_engines',
    title: 'To list on metasearch engines such as Google VR and Sensei',
  },
  {
    value: 'do_not_know',
    title: 'I don’t know yet',
  },
]

const OnboardingInterest = () => {
  const dispatch = useAppDispatch()
  const { settingData } = useAppSelector((state) => state.user)
  const [value, setValue] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (settingData) {
      setValue(settingData.interest || '')
    }
  }, [settingData])

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      const isCreateDirectBooking = value === 'create_direct_booking'
      const resSetting = await updateHostSettings({
        interest: value,
        status: isCreateDirectBooking ? 'active' : 'inactive',
      })
      if (resSetting.data) {
        dispatch(setSettingData(resSetting.data))
      }
      dispatch(updateOnboardingData({ interest: value }))
      // dispatch(handleNextOnboardingStep(ONBOARDING_STEP.PMS_USAGE))
      dispatch(handleNextOnboardingStep(ONBOARDING_STEP.LISTING_SOURCE))
    } catch (err) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const renderSubmitBtn = () => {
    return (
      <BasicButton
        variant={'contained'}
        isRadius100={true}
        color={'red'}
        size={'xl'}
        onClick={onSubmit}
        disabled={!value}
        loading={isLoading}
      >
        Continue
      </BasicButton>
    )
  }

  return (
    <LayoutOnboarding headerTitle={'Interests'} rightContent={<RightContentIdentity />}>
      <div className={'mt-[38px] lg:mt-[70px] w-full mx-auto md:max-w-[524px] pb-[112px] lg:pb-0'}>
        <h2 className={'font-maison-neue text-24-36 text-neutral-800'}>
          What&apos;s the primary way you intend to use Dtravel Direct?
        </h2>
        <p className={'font-maison-neue text-16-24 text-neutral-800 mt-[4px]'}>
          You will have access to all Dtravel Direct features; this simply helps us understand how best to support you.
        </p>

        <div className={'flex flex-col gap-[16px] mt-[16px]'}>
          {OPTIONS.map((item) => {
            return (
              <label
                key={item.value}
                className={clsx(
                  'flex justify-between items-center gap-[16px] rounded-[16px] cursor-pointer bg-white p-[24px]',
                  'border border-neutral-300',
                  {
                    'border-[2px] border-neutral-800': value === item.value,
                  }
                )}
                htmlFor={`persona_${item.value}`}
              >
                <div className={'flex flex-col gap-[4px]'}>
                  <span className={'font-maison-neue text-16-24 text-neutral-800'}>{item.title}</span>
                </div>
                <BasicRadio
                  id={`persona_${item.value}`}
                  type={'radio'}
                  classes={'w-[24px] h-[24px] cursor-pointer'}
                  name={'primary_way'}
                  value={item.value}
                  checked={value === item.value}
                  onChange={(event) => {
                    setValue(event.target.value)
                    // dispatch(updateOnboardingData({ interest: event.target.value }))
                  }}
                />
              </label>
            )
          })}
        </div>

        <div className={'mt-[24px] hidden lg:block'}>{renderSubmitBtn()}</div>
      </div>

      <div
        className={clsx(
          'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
          'border-t border-neutral-300 px-4 md:px-8',
          'shadow-footer'
        )}
      >
        {renderSubmitBtn()}
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingInterest
