import { SETTINGS_SIDE_BAR, TABS_SETTING_KEYS } from '../../contants/common'
import { CREATE_LISTING_TYPES } from '../../contants/property'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setIsOpenPopupAddListing } from '../../redux/slices/listing'
import { handleLogicSelectAccountAirbnb } from '../../utils/property'
import { IconAirbnb, IconEditWrite, IconPlugConnect } from '../common/Icons'
import BasicButton from '../ui/BasicButton'
import clsx from 'clsx'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
  inPopup?: boolean
}

const ThreeWaysAddListing: React.FC<Props> = ({ inPopup }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { airbnbViaHospitable, airbnbViaChannex } = useAppSelector((state) => state.user)

  const handleClickButton = (type: string) => {
    dispatch(setIsOpenPopupAddListing(false))
    if (type === 'manual') {
      navigate('/native-listing/property')
    }
    if (type === 'pms') {
      navigate('/settings?tab=' + TABS_SETTING_KEYS.PMS_AND_CHANNELS + '#' + SETTINGS_SIDE_BAR.CHANNEL_INTEGRATION)
    }
    if (type === 'airbnb') {
      handleLogicSelectAccountAirbnb(airbnbViaHospitable, airbnbViaChannex)
    }
  }

  return (
    <div
      className={clsx('flex flex-col lg:flex-row justify-center items-center w-full', {
        'md:h-[calc(100vh-204px)] gap-[32px]': !inPopup,
        'gap-0 lg:gap-[32px]': inPopup,
      })}
    >
      {CREATE_LISTING_TYPES.map((item: any) => {
        return (
          <div
            key={item.type}
            className={clsx('flex flex-row lg:flex-col h-auto', 'bg-white rounded-[16px]', 'w-full lg:w-1/3', {
              'gap-[48px] p-[24px] md:p-[32px] lg:p-[48px] h-auto lg:min-h-[472px] shadow-xs': !inPopup,
              'gap-[32px] lg:gap-[16px] py-[16px] md:py-[24px ] p-[24px] lg:p-0': inPopup,
            })}
          >
            <div
              className={clsx('hidden md:flex md:justify-center md:items-center', 'rounded-full bg-grayscale-50', {
                'md:w-[64px] md:h-[64px] md:min-w-[64px] md:min-h-[64px]': !inPopup,
                'lg:w-[112px] lg:h-[112px] lg:min-w-[112px] lg:min-h-[112px]': !inPopup,
                'w-[64px] h-[64px] min-w-[64px] min-h-[64px]': inPopup,
              })}
            >
              <span>
                <span className={inPopup ? '' : 'block lg:hidden'}>
                  {item.type === 'manual' && <IconEditWrite width={32} height={32} />}
                  {item.type === 'airbnb' && <IconAirbnb width={32} height={32} />}
                  {item.type === 'pms' && <IconPlugConnect width={32} height={32} />}
                </span>
                <span className={inPopup ? 'hidden' : 'hidden lg:block'}>
                  {item.type === 'manual' && <IconEditWrite />}
                  {item.type === 'airbnb' && <IconAirbnb />}
                  {item.type === 'pms' && <IconPlugConnect />}
                </span>
              </span>
            </div>
            <div
              className={clsx('flex flex-col justify-between', {
                'flex-grow gap-[24px]': !inPopup,
                'gap-[16px]': inPopup,
              })}
            >
              <div>
                <p
                  className={clsx('font-maison-neue text-neutral-800', {
                    'text-24-36': !inPopup,
                    'text-24-36 lg:text-20-32': inPopup,
                  })}
                >
                  {item.title}
                </p>
                <p
                  className={clsx('font-maison-neue text-neutral-600 mt-[8px]', {
                    'text-16-24': !inPopup,
                    'text-16-24 lg:text-14-18': inPopup,
                  })}
                >
                  {item.description}
                </p>
              </div>
              <div className={''}>
                <BasicButton
                  variant={'contained'}
                  color={'red'}
                  size={'md'}
                  isRadius100={true}
                  onClick={() => handleClickButton(item.type)}
                >
                  {item.labelButton}
                </BasicButton>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ThreeWaysAddListing
