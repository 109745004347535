import { ServerResponse } from '../interfaces'
import { ListingParams, UpdateListingHospitable } from '../interfaces/listing'
import Api from './index'

export const getListings = async (params: ListingParams, source?: any): Promise<ServerResponse> => {
  const axiosRes = await Api.get<ServerResponse>('/listing-service/v2/property/host', {
    params,
    cancelToken: source?.token,
  })
  return axiosRes?.data
}

export const getListingDetail = async (id: number | string): Promise<ServerResponse> => {
  const axiosRes = await Api.get<ServerResponse>(`/listing-service/v2/property/host/${id}`)
  return axiosRes.data
}

export const importListing = (type: string): Promise<ServerResponse> => {
  return Api.post<ServerResponse>('/integration-service/listings/import', {
    type,
  }).then((axiosRes) => axiosRes.data)
}

export const syncListing = (pmsListingId?: string | number): Promise<ServerResponse> => {
  const data: { pmsListingId?: string | number } = {}
  if (pmsListingId) data.pmsListingId = pmsListingId
  return Api.post<ServerResponse>('/integration-service/listings/import', data).then((axiosRes) => axiosRes.data)
}

export const getImportProgress = (params?: any): Promise<ServerResponse> => {
  return Api.get<ServerResponse>('/integration-service/listings/import-progress', { params: params || {} }).then(
    (axiosRes) => axiosRes.data
  )
}

export const getImportProgressDetail = (params?: any): Promise<ServerResponse> => {
  return Api.get<ServerResponse>('/integration-service/listings/list-import-progress', { params: params || {} }).then(
    (axiosRes) => axiosRes.data
  )
}

export const getImportProgressHospitable = async ({
  requestId,
  listPmsId,
}: {
  requestId: string
  listPmsId: string[]
}): Promise<ServerResponse> => {
  let axiosRes = await Api.get<ServerResponse>('/integration-service/v1/hospitable/import-progress', {
    params: { requestId, listPmsId: JSON.stringify(listPmsId) },
  })
  return axiosRes.data
}
export const getImportProgressByListIds = async (listPmsId: string[]): Promise<ServerResponse> => {
  let axiosRes = await Api.get<ServerResponse>('/integration-service/v1/hospitable/import-progress', {
    params: { listPmsId: JSON.stringify(listPmsId) },
  })
  return axiosRes.data
}

// curl --location 'localhost:3000/listings/job-import/5648d709-fb24-4f82-89ba-5ea59c21453a'
export const getImportProgressByRequestId = async (requestId: string) => {
  let axiosRes = await Api.get<ServerResponse>(`/integration-service/listings/job-import/${requestId}`)
  return axiosRes.data
}

export const deployContract = async (propertyIds: number[]): Promise<ServerResponse> => {
  let axiosRes = await Api.post<ServerResponse>('/listing-service/v1/property/contract-deploy', { propertyIds })
  return axiosRes.data
}

export const deployAllContract = (): Promise<ServerResponse> => {
  return Api.post<ServerResponse>('/listing-service/v1/property/all-contract-deploy').then((axiosRes) => axiosRes.data)
}

export const checkDeployedAllListing = (): Promise<ServerResponse> => {
  return Api.get<ServerResponse>('/listing-service/v1/property/host/status').then((axiosRes) => axiosRes.data)
}

export const updateMultiListing = (body: any): Promise<ServerResponse> => {
  return Api.post<ServerResponse>('/listing-service/v2/property/update/status', body).then((axiosRes) => axiosRes.data)
}

export const updateAllListing = (body: {
  publishedStatus?: string
  crypto?: boolean
  fiat?: boolean
}): Promise<ServerResponse> => {
  return Api.post<ServerResponse>('/listing-service/v1/property/batch/status', body).then((axiosRes) => axiosRes.data)
}

export const countPmsProperty = (): Promise<ServerResponse> => {
  return Api.get<ServerResponse>('/listing-service/v1/property/pms-count').then((axiosRes) => axiosRes.data)
}

/**
 * Returns array availability for each day.
 *
 * @param {string} hostAddress The wallet address of host
 * @param {string} listingId The hostaway id
 * @param {string} startDate Date from, format YYYY-MM-DD.
 * @param {string} endDate Date to, format YYYY-MM-DD.
 * @param {string} currency
 * @return {Promise} array availability for each day.
 */
export const getCalendar = async (
  hostAddress: string,
  listingId: string,
  startDate: string,
  endDate: string,
  currency?: string
): Promise<any> => {
  const params: any = { hostAddress, listingId, startDate, endDate }
  if (currency) {
    params.currency = currency
  }
  const res = await Api({
    method: 'get',
    url: '/listing-service/calendar',
    params: params,
  })
  const res_1 = res.data
  if (!res_1.success) {
    throw res_1.error
  }
  return res_1
}

export const getCalendarHost = async (
  hostAddress: string,
  listingId: string,
  startDate: string,
  endDate: string,
  currency?: string
) => {
  const params: any = { hostAddress, listingId, startDate, endDate }
  if (currency) {
    params.currency = currency
  }
  const res = await Api({
    method: 'get',
    url: '/listing-service/calendar/host',
    params: params,
  })
  const res_1 = res.data
  if (!res_1.success) {
    throw res_1.error
  }
  return res_1
}

// https://api.dataismist.com/listing-service/v2/property/settings?id=2420
export const getTaxFeeSettings = (
  listingId: string,
  checkIn: string,
  checkOut: string,
  adults: number,
  children: number,
  infants: number
) => {
  const params: any = { id: listingId, checkinDate: checkIn, checkoutDate: checkOut, adults }
  if (children > 0) params.children = children
  if (infants > 0) params.infants = infants
  return Api({
    method: 'get',
    url: '/listing-service/v2/property/settings',
    params,
  }).then((res) => res.data)
}

export const superHogCreateListing = (body: any): Promise<ServerResponse> => {
  return Api.post<ServerResponse>('/listing-service/v2/property/superhog/create-listing', body).then(
    (axiosRes) => axiosRes.data
  )
}

export const superHogDisableListing = (listingId: string | number): Promise<ServerResponse> => {
  return Api.post<ServerResponse>('/listing-service/v2/property/superhog/disable-listing/' + listingId).then(
    (axiosRes) => axiosRes.data
  )
}

export const getIpInfo = () => {
  return Api.get<ServerResponse>('/activity-service/geo-ip/location').then((axiosRes) => axiosRes.data)
}

export const getSourcesListing = () => {
  return Api.get<ServerResponse>('/listing-service/v2/property/host/sources').then((axiosRes) => axiosRes.data)
}

export const updateMinimumStay = (body: any): Promise<ServerResponse> => {
  return Api.post<ServerResponse>('/listing-service/v2/calendar/update-minimum-stay', body).then(
    (axiosRes) => axiosRes.data
  )
}
export const getMinimumStay = (listingId: number | string) => {
  return Api.get<ServerResponse>(`/listing-service/v2/calendar/minimum-stay/${listingId}`).then(
    (axiosRes) => axiosRes.data
  )
}
// https://api.dataismist.com/listing-service/v1/property/stripe-connect
export const stripeConnectMultiListing = (body: { stripeId: string; propertyIds: number[] }) => {
  return Api.post<ServerResponse>('/listing-service/v1/property/stripe-connect', body).then((axiosRes) => axiosRes.data)
}

export const getListingByStripeId = (params: any) => {
  return Api.get<ServerResponse>(`/listing-service/v2/property/host/stripe`, {
    params: params,
  }).then((axiosRes) => axiosRes.data)
}
export const deleteListing = (listingId: string | number): Promise<ServerResponse> => {
  return Api.post<ServerResponse>(`/listing-service/v1/property/${listingId}/delete`).then((axiosRes) => axiosRes.data)
}
export const listingCanBeDelete = (listingId: string | number): Promise<ServerResponse> => {
  return Api.post<ServerResponse>(`listing-service/v1/property/${listingId}/can-be-deleted`).then(
    (axiosRes) => axiosRes.data
  )
}
export const updateManualStatus = async (listingId: string | number): Promise<ServerResponse> => {
  const axiosRes = await Api.post<ServerResponse>(`listing-service/v1/property/${listingId}/update-manual-status`)
  return axiosRes.data
}
export const updateListingHospitable = async (properties: UpdateListingHospitable[]): Promise<ServerResponse> => {
  let axiosRes = await Api.post<ServerResponse>(`/listing-service/v1/property/hospitable/update`, { properties })
  return axiosRes.data
}
export const getListingsStatus = async (listId: string): Promise<ServerResponse> => {
  let axiosRes = await Api.get<ServerResponse>(`/listing-service/v2/property/properties`, { params: { listId } })
  return axiosRes.data
}
// https://api.dataismist.com/integration-service/v1/hospitable/import-by-ids'
export const importByIds = async (ids: string[], channelId: string): Promise<ServerResponse> => {
  let axiosRes = await Api.post<ServerResponse>(`/integration-service/v1/hospitable/import-by-ids`, { ids, channelId })
  return axiosRes.data
}
