import { fetchIntegratedConfig } from '../../utils/property'
import BasicButton from '../ui/BasicButton'
import BasicCheckbox from '../ui/BasicCheckbox'
import React, { useState } from 'react'
import { disconnectChannex, disconnectHospitable } from 'src/api/integration'
import BasicPopup from 'src/components/ui/BasicPopup'
import { useAppDispatch } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { setAirbnbPmsType, setIsConnectedAirbnb } from 'src/redux/slices/user'
import { handleErrorMessage } from 'src/utils/common'

interface Props {
  channelId?: string
}

const DisconnectAirbnb: React.FC<Props> = ({ channelId }) => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState<boolean>(false)
  const [isDeleteAllListings, setDeleteAllListings] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDisconnectAirbnb = async () => {
    try {
      setLoading(true)
      let message = ''
      if (channelId) {
        await disconnectHospitable({ isDeleteAllListings, channelId })
        message = `Airbnb via Hospitable disconnected`
      } else {
        await disconnectChannex({ isDeleteAllListings })
        message = `Airbnb via Channex disconnected`
        dispatch(setIsConnectedAirbnb(false))
        dispatch(setAirbnbPmsType(''))
      }
      await fetchIntegratedConfig()
      dispatch(setToastSuccess({ message }))
      handleClose()
    } catch (error) {
      handleErrorMessage(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <BasicButton onClick={handleOpen} clases={''} variant={'outlined'} size={'md'} isRadius100={true}>
        Disconnect
      </BasicButton>

      <BasicPopup
        isOpen={open}
        onClose={handleClose}
        title={`Disconnect Airbnb`}
        extraTitle={`You're about to disconnect this integration`}
      >
        <div className="flex items-center gap-4 cursor-pointer bg-red-100 rounded-[8px] p-4 mb-10 mt-4">
          <div className="min-w-[16px] mt-[-4px]">
            <BasicCheckbox
              className="min-w-[16px]"
              checked={isDeleteAllListings}
              onChange={() => {
                setDeleteAllListings(!isDeleteAllListings)
              }}
            />
          </div>
          <div className={'font-maison-neue text-16-24 text-neutral-800'}>Delete all imported listings from Airbnb</div>
        </div>

        <div className={'flex flex-col gap-4'}>
          <BasicButton
            variant="contained"
            size={'xl'}
            color={'red'}
            clases={'w-full flex justify-center'}
            onClick={handleDisconnectAirbnb}
            loading={loading}
            disabled={false}
            isRadius100={true}
          >
            Confirm
          </BasicButton>
          <p className="text-14-20 font-maison-neue text-neutral-500 text-center">This action cannot be undone</p>
        </div>
      </BasicPopup>
    </>
  )
}

export default DisconnectAirbnb
