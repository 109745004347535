import { createApiKey } from '../../../api/user'
import { useAppDispatch } from '../../../redux/hooks'
import { setToastSuccess } from '../../../redux/slices/common'
import { handleErrorMessage } from '../../../utils/common'
import BasicInput from '../../ui/BasicInput'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useState } from 'react'

const OnboardingDtravelApi = () => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [clientId, setClientId] = useState<string>('')
  const [clientSecret, setClientSecret] = useState<string>('')

  useEffect(() => {
    const fetchApiKey = async () => {
      if (!clientId && !clientSecret) {
        try {
          setIsLoading(true)
          const res = await createApiKey()
          const { clientId, clientSecret } = res.data
          setClientId(clientId)
          setClientSecret(clientSecret)
        } catch (err: any) {
          handleErrorMessage(err)
          throw err
        } finally {
          setIsLoading(false)
        }
      }
    }
    fetchApiKey()
  }, [])

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value)
    dispatch(setToastSuccess({ message: `Copied!`, duration: 2000 }))
  }

  return (
    <>
      <div className={'grid grid-cols-1 gap-[24px]'}>
        <div>
          <BasicInput
            label={'Account ID'}
            type="password"
            name="clientId"
            autoComplete="false"
            showIconPassword
            showIconCopy
            onCopy={() => handleCopy(clientId)}
            value={clientId}
            readOnly
          />
        </div>
        <div>
          <BasicInput
            label={'API key'}
            type="password"
            name="clientSecret"
            autoComplete="false"
            showIconPassword
            showIconCopy
            onCopy={() => handleCopy(clientSecret)}
            value={clientSecret}
            readOnly
          />
        </div>
      </div>
      {isLoading && (
        <div
          className={
            'absolute w-full h-full top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center opacity-30 cursor-not-allow'
          }
        >
          <CircularProgress size={50} style={{ color: '#0B2336' }} />
        </div>
      )}
    </>
  )
}

export default OnboardingDtravelApi
