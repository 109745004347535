import axios from 'axios'
import {
  getAccessToken,
  getRefreshToken,
  getUserInfo,
  logOut,
  saveAccessToken,
  saveRefreshToken,
  saveUserInfo,
} from 'src/utils/user'

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_SERVICE_URL,
  responseType: 'json',
})
let isRefreshing = false
let subscribers: Array<any> = []

function addSubscriber(callback: any) {
  subscribers.push(callback)
}

function onRrefreshed(new_token: any) {
  subscribers = subscribers.map((callback) => callback(new_token))
  subscribers = []
}

function logout() {
  subscribers = []
  logOut()
}

Api.defaults.timeout = 600000
Api.defaults.headers.post['Content-Type'] = 'application/json'

// request api
Api.interceptors.request.use(
  async function (config) {
    const token = getAccessToken()
    if (token && config && config.headers) {
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// response api
Api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    if (error.response && (error?.response?.status === 401 || error?.response?.statusCode === 401)) {
      const refreshToken = getRefreshToken()
      if (error.config.url.indexOf('refresh-token') !== -1) {
        logout()
      }
      if (refreshToken && !originalRequest._retry) {
        originalRequest._retry = true
        if (!isRefreshing) {
          isRefreshing = true
          try {
            const res = await axios.post(
              process.env.REACT_APP_API_SERVICE_URL + '/account-service/v1/auth/refresh-token',
              { token: refreshToken }
            )
            const { data } = res.data
            saveAccessToken(data.token)
            saveRefreshToken(data.refreshToken)
            isRefreshing = false
            const user = getUserInfo()
            saveUserInfo({ ...user, ...data })
            onRrefreshed(data.token)
            return new Promise((resolve) => {
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token
              originalRequest.headers['Authorization'] = 'Bearer ' + data.token
              resolve(axios(originalRequest))
            })
          } catch (error) {
            logout()
          }
        }
        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber((new_token: any) => {
            originalRequest.headers.Authorization = `Bearer ${new_token}`
            resolve(axios(originalRequest))
          })
        })
        return retryOriginalRequest
      } else {
        logout()
      }
    }
    return Promise.reject(error.response ? error.response.data : error)
  }
)

export default Api
