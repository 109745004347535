import React from 'react'
import ic_external_link from 'src/assets/icons/ic_external_link.svg'
import ic_hostaway from 'src/assets/icons/ic_hostaway.svg'
import ic_uplisting from 'src/assets/icons/ic_uplisting.svg'
import BasicCardV2 from 'src/components/common/BasicCardV2'
import { PMS, PMS_INFO } from 'src/contants/common'

interface Props {
  pmsType: string
}

const DetailListing: React.FC<Props> = ({ pmsType }) => {
  return (
    <BasicCardV2
      title={'Listing details'}
      extraTitle={
        <button className={'flex items-center text-14-18 font-maison-neue-medium text-neutral-600 hover:underline'}>
          <a href={PMS_INFO[pmsType]?.url} target="_blank" rel="noreferrer">
            <span className={'mr-2'}>Edit in {PMS_INFO[pmsType]?.label}</span>
          </a>
          <img src={ic_external_link} alt={'ic_external_link'} />
        </button>
      }
    >
      <div className={'flex flex-row items-center'}>
        {pmsType === PMS.HOSTAWAY && <img src={ic_hostaway} alt={PMS_INFO[pmsType]?.label} />}
        {pmsType === PMS.UPLISTING && <img src={ic_uplisting} alt={PMS_INFO[pmsType]?.label} />}
      </div>

      <p className={'mt-3 text-neutral-700 text-16-24'}>
        Descriptions and settings for this listing are being populated by a connection to{' '}
        {PMS_INFO[pmsType]?.label || pmsType}. To edit your listing page, settings, and more,&nbsp;
        <a
          className={'text-neutral-700 cursor-pointer underline'}
          href={PMS_INFO[pmsType]?.url}
          target="_blank"
          rel="noreferrer"
        >
          go to your {PMS_INFO[pmsType]?.label} account
        </a>
        .
      </p>
    </BasicCardV2>
  )
}

export default DetailListing
