import useWindowDimensions from '../../hooks/useWindowDimensions'
import { getDomain } from '../../utils/common'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import { Drawer } from '@mui/material'
import clsx from 'clsx'
import React, { useState } from 'react'
import ic_logo from 'src/assets/icons/ic_D_logo_red.svg'

const SideMenu = () => {
  const { isMobile } = useWindowDimensions()
  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div>
      <button onClick={handleOpen}>
        <MenuIcon />
      </button>
      <Drawer
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: isMobile ? '100svw' : '320px',
          },
        }}
      >
        <div className="w-full md:w-[320px] px-6 py-4">
          <div className="flex items-center justify-between mb-8">
            <img src={ic_logo} alt={'ic_logo'} className={'w-[40px] h-[40px]'} />
            <button onClick={handleClose}>
              <CloseIcon />
            </button>
          </div>
          <div className="flex flex-col gap-[8px]">
            {[
              {
                title: 'Travelers',
                description: 'Find stays and manage bookings',
                url: `https://app.${getDomain()}/travelers`,
              },
              {
                title: 'Operators',
                description: 'Launch a site, create and manage listings',
              },
              {
                title: 'Rewards',
                description: 'Complete activities and earn TRVL',
                url: `https://app.${getDomain()}/rewards`,
              },
            ].map((item: any, idx: number) => {
              const isActived = item.title === 'Operators'
              return (
                <div
                  key={idx}
                  className={clsx('flex flex-col gap-1 p-4 rounded-[16px]', isActived ? 'bg-neutral-200' : 'bg-white', {
                    'hover:bg-neutral-200': !!item.url,
                  })}
                >
                  {item.url ? (
                    <a href={item.url} target={'_blank'} rel="noreferrer">
                      <p className="text-12-16 font-maison-neue-demibold text-neutral-800 uppercase tracking-[4%]">
                        {item.title}
                      </p>
                      <p className="text-14-18 font-maison-neue text-neutral-800">{item.description}</p>
                    </a>
                  ) : (
                    <>
                      <p className="text-12-16 font-maison-neue-demibold text-neutral-800 uppercase tracking-[4%]">
                        {item.title}
                      </p>
                      <p className="text-14-18 font-maison-neue text-neutral-800">{item.description}</p>
                    </>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default SideMenu
