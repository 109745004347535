import { getWidthHeightImage, handleErrorMessage } from '../../../utils/common'
import IconButton from '@mui/material/IconButton'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { updateHostSettings, uploadSettingImages } from 'src/api/user'
import ic_media_40_40 from 'src/assets/icons/ic_media_40_40.svg'
import ic_trash_empty from 'src/assets/icons/ic_trash_empty.svg'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { setSettingData } from 'src/redux/slices/user'

const SettingFavicon = () => {
  const dispatch = useAppDispatch()
  const inputPhoto = useRef<HTMLInputElement | null>(null)
  const { settingData } = useAppSelector((state) => state.user)
  const [favicon, setFavicon] = useState<string>('')
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isHover, setIsHover] = useState(false)

  useEffect(() => {
    setFavicon(settingData?.favicon || '')
  }, [settingData?.favicon])

  const handleOpen = () => {
    setIsOpenPopup(true)
  }

  const handleClose = () => {
    setIsOpenPopup(false)
    setFavicon(settingData?.favicon || '')
  }

  const handleUpload = async (e: any) => {
    const file: File = e.target.files[0]
    const { width, height }: any = await getWidthHeightImage(file)
    if (width !== height) {
      handleErrorMessage({ message: 'Please use a photo with ratio 1:1 for favicon.' })
      return
    }
    if (Number(width) > 192 || Number(height) > 192) {
      handleErrorMessage({ message: 'The favicon cannot be bigger than 192x192.' })
      return
    }
    if (file) {
      try {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('file', file)
        const res = await uploadSettingImages('favicon', formData)
        if (res.success) {
          setFavicon(res.data)
        }
      } catch (error: any) {
        handleErrorMessage(error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleSave = async () => {
    try {
      setIsLoading(true)
      const data: any = { favicon }
      const res = await updateHostSettings(data)
      if (res.success && res.data) {
        dispatch(setToastSuccess({ message: `Update success!` }))
        dispatch(setSettingData({ ...settingData, ...res.data }))
        handleClose()
      }
    } catch (error: any) {
      handleErrorMessage(error)
    } finally {
      setIsLoading(false)
    }
  }
  const renderAddButton = () => (
    <BasicButton size={'md'} variant={'outlined'} clases={'w-full md:w-auto'} isRadius100 onClick={handleOpen}>
      {settingData?.favicon ? 'Update' : 'Add'}
    </BasicButton>
  )
  return (
    <>
      <div className={'flex flex-col md:flex-row justify-between items-start gap-[12px]'}>
        <div>
          <p className={'font-maison-neue-medium text-neutral-800 text-16-20'}>Favicon</p>
          <p className={'font-maison-neue text-neutral-600 text-14-18'}>
            Build brand awareness with a browser icon that helps visitors identify your site. We recommend 192x192px for best results.
          </p>
        </div>
        {/* For Desktop, Table */}
        <div className={'hidden md:block'}>{renderAddButton()}</div>

        <BasicDialog
          isOpen={isOpenPopup}
          title={'Favicon'}
          extraTitle={'Build brand awareness with a browser icon that helps visitors identify your site. We recommend 192x192px for best results.'}
          onClose={handleClose}
          allowBackdropClick
          titleAlign={'center'}
        >
          <p className={'font-maison-neue-medium text-14-18 text-neutral-800'}>Photo</p>
          {favicon ? (
            <div
              className={'relative w-[160px] h-[120px] mt-[8px]'}
              onFocus={() => setIsHover(true)}
              onMouseOver={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              <img src={favicon} alt="hero" className="min-w-[160px] w-[160px] h-[120px] rounded-[8px] object-cover" />
              <div
                className={
                  'flex items-center justify-center bg-[#000000] absolute opacity-60 top-0 w-full h-full rounded-[8px] ' +
                  `${isHover ? '' : 'hidden'}`
                }
              >
                <IconButton size={'small'} onClick={() => setFavicon('')}>
                  <img src={ic_trash_empty} alt={'ic_trash_empty'} />
                </IconButton>
              </div>
            </div>
          ) : (
            <button
              className={clsx(
                'flex justify-center items-center border border-dashed border-neutral-800 w-full h-[128px] rounded-[12px] shadow-basic mt-[8px]'
              )}
              style={{ border: '0.5px dashed rgba(0, 0, 0, 0.15)' }}
              onClick={() => {
                const inputEle = inputPhoto.current as HTMLInputElement
                inputEle.click()
              }}
            >
              <img src={ic_media_40_40} alt={'ic_media'} className={''} />
            </button>
          )}

          <input
            ref={inputPhoto}
            type={'file'}
            multiple={true}
            accept={'image/*'}
            className={'opacity-0 w-full h-full z-50 hidden'}
            onChange={handleUpload}
            onClick={() => {
              const element = inputPhoto.current as HTMLInputElement
              if (element) element.value = ''
            }}
          />
          <BasicButton
            variant={'contained'}
            color={'red'}
            clases={'w-full mt-[24px]'}
            isRadius100={true}
            onClick={handleSave}
            loading={isLoading}
            disabled={!favicon}
          >
            Save
          </BasicButton>
        </BasicDialog>
      </div>

      {settingData?.favicon && (
        <div className={'w-[32px] h-[32px] rounded-[8px] mt-[12px]'}>
          <img src={settingData?.favicon} alt={'favicon'} className={'w-full h-full rounded-[8px] object-cover'} />
        </div>
      )}
      {/* For Mobile */}
      <div className={'block md:hidden w-full mt-[12px]'}>{renderAddButton()}</div>
    </>
  )
}

export default SettingFavicon
