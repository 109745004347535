import { ListingView } from '../../interfaces/listing'
import clsx from 'clsx'
import React from 'react'

interface Props {
  view?: ListingView
  onChangeView: (_view: ListingView) => void
}

const VIEWS: Array<ListingView> = [ListingView.LIST, ListingView.CALENDAR]

const SwitchCalendarView: React.FC<Props> = ({ view = 'list', onChangeView }) => {
  return (
    <div
      className={clsx('flex items-center justify-center', 'h-[40px] bg-white p-[3px]', 'rounded-[32px]', 'shadow-sm')}
    >
      {VIEWS.map((item) => {
        const isActive = view === item
        return (
          <button
            key={item}
            className={clsx('h-[34px] px-[12px] py-[8px] rounded-[40px] w-[79px]', 'text-14-18 capitalize', {
              'bg-neutral-100 font-maison-neue-medium text-red-700': isActive,
              'bg-white font-maison-neue text-neutral-700': !isActive,
            })}
            onClick={() => onChangeView(item)}
          >
            {item.toLowerCase()}
          </button>
        )
      })}
    </div>
  )
}

export default SwitchCalendarView
