import BasicTooltip from '../ui/BasicTooltip'
import Step from '@mui/material/Step'
import { StepIconProps } from '@mui/material/StepIcon'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import queryString from 'query-string'
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ic_checked from 'src/assets/icons/ic_checked.svg'
import ic_lock from 'src/assets/icons/ic_lock.svg'
import { NATIVE_LISTING_MENU, TYPE_EDIT_LISTINGS } from 'src/contants/common'
import { PROPERTY_STATUS } from 'src/contants/native'
import { isEmpty } from 'src/utils/common'
import { checkCompletedStep, isPropertyAirbnb } from 'src/utils/property'

export const nativeMenu = [
  { name: 'Property', id: NATIVE_LISTING_MENU.PROPERTY, index: 0 },
  { name: 'Location', id: NATIVE_LISTING_MENU.LOCATION, index: 1 },
  { name: 'Guest & Rooms', id: NATIVE_LISTING_MENU.GUEST_ROOMS, index: 2 },
  { name: 'Photos', id: NATIVE_LISTING_MENU.PHOTOS, index: 3 },
  { name: 'Amenities', id: NATIVE_LISTING_MENU.AMENITIES, index: 4 },
  { name: 'Description', id: NATIVE_LISTING_MENU.DESCRIPTION, index: 5 },
  { name: 'House Rules', id: NATIVE_LISTING_MENU.HOUSE_RULES, index: 6 },
  { name: 'Availability & LoS', id: NATIVE_LISTING_MENU.AVAILABILITY, index: 7 },
  { name: 'iCal', id: NATIVE_LISTING_MENU.ICAL, index: 8 },
  { name: 'Fees & Taxes', id: NATIVE_LISTING_MENU.FEES, index: 9 },
  { name: 'Discounts', id: NATIVE_LISTING_MENU.DISCOUNTS, index: 10 },
  { name: 'Rates', id: NATIVE_LISTING_MENU.RATES, index: 11 },
]
export const bulkEditEnableAll: string[] = [
  NATIVE_LISTING_MENU.AMENITIES,
  NATIVE_LISTING_MENU.HOUSE_RULES,
  NATIVE_LISTING_MENU.FEES,
  NATIVE_LISTING_MENU.DISCOUNTS,
]
export const bulkEditEnableAirbnb: string[] = [...bulkEditEnableAll, NATIVE_LISTING_MENU.RATES]
export const bulkEditEnableNative: string[] = [
  ...bulkEditEnableAirbnb,
  NATIVE_LISTING_MENU.PROPERTY,
  NATIVE_LISTING_MENU.LOCATION,
  NATIVE_LISTING_MENU.GUEST_ROOMS,
  // NATIVE_LISTING_MENU.PHOTOS,
  NATIVE_LISTING_MENU.DESCRIPTION,
  NATIVE_LISTING_MENU.AVAILABILITY,
]
export const bulkEditEnableCurrency: string[] = [
  // NATIVE_LISTING_MENU.AVAILABILITY,
  NATIVE_LISTING_MENU.FEES,
  NATIVE_LISTING_MENU.RATES,
]
const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ ownerState }) => ({
  color: '#8D8D8D',
  display: 'flex',
  height: 16,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#8D8D8D',
  }),
  '& .QontoStepIcon-circle': {
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: '#F7F7F4',
    border: '1px solid #8D8D8D',
  },
}))
function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <img alt={'checked'} src={ic_checked} className="w-4 h-4" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  )
}
interface Props {
  listingData: any
  statusStep: any
}
const NativeListingSideBar = ({ listingData, statusStep }: Props) => {
  const navigate = useNavigate()
  const { type } = useParams() // dynamic route => type tab
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const isDraft = listingData?.status === PROPERTY_STATUS.DRAFT || (isEmpty(parseQuery?.id) && !isEditBulk)
  const typeBulkEdit = parseQuery.type // type bulk edit listings
  const isAirbnb = isPropertyAirbnb(listingData)
  const hanldeClickItem = (id: string) => {
    navigate(`/native-listing/${id}` + search)
  }
  const nativeMenuType = nativeMenu.find((v: any) => v.id === type)
  const activeStep = type === 'publish' ? 12 : nativeMenuType?.index || 0
  const renderTitleTooltip = (id: string) => {
    if (typeBulkEdit === TYPE_EDIT_LISTINGS.NATIVE) return 'This content can only be edited on individual listings'
    if (typeBulkEdit === TYPE_EDIT_LISTINGS.AIRBNB) {
      if (id === NATIVE_LISTING_MENU.ICAL) return 'This content can only be edited on individual listings'
      else return 'This content is automatically imported from Airbnb and cannot be edited'
    }
    // native and airbnb
    return 'This content cannot be edited for some of the listings selected. Edit listings individually to access this section'
  }
  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      sx={{
        '.MuiStepConnector-line': {
          minHeight: '16px !important',
        },
      }}
    >
      {nativeMenu.map((el: any) => {
        let isLocked = false
        if (typeBulkEdit === TYPE_EDIT_LISTINGS.NATIVE) isLocked = !bulkEditEnableNative.includes(el.id)
        if (typeBulkEdit === TYPE_EDIT_LISTINGS.AIRBNB) isLocked = !bulkEditEnableAirbnb.includes(el.id)
        if (typeBulkEdit === TYPE_EDIT_LISTINGS.ALL) isLocked = !bulkEditEnableAll.includes(el.id)
        if (isAirbnb) isLocked = ![...bulkEditEnableAirbnb, NATIVE_LISTING_MENU.ICAL].includes(el.id)
        if (isEditBulk && bulkEditEnableCurrency.includes(el.id) && isEmpty(parseQuery?.currency) && typeBulkEdit !== TYPE_EDIT_LISTINGS.AIRBNB) {
          isLocked = true
        }
        const completedResult = checkCompletedStep(el.id, statusStep)
        const isCreateNativeListing = isEmpty(search) || isDraft
        const isEnabled = isCreateNativeListing ? activeStep >= el.index : !isLocked
        return (
          <Step
            key={el.name}
            sx={{ marginLeft: '4px', height: '20px' }}
            onClick={() => {
              if (isEnabled && !isCreateNativeListing) hanldeClickItem(el.id)
            }}
          >
            <StepLabel
              StepIconComponent={({ active, ...otherProps }: any) => {
                return (
                  <QontoStepIcon
                    {...otherProps}
                    active={active}
                    completed={
                      isEmpty(search) ?
                        activeStep > el.index :
                        (isCreateNativeListing ? completedResult && isEnabled : completedResult)
                    }
                  />
                )
              }}
              sx={{ padding: 0 }}
            >
              <div className="flex items-center gap-3">
                <span
                  className={clsx(
                    `text-16-20`,
                    isEnabled ? 'text-grayscale-800' : 'text-neutral-500',
                    isEnabled && !isCreateNativeListing ? 'cursor-pointer' : 'cursor-not-allowed',
                    type === el?.id ? 'font-maison-neue-demibold' : 'font-maison-neue'
                  )}
                >
                  {el.name}
                </span>
                {isLocked && (
                  <BasicTooltip
                    placement="right"
                    title={renderTitleTooltip(el.id)}
                    sx={{ fontSize: 16 }}
                    arrow
                  >
                    <img alt={''} src={ic_lock} className="w-4 h-4" />
                  </BasicTooltip>
                )}
              </div>
            </StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}

export default NativeListingSideBar
