import { superHogCreateListing } from '../../api/listings'
import no_image from '../../assets/images/no_image_listing.jpg'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { FormError } from '../../interfaces'
import { PropertyDetailProps, SuperHogCreateListing } from '../../interfaces/listing'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setToastSuccess } from '../../redux/slices/common'
import { getCountryCodeFromName, handleErrorMessage, isEmailValid, isPostalCode } from '../../utils/common'
import { countries } from '../../utils/country'
import { renderLocation } from '../../utils/property'
import { IconClose } from '../common/Icons'
import BasicButton from '../ui/BasicButton'
import BasicInput from '../ui/BasicInput'
import BasicSelect from '../ui/BasicSelect'
import { Drawer } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import clsx from 'clsx'
import React, { MouseEvent, useEffect, useState } from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  propertiesNeedConfirm: any[]
  superHogStatus: string
  afterUpdateSuperhog?: (propertyIds: number[], newStatus: string) => void
}
const REQUIRED_FIELDS = ['town', 'postcode', 'hostFirstName', 'hostLastName', 'hostEmail']

interface FieldsError {
  friendlyName?: FormError
  addressLine1?: FormError
  town: FormError
  postcode: FormError
  countryIso?: FormError
  hostFirstName: FormError
  hostLastName: FormError
  hostEmail: FormError
}

const BulkEnableSuperHog: React.FC<Props> = ({
  isOpen,
  onClose,
  propertiesNeedConfirm,
  superHogStatus,
  afterUpdateSuperhog,
}) => {
  const dispatch = useAppDispatch()
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768
  const { profile } = useAppSelector((state) => state.user)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentIndex, setCurrentIndex] = useState<number>(-1)
  const [propertyData, setPropertyData] = useState<PropertyDetailProps | null>(null)
  const [data, setData] = useState<SuperHogCreateListing>({
    listingId: 0,
    friendlyName: '',
    hostFirstName: '',
    hostLastName: '',
    hostEmail: '',
    addressLine1: '',
    addressLine2: '',
    town: '',
    postcode: '',
    countryIso: '',
    superHogStatus: '',
  })
  const [errors, setErrors] = useState<FieldsError>({
    hostFirstName: { show: false, message: '' },
    hostLastName: { show: false, message: '' },
    hostEmail: { show: false, message: '' },
    postcode: { show: false, message: '' },
    town: { show: false, message: '' },
  })
  const [isEnableSkip, setIsEnableSkip] = useState<boolean>(false)

  useEffect(() => {
    if (isOpen) {
      setCurrentIndex(0)
      setIsEnableSkip(false)
    }
  }, [isOpen])

  useEffect(() => {
    if (propertiesNeedConfirm.length > 0 && currentIndex > -1) {
      const property = propertiesNeedConfirm[currentIndex]
      setPropertyData(property)
      setIsEnableSkip(false)
      const { address, externalName, id } = property
      const countryIso = address.countryCode || getCountryCodeFromName(address.country)
      setData({
        listingId: Number(id),
        friendlyName: externalName,
        addressLine1: address.address || '',
        countryIso: countryIso || '',
        postcode: address.zipcode || '',
        town: address.city || '',
        hostFirstName: profile?.firstName || '',
        hostLastName: profile?.lastName || '',
        hostEmail: profile?.user.email || profile?.email || '',
        superHogStatus: superHogStatus,
      })
    }
  }, [superHogStatus, propertiesNeedConfirm, currentIndex, profile])

  const handleCloseWrapper = (
    event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>,
    reason?: string
  ) => {
    if (reason !== 'backdropClick') {
      handleClose()
    }
  }

  const handleClose = () => {
    onClose()
  }

  const onChangeData = (field: string, value: string) => {
    setData((prevState) => ({ ...prevState, [field]: value }))
    if (REQUIRED_FIELDS.includes(field)) {
      setErrors((prevState) => ({ ...prevState, [field]: { show: false, message: '' } }))
    }
  }

  const handleConfirm = async () => {
    let isValid: boolean = true
    REQUIRED_FIELDS.forEach((field: string) => {
      // @ts-ignore
      if (!data[field] || !data[field].trim()) {
        setErrors((prevState) => ({ ...prevState, [field]: { show: true, message: 'Please enter a value' } }))
        isValid = false
      }
      if (field === 'postcode') {
        if (!isPostalCode(data[field])) {
          setErrors((prevState) => ({
            ...prevState,
            [field]: { show: true, message: 'Please enter a valid postcode' },
          }))
          isValid = false
        }
      }
      if (field === 'hostEmail') {
        if (!isEmailValid(data[field])) {
          setErrors((prevState) => ({ ...prevState, [field]: { show: true, message: 'Please enter a valid email' } }))
          isValid = false
        }
      }
    })
    if (!isValid) return
    try {
      setIsLoading(true)
      await superHogCreateListing(data)
      dispatch(setToastSuccess({ message: 'Success!' }))
      // update status on UI
      if (typeof afterUpdateSuperhog === 'function') {
        afterUpdateSuperhog([data.listingId], superHogStatus)
      }
      handleSkip()
    } catch (err: any) {
      setIsEnableSkip(true)
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSkip = () => {
    if (currentIndex === propertiesNeedConfirm.length - 1) {
      // lastItem => submit and close popup
      onClose()
    } else {
      setCurrentIndex((prevState) => prevState + 1)
    }
  }

  const renderContent = () => {
    return (
      <div>
        {/*---Header---*/}
        <div className={'p-[24px] shadow-[0px_1px_0px_0px_#E9E9E4]'}>
          <div className={'flex items-center gap-[24px] '}>
            <div>
              <p className={'font-maison-neue-medium text-16-20 text-neutral-800 mb-[4px]'}>Confirm your information</p>
              <p className={'font-maison-neue text-14-20 text-neutral-600'}>
                Your {propertiesNeedConfirm.length} {propertiesNeedConfirm.length > 1 ? 'listings' : 'listing'}{' '}
                information below will be submitted to SUPERHOG.
              </p>
            </div>
            <IconButton onClick={handleClose} sx={{ padding: '6px', minWidth: '24px', marginLeft: 'auto' }}>
              <IconClose />
            </IconButton>
          </div>
        </div>

        {/*---Body 1---*/}
        <div className={'p-[24px]'}>
          <div className={'border border-neutral-300 rounded-[16px] flex gap-[12px] p-[16px]'}>
            <div className={'w-[44px] min-w-[44px] h-[44px] bg-neutral-500 rounded-[8px]'}>
              <img
                src={propertyData?.thumbnail || no_image}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.src = no_image
                }}
                alt="thumb"
                className="rounded-[8px] min-w-[44px] w-[44px] h-[44px] object-cover"
              />
            </div>
            <div>
              <p className={'font-maison-neue text-16-20 text-neutral-900 mb-[4px] line-clamp-1'}>
                {propertyData?.externalName}
              </p>
              <p className={'font-maison-neue text-16-20 text-neutral-500'}>
                {propertyData ? renderLocation(propertyData?.address) : ' '}
              </p>
            </div>
          </div>
          <div className={'flex gap-[12px] justify-center items-center mt-[16px]'}>
            {propertiesNeedConfirm.map((item, index) => (
              <div
                key={index}
                className={clsx('w-[8px] h-[8px] rounded-full ', {
                  'bg-neutral-300': index !== currentIndex,
                  'bg-neutral-900': index === currentIndex,
                })}
              />
            ))}
          </div>
        </div>

        {/*---Body 2---*/}
        <div className={'py-[24px] px-[16px] md:px-[24px] bg-neutral-100 flex flex-col gap-[24px]'}>
          <div>
            <BasicInput
              label={'City'}
              value={data.town}
              onChange={(event) => onChangeData('town', event.target.value)}
              error={errors.town}
              placeholder={'Your listing’s city'}
            />
          </div>
          <div className={'flex flex-col md:flex-row gap-[24px] md:gap-[12px]'}>
            <div className={'w-full md:w-1/2'}>
              <BasicSelect
                label={'Country'}
                options={countries.map((v: any) => ({ ...v, label: v.name, value: v.iso }))}
                value={data.countryIso}
                onChange={(event) => onChangeData('countryIso', event.target.value)}
                // showIconEdit={true}
                error={errors.countryIso}
                placeholder={'Your listing’s country'}
              />
            </div>
            <div className={'w-full md:w-1/2'}>
              <BasicInput
                label={'Postal code'}
                value={data.postcode}
                onChange={(event) => onChangeData('postcode', event.target.value)}
                placeholder={'Your listing’s postal code'}
                // showIconEdit={true}
                error={errors.postcode}
              />
            </div>
          </div>
          <div className={'flex flex-col md:flex-row gap-[24px] md:gap-[12px]'}>
            <div className={'w-full md:w-1/2'}>
              <BasicInput
                label={'First name'}
                value={data.hostFirstName}
                onChange={(event) => onChangeData('hostFirstName', event.target.value)}
                placeholder={'Your first name'}
                // readOnly={true}
                // showIconEdit={true}
                error={errors.hostFirstName}
              />
            </div>
            <div className={'w-full md:w-1/2'}>
              <BasicInput
                label={'Last name'}
                value={data.hostLastName}
                onChange={(event) => onChangeData('hostLastName', event.target.value)}
                placeholder={'Your last name'}
                // readOnly={true}
                // showIconEdit={true}
                error={errors.hostLastName}
              />
            </div>
          </div>
          <div>
            <BasicInput
              label={'Email'}
              value={data.hostEmail}
              onChange={(event) => onChangeData('hostEmail', event.target.value)}
              placeholder={'Your email'}
              // readOnly={true}
              // showIconEdit={true}
              error={errors.hostEmail}
            />
          </div>
        </div>

        {/*---Footer---*/}
        <div className={'p-[24px]'}>
          <div className={'flex gap-[8px] justify-end'}>
            <BasicButton variant={'outlined'} isRadius100={true} clases={'w-full md:w-auto'} onClick={onClose}>
              Cancel
            </BasicButton>
            <BasicButton
              variant={'outlined'}
              isRadius100={true}
              clases={`w-full md:w-auto ${isEnableSkip ? '' : 'hidden'}`}
              onClick={handleSkip}
            >
              Skip
            </BasicButton>
            <BasicButton
              variant={'contained'}
              isRadius100={true}
              color={'red'}
              clases={'w-full md:w-auto'}
              onClick={handleConfirm}
              loading={isLoading}
            >
              Confirm & Next
            </BasicButton>
          </div>
        </div>
      </div>
    )
  }

  if (isMobile) {
    return (
      <Drawer
        anchor={'bottom'}
        open={isOpen}
        onClose={handleClose}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            borderRadius: '24px 24px 0px 0px',
            height: 'auto',
            maxHeight: 'calc(100% - 16px)',
          },
        }}
      >
        {renderContent()}
      </Drawer>
    )
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseWrapper}
      maxWidth={'md'}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '24px',
          padding: 0,
          margin: 0,
          position: 'relative',
          backgroundColor: '#FFFFF',
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)',
          overflowY: 'auto',
          scrollbarWidth: 'none',
          width: '480px',
          '-ms-overflow-style': 'none' /* IE 10+ */,
          '&::-webkit-scrollbar': {
            background: 'transparent',
            width: 0,
          },
          'scroll-behavior': 'smooth',
        },
        '& .MuiDialogContent-root': {
          padding: 0,
          overflowY: 'initial',
        },
      }}
    >
      <DialogContent>{renderContent()}</DialogContent>
    </Dialog>
  )
}

export default BulkEnableSuperHog
