// import DisabledSection from '../common/DisabledSection'
import DisabledSectionOnEdit from '../common/DisabledSectionOnEdit'
import BasicInput from '../ui/BasicInput'
import BasicSelect from '../ui/BasicSelect'
import NativeListingBackAndContinue from './NativeListingBackAndContinue'
import clsx from 'clsx'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getPropertyTypes, updateBulkProperties, updateProperty } from 'src/api/native-listing'
import ic_bed_double_hotel from 'src/assets/icons/ic_bed_double_hotel.svg'
import ic_building_hotel from 'src/assets/icons/ic_building_hotel.svg'
import ic_house from 'src/assets/icons/ic_house.svg'
import { NATIVE_LISTING_MENU, PMS_TYPE } from 'src/contants/common'
import { PROPERTY_STATUS, RENTAL_TYPE } from 'src/contants/native'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { setBulkListingData, setBulkListingSection, setNativeListingProperty } from 'src/redux/slices/listing'
import { handleErrorMessage, isEmpty, isNumber } from 'src/utils/common'

interface Props {
  listingData: any
  fetchStatusStep: any
}
const NativeListingProperty = ({ listingData, fetchStatusStep }: Props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isOnboarding = parseQuery?.f === 'onboarding'
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const isDraft = listingData?.status === PROPERTY_STATUS.DRAFT || isEmpty(parseQuery?.id)
  const { nativeListingProperty, bulkListingData, bulkListingSection } = useAppSelector((state) => state.listing)
  const [propertyTypes, setPropertyTypes] = useState<any[]>([])
  const [squareType, setSquareType] = useState<'meters' | 'feet'>('meters')
  const [squareMeters, setSquareMeters] = useState<string>('')
  const [propertyTypeId, setPropertyTypeId] = useState<number | null>(null)
  const [roomType, setRoomType] = useState<string>(RENTAL_TYPE.ENTIRE_PLACE)
  const [toggleEdit, setToggleEdit] = useState<any>({ propertyType: false, rentalType: false })
  const isHospitable = listingData?.pmsType === PMS_TYPE.HOSPITABLE

  useEffect(() => {
    if (listingData) {
      setPropertyTypeId(listingData?.propertyTypeId)
      setRoomType(listingData?.roomType || RENTAL_TYPE.ENTIRE_PLACE)
      setSquareType(listingData?.squareType || 'meters')
      setSquareMeters(listingData?.squareMeters || null)
    }
  }, [listingData])

  useEffect(() => {
    if (!isEmpty(nativeListingProperty)) {
      setPropertyTypeId(nativeListingProperty?.propertyTypeId)
      setRoomType(nativeListingProperty?.roomType || RENTAL_TYPE.ENTIRE_PLACE)
      setSquareMeters(nativeListingProperty?.squareMeters)
      setSquareType(nativeListingProperty?.squareType)
    }
  }, [nativeListingProperty])
  useEffect(() => {
    if (!isEmpty(bulkListingData) && isEditBulk) {
      setPropertyTypeId(bulkListingData?.propertyTypeId || null)
      setRoomType(bulkListingData?.roomType || RENTAL_TYPE.ENTIRE_PLACE)
      setSquareMeters(bulkListingData?.squareMeters || '')
      setSquareType(bulkListingData?.squareType || 'meters')
    }
  }, [bulkListingData])

  const fetchData = async () => {
    try {
      const res: any = await getPropertyTypes()
      if (res?.success) setPropertyTypes(res?.data.filter((v: any) => v?.id !== 1 && v?.id !== 10 && v?.id !== 36))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleSubmit = async (isExit?: boolean) => {
    try {
      if (isEditBulk) {
        let dataDTO: any = {}
        if (toggleEdit.propertyType) dataDTO = { ...dataDTO, propertyTypeId }
        if (toggleEdit.rentalType) {
          dataDTO = { ...dataDTO, roomType, squareType }
          if (
            squareMeters !== '' &&
            String(squareMeters)?.trim() !== '' &&
            squareMeters !== null &&
            Number(squareMeters) >= 0
          ) {
            dataDTO.squareMeters = Number(squareMeters)
          } else {
            dataDTO.squareMeters = null
          }
        }
        if (toggleEdit.propertyType || toggleEdit.rentalType) {
          dispatch(setBulkListingData({ ...bulkListingData, propertyTypeId, roomType, squareMeters, squareType }))
          const propertyIds = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
          await updateBulkProperties({ propertyIds, propertyInfo: dataDTO })
          setToggleEdit({ propertyType: false, rentalType: false })
          dispatch(setToastSuccess({ message: 'Your changes have been applied.' }))
        }
        fetchStatusStep()
      } else if (propertyTypeId) {
        let dataDTO: any = { propertyTypeId, roomType, squareType }
        if (
          squareMeters !== '' &&
          String(squareMeters)?.trim() !== '' &&
          squareMeters !== null &&
          Number(squareMeters) >= 0
        ) {
          dataDTO.squareMeters = Number(squareMeters)
        } else {
          dataDTO.squareMeters = null
        }
        if (!isEmpty(parseQuery.id)) {
          if (!isEmpty(listingData?.bathroomTypes) && roomType?.toLowerCase() === 'entire place') {
            dataDTO = {
              ...dataDTO,
              bathrooms: [...(listingData?.bathroomTypes || [])].map(() => ({ name: 'Private', type: 'private' })),
            }
          }
          await updateProperty(Number(parseQuery.id), dataDTO)
          if (!isDraft) dispatch(setToastSuccess({ message: 'Your changes have been applied.' }))
          fetchStatusStep()
        } else dispatch(setNativeListingProperty(dataDTO))
        if (isExit) navigate(isOnboarding ? '/' : `/listings`)
        else if (isDraft) navigate(`/native-listing/${NATIVE_LISTING_MENU.LOCATION}` + search)
      }
    } catch (err: any) {
      handleErrorMessage(err)
    }
  }

  const renderRadio = (checked: boolean) => {
    return (
      <div
        className={clsx(
          'w-[20px] h-[20px] min-w-[20px] rounded-[50%] bg-white',
          checked ? 'border-[6px] border-neutral-900' : 'border border-neutral-300'
        )}
      />
    )
  }

  const renderContent = () => {
    return (
      <div className="flex flex-col">
        <div className="flex gap-4 mb-4">
          {[
            { id: 10, name: 'House', icon: ic_house },
            { id: 36, name: 'Apartment', icon: ic_building_hotel },
            { id: 1, name: 'Bed & Breakfast', icon: ic_bed_double_hotel },
          ].map((el: any, idx: number) => {
            const isSelected = propertyTypeId === el.id
            return (
              <div
                key={idx}
                className={clsx(
                  'w-1/3 h-[148px] flex flex-col items-center justify-center gap-2 bg-white border rounded-[12px] shadow[0px_1px_2px_rgba(0,0,0,0.05)] cursor-pointer',
                  isSelected ? 'border-neutral-900 border-[2px]' : 'border-neutral-300'
                )}
                onClick={() => setPropertyTypeId(el.id)}
                role="presentation"
              >
                <img src={el.icon} alt="" style={{ width: 32, height: 32 }} />
                <span className="text-16-24 font-maison-neue text-neutral-800 text-center">{el.name}</span>
              </div>
            )
          })}
        </div>
        <p className="text-16-20 font-maison-neue text-neutral-900 text-center mb-4">or</p>
        <BasicSelect
          label={''}
          placeholder="Choose from..."
          options={propertyTypes.map((v: any) => ({ ...v, value: v?.id, label: v?.name }))}
          value={`${propertyTypeId === 1 || propertyTypeId === 10 || propertyTypeId === 36 ? '' : propertyTypeId || ''
            }`}
          onChange={(e: any) => {
            setPropertyTypeId(Number(e?.target?.value))
          }}
        />
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-8">
      <p className="text-24-32 md:text-28-36 text-neutral-800 font-maison-neue tracking-[-0.01em]">
        {`What best describes your ${isEditBulk ? 'properties?' : 'property?'}`}
      </p>
      <DisabledSectionOnEdit
        isDisable={isHospitable}
        title="Property type"
        openEdit={toggleEdit.propertyType}
        handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, propertyType: value })}
      >
        {renderContent()}
      </DisabledSectionOnEdit>
      <DisabledSectionOnEdit
        isDisable={isHospitable}
        title="Rental type"
        openEdit={toggleEdit.rentalType}
        handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, rentalType: value })}
      >
        <BasicSelect
          label={''}
          placeholder="Choose from..."
          options={[
            { value: RENTAL_TYPE.ENTIRE_PLACE, label: RENTAL_TYPE.ENTIRE_PLACE },
            { value: RENTAL_TYPE.PRIVATE_ROOM, label: RENTAL_TYPE.PRIVATE_ROOM },
            { value: RENTAL_TYPE.SHARED_ROOM, label: RENTAL_TYPE.SHARED_ROOM },
          ]}
          value={roomType}
          onChange={(e: any) => {
            setRoomType(e?.target?.value)
          }}
        />
        <div>
          <p className={'text-neutral-800 text-14-18 font-maison-neue-medium mb-2 mt-4'}>Property size</p>
          <div className="flex items-center gap-4">
            <div className="w-1/2">
              <BasicInput
                placeholder="100..."
                value={squareMeters}
                maxLength={8}
                onChange={(e: any) => {
                  const newValue = e?.target.value.trim()
                  if (newValue === '' || (isNumber(newValue) && Number(newValue) <= 10000000)) setSquareMeters(newValue)
                }}
              />
            </div>
            <div
              className="flex items-center gap-3 cursor-pointer"
              onClick={() => setSquareType('meters')}
              role="presentation"
            >
              {renderRadio(squareType === 'meters')}
              <span className="text-14-18 font-maison-neue text-neutral-800">Square meters</span>
            </div>
            <div
              className="flex items-center gap-3 cursor-pointer"
              onClick={() => setSquareType('feet')}
              role="presentation"
            >
              {renderRadio(squareType === 'feet')}
              <span className="text-14-18 font-maison-neue text-neutral-800">Square feet</span>
            </div>
          </div>
        </div>
      </DisabledSectionOnEdit>

      <NativeListingBackAndContinue
        onSubmit={() => handleSubmit()}
        isEdit={!isDraft}
        isDisabledSubmit={
          isEditBulk ?
            !(toggleEdit.propertyType || toggleEdit.rentalType) :
            (isEmpty(propertyTypeId) ||
              isEmpty(roomType) ||
              isEmpty(squareMeters) ||
              Number(squareMeters) > 10000000 ||
              Number(squareMeters) < 1
            )
        }
      />
      <div
        id={`${NATIVE_LISTING_MENU.PROPERTY}_save_exit`}
        className="hidden"
        onClick={() => handleSubmit(true)}
        role="presentation"
      />
      <div
        id={`${NATIVE_LISTING_MENU.PROPERTY}_save`}
        className="hidden"
        onClick={() => {
          if (isEditBulk) {
            if (isEmpty(bulkListingSection) && (toggleEdit.propertyType || toggleEdit.rentalType)) {
              let result: string[] = []
              if (toggleEdit.propertyType) result.push('Property type')
              if (toggleEdit.rentalType) result.push('Rental type')
              dispatch(setBulkListingSection(result.join(', ')))
            } else handleSubmit()
          } else handleSubmit()
        }}
        role="presentation"
      />
    </div>
  )
}

export default NativeListingProperty
