import React, { useRef } from 'react'
import { uploadLogoHost } from 'src/api/user'
import BasicButton from 'src/components/ui/BasicButton'
import { SETUP_GUIDE } from 'src/contants/common'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setLoading } from 'src/redux/slices/common'
import { getWidthHeightImage, handleErrorMessage } from 'src/utils/common'
import { saveUserInfo } from 'src/utils/user'

interface Props {}

const SettingSiteLogo: React.FC<Props> = () => {
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector((state) => state.user)
  const inputLogo = useRef<HTMLInputElement | null>(null)

  const handleClickUpload = async () => {
    const inputEl = document.getElementById('file-input')
    if (inputEl) inputEl.click()
  }

  const handleUpload = async (e: any) => {
    const file: File = e.target.files[0]
    if (file) {
      const { width, height }: any = await getWidthHeightImage(file)
      if (Number(width) > 1920 || Number(height) > 1080) {
        handleErrorMessage({ message: 'Maximum logo image size: 1920x1080px' })
        return
      }
      if (Number(width) < 128 || Number(height) < 80) {
        handleErrorMessage({ message: 'Minimum logo image size: 128x80px' })
        return
      }
      try {
        dispatch(setLoading(true))
        const formData = new FormData()
        formData.append('file', file)
        const res: any = await uploadLogoHost(formData)
        if (res.success) {
          const hostLogo = res.data.hostLogo
          saveUserInfo({ ...profile, hostLogo })
        }
      } catch (err: any) {
        handleErrorMessage(err)
      } finally {
        dispatch(setLoading(false))
      }
    }
  }

  return (
    <div className={'flex flex-col md:flex-row justify-between items-start gap-[12px]'} id={SETUP_GUIDE.ADD_A_LOGO}>
      <div>
        <p className={'font-maison-neue-medium text-neutral-800 text-16-20'}>Logo</p>
        <p className={'font-maison-neue text-neutral-600 text-14-18 mt-[4px]'}>
          We recommend SVGs or other image files with minimal white space around the logo. Height must be at least 80px.
        </p>
        {profile?.hostLogo && (
          <div className={'mt-[12px]'}>
            <img
              src={profile?.hostLogo + '?time=' + +new Date()}
              alt=""
              className="w-[42px] h-[42px] rounded-full object-cover"
            />
          </div>
        )}
      </div>
      <BasicButton size={'md'} variant={'outlined'} clases={'w-full md:w-auto'} isRadius100 onClick={handleClickUpload}>
        {profile?.hostLogo ? 'Update' : 'Add'}
        <input
          ref={inputLogo}
          id="file-input"
          type="file"
          name="name"
          className="hidden"
          onChange={handleUpload}
          onClick={() => {
            const element = inputLogo.current as HTMLInputElement
            if (element) element.value = ''
          }}
        />
      </BasicButton>
    </div>
  )
}

export default SettingSiteLogo
