import CancelReservationCrypto from './CancelReservationCrypto'
import CancelReservationCryptoNoRefund from './CancelReservationCryptoNoRefund'
import CancelReservationFiat from './CancelReservationFiat'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import { PAYMENT_METHOD, PMS } from 'src/contants/common'
import { PROPERTY_SOURCE } from 'src/contants/native'
import { RESERVATION_STATUS } from 'src/contants/property'
import { renderSourceType } from 'src/utils/property'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { getReservationConnectionStatus } from 'src/api/reservation'
import { setToastError } from 'src/redux/slices/common'
import { useConnectModal } from 'thirdweb/react'

interface Props {
  data: any
  handleChangeStatus: (callback?: () => void) => void
}

const CancelReservation: React.FC<Props> = ({ data, handleChangeStatus }) => {
  const {
    // superHogStayState,
    // superHogVerifyStatus,
    checkinDate,
    checkoutDate,
    timezone,
    pmsType,
    status: reservationStatus,
    // payoutStatus,
    paymentMethod,
  } = data
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector((state) => state.user)
  const isSmartWallet = profile?.user?.hasSmartWallet
  const channel = data?.listingInfo?.channel
  const source = data?.listingInfo?.source
  const isHospitable = channel === PROPERTY_SOURCE.HOSPITABLE
  const isActiveCancelByHost =
    (pmsType === PMS.DTRAVEL && source === PROPERTY_SOURCE.DTRAVEL) ||
    (channel === PROPERTY_SOURCE.HOSPITABLE || channel === PROPERTY_SOURCE.AIRBNB)
  const [isShowCancel, setIsShowCancel] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [openInsufficientBalance, setOpenInsufficientBalance] = useState<boolean>(false)
  const [titleDialog, setTitleDialog] = useState<string>('Cancel this reservation')
  const [hasConnection, setHasConnection] = useState<boolean>(false)

  const { isConnecting } = useConnectModal();

  const isWalletModalOpen = isSmartWallet ? isConnecting : false // fix bug loop when connect smart wallet 

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const isBeforeCheckOut = moment().isSameOrBefore(momentTZ.tz(checkoutDate, timezone), 'day')
    // const isFailedSuperHog = typeof superHogVerifyStatus === 'boolean' && superHogVerifyStatus === false
    const isValidStatus =
      reservationStatus === RESERVATION_STATUS.NEW ||
      reservationStatus === RESERVATION_STATUS.MODIFIED ||
      reservationStatus === RESERVATION_STATUS.COMPLETED
    // const isNotPayout = !payoutStatus || payoutStatus.toUpperCase() !== 'PAYOUT'
    setIsShowCancel(isBeforeCheckOut && isValidStatus)
  }, [checkoutDate, timezone, reservationStatus])

  const fetchReservationConnectionStatus = async () => {
    try {
      const res: any = await getReservationConnectionStatus()
      if (res.data) setHasConnection(res.data?.hasConnection)
    } catch (error) { console.log(error) }
  }

  useEffect(() => {
    if (isHospitable) fetchReservationConnectionStatus()
  }, [isHospitable])
  const renderPMSContent = () => {
    return (
      <div>
        <p className="text-16-24 font-maison-neue text-neutral-800 text-center mb-6">
          {`This reservation is handled via ${renderSourceType(
            channel
          )} and cannot be canceled via Dtravel. To cancel it, visit your ${renderSourceType(channel)} dashboard.`}
        </p>
        <BasicButton variant={'contained'} color={'red'} isRadius100={true} clases={'w-full'} onClick={handleClose}>
          Close
        </BasicButton>
      </div>
    )
  }

  const isNoRefund = moment().isSameOrAfter(momentTZ.tz(checkinDate, timezone), 'day') || Number(data?.finalPrice) === 0

  return (
    <>
      {isShowCancel && (
        <BasicButton
          variant={'outlined'}
          color={'red'}
          size={'md'}
          isRadius100={true}
          clases={'w-full md:w-auto whitespace-nowrap'}
          onClick={() => {
            if (isHospitable && !hasConnection) {
              dispatch(setToastError({ message: "Your Airbnb account was already disconnected from Dtravel. Please go to Airbnb to cancel this reservation." }))

            } else handleOpen()
          }}
        >
          Cancel reservation
        </BasicButton>
      )}
      <BasicDialog isOpen={open && !isWalletModalOpen} onClose={handleClose} title={titleDialog}>
        {isActiveCancelByHost && paymentMethod === PAYMENT_METHOD.CRYPTO && (
          <>
            {isNoRefund ? (
              <CancelReservationCryptoNoRefund
                data={data}
                handleChangeStatus={handleChangeStatus}
                handleClose={handleClose}
                setTitleDialog={setTitleDialog}
              />
            ) : (
              <>
                <CancelReservationCrypto
                  data={data}
                  handleChangeStatus={handleChangeStatus}
                  handleClose={handleClose}
                  setTitleDialog={setTitleDialog}
                />
              </>
            )}
          </>
        )}
        {isActiveCancelByHost && paymentMethod === PAYMENT_METHOD.FIAT && (
          <CancelReservationFiat
            isNoRefund={isNoRefund}
            data={data}
            handleChangeStatus={handleChangeStatus}
            handleClose={handleClose}
            handleOpenInsufficientBalance={() => setOpenInsufficientBalance(true)}
          />
        )}
        {!isActiveCancelByHost && renderPMSContent()}
      </BasicDialog>
      <BasicDialog
        isOpen={openInsufficientBalance}
        onClose={() => setOpenInsufficientBalance(false)}
        title={'We couldn’t cancel this reservation'}
      >
        <p className="text-16-24 font-maison-neue text-neutral-800 text-center mb-6">
          Due to an insufficient balance in your Stripe account, we couldn’t refund your guest or cancel this
          reservation. Please try again.
        </p>
        <BasicButton
          variant={'contained'}
          color={'red'}
          isRadius100={true}
          clases={'w-full'}
          onClick={() => setOpenInsufficientBalance(false)}
        >
          Close
        </BasicButton>
      </BasicDialog>
    </>
  )
}

export default CancelReservation
