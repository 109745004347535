import { getStripeStatus } from '../../api/integration'
import { getListingDetail, superHogCreateListing, superHogDisableListing } from '../../api/listings'
import { PROPERTY_STATUS } from '../../contants/native'
import { SUPERHOG_STATUS } from '../../contants/property'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setToastError, setToastSuccess, setTriggerListingProperty } from '../../redux/slices/common'
import { getCountryCodeFromName, handleErrorMessage, isEmpty, isPostalCode } from '../../utils/common'
import BasicButton from '../ui/BasicButton'
import BasicSwipeDrawer from '../ui/BasicSwipeDrawer'
import BulkEnableSuperHog from './BulkEnableSuperHog'
import PropertyEditDamageProtection from './PropertyEditDamageProtection'
import PropertyEditGuestScreening from './PropertyEditGuestScreening'
import PropertyEditStatus from './PropertyEditStatus'
import PropertyEditStripe from './PropertyEditStripe'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isChannexAirbnb, isPropertyAirbnb, isPropertyDtravel } from 'src/utils/property'
import { NATIVE_LISTING_STEPS, TYPE_EDIT_LISTINGS } from 'src/contants/common'
import { setBulkListingData } from 'src/redux/slices/listing'
import queryString from 'query-string'

type EditType = 'status' | 'guest_screening' | 'damage_protection' | 'stripe' | 'details' | 'rates'

interface Props {
  selectedIds: number[]
  afterChangeStatus?: (propertyIds: number[], newStatus: string) => void
  afterUpdateSuperhog?: (propertyIds: number[], newStatus: string) => void
  isSelectedAllPage?: boolean
  propertySelected: any[]
}

const PropertyEdit: React.FC<Props> = ({ selectedIds, afterChangeStatus, afterUpdateSuperhog, isSelectedAllPage, propertySelected }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { profile } = useAppSelector((state) => state.user)
  const propertyNativeSelected = (propertySelected || []).filter((item: any) => isPropertyDtravel(item) || isChannexAirbnb(item))
  const propertyChannexSelected = (propertySelected || []).filter((item: any) => isChannexAirbnb(item))
  const propertyAirbnbSelected = (propertySelected || []).filter((item: any) => isPropertyAirbnb(item))
  const isEnableEditDetail = !isEmpty(selectedIds) && [...propertyNativeSelected, ...propertyAirbnbSelected].length === selectedIds.length
  const isEnableEditRate = !isEmpty(selectedIds) && (propertyNativeSelected.length === selectedIds.length || propertyAirbnbSelected.length === selectedIds.length)
  const bulkCurrency = [...new Set([...propertyNativeSelected, ...propertyAirbnbSelected].map((el: any) => el?.propertyPrice?.currency))]
  const [anchorElStatus, setAnchorElStatus] = useState<HTMLButtonElement | null>(null)
  const [anchorElGuestScreening, setAnchorElGuestScreening] = useState<HTMLButtonElement | null>(null)
  const [anchorElDamageProtection, setAnchorElDamageProtection] = useState<HTMLButtonElement | null>(null)
  const [anchorElStripe, setAnchorElStripe] = useState<HTMLButtonElement | null>(null)
  const [isOpenPopupEditCommon, setIsOpenPopupEditCommon] = useState<boolean>(false)
  const [isOpenPopupBulkSuperHog, setIsOpenPopupBulkSuperHog] = useState<boolean>(false)
  const [superHogStatus, setSuperHogStatus] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [propertiesNeedConfirm, setPropertiesNeedConfirm] = useState<any[]>([])
  const [stripeAccounts, setStripeAccounts] = useState<any[]>([])

  useEffect(() => {
    async function fetchStripeStatus() {
      const res: any = await getStripeStatus()
      if (res.success) {
        const stripeAccounts = res.data.stripeAccounts
        setStripeAccounts(Array.isArray(stripeAccounts) ? stripeAccounts : [])
      }
    }
    fetchStripeStatus()
  }, [])

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>, _type: EditType) => {
    if (_type === 'status') {
      setAnchorElStatus(event.currentTarget)
    }
    if (_type === 'guest_screening') {
      setAnchorElGuestScreening(event.currentTarget)
    }
    if (_type === 'damage_protection') {
      setAnchorElDamageProtection(event.currentTarget)
    }
    if (_type === 'stripe') {
      setAnchorElStripe(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorElStatus(null)
    setAnchorElGuestScreening(null)
    setAnchorElDamageProtection(null)
    setAnchorElStripe(null)
  }

  const togglePopupEditCommonMobile = () => {
    setIsOpenPopupEditCommon((prevState) => !prevState)
  }

  const onOpenEditMobile = (event: React.MouseEvent<HTMLButtonElement>, _type: EditType) => {
    togglePopupEditCommonMobile()
    handleOpen(event, _type)
  }

  const handleOpenPopupBulkSuperHog = () => {
    setIsOpenPopupBulkSuperHog(true)
  }
  const handleClosePopupBulkSuperHog = () => {
    setIsOpenPopupBulkSuperHog(false)
  }

  const validateSuperHogData = (property: any, newStatus: string) => {
    const { externalName, address, superhogStatus, status } = property
    const isNativeListing = isPropertyDtravel(property)
    const isIncomplete = isNativeListing && [PROPERTY_STATUS.DRAFT, PROPERTY_STATUS.PENDING_REVIEW].includes(status)
    if (isIncomplete) {
      return 'invalid'
    }
    // if currentStatus = KYG_DAMAGE, newStatus = KYG => ignore
    if (newStatus === superhogStatus || superhogStatus === SUPERHOG_STATUS.KYG_DAMAGE) {
      return 'ignore'
    }

    const addressLine1 = address.address
    const countryIso = address.countryCode || getCountryCodeFromName(address.country)
    const town = address.city
    const isPostcodeValid = !!address.zipcode && isPostalCode(address.zipcode)
    const hostFirstName = profile?.firstName || '',
      hostLastName = profile?.lastName || '',
      hostEmail = profile?.user.email || profile?.email || ''
    if (
      !hostFirstName ||
      !hostFirstName?.trim() ||
      !hostLastName ||
      !hostLastName?.trim() ||
      !hostEmail ||
      !addressLine1 ||
      !countryIso ||
      !town ||
      !isPostcodeValid ||
      !externalName
    ) {
      return 'needConfirm'
    }
    return 'valid'
  }
  const handleEnableSuperhog = async (ids: number[], newStatus: string) => {
    try {
      setLoading(true)
      let arrDettails: any[] = []
      let validProperties: any[] = []
      let invalidProperties: any[] = []
      let ignoreProperties: any[] = []
      let needConfirmProperties: any[] = []
      for (let id of ids) {
        arrDettails.push(getListingDetail(id).then((res) => res.data))
      }
      const resDetails = await Promise.all(arrDettails)
      // validate each property
      for (let property of resDetails) {
        const validateStatus: string = validateSuperHogData(property, newStatus)
        if (validateStatus === 'ignore') {
          ignoreProperties.push(property)
          // not handle
        }
        if (validateStatus === 'valid') {
          validProperties.push(property)
        }
        if (validateStatus === 'invalid') {
          invalidProperties.push(property)
        }
        if (validateStatus === 'needConfirm') {
          needConfirmProperties.push(property)
        }
      }
      // auto submit all property valid
      if (invalidProperties.length > 0) {
        dispatch(
          setToastError({
            message: `${invalidProperties.length}/${ids.length} listing${invalidProperties.length > 1 ? 's' : ''
              } cannot be edited due to the Incomplete or Pending Review status.`,
          })
        )
      }
      if (validProperties.length > 0) {
        //auto submit
        const arrEnableSh = []
        const validIds = []
        for (let property of validProperties) {
          const { externalName, address, id } = property
          const countryIso = address.countryCode || getCountryCodeFromName(address.country)
          const bodyData = {
            listingId: Number(property.id),
            friendlyName: externalName,
            addressLine1: address.address,
            countryIso: countryIso,
            postcode: address.zipcode,
            town: address.city,
            hostFirstName: profile?.firstName || '',
            hostLastName: profile?.lastName || '',
            hostEmail: profile?.user.email || profile?.email || '',
            superHogStatus: newStatus,
          }
          arrEnableSh.push(superHogCreateListing(bodyData))
          validIds.push(id)
        }
        await Promise.all(arrEnableSh)
        dispatch(
          setToastSuccess({
            message: `${validProperties.length}/${ids.length} listing${validProperties.length > 1 ? 's' : ''
              } have been successfully updated.`,
          })
        )
        // update status on UI
        if (typeof afterUpdateSuperhog === 'function') {
          afterUpdateSuperhog(validIds, newStatus)
        }
      }
      if (needConfirmProperties.length > 0) {
        setPropertiesNeedConfirm(needConfirmProperties)
        // open popup bulk enable
        handleOpenPopupBulkSuperHog()
      }
      handleClose() // close current Apply popup
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const handleDisableSuperhog = async (ids: number[]) => {
    try {
      setLoading(true)
      const arrPromise = []
      for (let id of ids) {
        arrPromise.push(superHogDisableListing(id))
      }
      await Promise.all(arrPromise)
      dispatch(
        setToastSuccess({
          message: `${ids.length}/${ids.length} listing${ids.length > 1 ? 's' : ''} have been successfully updated.`,
        })
      )
      // update status on UI
      if (typeof afterUpdateSuperhog === 'function') {
        afterUpdateSuperhog(ids, '')
      }
      handleClose()
    } catch (err) {
      handleErrorMessage(err)
    } finally {
      setLoading(false)
    }
  }

  const handleApplySuperHog = async (value: string, status: string) => {
    setSuperHogStatus(status)
    if (value === 'on') {
      await handleEnableSuperhog(selectedIds, status)
    } else {
      await handleDisableSuperhog(selectedIds)
    }
    dispatch(setTriggerListingProperty()) // to recall api
  }

  const renderEditBtn = (_type: EditType | 'common') => {
    let text = ''
    if (_type === 'status') {
      text = 'Edit status'
    }
    if (_type === 'guest_screening') {
      text = 'Edit guest screening'
    }
    if (_type === 'damage_protection') {
      text = 'Edit damage protection'
    }
    if (_type === 'details') {
      text = 'Details'
    }
    if (_type === 'rates') {
      text = 'Rates'
    }
    if (_type === 'common') {
      text = 'Edit...'
    }
    if (_type === 'stripe') {
      text = 'Stripe'
    }
    const open =
      (Boolean(anchorElStatus) && _type === 'status') ||
      (Boolean(anchorElGuestScreening) && _type === 'guest_screening') ||
      (Boolean(anchorElDamageProtection) && _type === 'damage_protection') ||
      (Boolean(anchorElStripe) && _type === 'stripe')
    const isDisabled = _type === 'details' ?
      !isEnableEditDetail :
      _type === 'rates' ?
        !(isEnableEditRate && (bulkCurrency.length === 1 || propertyAirbnbSelected.length === selectedIds.length))
        : false
    return (
      <button
        className={clsx(
          'px-[14px] py-[6px] flex items-center justify-center font-maison-neue-medium text-16-20 md:text-14-18 rounded-[100px]',
          'border border-neutral-300 h-[44px] md:h-[32px] text-neutral-800 bg-none hover:bg-neutral-200',
          isDisabled ? "opacity-60 cursor-not-allowed" : "opacity-[1] cursor-pointer",
          { 'shadow-active': open }
        )}
        disabled={isDisabled}
        onClick={(event) => {
          let typeEditBulk: string = TYPE_EDIT_LISTINGS.ALL
          let nativeType: string = ""
          if (propertyAirbnbSelected.length === selectedIds.length) typeEditBulk = TYPE_EDIT_LISTINGS.AIRBNB
          if (propertyNativeSelected.length === selectedIds.length) {
            typeEditBulk = TYPE_EDIT_LISTINGS.NATIVE
            if (!isEmpty(propertyChannexSelected)) {
              nativeType = TYPE_EDIT_LISTINGS.CHANNEX
            }
          }
          let paramsBulkEdit: any = { type: typeEditBulk } // detect currency edit bulk listing
          if (selectedIds.length > 1) paramsBulkEdit = { ...paramsBulkEdit, ids: selectedIds.join(',') }
          if (bulkCurrency.length === 1) paramsBulkEdit = { ...paramsBulkEdit, currency: bulkCurrency[0] }
          if (!isEmpty(nativeType)) paramsBulkEdit = { ...paramsBulkEdit, native: nativeType }
          const paramsURL = queryString.stringify(paramsBulkEdit, { skipEmptyString: true, skipNull: true })
          if (_type === 'details') {
            dispatch(setBulkListingData(null))
            if (selectedIds.length === 1 && !isEmpty(propertyNativeSelected) && propertyNativeSelected[0].status === PROPERTY_STATUS.DRAFT) {
              const settingSteps = propertyNativeSelected[0].settingSteps || 1
              navigate(`/native-listing/${NATIVE_LISTING_STEPS[String(settingSteps)]}?id=${selectedIds[0]}`)
            } else {
              navigate(selectedIds.length === 1 ?
                `/native-listing/${!isEmpty(propertyNativeSelected) ? 'property' : 'amenities'}?id=${selectedIds[0]}` :
                `/native-listing/${typeEditBulk === TYPE_EDIT_LISTINGS.NATIVE ? 'property' : 'amenities'}?${paramsURL}`
              )
            }
          } else if (_type === 'rates') {
            dispatch(setBulkListingData(null))
            navigate(selectedIds.length === 1 ?
              `/native-listing/rates?id=${selectedIds[0]}` :
              `/native-listing/rates?${paramsURL}`
            )
          } else {
            _type === 'common' ? togglePopupEditCommonMobile() : handleOpen(event, _type)
          }
        }}
      >
        {text}
      </button>
    )
  }

  if (Array.isArray(selectedIds) && selectedIds.length === 0) {
    return (
      <div className={'flex items-center gap-[8px] font-maison-neue-medium text-14-18'}>
        <span className={'text-neutral-900'}>Edit:</span>
        <span className={'text-neutral-400'}>Select one or more listings to edit</span>
      </div>
    )
  }

  return (
    <>
      <div className={'hidden md:flex gap-[16px]'}>
        {renderEditBtn('status')}
        {renderEditBtn('guest_screening')}
        {renderEditBtn('damage_protection')}
        {renderEditBtn('details')}
        {renderEditBtn('rates')}
        {Array.isArray(stripeAccounts) && stripeAccounts.length > 0 && renderEditBtn('stripe')}
      </div>
      <div className={'block md:hidden'}>{renderEditBtn('common')}</div>

      <PropertyEditStatus
        anchorEl={anchorElStatus}
        onClose={handleClose}
        selectedIds={selectedIds}
        isSelectedAllPage={isSelectedAllPage}
        afterChangeStatus={afterChangeStatus}
      />
      <PropertyEditGuestScreening
        loading={loading}
        anchorEl={anchorElGuestScreening}
        onClose={handleClose}
        selectedIds={selectedIds}
        isSelectedAllPage={isSelectedAllPage}
        onSubmit={handleApplySuperHog}
      />
      <PropertyEditDamageProtection
        loading={loading}
        anchorEl={anchorElDamageProtection}
        onClose={handleClose}
        selectedIds={selectedIds}
        isSelectedAllPage={isSelectedAllPage}
        onSubmit={handleApplySuperHog}
      />

      <PropertyEditStripe
        anchorEl={anchorElStripe}
        onClose={handleClose}
        selectedIds={selectedIds}
        isSelectedAllPage={isSelectedAllPage}
        stripeAccounts={stripeAccounts}
      />

      <BasicSwipeDrawer
        isOpen={isOpenPopupEditCommon}
        onClose={togglePopupEditCommonMobile}
        padding={'32px 24px'}
        title={'Edit...'}
      >
        <div className={'flex flex-col gap-[12px]'}>
          <BasicButton
            variant={'outlined'}
            isRadius100={true}
            clases={'w-full'}
            onClick={(event) => onOpenEditMobile(event, 'status')}
          >
            Edit status
          </BasicButton>
          <BasicButton
            variant={'outlined'}
            isRadius100={true}
            clases={'w-full'}
            onClick={(event) => onOpenEditMobile(event, 'guest_screening')}
          >
            Edit guest screening
          </BasicButton>
          <BasicButton
            variant={'outlined'}
            isRadius100={true}
            clases={'w-full'}
            onClick={(event) => onOpenEditMobile(event, 'damage_protection')}
          >
            Edit damage protection
          </BasicButton>
          {renderEditBtn('details')}
          {renderEditBtn('rates')}
          <BasicButton
            variant={'outlined'}
            isRadius100={true}
            clases={'w-full'}
            onClick={(event) => onOpenEditMobile(event, 'stripe')}
          >
            Stripe
          </BasicButton>
        </div>
      </BasicSwipeDrawer>

      <BulkEnableSuperHog
        superHogStatus={superHogStatus}
        propertiesNeedConfirm={propertiesNeedConfirm}
        isOpen={isOpenPopupBulkSuperHog}
        onClose={handleClosePopupBulkSuperHog}
        afterUpdateSuperhog={afterUpdateSuperhog}
      />
    </>
  )
}

export default PropertyEdit
