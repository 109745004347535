import BasicPopup, { PopupProps } from './BasicPopup'
import BasicSwipeDrawer, { BasicSwipeDrawerProps } from './BasicSwipeDrawer'
import React from 'react'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

const BasicDialog = (props: PopupProps & BasicSwipeDrawerProps) => {
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768
  const {
    // PopupProps
    allowBackdropClick,
    maxWidth,

    // BasicSwipeDrawerProps
    titleAlign,
    height,
    maxHeight,
    noScroll,
    padding,
    hiddenTitleDivider,
    // CommonProps
    ...commonProps
  } = props
  const IPopupProps = { allowBackdropClick, maxWidth, ...commonProps }
  const IBasicSwipeDrawerProps = { titleAlign, height, maxHeight, noScroll, padding, hiddenTitleDivider, ...commonProps }

  return <>{isMobile ? <BasicSwipeDrawer {...IBasicSwipeDrawerProps} /> : <BasicPopup {...IPopupProps} />}</>
}

export default BasicDialog
