import { createCustomTax, updateCustomTax, getTaxSettings, deleteTaxById } from '../../../api/native-listing'
import ic_delete_trash from '../../../assets/icons/ic_delete_trash.svg'
import ic_edit_write_alt from '../../../assets/icons/ic_edit_write_alt.svg'
import { AMOUNT_TYPE_VALUE } from '../../../contants/native'
import { FormError } from '../../../interfaces'
import { useAppDispatch } from '../../../redux/hooks'
import { setToastSuccess } from '../../../redux/slices/common'
import { handleErrorMessage, isEmpty } from '../../../utils/common'
import { convertTaxFeeAppliedPer } from '../../../utils/property'
import RenderPrice from '../../common/RenderPrice'
import BasicButton from '../../ui/BasicButton'
import BasicDialog from '../../ui/BasicDialog'
import BasicInput from '../../ui/BasicInput'
import BasicInputPrice from '../../ui/BasicInputPrice'
import BasicRadio from '../../ui/BasicRadio'
import BasicSelect from '../../ui/BasicSelect'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
interface Props {
  propertyId: string
  currency: string
  taxSettings?: any[]
  updateTaxes?: (newTaxes: any[]) => void
  hiddenTitle?: boolean
  fetchStatusStep?: any
}

const CustomTaxes: React.FC<Props> = ({ propertyId, currency, taxSettings, updateTaxes, hiddenTitle, fetchStatusStep }) => {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [data, setData] = useState<{
    taxTitle: string
    amountType: string
    amount: string | number
    taxAppliedPer: string
  }>({
    taxTitle: '',
    amountType: AMOUNT_TYPE_VALUE.FLAT,
    amount: '',
    taxAppliedPer: 'reservation', // per stay
  })
  const [errors, setErrors] = useState<{
    taxTitle: FormError
    amount: FormError
  }>({
    taxTitle: {
      show: false,
      message: '',
    },
    amount: {
      show: false,
      message: '',
    },
  })
  const [customTaxes, setCustomTaxes] = useState<any[]>([])
  const [selectedId, setSelectedId] = useState<number>(-1)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpenPopupDelete, setIsOpenPopupDelete] = useState<boolean>(false)
  const [typePopup, setTypePopup] = useState<string>('')
  const isImportFlow = Array.isArray(taxSettings) && typeof updateTaxes === 'function'
  const taxes = isImportFlow ? taxSettings : customTaxes

  useEffect(() => {
    if (propertyId && !isImportFlow) {
      fetchTaxesSettings(propertyId)
    }
  }, [propertyId, isImportFlow])

  async function fetchTaxesSettings(_id: number | string) {
    const res = await getTaxSettings(_id)
    if (res.success && Array.isArray(res.data)) {
      setCustomTaxes(res.data.filter((item: any) => item.taxType === 'otherTaxes'))
    }
  }

  const handleOpenPopup = (type: string) => {
    setIsOpen(true)
    setTypePopup(type)
  }

  const handleClosePopup = () => {
    setSelectedId(-1)
    setIsOpen(false)
    setTypePopup('')
    // clear data
    setData({
      taxTitle: '',
      amountType: AMOUNT_TYPE_VALUE.FLAT,
      amount: '',
      taxAppliedPer: 'reservation', // per stay
    })
    setErrors({
      taxTitle: {
        show: false,
        message: '',
      },
      amount: {
        show: false,
        message: '',
      },
    })
  }

  const handleOpenPopupDelete = (feeId: number) => {
    // Nếu trong luồng import hospitable: click delete => xóa luôn ( vì tất ca fee hien tai chua dc save)
    if (isImportFlow) {
      const newTaxes = [...taxSettings]
      newTaxes.splice(feeId, 1)
      updateTaxes(newTaxes)
      return
    }
    setSelectedId(feeId)
    setIsOpenPopupDelete(true)
  }

  const handleClosePopupDelete = () => {
    setIsOpenPopupDelete(false)
    setSelectedId(-1)
  }

  const onChangeData = (field: string, value: string | number) => {
    setData((prevState) => ({ ...prevState, [field]: value }))
    setErrors((prevState) => ({
      ...prevState,
      [field]: { show: false, message: '' },
    }))
    if (field === 'amountType') {
      setErrors((prevState) => ({
        ...prevState,
        amount: { show: false, message: '' },
      }))
    }
  }

  const isFormValid = () => {
    let isValid = true
    let _errors = { ...errors }
    if (!data.taxTitle || !data.taxTitle.trim()) {
      _errors.taxTitle = {
        show: true,
        message: 'Please enter name',
      }
      isValid = false
    }
    if (data.amountType === AMOUNT_TYPE_VALUE.PERCENT) {
      // Value: number between 1 - 100 with maximum 2 decimal digits are allowed.
      const amount = data.amount
      if (amount && (Number(amount) <= 0 || Number(amount) > 100)) {
        _errors.amount = { show: true, message: 'Amount must be between 1 - 100%' }
        isValid = false
      }
      const amountSplit = String(data.amount).split('.')
      if (amountSplit[1] && amountSplit[1].length > 2) {
        _errors.amount = { show: true, message: 'Only 2 decimal digits are allowed' }
        isValid = false
      }
    }
    if (!isValid) {
      setErrors((prevState) => ({ ...prevState, ..._errors }))
    }
    return isValid
  }

  const onSubmit = async () => {
    const isValid = isFormValid()
    if (!isValid) return
    const appliedPer = data.amountType === AMOUNT_TYPE_VALUE.FLAT ? data.taxAppliedPer : 'reservation'
    if (isImportFlow) {
      const taxData = {
        ...data,
        amount: Number(data.amount),
        taxType: 'otherTaxes',
        taxAppliedPer: appliedPer,
      }
      if (selectedId > -1) {
        // edit
        const cloneTaxes = [...taxSettings]
        cloneTaxes[selectedId] = taxData
        updateTaxes(cloneTaxes)
      } else {
        // add new
        updateTaxes([...taxSettings, taxData])
      }
      handleClosePopup()
      return
    }

    try {
      setIsLoading(true)
      let dataDTO: any = {
        ...data,
        amount: Number(data.amount),
        taxType: 'otherTaxes',
        taxAppliedPer: appliedPer,
      }
      if (isEditBulk) {
        if (updateTaxes) {
          updateTaxes([...(taxSettings || []), dataDTO])
          // const propertyIds = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
          // await updateBulkProperties({ propertyIds, taxSettings: [dataDTO] })
        }
      } else {
        if (selectedId > 0) {
          await updateCustomTax(propertyId, selectedId, dataDTO)
          dispatch(setToastSuccess({ message: 'Update tax successfully' }))
        } else {
          await createCustomTax(propertyId, dataDTO)
          dispatch(setToastSuccess({ message: 'Create tax successfully' }))
        }
        await fetchTaxesSettings(propertyId)
      }
      handleClosePopup()
      if (fetchStatusStep) fetchStatusStep()
    } catch (err) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDelete = async () => {
    try {
      setIsLoading(true)
      await deleteTaxById(propertyId, selectedId)
      await fetchTaxesSettings(propertyId)
      dispatch(setToastSuccess({ message: 'Delete tax successfully' }))
      handleClosePopupDelete()
    } catch (err) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleClickEdit = (editId: number) => {
    setSelectedId(editId)
    const item = isImportFlow ? taxSettings[editId] : customTaxes.find((item) => Number(item.id) === Number(editId))
    if (item) {
      setData({
        taxTitle: item.taxTitle,
        amountType: item.amountType,
        amount: Number(item.amount),
        taxAppliedPer: item.taxAppliedPer, // per stay
      })
    }
    handleOpenPopup('edit')
  }

  const renderPopupTitle = () => {
    return typePopup === 'edit' ? 'Edit tax' : 'Add a new tax'
  }
  const renderTextBtn = () => {
    if (isEditBulk) {
      return typePopup === 'edit' ? 'Update' : 'Create'
    } else return 'Save'
  }
  return (
    <div className="p-[24px] bg-white w-full mb-[32px] rounded-[16px] flex flex-col gap-6">
      {!hiddenTitle && (
        <div>
          <h3 className={'font-maison-neue text-20-32 text-neutral-800'}>Custom taxes</h3>
          <p className={'font-maison-neue text-14-20 text-neutral-600 mt-[4px]'}>Add additional taxes to your fees.</p>
        </div>
      )}
      {Array.isArray(taxes) && taxes.length > 0 && (
        <div className={`flex flex-col gap-[12px]`}>
          {taxes.map((item, index) => {
            return (
              <div key={item} className={'flex justify-between items-center gap-[16px]'}>
                <p className={'font-maison-neue text-16-20 text-neutral-800 line-clamp-2'}>
                  <span>{item.taxTitle}:</span>&nbsp;
                  <span>
                    {item.amountType === AMOUNT_TYPE_VALUE.PERCENT ? (
                      <>{item.amount}%</>
                    ) : (
                      <RenderPrice
                        price={Number(item.amount)}
                        currency={currency}
                        isUseSymbol={true}
                      // isRounded={true}
                      />
                    )}
                  </span>
                  &nbsp;
                  <span>{convertTaxFeeAppliedPer(item.taxAppliedPer)}</span>
                </p>
                <div className={'flex items-center gap-[12px]'}>
                  <button
                    className={clsx(
                      'w-[36px] h-[36px] min-w-[36px] rounded-full border border-neutral-300 shadow-xs flex items-center justify-center',
                      'hover:bg-neutral-200'
                    )}
                    onClick={() => handleClickEdit(isImportFlow ? index : item.id)}
                  >
                    <img alt={'ic_edit'} src={ic_edit_write_alt} />
                  </button>
                  <button
                    className={clsx(
                      'w-[36px] h-[36px] min-w-[36px] rounded-full border border-neutral-300 shadow-xs flex items-center justify-center',
                      'hover:bg-neutral-200'
                    )}
                    onClick={() => handleOpenPopupDelete(isImportFlow ? index : item.id)}
                  >
                    <img alt={'ic_delete'} src={ic_delete_trash} />
                  </button>
                </div>
              </div>
            )
          })}
        </div>
      )}

      <div>
        <BasicButton size={'md'} variant={'outlined'} isRadius100={true} onClick={() => handleOpenPopup('create')}>
          Add a custom tax
        </BasicButton>
      </div>

      <div className={'mt-[24px]'}>
        <p className={'font-maison-neue text-14-20 text-neutral-600'}>
          Please note: Dtravel will help you collect taxes, but you are responsible for reporting taxes to relevant
          authorities.
        </p>
      </div>

      <BasicDialog
        isOpen={isOpen}
        onClose={handleClosePopup}
        title={renderPopupTitle()}
        extraTitle={'Add additional taxes to your fees'}
      >
        <div className={'flex flex-col gap-[16px]'}>
          <div>
            <BasicInput
              label={'Name'}
              value={data.taxTitle}
              onChange={(event) => onChangeData('taxTitle', event.target.value)}
              onBlur={(event) => onChangeData('taxTitle', event.target.value.trim())}
              error={errors.taxTitle}
              maxLength={30}
            />
          </div>
          <div className={'flex items-center gap-[32px] h-[48px]'}>
            <label htmlFor={'percentage_type'} className={'flex items-center gap-[16px] cursor-pointer'}>
              <BasicRadio
                type={'radio'}
                id={'percentage_type'}
                name={'fee_type'}
                checked={data.amountType === AMOUNT_TYPE_VALUE.PERCENT}
                value={AMOUNT_TYPE_VALUE.PERCENT}
                onChange={(event) => onChangeData('amountType', event.target.value)}
              />
              <span className={'font-maison-neue text-16-20 text-neutral-800 mt-[6px]'}>Percentage</span>
            </label>
            <label htmlFor={'fixed_type'} className={'flex items-center gap-[16px] cursor-pointer'}>
              <BasicRadio
                type={'radio'}
                id={'fixed_type'}
                name={'fee_type'}
                checked={data.amountType === AMOUNT_TYPE_VALUE.FLAT}
                value={AMOUNT_TYPE_VALUE.FLAT}
                onChange={(event) => onChangeData('amountType', event.target.value)}
              />
              <span className={'font-maison-neue text-16-20 text-neutral-800 mt-[6px]'}>Fixed amount</span>
            </label>
          </div>
          <div className={'flex gap-[16px] items-center'}>
            <div className={'flex-1'}>
              <BasicInputPrice
                currency={data.amountType === AMOUNT_TYPE_VALUE.FLAT ? currency : ''}
                value={data.amount}
                onValueChange={(values) => {
                  let _val: number | string = values.value.replaceAll('-', '').replaceAll(' ', '')
                  if (data.amountType === AMOUNT_TYPE_VALUE.PERCENT && Number(_val) > 100) {
                    _val = _val.substring(0, _val.length - 1)
                  }
                  const arrStr = _val.split('.')
                  if (arrStr[1] && arrStr[1].length > 2) {
                    _val = _val.substring(0, _val.length - 1)
                  }
                  onChangeData('amount', _val)
                }}
                endAdornment={
                  data.amountType === AMOUNT_TYPE_VALUE.FLAT ? (
                    ''
                  ) : (
                    <span
                      className={`absolute right-[12px] bottom-[4px] -translate-y-1/2 font-maison-neue text-16-20 text-neutral-500'
                    }`}
                    >
                      %
                    </span>
                  )
                }
                error={errors.amount}
              />
            </div>
            {data.amountType === AMOUNT_TYPE_VALUE.FLAT ? (
              <div className={'w-[198px]'}>
                <BasicSelect
                  options={[
                    { label: 'Per stay', value: 'reservation' },
                    { label: 'Per guest', value: 'person' },
                    { label: 'Per night', value: 'night' },
                    { label: 'Per guest per night', value: 'person_per_night' },
                  ]}
                  value={data.taxAppliedPer}
                  onChange={(event) => onChangeData('taxAppliedPer', event.target.value)}
                />
              </div>
            ) : (
              <div className={''}>Per stay</div>
            )}
          </div>

          <div className={'mt-[40px]'}>
            <BasicButton
              color={'red'}
              variant={'contained'}
              isRadius100={true}
              clases={'w-full'}
              loading={isLoading}
              onClick={onSubmit}
            >
              {renderTextBtn()}
            </BasicButton>
          </div>
        </div>
      </BasicDialog>

      {/* ----Start Popup Confirm Delete range block dates---- */}
      <BasicDialog
        isOpen={isOpenPopupDelete}
        onClose={handleClosePopupDelete}
        title={'Are you sure to delete this tax?'}
      >
        <div className={'flex flex-col gap-[8px]'}>
          <BasicButton
            variant={'contained'}
            isRadius100={true}
            color={'red'}
            onClick={handleDelete}
            loading={isLoading}
          >
            Confirm
          </BasicButton>
        </div>
      </BasicDialog>
    </div>
  )
}

export default CustomTaxes
