import { setTriggerResetCalendar } from '../../../redux/slices/listing'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { importICalCalendar } from 'src/api/native-listing'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import BasicInput from 'src/components/ui/BasicInput'
import { FormError } from 'src/interfaces'
import { setToastSuccess } from 'src/redux/slices/common'
import { getKeys, handleErrorMessage, isValidHttpUrl } from 'src/utils/common'

interface Props {
  propertyId: string
  fetchICal?: (propertyId: string) => void
  fetchStatusStep?: any
}

const ImportICalCalendar: React.FC<Props> = ({ propertyId, fetchICal, fetchStatusStep }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState<{ name: string; url: string }>({ name: '', url: '' })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [errors, setErros] = useState<{ name: FormError; url: FormError }>({
    name: { show: false, message: '' },
    url: { show: false, message: '' },
  })

  const handleOpenPopup = () => {
    setIsOpen(true)
  }

  const handleClosePopup = () => {
    setIsOpen(false)
  }

  const onChangData = (field: string, value: string) => {
    setData((prevState) => ({ ...prevState, [field]: value }))
    setErros((prevState) => ({ ...prevState, [field]: { show: false, message: '' } }))
  }

  const onSubmit = async () => {
    if (!propertyId) {
      handleErrorMessage({ message: 'Missing property id' })
      return
    }
    let isError: boolean = false
    for (const field of getKeys(data)) {
      if (!data[field] || !data[field].trim()) {
        setErros((prevState) => ({ ...prevState, [field]: { show: true, message: 'Please enter a value' } }))
        isError = true
      }
      if (field === 'url' && !isValidHttpUrl(data[field])) {
        setErros((prevState) => ({ ...prevState, [field]: { show: true, message: 'Please enter a valid url' } }))
        isError = true
      }
    }

    if (isError) {
      return
    }
    try {
      setIsLoading(true)
      const bodyData = {
        urls: [data],
      }
      await importICalCalendar(propertyId, bodyData)
      dispatch(setToastSuccess({ message: 'Import calendar success' }))
      dispatch(setTriggerResetCalendar(+new Date()))
      if (typeof fetchICal === 'function') {
        await fetchICal(propertyId)
      }
      if (fetchStatusStep) fetchStatusStep()
      handleClose()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    setData({ name: '', url: '' }) // clear data before close
    handleClosePopup()
  }

  return (
    <>
      <BasicButton
        variant="outlined"
        size={'md'}
        color={'black'}
        clases={'w-full md:w-auto'}
        onClick={handleOpenPopup}
        disabled={false}
        isRadius100={true}
      >
        <span className="text-14-18 font-maison-neue-medium text-neutral-900">Import calendar</span>
      </BasicButton>

      <BasicDialog
        isOpen={isOpen}
        onClose={handleClose}
        title={'Import a calendar'}
        extraTitle={
          <p className={'font-maison-neue text-14-20 text-neutral-600 text-center mt-[8px]'}>
            Keep your calendar updated by importing booked and unavailable dates from booking services and other
            calendars you use. Note that importing a calendar can take a few minutes.
          </p>
        }
        maxWidth={'md'}
      >
        <div className={''}>
          <BasicInput
            label={'iCal address (URL)'}
            placeholder={'Paste your URL...'}
            value={data.url}
            onChange={(event) => onChangData('url', event.target.value)}
            error={errors.url}
          />
        </div>
        <div className={'mt-[24px]'}>
          <BasicInput
            label={'Name'}
            placeholder={'Name this calendar...'}
            value={data.name}
            onChange={(event) => onChangData('name', event.target.value)}
            error={errors.name}
            maxLength={20}
          />
        </div>
        <div className={'mt-[24px]'}>
          <BasicButton
            variant={'contained'}
            size={'md'}
            color={'red'}
            isRadius100={true}
            clases={'w-full'}
            loading={isLoading}
            onClick={onSubmit}
            disabled={!data.url || !data.name}
          >
            Connect
          </BasicButton>
        </div>
      </BasicDialog>
    </>
  )
}

export default ImportICalCalendar
