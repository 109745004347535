import { PMS } from '../../../contants/common'
import { handleErrorMessage } from '../../../utils/common'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useState } from 'react'
import { createApiKey } from 'src/api/user'
import BasicDialog from 'src/components/ui/BasicDialog'
import BasicInput from 'src/components/ui/BasicInput'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setConnectDtravelApiType, setIsOpenPopupViewDtravelApi, setToastSuccess } from 'src/redux/slices/common'

const ViewDtravelApi = () => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [clientId, setClientId] = useState<string>('')
  const [clientSecret, setClientSecret] = useState<string>('')
  const { isOpenPopupViewDtravelApi, connectDtravelApiType } = useAppSelector((state) => state.common)

  const fetchApiKey = async () => {
    if (!clientId && !clientSecret) {
      try {
        setIsLoading(true)
        const res = await createApiKey()
        const { clientId, clientSecret } = res.data
        setClientId(clientId)
        setClientSecret(clientSecret)
      } catch (err: any) {
        handleErrorMessage(err)
        throw err
      } finally {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    if (isOpenPopupViewDtravelApi) {
      fetchApiKey()
    }
  }, [isOpenPopupViewDtravelApi])

  const handleClose = () => {
    dispatch(setIsOpenPopupViewDtravelApi(false))
    dispatch(setConnectDtravelApiType(''))
  }

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value)
    dispatch(setToastSuccess({ message: `Copied!`, duration: 2000 }))
  }

  const renderTitleAndExtraTitle = (type: 'title' | 'extraTitle') => {
    if (type === 'title') {
      if (connectDtravelApiType === PMS.GUESTY) return 'Connect to Guesty'
      if (connectDtravelApiType === PMS.RENTAL_WISE) return 'Connect to RentalWise'
      return 'Your Dtravel API Key'
    }
    // extraTitle
    return (
      <p className={'font-maison-neue text-16-24 text-grayscale-800 text-center mt-[8px] whitespace-pre-line'}>
        {connectDtravelApiType
          ? 'Sync with your PMS to automatically import and manage your listings.'
          : 'Please use the below API key to connect with our partner platforms.'}
        &nbsp;
        <a
          className={'underline'}
          target={'_blank'}
          rel="noreferrer"
          href={
            connectDtravelApiType === PMS.GUESTY
              ? 'https://dtravel.com/help/how-to-connect-dtravel-to-guesty'
              : connectDtravelApiType === PMS.RENTAL_WISE
                ? 'https://dtravel.com/help/how-to-connect-dtravel-to-rentalwise'
                : 'https://docs.dtravel.com/'
          }
        >
          {connectDtravelApiType === PMS.GUESTY || connectDtravelApiType === PMS.RENTAL_WISE
            ? 'Learn how to connect.'
            : 'Learn more about our API.'}
        </a>
      </p>
    )
  }

  return (
    <BasicDialog
      isOpen={isOpenPopupViewDtravelApi}
      onClose={handleClose}
      title={renderTitleAndExtraTitle('title')}
      extraTitle={renderTitleAndExtraTitle('extraTitle')}
    >
      <div className={'grid grid-cols-1 gap-4'}>
        <div>
          <p className={'text-neutral-800 text-16-24 mb-2'}>Account ID</p>
          <BasicInput
            type="password"
            name="clientId"
            autoComplete="false"
            showIconPassword
            showPasswordFirstTime
            showIconCopy
            onCopy={() => handleCopy(clientId)}
            value={clientId}
            readOnly
          />
        </div>
        <div>
          <p className={'text-neutral-800 text-16-24 mb-2'}>API key</p>
          <BasicInput
            type="password"
            name="clientSecret"
            autoComplete="false"
            showIconPassword
            showPasswordFirstTime
            showIconCopy
            onCopy={() => handleCopy(clientSecret)}
            value={clientSecret}
            readOnly
          />
        </div>
      </div>
      {isLoading && (
        <div
          className={
            'absolute w-full h-full top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center opacity-30 cursor-not-allow'
          }
        >
          <CircularProgress size={50} style={{ color: '#0B2336' }} />
        </div>
      )}
    </BasicDialog>
  )
}

export default ViewDtravelApi
