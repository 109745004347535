import { getSymbolCurrencyByKey } from '../../utils/common'
import Error from '../common/Error'
import { IconCircleWarning } from '../common/Icons'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import React, { FocusEventHandler, useEffect, useState } from 'react'
import CurrencyFormat, { Values } from 'react-currency-format'
import { FormError } from 'src/interfaces'

interface Props {
  placeholder?: string
  error?: FormError
  value?: string | number
  currency?: string
  size?: 'md' | 'lg'
  clases?: string
  onValueChange?: (values: Values) => void
  disabled?: boolean
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined
  endAdornment?: any
}

// eslint-disable-next-line
const BootstrapTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#0F0F0E',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#0F0F0E',
    padding: '8px 12px',
    borderRadius: '8px',
    color: '#FFF',
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'Maison Neue Regular',
  },
  [`& .${tooltipClasses.tooltipPlacementTop}`]: {
    marginBottom: '8px !important',
  },
}))

const BasicInputPrice: React.FC<Props> = ({
  error,
  currency,
  size = 'md',
  clases,
  disabled,
  endAdornment,
  ...props
}) => {
  const [symbol, setSymbol] = useState<string>('')

  useEffect(() => {
    setSymbol(getSymbolCurrencyByKey(currency))
  }, [currency])

  const renderClassBySize = () => {
    switch (size) {
      case 'md':
        // height: 48px
        return 'h-[48px] px-[16px] text-16-20'
      case 'lg':
        // height: 64px
        return 'h-[64px] px-[16px] text-24-28'
    }
  }

  const renderClasseByCurrencySymbol = () => {
    if (!symbol) {
      return ''
    }
    if (symbol.length === 1) {
      return 'pl-[32px]'
    }
    if (symbol.length === 2) {
      return 'pl-[43px]'
    }
    if (symbol.length === 3) {
      return 'pl-[54px]'
    }
  }

  return (
    <div className={'w-full'}>
      <label className="block relative w-full">
        <CurrencyFormat
          placeholder={props.placeholder}
          onBlur={props.onBlur}
          className={clsx(
            'font-maison-neue text-neutral-800',
            'w-full px-[16px] rounded-[12px]',
            'placeholder:text-neutral-500',
            'border',
            'hover:text-neutral-900',
            'focus:outline-none',
            'shadow-xs',
            'disabled:cursor-not-allowed disabled:text-neutral-500',
            'disabled:bg-neutral-50',
            'inputNumberHideArrow',
            renderClassBySize(),
            renderClasseByCurrencySymbol(),
            clases,
            {
              'border-neutral-300 focus:border-neutral-800 focus:text-neutral-900 ': !error?.show,
              'border-red-700 hover:border-red-700 focus:border-red-700': error?.show,
              'pr-[40px]': error?.show && error?.showTooltip && !clases?.includes('text-right'),
              'pl-[40px]': error?.show && error?.showTooltip && clases?.includes('text-right'),
            }
          )}
          thousandSeparator={true}
          inputMode="numeric"
          disabled={disabled}
          {...props}
        />
        {currency && (
          <span
            className={clsx('absolute left-[16px] my-auto font-maison-neue text-neutral-500', {
              'text-16-20 top-[15px]': size === 'md',
              'text-24-28 top-[18px]': size === 'lg',
            })}
          >
            {symbol}
          </span>
        )}
        {error && error.showTooltip && error.show && (
          <BootstrapTooltip title={error.message} placement={'bottom'} arrow={true} enterTouchDelay={0}>
            <span
              className={clsx('absolute my-auto', {
                'text-16-20 top-[14px]': size === 'md',
                'text-24-28 top-[20px]': size === 'lg',
                'left-[12px]': clases?.includes('text-right'),
                'right-[12px]': !clases?.includes('text-right'),
              })}
            >
              <IconCircleWarning />
            </span>
          </BootstrapTooltip>
        )}
        {endAdornment}
      </label>
      {error && !error.showTooltip && <Error {...error} />}
    </div>
  )
}

export default BasicInputPrice
