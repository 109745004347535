import DisabledSection from '../common/DisabledSection'
import DisabledSectionOnEdit from '../common/DisabledSectionOnEdit'
import BasicInput from '../ui/BasicInput'
import BasicSelect from '../ui/BasicSelect'
import NativeListingBackAndContinue from './NativeListingBackAndContinue'
import axios from 'axios'
import moment from 'moment-timezone'
import queryString from 'query-string'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import Map from 'react-map-gl'
import { Marker } from 'react-map-gl'
import { useLocation, useNavigate } from 'react-router-dom'
import { getIpInfo } from 'src/api/listings'
import { createProperty, getLatLongMapbox, updateBulkProperties, updateProperty } from 'src/api/native-listing'
import ic_marker_pin from 'src/assets/icons/ic_marker_pin.svg'
import { NATIVE_LISTING_MENU, PMS_TYPE } from 'src/contants/common'
import { PROPERTY_STATUS } from 'src/contants/native'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { setBulkListingData, setBulkListingSection, setNativeListingProperty } from 'src/redux/slices/listing'
import { handleErrorMessage, isEmpty } from 'src/utils/common'
import { countries } from 'src/utils/country'

interface Props {
  suggestionSelected: any
  listingData: any
  handleBack: any
  fetchStatusStep: any
}

let cancelToken: any

const NativeListingLocationConfirm = ({ suggestionSelected, listingData, handleBack, fetchStatusStep }: Props) => {
  const mapboxAccessToken =
    process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ||
    'pk.eyJ1IjoiZHRyYXZlbCIsImEiOiJjbGN0enZjM2YwenRhM3ZudzQwOHBqNGRwIn0.aAG5VZns5U5dt7eciHSOew'
  const mapManuallyRef: any = useRef(null)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isOnboarding = parseQuery?.f === 'onboarding'
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const { nativeListingProperty, bulkListingData, bulkListingSection } = useAppSelector((state) => state.listing)
  const [country, setCountry] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [street, setStreet] = useState<string>('')
  const [state, setState] = useState<string>('')
  const [zipcode, setZipcode] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [marker, setMarker] = useState<any>({})
  const [isInit, setIsInit] = useState<boolean>(true)
  const [toggleEdit, setToggleEdit] = useState<any>({ propertyAddress: false })
  const isHospitable = listingData?.pmsType === PMS_TYPE.HOSPITABLE

  const isDraft = listingData?.status === PROPERTY_STATUS.DRAFT || isEmpty(parseQuery?.id)

  useEffect(() => {
    async function fetchCountry() {
      try {
        const res = await getIpInfo().then((res) => res.data)
        if (!isEmpty(res)) {
          if (!isEmpty(res?.countryCode)) setCountry(res?.countryCode)
          if (!isEmpty(res?.city)) setCity(res?.city)
        }
      } catch (err) {
        console.log(err)
      }
    }
    if (!isEditBulk) {
      if (!isEmpty(listingData) && !isEmpty(parseQuery.id)) {
        setCountry(listingData?.address?.countryCode)
        setCity(listingData?.address?.city)
        setState(listingData?.address?.state)
        setStreet(listingData?.address?.street)
        setZipcode(listingData?.address?.zipcode)
        setMarker({ latitude: listingData?.address?.lat, longitude: listingData?.address?.lng })
        setTimeout(() => {
          setIsInit(false)
        }, 300)
      } else if (!isEmpty(suggestionSelected) && isEmpty(parseQuery.id)) {
        setIsInit(false)
        setCountry(suggestionSelected?.context?.country?.country_code)
        if (!isEmpty(suggestionSelected?.context?.region)) {
          setCity(suggestionSelected?.context?.region?.name || suggestionSelected?.context?.place?.name)
          setState(suggestionSelected?.context?.region?.name)
        }
        if (!isEmpty(suggestionSelected?.context?.place)) {
          setCity(suggestionSelected?.context?.place?.name)
        }
        if (!isEmpty(suggestionSelected?.name)) {
          setStreet(suggestionSelected?.name)
        }
      } else if (isDraft) {
        setIsInit(false)
        fetchCountry()
      }
    } else {
      setIsInit(false)
    }
  }, [listingData, suggestionSelected])
  useEffect(() => {
    if (bulkListingData) {
      setCountry(bulkListingData?.country || '')
      setCity(bulkListingData?.city || '')
      setStreet(bulkListingData?.street || '')
      setState(bulkListingData?.state || '')
      setZipcode(bulkListingData?.zipcode || '')
      setMarker(bulkListingData?.marker || {})
    }
  }, [bulkListingData])

  const fetchLatLong = async () => {
    const countrySelected = countries.find((v: any) => v.iso === country)
    const address = [street, city, countrySelected?.name].filter((v) => !isEmpty(v))?.join(', ')
    try {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('request canceled')
      }
      cancelToken = axios.CancelToken.source()
      const res: any = await getLatLongMapbox(
        address,
        { access_token: mapboxAccessToken },
        { cancelToken: cancelToken.token }
      )
      const longitude = res?.features[0]?.center[0]
      const latitude = res?.features[0]?.center[1]
      if (latitude && longitude) {
        setMarker({ latitude, longitude })
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (!isInit) fetchLatLong()
  }, [country, city, state, street])

  const handleFitbounds = () => {
    if (mapManuallyRef) {
      if (marker?.longitude && marker?.latitude) {
        ; (mapManuallyRef as any)?.current?.fitBounds(
          [
            [marker.longitude, marker.latitude],
            [marker.longitude, marker.latitude],
          ],
          {
            padding: { top: 50, bottom: 50, left: 100, right: 100 },
            duration: 1000,
            maxZoom: 13,
          }
        )
      } else {
        ; (mapManuallyRef as any)?.current.fitBounds(
          [
            [-180, 0],
            [180, 0],
          ],
          {
            padding: { top: 0, bottom: 0, left: 0, right: 0 },
            duration: 1000,
            maxZoom: 10,
          }
        )
      }
    }
  }
  useEffect(() => {
    if (!isEmpty(marker) && marker?.latitude && marker?.longitude) handleFitbounds()
  }, [marker])
  const handleSubmit = async (isExit?: boolean) => {
    try {
      setLoading(true)
      const countrySelected = countries.find((v: any) => v.iso === country)
      const address = [street, state, city, countrySelected?.name].filter((v) => !isEmpty(v))?.join(', ')
      const dataAddressDTO = {
        timezone: moment.tz.zonesForCountry(country)?.[0],
        address: {
          country: countrySelected?.name,
          countryCode: countrySelected?.iso,
          state,
          city,
          street,
          address,
          publicAddress: address,
          zipcode,
          lat: marker?.latitude,
          lng: marker?.longitude,
        },
      }
      let res: any = null
      if (!isEmpty(parseQuery.id)) res = await updateProperty(Number(parseQuery.id), dataAddressDTO)
      else if (isEditBulk) {
        if (toggleEdit.propertyAddress) {
          const propertyIds = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
          dispatch(setBulkListingData({ ...bulkListingData, country, city, street, state, zipcode, marker }))
          res = await updateBulkProperties({ propertyIds, propertyInfo: dataAddressDTO })
          setToggleEdit({ propertyAddress: false })
        }
      } else {
        res = await createProperty({
          ...nativeListingProperty,
          ...dataAddressDTO,
          settingSteps: 2,
          minNights: 1,
          maxNights: 90,
        })
        if (res?.data?.id) fetchStatusStep(res?.data?.id)
      }
      dispatch(setNativeListingProperty({}))
      if (res?.data) {
        if (isEmpty(parseQuery?.id)) fetchStatusStep()
        if (isExit) navigate(isOnboarding ? '/' : `/listings`)
        else if (isDraft && !isEditBulk) {
          navigate(
            `/native-listing/${NATIVE_LISTING_MENU.GUEST_ROOMS}?id=${res?.data?.id}${isOnboarding ? `&f=${parseQuery?.f}` : ''
            }`
          )
        } else dispatch(setToastSuccess({ message: 'Your changes have been applied.' }))
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setLoading(false)
    }
  }

  const onMarkerDragEnd = useCallback((e: any) => {
    setMarker({
      longitude: e?.lngLat?.lng,
      latitude: e?.lngLat?.lat,
    })
  }, [])

  const renderContent = () => {
    return (
      <>
        <DisabledSectionOnEdit
          title="Property address"
          openEdit={toggleEdit.propertyAddress}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, propertyAddress: value })}
        >
          <div className="flex flex-col bg-white p-4 rounded-[16px] mb-8">
            <div className="flex items-center gap-4">
              <div className="w-1/2 flex flex-col">
                <p className="text-14-18 font-maison-neue text-neutral-800 mb-2">Country</p>
                <BasicSelect
                  label={''}
                  placeholder="select country"
                  options={countries.map((v: any) => ({ ...v, label: v.name, value: v.iso }))}
                  value={country}
                  onChange={(e: any) => {
                    setCountry(e?.target?.value)
                  }}
                />
              </div>
              <div className="w-1/2 flex flex-col">
                <p className="text-14-18 font-maison-neue text-neutral-800 mb-2">City</p>
                <BasicInput
                  placeholder=""
                  value={city}
                  onChange={(e: any) => {
                    setCity(e?.target.value)
                  }}
                />
              </div>
            </div>
            <p className="text-14-18 font-maison-neue text-neutral-800 mb-2 mt-4">Street</p>
            <BasicInput
              placeholder=""
              value={street}
              onChange={(e: any) => {
                setStreet(e?.target.value)
              }}
            />
            <p className="text-14-18 font-maison-neue text-neutral-800 mb-2 mt-4">Province/State/Region</p>
            <BasicInput
              placeholder=""
              value={state}
              onChange={(e: any) => {
                setState(e?.target.value)
              }}
            />
            <p className="text-14-18 font-maison-neue text-neutral-800 mb-2 mt-4">Postal code</p>
            <BasicInput
              placeholder=""
              value={zipcode}
              onChange={(e: any) => {
                setZipcode(e?.target.value.trim())
              }}
            />
          </div>
          {/* </DisabledSectionOnEdit>
        <DisabledSectionOnEdit
          title="Is the pin in the right spot?" subTitle='Drag the pin to reposition it to the exact spot.'
        > */}
          <p className="text-20-32 text-neutral-800 font-maison-neue mb-1 mt-8">Is the pin in the right spot?</p>
          <p className="text-14-20 text-neutral-600 font-maison-neue mb-4">
            Drag the pin to reposition it to the exact spot.
          </p>
          <div className="flex flex-col">
            <div className="w-full h-[300px] relative rounded-[16px] border border-neutral-300 shadow[0px_1px_2px_rgba(0,0,0,0.05)] mapCustomContainer">
              <Map
                ref={mapManuallyRef}
                mapboxAccessToken={
                  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ||
                  'pk.eyJ1IjoiZHRyYXZlbCIsImEiOiJjbGN0enZjM2YwenRhM3ZudzQwOHBqNGRwIn0.aAG5VZns5U5dt7eciHSOew'
                }
                initialViewState={{
                  latitude: 0,
                  longitude: 0,
                  zoom: 1,
                }}
                reuseMaps
                // onSourceData={(propsSourceData: MapSourceDataEvent) => {
                //   if (propsSourceData?.isSourceLoaded) handleFitbounds()
                // }}
                style={{ width: '100%', height: '100%', borderRadius: 16 }}
                mapStyle="mapbox://styles/mapbox/light-v10"
              // dragPan={windowDimensions.width >= 1024}
              >
                {marker?.longitude && marker?.latitude && (
                  <Marker
                    longitude={Number(marker?.longitude)}
                    latitude={Number(marker?.latitude)}
                    style={{ zIndex: 'unset' }}
                    offset={[0, 0]}
                    draggable
                    // onDragStart={onMarkerDragStart}
                    // onDrag={onMarkerDrag}
                    onDragEnd={onMarkerDragEnd}
                  >
                    <img src={ic_marker_pin} alt="" style={{ width: 44, height: 53 }} />
                  </Marker>
                )}
              </Map>
            </div>
          </div>
        </DisabledSectionOnEdit>
      </>
    )
  }

  return (
    <>
      <p className="text-28-36 text-neutral-800 font-maison-neue tracking-[-0.01em] mb-8">
        {isEditBulk ? 'Where are your properties located?' : 'Where is the property located?'}
      </p>
      <DisabledSection isDisable={isHospitable}>{renderContent()}</DisabledSection>
      <NativeListingBackAndContinue
        onSubmit={() => handleSubmit()}
        isLoading={loading}
        onBack={handleBack}
        isDisabledSubmit={
          isEditBulk ?
            !toggleEdit.propertyAddress :
            (isEmpty(country) || isEmpty(city) || isEmpty(street))
        }
        isEdit={!isDraft}
      />
      <div
        id={`${NATIVE_LISTING_MENU.LOCATION}_save_exit`}
        className="hidden"
        onClick={() => handleSubmit(true)}
        role="presentation"
      />
      <div
        id={`${NATIVE_LISTING_MENU.LOCATION}_save`}
        className="hidden"
        onClick={() => {
          if (isEditBulk) {
            if (isEmpty(bulkListingSection) && toggleEdit.propertyAddress) {
              let result: string[] = []
              if (toggleEdit.propertyAddress) result.push('Property address')
              dispatch(setBulkListingSection(result.join(', ')))
            } else handleSubmit()
          } else handleSubmit()
        }}
        role="presentation"
      />
    </>
  )
}

export default NativeListingLocationConfirm
