import { FormError } from '../../../interfaces'
import BasicInputPrice from '../../ui/BasicInputPrice'
import BasicRadio from '../../ui/BasicRadio'
import BasicSwitch from '../../ui/BasicSwitch'
import React from 'react'

type MarkupType = 'add' | 'remove'

interface Props {
  isEnable?: boolean
  onChangeEnable: (checked: boolean) => void
  value: number | string | undefined
  error?: FormError
  onChangeValue: (val: string | number | undefined) => void
  markupType: MarkupType
  onChangeMarkupType: (type: 'add' | 'remove') => void
}

const OPTIONS = [
  { value: 'add', label: 'Add' },
  { value: 'remove', label: 'Subtract' },
]

const MarkupRate: React.FC<Props> = ({
  isEnable,
  value,
  error,
  onChangeValue,
  onChangeEnable,
  markupType,
  onChangeMarkupType,
}) => {
  return (
    <>
      {/* <p className={'font-maison-neue text-20-32 text-neutral-800 mb-[4px]'}>Synced rates modifier</p>
      <p className="text-neutral-600 text-14-20 font-maison-neue mb-[16px]">
        Dtravel will automatically add or subtract this % to the rates synced from Airbnb
      </p> */}
      <div className={'flex flex-col md:flex-row items-start md:items-center justify-between gap-[16px]'}>
        <div className={'flex items-center gap-[16px] w-full md:w-[44%]'}>
          <BasicSwitch checked={Boolean(isEnable)} onChange={(event) => onChangeEnable(event.target.checked)} />
          <div className={'font-maison-neue text-16-20 text-neutral-800'}>Adjust synced rates</div>
        </div>

        <div className={'flex items-center justify-between gap-[16px] w-full md:w-[56%]'}>
          <div className={'flex items-center gap-[16px]'}>
            {OPTIONS.map((opt) => {
              return (
                <label key={opt.value} className={'flex items-center gap-[16px]'} htmlFor={`markup_type_${opt.value}`}>
                  <BasicRadio
                    id={`markup_type_${opt.value}`}
                    isWidthHeight20px
                    disabled={!isEnable}
                    checked={markupType === opt.value}
                    value={opt.value}
                    onChange={(event) => onChangeMarkupType(event.target.value as MarkupType)}
                  />
                  <span className={'font-maison-neue text-16-20 text-neutral-800 capitalize'}>{opt.label}</span>
                </label>
              )
            })}
          </div>
          <div className={'min-w-[128px] md:min-w-[80px] max-w-[128px]'}>
            <BasicInputPrice
              value={value || undefined}
              onValueChange={(values) => {
                let _valNumber = Number.isNaN(values.floatValue) ? '' : Math.abs(values.floatValue)
                if (_valNumber > 100) {
                  const str = String(_valNumber).slice(0, -1) // prevent input > 100
                  _valNumber = Number(str)
                }
                // let val = values.value
                onChangeValue(_valNumber)
              }}
              clases={'w-full pr-[36px] text-right'}
              disabled={!isEnable}
              error={error}
              endAdornment={
                <span
                  className={`absolute right-[12px] bottom-[4px] -translate-y-1/2 font-maison-neue text-16-20 text-neutral-500 ${isEnable ? '' : 'cursor-not-allowed'
                    }`}
                >
                  %
                </span>
              }
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default MarkupRate
