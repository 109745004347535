import { checkStatusHospitable, connectHospitable } from '../../../api/integration'
import { ONBOARDING_STEP } from '../../../contants/user'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { setAirbnbChannelId } from '../../../redux/slices/listing'
import { handleNextOnboardingStep } from '../../../redux/slices/user'
import { handleErrorMessage } from '../../../utils/common'
import { IconAlertTriangle, IconCalendar, IcUsers, IconRefresh } from '../../common/Icons'
import BasicButton from '../../ui/BasicButton'
import LayoutOnboarding from '../LayoutOnboarding'
import RightContentPmsCm from './RightContentPmsCm'
import clsx from 'clsx'
import React, { useEffect, useState, useRef } from 'react'

const NOTES = [
  {
    text: "Dtravel uses Hospitable Connect to import and sync your Airbnb account, which works even if you're connected to other channel management software.",
    icon: <IconRefresh />,
  },
  {
    text: 'You can only import active listings from Airbnb.',
    icon: <IconAlertTriangle />,
  },
  {
    text: 'Dtravel will automatically sync your rates and availability from Airbnb.',
    icon: <IconCalendar width={24} height={24} color={'black'} />,
  },
  {
    text: 'Co-hosting is not yet supported. If you are a co-host, you will need the listing owner to connect.',
    icon: <IcUsers width={24} height={24} color={'black'} />,
  },
]

interface Props {
  isHasAirbnbViaChannex: boolean
}

const OnboardingAirbnbImport: React.FC<Props> = ({ isHasAirbnbViaChannex }) => {
  const dispatch = useAppDispatch()
  const intervalRef = useRef<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [url, setUrl] = useState<string>('')
  const { airbnbViaHospitable } = useAppSelector((state) => state.user)

  useEffect(() => {
    fetchHospitableConnectionLink()
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [isHasAirbnbViaChannex])

  async function fetchHospitableConnectionLink() {
    try {
      setIsLoading(true)
      const resConnect = await connectHospitable()
      if (resConnect.data && resConnect.success) {
        const connectionId = resConnect.data.id
        const resCheckStatus = await checkStatusHospitable(
          connectionId,
          `/handle-connect-hospitable?connectionId=${connectionId}`
        )
        setUrl(resCheckStatus.data.returnUrl)
      }
    } catch (err: any) {
      handleErrorMessage(err)
      // setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  // eslint-disable-next-line
  const moveToStep = (type: string) => {
    if (type === 'ERROR') {
      dispatch(handleNextOnboardingStep(ONBOARDING_STEP.AIRBNB_CONNECT_ERROR))
    }
    if (type === 'NEXT') {
      dispatch(handleNextOnboardingStep(ONBOARDING_STEP.AIRBNB_CHOOSE_LISTINGS))
    }
    if (type === 'PMS_SELECT') {
      dispatch(handleNextOnboardingStep(ONBOARDING_STEP.PMS_SELECT))
    }
  }

  const onSubmit = () => {
    if (Array.isArray(airbnbViaHospitable) && airbnbViaHospitable.length > 0) {
      dispatch(setAirbnbChannelId(airbnbViaHospitable[0]?.channelId))
      moveToStep('NEXT')
    } else {
      if (url) {
        window.location.href = url
      }
    }
  }

  const renderSubmitBtn = () => {
    return (
      <div className={'flex items-center gap-[16px]'}>
        <BasicButton
          variant={'contained'}
          isRadius100={true}
          color={'red'}
          size={'xl'}
          onClick={onSubmit}
          loading={isLoading}
        >
          Connect to Airbnb
        </BasicButton>
        {/*<span className={'font-maison-neue text-14-20 text-neutral-500'}>You’ll be asked to approve Channex</span>*/}
      </div>
    )
  }

  return (
    <LayoutOnboarding headerTitle={'Listings'} rightContent={<RightContentPmsCm />}>
      <div className={'mt-[38px] lg:mt-[70px] w-full mx-auto md:max-w-[524px] pb-[112px]'}>
        <h2 className={'font-maison-neue text-24-36 text-neutral-800 mb-[16px]'}>
          Let&apos;s import your listings from Airbnb
        </h2>
        <p className={'font-maison-neue text-16-24 text-neutral-800'}>
          We&apos;ve partnered with Hospitable to power the Dtravel Channel Manager.
        </p>

        {/* Notes */}
        <div className={'p-[24px] rounded-[16px] bg-white mt-[24px]'}>
          <p className={'font-maison-neue-demibold text-16-24 text-neutral-800 mb-[24px]'}>Note:</p>
          <ul className={'list-none'}>
            {NOTES.map(({ icon, text }, index) => (
              <li
                className={'font-maison-neue text-16-24 text-neutral-800 flex items-start gap-[24px] pb-[24px]'}
                key={index}
              >
                <span>{icon}</span>
                <span>{text}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className={'mt-[24px] hidden lg:block'}>{renderSubmitBtn()}</div>
      </div>

      <div
        className={clsx(
          'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
          'border-t border-neutral-300 px-4 md:px-8',
          'shadow-footer'
        )}
      >
        {renderSubmitBtn()}
      </div>
    </LayoutOnboarding>
  )
} //

export default OnboardingAirbnbImport
