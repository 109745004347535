import { ListingItem } from '../../interfaces/listing'
import LayoutV2 from '../layout/LayoutV2'
import CircularProgress from '@mui/material/CircularProgress'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getTaxFeeSettings } from 'src/api/listings'
import { createManualReservation, getCalendarHostPrice } from 'src/api/reservation'
import ic_arrow_back from 'src/assets/icons/ic_arrow_left.svg'
import ic_eye_show from 'src/assets/icons/ic_eye_show.svg'
import PreviewReservation from 'src/components/reservation-manual/PreviewReservation'
import ReservationAddOn from 'src/components/reservation-manual/ReservationAddOn'
import ReservationExpiration from 'src/components/reservation-manual/ReservationExpiration'
import ReservationMessage from 'src/components/reservation-manual/ReservationMessage'
import ReservationSummary from 'src/components/reservation-manual/ReservationSummary'
import ReservationTaxFeeAndDiscount from 'src/components/reservation-manual/ReservationTaxFeeAndDiscount'
import ReservationTripInformation from 'src/components/reservation-manual/ReservationTripInformation'
import { DATE_FORMAT } from 'src/contants/common'
import { GuestObject } from 'src/interfaces'
import { AddOn, CustomFeeAndDiscount, PriceReservation, PriceType } from 'src/interfaces/reservations'
import { useAppSelector } from 'src/redux/hooks'
import {
  setAdults,
  setChildren,
  setDateTime,
  setInfants,
  setListingData,
  setPets,
  setToastSuccess,
} from 'src/redux/slices/common'
import { convertTimeZone, firstTimeLoadCalendar, handleErrorMessage } from 'src/utils/common'
import { getUserInfo } from 'src/utils/user'

const CreateReservation = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [hostId, setHostId] = useState<string>('')
  const [listingId, setListingId] = useState<string>('')
  const [currency, setCurrency] = useState<string>('USD')
  const [guestMessage, setGuestMessage] = useState<string>('')
  const [checkInDate, setCheckInDate] = useState<string | null>('')
  const [checkOutDate, setCheckOutDate] = useState<string | null>('')
  const [checkInTime, setCheckInTime] = useState<number>(0)
  const [checkOutTime, setCheckOutTime] = useState<number>(0)
  const [guest, setGuest] = useState<GuestObject>({
    adults: 1,
    children: 0,
    infants: 0,
    pets: 0,
  })
  const [priceData, setPriceData] = useState<PriceReservation | null>(null)
  const { listingData, dateTime, durationHour, expirationType } = useAppSelector((state) => state.common)
  // tax
  const [propertyTax, setPropertyTax] = useState<any[]>([])
  const [groupTax, setGroupTax] = useState<any[]>([])
  // fee
  const [propertyFee, setPropertyFee] = useState<any[]>([])
  const [customFee, setCustomFee] = useState<CustomFeeAndDiscount[]>([])
  const [groupFee, setGroupFee] = useState<any[]>([])
  // discount
  const [propertyDiscount, setPropertyDiscount] = useState<any[]>([])
  const [customDiscount, setCustomDiscount] = useState<CustomFeeAndDiscount[]>([])
  const [groupDiscount, setGroupDiscount] = useState<any[]>([])
  const [addOns, setAddOns] = useState<AddOn[]>([])
  const [totalFee, setTotalFee] = useState<number>(0)
  const [totalTax, setTotalTax] = useState<number>(0)
  const [totalDiscount, setTotalDiscount] = useState<number>(0)
  const [totalAddOn, setTotalAddOn] = useState<number>(0)
  const [finalPrice, setFinalPrice] = useState<number>(0)
  const [maxExpirationTime, setMaxExpirationTime] = useState<Moment | null>(null)
  const [timeZone, setTimeZone] = useState<string>('')
  const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false)
  const [reservationId, setReservationId] = useState<string>('')
  const [isBlockedDates, setIsBlockedDates] = useState<boolean>(false)

  async function fetchPrice(bodyData: any) {
    try {
      setLoading(true)
      const res = await getCalendarHostPrice(bodyData)
      setPriceData(res.data)
    } catch (err: any) {
      setPriceData(null)
      handleErrorMessage(err)
    } finally {
      setLoading(false)
    }
  }

  async function fetchTaxFeeSettings(
    listingId: string,
    checkIn: string,
    checkOut: string,
    adults: number,
    children: number,
    infants: number
  ) {
    try {
      const res = await getTaxFeeSettings(listingId, checkIn, checkOut, adults, children, infants)
      const { tax, fee, discount } = res.data
      if (Array.isArray(tax)) {
        setPropertyTax(tax.map((item) => ({ ...item, name: item.taxType, title: item.taxTitle })))
      } else {
        setPropertyTax([])
      }
      if (Array.isArray(fee)) {
        setPropertyFee(fee.map((item) => ({ ...item, name: item.feeType, title: item.feeTitle })))
      } else {
        setPropertyFee([])
      }
      if (Array.isArray(discount)) {
        setPropertyDiscount(discount.map((item) => ({ ...item, name: item.discountType, title: item.discountTitle })))
      } else {
        setPropertyDiscount([])
      }
    } catch (err: any) {
      handleErrorMessage(err)
    }
  }

  useEffect(() => {
    // clear reservation when
    return () => {
      setReservationId('')
    }
  }, [])

  useEffect(() => {
    const user = getUserInfo()
    if (user) {
      setHostId(user.userId)
    }
  }, [])

  // call api to get calendar data (4 months)
  useEffect(() => {
    async function fetchCalendar(address: string, id: string) {
      setLoading(true)
      await firstTimeLoadCalendar(address, id)
      setLoading(false)
    }

    if (hostId && listingData?.id) {
      fetchCalendar(hostId, listingData?.id)
    }
  }, [hostId, listingData?.id])

  // fetch price
  useEffect(() => {
    if (listingData && checkInDate && checkOutDate) {
      const { adults, children, infants, pets } = guest
      const bodyData: any = {
        checkinDate: checkInDate,
        checkoutDate: checkOutDate,
        listingId: Number(listingData.id),
        guestCount: adults + children + infants,
        adults,
        children,
        infants,
        pets,
        currency: currency,
      }
      if (propertyTax.length > 0) {
        if (customFee.length > 0) {
          let taxesAppliedCustomFee = new Set()
          for (let item of customFee) {
            if (!item.isPercent && item.configFee && item.configFee.isCheckedFlatFeeApply) {
              if (Array.isArray(item.configFee.flatFeeApplyOnTaxes)) {
                item.configFee.flatFeeApplyOnTaxes.forEach((tax: string) => taxesAppliedCustomFee.add(tax))
              }
            }
          }
          bodyData.taxSettings = propertyTax.map((item) => {
            const cloneItem = { ...item, name: item.taxType }
            if (taxesAppliedCustomFee.has(item.name)) {
              cloneItem.isIncludedInTotalPrice = true
            }
            return cloneItem
          })
        } else {
          bodyData.taxSettings = propertyTax
        }
      }
      if (propertyFee.length > 0) {
        bodyData.feeSettings = propertyFee
      }
      if (propertyDiscount.length > 0) {
        bodyData.discountSettings = propertyDiscount
      }
      if (customFee.length > 0) {
        bodyData.customFeeSettings = customFee.map((item) => {
          //Fee type = percentage
          const appliedOn = item.configFee?.percentageFeeApplyOn || 'BASE_PRICE'
          const feeAppliedPer = appliedOn === 'BASE_PRICE' ? 'base_rate' : 'reservation'
          if (item.isPercent) {
            return {
              feeTitle: item.title,
              feeAppliedPer: feeAppliedPer,
              amount: item.configAmount,
              amountType: 'percent',
              isIncludedInTotalPrice: item.isIncludedInTotalPrice,
            }
          }
          //Fee type = flat amount
          const flatFeeApplyOnTaxes = item.configFee?.flatFeeApplyOnTaxes || []
          return {
            feeTitle: item.title,
            feeAppliedPer: feeAppliedPer,
            amount: item.configAmount,
            amountType: 'flat',
            isIncludedInTotalPrice: item.isIncludedInTotalPrice,
            applyTo: flatFeeApplyOnTaxes,
          }
        })
      }

      fetchPrice(bodyData)
    }
  }, [
    listingData?.id,
    checkInDate,
    checkOutDate,
    guest,
    currency,
    propertyTax,
    propertyFee,
    propertyDiscount,
    customFee,
    customDiscount,
  ])

  useEffect(() => {
    if (listingId && checkInDate && checkOutDate) {
      const { adults, children, infants } = guest
      fetchTaxFeeSettings(listingId, checkInDate, checkOutDate, adults, children, infants)
    }
  }, [listingId, checkInDate, checkOutDate, guest])

  useEffect(() => {
    setListingId(listingData?.id ? String(listingData?.id) : '')
    setCheckInTime(listingData?.defaultCheckInTime ? Number(listingData.defaultCheckInTime) : 0)
    setCheckOutTime(listingData?.defaultCheckOutTime ? Number(listingData.defaultCheckOutTime) : 0)
    setTimeZone(listingData?.timezone ? convertTimeZone(listingData?.timezone) : '')
    setCurrency(listingData?.propertyPrice ? listingData?.propertyPrice?.currency : 'USD')
  }, [listingData?.id])

  // group, calculate fee and tax
  useEffect(() => {
    if (priceData) {
      const tax = Array.isArray(priceData.priceDetail.tax)
        ? priceData.priceDetail.tax.map((item) => ({ ...item, type: 'tax' }))
        : []
      const fee = Array.isArray(priceData.priceDetail.fee)
        ? priceData.priceDetail.fee.map((item) => ({ ...item, type: 'fee' }))
        : []
      // 1. calculate again percentage taxes from PMS, base on flat custom fee only if it has
      const calculatedTax = tax.filter((item) => item.isIncludedInTotalPrice)
      // .map((item) => {
      //   const cloneItem = { ...item }
      //   const taxeName = cloneItem.name
      //   // if this tax apply to fee => get config from property tax ( percentage amount)
      //   // mapping this tax with property tax (from PMS setting)
      //   const taxFromProperty = propertyTax.find((propertyTaxItem) => propertyTaxItem.name === taxeName)
      //   if (taxFromProperty) {
      //     const percemtAmount = taxFromProperty.amount
      //     let newValue: number = cloneItem.value
      //     for (let customFeeItem of customFee) {
      //       const { configFee, isPercent, isIncludedInTotalPrice } = customFeeItem
      //       // only calculate when isIncludedInTotalPrice and flat fee
      //       if (isIncludedInTotalPrice && !isPercent && configFee) {
      //         const flatFeeApplyOnTaxes = configFee.flatFeeApplyOnTaxes
      //         const isCheckedFlatFeeApply = configFee.isCheckedFlatFeeApply
      //         if (isCheckedFlatFeeApply && flatFeeApplyOnTaxes.includes(taxeName)) {
      //           newValue = newValue + (customFeeItem.value * percemtAmount) / 100
      //         }
      //       }
      //     }
      //     cloneItem.value = newValue
      //   }
      //   return cloneItem
      // })
      const basePrice = Number(priceData.price)
      const totalPrice = Number(priceData.totalPrice)
      // caluculate percentage fee
      // eslint-disable-next-line
      const calculatePercentCustomFee = customFee.map((item) => {
        const cloneItem = { ...item, isCustom: true } // add field isCustom = true
        if (cloneItem.isPercent) {
          const percentageFeeApplyOn = cloneItem.configFee?.percentageFeeApplyOn || 'BASE_PRICE'
          const priceBaseOn = percentageFeeApplyOn === 'ENTIRE_RESERVATION' ? totalPrice : basePrice
          cloneItem.value = (priceBaseOn * cloneItem.configAmount) / 100
        }
        return cloneItem
      })
      setGroupTax([...calculatedTax])
      const _totalTax = [...calculatedTax].reduce(
        (acc, curr) => acc + (curr.isIncludedInTotalPrice ? Number(curr.value) : 0),
        0
      )
      setTotalTax(_totalTax)
      // const _groupFee = [...fee, ...calculatePercentCustomFee]
      const _groupFee = [...fee]
      setGroupFee(_groupFee)
      const _totalFee = _groupFee.reduce((acc, curr) => acc + (curr.isIncludedInTotalPrice ? Number(curr.value) : 0), 0)
      setTotalFee(_totalFee)
    }
  }, [customFee, propertyTax, priceData])

  // calculate discount
  useEffect(() => {
    if (priceData) {
      const discount = Array.isArray(priceData.priceDetail.discount)
        ? priceData.priceDetail.discount.map((item) => ({ ...item, type: 'discount' }))
        : []
      const basePrice = Number(priceData.price)
      const priceAfterFee = basePrice + totalTax + totalFee
      const convertCustomPercentToFlat = customDiscount.map((item) => {
        const cloneItem = { ...item, isCustom: true }
        if (cloneItem.isPercent) {
          cloneItem.value = (priceAfterFee * cloneItem.configAmount) / 100
        }
        return cloneItem
      })
      const _group = [...discount, ...convertCustomPercentToFlat]
      setGroupDiscount(_group)
      const _totalDiscount = _group.reduce(
        (acc, curr) => acc + (curr.isIncludedInTotalPrice ? Number(curr.value) : 0),
        0
      )
      setTotalDiscount(_totalDiscount)
    }
  }, [propertyDiscount, customDiscount, priceData, totalTax, totalFee])

  useEffect(() => {
    const _totalAddOn = addOns.filter((item) => item.isIncludedInTotalPrice).reduce((acc, curr) => acc + curr.price, 0)
    setTotalAddOn(_totalAddOn)
  }, [addOns])

  // calculate total price
  useEffect(() => {
    setFinalPrice(Number(priceData?.price || 0) + totalTax + totalFee + totalDiscount)
  }, [totalTax, totalFee, totalDiscount, priceData?.price])

  useEffect(() => {
    const maxExpiration = moment(checkInDate, DATE_FORMAT)
    maxExpiration.set('hour', checkInTime)
    setMaxExpirationTime(maxExpiration)
  }, [checkInDate, checkInTime])

  const getNights = () => {
    if (checkInDate && checkOutDate) {
      const checkInMoment = moment(checkInDate, 'YYYY-MM-DD')
      const checkOutMoment = moment(checkOutDate, 'YYYY-MM-DD')
      return checkOutMoment.diff(checkInMoment, 'days')
    }
    return 0
  }

  const clearData = () => {
    dispatch(setAdults(1))
    dispatch(setChildren(0))
    dispatch(setInfants(0))
    dispatch(setPets(0))
    setCheckInDate(null)
    setCheckOutDate(null)
    dispatch(setDateTime(null)) // clear expiration date
    setGuest({ adults: 1, children: 0, infants: 0, pets: 0 }) // reset guest
    setCustomFee([]) // reset custom fee
    setPropertyTax([])
    setPropertyFee([])
    setPropertyDiscount([])
  }

  const onSelectOneProperty = (newData: ListingItem) => {
    if (newData.id !== listingData?.id) {
      clearData()
      dispatch(setListingData(newData))
    }
  }

  const onSaveDates = (_checkIn: string | null, _checkOut: string | null) => {
    setCheckInDate(_checkIn)
    setCheckOutDate(_checkOut)
    // clear expiration date
    dispatch(setDateTime(null))
  }

  const onSaveGuest = (guestData: GuestObject) => {
    setGuest(guestData)
  }

  const onSaveCheckInTime = (hour: number) => {
    setCheckInTime(hour)
  }

  const onSaveCheckOutTime = (hour: number) => {
    setCheckOutTime(hour)
  }

  const onChangeIsBlockedDates = (isBlocked: boolean) => {
    setIsBlockedDates(isBlocked)
  }

  const updatePropertyTaxFeeDiscount = (index: number, isChecked: boolean, priceType: PriceType) => {
    if (priceType === 'tax') {
      setPropertyTax((prevState) => {
        return prevState.map((item, indx) => {
          const cloneItem = { ...item }
          if (indx === index) cloneItem.isIncludedInTotalPrice = isChecked
          return cloneItem
        })
      })
    }
    if (priceType === 'fee') {
      setPropertyFee((prevState) => {
        return prevState.map((item, indx) => {
          const cloneItem = { ...item }
          if (indx === index) cloneItem.isIncludedInTotalPrice = isChecked
          return cloneItem
        })
      })
    }
    if (priceType === 'discount') {
      setPropertyDiscount((prevState) => {
        return prevState.map((item, indx) => {
          const cloneItem = { ...item }
          if (indx === index) cloneItem.isIncludedInTotalPrice = isChecked
          return cloneItem
        })
      })
    }
  }

  const onTogglePropertyTaxFeeDiscount = async (index: number, isChecked: boolean, priceType: PriceType) => {
    updatePropertyTaxFeeDiscount(index, isChecked, priceType)
    // if (checkInDate && checkOutDate && listingId) {
    //   // let currentData = null
    //   const { adults, children, infants, pets } = guest
    //   const bodyData: any = {
    //     checkinDate: checkInDate,
    //     checkoutDate: checkOutDate,
    //     listingId: Number(listingId),
    //     guestCount: adults + children + infants,
    //     adults,
    //     children,
    //     infants,
    //     pets,
    //     currency: currency,
    //   }
    //   if (priceType === 'tax') {
    //     bodyData.taxSettings = propertyTax.map((item, indx) => {
    //       const cloneItem = { ...item, name: item.taxType }
    //       if (indx === index) {
    //         cloneItem.isIncludedInTotalPrice = isChecked
    //       }
    //       return cloneItem
    //     })
    //     bodyData.feeSettings = propertyFee
    //     bodyData.discountSettings = propertyDiscount
    //   }
    //   if (priceType === 'fee') {
    //     bodyData.feeSettings = propertyFee.map((item, indx) => {
    //       const cloneItem = { ...item, name: item.feeType }
    //       if (indx === index) {
    //         cloneItem.isIncludedInTotalPrice = isChecked
    //       }
    //       return cloneItem
    //     })
    //     bodyData.taxSettings = propertyTax
    //     bodyData.discountSettings = propertyDiscount
    //     if (customFee.length > 0) {
    //       bodyData.customFeeSettings = customFee.map((item) => {
    //         //Fee type = percentage
    //         const appliedOn = item.configFee?.percentageFeeApplyOn || 'BASE_PRICE'
    //         const feeAppliedPer = appliedOn === 'BASE_PRICE' ? 'base_rate' : 'reservation'
    //         if (item.isPercent) {
    //           return {
    //             feeTitle: item.title,
    //             feeAppliedPer: feeAppliedPer,
    //             amount: item.configAmount,
    //             amountType: 'percent',
    //             isIncludedInTotalPrice: item.isIncludedInTotalPrice,
    //           }
    //         }
    //         //Fee type = flat amount
    //         const flatFeeApplyOnTaxes = item.configFee?.flatFeeApplyOnTaxes || []
    //         return {
    //           feeTitle: item.title,
    //           feeAppliedPer: feeAppliedPer,
    //           amount: item.configAmount,
    //           amountType: 'flat',
    //           isIncludedInTotalPrice: item.isIncludedInTotalPrice,
    //           applyTo: flatFeeApplyOnTaxes,
    //         }
    //       })
    //     }
    //   }
    //   if (priceType === 'discount') {
    //     bodyData.discountSettings = propertyDiscount.map((item, indx) => {
    //       const cloneItem = { ...item, name: item.discountType }
    //       if (indx === index) {
    //         cloneItem.isIncludedInTotalPrice = isChecked
    //       }
    //       return cloneItem
    //     })
    //     bodyData.taxSettings = propertyTax
    //     bodyData.feeSettings = propertyFee
    //   }
    //   await fetchPrice(bodyData)
    // }
  }

  const onChangeCustomFeeDiscount = async (newData: CustomFeeAndDiscount[], priceType: PriceType) => {
    if (priceType === 'fee') {
      setCustomFee(newData)
      // let taxesAppliedCustomFee = new Set()
      // for (let item of newData) {
      //   if (!item.isPercent && item.configFee && item.configFee.isCheckedFlatFeeApply) {
      //     if (Array.isArray(item.configFee.flatFeeApplyOnTaxes)) {
      //       item.configFee.flatFeeApplyOnTaxes.forEach((tax: string) => taxesAppliedCustomFee.add(tax))
      //     }
      //   }
      // }
      // // more logic:
      // if (checkInDate && checkOutDate && listingId) {
      //   const { adults, children, infants, pets } = guest
      //   const bodyData: any = {
      //     checkinDate: checkInDate,
      //     checkoutDate: checkOutDate,
      //     listingId: Number(listingId),
      //     guestCount: adults + children + infants,
      //     adults,
      //     children,
      //     infants,
      //     pets,
      //     currency: currency,
      //   }
      //   bodyData.taxSettings = propertyTax.map((item) => {
      //     const cloneItem = { ...item, name: item.taxType }
      //     if (taxesAppliedCustomFee.has(item.name)) {
      //       cloneItem.isIncludedInTotalPrice = true
      //     }
      //     return cloneItem
      //   })
      //   bodyData.feeSettings = propertyFee
      //   bodyData.discountSettings = propertyDiscount
      //
      //   // logic 09-11-2023: add custom fee to body
      //   bodyData.customFeeSettings = newData.map((item) => {
      //     //Fee type = percentage
      //     const appliedOn = item.configFee?.percentageFeeApplyOn || 'BASE_PRICE'
      //     const feeAppliedPer = appliedOn === 'BASE_PRICE' ? 'base_rate' : 'reservation'
      //     if (item.isPercent) {
      //       return {
      //         feeTitle: item.title,
      //         feeAppliedPer: feeAppliedPer,
      //         amount: item.configAmount,
      //         amountType: 'percent',
      //         isIncludedInTotalPrice: item.isIncludedInTotalPrice,
      //       }
      //     }
      //     //Fee type = flat amount
      //     const flatFeeApplyOnTaxes = item.configFee?.flatFeeApplyOnTaxes || []
      //     return {
      //       feeTitle: item.title,
      //       feeAppliedPer: feeAppliedPer,
      //       amount: item.configAmount,
      //       amountType: 'flat',
      //       isIncludedInTotalPrice: item.isIncludedInTotalPrice,
      //       applyTo: flatFeeApplyOnTaxes,
      //     }
      //   })
      //
      //   await fetchPrice(bodyData)
      // }
    }
    if (priceType === 'discount') {
      setCustomDiscount(newData)
    }
  }

  const onChangeAddOns = (newData: AddOn[]) => {
    setAddOns(newData)
  }

  const onChangeGuestMessage = (mess: string) => {
    setGuestMessage(mess)
  }

  const onClosePreview = () => {
    setIsOpenPreview(false)
  }

  const onClickPreviewReservation = async () => {
    const id = await upsertReservation(reservationId, 'draft')
    if (id) {
      setReservationId(id)
      setIsOpenPreview(true)
    }
  }

  const onClickCreateReservation = async () => {
    const id = await upsertReservation(reservationId, 'inquiry')
    if (id) {
      navigate('/reservations/' + id)
    }
  }

  const upsertReservation = async (_reservationId?: string, _status?: 'draft' | 'inquiry') => {
    try {
      setLoading(true)
      const priceComp = {
        id: null,
        listingFeeSettingId: null,
        type: 'price',
        name: 'baseRate',
        title: 'Base rate',
        alias: null,
        quantity: null,
        value: priceData?.price,
        total: priceData?.price,
        isIncludedInTotalPrice: 1,
        isOverriddenByUser: 0,
        isQuantitySelectable: 0,
        isMandatory: null,
        isDeleted: 0,
      }
      const priceComponents = [
        priceComp,
        ...groupTax,
        ...groupFee.filter(
          (item) => !item.isCustom && item.name !== 'Credit card processing fee' && item.name !== 'Card processing fee'
        ),
        ...groupDiscount.filter((item) => !item.isCustom),
      ].map((item: any) => {
        return {
          id: null,
          listingFeeSettingId: item.listingFeeSettingId,
          type: item.type,
          name: item.name,
          title: item.title,
          alias: null,
          quantity: null,
          value: Number(item.value),
          total: Number(item.value),
          isIncludedInTotalPrice: item.isIncludedInTotalPrice ? 1 : 0,
          isOverriddenByUser: 0,
          isQuantitySelectable: 0,
          isMandatory: null,
          isDeleted: 0,
        }
      })
      const customFeeComponents = groupFee
        .filter((item) => item.isCustom)
        .map((item) => {
          return {
            type: item.type,
            name: item.name || item.title,
            title: item.name || item.title,
            value: item.value,
            total: item.value,
            configAmount: item.amount,
            // configType: item.isPercent ? 'percentage' : 'amount',
            configType: item.amountType === 'flat' ? 'amount' : 'percentage',
            isIncludedInTotalPrice: item.isIncludedInTotalPrice,
          }
        })
      const customDiscountComponents = [...groupDiscount.filter((item) => item.isCustom)].map((item) => {
        return {
          type: item.type,
          name: item.name,
          title: item.name,
          value: item.value,
          total: item.value,
          configAmount: item.configAmount,
          configType: item.isPercent ? 'percentage' : 'amount',
          isIncludedInTotalPrice: item.isIncludedInTotalPrice,
        }
      })
      const customPriceComponents = [...customFeeComponents, ...customDiscountComponents]
      const bodyData: any = {
        checkinDate: checkInDate,
        checkoutDate: checkOutDate,
        checkinTime: checkInTime,
        checkoutTime: checkOutTime,
        guestCount: guest.adults + guest.children + guest.infants,
        adults: guest.adults,
        children: guest.children,
        infants: guest.infants,
        pets: guest.pets,
        listingId: listingData.id,
        finalPrice: finalPrice,
        currency: currency,
        hostSelectedCurrency: currency,
        priceComponents,
        customPriceComponents,
        expirationType,
        addons: addOns,
        priceInfo: priceData,
        guestMessage: guestMessage || null,
        status: _status,
        isBlockCalendar: isBlockedDates,
      }
      if (_reservationId) {
        bodyData.reservationId = _reservationId
      }
      if (expirationType === 'datetime') {
        bodyData.expiredAt = dateTime ? moment(dateTime).format('YYYY-MM-DD HH:mm:ss') : null
      }
      if (expirationType === 'duration') {
        bodyData.expirationDuration = durationHour * 60
      }

      const res = await createManualReservation(bodyData)
      if (_status === 'inquiry') {
        dispatch(setToastSuccess({ message: 'New reservation have been created successfully.' }))
      }
      // const reservationId = res.data.reservationId
      return res.data.reservationId
    } catch (err: any) {
      handleErrorMessage(err)
      return ''
    } finally {
      setLoading(false)
    }
  }

  const isSubmitDisabled = () => {
    const isHasExpiration =
      (expirationType === 'datetime' && dateTime) || (expirationType === 'duration' && durationHour)
    const isAvail = priceData && priceData.isAvail
    return (
      loading ||
      !listingData ||
      !priceData ||
      !isHasExpiration ||
      !isAvail ||
      !checkInDate ||
      !checkOutDate ||
      !checkInTime ||
      !checkOutTime
    )
  }

  return (
    <LayoutV2
      childrenHeader={
        <>
          <Link to={'/reservations'}>
            <button
              className={
                'rounded-full flex items-center justify-center bg-white w-[40px] h-[40px] hover:bg-neutral-200'
              }
            >
              <img alt={'ic_exit'} src={ic_arrow_back} />
            </button>
          </Link>
          <div className={'flex gap-[8px] items-center'}>
            <button
              className={
                'rounded-full bg-white w-[40px] h-[40px] flex items-center justify-center ' +
                'disabled:opacity-50 disabled:cursor-not-allowed'
              }
              disabled={isSubmitDisabled()}
              onClick={onClickPreviewReservation}
            >
              <img src={ic_eye_show} alt={'ic_eye_show'} />
            </button>

            <button
              className={
                'bg-red-700 rounded-[100px] px-[24px] py-[16px] md:py-[13px] font-maison-neue-medium text-16-16 md:text-14-14 text-white hidden md:block ' +
                'disabled:opacity-50 disabled:cursor-not-allowed'
              }
              disabled={isSubmitDisabled()}
              onClick={onClickCreateReservation}
            >
              Create reservation
            </button>
          </div>
        </>
      }
    >
      <div className={'relative'}>
        <div className={'w-full grid grid-cols-12 gap-[24px] md:gap-[48px]'}>
          <div className={'col-span-12 md:col-span-7'}>
            <h1 className={'font-maison-neue text-32-40 text-neutral-900 tracking-[-0.015em]'}>New reservation</h1>

            <div className={'mt-[40px]'}>
              <ReservationTripInformation
                hostId={hostId}
                checkInDate={checkInDate}
                checkOutDate={checkOutDate}
                onSaveDates={onSaveDates}
                guest={guest}
                onSaveGuest={onSaveGuest}
                checkInTime={checkInTime}
                onSaveCheckInTime={onSaveCheckInTime}
                checkOutTime={checkOutTime}
                onSaveCheckOutTime={onSaveCheckOutTime}
                timezone={timeZone}
                onSelectProperty={onSelectOneProperty}
              />
              <div className={'my-[40px] w-full h-[0.5px] bg-[#00000026]'} />
              <ReservationExpiration
                maxDate={maxExpirationTime}
                timezone={timeZone}
                isBlockedDates={isBlockedDates}
                onChangeIsBlockedDates={onChangeIsBlockedDates}
              />
              <div className={'my-[40px] w-full h-[0.5px] bg-[#00000026]'} />
              <ReservationTaxFeeAndDiscount
                currency={currency}
                propertyTax={propertyTax}
                propertyFee={propertyFee}
                propertyDiscount={propertyDiscount}
                customFee={customFee}
                customDiscount={customDiscount}
                onTogglePropertyData={onTogglePropertyTaxFeeDiscount}
                onChangeCustomData={onChangeCustomFeeDiscount}
              />
              <div className={'my-[40px] w-full h-[0.5px] bg-[#00000026]'} />
              <ReservationAddOn currency={currency} data={addOns} onChange={onChangeAddOns} />
              <div className={'my-[40px] w-full h-[0.5px] bg-[#00000026]'} />
              <ReservationMessage message={guestMessage} onChangeMessage={onChangeGuestMessage} />
            </div>
          </div>
          <div className={'col-span-12 md:col-span-5 relative'}>
            <ReservationSummary
              loading={loading}
              priceData={priceData}
              nights={getNights()}
              currency={currency}
              tax={groupTax}
              fee={groupFee}
              discount={groupDiscount}
              addOns={addOns}
              totalTax={totalTax}
              totalFee={totalFee}
              totalDiscount={totalDiscount}
              totalAddOn={totalAddOn}
              finalPrice={finalPrice}
            />
          </div>
        </div>

        {/* ---MOBILE: button Create--- */}
        <div className={'mt-[24px] mb-[16px]'}>
          <button
            className={
              'w-full bg-red-700 rounded-[100px] px-[24px] py-[16px] font-maison-neue-medium text-16-16 text-white md:hidden ' +
              'disabled:opacity-50 disabled:cursor-not-allowed'
            }
            disabled={isSubmitDisabled()}
            onClick={onClickCreateReservation}
          >
            Create reservation
          </button>
        </div>

        {loading && (
          <div
            className={
              'absolute w-full h-full top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center opacity-30 bg-neutral-200 cursor-not-allow'
            }
          >
            <CircularProgress size={50} style={{ color: '#0B2336' }} />
          </div>
        )}
        <PreviewReservation
          reservationId={reservationId}
          isOpen={isOpenPreview}
          onClose={onClosePreview}
          listingId={listingData?.id || ''}
          checkInDate={checkInDate}
          checkOutDate={checkOutDate}
          guest={guest}
        />
      </div>
    </LayoutV2>
  )
}

export default CreateReservation
