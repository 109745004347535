import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import React from 'react'

interface Props extends TooltipProps { }

const BootstrapTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#171716',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#171716', // neutral-8
    padding: '8px 12px',
    borderRadius: '8px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',

    // [`& .${tooltipClasses.arrow}`]: {
    //   // color: '#171716',
    //   color: '#FFFFFF',
    // },
    // [`& .${tooltipClasses.tooltip}`]: {
    //   // backgroundColor: '#171716', // neutral-8
    //   backgroundColor: '#FFFFFF', // neutral-8
    //   padding: '8px 12px',
    //   borderRadius: '8px',
    //   // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    //   boxShadow: '0px 20px 25px -5px rgba(0, 0, 0, 0.08), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
    //
    //   color: '#50504E',
  },
}))

const BasicTooltip: React.FC<Props> = ({ children, ...props }) => {
  return <BootstrapTooltip {...props}>{children}</BootstrapTooltip>
}

export default BasicTooltip
