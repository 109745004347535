import {
  getChannexConnectionLink,
  getListingByChannelId,
  getListingDetailByChannelId,
  importAirbnb,
} from '../../../api/integration'
import { ONBOARDING_STEP } from '../../../contants/user'
import { useAppDispatch } from '../../../redux/hooks'
import { setImportRequestId } from '../../../redux/slices/listing'
import { handleNextOnboardingStep } from '../../../redux/slices/user'
import { getNameByCountryCode, handleErrorMessage } from '../../../utils/common'
import LoadingSection from '../../common/LoadingSection'
import LoadingThreeDots from '../../common/LoadingThreeDots'
import BasicButton from '../../ui/BasicButton'
import BasicCheckbox from '../../ui/BasicCheckbox'
import LayoutOnboarding from '../LayoutOnboarding'
import RightContentPmsCm from './RightContentPmsCm'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

const OnboardingAirbnbChooseListingChannex = () => {
  const dispatch = useAppDispatch()
  // const { airbnbChannelId } = useAppSelector((state) => state.listing)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [listings, setListings] = useState<Array<any>>([])
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false)
  const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false)
  const [isLoadingSubmmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [listingDetails, setListingDetails] = useState<any>({})
  const [isEmpty, setIsEmpty] = useState<boolean>(false)

  useEffect(() => {
    async function fetchListingDetail(channelId: string, ids: string[]) {
      try {
        setIsLoadingDetail(true)
        const res = await Promise.all(ids.map((id) => getListingDetailByChannelId(channelId, id)))
        const details: any = {}
        res.forEach((item) => {
          const data = item.data
          details[data.id] = data
        })
        setListingDetails(details)
      } catch (err: any) {
        console.log(err)
      } finally {
        setIsLoadingDetail(false)
      }
    }
    async function fetchListingAirbnb() {
      try {
        setIsLoadingList(true)
        const resLink = await getChannexConnectionLink()
        const channelId = resLink.data.channelId
        const res = await getListingByChannelId(channelId)
        setIsLoadingList(false)
        if (Array.isArray(res.data) && res.data.length > 0) {
          setListings(res.data)
          setIsEmpty(false)
          const ids = res.data.map((item: any) => item.id)
          await fetchListingDetail(channelId, ids)
        } else {
          setListings([])
          setIsEmpty(true)
        }
      } catch (err: any) {
        handleErrorMessage(err)
        setIsLoadingList(false)
      }
    }

    fetchListingAirbnb()
  }, [])

  const onClickListingItem = (listingId: string) => {
    setSelectedIds((prevState) => {
      if (prevState.includes(listingId)) {
        return prevState.filter((id) => id !== listingId)
      } else {
        return [...prevState, listingId]
      }
    })
  }

  const onSubmit = async () => {
    try {
      setIsLoadingSubmit(true)
      const res = await importAirbnb({ selectedIds })
      dispatch(setImportRequestId(res.data?.requestId))
      // next step
      dispatch(handleNextOnboardingStep(ONBOARDING_STEP.AIRBNB_CONNECT_SUCCESS))
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const onSkip = () => {
    dispatch(handleNextOnboardingStep(ONBOARDING_STEP.SITE_DETAILS_SITE_NAME))
  }

  const renderThumbImage = (listingData: any) => {
    if (listingData) {
      const images = listingData.images
      if (Array.isArray(images) && images.length > 0) {
        return (
          <img
            src={images[0].thumbnail_url}
            alt={images[0].caption}
            className={'object-cover w-[32px] h-[32px] rounded-[8px]'}
          />
        )
      }
      return ''
    }
    return ''
  }

  const renderAddress = (listingData: any) => {
    if (listingData) {
      const { state, country_code } = listingData
      const countryName = getNameByCountryCode(country_code)
      return [state, countryName || country_code].join(', ')
    }
    return ''
  }

  const renderSubmitBtn = () => {
    return (
      <div className={'flex gap-[16px]'}>
        <BasicButton
          variant={'contained'}
          isRadius100={true}
          color={'red'}
          size={'xl'}
          onClick={onSubmit}
          loading={isLoadingSubmmit}
          disabled={selectedIds.length === 0}
        >
          Import
        </BasicButton>
        <span className={'lg:hidden'}>
          <BasicButton variant={'outlined'} isRadius100={true} color={'white'} size={'xl'} onClick={onSkip}>
            Skip
          </BasicButton>
        </span>
        <span className={'hidden lg:block'}>
          <BasicButton variant={'contained'} isRadius100={true} color={'white'} size={'xl'} onClick={onSkip}>
            Skip
          </BasicButton>
        </span>
      </div>
    )
  }

  const isHasScroll = listings.length > 7
  return (
    <LayoutOnboarding headerTitle={'Listings'} rightContent={<RightContentPmsCm />}>
      <div className={'mt-[38px] lg:mt-[70px] w-full mx-auto md:max-w-[524px] pb-[112px]'}>
        <h2 className={'font-maison-neue text-24-36 text-neutral-800 mb-[24px]'}>
          Choose which listings to add to Dtravel Direct
        </h2>

        <div
          className={clsx('relative flex flex-col rounded-[16px] overflow-y-auto bg-white shadow-sm', {
            'h-[512px]': isHasScroll && !isLoadingList,
            'h-auto': !isHasScroll && !isLoadingList,
            'h-[140px]': isLoadingList || (Array.isArray(listings) && listings.length === 0),
          })}
        >
          <LoadingSection isLoading={isLoadingList} size={30} />

          {isEmpty && (
            <div className={'font-maison-neue text-14-20 text-neutral-600 p-[40px] text-center'}>
              No property found in your Airbnb account
            </div>
          )}

          {Array.isArray(listings) &&
            listings.map((item, index) => {
              const isSelected = Array.isArray(selectedIds) && selectedIds.includes(item.id)
              const isLastItem = index === listings.length - 1
              return (
                <button
                  key={item.id}
                  className={clsx('flex gap-[12px] items-center p-[16px] border-[rgba(0,0,0,0.15)]', {
                    'bg-neutral-100': isSelected,
                    'border-b-[0.5px]': !isLastItem,
                  })}
                  onClick={() => onClickListingItem(item.id)}
                >
                  <BasicCheckbox color={'black'} checked={isSelected} onClick={() => onClickListingItem(item.id)} />
                  <div className={'w-[32px] h-[32px] rounded-[8px] ml-[12px]'}>
                    {isLoadingDetail ? (
                      <LoadingThreeDots color={'#292926'} />
                    ) : (
                      renderThumbImage(listingDetails[item.id])
                    )}
                  </div>
                  <div className={'text-left'}>
                    <p className={'font-maison-neue-medium text-14-18 text-neutral-800'}>{item.title}</p>
                    <p className={'font-maison-neue text-14-18 text-neutral-600'}>
                      {isLoadingDetail ? (
                        <LoadingThreeDots color={'#6A6A68'} />
                      ) : (
                        renderAddress(listingDetails[item.id])
                      )}
                    </p>
                  </div>
                </button>
              )
            })}
        </div>

        <div className={'mt-[24px] hidden lg:block'}>{renderSubmitBtn()}</div>
      </div>

      <div
        className={clsx(
          'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
          'border-t border-neutral-300 px-4 md:px-8',
          'shadow-footer'
        )}
      >
        {renderSubmitBtn()}
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingAirbnbChooseListingChannex
