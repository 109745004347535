import { IcChervonLeft, IcChervonRight } from '../common/Icons'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
// import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import clsx from 'clsx'
import React from 'react'

interface Props {
  total: number
  page: number
  pageSize: number
  totalPages: number
  handleChangeSearchAndPaging: (field: string, value: string) => void
}

const TablePaging: React.FC<Props> = ({ total, page, pageSize, totalPages, handleChangeSearchAndPaging }) => {
  const handleNextPage = () => {
    handleChangeSearchAndPaging('page', String(page >= totalPages ? totalPages : page + 1))
  }

  const handlePrevPage = () => {
    handleChangeSearchAndPaging('page', String(page > 1 ? page - 1 : 1))
  }

  if (total <= 0) {
    return null
  }

  return (
    <>
      <div
        className={clsx(
          'w-full h-[52px] bg-neutral-50',
          'flex items-center px-[16px] gap-[16px]',
          'rounded-bl-[16px] rounded-br-[16px]',
          'justify-between',
          'font-maison-neue-medium text-14-18'
        )}
        style={{ boxShadow: '0px 0.5px 0px 0px rgba(0, 0, 0, 0.15)' }}
      >
        <div className={' text-neutral-600 px-[8px] py-[6px]'}>
          {(page - 1) * pageSize + 1}-{total > page * pageSize ? page * pageSize : total} of {total} listings
        </div>
        <div className={'hidden lg:flex items-center gap-[24px]'}>
          <button
            className={clsx(
              'flex items-center justify-center',
              'gap-[4px] rounded-[4px]',
              'disabled:opacity-40 disabled:cursor-not-allowed'
            )}
            onClick={handlePrevPage}
            disabled={page === 1}
          >
            <IcChervonLeft width={24} height={24} color={page === 1 ? '#8C8C88' : '#292926'} />
            <span>Previous</span>
          </button>
          <button
            className={clsx(
              'flex items-center justify-center',
              'gap-[4px] rounded-[4px]',
              'disabled:opacity-40 disabled:cursor-not-allowed'
            )}
            onClick={handleNextPage}
            disabled={page === totalPages}
          >
            <span className={page === totalPages ? '' : ''}>Next</span>
            <IcChervonRight width={24} height={24} color={page === totalPages ? '#8C8C88' : '#292926'} />
          </button>
        </div>
      </div>
      {/* Paging mobile */}
      <div className={'fixed lg:hidden z-50 bottom-[88px] right-[16px] flex items-center gap-[8px]'}>
        <button
          className={`w-[52px] h-[52px] rounded-[12px] ${page === 1 ? 'bg-neutral-300' : 'bg-neutral-800'}`}
          onClick={handlePrevPage}
          disabled={page === 1}
        >
          <ChevronLeftIcon style={{ color: '#FBFAFA' }} />
        </button>
        <button
          className={`w-[52px] h-[52px] rounded-[12px] ${page === totalPages ? 'bg-neutral-300' : 'bg-neutral-800'}`}
          onClick={handleNextPage}
          disabled={page === totalPages}
        >
          <ChevronRightIcon style={{ color: '#FBFAFA' }} />
        </button>
      </div>
    </>
  )
}

export default TablePaging
