import ic_copy from '../../../assets/icons/ic_copy.svg'
import ic_metamask from '../../../assets/icons/ic_metamask.svg'
import useWallet from '../../../hooks/useWallet'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { setToastSuccess } from '../../../redux/slices/common'
import { isEmpty, shorterAddress } from '../../../utils/common'
import StripeWarningBanner from '../../home/StripeWarningBanner'
import ConnectWalletThirdWeb from '../../thirdweb/ConnectWalletThirdWeb'
import BasicButton from '../../ui/BasicButton'
import BasicInput from '../../ui/BasicInput'
import SettingsStripeAccount from './SettingsStripeAccount'
import React from 'react'
import ic_smart_wallet from 'src/assets/icons/ic_smart_wallet.svg'
import { SETTINGS_SIDE_BAR, SETUP_GUIDE } from 'src/contants/common'

interface Props { }

const SettingPaymentIntegration: React.FC<Props> = () => {
  const { handleConnectMetamask, isConnectingMetamask } = useWallet()
  const { profile } = useAppSelector((state) => state.user)
  const dtravelSmartWallet = profile?.user?.hasSmartWallet ? profile?.user?.walletId : ''
  const metamaskWallet = !profile?.user?.hasSmartWallet ? profile?.user?.walletId : ''
  const dispatch = useAppDispatch()

  const renderStatus = (isConnected: boolean) => {
    return (
      <>
        {isConnected ? (
          <span className="font-maison-neue-medium text-forest-6 uppercase text-12-16 bg-forest-1 px-2 py-[1px] rounded-[100px] tracking-[.04em] ml-[8px]">
            CONNECTED
          </span>
        ) : (
          <span className="font-maison-neue-medium text-neutral-6 uppercase text-12-16 bg-neutral-2 px-2 py-[1px] rounded-[100px] tracking-[.04em]">
            DISCONNECTED
          </span>
        )}
      </>
    )
  }

  const renderMetamaskPayment = () => {
    return (
      <>
        {/*----Metamask wallet---- */}
        <div
          className={'flex flex-col md:flex-row gap-[12px] justify-between items-start md:items-center'}
          id={SETUP_GUIDE.CONNECT_METAMASK_WALLET}
        >
          <div className={'flex gap-[12px]'}>
            <div className={'flex items-center justify-center rounded-full border border-neutral-3 w-[42px] h-[42px]'}>
              <img src={ic_metamask} alt={'ic_metamask'} className={'w-[34px] h-[34px]'} />
            </div>
            <div>
              <p className={'flex items-center gap-[8px]'}>
                <span className={'font-maison-neue-medium text-16-20 text-neutral-8'}>Wallet Address</span>
                {renderStatus(!isEmpty(metamaskWallet))}
              </p>
              <p className={'font-maison-neue text-neutral-6 text-14-18 mt-[4px]'}>
                {metamaskWallet ? (
                  <span className={'flex items-center gap-[10px]'}>
                    <span>{shorterAddress(metamaskWallet)}</span>
                    <img
                      src={ic_copy}
                      alt=""
                      className="w-[18px] h-[18px] cursor-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(metamaskWallet)
                        dispatch(setToastSuccess({ message: 'Copied' }))
                      }}
                      role="presentation"
                    />
                  </span>
                ) : (
                  'Accept crypto payments and earn rewards '
                )}
              </p>
            </div>
          </div>

          {!metamaskWallet && (
            <BasicButton
              onClick={() => handleConnectMetamask(true)}
              size={'md'}
              variant={'contained'}
              id="connect_wallet_unify"
              clases={'w-full md:w-auto'}
              loading={isConnectingMetamask}
              isRadius100={true}
            >
              {isConnectingMetamask ? 'Connecting...' : 'Connect'}
            </BasicButton>
          )}
        </div>
      </>
    )
  }
  const renderDtravelSmartWalletPayment = () => {
    return (
      <>
        <div className={'flex flex-col md:flex-row gap-[12px] justify-between items-start md:items-center'}>
          <div className={'flex items-center gap-[12px]'}>
            <div
              className={
                'rounded-full w-[42px] min-w-[42px] h-[42px] flex items-center justify-center border border-neutral-300'
              }
            >
              <img src={ic_smart_wallet} alt={''} className="rounded-full w-[24px] h-[24px]" />
            </div>
            <div>
              <p className={'flex items-center gap-[8px]'}>
                <span className={'font-maison-neue-medium text-16-20 text-neutral-8'}>Dtravel Smartwallet</span>
                {!isEmpty(dtravelSmartWallet) && renderStatus(true)}
              </p>
              <p className={'font-maison-neue text-14-18 text-neutral-6 mt-[4px]'}>
                Accept cryptocurrencies and web3 rewards via a non-custodial wallet.
              </p>
            </div>
          </div>
          {isEmpty(dtravelSmartWallet) && <ConnectWalletThirdWeb className="smart-wallet-onboarding" disableAutoConnect />}
        </div>
        {!isEmpty(dtravelSmartWallet) && (
          <div className={'flex items-center gap-[12px] mt-[-8px]'}>
            <div className={'rounded-full w-[42px] min-w-[42px] h-[42px] flex items-center justify-center'} />
            <div className="flex flex-col gap-2 w-full">
              <BasicInput
                value={dtravelSmartWallet}
                showIconCopy={true}
                onCopy={() => {
                  navigator.clipboard.writeText(dtravelSmartWallet)
                  dispatch(setToastSuccess({ message: 'Copied' }))
                }}
                readOnly={true}
                style={{
                  color: '#8C8C88', // neutral-500
                }}
              />
              {/*<div*/}
              {/*  className="flex items-center justify-between gap-2 py-[10px] px-4 bg-neutral-50 rounded-[12px]"*/}
              {/*  style={{*/}
              {/*    border: '0.5px solid rgba(0, 0, 0, 0.15)',*/}
              {/*    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <p*/}
              {/*    className="text-neutral-500 text-14-18 font-maison-neue text-ellipsis whitespace-nowrap overflow-hidden max-w-[calc(100vw_-_168px)]"*/}
              {/*    title={dtravelSmartWallet}*/}
              {/*  >*/}
              {/*    {dtravelSmartWallet}*/}
              {/*  </p>*/}
              {/*  <img*/}
              {/*    src={ic_copy}*/}
              {/*    alt=""*/}
              {/*    className="w-[20px] h-[20px] cursor-pointer opacity-[0.6]"*/}
              {/*    onClick={() => {*/}
              {/*      navigator.clipboard.writeText(dtravelSmartWallet)*/}
              {/*      dispatch(setToastSuccess({ message: 'Copied' }))*/}
              {/*    }}*/}
              {/*    role="presentation"*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
          </div>
        )}
      </>
    )
  }
  return (
    <div>
      <h2 id={SETTINGS_SIDE_BAR.PAYMENT_INTEGRATION} className={'font-maison-neue text-20-24 text-neutral-800'}>
        Payment Integrations
      </h2>
      <p className={'font-maison-neue text-14-18 text-neutral-500 mt-[4px]'}>
        Connect the payment gateways that work best for your business
      </p>
      <div className={'flex flex-col gap-[24px] w-full bg-white rounded-[16px] mt-[16px] p-[24px]'}>
        <StripeWarningBanner position={'settings'} />

        <SettingsStripeAccount />

        <div className={'w-full h-[1px] bg-neutral-300'} />

        {!isEmpty(metamaskWallet) ? renderMetamaskPayment() : renderDtravelSmartWalletPayment()}
      </div>
    </div>
  )
}

export default SettingPaymentIntegration
