import { PMS_TYPE } from '../../../contants/common'
import { ONBOARDING_STEP } from '../../../contants/user'
import { useAppSelector } from '../../../redux/hooks'
import { IconAirbnbOrange, IconDtravelDirect, IconGuesty, IconQuestionCircle } from '../../common/Icons'
import React from 'react'
import nextpax_logo from 'src/assets/images/nextpax_logo.webp'
import onboarding_group_133516 from 'src/assets/images/onboarding_group_133516.png'
import onboarding_group_133517 from 'src/assets/images/onboarding_group_133517.png'
import onboarding_line_44 from 'src/assets/images/onboarding_line_44.png'
import track_pms from 'src/assets/images/track_pms.png'

const RightContentPmsCm = () => {
  const { onboardingData, onboardingStep, pmsTypeConnected } = useAppSelector((state) => state.user)

  const renderLogoPms = () => {
    const arrSteps = onboardingStep.split(', ')
    const length = arrSteps.length
    const step = arrSteps[length - 1]
    if ([ONBOARDING_STEP.PMS_SELECT, ONBOARDING_STEP.PMS_CONNECT_SUCCESS].includes(step)) {
      const pmsType = onboardingData?.pmsType || pmsTypeConnected
      if (pmsType && pmsType !== PMS_TYPE.OTHER) {
        if (pmsType === PMS_TYPE.GUESTY) {
          return <IconGuesty />
        }
        if (pmsType === PMS_TYPE.GUEST_SMILES) {
          return <img src={track_pms} alt={'PMS logo'} width={'32px'} height={'32px'} />
        }
        if (pmsType === PMS_TYPE.NEXTPAX) {
          return <img src={nextpax_logo} alt={'PMS logo'} width={'32px'} height={'32px'} />
        }
        return (
          <img src={`https://static.dtravel.com/logo/${pmsType}.png`} alt={'PMS logo'} width={'32px'} height={'32px'} />
        )
      }
    }
    if (
      [
        ONBOARDING_STEP.AIRBNB_IMPORT,
        ONBOARDING_STEP.AIRBNB_CHOOSE_LISTINGS,
        ONBOARDING_STEP.AIRBNB_CONNECT_ERROR,
      ].includes(step)
    ) {
      return <IconAirbnbOrange width={64} height={64} />
    }
    return <IconQuestionCircle />
  }

  return (
    <div className={'flex items-center gap-[16px]'}>
      <div>
        <div className={'w-[64px] h-[64px] rounded-full bg-neutral-200 shadow-sm flex justify-center items-center'}>
          {renderLogoPms()}
        </div>
      </div>
      <div className={'relative flex items-center justify-center px-[48px] xl:px-[73px]'}>
        <div
          className={
            'z-20 flex flex-col gap-[10px] border-[5.3px] border-black rounded-[21.5px] px-[10px] pt-[10px] bg-neutral-100'
          }
        >
          <img src={onboarding_group_133516} alt={''} />
          <img src={onboarding_group_133517} alt={''} />
        </div>
        <div className={'absolute top-[48.5%] left-0 right-0 z-10 w-full'}>
          <img src={onboarding_line_44} alt={''} className={'w-full'} />
        </div>
      </div>
      <div>
        <IconDtravelDirect width={64} height={64} />
      </div>
    </div>
  )
}

export default RightContentPmsCm
