import LayoutOnboarding from '../LayoutOnboarding'
import OnboardingSiteDetailsFees from './OnboardingSiteDetailsFees'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { getPaymentMethodHost } from 'src/api/integration'
import credit_card_payment from 'src/assets/icons/credit_card_payment.svg'
import ic_affirm from 'src/assets/icons/ic_affirm.svg'
import ic_apple_pay from 'src/assets/icons/ic_apple_pay.svg'
import ic_google_pay from 'src/assets/icons/ic_google_pay.svg'
import { ONBOARDING_STEP } from 'src/contants/user'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { handleNextOnboardingStep } from 'src/redux/slices/user'

interface Props {}

const OnboardingSiteDetailsFeesContainer: React.FC<Props> = () => {
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector((state) => state.user)
  const [paymentMethod, setPaymentMethod] = useState<Array<any>>([])
  const [isGuestPayFee, setIsGuestPayFee] = useState<boolean>(true)

  const fetchPaymentMethodHost = async () => {
    try {
      const res = await getPaymentMethodHost()
      if (res.success) {
        const result = res.data.sort((a: any, b: any) => a.order - b.order)
        setPaymentMethod(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchPaymentMethodHost()
  }, [])

  const renderRightContent = () => {
    return (
      <div className="h-full flex pt-[300px] overflow-hidden">
        <div
          className={clsx(
            'w-[400px] h-[868px] pb-[228px] overflow-hidden',
            'border-[8px] border-black rounded-[40px]',
            'flex flex-col items-center bg-white p-6 gap-4'
          )}
        >
          <div className="w-full flex items-center">
            <span className="text-20-32 font-inter-500 text-neutral-900">Payment methods</span>
          </div>
          {[
            {
              icon: () => <img src={credit_card_payment} width={173} height={20} alt="" />,
              isActived: true,
              title: 'Credit card',
              subTitle: isGuestPayFee ? 'Includes a 2.9% + $0.30 fee' : '',
            },
            {
              icon: () => {
                return (
                  <div className="flex items-center gap-[5px]">
                    {paymentMethod
                      ?.filter((v) => v.type === 'CRYPTO')
                      ?.map((v: any, index: number) => {
                        return <img src={v?.icon} width={20} height={20} alt="" key={index} title={v?.name} />
                      })}
                  </div>
                )
              },
              isActived: true,
              title: 'Crypto',
              subTitle: 'No extra fees',
            },
            {
              icon: () => <img src={ic_apple_pay} width={20} height={20} alt="" />,
              isActived: false,
              title: 'Apple Pay',
            },
            {
              icon: () => <img src={ic_google_pay} width={20} height={20} alt="" />,
              isActived: false,
              title: 'Google Pay',
            },
            {
              icon: () => <img src={ic_affirm} width={20} height={20} alt="" />,
              isActived: false,
              title: 'Affirm',
            },
          ].map((el: any, idx: number) => {
            return (
              <div
                key={idx}
                className={clsx(
                  'px-6 py-[48px] border border-neutral-300 rounded-[16px] w-full flex items-center justify-between gap-[4px]',
                  el?.isActived ? 'opacity-[1]' : 'opacity-30'
                )}
              >
                <div className="flex flex-col">
                  {el.icon()}
                  <p className="text-16-20 font-maison-neue-medium text-neutral-900 mb-1 mt-3">{el?.title}</p>
                  {el?.subTitle && <p className="text-14-20 font-maison-neue text-neutral-500">{el?.subTitle}</p>}
                </div>
                <div
                  className={clsx('w-[24px] h-[24px] rounded-[50%] cursor-pointer', 'border-[2px] border-neutral-400')}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
  const handleNext = async () => {
    const hasWallet = profile?.user?.walletId
    dispatch(
      handleNextOnboardingStep(
        hasWallet ? ONBOARDING_STEP.SITE_DETAILS_CURRENCIES : ONBOARDING_STEP.DISTRIBUTION_CHANNELS
      )
    )
  }

  return (
    <LayoutOnboarding rightContent={renderRightContent()} headerTitle={'Payment methods'}>
      <div className="pb-[136px] lg:pb-[64px] pt-[38px] lg:pt-[64px] max-w-[524px] mx-auto overflow-auto">
        <OnboardingSiteDetailsFees
          isGuestPayFee={isGuestPayFee}
          handleUpdateGuestPayFee={(value: boolean) => setIsGuestPayFee(value)}
          handleNext={handleNext}
        />
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingSiteDetailsFeesContainer
