import { ONBOARDING_STEP } from '../../../contants/user'
import { useAppDispatch } from '../../../redux/hooks'
import LayoutOnboarding from '../LayoutOnboarding'
import OnboardingSiteDetailsCurrencies from './OnboardingSiteDetailsCurrencies'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { getPaymentMethodHost } from 'src/api/integration'
import ic_arrow_right_white from 'src/assets/icons/ic_arrow_right_white.svg'
import { handleNextOnboardingStep } from 'src/redux/slices/user'
import { removeDuplicate } from 'src/utils/common'

interface Props { }

const OnboardingSiteDetailsCurrenciesContainer: React.FC<Props> = () => {
  const dispatch = useAppDispatch()
  const [paymentMethod, setPaymentMethod] = useState<Array<any>>([])

  useEffect(() => {
    fetchPaymentMethodHost()
  }, [])

  const fetchPaymentMethodHost = async () => {
    try {
      const res = await getPaymentMethodHost()
      if (res.success) {
        const result = res.data.sort((a: any, b: any) => a.order - b.order)
        setPaymentMethod(result)
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  const renderRightContent = () => {
    return (
      <div className="h-full flex pt-[300px] overflow-hidden">
        <div
          className={clsx(
            'w-[400px] h-[868px] pb-[228px] overflow-hidden',
            'border-[8px] border-black rounded-[40px]',
            'flex flex-col items-center bg-white p-6 overflow-hidden'
          )}
        >
          <div className="w-full flex items-center justify-between mb-6">
            <span className="text-20-32 font-inter-500 text-neutral-900">Pay with crypto</span>
            <span className="text-14-18 font-inter-500 text-neutral-600 underline">Show contracts</span>
          </div>
          <div className="w-full flex flex-col gap-4">
            {removeDuplicate(paymentMethod, "key").map((el: any, idx: number) => {
              if (el.type !== 'CRYPTO') return null
              const isChecked = el.status
              return (
                <div
                  key={idx}
                  className={clsx(
                    'px-6 py-[32px] border border-neutral-300 rounded-[16px] w-full flex items-center justify-between gap-[4px]',
                    isChecked ? 'opacity-[1]' : 'opacity-30'
                  )}
                >
                  <div className="flex items-center gap-3">
                    <img src={el.icon} width={24} height={24} alt="" />
                    <p className="text-16-20 font-maison-neue-medium text-neutral-900">{el?.currency}</p>
                  </div>
                  <div
                    className={clsx(
                      'w-[24px] h-[24px] rounded-[50%] cursor-pointer',
                      isChecked ? 'border-[8px] border-neutral-900' : 'border-[2px] border-neutral-400'
                    )}
                  />
                </div>
              )
            })}
          </div>
          <div className="flex flex-col gap-3 mt-6 pt-6 border-t-[12px] border-neutral-100 mx-[-24px] px-6">
            <p className="text-20-24 font-inter-500 text-neutral-900">Complete booking</p>
            <p className="text-16-20 font-maison-neue text-neutral-600">
              By completing this booking, you agree to the&nbsp;
              <span className="underline">Terms and Conditions</span>
              &nbsp;and&nbsp;
              <span className="underline">Privacy Policy</span>.
            </p>
            <div className="bg-neutral-900 px-6 py-5 rounded-[16px] flex items-center justify-center">
              <p className="text-16-20 font-inter-500 text-white">Confirm and book now</p>
              <img src={ic_arrow_right_white} alt={''} className="w-5 h-5 ml-2" />
            </div>
          </div>
        </div>
      </div>
    )
  }
  const handleNext = async () => {
    dispatch(handleNextOnboardingStep(ONBOARDING_STEP.DISTRIBUTION_CHANNELS))
  }

  return (
    <LayoutOnboarding rightContent={renderRightContent()} headerTitle={'Payment methods'}>
      <div className="pb-[136px] lg:pb-[64px] pt-[38px] lg:pt-[64px] max-w-[524px] mx-auto overflow-auto">
        <OnboardingSiteDetailsCurrencies
          paymentMethod={paymentMethod}
          handleNext={handleNext}
          fetchPaymentMethodHost={fetchPaymentMethodHost}
        />
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingSiteDetailsCurrenciesContainer
