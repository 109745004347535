import React from 'react'
import { useSwitch } from '@mui/base';
import { styled } from '@mui/system'
import clsx from 'clsx'

interface SwitchProps {
  checkedColor?: string
  thumbColor?: string
  id?: string
  checked: boolean
  disabled?: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
  showCloseIcon?: boolean
}

const grey = {
  400: '#949494',
  500: '#AAB4BE',
  600: '#6F7E8C',
}

const BasicSwitchRoot = styled('span')(
  ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  // margin: 10px;
  background: ${theme.palette.mode === 'dark' ? grey[600] : '#D1CDCB'};
  border-radius: 12px;
  cursor: pointer;

  &.Switch-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.Switch-checked {
    background: #000;
  }`
)

const BasicSwitchInput = styled('input')`
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
`

const BasicSwitchThumb = styled('span')`
  display: block;
  width: 16px;
  height: 16px;
  top: 2px;
  left: 2px;
  border-radius: 28px;
  background-color: #fff;
  position: relative;
  transition: all 200ms ease;

  &.Switch-focusVisible {
    background-color: ${grey[500]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.Switch-checked {
    left: 18px;
    top: 2px;
    background-color: #fff;
  }
},
`

function BasicSwitch(props: SwitchProps) {
  const { checkedColor, thumbColor, showCloseIcon } = props
  const { getInputProps, checked, disabled, focusVisible } = useSwitch(props)

  const stateClasses = {
    'Switch-checked': checked,
    'Switch-disabled': disabled,
    'Switch-focusVisible': focusVisible,
  }

  return (
    <BasicSwitchRoot className={clsx(stateClasses)} style={checked && checkedColor ? { background: checkedColor } : {}}>
      <BasicSwitchThumb className={clsx(stateClasses)} style={thumbColor ? { background: thumbColor } : {}}>
        {showCloseIcon &&
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.97982 4.98043C5.17508 4.78517 5.49167 4.78517 5.68693 4.98043L11.0202 10.3137C11.2155 10.509 11.2155 10.8256 11.0202 11.0209C10.825 11.2161 10.5084 11.2161 10.3131 11.0209L4.97982 5.68754C4.78456 5.49228 4.78456 5.17569 4.97982 4.98043Z" fill="#CFCFCB" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.0203 4.98043C11.2155 5.17569 11.2155 5.49228 11.0203 5.68754L5.68693 11.0209C5.49167 11.2161 5.17508 11.2161 4.97982 11.0209C4.78456 10.8256 4.78456 10.509 4.97982 10.3138L10.3132 4.98043C10.5084 4.78517 10.825 4.78517 11.0203 4.98043Z" fill="#CFCFCB" />
          </svg>
        }
      </BasicSwitchThumb>
      <BasicSwitchInput {...getInputProps()} aria-label="Switch" />
    </BasicSwitchRoot>
  )
}

export default BasicSwitch
