import { getReservations } from '../../api/integration'
import no_image_listing from '../../assets/images/no_image_listing.jpg'
import { PAGE_SIZE_DEFAULT } from '../../contants/common'
import { Paging, ServerResponse } from '../../interfaces'
import { ListingInfo, ReservationInfo } from '../../interfaces/reservations'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setLoading } from '../../redux/slices/common'
import { renderTimeMobile } from '../../utils/common'
import RenderPrice from '../common/RenderPrice'
import LayoutV2 from '../layout/LayoutV2'
import BasicButton from '../ui/BasicButton'
import RenderReservationStatus from './RenderReservationStatus'
import ReservationListNoResult from './ReservationListNoResult'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import IconButton from '@mui/material/IconButton'
import clsx from 'clsx'
import momentTZ from 'moment-timezone'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ic_add_plus from 'src/assets/icons/ic_add_plus.svg'

const ReservationList = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { search, pathname } = useLocation()
  const { isLoading } = useAppSelector((state) => state.common)
  const [data, setData] = useState<ReservationInfo[]>([])
  const [paging, setPaging] = useState<Paging>({ page: 1, pageSize: 1, total: 0, totalPages: 0 })
  const [hoverIndex, setHoverIndex] = useState<number>(-1)

  useEffect(() => {
    async function fetchData(params: any) {
      try {
        dispatch(setLoading(true))
        const newParams = { page: 1, pageSize: PAGE_SIZE_DEFAULT }
        if (params?.page) {
          newParams.page = Number(params.page)
        }
        if (params?.pageSize) {
          newParams.pageSize = Number(params.pageSize)
        }
        const res: ServerResponse = await getReservations(newParams)
        const { data, paging } = res
        setPaging(paging)
        setData(data)
      } catch (err) {
        console.log(err)
      } finally {
        dispatch(setLoading(false))
      }
    }

    const parsed = queryString.parse(search)
    fetchData({ ...parsed })
  }, [search])

  const handleNextPrevPage = (type: 'next' | 'prev') => {
    const { page, totalPages } = paging
    let newPage: number = page >= totalPages ? totalPages : page + 1
    if (type === 'prev') {
      newPage = page > 1 ? page - 1 : 1
    }
    const parsed = queryString.parse(search)
    let newFilters: any = { ...parsed, page: newPage }
    const stringified = queryString.stringify(newFilters, { skipEmptyString: true, skipNull: true })
    navigate(pathname + stringified ? '?' + stringified : '')
  }

  const renderThumbImage = (listingInfo: ListingInfo) => {
    return listingInfo.thumbnailUrl || no_image_listing
  }

  // const renderPayoutStatus = (_paymentMethod: string, _status: string, _reservationStatus: string) => {
  //   if (_paymentMethod === PAYMENT_METHOD.CRYPTO) {
  //     if (_reservationStatus === 'cancelled') {
  //       return 'Processed'
  //     }
  //     if (_status) {
  //       //partial_payout, payout, processing, error
  //       switch (_status.toLowerCase()) {
  //         case 'partial_payout':
  //           return 'Processing'
  //         case 'processing':
  //           return 'Processing'
  //         case 'payout':
  //           return 'Processed'
  //         default:
  //           return 'Pending'
  //       }
  //     }
  //     return 'Pending'
  //   }
  //   return ''
  // }

  const { page, pageSize, total, totalPages } = paging

  return (
    <LayoutV2 showDefaultHeader={true} isFullWidth={true}>
      <div className={'w-full px-0 sm:px-[32px] lg:px-[48px] xl:px-[32px]'}>
        <div className={'flex justify-between items-center mb-[24px] mx-[16px] md:mx-0'}>
          <h3 className={'text-24-28 font-maison-neue text-neutral-800'}>Reservations</h3>
          <Link to={'/create-reservation'} className={'text-right'}>
            <BasicButton isRadius100={true} variant={'contained'} color={'red'} size={'md'} clases="hidden md:flex">
              <span className={'font-maison-neue-medium text-14-18 text-white'}>Create reservation</span>
            </BasicButton>
            <div className="w-10 h-10 flex items-center justify-center md:hidden text-white bg-red-700 hover:bg-red-800 rounded-[50%]">
              <img src={ic_add_plus} alt={'ic_plus'} />
            </div>
          </Link>
        </div>

        <div className={'relative'}>
          {paging.total === 0 && !isLoading ? (
            <ReservationListNoResult />
          ) : (
            <div>
              {/* Paging */}
              <div
                className={
                  'bg-neutral-50 shadow-table rounded-t-[16px] flex gap-[10px] items-center justify-end px-[16px] h-[52px] border-b-[0.5px] border-[rgba(0_0_0_0.15)]'
                }
              >
                <div className={'font-maison-neue-medium text-14-18 text-neutral-600'}>
                  {(page - 1) * pageSize + 1}-{total > page * pageSize ? page * pageSize : total} of {total}
                </div>
                <IconButton size={'small'} onClick={() => handleNextPrevPage('prev')} disabled={page === 1}>
                  <KeyboardArrowLeftIcon fontSize={'small'} />
                </IconButton>
                <IconButton size={'small'} onClick={() => handleNextPrevPage('next')} disabled={page === totalPages}>
                  <KeyboardArrowRightIcon fontSize={'small'} />
                </IconButton>
              </div>
              <div className={'overflow-x-auto mb-[120px]'}>
                <table className={'w-full bg-white px-[16px] shadow-3xl rounded-b-[16px] border-collapse '}>
                  <thead>
                    <tr
                      className={
                        'h-[52px] font-maison-neue-medium text-12-16 text-neutral-600 shadow-table hidden md:table-row'
                      }
                    >
                      <th
                        className={
                          'text-left px-[16px] w-[25%] min-w-[264px] bg-white border-b-[0.5px] border-[rgba(0_0_0_0.15)] freezeColumn'
                        }
                      >
                        Listing
                      </th>
                      <th className={'text-left px-[16px] w-[10%] min-w-[170px]'}>Check-in</th>
                      <th className={'text-left px-[16px] w-[10%] min-w-[170px]'}>Check-out</th>
                      <th className={'text-left px-[16px] w-[12.5%] min-w-[170px]'}>Reservation status</th>
                      <th className={'text-left px-[16px] w-[12.5%] min-w-[170px]'}>Payout</th>
                      {/*<th className={'text-left px-[16px] w-[10%] min-w-[170px]'}>Payout status</th>*/}
                      <th className={'text-left px-[16px] w-[10%] min-w-[170px]'}>Currency</th>
                      <th className={'text-left px-[16px] w-[10%] min-w-[170px]'}>Booking date</th>
                    </tr>
                  </thead>

                  <tbody>
                    {Array.isArray(data) &&
                      data.map((item, index) => {
                        return (
                          <tr
                            key={item.id}
                            className={'shadow-table font-maison-neue text-14-18 text-neutral-600 hover:bg-neutral-100'}
                            onMouseEnter={() => setHoverIndex(index)}
                            // onMouseOver={() => setHoverIndex(index)}
                            // onFocus={() => setHoverIndex(index)}
                            onMouseLeave={() => setHoverIndex(-1)}
                          >
                            <td
                              className={clsx(
                                'px-[16px] py-[20px] hidden md:table-cell border-b-[0.5px] border-[rgba(0_0_0_0.15)] freezeColumn',
                                `${index === hoverIndex ? 'bg-neutral-100' : 'bg-white'}`
                              )}
                            >
                              <Link to={`/reservations/${item.reservationId}`} className={'w-auto'}>
                                <div className={'flex items-center gap-[12px] hover:underline'}>
                                  <img
                                    className={'w-[40px] h-[40px] rounded-[8px] object-cover'}
                                    src={renderThumbImage(item.listingInfo)}
                                    alt={item.listingInfo.externalName}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null // prevents looping
                                      currentTarget.src = no_image_listing
                                    }}
                                  />
                                  <div>
                                    <p className={'font-maison-neue-medium text-14-18 text-neutral-800 line-clamp-1'}>
                                      {item.listingInfo.internalName || item.listingInfo.name}
                                    </p>
                                    <p className={'font-maison-neue text-14-18 text-neutral-600 line-clamp-1 mt-[4px]'}>
                                      {item.listingInfo.externalName}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </td>
                            <td className="hidden md:table-cell px-[16px] border-b-[0.5px] border-[rgba(0_0_0_0.15)]">
                              {item.checkinDate ? (
                                momentTZ.tz(item.checkinDate, item.timezone).utc().format('MMMM D, YYYY')
                              ) : (
                                <></>
                              )}
                            </td>
                            <td className="hidden md:table-cell px-[16px] border-b-[0.5px] border-[rgba(0_0_0_0.15)]">
                              {item.checkoutDate ? (
                                momentTZ.tz(item.checkoutDate, item.timezone).utc().format('MMMM D, YYYY')
                              ) : (
                                <></>
                              )}
                            </td>
                            <td className="hidden md:table-cell px-[16px] border-b-[0.5px] border-[rgba(0_0_0_0.15)]">
                              <RenderReservationStatus status={item.status} isHaveDot />
                            </td>
                            <td className="hidden md:table-cell px-[16px] border-b-[0.5px] border-[rgba(0_0_0_0.15)]">
                              <RenderPrice price={Number(item?.finalPrice)} currency={item.currency} />
                            </td>
                            {/*<td className="hidden md:table-cell px-[16px] border-b-[0.5px] border-[rgba(0_0_0_0.15)]">*/}
                            {/*  {renderPayoutStatus(item.paymentMethod, item.payoutStatus, item.status)}*/}
                            {/*</td>*/}
                            <td className="hidden md:table-cell px-[16px] border-b-[0.5px] border-[rgba(0_0_0_0.15)]">
                              {item.currency}
                            </td>
                            <td className="hidden md:table-cell px-[16px] border-b-[0.5px] border-[rgba(0_0_0_0.15)]">
                              {item.createdAt ? (
                                momentTZ.tz(item.createdAt, item.timezone).utc().format('MMMM D, YYYY')
                              ) : (
                                <></>
                              )}
                            </td>

                            {/*Row for mobile*/}
                            <td className="py-[20px] px-[16px] table-cell md:hidden">
                              <div
                                className={'flex gap-[16px] items-center'}
                                onClick={() => {
                                  navigate('/reservations/' + item.reservationId)
                                }}
                                role="presentation"
                              >
                                <img
                                  className={'w-[48px] h-[48px] rounded-[8px] object-cover'}
                                  src={renderThumbImage(item.listingInfo)}
                                  alt={item.listingInfo.externalName}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null // prevents looping
                                    currentTarget.src = no_image_listing
                                  }}
                                />
                                <div className={'flex flex-col gap-[4px]'}>
                                  <RenderReservationStatus status={item.status} isHaveDot />
                                  <div className={'mt-[2px]'}>
                                    {renderTimeMobile(item.checkinDate, item.checkoutDate)}
                                  </div>
                                </div>
                                <div className={'ml-auto'}>
                                  <RenderPrice price={Number(item.finalPrice)} currency={item.currency} />
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </LayoutV2>
  )
}

export default ReservationList
