import DisabledSectionOnEdit from '../common/DisabledSectionOnEdit'
import BasicButton from '../ui/BasicButton'
import BasicSelect from '../ui/BasicSelect'
import BasicSwitch from '../ui/BasicSwitch'
import TooltipClick from '../ui/TooltipClick'
import NativeListingBackAndContinue from './NativeListingBackAndContinue'
import clsx from 'clsx'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getPropertyBedTypes, updateBulkProperties, updateProperty } from 'src/api/native-listing'
import ic_delete_circle from 'src/assets/icons/ic_delete_circle.svg'
import ic_minus from 'src/assets/icons/ic_minus.png'
import ic_plus from 'src/assets/icons/ic_plus.png'
import { NATIVE_LISTING_MENU, PMS_TYPE } from 'src/contants/common'
import { PROPERTY_STATUS } from 'src/contants/native'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setLoading, setToastSuccess } from 'src/redux/slices/common'
import { setBulkListingData, setBulkListingSection } from 'src/redux/slices/listing'
import { handleErrorMessage, isEmpty } from 'src/utils/common'

interface RoomsDataProps {
  bedroomsNumber: number
  bathroomsNumber: number
  livingrooms: number
  kitchens: number
  personCapacity: number
  maxChildrenAllowed: number
  rooms: any[]
  bathrooms: any[]
}
interface Props {
  listingData: any
  fetchStatusStep: any
}
const kingBedId = 41
const maxNumber = 20

const NativeListingGuestRooms = ({ listingData, fetchStatusStep }: Props) => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const dispatch = useAppDispatch()
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768
  const { bulkListingData, bulkListingSection } = useAppSelector((state) => state.listing)
  const parseQuery = queryString.parse(search)
  const isOnboarding = parseQuery?.f === 'onboarding'
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const isDraft = listingData?.status === PROPERTY_STATUS.DRAFT || isEmpty(parseQuery?.id)
  const defaultStudioRoom = () => ({
    name: `Studio`,
    hasBathroom: false,
    bedTypes: [],
  })
  const defaultRoomItem = (name: string, idx: number) => ({
    name: `${name} ${idx}`,
    hasBathroom: false,
    bedTypes: [{ bedTypeId: kingBedId, quantity: 1 }],
  })
  const defaultBathroom = { name: 'Private', type: 'private' }
  const [roomsGuest, setRoomsGuest] = useState<RoomsDataProps>({
    bedroomsNumber: 1,
    bathroomsNumber: 1,
    livingrooms: 1,
    kitchens: 1,
    personCapacity: 1,
    maxChildrenAllowed: maxNumber,
    rooms: [defaultRoomItem('BEDROOM', 1), defaultRoomItem('LIVING ROOM', 1)],
    bathrooms: [defaultBathroom],
  })
  const [bedTypes, setBedTypes] = useState<any[]>([])
  const [toggleEdit, setToggleEdit] = useState<any>({ guests: false, rooms: false })

  const isStudio = roomsGuest?.bedroomsNumber === 0 && roomsGuest?.livingrooms === 0
  const isHospitable = listingData?.pmsType === PMS_TYPE.HOSPITABLE

  const convertPropertyRooms = (propertyRooms: any[]) => {
    const result: any[] = (propertyRooms || []).map((el: any) => {
      const newItem: any = {
        ...el,
        bedTypes: (el.bedTypes || []).map((v: any) => {
          return { bedTypeId: v.id, name: v.name, quantity: v.quantity }
        }),
      }
      return newItem
    })
    return result
  }
  useEffect(() => {
    if (listingData) {
      setRoomsGuest({
        bedroomsNumber: listingData?.bedrooms === null ? 1 : listingData?.bedrooms,
        bathroomsNumber: listingData?.bathrooms === null ? 1 : listingData?.bathrooms,
        livingrooms: listingData?.livingrooms === null ? 1 : listingData?.livingrooms,
        kitchens: listingData?.kitchens === null ? 1 : listingData?.kitchens,
        personCapacity:
          listingData?.personCapacity === null
            ? 1
            : listingData?.personCapacity > 16
              ? 17
              : listingData?.personCapacity,
        maxChildrenAllowed:
          isEmpty(listingData?.maxChildren) && listingData?.maxChildren !== 0 ? maxNumber : listingData?.maxChildren,
        rooms:
          isEmpty(listingData?.propertyRooms) && listingData?.bedrooms === null && listingData?.livingrooms === null
            ? [defaultRoomItem('BEDROOM', 1), defaultRoomItem('LIVING ROOM', 1)]
            : isEmpty(listingData?.propertyRooms) && listingData?.bedrooms === 0 && listingData?.livingrooms === 0 // studio
              ? [defaultStudioRoom()]
              : convertPropertyRooms(listingData?.propertyRooms || []),
        bathrooms: listingData?.bathroomTypes !== null ? listingData?.bathroomTypes : [defaultBathroom],
      })
    }
  }, [listingData])
  useEffect(() => {
    if (isEditBulk) {
      setRoomsGuest({
        bedroomsNumber: bulkListingData?.bedroomsNumber || -1,
        bathroomsNumber: bulkListingData?.bathroomsNumber || -1,
        livingrooms: bulkListingData?.livingrooms || -1,
        kitchens: bulkListingData?.kitchens || -1,
        personCapacity: bulkListingData?.personCapacity || -1,
        maxChildrenAllowed: bulkListingData?.maxChildrenAllowed || maxNumber,
        rooms: bulkListingData?.rooms || [],
        bathrooms: bulkListingData?.bathrooms || [],
      })
    }
  }, [bulkListingData])
  console.log('roomsGuest', roomsGuest)
  const changeBeds = (value: RoomsDataProps) => setRoomsGuest(value)
  const fetchData = async () => {
    try {
      const res: any = await getPropertyBedTypes()
      if (res?.success) setBedTypes(res?.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  const getBedsNumber = (rooms: any[]) => {
    let bedsResult: any[] = []
    rooms.forEach((el: any) => {
      bedsResult = [...bedsResult, ...(el.bedTypes || [])]
    })
    return bedsResult.length
  }
  const handleSubmit = async (isExit?: boolean) => {
    try {
      dispatch(setLoading(true))
      let dataDTO: any = {}
      if ((isEditBulk && toggleEdit.guests) || !isEditBulk) {
        dataDTO = {
          ...dataDTO,
          personCapacity: roomsGuest?.personCapacity > 16 ? maxNumber : roomsGuest?.personCapacity,
          maxChildrenAllowed: roomsGuest?.maxChildrenAllowed,
        }
      }
      if ((isEditBulk && toggleEdit.rooms) || !isEditBulk) {
        dataDTO = {
          ...roomsGuest,
          ...dataDTO,
          bedsNumber: getBedsNumber(roomsGuest?.rooms),
        }
      }
      if (isDraft && !isEditBulk)
        dataDTO = { ...dataDTO, settingSteps: listingData?.settingSteps > 3 ? listingData?.settingSteps : 3 }
      let res: any = null
      if (isEditBulk) {
        if (toggleEdit.rooms || toggleEdit.guests) {
          const propertyIds = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
          dispatch(setBulkListingData({ ...bulkListingData, ...roomsGuest }))
          res = await updateBulkProperties({ propertyIds, propertyInfo: dataDTO })
          setToggleEdit({ guests: false, rooms: false })
        }
      } else {
        const newAmenities: any[] = [...(listingData?.amenities || [])]
          .filter((el: any) => el.id !== 30)
          .map((v: any) => ({ amenityId: v.id }))
        dataDTO = { ...dataDTO, amenities: newAmenities }
        res = await updateProperty(Number(parseQuery.id), dataDTO)
      }
      if (res?.success) {
        fetchStatusStep()
        if (isExit) navigate(isOnboarding ? '/' : `/listings`)
        else if (isDraft && !isEditBulk) navigate(`/native-listing/${NATIVE_LISTING_MENU.PHOTOS}` + search)
        else dispatch(setToastSuccess({ message: 'Your changes have been applied.' }))
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const renderActionItem = (
    type: 'bedroomsNumber' | 'bathroomsNumber' | 'livingrooms' | 'kitchens' | 'personCapacity',
    maxValue: number
  ) => {
    const currentNumberShow = roomsGuest[type] === -1 ? '' : roomsGuest[type]
    const currentNumber = roomsGuest[type] ? (roomsGuest[type] === -1 ? 0 : roomsGuest[type]) : 0
    const isPersonCapacity = type === 'personCapacity'
    const isBedRoom = type === 'bedroomsNumber'
    const isBathRoom = type === 'bathroomsNumber'
    const isLivingRoom = type === 'livingrooms'
    const disabledMinus = currentNumber === 0
    const disabledPlus = currentNumber === maxValue
    return (
      <div className="flex items-center justify-center gap-2">
        <button
          className={clsx(
            'rounded-full flex items-center justify-center bg-white w-[36px] h-[36px]',
            disabledMinus ? 'opacity-50 cursor-not-allowed' : 'opacity-[1] cursor-pointer'
          )}
          disabled={disabledMinus}
          onClick={() => {
            if (currentNumber > 0) {
              let newRoomsGuest = { ...roomsGuest, [type]: currentNumber - 1 }
              if (isBedRoom || isLivingRoom) {
                if (
                  currentNumber - 1 === 0 &&
                  ((isBedRoom && roomsGuest['livingrooms'] === 0) ||
                    (isLivingRoom && roomsGuest['bedroomsNumber'] === 0))
                ) {
                  newRoomsGuest = { ...newRoomsGuest, rooms: [defaultStudioRoom()] }
                } else {
                  let bedRoomsInfo = [...roomsGuest.rooms].filter((v) => v.name.includes('BEDROOM'))
                  if (isBedRoom) bedRoomsInfo = bedRoomsInfo.filter((v: any, i: number) => i < currentNumber - 1)
                  let livingRoomsInfo = [...roomsGuest.rooms].filter((v) => v.name.includes('LIVING'))
                  if (isLivingRoom)
                    livingRoomsInfo = livingRoomsInfo.filter((v: any, i: number) => i < currentNumber - 1)
                  newRoomsGuest = { ...newRoomsGuest, rooms: [...bedRoomsInfo, ...livingRoomsInfo] }
                }
              }
              if (isBathRoom) {
                newRoomsGuest = {
                  ...newRoomsGuest,
                  bathrooms: [...roomsGuest.bathrooms].filter((v: any, i: number) => i < currentNumber - 1),
                }
              }
              changeBeds(newRoomsGuest)
            }
          }}
        >
          <img alt={'icon'} src={ic_minus} />
        </button>
        <div className="w-[64px] min-w-[64px] flex items-center justify-center text-24-36 text-neutral-800 font-maison-neue">
          {isPersonCapacity && currentNumber > 16 ? '16+' : currentNumberShow}
        </div>
        <button
          className={clsx(
            'rounded-full flex items-center justify-center bg-white w-[36px] h-[36px]',
            disabledPlus ? 'opacity-50 cursor-not-allowed' : 'opacity-[1] cursor-pointer'
          )}
          disabled={disabledPlus}
          onClick={() => {
            let newRoomsGuest = { ...roomsGuest, [type]: currentNumber + 1 > maxValue ? maxValue : currentNumber + 1 }
            if (isBedRoom || isLivingRoom) {
              const bedRoomsInfo = [...roomsGuest.rooms].filter((v) => v.name.includes('BEDROOM'))
              const livingRoomsInfo = [...roomsGuest.rooms].filter((v) => v.name.includes('LIVING'))
              newRoomsGuest = {
                ...newRoomsGuest,
                rooms: isBedRoom
                  ? [...bedRoomsInfo, defaultRoomItem('BEDROOM', bedRoomsInfo.length + 1), ...livingRoomsInfo]
                  : [...bedRoomsInfo, ...livingRoomsInfo, defaultRoomItem('LIVING ROOM', livingRoomsInfo.length + 1)],
              }
            }
            if (isBathRoom) {
              newRoomsGuest = { ...newRoomsGuest, bathrooms: [...roomsGuest.bathrooms, defaultBathroom] }
            }
            changeBeds(newRoomsGuest)
          }}
        >
          <img alt={'icon'} src={ic_plus} />
        </button>
      </div>
    )
  }
  const renderBathroomSelect = (item: any, idx: number, disabled: boolean) => {
    return (
      <div className="w-1/2 md:w-[258px]">
        <BasicSelect
          label={''}
          options={[
            { value: 'private', label: 'Private' },
            { value: 'shared', label: 'Shared' },
          ]}
          disabled={disabled}
          value={item.type}
          onChange={(e: any) => {
            const newBathRoomsId = e?.target?.value
            const newBathRooms = [...roomsGuest.bathrooms].map((v: any, i: number) => {
              if (i === idx)
                return {
                  name: newBathRoomsId === 'private' ? 'Private' : 'Shared',
                  type: newBathRoomsId,
                }
              return v
            })
            setRoomsGuest({ ...roomsGuest, bathrooms: newBathRooms })
          }}
        />
      </div>
    )
  }
  return (
    <>
      <div className="flex flex-col gap-8">
        <p className="text-28-36 text-neutral-800 font-maison-neue tracking-[-0.01em]">
          {/*{isEditBulk ? 'How many guests can stay in these properties?' : 'How many guests can stay in this property?'}*/}
          Guests & Rooms
        </p>
        <DisabledSectionOnEdit
          isDisable={isHospitable}
          title={
            isEditBulk ? 'How many guests can stay in these properties?' : 'How many guests can stay in this property?'
          }
          openEdit={toggleEdit?.guests}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, guests: value })}
        >
          <div className={'flex flex-col rounded-[16px] p-[24px] bg-white shadow-basic'}>
            <div className={clsx('w-full flex justify-between py-[24px] border-b border-neutral-300')}>
              <div className="flex flex-col">
                <p className={'font-maison-neue-medium text-16-20 text-neutral-8'}>Maximum number of guests</p>
                <p className={'font-maison-neue text-14-18 text-neutral-6 hidden md:block mt-[4px]'}>
                  Default price per night will be the same for this amount of guests
                </p>
              </div>
              {renderActionItem('personCapacity', 17)}
            </div>

            <div className={clsx('w-full flex justify-between py-[24px]')}>
              <div className="flex flex-col">
                <p className={'font-maison-neue-medium text-16-20 text-neutral-8'}>Are children allowed? (Age 2-12)</p>
                <p className={'font-maison-neue text-14-18 text-neutral-6 hidden md:block mt-[4px]'}>
                  Only children above the age of 2 are counted in the total guests
                </p>
              </div>
              <BasicSwitch
                checked={roomsGuest.maxChildrenAllowed > 0}
                onChange={() => {
                  setRoomsGuest({
                    ...roomsGuest,
                    maxChildrenAllowed: roomsGuest.maxChildrenAllowed > 0 ? 0 : maxNumber,
                  })
                }}
                checkedColor="#292926"
              />
            </div>
          </div>
        </DisabledSectionOnEdit>
        <DisabledSectionOnEdit
          isDisable={isHospitable}
          title="How many rooms are available to guests?"
          openEdit={toggleEdit?.rooms}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, rooms: value })}
        >
          {/* <p className="font-maison-neue text-neutral-800 text-20-32 mt-[32px]">
            How many rooms are available to guests?
          </p> */}
          <div className={'w-full bg-white rounded-[16px] shadow-basic px-[24px] mt-[16px]'}>
            {[
              { name: 'Bedroom', key: 'bedroomsNumber' },
              { name: 'Bathroom', key: 'bathroomsNumber' },
              { name: 'Living room', key: 'livingrooms' },
              { name: 'Kitchen', key: 'kitchens' },
            ].map((item: any, index: number) => {
              const isFirstItem = index === 0
              return (
                <div
                  key={item.key}
                  className={clsx('flex justify-between py-[24px]', {
                    ['border-t border-neutral-300 ']: !isFirstItem,
                  })}
                >
                  <div className="flex items-center gap-[12px]">
                    <div>
                      <p className={'flex flex-col md:flex-row justify-start gap-[4px] md:gap-[8px]'}>
                        <span className={'font-maison-neue text-16-24 text-neutral-800'}>{item.name}</span>
                      </p>
                    </div>
                  </div>
                  {renderActionItem(item.key, 50)}
                </div>
              )
            })}
          </div>
          {(!isEmpty(roomsGuest.rooms) || isStudio) && (
            <>
              <p className="font-maison-neue text-neutral-800 text-20-32 mt-[32px]">
                What types of beds are available?
              </p>
              {!isEmpty(roomsGuest.rooms) &&
                [...roomsGuest.rooms].map((el: any, idx: number) => {
                  return (
                    <div
                      className={'w-full bg-white rounded-[16px] shadow-basic px-[24px] pb-6 mt-[16px] pt-6'}
                      key={idx}
                    >
                      {!isStudio && (
                        <p className="pb-6 text-14-18 font-inter-600 uppercase tracking-[0.04em]">{el.name}</p>
                      )}
                      <div className="flex flex-col gap-2">
                        {!isEmpty(el.bedTypes) &&
                          el.bedTypes.map((item: any, index: number) => {
                            return (
                              <div key={index} className={clsx('flex justify-between')}>
                                <div className="flex items-center gap-[12px]">
                                  <div className={'flex items-center gap-[4px] md:gap-[8px]'}>
                                    <img
                                      alt={'ic_exit'}
                                      src={ic_delete_circle}
                                      className="cursor-pointer"
                                      role="presentation"
                                      onClick={() => {
                                        const newRooms = [...roomsGuest.rooms].map((v: any, i: number) => {
                                          if (i === idx)
                                            return {
                                              ...v,
                                              bedTypes: [...v.bedTypes].filter((u: any, j: number) => j !== index),
                                            }
                                          return v
                                        })
                                        setRoomsGuest({ ...roomsGuest, rooms: newRooms })
                                      }}
                                    />
                                    <span className={'font-maison-neue text-16-24 text-neutral-800'}>
                                      Bed {index + 1}
                                    </span>
                                  </div>
                                </div>
                                <div className="w-1/2 md:w-[258px]">
                                  <BasicSelect
                                    label={''}
                                    placeholder="select bed type"
                                    options={bedTypes.map((v: any) => ({ ...v, value: v.id, label: v.name }))}
                                    value={item.bedTypeId}
                                    onChange={(e: any) => {
                                      const newBedTypeId = Number(e?.target?.value)
                                      const newBedTypes = { ...item, bedTypeId: newBedTypeId }
                                      const newRooms = [...roomsGuest.rooms].map((v: any, i: number) => {
                                        if (i === idx)
                                          return {
                                            ...v,
                                            bedTypes: [...v.bedTypes].map((u: any, j: number) =>
                                              j === index ? newBedTypes : u
                                            ),
                                          }
                                        return v
                                      })
                                      setRoomsGuest({ ...roomsGuest, rooms: newRooms })
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          })}
                        <div>
                          <BasicButton
                            variant="contained"
                            size={'md'}
                            color={'white'}
                            clases={clsx(
                              `w-auto rounded-[100px] flex justify-center border border-neutral-300 shadow-[0px_1px_2px_rgba(0,0,0,0.05)] mt-2`
                            )}
                            onClick={() => {
                              const newRooms = [...roomsGuest.rooms].map((v: any, i: number) => {
                                if (i === idx)
                                  return { ...v, bedTypes: [...v.bedTypes, { bedTypeId: kingBedId, quantity: 1 }] }
                                return v
                              })
                              setRoomsGuest({ ...roomsGuest, rooms: newRooms })
                            }}
                          >
                            <span className={`text-14-18 font-maison-neue-medium`}>Add a bed</span>
                          </BasicButton>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </>
          )}
          {!isEmpty(roomsGuest.bathrooms) && (
            <>
              <p className="font-maison-neue text-neutral-800 text-20-32 mt-[32px]">
                What types of bathrooms are available?
              </p>
              <div className={'w-full bg-white rounded-[16px] shadow-basic px-[24px] mt-[16px] py-6'}>
                <div className="flex flex-col gap-2">
                  {[...roomsGuest.bathrooms].map((item: any, idx: number) => {
                    const disabled = listingData?.roomType?.toLowerCase() === 'entire place'
                    return (
                      <div key={idx} className={clsx('flex justify-between')}>
                        <div className="flex items-center gap-[12px]">
                          <p className={'flex flex-col md:flex-row justify-start gap-[4px] md:gap-[8px]'}>
                            <span className={'font-maison-neue text-16-24 text-neutral-800'}>Bathroom {idx + 1}</span>
                          </p>
                        </div>
                        {disabled && !isMobile ? (
                          <TooltipClick title="When renting out an entire place, all bathrooms must be private.">
                            {renderBathroomSelect(item, idx, disabled)}
                          </TooltipClick>
                        ) : (
                          renderBathroomSelect(item, idx, disabled)
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            </>
          )}
        </DisabledSectionOnEdit>
      </div>
      <NativeListingBackAndContinue
        onSubmit={() => handleSubmit()}
        isEdit={!isDraft}
        isDisabledSubmit={isEditBulk ? !(toggleEdit.guests || toggleEdit.rooms) : false}
      />
      <div
        id={`${NATIVE_LISTING_MENU.GUEST_ROOMS}_save_exit`}
        className="hidden"
        onClick={() => handleSubmit(true)}
        role="presentation"
      />
      <div
        id={`${NATIVE_LISTING_MENU.GUEST_ROOMS}_save`}
        className="hidden"
        onClick={() => {
          if (isEditBulk) {
            if (isEmpty(bulkListingSection) && (toggleEdit.guests || toggleEdit.rooms)) {
              let result: string[] = []
              if (toggleEdit.guests) result.push('How many guests can stay in these properties')
              if (toggleEdit.rooms) result.push('How many rooms are available to guests')
              dispatch(setBulkListingSection(result.join(', ')))
            } else handleSubmit()
          } else handleSubmit()
        }}
        role="presentation"
      />
    </>
  )
}

export default NativeListingGuestRooms
