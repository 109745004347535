import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setIsSurveySubmitted } from '../../redux/slices/common'
import { getUserInfo } from '../../utils/user'
import ConfirmLogout from '../common/ConfirmLogout'
import Loading from '../common/Loading'
import GetStartedQuickAccess from '../home/GetStartedQuickAccess'
import AirbnbImportProgressBackground from '../property-import-airbnb/AirbnbImportProgressBackground'
import ConnectWalletThirdWeb from '../thirdweb/ConnectWalletThirdWeb'
import UserInfoNew from '../user/UserInfoNew'
import MenuBarMobile from './MenuBarMobile'
import MenuBarV2 from './MenuBarV2'
import SideMenu from './SideMenu'
import clsx from 'clsx'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import ic_logo from 'src/assets/icons/ic_D_logo_red.svg'
import useSetupGuide from 'src/hooks/useSetupGuide'

interface Props {
  children: ReactElement | ReactElement[]
  childrenHeader?: ReactElement | ReactElement[]
  showDefaultHeader?: boolean
  isFullWidth?: boolean
  isHiddenMenuBarMobile?: boolean
  isOnboarding?: boolean
  bodyStyle?: any
  bodyClass?: string
}

const LayoutV2: React.FC<Props> = ({
  children,
  childrenHeader,
  showDefaultHeader,
  isFullWidth,
  isHiddenMenuBarMobile,
  isOnboarding,
  bodyStyle,
  bodyClass,
}) => {
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector((state) => state.user)
  const location = useLocation()
  const { stepList, stepProgress, onClickStepItem } = useSetupGuide()
  const [scrollY, setScrollY] = useState(0)
  const dtravelSmartWallet = profile?.user?.hasSmartWallet ? profile?.user?.walletId : ''

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.pageYOffset || document.documentElement.scrollTop)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const user = getUserInfo()
    const isSurveySubmittedNew = !!user?.gettingStarted?.isSurveySubmitted
    if (isSurveySubmittedNew) dispatch(setIsSurveySubmitted(isSurveySubmittedNew))
  }, [])

  const numberOfStepFinished: number = Object.values(stepProgress)?.filter((v: any) => v)?.length || 0

  return (
    <div className={'bg-neutral-100 w-full min-h-screen'}>
      <div className={'relative'}>
        <div
          className={clsx(
            'fixed h-[80px] w-full top-0 right-0 bg-neutral-100',
            'px-[16px] md:px-[24px] lg:px-[48px] xl:px-[24px]',
            'flex items-center justify-between',
            {
              'shadow-3xl': scrollY > 0,
              'z-[-1]': isOnboarding,
              'z-[101]': !isOnboarding,
            }
          )}
        >
          {showDefaultHeader ? (
            <>
              <div className={'md:w-1/3 flex gap-[12px] items-center'}>
                <SideMenu />
                <button>
                  <Link to={'/'} className={'flex items-center gap-[8px] w-full h-full'}>
                    <span className={'min-w-[40px] min-h-[40px] md:min-w-[28px] md:min-h-[28px]'}>
                      <img src={ic_logo} alt={'ic_logo'} className={'w-[40px] h-[40px]'} />
                    </span>
                  </Link>
                </button>
                <span className={'font-maison-neue-demibold tracking-[4%] text-12-16 text-neutral-800 hidden md:block'}>
                  OPERATORS
                </span>
              </div>

              <div className={'md:w-auto mx-auto'}>
                <MenuBarV2 />
              </div>

              <div className={'flex items-center justify-end gap-[8px] md:w-1/3'}>
                {!location.pathname.includes('/native-listing') && stepList.length > numberOfStepFinished && (
                  <GetStartedQuickAccess
                    stepList={stepList}
                    stepProgress={stepProgress}
                    numberOfStepFinished={numberOfStepFinished}
                    onClickStepItem={onClickStepItem}
                    btnStyle={{
                      fontSize: '12px',
                      lineHeight: '16px',
                      color: '#6A6A68',
                    }}
                  />
                )}
                {dtravelSmartWallet && location?.pathname === '/' && (
                  <ConnectWalletThirdWeb btnTitle="Connect wallet" className="smart-wallet-header" />
                )}
                <UserInfoNew />
                {/*{isNewUser ? <UserInfoNew /> : <UserInfoOld />}*/}
              </div>
            </>
          ) : (
            childrenHeader
          )}
        </div>
        {isOnboarding ? (
          children
        ) : (
          <div
            className={clsx(
              'm-auto',
              {
                'px-[16px] md:px-[32px] lg:px-[48px] xl:px-0': !isFullWidth,
                'xl:max-w-[1136px]': !isFullWidth,
                '2xl:max-w-[1284px]': !isFullWidth,
                'pt-[104px] md:pt-[148px] pb-[64px]': true,
              },
              `${bodyClass || ''}`
            )}
            style={bodyStyle ? { ...bodyStyle } : {}}
          >
            {children}
          </div>
        )}
      </div>
      <Loading />
      {!isHiddenMenuBarMobile && !isOnboarding && <MenuBarMobile />}
      <ConfirmLogout />
      <AirbnbImportProgressBackground />
    </div>
  )
}

export default LayoutV2
