import { ListingView } from '../../interfaces/listing'
import { useAppDispatch } from '../../redux/hooks'
import { setIsOpenPopupAddListing } from '../../redux/slices/listing'
import BasicButton from '../ui/BasicButton'
import PropertySync from './PropertySync'
import SwitchCalendarView from './SwitchCalendarView'
import clsx from 'clsx'
import React from 'react'

interface Props {
  total: number
  view?: ListingView
  onChangeView: (_view: ListingView) => void
}

const PropertyTopSection: React.FC<Props> = ({ total, view, onChangeView }) => {
  const dispatch = useAppDispatch()
  const onOpenPopupAddListing = () => {
    dispatch(setIsOpenPopupAddListing(true))
  }

  const renderAddListing = () => {
    return (
      <BasicButton isRadius100={true} variant={'contained'} size={'md'} color={'red'} onClick={onOpenPopupAddListing}>
        <span className={'font-maison-neue-medium text-14-14 text-white'}>Add listings</span>
      </BasicButton>
    )
  }

  return (
    <div className={clsx('w-full flex items-center justify-between mb-[16px] gap-[16px]', 'px-[16px] md:px-0')}>
      <div className={'font-maison-neue text-16-24 md:text-24-36 text-neutral-800 w-auto whitespace-nowrap'}>
        {total} {total > 1 ? 'listings' : 'listing'}
      </div>
      <div className={'flex items-center gap-[8px]'}>
        <div className={'hidden md:block'}>
          <SwitchCalendarView view={view} onChangeView={onChangeView} />
        </div>
        <PropertySync />
        {renderAddListing()}
      </div>
    </div>
  )
}

export default PropertyTopSection
