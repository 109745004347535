import { handleErrorMessage } from '../../../utils/common'
import IconButton from '@mui/material/IconButton'
import React, { useState } from 'react'
import { updateHostSettings } from 'src/api/user'
import ic_edit_pencil_line from 'src/assets/icons/ic_edit_pencil_line.svg'
import ic_trash_full from 'src/assets/icons/ic_trash_full.svg'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import BasicTextarea from 'src/components/ui/BasicTextarea'
import { Faq } from 'src/interfaces/user'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { setSettingData } from 'src/redux/slices/user'

const SettingFaqs = () => {
  const dispatch = useAppDispatch()
  const { settingData } = useAppSelector((state) => state.user)
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [isOpenPopupDelete, setIsOpenPopupDelete] = useState(false)
  const [question, setQuestion] = useState<string>('')
  const [answer, setAnswer] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [indexEdit, setIndexEdit] = useState<number>(-1)

  const handleOpen = () => {
    setIsOpenPopup(true)
  }

  const handleClose = () => {
    setIsOpenPopup(false)
    // clear data
    setIndexEdit(-1)
    setAnswer('')
    setQuestion('')
  }

  const handleClosePopupDelete = () => {
    setIsOpenPopupDelete(false)
    setIndexEdit(-1)
  }

  const onChangeQuestion = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuestion(event.target.value)
  }

  const onChangeAnswer = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAnswer(event.target.value)
  }

  const onClickEdit = (_index: number) => {
    setIndexEdit(_index)
    const currenFaq = Array.isArray(settingData.faq) ? settingData.faq[_index] : null
    if (currenFaq) {
      setQuestion(currenFaq.question)
      setAnswer(currenFaq.answer)
      // setAnswer(
      //   typeof currenFaq.answer === 'string'
      //     ? currenFaq.answer
      //     : Array.isArray(currenFaq.answer) && currenFaq.answer.length > 0
      //     ? currenFaq.answer[0]
      //     : ''
      // )
    }
    handleOpen()
  }
  const onClickDelete = (_index: number) => {
    setIndexEdit(_index)
    setIsOpenPopupDelete(true)
  }

  const handleSave = async (isDelete?: boolean) => {
    try {
      setIsLoading(true)
      let newFaq: any = Array.isArray(settingData.faq) ? [...settingData.faq] : []
      if (indexEdit > -1) {
        if (isDelete) {
          newFaq = newFaq.filter((_: any, index: number) => index !== indexEdit)
        } else {
          newFaq[indexEdit] = { question, answer }
        }
      } else {
        newFaq = [...newFaq, { question, answer }]
      }
      const res = await updateHostSettings({ faq: [...newFaq] })
      if (res.success && res.data) {
        dispatch(setToastSuccess({ message: 'Update success!' }))
        dispatch(setSettingData({ ...settingData, ...res.data }))
        handleClose()
        handleClosePopupDelete()
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const renderAddButton = () => (
    <BasicButton size={'md'} variant={'outlined'} clases={'w-full md:w-auto'} isRadius100 onClick={handleOpen}>
      Add
    </BasicButton>
  )

  return (
    <>
      <div className={'flex flex-col md:flex-row justify-between items-start gap-[12px]'}>
        <div className={'w-full'}>
          <p className={'font-maison-neue-medium text-neutral-800 text-16-20'}>FAQs</p>
          <p className={'font-maison-neue text-neutral-600 text-14-18 mt-[4px]'}>
            Create frequently asked questions from your guests
          </p>
        </div>

        <div className={'hidden md:block'}>{renderAddButton()}</div>

        {/*  Popup */}
        <BasicDialog
          isOpen={isOpenPopup}
          title={'FAQs'}
          extraTitle={'Create frequently asked questions from your guests'}
          onClose={handleClose}
          allowBackdropClick
          titleAlign={'center'}
        >
          <div>
            <BasicTextarea
              rows={2}
              label={'Question'}
              maxLength={150}
              showCount
              value={question}
              onChange={onChangeQuestion}
              onBlur={() => setQuestion((prevState) => prevState.trim())}
            />
          </div>
          <div className={'mt-[24px]'}>
            <BasicTextarea
              rows={7}
              label={'Answer'}
              maxLength={1500}
              showCount
              value={answer}
              onChange={onChangeAnswer}
              onBlur={() => setAnswer((prevState) => prevState.trim())}
            />
          </div>
          <div className={'mt-[24px]'}>
            <BasicButton
              size={'xl'}
              color={'red'}
              variant={'contained'}
              clases={'w-full'}
              isRadius100={true}
              disabled={!question || !answer}
              loading={isLoading}
              onClick={() => handleSave()}
            >
              Add
            </BasicButton>
          </div>
        </BasicDialog>
      </div>

      {settingData && Array.isArray(settingData.faq) && (
        <div className={'flex flex-col gap-[12px] mt-[12px]'}>
          {settingData.faq.map((item: Faq, index: number) => {
            return (
              <div
                key={index}
                className={
                  'flex items-center justify-between bg-neutral-50 p-[24px] gap-[16px] md:gap-[24px] rounded-[16px]'
                }
              >
                <div className={'font-maison-neue-medium text-16-20 text-neutral-800 break-all'}>{item.question}</div>
                <div className={'flex items-center gap-[12px] min-w-[64px] md:min-w-[96px] justify-end'}>
                  <IconButton size={'small'} onClick={() => onClickEdit(index)}>
                    <img src={ic_edit_pencil_line} alt={'ic_edit_pencil_line'} />
                  </IconButton>
                  <IconButton size={'small'} onClick={() => onClickDelete(index)}>
                    <img src={ic_trash_full} alt={'ic_trash_full'} />
                  </IconButton>
                </div>
              </div>
            )
          })}
        </div>
      )}

      <div className={'block md:hidden w-full mt-[12px]'}>{renderAddButton()}</div>

      <BasicDialog
        isOpen={isOpenPopupDelete}
        title={'Delete this FAQ?'}
        extraTitle={'Are you sure you want to delete this FAQ?'}
        onClose={handleClosePopupDelete}
        allowBackdropClick
        titleAlign={'center'}
      >
        <div className={'flex flex-col gap-[8px]'}>
          <BasicButton variant={'contained'} color={'red'} isRadius100={true} onClick={() => handleSave(true)}>
            Confirm
          </BasicButton>
          <BasicButton variant={'outlined'} isRadius100={true} onClick={handleClosePopupDelete}>
            Cancel
          </BasicButton>
        </div>
      </BasicDialog>
    </>
  )
}

export default SettingFaqs
