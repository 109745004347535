import { useAppSelector } from '../../redux/hooks'
import { handleErrorMessage, isEmpty } from '../../utils/common'
import { renderCancelPolicyToText } from '../property/PropertyAirbnbCancelPolicy'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { updateBulkProperties, updateProperty } from 'src/api/native-listing'
import NativeListingBackAndContinue from 'src/components/native-listing/NativeListingBackAndContinue'
import BasicSelect from 'src/components/ui/BasicSelect'
import BasicTextarea from 'src/components/ui/BasicTextarea'
import { NATIVE_LISTING_MENU, DEFAULT_CHECKIN_TIME, DEFAULT_CHECKOUT_TIME } from 'src/contants/common'
import { PROPERTY_STATUS } from 'src/contants/native'
import { PropertyDetailProps } from 'src/interfaces/listing'
import { setLoading, setToastSuccess } from 'src/redux/slices/common'
import clsx from 'clsx'
import ic_minus from 'src/assets/icons/ic_minus.png'
import ic_plus from 'src/assets/icons/ic_plus.png'
import { isPropertyAirbnb } from 'src/utils/property'
import DisabledSectionOnEdit from '../common/DisabledSectionOnEdit'
import { setBulkListingData, setBulkListingSection } from 'src/redux/slices/listing'
interface Props {
  propertyId: string
  listingData: PropertyDetailProps | null
  fetchStatusStep: any
}

const NativeListingHouseRules: React.FC<Props> = ({ propertyId, listingData, fetchStatusStep }) => {
  const isDraft = listingData?.status === PROPERTY_STATUS.DRAFT
  const isAirbnb = isPropertyAirbnb(listingData)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isOnboarding = parseQuery?.f === 'onboarding'
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const { isLoading } = useAppSelector((state) => state.common)
  const { bulkListingData, bulkListingSection } = useAppSelector((state) => state.listing)
  const [timeOptions, setTimeOptions] = useState<{ value: string; label: string }[]>([])
  const [data, setData] = useState<any>({
    checkInTime: '',
    checkOutTime: '',
    houseRules: '',
    cancelPolicyDescription: '',
  })
  const [roomsGuest, setRoomsGuest] = useState<any>({ maxPetsAllowed: 0 })
  const [toggleEdit, setToggleEdit] = useState<any>({ checkIn: false, pets: false, houseRules: false, cancelationPolicy: false })

  useEffect(() => {
    let _options: { value: string; label: string }[] = [{ value: '', label: '' }]
    for (let i = 0; i <= 23; i++) {
      const label = (i > 9 ? i.toString() : '0' + i.toString()) + ':00'
      _options.push({ value: i.toString(), label })
    }
    setTimeOptions(_options)
  }, [])

  useEffect(() => {
    if (listingData) {
      const {
        defaultCheckInTime,
        defaultCheckOutTime,
        houseRules,
        airbnbCancellationPolicies,
        maxPet
      } = listingData

      let checkInTime = isAirbnb ? defaultCheckInTime : defaultCheckInTime || DEFAULT_CHECKIN_TIME
      if (checkInTime === '00') {
        checkInTime = '0'
      }
      let checkOutTime = isAirbnb ? defaultCheckOutTime : defaultCheckOutTime || DEFAULT_CHECKOUT_TIME
      if (checkOutTime === '00') {
        checkOutTime = '0'
      }
      let cancelPolicyDescription = listingData.cancelPolicyDescription
      if (isAirbnb && !isEmpty(airbnbCancellationPolicies) && !cancelPolicyDescription) {
        cancelPolicyDescription = renderCancelPolicyToText(airbnbCancellationPolicies)
      }
      setData({
        checkInTime: checkInTime,
        checkOutTime: checkOutTime,
        houseRules,
        cancelPolicyDescription,
      })
      setRoomsGuest({
        maxPetsAllowed: maxPet || 0
      })
    }
  }, [listingData, isAirbnb])
  useEffect(() => {
    if (isEditBulk) {
      setData({
        checkInTime: bulkListingData?.checkInTime || '',
        checkOutTime: bulkListingData?.checkOutTime || '',
        houseRules: bulkListingData?.houseRules || '',
        cancelPolicyDescription: bulkListingData?.cancelPolicyDescription || '',
      })
      setRoomsGuest({
        maxPetsAllowed: bulkListingData?.maxPetsAllowed || -1
      })
    }
  }, [bulkListingData])

  const handleChangeData = (field: string, value: string) => {
    setData((prevState: any) => {
      return { ...prevState, [field]: value }
    })
  }

  const handleSubmit = async (isExit?: boolean) => {
    try {
      dispatch(setLoading(true))
      let res: any = null
      if (isEditBulk) {
        if (toggleEdit.checkIn || toggleEdit.pets || toggleEdit.houseRules || toggleEdit.cancelationPolicy) {
          const propertyIds = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
          let dataDTO: any = { propertyIds }
          if (data.checkInTime && toggleEdit.checkIn) dataDTO = { ...dataDTO, checkInTime: data.checkInTime }
          if (data.checkOutTime && toggleEdit.checkIn) dataDTO = { ...dataDTO, checkOutTime: data.checkOutTime }
          if (toggleEdit.houseRules) dataDTO = { ...dataDTO, houseRules: data?.houseRules }
          if (toggleEdit.pets) dataDTO = { ...dataDTO, maxPetsAllowed: roomsGuest?.maxPetsAllowed }
          if (toggleEdit.cancelationPolicy) dataDTO = { ...dataDTO, cancelPolicyDescription: data?.cancelPolicyDescription }
          dispatch(setBulkListingData({ ...bulkListingData, ...dataDTO }))
          res = await updateBulkProperties(dataDTO)
          setToggleEdit({ checkIn: false, pets: false, houseRules: false, cancelationPolicy: false })
        }
      } else {
        const bodyData: any = { ...data }
        if (isDraft) { bodyData.settingSteps = 7 }
        res = await updateProperty(propertyId, bodyData)
      }
      if (res?.data) {
        if (isExit) navigate(isOnboarding ? '/' : `/listings`)
        else if (isDraft) navigate(`/native-listing/${NATIVE_LISTING_MENU.AVAILABILITY}` + search)
        else dispatch(setToastSuccess({ message: 'Your changes have been applied.' }))
        fetchStatusStep()
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      dispatch(setLoading(false))
    }
  }
  const renderActionItem = (type: 'maxPetsAllowed', maxValue: number) => {
    const currentNumberShow = roomsGuest[type] === -1 ? '' : roomsGuest[type]
    const currentNumber = roomsGuest[type] ? (roomsGuest[type] === -1 ? 0 : roomsGuest[type]) : 0
    const disabledMinus = currentNumber === 0
    const disabledPlus = currentNumber === maxValue
    return (
      <div className="flex items-center justify-center gap-2">
        <button
          className={clsx(
            'rounded-full flex items-center justify-center bg-white w-[36px] h-[36px]',
            disabledMinus ? 'opacity-50 cursor-not-allowed' : 'opacity-[1] cursor-pointer'
          )}
          disabled={disabledMinus}
          onClick={() => {
            if (currentNumber > 0) {
              let newRoomsGuest = { ...roomsGuest, [type]: currentNumber - 1 }
              setRoomsGuest(newRoomsGuest)
            }
          }}
        >
          <img alt={'icon'} src={ic_minus} />
        </button>
        <div className="w-[64px] min-w-[64px] flex items-center justify-center text-24-36 text-neutral-800 font-maison-neue">
          {currentNumberShow}
        </div>
        <button
          className={clsx(
            'rounded-full flex items-center justify-center bg-white w-[36px] h-[36px]',
            disabledPlus ? 'opacity-50 cursor-not-allowed' : 'opacity-[1] cursor-pointer'
          )}
          disabled={disabledPlus}
          onClick={() => {
            let newRoomsGuest = { ...roomsGuest, [type]: currentNumber + 1 > maxValue ? maxValue : currentNumber + 1 }
            setRoomsGuest(newRoomsGuest)
          }}
        >
          <img alt={'icon'} src={ic_plus} />
        </button>
      </div>
    )
  }
  return (
    <>
      <div className="flex flex-col gap-8">
        <p className="text-28-36 text-neutral-800 font-maison-neue tracking-[-0.01em]">
          Set your house rules
        </p>
        <DisabledSectionOnEdit
          title="Check-in/out"
          openEdit={toggleEdit.checkIn}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, checkIn: value })}
        >
          <div className="flex gap-[16px] p-6 bg-white w-full shadow-sm rounded-[16px]">
            <div className="w-1/2">
              <BasicSelect
                options={[...timeOptions, { value: '25', label: '01:00 (next day)' }]}
                label={'Check-in'}
                value={data.checkInTime}
                onChange={(event) => handleChangeData('checkInTime', event.target.value)}
              />
            </div>
            <div className="w-1/2">
              <BasicSelect
                options={timeOptions}
                label={'Check-out'}
                value={data.checkOutTime}
                onChange={(event) => handleChangeData('checkOutTime', event.target.value)}
              />
            </div>
          </div>
        </DisabledSectionOnEdit>
        <DisabledSectionOnEdit
          title="Pets"
          openEdit={toggleEdit.pets}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, pets: value })}
        >
          <div className="flex gap-[16px] p-6 bg-white w-full shadow-sm rounded-[16px]">
            <div className={clsx('w-full flex items-center justify-between')}>
              <div className="flex flex-col">
                <p className={'font-maison-neue text-16-20 text-neutral-800'}>Maximum number of pets allowed</p>
              </div>
              {renderActionItem('maxPetsAllowed', 20)}
            </div>
          </div>
        </DisabledSectionOnEdit>
        {/* --- House rules--- */}
        <DisabledSectionOnEdit
          title="House rules"
          openEdit={toggleEdit.houseRules}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, houseRules: value })}
        >
          <BasicTextarea
            placeholder="Add any house rules..."
            rows={5}
            value={data.houseRules}
            onChange={(event) => handleChangeData('houseRules', event.target.value)}
          />
          <p className="text-14-18 text-neutral-500 font-maison-neue mt-2">
            Operators typically mention noise rules, trash restrictions, etc...
          </p>
        </DisabledSectionOnEdit>

        {/* --- Cancelation policy--- */}
        <DisabledSectionOnEdit
          title="Cancelation policy"
          openEdit={toggleEdit.cancelationPolicy}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, cancelationPolicy: value })}
        >
          <BasicTextarea
            placeholder="Add any cancelation policy..."
            rows={5}
            value={data.cancelPolicyDescription}
            onChange={(event) => handleChangeData('cancelPolicyDescription', event.target.value)}
          />
        </DisabledSectionOnEdit>
      </div>

      <NativeListingBackAndContinue
        isLoading={isLoading}
        onSubmit={handleSubmit}
        isEdit={!isDraft}
        isDisabledSubmit={isEditBulk ? !(toggleEdit.checkIn || toggleEdit.pets || toggleEdit.houseRules || toggleEdit.cancelationPolicy) : false}
      />
      <div
        id={`${NATIVE_LISTING_MENU.HOUSE_RULES}_save_exit`}
        className="hidden"
        onClick={() => handleSubmit(true)}
        role="presentation"
      />
      <div
        id={`${NATIVE_LISTING_MENU.HOUSE_RULES}_save`}
        className="hidden"
        onClick={() => {
          if (isEditBulk) {
            if (isEmpty(bulkListingSection) && (toggleEdit.checkIn || toggleEdit.pets || toggleEdit.houseRules || toggleEdit.cancelationPolicy)) {
              let result: string[] = []
              if (toggleEdit.checkIn) result.push('Check-in/out')
              if (toggleEdit.pets) result.push('Pets')
              if (toggleEdit.houseRules) result.push('House rules')
              if (toggleEdit.cancelationPolicy) result.push('Cancelation policy')
              dispatch(setBulkListingSection(result.join(', ')))
            } else handleSubmit()
          } else handleSubmit()
        }}
        role="presentation"
      />
    </>
  )
}

export default NativeListingHouseRules
