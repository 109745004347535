import useWindowDimensions from '../../hooks/useWindowDimensions'
import { fetchIntegratedConfig } from '../../utils/property'
import { IconChecked } from '../common/Icons'
// import { IcInfo, IconChecked } from '../common/Icons'
import ConnectViaEmail from './ConnectViaEmail'
import ConnectViaWallet from './ConnectViaWallet'
import SelectAccount from './SelectAccount'
import clsx from 'clsx'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getBusinessAccounts } from 'src/api/user'
import avatar_amir from 'src/assets/images/avatar_amir.jpeg'
import avatar_jeremy from 'src/assets/images/avatar_jeremy.png'
import { isEmpty } from 'src/utils/common'
import 'swiper/css'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import SideMenu from '../layout/SideMenu'
import ic_logo from 'src/assets/icons/ic_D_logo_red.svg'

const MESSAGES: Array<string> = [
  'Build your brand with a customizable, mobile-first direct booking site.',
  'Access direct booking distribution channels like Google Vacation Rentals.',
  'No upfront or monthly fees. Only pay 3% on completed reservations.',
  '$5M Damage Protection Plan and free Guest Screening.',
  'Offer diverse payment options (e.g. Google Pay, Apple Pay, Book-Now-Pay-Later, Cryptocurrencies).',
  'Boost marketing with Google Analytics and Meta Ads.',
  'Earn web3 rewards with each booking.',
]

const SAYING = [
  {
    message:
      '“Having the ability to earn more and pass on savings to my guests through Dtravel, without having to spend time and resources setting up my own booking site, immediately resonated with me.”',
    name: 'Amir',
    desc: 'directstaylondon.com',
    avatar: avatar_amir,
  },
  {
    message:
      "I'd like to see at least half of my bookings come through direct in the next 12 months. My last booking site was junk, guests just didn’t trust it so I switched to Dtravel and I can tell you they are going to be the standard for direct bookings. The fact that I can have a professional booking site at such a low cost and not have to worry about anything is incredibly valuable!",
    name: 'Jeremy Howell',
    desc: 'hfproperties.net',
    avatar: avatar_jeremy,
  },
]

const SignUp = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [swiperInstance, setSwiperInstance] = useState<SwiperProps>()
  const windowDimensions = useWindowDimensions()
  const screenHeight = windowDimensions.height
  const params: any = queryString.parse(search)
  const isMultipleAccounts = params?.account === 'multiple'
  const isViewAsAdmin = !isEmpty(params?.view_as)

  useEffect(() => {
    document.body.style.backgroundColor = '#F0F0EE' // bg-neutral-200
    return () => {
      document.body.style.backgroundColor = '#FFFFFF'
    }
  }, [])

  const handleLoginSuccess = async () => {
    await fetchIntegratedConfig() // set PMS config before login
    if (isViewAsAdmin) {
      navigate(`/`, { replace: true })
    } else {
      const res: any = await getBusinessAccounts()
      if (res?.data?.length <= 1) {
        navigate(`/`, { replace: true })
      } else {
        navigate(`/login?account=multiple`, { replace: true })
        // setMultipleAccounts(res?.data)
      }
    }
  }
  // const renderBannerChangeURL = () => {
  //   return (
  //     <div className='bg-sun-200 px-6 py-4 rounded-[8px] flex items-center gap-6 mb-4'>
  //       <div className='w-6 min-w-[24px]'>
  //         <IcInfo />
  //       </div>
  //       <div className='flex flex-col'>
  //         <p className='text-neutral-800 text-16-20 md:text-20-24 font-maison-neue mb-1'>Important Update: URL Change</p>
  //         <p className='text-neutral-800 text-14-20 font-maison-neue'>
  //           Our URL has changed to unify our apps experiences.&nbsp;<br className='hidden md:block' />
  //           <b className='font-maison-neue-demibold'>host.dtravel.com</b> now redirects to <b className='font-maison-neue-demibold'>app.dtravel.com/operators</b>.
  //         </p>
  //       </div>
  //     </div>
  //   )
  // }
  const renderLoginContent = () => {
    return (
      <>
        {/*-----LEFT SIDE-----*/}
        <div className="w-full xl:w-1/2 h-full flex justify-center items-start">
          <div
            className={clsx('w-full px-[16px] md:px-0 md:max-w-[524px]', 'xl:pt-[calc((100vh-80px-588px-60px)/2)]', {
              'pt-[56px]': screenHeight > 670,
              'pt-[24px]': screenHeight <= 670,
            })}
          >
            {/* {renderBannerChangeURL()} */}
            <p className="font-maison-neue text-24-36 text-neutral-800 mb-[16px]">Connect to Dtravel Operators</p>
            <p className="font-maison-neue text-14-18 text-neutral-800 mb-[16px] xl:hidden">
              Sign up or log in to grow your vacation rental business.
            </p>
            <div className={'bg-white p-[32px] rounded-[16px] w-full'}>
              <ConnectViaEmail handleLoginSuccess={handleLoginSuccess} />
              <div className={'flex w-full my-[32px] items-center'}>
                <span className={'h-[1px] bg-neutral-300 w-1/2'}></span>
                <span className={'px-[16px] font-maison-neue text-14-18 text-neutral-500'}>or</span>
                <span className={'h-[1px] bg-neutral-300 w-1/2'}></span>
              </div>
              <ConnectViaWallet />
            </div>

            <p className="font-maison-neue text-14-18 text-neutral-800 mt-[16px]">
              By connecting to Dtravel, you agree to the&nbsp;
              <span
                className="underline cursor-pointer"
                onClick={() => {
                  window.open('https://www.dtravel.com/terms-of-service', '_blank')
                }}
                role="presentation"
              >
                Terms of Service
              </span>
              &nbsp;and&nbsp;
              <span
                className="underline cursor-pointer"
                onClick={() => {
                  window.open('https://www.dtravel.com/privacy-policy', '_blank')
                }}
                role="presentation"
              >
                Privacy Statement
              </span>
              .
            </p>
          </div>
        </div>

        {/*-----RIGHT SIDE-----*/}
        <div className="xl:w-1/2 xl:bg-white hidden md:flex justify-center items-start">
          <div className="w-full max-w-[530px] pb-[80px] xl:pt-[calc(((100vh-80px-588px-60px)/2)+52px)]">
            <div className={'hidden xl:block'}>
              <p className="font-maison-neue text-24-36 text-neutral-800 mb-[8px]">
                Grow your vacation rental business
              </p>
              {MESSAGES.map((el: string, idx: number) => {
                return (
                  <div
                    className={`flex gap-[16px] w-full center items-center ${idx === MESSAGES.length - 1 ? '' : 'mb-[16px]'
                      }`}
                    key={idx}
                  >
                    <span className={'min-w-[24px]'}>
                      <IconChecked />
                    </span>
                    <span className="text-14-20 font-maison-neue text-neutral-800">{el}</span>
                  </div>
                )
              })}
            </div>

            <div className={'mt-[72px] xl:mt-[32px]'}>
              <p className="font-maison-neue text-20-32 text-neutral-800 mb-[8px] ">What our customers are saying</p>
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                onSlideChange={(swiper: any) => {
                  setActiveIndex(swiper ? swiper.activeIndex : 0)
                }}
                autoplay={true}
                // loop={true}
                onInit={(swiper: SwiperProps) => setSwiperInstance(swiper)}
              >
                {SAYING.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className={'flex gap-[32px]'}>
                        <div>
                          <p className={'font-maison-neue text-14-20 text-neutral-800'}>{item.message}</p>
                          <p className={'mt-[16px] font-maison-neue-medium text-14-20 text-neutral-800'}>{item.name}</p>
                          <p className={'font-maison-neue text-14-20 text-neutral-500'}>{item.desc}</p>
                        </div>
                        <div className={'min-w-[64px]'}>
                          <img
                            src={item.avatar}
                            alt="avatar"
                            style={{ width: 64, height: 64 }}
                            className={'rounded-full'}
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>

              <div className={'flex gap-[8px] justify-center mt-[8px]'}>
                {[0, 1].map((index) => (
                  <button
                    key={index}
                    className={`${activeIndex === index ? 'bg-red-700' : 'bg-neutral-300'
                      } w-[8px] h-[8px] rounded-full`}
                    onClick={() => {
                      if (swiperInstance && activeIndex !== index) {
                        swiperInstance.slideTo(index)
                      }
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className={'min-h-screen overflow-hidden'} style={{ minHeight: '-webkit-fill-available' }}>
      <div className="h-[80px] flex items-center">
        <div
          className={clsx(
            isMultipleAccounts ? 'w-full' : 'w-full xl:w-1/2',
            'bg-neutral-200 px-[16px] md:px-[24px] py-[16px] flex gap-[12px] items-center'
          )}
        >
          <SideMenu />
          <span className={'min-w-[40px] min-h-[40px] md:min-w-[28px] md:min-h-[28px]'}>
            <img src={ic_logo} alt={'ic_logo'} className={'w-[40px] h-[40px]'} />
          </span>
          <span className={'font-maison-neue-demibold tracking-[4%] text-12-16 text-neutral-800 hidden md:block'}>
            OPERATORS
          </span>
        </div>
        {!isMultipleAccounts && <div className={'hidden xl:block w-1/2 bg-white h-full'} />}
      </div>
      <div
        className="flex flex-col xl:flex-row w-full bg-neutral-200"
        style={{ minHeight: `calc(${screenHeight}px - 80px + 1px)` }}
      >
        {isMultipleAccounts ? <SelectAccount /> : renderLoginContent()}
      </div>
    </div>
  )
}

export default SignUp
