// import ic_external_link from '../../assets/icons/ic_external_link.svg'
import BasicCard from '../common/BasicCard'
import React from 'react'
import { isPolygon } from 'src/utils/common'

interface Props {
  data: any
}

const ReservationTransactionHash: React.FC<Props> = ({ data }) => {
  const transactions = data?.transactions
  const reservationChainId = data?.chainId
  const renderTransactionLink = () => {
    if (transactions && transactions.successTransaction) {
      const url = `${isPolygon(reservationChainId) ? process.env.REACT_APP_POLYGON_SCAN_URL : process.env.REACT_APP_BASE_SCAN_URL}/tx/${transactions.successTransaction.metadata.transactionHash}`
      return url
    }
    return ''
  }
  const urlTransaction = renderTransactionLink()

  return (
    <>
      <div className="flex justify-between items-center mb-[16px] mt-[40px]">
        <p className=" font-maison-neue text-20-24 text-neutral-900">{'Smart contract'}</p>
        {urlTransaction !== '' && (
          <a
            href={urlTransaction}
            target="_blank"
            rel="noreferrer"
            className="text-grayscale-600 font-maison-neue-medium text-14-14 pr-[8px]"
          >
            Go to contracts
          </a>
        )}
      </div>
      <BasicCard>
        <p className={'text-16-24 text-neutral-600  '}>
          This reservation was paid for with cryptocurrency. When a guest pays with cryptocurrency, the reservation is
          powered by a smart contract. Smart contracts automate bookings with specific instructions, such as the payment
          type, payment amount and cancellation window. With smart contracts, payment instructions are clear and
          chargebacks are avoided.
        </p>
      </BasicCard>
    </>
  )
}

export default ReservationTransactionHash
