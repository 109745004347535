import clsx from 'clsx'
import React from 'react'
import { PROPERTY_STATUS } from 'src/contants/native'

interface Props {
  status: string
  isActive: boolean
}

const PropertyStatus: React.FC<Props> = ({ status, isActive }) => {
  const renderPublishedStatus = () => {
    const isListed = isActive && status !== PROPERTY_STATUS.DRAFT && status !== PROPERTY_STATUS.PENDING_REVIEW
    const isDraft = status === PROPERTY_STATUS.DRAFT
    const isPendingReview = status === PROPERTY_STATUS.PENDING_REVIEW
    const isUnListed = !isActive && !isDraft && !isPendingReview
    return (
      <span
        className={clsx(
          'px-[10px] pt-[2px] pb-[1px] rounded-[16px] whitespace-nowrap',
          isListed && 'bg-forest-50 text-forest-700',
          (isDraft || isPendingReview) && 'bg-sun-50 text-sun-700',
          isUnListed && 'bg-red-50 text-red-700'
        )}
      >
        {isListed && 'LISTED'}
        {isDraft && 'INCOMPLETE'}
        {isPendingReview && 'PENDING REVIEW'}
        {isUnListed && 'UNLISTED'}
      </span>
    )
  }
  return <>{renderPublishedStatus()}</>
}

export default PropertyStatus
