import CircularProgress from '@mui/material/CircularProgress'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { getUserProfile, sendVerifyContactEmail, updateUserProfile } from 'src/api/user'
import BasicButton from 'src/components/ui/BasicButton'
import BasicInput from 'src/components/ui/BasicInput'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setLoading, setToastSuccess } from 'src/redux/slices/common'
import { getTimeZoneName, handleErrorMessage, isEmailValid, isEmpty } from 'src/utils/common'
import { getUserInfo, saveUserInfo } from 'src/utils/user'

interface Props {
  onClose?: () => void
}

const SettingContactEmail: React.FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch()
  const { profile: data } = useAppSelector((state) => state.user)
  const { isLoading } = useAppSelector((state) => state.common)
  const isEditEmail = data?.email

  const [errorMsg, setErrorMsg] = useState({ email: '' })
  const [isDisabled, setIsDisabled] = useState(false)
  const [open, setOpen] = useState<'default' | 'add' | 'edit' | 'confirm' | null>(isEditEmail ? 'edit' : 'add')
  const [email, setEmail] = useState<string>('')

  const fetchUserProfile = async () => {
    try {
      const res = await getUserProfile()
      const { data } = res
      const user = getUserInfo()
      saveUserInfo({ ...user, ...data })
    } catch (err) {
      console.log(err)
    }
  }

  const initValue = () => {
    setEmail(data?.email || '')
  }

  useEffect(() => {
    initValue()
  }, [data])

  useEffect(() => {
    const hasChange = !data || (!isEmpty(email) && data?.email !== email)
    setIsDisabled(!hasChange || ((open === 'add' || open === 'edit') && (!email || !isEmailValid(email))))
  }, [email, data, open])

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.trim())
    setErrorMsg((prevState) => ({ ...prevState, email: '' }))
  }

  const onBlurEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isEmailValid(event.target.value)) {
      setErrorMsg((prevState) => ({
        ...prevState,
        email: 'Please enter a valid email.',
      }))
    }
  }

  const onSave = async (newEmail?: string) => {
    try {
      dispatch(setLoading(true))
      await updateUserProfile({ email: newEmail || email })
      setOpen(null)
      dispatch(setToastSuccess({ message: `Contact email ${isEditEmail ? 'edited' : 'added'}.` }))
      fetchUserProfile()
      if (typeof onClose === 'function') {
        onClose()
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const handleSendEmail = async () => {
    if (email === data?.email) {
      onSave()
    } else {
      try {
        setLoading(true)
        const res: any = await sendVerifyContactEmail({
          email,
          type: 'host',
          timezone: getTimeZoneName(),
        })
        if (res.data) {
          setOpen('confirm')
          setErrorMsg({ email: '' })
        }
      } catch (err) {
        handleErrorMessage(err)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <>
      {open === 'default' && (
        <>
          {data?.email && (
            <button
              className={clsx('bg-black px-3 rounded-xl flex justify-center items-center cursor-pointer')}
              disabled={isLoading}
              onClick={() => onSave(data?.email)}
            >
              <p className="text-16-20 font-maison-neue-medium text-white my-[14px]">
                Use {data?.email} as contact email
              </p>
              <span>&nbsp;{isLoading && <CircularProgress size={12} style={{ color: '#32302D' }} />}</span>
            </button>
          )}
          <button
            className={clsx(
              'bg-white px-3 rounded-xl flex justify-center items-center mt-2 cursor-pointer border border-neutral-3'
            )}
            onClick={() => {
              setEmail('')
              setOpen('add')
              setErrorMsg({ email: '' })
            }}
          >
            <p className="text-16-20 font-maison-neue-medium text-neutral-7 my-[14px]">Add another email</p>
          </button>
        </>
      )}
      {open === 'confirm' && (
        <>
          <p className={'font-maison-neue text-16-24 text-neutral-700 text-center'}>
            We emailed an authentication link to
          </p>
          <p className={'font-maison-neue-medium text-16-24 text-neutral-800 text-center'}>{email}.</p>
          <p className={'font-maison-neue text-16-24 text-neutral-700 text-center mb-'}>
            Tap the button in that email to verify.
          </p>
        </>
      )}
      {(open === 'add' || open === 'edit') && (
        <>
          <div className={'mb-[24px]'}>
            <p className={'font-maison-neue-medium text-14-18 text-neutral-800 mb-[8px]'}>Contact email address</p>
            <BasicInput
              label={''}
              placeholder={'Email address'}
              value={email}
              onChange={onChangeEmail}
              onBlur={onBlurEmail}
              maxLength={50}
            />
            {errorMsg.email && <span className={'text-red mt-2 ml-2 text-12-12'}>{errorMsg.email}</span>}
          </div>

          <BasicButton
            variant={'contained'}
            isRadius100={true}
            color={'red'}
            clases={'w-full'}
            disabled={isDisabled}
            onClick={handleSendEmail}
          >
            Verify
          </BasicButton>
        </>
      )}
    </>
  )
}

export default SettingContactEmail
