import './App.scss'
import { getStripeStatus } from './api/integration'
import { getListings } from './api/listings'
import { getHostSettings } from './api/user'
import './assets/styles/app.scss'
import './assets/styles/react_dates_overrides.scss'
import BasicToast from './components/common/BasicToast'
import ConfirmAccount from './components/common/ConfirmAccount'
import { ConnectWalletProvider } from './components/connect-wallet/ConnectWalletProvider'
import PrivacyAndCookiePolicy from './components/privacy-and-cookie-policy/PrivacyAndCookiePolicy'
import { PROPERTY_STATUS } from './contants/native'
import useWindowDimensions from './hooks/useWindowDimensions'
import { ServerResponse } from './interfaces'
import { useAppDispatch } from './redux/hooks'
import {
  setIsConnectedStripe,
  setOnBoardingFinishedLoadApi,
  setOnBoardingTotalListing,
  setProfile,
  setSettingData,
  setNumberOfGenesysAccount,
} from './redux/slices/user'
import routes from './router'
import { fetchIntegratedConfig } from './utils/property'
import { getUserInfo, isLoggedIn } from './utils/user'
import 'mapbox-gl/dist/mapbox-gl.css'
import React, { Suspense, useEffect } from 'react'
import 'react-dates/initialize'
import 'react-phone-number-input/style.css'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import ConfirmPage from 'src/components/sign-up/ConfirmPage'
import { isMobileDevice } from 'src/utils/common'

const App: React.FC = () => {
  const { search, pathname } = useLocation()
  const dispatch = useAppDispatch()
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 1024

  useEffect(() => {
    if (isLoggedIn()) {
      fetchIntegratedConfig()
    }
  }, [])

  useEffect(() => {
    const userProfile = getUserInfo()
    dispatch(setProfile(userProfile))
  }, [])

  // scroll to top when change pathname
  useEffect(() => {
    if (pathname !== '/settings') window.scroll(0, 0)
    // handleGTMEvent()
  }, [pathname])

  useEffect(() => {
    if (search && isMobileDevice() && search.includes('scope=')) {
      // https://metamask.app.link/dapp/dtravel-direct.dataismist.com/
      const url = `https://metamask.app.link/dapp/${window.location.href}`
      window.open(url)
    }
  }, [search])

  useEffect(() => {
    const hubSpotIframe = document.getElementById('hubspot-messages-iframe-container')
    if (hubSpotIframe) {
      hubSpotIframe.style.width = isMobile ? '0px' : '100px'
      hubSpotIframe.style.height = isMobile ? '0px' : '96px'
    }
  }, [isMobile])

  useEffect(() => {
    // FOR HOST ONBOARDING, SETUP GUIDE
    async function fetchStripeStatus() {
      try {
        const res: any = await getStripeStatus()
        if (res.success && res.data) {
          const { stripeAccounts, status } = res.data
          dispatch(setIsConnectedStripe(status))
          dispatch(
            setNumberOfGenesysAccount(
              Array.isArray(stripeAccounts)
                ? stripeAccounts.filter((item) => item.platformAccount === 'genesys').length
                : 0
            )
          )
        }
      } catch (error) {
        console.log(error)
      }
    }
    async function fetchHostSettings() {
      try {
        const res = await getHostSettings()
        if (res.success) {
          dispatch(setSettingData(res.data || {}))
        }
      } catch (error) {
        console.log(error)
      }
    }
    async function fetchListing() {
      try {
        const res: ServerResponse = await getListings({ status: PROPERTY_STATUS.LISTED, page: 1, pageSize: 1 })
        dispatch(setOnBoardingTotalListing(res?.paging?.total || 0))
      } catch (error) {
        console.log(error)
      }
    }
    async function fetchAll() {
      try {
        dispatch(setOnBoardingFinishedLoadApi(false))
        await Promise.all([fetchListing(), fetchStripeStatus(), fetchHostSettings()])
      } finally {
        dispatch(setOnBoardingFinishedLoadApi(true))
      }
    }
    fetchAll()
  }, [])

  if (pathname === '/confirm') return <ConfirmPage />
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ConnectWalletProvider>
        <Routes>
          {routes.map(({ component: Component, layout: Layout, path, name, isFullPage }) => {
            if (!isLoggedIn() && path === '/login') {
              return (
                <Route
                  key={name}
                  path={path}
                  element={
                    <Layout>
                      <Component />
                    </Layout>
                  }
                />
              )
            }
            if (!isLoggedIn() && path !== '/login') {
              return (
                <Route key={name} path={path} element={<Navigate to={`/login${search ? search : ''}`} replace />} />
              )
            }
            // if (isLoggedIn() && path === '/login' && !search.includes('email-login=')) {
            // need select role
            //   return <Route key={name} path={path} element={<Navigate to={'/'} replace />} />
            // }

            return (
              <Route
                key={name}
                path={path}
                element={
                  <Layout isFullPage={isFullPage}>
                    <Component />
                  </Layout>
                }
              />
            )
          })}
        </Routes>
      </ConnectWalletProvider>
      <ConfirmAccount />
      <BasicToast />
      <PrivacyAndCookiePolicy />
    </Suspense>
  )
}

export default App
