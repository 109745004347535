import useWindowDimensions from '../../hooks/useWindowDimensions'
import BasicSwipeDrawer from '../ui/BasicSwipeDrawer'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import React, { useEffect, useState } from 'react'
import ic_close from 'src/assets/icons/ic_close.svg'

interface Props {
  isOpen: boolean
  anchorElement: HTMLElement | null
  transformOrigin?: any
  anchorOrigin?: any
  onClose: () => void
  value: number
  onSaveTime: (time: number) => void
}

const SelectTime: React.FC<Props> = ({
  isOpen,
  anchorElement,
  anchorOrigin,
  transformOrigin,
  onClose,
  value,
  onSaveTime,
}) => {
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768
  const [popupWidth, setPopupWidth] = useState<number>(350)
  const [time, setTime] = useState<number>(0)
  const [isAm, setIsAm] = useState<boolean>(true)

  useEffect(() => {
    if (value < 12) {
      setIsAm(true)
      setTime(value)
    }
    if (value > 12) {
      setIsAm(false)
      setTime(value - 12)
    }
    if (value === 12) {
      setIsAm(false)
      setTime(12)
    }
  }, [value, isOpen])

  useEffect(() => {
    if (anchorElement) {
      setPopupWidth(anchorElement.offsetWidth > 350 ? anchorElement.offsetWidth : 350)
    }
  }, [anchorElement])

  const handleSelectTime = (_hour: number) => {
    setTime(_hour)
  }

  const toggleAmPm = (_isAm: boolean) => {
    setIsAm(_isAm)
  }

  const handleApply = () => {
    if (isAm) {
      onSaveTime(time === 12 ? 24 : time)
    } else {
      onSaveTime(time === 12 ? 12 : time + 12)
    }

    onClose()
  }

  const handleClear = () => {
    setTime(0)
    setIsAm(true)
    onSaveTime(0)
  }

  const renderContent = () => {
    return (
      <div>
        <div className={'hidden md:flex justify-between items-center p-[24px]'}>
          <span className={'font-maison-neue-medium text-16-16 text-neutral-900'}>Select time</span>
          <IconButton onClick={onClose}>
            <img src={ic_close} alt={'ic_close'} />
          </IconButton>
        </div>

        <div
          className={
            'flex flex-col md:flex-row justify-between items-center gap-[24px] bg-neutral-100 px-[24px] py-[24px] font-maison-neue-medium text-14-18 text-neutral-500'
          }
        >
          <div className={'grid grid-cols-4 gap-x-[20px] gap-y-[16px]'}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((hour) => {
              return (
                <button
                  key={hour}
                  className={
                    'active:bg-neutral-300 px-[4px] py-[4px] rounded-[8px] ' +
                    `${time === hour ? 'text-neutral-900 bg-neutral-300' : 'bg-none'}`
                  }
                  onClick={() => handleSelectTime(hour)}
                >
                  {hour + ':00'}
                </button>
              )
            })}
          </div>

          <div className={'flex flex-row md:flex-col gap-[24px]'}>
            <button
              className={
                'active:bg-neutral-300 px-[8px] py-[4px] rounded-[8px] ' +
                `${isAm ? 'text-neutral-900 bg-neutral-300' : ''}`
              }
              onClick={() => toggleAmPm(true)}
            >
              AM
            </button>
            <button
              className={
                'active:bg-neutral-300 px-[8px] py-[4px] rounded-[8px] ' +
                `${isAm ? '' : 'text-neutral-900 bg-neutral-300'}`
              }
              onClick={() => toggleAmPm(false)}
            >
              PM
            </button>
          </div>
        </div>

        <div className={'flex justify-between items-center px-[16px] md:px-[24px] py-[12px] md:py-[18px] gap-[12px]'}>
          <button
            className={
              'h-[56px] md:h-[40px] flex items-center justify-center font-maison-neue-medium text-neutral-900 ' +
              'border border-neutral-300 md:border-none ' +
              'bg-white  bg-none ' +
              'rounded-[16px] md:rounded-[12px] ' +
              'px-[32px] md:px-[16px] ' +
              'text-16-16 md:text-14-14 ' +
              'active:underline ' +
              'disabled:opacity-50 disabled:cursor-not-allowed'
            }
            onClick={handleClear}
            disabled={!time}
          >
            Clear
          </button>
          <button
            className={
              'h-[56px] md:h-[40px] flex items-center justify-center font-maison-neue-medium text-white ' +
              'bg-neutral-900 ' +
              'text-16-16 md:text-14-14 ' +
              'rounded-[16px] md:rounded-[12px] ' +
              'px-[16px] ' +
              'w-full md:w-auto ' +
              'disabled:opacity-50 disabled:cursor-not-allowed'
            }
            disabled={!time}
            onClick={handleApply}
          >
            Apply
          </button>
        </div>
      </div>
    )
  }

  const open = Boolean(anchorElement)

  if (isMobile) {
    return (
      <BasicSwipeDrawer isOpen={isOpen} onClose={onClose} title={'Select time'} bodyStyle={{ padding: 0 }}>
        {renderContent()}
      </BasicSwipeDrawer>
    )
  }

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorElement}
        onClose={onClose}
        anchorOrigin={
          anchorOrigin
            ? { ...anchorOrigin }
            : {
                vertical: 'bottom',
                horizontal: 'right',
              }
        }
        transformOrigin={
          transformOrigin
            ? { ...transformOrigin }
            : {
                vertical: -8,
                horizontal: 'right',
              }
        }
        sx={{
          '& .MuiPaper-root': {
            width: popupWidth + 'px',
            boxShadow: '0px 16px 48px rgba(0, 0, 0, 0.16)',
            borderRadius: '24px',
            backgroundColor: '#FFFFFF',
            padding: '0',
          },
        }}
      >
        {renderContent()}
      </Popover>
    </>
  )
}

export default SelectTime
