import React, { useEffect, useState } from 'react'
import { convertCurrency, getSymbolCurrencyByKey, numberWithCommas } from 'src/utils/common'

interface Props {
  price: number
  currency?: string
  isDynamic?: boolean
  isRounded?: boolean
  isDiscount?: boolean
}
export const MAP_ICON_CRYPTO_CURRENCY: any = {
  USDT: 'https://static.dtravel.com/coin-logo/usdt.svg',
  BTC: 'https://static.dtravel.com/coin-logo/btc.svg',
  ETH: 'https://static.dtravel.com/coin-logo/eth.svg',
  BUSD: 'https://static.dtravel.com/coin-logo/busd.svg',
  USDC: 'https://static.dtravel.com/coin-logo/usdc.svg',
  TRVL: 'https://static.dtravel.com/coin-logo/trvl-logo.svg',
}

const RenderCryptoCurrency: React.FC<Props> = ({ price, currency, isDynamic, isRounded, isDiscount }) => {
  const currencySelected = currency || 'USD'
  const [converted, setConverted] = useState('')
  const isSpecialCurrency = currencySelected === 'VND' || currencySelected === 'KRW'
  const isCrypto =
    currencySelected === 'BTC' ||
    currencySelected === 'ETH' ||
    currencySelected === 'USDT' ||
    currencySelected === 'BUSD' ||
    currencySelected === 'USDC' ||
    currencySelected === 'MATIC' ||
    currencySelected === 'TRVL'
  const isETH = currency === 'ETH'

  useEffect(() => {
    // const amountPreConvert = isSpecialCurrency ? Number(Number(price).toFixed(0)) : Number(Number(price).toFixed(2))
    // const amount = isDynamic
    //   ? amountPreConvert
    //   : isSpecialCurrency
    //     ? Number(price).toFixed(0)
    //     : Number(price).toFixed(2)
    const numberCommas = isSpecialCurrency ? 0 : isETH ? 6 : 2
    const amount = Number(price).toFixed(numberCommas)
    if (typeof amount !== 'number' && !amount) {
      setConverted('')
    } else {
      const amountToString = String(Number(amount) < 0 ? -1 * Number(amount) : Number(amount))
      const arrSplit = amountToString.split('.')
      if (arrSplit[1]) {
        // case số thập phân, có phần nguyên
        if (arrSplit[1].length === 1) {
          arrSplit[1] = arrSplit[1] + '0'
        } else {
          // case có từ 2 chữ số sau dấu phẩy trở lên => GIỮ NGUYÊN
        }
      } else {
        // case số nguyên, ko có phần thập phân sau dấu phẩy
        arrSplit[1] = isRounded || isSpecialCurrency ? '' : '00'
      }
      let newAmount = arrSplit.filter((v) => v !== '').join('.')
      setConverted(newAmount)
    }
    // eslint-disable-next-line
  }, [price, currencySelected, isDynamic, isRounded])

  const renderCurrencySymbol = () => {
    // if (currencySelected && currencySelected.toUpperCase() === 'USD') return '$'
    // if (currencySelected && currencySelected.toUpperCase() === 'GBP') return '£'
    // if (currencySelected && currencySelected.toUpperCase() === 'EUR') return '€'
    // if (currencySelected && currencySelected.toUpperCase() === 'AUD') return 'AU$'
    // if (currencySelected && currencySelected.toUpperCase() === 'SGD') return 'S$'
    // if (currencySelected && currencySelected.toUpperCase() === 'CAD') return 'CA$'
    // if (currencySelected && currencySelected.toUpperCase() === 'HKD') return 'HK$'
    // if (currencySelected && currencySelected.toUpperCase() === 'CNY') return '¥'
    // if (currencySelected && currencySelected.toUpperCase() === 'NZD') return 'NZ$'
    // if (currencySelected && currencySelected.toUpperCase() === 'INR') return '₹'
    // if (currencySelected && currencySelected.toUpperCase() === 'VND') return '₫'
    // if (currencySelected && currencySelected.toUpperCase() === 'AED') return 'د.إ'
    // if (currencySelected && currencySelected.toUpperCase() === 'KRW') return '₩'
    const symbol = getSymbolCurrencyByKey(currencySelected)
    if (symbol) return symbol
    return currencySelected
  }

  if (isDynamic) {
    let icon = null
    if (currencySelected) {
      icon = MAP_ICON_CRYPTO_CURRENCY[currencySelected.toUpperCase()]
    }

    return (
      <span className="flex items-center">
        {icon ? <img src={icon} alt="" width={24} height={24} /> : <>{renderCurrencySymbol()}</>}
        <span className="ml-1">
          {convertCurrency(converted.toString())}
          <>{isCrypto ? ' ' + currencySelected : ''}</>
        </span>
      </span>
    )
  }

  return (
    <span className="whitespace-nowrap">
      {(price < 0 || isDiscount) && '-'}
      {renderCurrencySymbol()}
      {isCrypto && <>&nbsp;</>}
      {numberWithCommas(converted)}
    </span>
  )
}

export default RenderCryptoCurrency
