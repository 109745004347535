import { authenUser, getUserProfile, getUserProfileWithToken, registerUser, updateUserProfile } from '../api/user'
import { PMS_TYPE, REFRESH_TOKEN, USER } from '../contants/common'
import { setProfile, setWalletAddress } from '../redux/slices/user'
import { TOKEN } from './../contants/common'
import { capitalizeString } from './common'
import { getSignature, getSignatureWalletConnect } from './ether'
import { getCookie, setCookie, deleteCookie } from 'cookies-next'
import { CONNECT_BY } from 'src/components/connect-wallet/ConnectWalletProvider'
import { EXTERNAL_LINK } from 'src/contants/common'
import { UserProfile } from 'src/interfaces/user'
import { store } from 'src/redux/store'

export const handleUpdateSurvey = async () => {
  const user: any = getUserInfo()
  try {
    await updateUserProfile({ gettingStarted: { ...user?.gettingStarted, isSurveySubmitted: true } })
    const res: any = await getUserProfile()
    saveUserInfo({ ...user, ...res.data })
  } catch (err: any) {
    console.log(err)
  } finally {
    localStorage.setItem('first', 'true')
    window.location.href = EXTERNAL_LINK.TYPE_FORM + user?.userId
  }
}

export const handleRegister = async (address: string, connector?: any) => {
  const res: any = await registerUser(address)
  await handleAuthen(address, res.data.nonce, connector)
}

export const handleAuthen = async (address: string, nonce: string, connector?: any) => {
  const signature = connector
    ? await getSignatureWalletConnect(connector, address, nonce)
    : await getSignature(address, nonce)
  const res: any = await authenUser(address, signature)
  saveAccessToken(res.data.token)
  saveRefreshToken(res.data.refreshToken)
  const profile = await getUserProfileWithToken(res.data.token)
  const newProfile = {
    ...res.data,
    ...profile.data,
    connectBy: connector ? CONNECT_BY.WALLETCONNECT : CONNECT_BY.METAMASK,
  }
  saveUserInfo(newProfile)
  store.dispatch(setWalletAddress(address))
  window.location.href = process.env.REACT_APP_BASE_PATH || '/'
  // if (newProfile?.gettingStarted?.isSurveySubmitted) {
  //   window.location.href = '/'
  // } else {
  //   await handleUpdateSurvey()
  //   // window.location.href = EXTERNAL_LINK.TYPE_FORM + newProfile?.userId
  // }
}

export async function logOut(connector?: any) {
  deleteCookie(USER, { domain: process.env.REACT_APP_FULL_DOMAIN })
  deleteCookie(TOKEN, { domain: process.env.REACT_APP_FULL_DOMAIN })
  deleteCookie(REFRESH_TOKEN, { domain: process.env.REACT_APP_FULL_DOMAIN })

  if (connector) {
    await connector.killSession()
  }
  if (!window.location.pathname.includes('/login') && !window.location.pathname.includes('/confirm')) {
    window.location.href = `${process.env.REACT_APP_BASE_PATH || ''}/login`
  }
}

export function getAccessToken() {
  return getCookie(TOKEN, { domain: process.env.REACT_APP_FULL_DOMAIN })
}
export function saveAccessToken(value: string) {
  setCookie(TOKEN, value, {
    domain: process.env.REACT_APP_FULL_DOMAIN,
    maxAge: 31536000,
    expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
  })
}
export function saveRefreshToken(value: string) {
  setCookie(REFRESH_TOKEN, value, {
    domain: process.env.REACT_APP_FULL_DOMAIN,
    maxAge: 31536000,
    expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
  })
}
export function getRefreshToken() {
  return getCookie(REFRESH_TOKEN, { domain: process.env.REACT_APP_FULL_DOMAIN })
}
export function getUserInfo() {
  const userInfo = getCookie(USER, { domain: process.env.REACT_APP_FULL_DOMAIN })
  return userInfo ? JSON.parse(userInfo) : null
}
export function saveUserInfo(value: UserProfile) {
  store.dispatch(setProfile(value))
  return setCookie(USER, value, {
    domain: process.env.REACT_APP_FULL_DOMAIN,
    maxAge: 31536000,
    expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
  })
}

export function isLoggedIn() {
  return !!getAccessToken()
}

export const renderPmsLabel = (value: string, otherLabel?: string) => {
  switch (value) {
    case PMS_TYPE.GUEST_SMILES:
      return 'Track'
    case PMS_TYPE.RENTAL_WISE:
      return 'RentalWise'
    case PMS_TYPE.AIRBNB:
    case PMS_TYPE.HOSPITABLE:
      return 'Airbnb'
    case PMS_TYPE.OTHER:
      return otherLabel ? otherLabel : 'Other'
    default:
      return capitalizeString(value)
  }
}

export const checkIsNewHost = (user: any) => {
  if (user) {
    const { hasSmartWallet, walletId } = user
    return hasSmartWallet === true || !walletId
  }
  return false
}
