
import React, { useEffect, useState } from 'react'
import { ConnectButton, lightTheme, useActiveAccount, useActiveWalletChain, useIsAutoConnecting } from "thirdweb/react";
import { createWallet, inAppWallet, Wallet } from "thirdweb/wallets";
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { handleErrorMessage, isEmpty } from 'src/utils/common';
import { base, baseSepolia, Chain, polygon, polygonAmoy } from 'thirdweb/chains'
import ic_wallet from 'src/assets/icons/ic_wallet.png'
import { useClientThirdweb, useDefaultActiveChain, useFactoryAddressThirdweb, useSupportedTokens } from './useClientThirdweb';
import { getRefreshTokenApi, getUserProfile, updateSmartWallet } from 'src/api/user';
import { getRefreshToken, getUserInfo, saveAccessToken, saveRefreshToken, saveUserInfo } from 'src/utils/user';
import { setProfile } from 'src/redux/slices/user';

// smart account: https://portal.thirdweb.com/connect/account-abstraction/guides/react

interface Props {
  btnTitle?: string
  className?: string
  targetActiveChain?: Chain
  disableAutoConnect?: boolean
}

const ConnectWalletThirdWeb = ({ btnTitle, className, targetActiveChain, disableAutoConnect }: Props) => {
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector((state) => state.user)
  const account = useActiveAccount();
  const defaultActiveChainThirdweb = useDefaultActiveChain()
  const activeChainConnected = useActiveWalletChain()
  const activeChainThirdweb = activeChainConnected || targetActiveChain || defaultActiveChainThirdweb
  const address = account?.address || ''
  const isAutoConnecting = useIsAutoConnecting()

  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production'
  const client = useClientThirdweb()
  const factoryAddress = useFactoryAddressThirdweb()
  const wallets = [
    inAppWallet({ smartAccount: { chain: targetActiveChain || defaultActiveChainThirdweb, sponsorGas: false, factoryAddress: factoryAddress || "" }, hidePrivateKeyExport: true }),
    (createWallet as any)("io.metamask"),
    (createWallet as any)("com.coinbase.wallet"),
    (createWallet as any)("walletConnect"),
  ];

  const [isAutoConnect, setIsAutoConnect] = useState<boolean>(false)

  useEffect(() => {
    if (isAutoConnecting && !isAutoConnect) setIsAutoConnect(true)
  }, [isAutoConnecting])

  const fetchUserProfile = async () => {
    try {
      const res: any = await getUserProfile()
      const user = getUserInfo()
      const newProfile = { ...user, ...res.data }
      saveUserInfo(newProfile)
      dispatch(setProfile(newProfile))
    } catch (err) {
      console.log(err)
    }
  }
  const disconnectSmartwallet = async (wallet: Wallet) => {
    await wallet.disconnect()
  }
  const handleSaveSmartWallet = async (smartWalletAddress: string, wallet: Wallet) => {
    try {
      await updateSmartWallet({ smartWallet: smartWalletAddress })
      const user = getUserInfo()
      const refreshToken = getRefreshToken() // get refresh token from cookie
      if (refreshToken) {
        // BE get wallet id from token for update crypto payment onboarding flow
        const res: any = await getRefreshTokenApi(refreshToken)
        saveAccessToken(res?.data.token)
        saveRefreshToken(res?.data.refreshToken)
        saveUserInfo({ ...user, ...res.data })
        fetchUserProfile()
      }
    } catch (error) {
      handleErrorMessage({
        message: 'This wallet address is already in use. Please try again with other supported wallets.',
      })
      disconnectSmartwallet(wallet)
    }
  }
  const handleConnectSmartWallet = async (wallet: Wallet) => {
    const chainActive = wallet.getChain()
    const smartWalletAddress = await wallet.getAccount()?.address
    const isWrongNetwork = chainActive?.id !== activeChainThirdweb.id
    if (isWrongNetwork && !isEmpty(activeChainConnected) && isEmpty(targetActiveChain)) disconnectSmartwallet(wallet)
    else {
      const walletIdAccount = profile?.user?.walletId // profile admin
      if (isEmpty(profile) || isEmpty(smartWalletAddress)) return
      if (walletIdAccount?.toLowerCase() !== smartWalletAddress?.toLowerCase()) {
        if (profile?.user?.hasSmartWallet) {
          if (!isAutoConnect) {
            handleErrorMessage({ message: `You're connecting with a wrong wallet address. Please try again.` })
          }
          disconnectSmartwallet(wallet)
        } else if (smartWalletAddress) {
          if (isAutoConnect) disconnectSmartwallet(wallet)
          else handleSaveSmartWallet(smartWalletAddress, wallet)
        }
      }
    }
    if (isAutoConnect) setIsAutoConnect(false)
  }
  const supportedTokens = useSupportedTokens()
  return (
    <div className={`custom-smart-wallet-button ${className || ""}`}>
      <ConnectButton
        client={client}
        wallets={wallets}
        showAllWallets={false}
        autoConnect={!disableAutoConnect}
        onConnect={handleConnectSmartWallet}
        accountAbstraction={{
          chain: targetActiveChain || defaultActiveChainThirdweb,
          factoryAddress: factoryAddress || "",
          sponsorGas: false
        }}
        connectButton={{
          className: `${'custom-connect-wallet'} ${!isEmpty(address) ? 'wallet-connected' : ''}`,
          label: btnTitle || "Create a wallet"
        }}
        chains={isProduction ? [polygon, base] : [polygonAmoy, baseSepolia]}
        connectModal={{
          title: "Create a Dtravel Smartwallet",
          welcomeScreen: () => {
            return (
              <div className='flex flex-col gap-4 items-center justify-center w-full h-full'>
                <img src={ic_wallet} alt="" className='w-[96px] h-[96px]' />
                <p className='text-16-20 font-maison-neue text-neutral-800'>Create a Dtravel Smartwallet</p>
              </div>
            )
          },
        }}
        supportedTokens={supportedTokens}
        theme={lightTheme({ colors: { accentText: "#A32A30", accentButtonBg: "#A32A30" } })}
      />
    </div>
  );
}
export default ConnectWalletThirdWeb