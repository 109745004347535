import { useAppSelector } from '../../redux/hooks'
import { checkIsNewHost } from '../../utils/user'
import BasicCardV2 from '../common/BasicCardV2'
import React, { useEffect, useState } from 'react'
import { getPerformanceInfo } from 'src/api/integration'
import PerformanceInfo from 'src/components/common/PerformanceInfo'
import { convertCurrency } from 'src/utils/common'

interface Props { }

const Performance: React.FC<Props> = () => {
  const [performanceData, setPerformanceData] = useState<any>({})
  const { profile } = useAppSelector((state) => state.user)
  const isNewUser = checkIsNewHost(profile?.user)

  useEffect(() => {
    const fetchPerformance = async () => {
      try {
        const res = await getPerformanceInfo()
        setPerformanceData(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchPerformance()
  }, [])

  if (isNewUser)
    return (
      <>
        <p className={'mb-[16px] font-maison-neue text-20-24 text-neutral-800'}>Performance</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-[8px]">
          <PerformanceInfo
            title={'Page Views'}
            percent={performanceData?.view || 0}
            styles={{ background: '#FFFFFF' }}
            stylePercent={{ color: '#A32A30' }}
          />
          <PerformanceInfo
            title={'Completed Bookings'}
            percent={performanceData?.booking || 0}
            styles={{ background: '#FFFFFF' }}
            stylePercent={{ color: '#986A00' }}
          />
          <PerformanceInfo
            title={'Realized Earnings'}
            percent={`$${convertCurrency(
              Number(performanceData?.earn || 0)
                .toFixed(2)
                .toString()
            )}`}
            styles={{ background: '#FFFFFF' }}
            stylePercent={{ color: '#3F753C' }}
          />
        </div>
      </>
    )

  return (
    <BasicCardV2 title={'Performance'}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 ">
        <PerformanceInfo title={'Page Views'} percent={performanceData?.view || 0} styles={{ background: '#F9F0F1' }} />
        <PerformanceInfo
          title={'Completed Bookings'}
          percent={performanceData?.booking || 0}
          styles={{ background: '#F8F3EB' }}
        />
        <PerformanceInfo
          title={'Realized Earnings'}
          percent={`$${convertCurrency(
            Number(performanceData?.earn || 0)
              .toFixed(2)
              .toString()
          )}`}
          styles={{ background: '#EFF7F3' }}
        />
      </div>
    </BasicCardV2>
  )
}

export default Performance
