import useWallet from '../../hooks/useWallet'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setIsOpenConfirmLogout, setLogOutType } from '../../redux/slices/user'
import { logOut } from '../../utils/user'
import BasicButton from '../ui/BasicButton'
import BasicPopup from '../ui/BasicPopup'
import React from 'react'
import { useActiveWallet } from 'thirdweb/react'

const ConfirmLogout = () => {
  const dispatch = useAppDispatch()
  // const disconnect = useDisconnect();
  const walletConnected: any = useActiveWallet()
  // const address = walletConnected?.address || ''
  const { isOpenConfirmLogout, logOutType } = useAppSelector((state) => state.user)
  const { connector } = useWallet()

  const handleClose = () => {
    dispatch(setLogOutType('')) // reset logout type
    dispatch(setIsOpenConfirmLogout(false))
  }
  const loading = false
  return (
    <BasicPopup
      isOpen={isOpenConfirmLogout}
      onClose={handleClose}
      title={logOutType === 'accountsChanged' ? 'Please login again!' : 'Are you sure?'}
      hideClose={logOutType === 'accountsChanged'}
    >
      <div className={'flex flex-col'}>
        <BasicButton
          variant={'outlined'}
          size={'xl'}
          color={'black'}
          onClick={handleClose}
          clases={'w-full'}
          isRadius100={true}
        >
          Cancel
        </BasicButton>
        <div className="w-full h-[16px]" />
        {logOutType !== 'accountsChanged' && (
          <BasicButton
            variant="contained"
            size={'xl'}
            color={'red'}
            clases={'w-full'}
            onClick={() => {
              logOut(connector)
              if (walletConnected) walletConnected.disconnect()
            }}
            loading={loading}
            isRadius100={true}
          >
            Logout
          </BasicButton>
        )}
      </div>
    </BasicPopup>
  )
}

export default ConfirmLogout
