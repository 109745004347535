import { deletePromotionCode, getPromotionCode, updateStatusPromotionCode } from '../../../api/reservation'
import ic_add_plus from '../../../assets/icons/ic_add_plus_16_16.svg'
import ic_chevron_down_000 from '../../../assets/icons/ic_chevron_down_000.svg'
import ic_chevron_up_000 from '../../../assets/icons/ic_chevron_up_000.svg'
import ic_trash_full from '../../../assets/icons/ic_trash_full.svg'
import { PROMO_CODE_TYPE, SETTINGS_SIDE_BAR } from '../../../contants/common'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { setToastSuccess } from '../../../redux/slices/common'
import { getSymbolCurrencyByKey, handleErrorMessage, numberWithCommas } from '../../../utils/common'
import { IconCopy16x16 } from '../../common/Icons'
import LoadingSection from '../../common/LoadingSection'
import BasicButton from '../../ui/BasicButton'
import BasicDialog from '../../ui/BasicDialog'
import BasicSwitch from '../../ui/BasicSwitch'
import AddNewPromoCode from './AddNewPromoCode'
import IconButton from '@mui/material/IconButton'
import clsx from 'clsx'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const SettingPromoCode = () => {
  const dispatch = useDispatch()
  const [isOpenAddNew, setIsOpenAddNew] = useState<boolean>(false)
  const [isOpenPopupDelete, setIsOpenPopupDelete] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<number>(0)
  const [promoCodes, setPromoCodes] = useState<any[]>([])
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [mobileToggleId, setMobileToggleId] = useState<number>(0)
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768

  async function fetchPromoCode() {
    try {
      setLoadingList(true)
      const res = await getPromotionCode()

      if (res.success && Array.isArray(res.data)) {
        setPromoCodes(res.data)
      } else {
        setPromoCodes([])
      }
    } finally {
      setLoadingList(false)
    }
  }

  useEffect(() => {
    fetchPromoCode()
  }, [])

  const onOpenPopupAddNew = () => {
    setIsOpenAddNew(true)
  }

  const onClosePopupAddNew = () => {
    setIsOpenAddNew(false)
  }

  const handleClickCopy = (code: string) => {
    navigator.clipboard.writeText(code)
    dispatch(setToastSuccess({ message: `Copied!`, duration: 2000 }))
  }

  const updateStatusOnUI = (id: number, status: string) => {
    setPromoCodes((prevState) => {
      const clone = [...prevState]
      for (let item of clone) {
        if (item.id === id) {
          item.status = status
          break
        }
      }
      return clone
    })
  }

  const onClosePopupDelete = () => {
    setDeleteId(0)
    setIsOpenPopupDelete(false)
  }

  const onOpenPopupDelete = (id: number) => {
    setDeleteId(id)
    setIsOpenPopupDelete(true)
  }

  const onConfirmDelete = async () => {
    try {
      setLoadingDelete(true)
      await deletePromotionCode(deleteId)
      dispatch(setToastSuccess({ message: 'Delete promo code successfully' }))
      await fetchPromoCode()
      onClosePopupDelete()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setLoadingDelete(false)
    }
  }

  const onToggleStatus = async (id: number, checked: boolean) => {
    try {
      setLoadingList(true)
      const status = checked ? 'active' : 'inactive'
      await updateStatusPromotionCode(id, status)
      updateStatusOnUI(id, status)
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setLoadingList(false)
    }
  }

  const renderDiscountValue = ({ value, type, currency, status }: any) => {
    const isFixed = type === PROMO_CODE_TYPE.FIXED
    return (
      <span
        className={clsx({
          'text-forest-700': ['active', 'inactive'].includes(status),
          'text-neutral-700': !['active', 'inactive'].includes(status),
        })}
      >
        {isFixed ? '-' + getSymbolCurrencyByKey(currency) + numberWithCommas(value) : '-' + value + '%'}
      </span>
    )
  }

  const renderTimeValidity = ({ validFrom, validTo }: any) => {
    const startDate = validFrom ? moment.utc(validFrom).format('MM/DD/YY') : ''
    const endDate = validTo ? moment.utc(validTo).format('MM/DD/YY') : ''
    if (startDate && endDate) {
      return startDate + ' - ' + endDate
    }
    if (startDate && !endDate) {
      return startDate + ' - ' + 'Infinitiy'
    }
    if (!startDate && endDate) {
      return moment.utc().format('MM/DD/YY') + ' - ' + endDate
    }
    return 'Always'
  }

  const renderStatus = ({ id, status }: any) => {
    return (
      <>
        {status === 'expired' && (
          <span
            className={
              'font-maison-neue-demibold text-12-16 text-neutral-700 tracking-[0.48px] uppercase px-[10px] py-[2px] bg-neutral-100 rounded-full'
            }
          >
            EXPIRED
          </span>
        )}
        {(status === 'active' || status === 'inactive') && (
          <BasicSwitch checked={status === 'active'} onChange={(event) => onToggleStatus(id, event.target.checked)} />
        )}
      </>
    )
  }

  const renderNumberOfUsed = ({ usedCount, maxUsage }: any) => {
    return (
      <span>
        <span className={'font-maison-neue-medium text-14-18 text-black'}>{usedCount}</span>/
        <span className={'font-maison-neue text-14-18 text-neutral-600'}>
          {maxUsage ? `${maxUsage} ${maxUsage > 1 ? 'times' : 'time'}` : 'unlimited'}
        </span>
      </span>
    )
  }

  const renderListMobile = () => {
    return (
      <div className={'w-full bg-white rounded-[16px] mt-[16px]'}>
        {promoCodes.map((item, index) => {
          const isLastItem = promoCodes.length - 1 === index
          const isToggleOn = mobileToggleId === item.id
          return (
            <div key={item.id} className={clsx('p-[16px]', { 'shadow-md': !isLastItem })}>
              <div key={item.id} className={clsx('flex items-center justify-between w-full ')}>
                <div>
                  <p className={'font-maison-neue-medium text-14-18 text-neutral-900'}>{item.name}</p>
                  <p className={'font-maison-neue text-14-18 text-neutral-600 mt-[4px] flex items-center gap-[4px]'}>
                    <span>{item.code}</span>
                    <IconButton size={'small'} onClick={() => handleClickCopy(item.code)}>
                      <IconCopy16x16 />
                    </IconButton>
                  </p>
                </div>

                <div className={'flex items-center'}>
                  <div>{renderStatus(item)}</div>
                  <IconButton size={'medium'} onClick={() => onOpenPopupDelete(item.id)}>
                    <img src={ic_trash_full} alt={'ic_trash_full'} />
                  </IconButton>
                  <IconButton
                    size={'medium'}
                    onClick={() => {
                      setMobileToggleId((prevState) => (item.id === prevState ? 0 : item.id))
                    }}
                  >
                    <img src={isToggleOn ? ic_chevron_up_000 : ic_chevron_down_000} alt={'ic_trash_full'} />
                  </IconButton>
                </div>
              </div>
              <div
                className={clsx('rounded-[16px] bg-neutral-100 p-[16px] mt-[16px] flex flex-col gap-[16px]', {
                  hidden: !isToggleOn,
                })}
              >
                <div className={'flex items-center justify-between'}>
                  <span className={'font-maison-neue-medium text-14-18 text-neutral-600'}>Discount</span>
                  <span>{renderDiscountValue(item)}</span>
                </div>
                <div className={'flex items-center justify-between'}>
                  <span className={'font-maison-neue-medium text-14-18 text-neutral-600'}>Validity</span>
                  <span className={'font-maison-neue text-14-18 text-neutral-600'}>{renderTimeValidity(item)}</span>
                </div>
                <div className={'flex items-center justify-between'}>
                  <span className={'font-maison-neue-medium text-14-18 text-neutral-600'}>Number of uses</span>
                  <span>{renderNumberOfUsed(item)}</span>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const renderListDesktop = () => {
    if (!Array.isArray(promoCodes) || promoCodes.length === 0) return null
    return (
      <table className={'w-full bg-white rounded-[16px] shadow-basic mt-[16px]'}>
        <thead>
          <tr className={'font-maison-neue-medium text-neutral-600 text-14-18'}>
            <th className={'text-left py-[24px] pl-[24px]'}>Code name</th>
            <th className={'text-left py-[24px]'}>Discount</th>
            <th className={'text-left py-[24px]'}>Validity</th>
            <th className={'text-left py-[24px]'}>Number of uses</th>
            <th className={'text-left py-[24px]'}>Status</th>
            <th className={'text-left py-[24px] pr-[24px]'}></th>
          </tr>
        </thead>

        <tbody>
          {promoCodes.map((item) => {
            return (
              <tr key={item.id} className={'font-maison-neue text-neutral-600 text-14-18 hover:bg-neutral-100'}>
                <td className={clsx('py-[24px] border-t border-neutral-300 pl-[24px]')}>
                  <div className={''}>
                    <p className={'font-maison-neue-medium text-14-18 text-neutral-900'}>{item.name}</p>
                    <p className={'font-maison-neue text-14-18 text-neutral-600 mt-[4px] flex items-center gap-[4px]'}>
                      <span>{item.code}</span>
                      <IconButton size={'small'} onClick={() => handleClickCopy(item.code)}>
                        <IconCopy16x16 />
                      </IconButton>
                    </p>
                  </div>
                </td>
                <td className={clsx('py-[24px] border-t border-neutral-300 font-maison-neue-medium', {})}>
                  {renderDiscountValue(item)}
                </td>
                <td className={clsx('py-[24px] border-t border-neutral-300')}>{renderTimeValidity(item)}</td>
                <td className={clsx('py-[24px] border-t border-neutral-300')}>{renderNumberOfUsed(item)}</td>
                <td className={clsx('py-[24px] border-t border-neutral-300')}>{renderStatus(item)}</td>
                <td className={clsx('py-[24px] border-t border-neutral-300 pr-[24px]')}>
                  <div className={'flex gap-[24px] justify-end'}>
                    <IconButton size={'medium'} onClick={() => onOpenPopupDelete(item.id)}>
                      <img src={ic_trash_full} alt={'ic_trash_full'} />
                    </IconButton>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  return (
    <div>
      <h2 id={SETTINGS_SIDE_BAR.PROMO_CODE} className={'font-maison-neue text-20-24 text-neutral-800'}>
        Promo Codes
      </h2>
      <p className={'font-maison-neue text-14-18 text-neutral-500 mt-[4px]'}>
        Create promo codes to offer discounts on your direct booking site.
      </p>
      <div className={'relative'}>
        <LoadingSection isLoading={loadingList} />
        {isMobile ? renderListMobile() : renderListDesktop()}
      </div>

      <div className={'mt-[16px]'}>
        <button
          className={
            'flex justify-between items-center gap-[8px] h-[40px] px-[16px] rounded-[100px] bg-white disabled:opacity-50 disabled:cursor-not-allowed hover:bg-neutral-100'
          }
          style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)' }}
          onClick={onOpenPopupAddNew}
        >
          <span>
            <img src={ic_add_plus} alt={'ic_add_plus'} />
          </span>
          <span>New promo code</span>
        </button>

        <AddNewPromoCode isOpen={isOpenAddNew} onClose={onClosePopupAddNew} fetchPromoCode={fetchPromoCode} />
      </div>

      <BasicDialog
        isOpen={isOpenPopupDelete}
        onClose={onClosePopupDelete}
        title={'Delete promo code'}
        extraTitle={'Are you sure to delete promo code for a fresh strategy?'}
      >
        <div className={'flex flex-col gap-[8px]'}>
          <BasicButton
            variant={'contained'}
            color={'red'}
            size={'xl'}
            isRadius100={true}
            onClick={onConfirmDelete}
            loading={loadingDelete}
          >
            Confirm
          </BasicButton>
          <BasicButton variant={'outlined'} color={'black'} size={'xl'} isRadius100={true} onClick={onClosePopupDelete}>
            Cancel
          </BasicButton>
        </div>
      </BasicDialog>
    </div>
  )
}

export default SettingPromoCode
