import BasicButton from '../ui/BasicButton'
import BasicDialog from '../ui/BasicDialog'
import React from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const AirbnbImportSuccess: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <BasicDialog
      isOpen={isOpen}
      onClose={onClose}
      title={'Your listings have been imported'}
      extraTitle={'Completed listings have been published. You can review all statuses on the Listings tab.'}
    >
      <BasicButton
        variant={'contained'}
        color={'red'}
        size={'xl'}
        isRadius100={true}
        onClick={onClose}
        clases={'w-full'}
      >
        Close
      </BasicButton>
    </BasicDialog>
  )
}

export default AirbnbImportSuccess
