import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { Breakpoint } from '@mui/system'
import React, { MouseEvent, ReactElement, ReactNode } from 'react'
import ic_close from 'src/assets/icons/ic_close.svg'

export interface PopupProps {
  isOpen: boolean
  onClose: () => void
  title?: string | ReactNode | ReactElement
  extraTitle?: string | ReactNode | ReactElement
  iconTitle?: ReactNode | ReactElement
  children?: any
  hideClose?: boolean
  classContainer?: string
  maxWidth?: Breakpoint
  allowBackdropClick?: boolean
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0),
    overflowY: 'initial',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(0),
  },
  '& .MuiDialog-paper': {
    '@media only screen and (max-width: 767px)': {
      minWidth: 'auto',
    },
    minWidth: '496px',
    borderRadius: '32px',
    padding: 32,
    position: 'relative',
    backgroundColor: '#FFFFFF',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none' /* IE 10+ */,
    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: 0,
    },
    'scroll-behavior': 'smooth',
  },
}))

const BasicPopup: React.FC<PopupProps> = ({
  isOpen,
  onClose,
  classContainer,
  allowBackdropClick,
  hideClose,
  title,
  extraTitle,
  iconTitle,
  children,
  maxWidth,
}) => {
  const handleCloseWrapper = (
    event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>,
    reason?: string
  ) => {
    if (reason === 'backdropClick' && allowBackdropClick) {
      // click outside
      onClose()
    }
  }

  return (
    <BootstrapDialog
      onClose={handleCloseWrapper}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      fullWidth
      maxWidth={maxWidth || 'xs'}
      scroll={'paper'}
      className={classContainer}
    >
      {iconTitle && <div>{iconTitle}</div>}
      {title && (
        <div className="flex items-start gap-[16px]">
          <div className="flex justify-center items-center w-full">
            {typeof title === 'string' ? (
              <h3 className={'font-maison-neue text-24-36 text-neutral-800 text-center'}>{title}</h3>
            ) : (
              <>{title}</>
            )}
          </div>
          {!hideClose && (
            <div className={'ml-auto'}>
              <IconButton onClick={onClose} sx={{ padding: 0, minWidth: 20 }}>
                <img src={ic_close} alt="" width={20} height={20} />
              </IconButton>
            </div>
          )}
        </div>
      )}
      {typeof extraTitle === 'string' ? (
        <p className={'font-maison-neue text-14-20 text-neutral-600 text-center mt-[12px] whitespace-pre-line'}>
          {extraTitle}
        </p>
      ) : (
        extraTitle
      )}

      <DialogContent
        sx={{
          '&.MuiDialogContent-root': {
            marginTop: title ? '24px' : '0px',
          },
        }}
      >
        {children}
      </DialogContent>
    </BootstrapDialog>
  )
}

export default BasicPopup
