import { useAppSelector } from '../../redux/hooks'
import { IcArrowRight, IcLabelInfo } from '../common/Icons'
import BasicButton from '../ui/BasicButton'
import clsx from 'clsx'
import React from 'react'

interface Props {
  position?: 'home' | 'settings'
}

const StripeWarningBanner: React.FC<Props> = ({ position }) => {
  const { profile, numberOfGenesysAccount } = useAppSelector((state) => state.user)
  const isReconnectingStripe = profile?.gettingStarted?.isReconnectingStripe

  if (isReconnectingStripe || numberOfGenesysAccount > 0) {
    return (
      <div
        className={clsx(
          'px-[24px] py-[16px] bg-sun-200 flex flex-col md:flex-row md:items-center gap-[24px] rounded-[8px]',
          {
            'mb-[40px]': position !== 'settings',
          }
        )}
      >
        <div className={'flex items-start md:items-center gap-[24px] flex-1'}>
          <div className={'min-w-[24px] mt-[10px] md:mt-0'}>
            <IcLabelInfo />
          </div>
          {position === 'settings' ? (
            <div className={''}>
              <p className={'font-maison-neue text-14-20 text-black'}>
                Due to a system upgrade, your Stripe {numberOfGenesysAccount > 1 ? 'accounts need' : 'account needs'} to
                be reconnected.
              </p>
            </div>
          ) : (
            <div className={''}>
              <p className={'font-maison-neue text-20-24 text-neutral-800'}>
                Your Stripe connection requires attention
              </p>
              <p className={'font-maison-neue text-14-20 text-black mt-1'}>
                Due to a system upgrade, your Stripe {numberOfGenesysAccount > 1 ? 'accounts need' : 'account needs'} to
                be reconnected.
              </p>
            </div>
          )}
        </div>

        {position !== 'settings' && (
          <div>
            <BasicButton
              variant={'contained'}
              color={'white'}
              size={'md'}
              isRadius100={true}
              endIcon={<IcArrowRight />}
              clases={'w-full md:w-auto'}
              href={'/settings?tab=payments'}
            >
              Go to settings
            </BasicButton>
          </div>
        )}
      </div>
    )
  }

  return null
}

export default StripeWarningBanner
