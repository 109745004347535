import { useAppSelector } from '../../redux/hooks'
import ContactSupportTeam from '../common/ContactSupportTeam'
import BasicButton from '../ui/BasicButton'
import React, { useState } from 'react'

const DisconnectPms = () => {
  const { pmsTypeConnected } = useAppSelector((state) => state.user)
  const [isOpenPopupDisconnect, setIsOpenPopupDisconnect] = useState<boolean>(false)

  const handleOpenPopupDisconnect = () => {
    setIsOpenPopupDisconnect(true)
  }

  const handleClosePopupDisconnect = () => {
    setIsOpenPopupDisconnect(false)
  }

  return (
    <>
      <BasicButton
        onClick={handleOpenPopupDisconnect}
        clases={'w-full md:w-auto'}
        variant={'outlined'}
        size={'md'}
        isRadius100={true}
      >
        Disconnect
      </BasicButton>

      <ContactSupportTeam
        isOpen={isOpenPopupDisconnect}
        handleClose={handleClosePopupDisconnect}
        type={pmsTypeConnected}
      />
    </>
  )
}

export default DisconnectPms
