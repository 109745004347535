import { ONBOARDING_STEP, ONBOARDING_STEP_TO_NUMBER } from '../../contants/user'
import { IconDtravelDirect } from '../common/Icons'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import ic_arrow_back from 'src/assets/icons/ic_arrow_left.svg'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { handleBackOnboardingStep, setIsOpenConfirmLogout } from 'src/redux/slices/user'

interface Props {
  headerTitle: string
  children?: any
  rightContent?: any
  handleBack?: () => void
  isDisableBack?: boolean
}

const LayoutOnboarding: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch()
  const { onboardingStep } = useAppSelector((state) => state.user)
  const { headerTitle, children, rightContent, isDisableBack, handleBack } = props
  const [step, setStep] = useState<string>('')

  useEffect(() => {
    const arrSteps = onboardingStep.split(', ')
    const length = arrSteps.length
    setStep(arrSteps[length - 1])
  }, [onboardingStep])

  const handleClickBack = async () => {
    if (typeof handleBack === 'function') {
      handleBack()
      return
    }
    dispatch(handleBackOnboardingStep(''))
  }

  const getStepProgress = () => {
    const stepNum = ONBOARDING_STEP_TO_NUMBER[step] ? ONBOARDING_STEP_TO_NUMBER[step] : 0
    return (stepNum / 3) * 100
  }

  return (
    <>
      <div className="w-full h-full min-h-[100svh] flex">
        <div className="w-full lg:w-1/2 bg-neutral-200">
          <div className="h-[80px] py-[16px] px-[24px] flex items-center justify-between">
            <div className={'flex gap-[12px] items-center'}>
              {!step || step === ONBOARDING_STEP.CREATE_ACCOUNT ? (
                <>
                  <IconDtravelDirect />
                  <span
                    className={
                      'font-maison-neue-demibold text-14-18 text-neutral-800 uppercase tracking-[0.56px] hidden md:block'
                    }
                  >
                    Direct
                  </span>
                </>
              ) : (
                <button
                  className={clsx(
                    'rounded-full flex items-center justify-center bg-white w-[40px] h-[40px] hover:bg-neutral-300',
                    'disabled:cursor-not-allowed disabled:opacity-60'
                  )}
                  onClick={handleClickBack}
                  disabled={isDisableBack}
                >
                  <img alt={'ic_exit'} src={ic_arrow_back} />
                </button>
              )}
            </div>
            <div className="flex flex-col gap-[8px] items-center justify-center">
              <span>{headerTitle}</span>
              <div className="w-[172px] md:w-[244px] rounded-[16px] h-[4px] bg-neutral-400">
                <div
                  style={{ width: `${getStepProgress()}%` }}
                  className="h-[4px] bg-forest-600 rounded-tl-[16px] rounded-bl-[16px]"
                />
              </div>
            </div>
            <div className="h-[40px] flex items-center justify-center">
              <button
                className="text-14-18 font-maison-neue-medium text-neutral-500 cursor-pointer md:px-[16px] px-0 hover:text-neutral-800 md:hover:bg-neutral-300 rounded-[100px] py-[10px]"
                onClick={() => {
                  dispatch(setIsOpenConfirmLogout(true))
                }}
              >
                Log out
              </button>
            </div>
          </div>
          <div className="px-4 md:px-6 overflow-auto pb-[120px] md:pb-0">{children}</div>
        </div>
        <div className="w-1/2 bg-neutral-100 hidden lg:flex justify-center items-center px-4 md:px-6 h-full min-h-[100svh] fixed top-0 right-0">
          {rightContent}
        </div>
      </div>
    </>
  )
}

export default LayoutOnboarding
