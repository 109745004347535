import { ONBOARDING_STEP } from '../../contants/user'
import { setIsSurveySubmitted, setToast, setUpdateStripePayment } from '../../redux/slices/common'
import { setAirbnbChannelId } from '../../redux/slices/listing'
import Onboarding from '../onboarding/Onboarding'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { connectStripe, getStripeStatus } from 'src/api/integration'
// eslint-disable-next-line
import { getUserProfile, updateUserProfile } from 'src/api/user'
import Home from 'src/components/home/Home'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import {
  handleNextOnboardingStep,
  openSelectProfile,
  setIsConnectedRentalWise,
  setIsConnectedStripe,
  setLoadingStripe,
  setProfile,
  setProfilesData,
} from 'src/redux/slices/user'
import { handleErrorMessage, isEmpty } from 'src/utils/common'
import { getUserInfo, saveUserInfo } from 'src/utils/user'

const HomeContainer = () => {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const navigate = useNavigate()
  const { profile, isOpenSelectProfile } = useAppSelector((state) => state.user)
  const { isSurveySubmitted } = useAppSelector((state) => state.common)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (profile?.user?.hasOldContact && !isOpenSelectProfile) {
      dispatch(setProfilesData(profile?.profiles))
      dispatch(openSelectProfile(true))
    }
    if (
      !isEmpty(profile?.gettingStarted?.isSurveySubmitted) &&
      profile?.gettingStarted?.isSurveySubmitted !== isSurveySubmitted
    ) {
      dispatch(setIsSurveySubmitted(profile?.gettingStarted?.isSurveySubmitted))
    }
  }, [profile])

  // for onboarding airbnb import hospitable
  useEffect(() => {
    const params: any = queryString.parse(search)
    if (params.airbnbChannelId) {
      dispatch(setAirbnbChannelId(params.airbnbChannelId))
      dispatch(handleNextOnboardingStep(ONBOARDING_STEP.AIRBNB_CHOOSE_LISTINGS))
    }
  }, [search])

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        setIsLoading(true)
        const user = getUserInfo()
        const res: any = await getUserProfile()
        saveUserInfo({ ...user, ...res?.data })
        setProfile(res?.data)
        if (res.data) {
          const _isSurveySubmitted = !!res.data?.gettingStarted?.isSurveySubmitted
          dispatch(setIsSurveySubmitted(_isSurveySubmitted))
        }
        if (res.data && res.data.user) {
          const oauthClient = res.data.user.oauthClient
          dispatch(setIsConnectedRentalWise(Array.isArray(oauthClient) && oauthClient.length > 0))
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    fetchUserProfile()
  }, [])

  useEffect(() => {
    const user = getUserInfo()
    const isSurveySubmittedNew = !!user?.gettingStarted?.isSurveySubmitted
    if (isSurveySubmittedNew) dispatch(setIsSurveySubmitted(isSurveySubmittedNew))

    // const handleFinishOnboarding = async () => {
    //   const user: any = getUserInfo()
    //   let dataProfileDTO: any = { gettingStarted: { ...user?.gettingStarted, isSurveySubmitted: false } }
    //   await updateUserProfile(dataProfileDTO)
    //   const res: any = await getUserProfile()
    //   saveUserInfo({ ...user, ...res.data })
    //   dispatch(setIsSurveySubmitted(true))
    // }
    // handleFinishOnboarding()
  }, [])

  // stripe for onboarding
  const fetchStripeStatus = async () => {
    try {
      dispatch(setLoadingStripe(true))
      const res: any = await getStripeStatus()
      const status = res?.data?.status
      dispatch(setIsConnectedStripe(status))
      dispatch(setUpdateStripePayment(true))
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(setLoadingStripe(false))
    }
  }
  const handleConnectStripe = async (code: string) => {
    try {
      await connectStripe(code)
      fetchStripeStatus()
      dispatch(setToast({ show: true, message: 'Stripe connected', type: 'success' }))
    } catch (error) {
      handleErrorMessage(error)
    }
  }
  useEffect(() => {
    if (search) {
      const params: any = queryString.parse(search)
      if (params.code) {
        handleConnectStripe(params.code)
        navigate('/', { replace: true })
      }
    }
  }, [search])

  return isLoading ? '' : isSurveySubmitted ? <Home /> : <Onboarding />
}

export default HomeContainer
