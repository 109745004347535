import { getPropertyAmenities } from '../../api/native-listing'
import ic_arrow_down from '../../assets/icons/ic_arrow_down.svg'
import BasicCheckbox from '../ui/BasicCheckbox'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

const POPULAR_AMENITY_IDS = [353, 20, 3, 10, 323, 11, 93, 302, 354, 25, 355, 12, 352, 1, 16]

interface Props {
  amenitiesSelected: number[]
  onChange: (newAmenities: number[]) => void
  isSmallSize?: boolean
}

const RenderListAmenities: React.FC<Props> = ({ amenitiesSelected, onChange, isSmallSize }) => {
  const [collapse, setCollapse] = useState<Array<string>>(['Popular'])
  const [categories, setCategories] = useState<any>({})

  const onChangeAmenity = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    const prevState = [...amenitiesSelected]
    let newAmenities = []
    if (checked) {
      newAmenities = [...prevState, id]
    } else {
      newAmenities = prevState.filter((item) => item !== id)
    }
    onChange(newAmenities)
  }

  useEffect(() => {
    async function fetchAmenities() {
      try {
        const res: any = await getPropertyAmenities()
        if (res.success) {
          let groupCategories: any = {}
          let popularAmenities: any[] = []
          res.data.forEach((item: any) => {
            if (POPULAR_AMENITY_IDS.includes(item.id)) {
              popularAmenities.push(item)
            } else {
              if (Object.prototype.hasOwnProperty.call(groupCategories, item.category)) {
                groupCategories[item.category].push(item)
              } else {
                groupCategories[item.category] = [item]
              }
            }
          })
          const sortPopular: any = []
          POPULAR_AMENITY_IDS.map((item) => {
            const popular = popularAmenities.find((amenity) => amenity.id === item)
            sortPopular.push(popular)
          })

          for (let key in groupCategories) {
            groupCategories[key].sort((a: any, b: any) => {
              const textA = a.name?.toUpperCase()
              const textB = b.name?.toUpperCase()
              return textA < textB ? -1 : textA > textB ? 1 : 0
            })
          }
          setCategories({ Popular: sortPopular, ...groupCategories })
        }
      } catch (error) {
        console.log(error)
      } finally {
        // dispatch(setLoading(false))
      }
    }

    fetchAmenities()
  }, [])

  return (
    <div className="flex flex-col">
      {/* <p
        className={clsx('text-neutral-800 mb-[16px]', {
          'font-maison-neue text-28-36 tracking-[-0.01em]': !isSmallSize,
          'font-maison-neue medium text-14-18': isSmallSize,
        })}
      >
        What amenities does this listing offer?
      </p> */}
      <div className="flex flex-col justify-center gap-[16px] w-full h-full">
        {(Object.keys(categories) || []).map((category: string, idx: number) => {
          const isOpened = collapse.includes(category)
          return (
            <Accordion
              key={idx}
              expanded={isOpened}
              onChange={() => {
                if (isOpened) setCollapse(collapse.filter((v: string) => v !== category))
                else setCollapse([...collapse, category])
              }}
              className={clsx('bg-white p-[24px] rounded-[16px]')}
              style={{ boxShadow: 'none', border: 'none' }}
              sx={{
                '&:before': {
                  backgroundColor: 'transparent !important',
                },
                '&.Mui-expanded': { margin: 0 },
                '&.MuiAccordion-root': {
                  backgroundColor: 'white',
                  borderRadius: '16px',
                },
              }}
            >
              <AccordionSummary
                expandIcon={<img alt={'icon'} src={ic_arrow_down} />}
                className="p-0 m-0"
                style={{ padding: 0, margin: 0, minHeight: '0' }}
                sx={{
                  '.MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded': { margin: 0 },
                }}
              >
                <p className="font-maison-neue-demibold text-14-18 text-neutral-800 tracking-[0.04em] break-word">
                  {category.toUpperCase()}
                </p>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  '&.MuiAccordionDetails-root': { padding: 0, marginTop: '16px' },
                }}
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-[16px]">
                  {(categories[category] || []).map((amenity: any) => {
                    if (amenity?.id === 30) return null // hidden Pets allowed
                    return (
                      <label className="flex gap-[12px] cursor-pointer" key={amenity.id}>
                        <div className="min-w-[20px]">
                          <BasicCheckbox
                            className="min-w-[20px]"
                            checked={amenitiesSelected.includes(amenity.id)}
                            onChange={onChangeAmenity(amenity.id)}
                            color='black'
                          />
                        </div>
                        <div
                          className={clsx('font-maison-neue text-16-20 ', {
                            'text-neutral-900': !isSmallSize,
                            'text-neutral-800': isSmallSize,
                          })}
                        >
                          {amenity.name}
                        </div>
                      </label>
                    )
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>
    </div>
  )
}

export default RenderListAmenities
