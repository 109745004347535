import Error from '../../common/Error'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import clsx from 'clsx'
import React, { useState, useEffect, useRef } from 'react'
import { createCustomUrl, updateCustomUrl, updateHostSettings, updateUserProfile } from 'src/api/user'
import BasicButton from 'src/components/ui/BasicButton'
import BasicInput from 'src/components/ui/BasicInput'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { getDomain, handleErrorMessage, isEmpty } from 'src/utils/common'

interface Props {
  handleNext: () => void
  handleUpdatePreview: any
  fetchHostSettings: any
  fetchUserProfile: any
}

const OnboardingSiteDetailsSiteName = ({
  handleNext,
  fetchHostSettings,
  fetchUserProfile,
  handleUpdatePreview,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { profile, settingData, onboardingStep } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [hostName, setHostName] = useState<string>('')
  const [legalName, setLegalName] = useState<string>('')
  const [errorClient, setErrorClient] = useState<string>('')
  const [errorApi, setErrorApi] = useState<string>('')
  const [subdomain, setSubdomain] = useState<string>('')
  const [customUrlId, setCustomUrlId] = useState<string>('')
  const userCustomUrl: any = (profile?.user?.userCustomUrl || []).find((v: any) => v.isPrimary)

  useEffect(() => {
    if (!isEmpty(userCustomUrl)) {
      handleUpdateSubdomain(userCustomUrl?.customId as string)
      handleUpdateCustomUrlId(userCustomUrl?.id as string)
    } else {
      handleUpdateSubdomain('')
      handleUpdateCustomUrlId('')
    }
  }, [userCustomUrl, onboardingStep])

  useEffect(() => {
    setLegalName(settingData?.legalName || '')
  }, [settingData])

  useEffect(() => {
    setHostName(profile?.hostName || '')
  }, [profile?.hostName])

  useEffect(() => {
    handleUpdatePreview({ hostName, legalName })
  }, [hostName, legalName])

  const clearError = () => {
    setErrorClient('')
    setErrorApi('')
  }

  const handleUpdateSubdomain = (sub: string) => {
    setSubdomain(sub)
  }

  const handleUpdateCustomUrlId = (customId: string) => {
    setCustomUrlId(customId)
  }

  const onChangeSubdomain = (value: string) => {
    clearError()
    handleUpdateSubdomain(value)
    if (value && value.trim().length < 4) {
      setErrorClient('Minimum number of characters: 4')
      return
    }
    if (value && value.trim().length > 50) {
      setErrorClient('Maximum number of characters: 50')
      return
    }
    if (value && !/^[a-zA-Z0-9-]+$/.test(value)) {
      setErrorClient('Only alphanumeric characters and dashes are allowed')
      return
    }
    if (value.startsWith('-') || value.endsWith('-')) {
      setErrorClient('Subdomain cannot start or end with a dash.')
      return
    }
  }

  const onSubmitCustomUrl = async () => {
    try {
      if (customUrlId) {
        await updateCustomUrl(customUrlId, subdomain)
      } else {
        await createCustomUrl(subdomain)
      }
    } catch (err: any) {
      setErrorApi(err.message)
      throw err
    }
  }

  const handleChangeHostName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sitenameValue = event.target.value
    setHostName(sitenameValue)
  }

  const onChangeLegalName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLegalName(event.target.value)
  }

  const handleSave = async () => {
    if (isEmpty(hostName)) return
    try {
      setIsLoading(true)
      if (!isEmpty(subdomain) && subdomain !== userCustomUrl?.customId) {
        await onSubmitCustomUrl()
      }
      if (!isEmpty(hostName) && hostName !== profile?.hostName) {
        await updateUserProfile({ hostName })
        fetchUserProfile()
      }
      if (!isEmpty(legalName) || legalName !== settingData?.legalName) {
        await updateHostSettings({ legalName })
        fetchHostSettings()
      }

      dispatch(setToastSuccess({ message: 'success' }))
      handleNext()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const renderFooterBtn = () => {
    return (
      <div className="flex items-center gap-4">
        <BasicButton
          size={'xl'}
          variant={'contained'}
          clases={'w-auto h-[48px] rounded-[100px_!important] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]'}
          color="red"
          onClick={handleSave}
          disabled={isEmpty(hostName.trim()) || isLoading}
        >
          Continue
        </BasicButton>
      </div>
    )
  }

  return (
    <>
      <p className="mb-6 text-24-36 text-neutral-800">What should we call your vacation rental business?</p>
      <div className="flex flex-col gap-6">
        <div className="w-full flex flex-col gap-4">
          <div>
            <BasicInput
              label={'Brand Name'}
              placeholder="E.g. Maui Paradise Properties..."
              isRequired
              value={hostName}
              onChange={handleChangeHostName}
              onBlur={() => {
                setHostName((prevState) => {
                  if (isEmpty(userCustomUrl) && !subdomain) {
                    onChangeSubdomain(
                      prevState
                        .trim()
                        .replaceAll(' ', '')
                        .replace(/[^a-zA-Z0-9]/g, '')
                        .toLowerCase()
                    )
                  }
                  return prevState.trim()
                })
              }}
            />
            <p className="text-14-18 font-maison-neue text-neutral-500 mt-2">
              This is the name you guests will see on your website (You can change this in the future).
            </p>
          </div>
          <div>
            <BasicInput
              label={'Business or Legal Name'}
              placeholder="E.g. MauiStays LLC..."
              value={legalName}
              onChange={onChangeLegalName}
              // onBlur={() => setHostName((prevState) => prevState.trim())}
            />
            <p className="text-14-18 font-maison-neue text-neutral-500 mt-2">
              This will be used to generate your Terms & Conditions and Privacy Policy.
            </p>
          </div>
          <div>
            <p className={'font-maison-neue-medium text-14-18 text-neutral-800 mb-[8px]'}>Website URL</p>
            <div
              className={`flex items-center gap-[4px] w-full h-[48px] rounded-[8px] px-[16px] py-[12px] border bg-white ${
                errorClient || errorApi ? 'border-red-6' : 'border-neutral-3'
              } `}
            >
              <div className={'font-maison-neue text-16-20 text-neutral-800'}>https://</div>
              <input
                ref={inputRef}
                className={`focus:outline-none font-maison-neue-medium text-16-24 min-w-0 flex-1 placeholder:text-neutral-500 ${
                  errorClient || errorApi ? 'text-red-6' : 'text-neutral-7'
                }`}
                placeholder={'mauistays...'}
                value={subdomain}
                onChange={(event) => onChangeSubdomain(event.target.value)}
                minLength={4}
                maxLength={50}
              />
              <div className={'font-maison-neue text-16-20 text-neutral-800 ml-auto'}>.{getDomain()}</div>
            </div>
            {errorClient && <Error show={true} message={errorClient} classes={'text-12-16'} />}
            {errorApi && (
              <div className={'p-4 bg-red-1 rounded-[12px] flex gap-[8px] mt-[8px]'}>
                <span>
                  <ErrorOutlineOutlinedIcon sx={{ color: '#A32A30' }} />
                </span>
                <div className={'w-full break-all'}>
                  <p className={'font-maison-neue-medium text-16-20 text-red-6'}>{errorApi}</p>
                  <p className={'font-maison-neue text-12-16 text-neutral-7 '}>
                    Please remove your current custom URL then try again.
                  </p>
                </div>
              </div>
            )}
            <p className={'font-maison-neue text-14-18 text-neutral-500 mt-[8px]'}>
              This is the URL of the website users will be directed to. You can change it later, including connecting a
              self-hosted domain.
            </p>
          </div>
        </div>
        <div
          className={clsx(
            'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
            'border-t border-neutral-300 px-4 md:px-8',
            'shadow-[0px_-2px_4px_-2px_rgba(0,0,0,0.08),0px_-4px_8px_-2px_rgba(0,0,0,0.06)]'
          )}
        >
          {renderFooterBtn()}
        </div>
        <div className="hidden lg:block">{renderFooterBtn()}</div>
      </div>
    </>
  )
}

export default OnboardingSiteDetailsSiteName
