import {
  connectStripe,
  disconnectStripe,
  getStripeStatus,
  getStripeUrl,
  makeDefaultSripe,
} from '../../../api/integration'
import { getUserProfile, updateUserProfile } from '../../../api/user'
import ic_stripe from '../../../assets/icons/ic_stripe.svg'
import { TABS_SETTING_KEYS } from '../../../contants/common'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { setToast, setUpdateStripePayment } from '../../../redux/slices/common'
import { setIsConnectedStripe, setLoadingStripe, setNumberOfGenesysAccount } from '../../../redux/slices/user'
import { handleErrorMessage } from '../../../utils/common'
import { getUserInfo, saveUserInfo } from '../../../utils/user'
import BasicButton from '../../ui/BasicButton'
import DisconnectStripeAccount from './DisconnectStripeAccount'
import EditStripeAccount from './EditStripeAccount'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const SettingsStripeAccount = () => {
  const { search } = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { isConnectedStripe, numberOfGenesysAccount } = useAppSelector((state) => state.user)
  const [isLoadingStripe, setIsLoadingStripe] = useState<boolean>(false)
  const [stripeAccounts, setStripeAccounts] = useState<any[]>([])
  const [makeDefaultLoading, setMakeDefaultLoading] = useState<any>({})
  const [reconnectloading, setReconnectloading] = useState<any>({})

  const fetchStripeStatus = async () => {
    try {
      dispatch(setLoadingStripe(true))
      const res: any = await getStripeStatus()
      if (res.success) {
        const { status, stripeAccounts } = res.data
        if (Array.isArray(stripeAccounts)) {
          const ids = stripeAccounts.map((item) => {
            return { [item.id]: false }
          })
          setReconnectloading(ids)
          setMakeDefaultLoading(ids)
        }
        setStripeAccounts(stripeAccounts)
        dispatch(
          setNumberOfGenesysAccount(
            Array.isArray(stripeAccounts)
              ? stripeAccounts.filter((item) => item.platformAccount === 'genesys').length
              : 0
          )
        )
        dispatch(setIsConnectedStripe(status))
        dispatch(setUpdateStripePayment(true))
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(setLoadingStripe(false))
    }
  }

  const handleConnectStripe = async (code: string) => {
    try {
      await connectStripe(code)
      fetchStripeStatus()
      // for case user used old stripe (Genesys account)
      // when user already disconnect all stripe account but do not connect to new account
      await updateIsReconnectingStripe(false)
      dispatch(setToast({ show: true, message: 'Stripe connected', type: 'success' }))
    } catch (error) {
      console.log(error)
    }
  }

  const fetchStripeUrlAndRedirect = async (_isReconnect?: boolean) => {
    try {
      if (!_isReconnect) setIsLoadingStripe(true)
      const res = await getStripeUrl()
      // window.location.href = res.data.url
      window.location.href = res.data.url + `&redirect_uri=${window.location.origin}${process.env.REACT_APP_BASE_PATH || ''}/settings?tab=payments`
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      // setIsLoading(false)
    }
  }

  useEffect(() => {
    if (search) {
      const params: any = queryString.parse(search)
      if (params.code) {
        handleConnectStripe(params.code)
        navigate('/settings?tab=' + TABS_SETTING_KEYS.PAYMENTS, { replace: true })
      }
    }
  }, [search])

  useEffect(() => {
    fetchStripeStatus()
  }, [])

  const handleMakeDefaultAccount = async (stripeAccountId: string) => {
    try {
      setMakeDefaultLoading((prevState: any) => {
        const cloneObj = { ...prevState }
        cloneObj[stripeAccountId] = true
        return cloneObj
      })
      const res = await makeDefaultSripe(stripeAccountId)
      if (res.success) {
        // update stripeAccounts on UI
        setStripeAccounts((prevState) => {
          return prevState.map((item) => {
            item.isPrimary = item.id === stripeAccountId
            return item
          })
        })
        // await fetchStripeStatus()
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setMakeDefaultLoading(false)
    }
  }

  const handleReconnect = async (data: any) => {
    //flow: disconnect one stripe accounts then redirect to stripe page and connect again
    // step 1: disconnect all
    try {
      setReconnectloading((prevState: any) => {
        const cloneObj = { ...prevState }
        cloneObj[data.id] = true
        return cloneObj
      })
      await disconnectStripe({
        stripeId: data.id,
        stripeIdChange: null,
      })
      // step 2: redirect
      await fetchStripeUrlAndRedirect(true)

      // for case user used old stripe (Genesys account)
      // when user already disconnect all stripe account but do not connect to new account
      await updateIsReconnectingStripe(true)
    } catch (err) {
      handleErrorMessage(err)
    } finally {
      // setLoadingReconnect((prevState: any) => {
      //   prevState[data.id] = false
      //   return prevState
      // })
    }
  }

  const updateIsReconnectingStripe = async (_isReconnect: boolean) => {
    const user: any = getUserInfo()
    await updateUserProfile({
      gettingStarted: { ...user?.gettingStarted, isReconnectingStripe: _isReconnect },
    })
    const res: any = await getUserProfile()
    saveUserInfo({ ...user, ...res.data })
  }

  const renderStatus = (isConnected: boolean) => {
    return (
      <>
        {isConnected ? (
          <span className="font-maison-neue-medium text-forest-6 uppercase text-12-16 bg-forest-1 px-2 py-[1px] rounded-[100px] tracking-[.04em] ml-[8px]">
            CONNECTED
          </span>
        ) : (
          <span className="font-maison-neue-medium text-neutral-6 uppercase text-12-16 bg-neutral-2 px-2 py-[1px] rounded-[100px] tracking-[.04em]">
            DISCONNECTED
          </span>
        )}
      </>
    )
  }

  const renderBtnReconnect = (data: any) => {
    if (data && data.platformAccount === 'genesys')
      return (
        <BasicButton
          variant={'outlined'}
          size={'md'}
          clases={'w-full md:w-auto'}
          onClick={() => handleReconnect(data)}
          loading={reconnectloading[data.id]}
          isRadius100={true}
        >
          Reconnect
        </BasicButton>
      )
    return <DisconnectStripeAccount data={data} stripeAccounts={stripeAccounts} fetchStripe={fetchStripeStatus} />
  }

  const renderBtnAddAnAccount = () => {
    if (!stripeAccounts || (Array.isArray(stripeAccounts) && stripeAccounts.length < 20))
      return (
        <BasicButton
          variant={'outlined'}
          size={'md'}
          clases={'w-full md:w-auto'}
          onClick={() => fetchStripeUrlAndRedirect()}
          loading={isLoadingStripe}
          isRadius100={true}
          disabled={numberOfGenesysAccount > 0}
        >
          Add an account
        </BasicButton>
      )
    return null
  }

  return (
    <>
      <div className={'flex flex-col md:flex-row gap-[12px] justify-between items-start md:items-center'}>
        <div className={'flex items-center gap-[12px]'}>
          <div className={'rounded-full w-[42px] h-[42px] min-w-[42px] flex items-center justify-center'}>
            {/*<img src={ic_stripe} alt={'stripe'} className="rounded-full w-[42px] h-[42px]" />*/}
            <img src={ic_stripe} alt={'stripe'} width={42} height={42} className={'rounded-full'} />
          </div>
          <div>
            <p className={'flex items-center gap-[8px]'}>
              <span className={'font-maison-neue-medium text-16-20 text-neutral-8'}>Stripe</span>
              {renderStatus(isConnectedStripe)}
            </p>
            <p className={'font-maison-neue text-14-18 text-neutral-6 mt-[4px]'}>
              Accept credit card payments, Apple Pay, Google Pay, Affirm, Klarna, and more.
            </p>
          </div>
        </div>

        {/* ---Desktop---*/}
        <div className={'hidden md:block'}>{renderBtnAddAnAccount()}</div>
      </div>

      <div className={'flex flex-col gap-[16px]'}>
        {Array.isArray(stripeAccounts) &&
          stripeAccounts.map((item) => {
            return (
              <div key={item.id} className={'flex justify-between flex-col md:flex-row gap-[16px]'}>
                <div className={'w-full md:w-1/2'}>
                  <p className={'font-maison-neue text-neutral-800 text-16-20 mb-[2px] flex items-center gap-[8px]'}>
                    <span>{item.displayName}</span>
                    {item.isPrimary && (
                      <span
                        className={
                          'font-maison-neue-medium text-12-16 text-neutral-6 tracking-[0.48px] uppercase px-[8px] py-[1px] bg-neutral-2 rounded-[100px]'
                        }
                      >
                        Default
                      </span>
                    )}
                  </p>
                  <p className={'font-maison-neue text-neutral-500 text-14-18'}>{item.email}&nbsp;</p>
                </div>
                <div className={'flex justify-end gap-[16px] w-full md:w-1/2'}>
                  {!item.isPrimary && (
                    <BasicButton
                      variant={'outlined'}
                      size={'md'}
                      isRadius100={true}
                      onClick={() => handleMakeDefaultAccount(item.id)}
                      loading={makeDefaultLoading[item.id]}
                    >
                      Make Default
                    </BasicButton>
                  )}

                  <EditStripeAccount data={item} stripeAccounts={stripeAccounts} />
                  {renderBtnReconnect(item)}
                  {/*<DisconnectStripeAccount*/}
                  {/*  data={item}*/}
                  {/*  stripeAccounts={stripeAccounts}*/}
                  {/*  fetchStripe={fetchStripeStatus}*/}
                  {/*/>*/}
                </div>
              </div>
            )
          })}
      </div>

      {/* ---Mobile---*/}
      <div className={'block md:hidden'}>{renderBtnAddAnAccount()}</div>
    </>
  )
}

export default SettingsStripeAccount
