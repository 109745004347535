import ic_minus from '../../../assets/icons/ic_minus.svg'
import ic_plus from '../../../assets/icons/ic_plus.svg'
import { FormError } from '../../../interfaces'
import BasicInputPrice from '../../ui/BasicInputPrice'
import BasicSwitch from '../../ui/BasicSwitch'
import IconButton from '@mui/material/IconButton'
import React from 'react'

interface Props {
  extraGuestFee: any
  toggleFee: (checked: boolean) => void
  onChangeFee: (field: 'value' | 'appliedPer', value: number | string | undefined) => void
  error?: FormError
  currency: string
  isEnable?: boolean
  onChangeExtraGuest: (type: 'plus' | 'minus') => void
  maxGuest: number
  hiddenTitle?: boolean
}

const MIN_GUEST = 1

const RenderExtraGuestFee: React.FC<Props> = ({
  isEnable,
  toggleFee,
  extraGuestFee,
  error,
  currency,
  onChangeFee,
  onChangeExtraGuest,
  maxGuest,
  hiddenTitle
}) => {
  return (
    <div className="p-[24px] bg-white w-full mb-[32px] rounded-[16px]">
      {!hiddenTitle && (
        <>
          <h3 className={'font-maison-neue text-20-32 text-neutral-800'}>Extra guest fee</h3>
          <p className={'font-maison-neue text-14-20 text-neutral-600 mt-[4px]'}>
            Charge for each additional guest past the default guest amount
          </p>
        </>
      )}

      <div className={'w-full flex flex-col md:flex-row gap-[16px] items-start my-[16px]'}>
        <div className={'w-full md:w-[58%]'}>
          <label htmlFor={'addition_guest_fee'} className={'flex items-center gap-[16px] h-[48px]'}>
            <BasicSwitch
              id={'addition_guest_fee'}
              checkedColor="#292926"
              checked={Boolean(isEnable)}
              onChange={(event) => toggleFee(event.target.checked)}
            />
            <span className={'font-maison-neue text-16-20 text-neutral-800'}>Additional guest fee</span>
          </label>
        </div>
        <div className={'flex justify-start md:justify-end items-start gap-[16px]'}>
          <div className={'md:w-auto md:min-w-[160px]'}>
            <BasicInputPrice
              currency={currency}
              value={extraGuestFee.value}
              onValueChange={(values) => {
                let _val: number | string = values.value.replaceAll('-', '').replaceAll(' ', '')
                _val = _val === '' ? _val : Number(_val)
                onChangeFee('value', _val)
              }}
              disabled={!isEnable}
              error={error}
            />
          </div>
          <div className={'w-auto h-[48px] flex items-center'}>
            <span className={'font-maison-neue text-16-20 text-neutral-800 whitespace-nowrap'}>per night</span>
          </div>
        </div>
      </div>

      <div className={'flex flex-col md:flex-row items-start md:items-center justify-between py-[16px] gap-[16px]'}>
        <p className={'font-maison-neue text-16-24 text-neutral-800'}>For each additional guest after</p>
        <div className={'flex justify-between items-center gap-[8px] w-full md:w-auto'}>
          <IconButton
            size={'small'}
            onClick={() => onChangeExtraGuest('minus')}
            disabled={!isEnable || extraGuestFee.additionalGuestAfter === MIN_GUEST || extraGuestFee.additionalGuestAfter === -1}
            sx={{
              '&.Mui-disabled': {
                'pointer-events': 'auto',
                cursor: 'not-allowed',
                opacity: 0.5,
              },
            }}
          >
            <img src={ic_minus} alt={''} className={'w-[36px] h-[36px] min-w-[36px]'} />
          </IconButton>
          <div className={'w-[64px] min-w-[64px] text-center font-maison-neue text-24-36 text-neutral-800'}>
            {extraGuestFee.additionalGuestAfter === -1 ? '' : extraGuestFee.additionalGuestAfter}
          </div>
          <IconButton
            size={'small'}
            onClick={() => onChangeExtraGuest('plus')}
            disabled={!isEnable || extraGuestFee.additionalGuestAfter === maxGuest}
            sx={{
              '&.Mui-disabled': {
                'pointer-events': 'auto',
                cursor: 'not-allowed',
                opacity: 0.5,
              },
            }}
          >
            <img src={ic_plus} alt={''} className={'w-[36px] h-[36px] min-w-[36px]'} />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default RenderExtraGuestFee
