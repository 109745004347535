import { updateHostSettings, uploadSettingImages } from '../../../api/user'
import ic_media_40_40 from '../../../assets/icons/ic_media_40_40.svg'
import ic_trash_full from '../../../assets/icons/ic_trash_full.svg'
import { MAX_LENGTH_HERO_CONTENT } from '../../../contants/common'
import { ONBOARDING_STEP } from '../../../contants/user'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { handleNextOnboardingStep, setSettingData } from '../../../redux/slices/user'
import { handleErrorMessage, validateWidthHeightImage } from '../../../utils/common'
import DisabledWrapper from '../../common/DisabledWrapper'
import SettingDisplayLandingPage from '../../settings/booking-site/SettingDisplayLandingPage'
import BasicButton from '../../ui/BasicButton'
import BasicInput from '../../ui/BasicInput'
import BasicTextarea from '../../ui/BasicTextarea'
import LayoutOnboarding from '../LayoutOnboarding'
import RightContentLandingPage from './RightContentLandingPage'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'

const OnboardingLandingPage = () => {
  const dispatch = useAppDispatch()
  const inputPhoto = useRef<HTMLInputElement | null>(null)
  const { settingData } = useAppSelector((state) => state.user)
  const [isLoading, setIsLoading] = useState(false)
  const [headline, setHeadline] = useState<string>('')
  const [subHeadline, setSubHeadline] = useState<string>('')
  const [gallery, setGallery] = useState<string>()
  const isShowLandingPage = settingData.status === 'active'

  useEffect(() => {
    setHeadline(settingData.headline || '')
    setSubHeadline(settingData.subHeadline || '')
    setGallery(settingData.gallery || '')
  }, [settingData])

  const onChangeHeadline = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeadline(event.target.value)
  }

  const onChangeSubHeadline = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSubHeadline(event.target.value)
  }

  const handleUpload = async (e: any) => {
    const file: File = e.target.files[0]
    const isValid = await validateWidthHeightImage(file, 1280, 720)
    if (!isValid) {
      handleErrorMessage({ message: 'Image must be at least 1280 x 720.' })
      return
    }
    if (file) {
      try {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('file', file)
        const res = await uploadSettingImages('gallery', formData)
        if (res.success) {
          setGallery(res.data)
        }
      } catch (error: any) {
        handleErrorMessage(error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleNext = async () => {
    dispatch(handleNextOnboardingStep(ONBOARDING_STEP.CUSTOMIZE_SITE))
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const data: any = { headline, subHeadline, gallery }
      const res = await updateHostSettings(data)
      if (res.success && res.data) {
        dispatch(setSettingData({ ...settingData, ...res.data }))
        handleNext()
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const renderFooterBtn = () => {
    return (
      <div className="flex items-center gap-4 pb-0 md:pb-6">
        <BasicButton
          size={'xl'}
          variant={'contained'}
          clases={'w-auto h-[48px] rounded-[100px_!important] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]'}
          color="red"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={!headline || !subHeadline}
        >
          Continue
        </BasicButton>
        <BasicButton
          size={'xl'}
          variant={'contained'}
          clases={
            'w-auto h-[48px] rounded-[100px_!important] border border-neutral-300 shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]'
          }
          color="white"
          onClick={handleNext}
        >
          Skip
        </BasicButton>
      </div>
    )
  }

  const renderHeroSection = () => {
    return (
      <div>
        <div className={'flex items-center justify-between gap-[24px] mb-[24px]'}>
          <p className={'font-maison-neue text-20-24 text-neutral-800'}>Hero section</p>
          <button
            className={
              'w-[32px] min-w-[32px] h-[32px] bg-white rounded-full flex items-center justify-center hover:bg-neutral-50 shadow-xs'
            }
            onClick={() => setGallery('')}
          >
            <img src={ic_trash_full} alt={'ic_trash_full'} width={16} height={16} />
          </button>
        </div>
        <p className={'font-maison-neue-medium text-14-18 text-neutral-800 mb-[12px]'}>Photo</p>
        <div
          className={'flex justify-center items-center w-full h-[240px] rounded-[24px]'}
          style={{
            border: '4px solid rgba(255, 255, 255, 1)',
            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
          }}
        >
          {gallery ? (
            <img src={gallery} alt="hero" className="w-full h-full object-cover rounded-[24px] box-border" />
          ) : (
            <button
              className={'flex justify-center items-center'}
              onClick={() => {
                const inputEle = inputPhoto.current as HTMLInputElement
                inputEle.click()
              }}
            >
              <img src={ic_media_40_40} alt={'ic_media'} className={''} />
            </button>
          )}
        </div>
        <input
          ref={inputPhoto}
          type={'file'}
          multiple={true}
          accept={'image/*'}
          className={'opacity-0 w-full h-full z-50 hidden'}
          onChange={handleUpload}
          onClick={() => {
            const element = inputPhoto.current as HTMLInputElement
            if (element) element.value = ''
          }}
        />

        <div className={'mt-[24px]'}>
          <BasicInput
            label={'Headline'}
            placeholder={'Write your headline here'}
            value={headline}
            onChange={onChangeHeadline}
            onBlur={() => setHeadline((prevState) => prevState.trim())}
            maxLength={MAX_LENGTH_HERO_CONTENT.HEADLINE}
            showCount
          />
        </div>
        <div className={'mt-[24px]'}>
          <BasicTextarea
            label={'Sub-Headline'}
            rows={3}
            placeholder={'Write your sub-headline here'}
            value={subHeadline}
            onChange={onChangeSubHeadline}
            onBlur={() => setSubHeadline((prevState) => prevState.trim())}
            showCount
            maxLength={MAX_LENGTH_HERO_CONTENT.SUB_HEADLINE}
          />
        </div>
      </div>
    )
  }

  return (
    <LayoutOnboarding
      headerTitle={'Landing page'}
      rightContent={<RightContentLandingPage headline={headline} subHeadline={subHeadline} gallery={gallery || ''} />}
    >
      <div className={'mt-[38px] lg:mt-[70px] w-full mx-auto md:max-w-[524px]'}>
        <h2 className={'font-maison-neue text-24-36 text-neutral-800 mb-[24px]'}>Customize your landing page</h2>

        <SettingDisplayLandingPage isOnboarding={true} />

        <div className={'w-full h-[0.5px] bg-neutral-900 opacity-[0.15] my-[32px]'} />

        <div>
          {isShowLandingPage ? renderHeroSection() : <DisabledWrapper>{renderHeroSection()}</DisabledWrapper>}
          <div className={'mt-[24px] hidden lg:block'}>{renderFooterBtn()}</div>
        </div>

        <div
          className={clsx(
            'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full z-[102]',
            'border-t border-neutral-300 px-4 md:px-8',
            'shadow-footer'
          )}
        >
          {renderFooterBtn()}
        </div>
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingLandingPage
