import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NativeListingProperty } from 'src/interfaces/listing'

export interface ListingState {
  nativeListingProperty: NativeListingProperty
  isShowPopupReviewing: boolean
  triggerResetCalendar: number
  airbnbChannelId: string
  isOpenPopupAddListing: boolean
  isOpenPopupInfoAirbnb: boolean
  isOpenPopupChooseListingAirbnb: boolean
  isOpenPopupEditListingAirbnb: boolean
  importRequestId: string
  importRequestIdAirbnb: string
  externalNameNativeListing: string
  isOpenPopupDeleteListingSuccess: boolean
  isOpenImportProgressBackground: boolean
  importingPmsIds: string[]
  importingPropertyStatus: any
  isOpenChooseListingHospitable: boolean
  isOpenChooseListingChannex: boolean
  isOpenPopupSelectAccountAirbnb: boolean
  bulkListingData: any
  bulkListingSection: string
}

const initialState: ListingState = {
  nativeListingProperty: {},
  isShowPopupReviewing: false,
  triggerResetCalendar: 0,
  airbnbChannelId: '',
  isOpenPopupAddListing: false,
  isOpenPopupInfoAirbnb: false,
  isOpenPopupChooseListingAirbnb: false,
  isOpenPopupEditListingAirbnb: false,
  importRequestId: '',
  importRequestIdAirbnb: '',
  externalNameNativeListing: '',
  isOpenPopupDeleteListingSuccess: false,
  isOpenImportProgressBackground: false,
  importingPmsIds: [],
  importingPropertyStatus: {},
  isOpenChooseListingHospitable: false,
  isOpenChooseListingChannex: false,
  isOpenPopupSelectAccountAirbnb: false,
  bulkListingData: null,
  bulkListingSection: '', // show section name when confirm edit bulk listing
}

export const listingSlices = createSlice({
  name: 'listing',
  initialState,
  reducers: {
    setNativeListingProperty: (state, action: PayloadAction<NativeListingProperty>) => {
      state.nativeListingProperty = action.payload
    },
    setShowPopupReviewing: (state, action: PayloadAction<boolean>) => {
      state.isShowPopupReviewing = action.payload
    },
    setTriggerResetCalendar: (state, action: PayloadAction<number>) => {
      state.triggerResetCalendar = action.payload
    },
    setAirbnbChannelId: (state, action: PayloadAction<string>) => {
      state.airbnbChannelId = action.payload
    },
    setIsOpenPopupAddListing: (state, action: PayloadAction<boolean>) => {
      state.isOpenPopupAddListing = action.payload
    },
    setIsOpenPopupInfoAirbnb: (state, action: PayloadAction<boolean>) => {
      state.isOpenPopupInfoAirbnb = action.payload
    },
    setIsOpenPopupChooseListingAirbnb: (state, action: PayloadAction<boolean>) => {
      state.isOpenPopupChooseListingAirbnb = action.payload
    },
    setIsOpenPopupEditListingAirbnb: (state, action: PayloadAction<boolean>) => {
      state.isOpenPopupEditListingAirbnb = action.payload
    },
    setImportRequestId: (state, action: PayloadAction<string>) => {
      state.importRequestId = action.payload
    },
    setImportRequestIdAirbnb: (state, action: PayloadAction<string>) => {
      state.importRequestIdAirbnb = action.payload
    },
    setExternalNameNativeListing: (state, action: PayloadAction<string>) => {
      state.externalNameNativeListing = action.payload
    },
    setIsOpenPopupDeleteListingSuccess: (state, action: PayloadAction<boolean>) => {
      state.isOpenPopupDeleteListingSuccess = action.payload
    },
    setIsOpenImportProgressBackground: (state, action: PayloadAction<boolean>) => {
      state.isOpenImportProgressBackground = action.payload
    },
    setImportingPmsIds: (state, action: PayloadAction<string[]>) => {
      state.importingPmsIds = action.payload
    },
    setImportingPropertyStatus: (state, action: PayloadAction<any[]>) => {
      state.importingPropertyStatus = action.payload
    },
    setIsOpenChooseListingHospitable: (state, action: PayloadAction<boolean>) => {
      state.isOpenChooseListingHospitable = action.payload
    },
    setIsOpenChooseListingChannex: (state, action: PayloadAction<boolean>) => {
      state.isOpenChooseListingChannex = action.payload
    },
    setIsOpenPopupSelectAccountAirbnb: (state, action: PayloadAction<boolean>) => {
      state.isOpenPopupSelectAccountAirbnb = action.payload
    },
    setBulkListingData: (state, action: PayloadAction<any>) => {
      state.bulkListingData = action.payload
    },
    setBulkListingSection: (state, action: PayloadAction<string>) => {
      state.bulkListingSection = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setNativeListingProperty,
  setShowPopupReviewing,
  setTriggerResetCalendar,
  setAirbnbChannelId,
  setIsOpenPopupAddListing,
  setIsOpenPopupInfoAirbnb,
  setIsOpenPopupChooseListingAirbnb,
  setIsOpenPopupEditListingAirbnb,
  setImportRequestId,
  setImportRequestIdAirbnb,
  setExternalNameNativeListing,
  setIsOpenPopupDeleteListingSuccess,
  setIsOpenImportProgressBackground,
  setImportingPmsIds,
  setImportingPropertyStatus,
  setIsOpenChooseListingHospitable,
  setIsOpenChooseListingChannex,
  setIsOpenPopupSelectAccountAirbnb,
  setBulkListingData,
  setBulkListingSection,
} = listingSlices.actions

export default listingSlices.reducer
