import LayoutV2 from '../layout/LayoutV2'
import React, { useState } from 'react'
import ic_check from 'src/assets/icons/ic_check.svg'
import ic_uncheck from 'src/assets/icons/ic_uncheck.svg'
import BasicInput from 'src/components/ui/BasicInput'
import BasicSelect from 'src/components/ui/BasicSelect'
import BasicTextarea from 'src/components/ui/BasicTextarea'
import { isURL } from 'src/utils/common'

const optionList = [
  { value: '', label: 'Please select' },
  { value: '1', label: '0 - 10' },
  { value: '2', label: '10 - 50' },
  { value: '3', label: '50 - 100' },
  { value: '4', label: 'Above 100' },
]
const describeList = [
  { value: '', label: 'Please select' },
  { value: '1', label: 'I have no idea about cryptocurrencies and web3' },
  { value: '2', label: 'I know a little bit' },
  { value: '3', label: 'I know well' },
]
const hostingList = [
  { value: 'airbnb', label: 'Airbnb' },
  { value: 'booking', label: 'Booking.com' },
  { value: 'vrbo', label: 'VRBO' },
  { value: 'other', label: 'Other' },
]
const Survey: React.FC = () => {
  const [urlValue, setUrlValue] = useState<string | undefined>()
  const [hosting, setHosting] = useState<Array<string>>(['airbnb', 'vrbo', 'other'])
  const [properties, setProperties] = useState<string>('')
  const [describe, setDescribe] = useState<string>('')
  const [isError, setError] = useState<boolean>(false)

  const handleValidate = () => {
    if (!urlValue) return
    if (isURL(urlValue)) return
    setError(true)
  }

  const renderErrorURL = () => {
    if (!urlValue) return null
    if (isURL(urlValue)) return null
    return (
      <>
        {isError && (
          <p className="text-red text-12-12 m-0 mb-8" style={{ marginTop: -24 }}>
            Please provide a valid website.
          </p>
        )}
      </>
    )
  }
  return (
    <LayoutV2 showDefaultHeader={true}>
      <div className="flex w-full">
        <div className="w-1/2">
          <h2 className="font-monument-extended text-32-40 text-grey-700 mb-8">Apply to host on Dtravel</h2>

          <BasicSelect
            options={optionList}
            label={'How many properties do you currently manage?'}
            classes={'mb-8'}
            value={properties}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setProperties(e.target.value)}
          />
          <BasicInput
            label={'Does your business have a website?'}
            placeholder={'www.inresidence.villas'}
            classes={'mb-8'}
            value={urlValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setUrlValue(e.target.value.trim())
              if (!e.target.value.trim()) setError(false)
            }}
            onBlur={handleValidate}
          />
          {renderErrorURL()}

          <p className="text-16-24 mb-3">Which hosting platform(s) do you currently list on?</p>
          {hostingList.map((el: any, idx: number) => {
            const isChecked = hosting.includes(el.value)
            return (
              <button
                key={idx}
                className="flex items-center mb-3 cursor-pointer"
                onClick={() => {
                  let result: Array<string> = []
                  if (isChecked) {
                    result = hosting.filter((v: string) => v !== el.value)
                  } else {
                    result = [...hosting, el.value]
                  }
                  setHosting(result)
                }}
              >
                <img src={isChecked ? ic_check : ic_uncheck} alt="" />
                <span className="text-16-24 text-grey mt-1 ml-2">{el.label}</span>
              </button>
            )
          })}

          <BasicTextarea
            label={'Why are you interesting in listing on Dtravel?'}
            placeholder={'Type your answer here'}
            rows={5}
            classes={'mb-8'}
            maxLength={1000}
          />
          <BasicSelect
            options={describeList}
            label={'How would you describe your knowledge of cryptocurrencies and web3 technologies?'}
            classes={'mb-8'}
            value={describe}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setDescribe(e.target.value)}
          />

          <button className="bg-red text-white py-4 px-6 rounded-xl">Submit application</button>
        </div>
        <div
          className="ml-28"
          style={{
            backgroundImage: 'url("https://static.dtravel.com/dtravel-direct/dtravel_direct_survey_bg.png")',
            backgroundSize: '100% 100%',
            width: 'calc(50% - 112px)',
          }}
        />
      </div>
    </LayoutV2>
  )
}

export default Survey
