import { FormError } from './index'

export interface ListingParams {
  name?: string
  orderByName?: string
  orderByImportedAt?: string
  page?: number
  pageSize?: number
  userId?: string
  fiat?: string
  crypto?: string
  publishedStatus?: string
  search?: string
  isActive?: boolean
  source?: string
  status?: string
  channel?: string
  channelStatus?: string
  month?: string
  guestScreening?: string
  damageProtection?: string
}

export interface Address {
  lat: string
  lng: string
  city: string
  state: string
  street: string
  address: string
  country: string
  zipcode: string
  countryCode: string
  publicAddress: string
}

export interface Amenity {
  id: number
  name: string
  category: string
  pmsAmenityId: string
}

export interface Contact {
  language: string
  contactName: string
  contactEmail: string
  contactPhone1: string
  contactPhone2: string
  contactAddress: string
  contactSurName: string
  contactLanguage: string
}

export interface PropertyImage {
  id: number
  propertyId: number
  caption: string
  url: string
  sortOrder: number
  pmsImageId: string
  createdAt: Date
  updatedAt: Date
}

export interface ListingItem {
  id: number
  userId: string
  walletId: string
  status: string
  title?: any
  internalName: string
  externalName: string
  doorCode?: any
  starRating: number
  description: string
  propertyTypeId: number
  roomType: string
  bathRoomType: string
  defaultCheckInTime: string
  defaultCheckOutTime: string
  checkinType?: any
  checkinInstruction?: any
  personCapacity: number
  guestsIncluded: number
  bedrooms: number
  beds: number
  bathrooms: number
  maxChildren?: any
  maxInfant?: any
  maxPet?: any
  address: Address
  pictures?: any
  amenities: Amenity[]
  additionalInfo?: any
  contact: Contact
  rooms?: any
  cleaningStatus?: any
  policies?: any
  cancelPolicyId?: any
  cancelPolicies?: any
  importedAt: Date
  propertyImages: PropertyImage[]
  timezone: string
  propertyContract?: string
  deployStatus: string
  deployedAt: string
  thumbnail: string
  updatedAt: Date
  publishedStatus: string
  crypto: boolean
  fiat: boolean
  isActive: boolean
  cryptoPaymentEnabled: boolean
  fiatPaymentEnabled: boolean
  pmsPropertyId: number | null
  pmsType: string
  source: string
  channel: string
  settingSteps?: number
  propertyChannelStatus?: any[]
  superhogStatus: string
  calendar: CalendarItem[]
  propertyPrice: PropertyPrice
}

export interface CalendarItem {
  id: number
  date: string
  isAvailable: boolean
  status: string
  price: number
  minimumStay: number
  maximumStay: number
  closedOnArrival: null | boolean
  closeOnDeparture: null | boolean
  note: null | string
  minimumStayThrough: number
  reason: null | string
}

export interface PropertyType {
  id: number
  name: string
}

export interface PropertyPrice {
  id: number
  propertyId: number
  currency: string
  basePrice: string
  guestsIncludedInRegularFee: string
  weeklyPriceFactor: string
  monthlyPriceFactor: string
  cleaningFee: string
  securityDepositFee?: any
  extraPersonFee: string
  propertyRentTax: string
  guestPerPersonPerNightTax: string
  guestStayTax: string
  guestNightlyTax: string
  refundableDamageDeposit: string
  rateConfig: any
  createdAt: Date
  updatedAt: Date
}

export interface FeeSetting {
  id: number
  propertyId: number
  feeType: string
  feeTitle?: any
  feeAppliedPer: string
  amount: string
  amountType: string
  isMandatory: boolean
  isQuantitySelectable: boolean
  pmsFeeId: string
  createdAt: Date
  updatedAt: Date
}

export interface PropertyDetailProps {
  id: number
  userId: string
  walletId: string
  status: string
  title?: any
  internalName: string
  externalName: string
  doorCode?: any
  keyPickup?: any
  starRating?: any
  description: string
  propertyTypeId: number
  roomType: string
  bathRoomType: string
  defaultCheckInTime: string
  defaultCheckOutTime: string
  checkinType?: any
  checkinInstruction?: any
  personCapacity: number
  guestsIncluded: number
  bedrooms: number
  beds: number
  bathrooms: number
  maxChildren?: any
  maxInfant?: any
  maxPet?: any
  address: Address
  pictures?: any
  amenities: Amenity[]
  additionalInfo?: any
  contact: Contact
  rooms?: any
  cleaningStatus?: any
  policies?: any
  cancelPolicyId?: any
  cancelPolicies?: any
  airbnbCancellationPolicies?: any
  importedAt: Date
  pmsType: string
  pmsPropertyId: string
  propertyType: PropertyType
  propertyRooms: any[]
  propertyPrice: PropertyPrice
  propertyImages: any[]
  feeSettings: FeeSetting[]
  propertyContract: string
  deployStatus: string
  deployAt: string
  publishedStatus: string
  isActive: boolean
  checkInTime: string
  checkOutTime: string
  houseRules: string
  cancelPolicyDescription: string
  superhogStatus?: string
  superhogKygId?: string
  superhogProtectId?: string
  propertyTypeName?: string
  livingrooms: number
  kitchens: number
  minNights: number
  maxNights: number
  source: string
  channel: string
  settingSteps?: number
  thumbnail: string
  propertyNiteContracts: any[]
  advanceNotice?: number
  availabilityWindow?: number | null
  markupPrice: number
}

export interface PriceAvailability {
  id: number
  date: string
  isAvailable: number
  status: string
  price: number
  minimumStay: number
  maximumStay: number
  closedOnCheckin?: boolean
  closedOnCheckout?: boolean
  bookable?: boolean
}

export interface NativeListingProperty {
  propertyTypeId?: number
  roomType?: string
  squareMeters?: string | number
  squareType?: string
}
export interface SuggestedMapboxParams {
  q: string
  session_token: string
  access_token: string
  limit?: number
  types?: string
  country?: string
  proximity?: string
}

export interface SuperHogCreateListing {
  listingId: number
  friendlyName: string
  addressLine1?: string
  addressLine2?: string
  town: string
  postcode: string
  countryIso: string
  superHogStatus: string
  numberOfBedrooms?: number
  hostFirstName: string
  hostLastName: string
  hostEmail: string
  hostCompanyName?: string
  hostTelephone?: string
}

export interface ErrorDayOfWeek {
  monday: FormError
  tuesday: FormError
  wednesday: FormError
  thursday: FormError
  friday: FormError
  saturday: FormError
  sunday: FormError
}

export interface UpdateListingHospitable {
  id: number
  checkinTime: string // '11'
  checkoutTime: string //'10'
  houseRules: string //'dsdsd'
  cancellationPolicy: string //'dsds'
  maxPet: number
  amenities: { amenityId: number }[]
  availabilityWindow?: number
  advanceNotice?: number
  taxSettings: any[]
  feeSettings: any[]
  price?: any
}

export enum ListingView {
  LIST = 'LIST',
  CALENDAR = 'CALENDAR',
}
