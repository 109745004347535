import { setToastError } from '../redux/slices/common'
import { isMobileDevice } from './common'
import { logOut } from './user'
import detectEthereumProvider from '@metamask/detect-provider'
import { ethers } from 'ethers'
import { PROVIDER_NETWORKS } from 'src/contants/common'
import { store } from 'src/redux/store'
import { MIN_ABI, PROPERTY_CONTRACT_ABI } from 'src/utils/abi'

const ethereum = (window as any).ethereum

export const startApp = async (provider: any) => {
  // If the provider returned by detectEthereumProvider is not the same as
  // window.ethereum, something is overwriting it, perhaps another wallet.
  if (!provider) {
    store.dispatch(setToastError({ message: 'Please install MetaMask!' }))
    return
  }
  if (provider !== window.ethereum) {
    store.dispatch(setToastError({ message: 'Do you have multiple wallets installed?' }))
    return
  }
  // Access the decentralized web!
  // const ethereum = (window as any).ethereum
  ethereum.on('chainChanged', handleChainChanged)

  // Note that this event is emitted on page load.
  // If the array of accounts is non-empty, you're already
  // connected.
  ethereum.on('accountsChanged', handleAccountsChanged)

  if (window.location.pathname !== `${process.env.REACT_APP_BASE_PATH || ''}/login`) {
    const accounts = await getAccounts()
    if (accounts.length === 0) {
      // no account connected
      logOut()
    }
  }
}

export const openMetaMaskUrl = (url: string) => {
  const a = document.createElement('a')
  a.href = url
  a.target = '_blank'
  document.body.appendChild(a)
  a.click()
  a.remove()
}

/*
  https://docs.metamask.io/guide/rpc-api.html#restricted-methods
  wallet_requestPermissions: This RPC method is not yet available in MetaMask Mobile.
  For mobile: use Browser in Metamask App
 */
export const connectWallet = async () => {
  try {
    const provider = await detectEthereumProvider()
    if (!provider) {
      // open deeplink
      if (!isMobileDevice()) {
        const url = `https://metamask.app.link/dapp/${window.location.host}/`
        openMetaMaskUrl(url)
      }
    }

    const accounts = await getAccounts()
    if (accounts.length === 0) {
      return await ethereum.request({ method: 'eth_requestAccounts' })
    }
    return accounts
  } catch (err) {
    if (err instanceof TypeError) {
      throw {
        code: 'uninstall_metamask',
        message: 'Please open the site in Metamask mobile app browser to connect to Dtravel',
      }
    }
    throw err
  }
}

export const getAccounts = async () => {
  return await ethereum.request({ method: 'eth_accounts' })
}

export const getCurrentAccount = async () => {
  const accounts = await (window as any).ethereum.request({
    method: 'eth_accounts',
  })
  return accounts.length > 0 ? accounts[0] : ''
}

export const getChainId = async () => {
  return await (window as any).ethereum.request({ method: 'eth_chainId' })
}

export const handleChainChanged = () => {
  // We recommend reloading the page, unless you must do otherwise
  window.location.reload()
}

// For now, 'eth_accounts' will continue to always return an array
export const handleAccountsChanged = async () => {
  // change account => make user logout
  if (window.location.pathname !== `${process.env.REACT_APP_BASE_PATH || ''}/login`) {
    logOut()
  }
}

export const getSignature = async (publicAddress: string, nonce: string) => {
  return await (window as any).ethereum.request({
    method: 'personal_sign',
    params: [`Signing one-time nonce: ${nonce}`, publicAddress, ''],
  })
}

export const getSignatureWalletConnect = async (connector: any, publicAddress: string, nonce: string) => {
  const msgParams = [
    // convertUtf8ToHex(message), // Required
    `Signing one-time nonce: ${nonce}`, // Required
    publicAddress, // Required
  ]
  return connector.signPersonalMessage(msgParams)
}

export const switchToPolygonNetwork = async () => {
  const chainId = process.env.REACT_APP_POLYGON_CHAIN_ID
  const chainName = PROVIDER_NETWORKS.find((v) => v?.hex === chainId)?.name || ''
  const rpcUrl = process.env.REACT_APP_POLYGON_RPC_URL
  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainId }],
    })
  } catch (switchError: any) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: chainId,
              chainName: chainName,
              nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18,
              },
              rpcUrls: [rpcUrl],
              blockExplorerUrls:
                chainId === '0x13882' ? ['https://amoy.polygonscan.com/'] : ['https://polygonscan.com/'],
            },
          ],
        })
      } catch (addError) {
        // handle "add" error
      }
    }
    // handle other "switch" errors
  }
}
export const switchToETHNetwork = async () => {
  const chainId = process.env.REACT_APP_ETH_CHAIN_ID
  const chainName = PROVIDER_NETWORKS.find((v) => v?.hex === chainId)?.name || ''
  const rpcUrl = process.env.NEXT_PUBLIC_ETH_RPC_URL
  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainId }],
    })
  } catch (switchError: any) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: chainId,
              chainName: chainName,
              nativeCurrency: {
                name: 'Ethereum',
                symbol: 'ETH',
                decimals: 18,
              },
              rpcUrls: [rpcUrl],
              blockExplorerUrls: ['https://etherscan.io'],
            },
          ],
        })
      } catch (addError) {
        // handle "add" error
      }
    } else {
      store.dispatch(setToastError({ message: switchError?.message }))
    }
    // handle other "switch" errors
  }
}
export const switchToBaseNetwork = async () => {
  const chainId = process.env.REACT_APP_BASE_CHAIN_ID
  const chainName = PROVIDER_NETWORKS.find((v) => v?.hex === chainId)?.name || ''
  const rpcUrl = process.env.REACT_APP_BASE_RPC_URL
  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainId }],
    })
  } catch (switchError: any) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: chainId,
              chainName: chainName,
              rpcUrls: [rpcUrl] /* ... */,
            },
          ],
        })
      } catch (addError) {
        // handle "add" error
      }
    }
    // handle other "switch" errors
  }
}

export const switchToBscNetworWalletConnect = async (connector: any) => {
  const chainId = process.env.REACT_APP_POLYGON_CHAIN_ID
  const chainName = PROVIDER_NETWORKS.find((v) => v?.hex === chainId)?.name || ''
  const rpcUrl = process.env.REACT_APP_POLYGON_RPC_URL

  return connector.sendCustomRequest({
    id: 1,
    jsonrpc: '2.0',
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: chainId,
        chainName: chainName,
        nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18,
        },
        rpcUrls: [rpcUrl],
        blockExplorerUrls: chainId === '0x13882' ? ['https://amoy.polygonscan.com/'] : ['https://polygonscan.com/'],
      },
    ],
  })
}

export const getBalance = async (tokenAddress: string, walletAddress: string, connector?: any) => {
  let provider: any
  if (connector) {
    const chainId = process.env.REACT_APP_POLYGON_CHAIN_ID
    const chainName = PROVIDER_NETWORKS.find((v) => v?.hex === chainId)?.name || ''
    const rpcUrl = process.env.REACT_APP_POLYGON_RPC_URL
    provider = await new ethers.providers.StaticJsonRpcProvider(rpcUrl, {
      chainId: Number(chainId),
      name: chainName,
    })
  } else {
    provider = await new ethers.providers.Web3Provider((window as any)?.ethereum)
  }
  const contract = await new ethers.Contract(tokenAddress, MIN_ABI, provider)
  // const balance = await contract.balanceOf(walletAddress)
  // return utils.formatEther(balance)
  return await contract.balanceOf(walletAddress)
}

export const cancelCryptoBookingByHost = async (bookingId: string, hostWallet: string, propertyContract: string) => {
  const provider = new ethers.providers.Web3Provider((window as any)?.ethereum)
  const signer = provider.getSigner(hostWallet)
  const contract = new ethers.Contract(propertyContract, PROPERTY_CONTRACT_ABI, signer)
  return contract.cancelByHost(Number(bookingId))
}
