import { renderPmsLabel } from '../../../utils/user'
import DisconnectPms from '../../connect-pms/DisconnectPms'
import ConnectAirbnbViaHospitable from './ConnectAirbnbViaHospitable'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { countPmsProperty } from 'src/api/listings'
import ic_D_logo from 'src/assets/icons/ic_D_logo.svg'
import ic_airbnb from 'src/assets/icons/ic_airbnb.svg'
import ic_guestsmiles from 'src/assets/icons/ic_guestsmiles.svg'
import ic_guesty from 'src/assets/icons/ic_guesty.svg'
import ic_hostaway_short from 'src/assets/icons/ic_hostaway_short.svg'
import ic_rentalwise from 'src/assets/icons/ic_rentalwise.svg'
import ic_uplisting_logo from 'src/assets/icons/ic_uplisting_logo.svg'
import nextpax_logo from 'src/assets/images/nextpax_logo.webp'
// import DisconnectAirbnb from 'src/components/connect-pms/DisconnectAirbnb'
import SelectPms from 'src/components/settings/pms-and-channel/SelectPms'
import ViewDtravelApi from 'src/components/settings/pms-and-channel/ViewDtravelApi'
import BasicButton from 'src/components/ui/BasicButton'
import { LIST_PMS, SETUP_GUIDE, SETTINGS_SIDE_BAR, PMS_TYPE } from 'src/contants/common'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setIsOpenPopupViewDtravelApi } from 'src/redux/slices/common'

const SettingPmsIntegration = () => {
  const dispatch = useAppDispatch()
  const { pmsTypeConnected } = useAppSelector((state) => state.user)
  const [dataCount, setDataCount] = useState<any>({
    hostaway: 0,
    uplisting: 0,
    guestsmiles: 0,
    guesty: 0,
    rentalwise: 0,
    airbnb: 0,
  })
  const [countHospitableByChannel, setCountHospitableByChannel] = useState<any[]>([])

  useEffect(() => {
    async function fetchCountProperty() {
      try {
        const res = await countPmsProperty()
        if (Array.isArray(res.data) && res.data.length > 0) {
          let countHostaway: number = 0
          let countUplisting: number = 0
          let countGS: number = 0
          let countNextPax: number = 0
          let countGuesty: number = 0
          let countRentalWise: number = 0
          let countAirbnb: number = 0
          for (let item of res.data) {
            if (item.pmsType === PMS_TYPE.HOSTAWAY) {
              countHostaway = item.count
            }
            if (item.pmsType === PMS_TYPE.UPLISTING) {
              countUplisting = item.count
            }
            if (item.pmsType === PMS_TYPE.GUEST_SMILES) {
              countGS = item.count
            }
            if (item.source === PMS_TYPE.NEXTPAX) {
              countNextPax = item.count
            }
            if (item.source === PMS_TYPE.GUESTY) {
              countGuesty = item.count
            }
            if (item.source === PMS_TYPE.RENTAL_WISE) {
              countRentalWise = item.count
            }
            // if (item.channel === PMS_TYPE.HOSPITABLE) {
            //   // airbnb source hospitable
            //   countAirbnb += item.count
            // }
            if (item.channel === PMS_TYPE.AIRBNB) {
              // airbnb source channex
              countAirbnb += item.count
            }
          }
          setDataCount({
            [PMS_TYPE.HOSTAWAY]: countHostaway,
            [PMS_TYPE.UPLISTING]: countUplisting,
            [PMS_TYPE.GUEST_SMILES]: countGS,
            [PMS_TYPE.NEXTPAX]: countNextPax,
            [PMS_TYPE.GUESTY]: countGuesty,
            [PMS_TYPE.RENTAL_WISE]: countRentalWise,
            [PMS_TYPE.HOSPITABLE]: countAirbnb,
            [PMS_TYPE.AIRBNB]: countAirbnb,
          })
          setCountHospitableByChannel(res.data.filter((item) => item.channel === PMS_TYPE.HOSPITABLE))
        }
      } catch (err) {
        console.log(err)
      }
    }

    fetchCountProperty()
  }, [pmsTypeConnected])

  const onClickViewDtravelApi = () => {
    dispatch(setIsOpenPopupViewDtravelApi(true))
  }

  const renderLogo = (pmsType: string) => {
    if (pmsType === PMS_TYPE.HOSTAWAY) {
      return ic_hostaway_short
    }
    if (pmsType === PMS_TYPE.UPLISTING) {
      return ic_uplisting_logo
    }
    if (pmsType === PMS_TYPE.GUEST_SMILES) {
      return ic_guestsmiles
    }
    if (pmsType === PMS_TYPE.NEXTPAX) {
      return nextpax_logo
    }
    if (pmsType === PMS_TYPE.GUESTY) {
      return ic_guesty
    }
    if (pmsType === PMS_TYPE.RENTAL_WISE) {
      return ic_rentalwise
    }
    if (pmsType === PMS_TYPE.AIRBNB || pmsType === PMS_TYPE.HOSPITABLE) {
      return ic_airbnb
    }
    return ''
  }

  return (
    <>
      <h2 id={SETTINGS_SIDE_BAR.CHANNEL_INTEGRATION} className={'font-maison-neue text-20-24 text-neutral-800'}>
        Property Management Software (PMS) Integrations
      </h2>
      <p className={'font-maison-neue text-14-18 text-neutral-500 mt-[4px]'}>
        Connect your property management software to seamlessly create your Dtravel listings
      </p>
      <div
        className={'flex flex-col gap-[24px] w-full bg-white rounded-[16px] mt-[16px] p-[24px]'}
        id={SETUP_GUIDE.IMPORT_LISTING}
      >
        {/*-----Dtravel API-----*/}
        <div className={'flex flex-col md:flex-row gap-[12px] justify-between'}>
          <div className={'flex items-center gap-[12px]'}>
            <span
              className={
                'rounded-full w-[42px] h-[42px] min-w-[42px] border border-neutral-300 flex items-center justify-center'
              }
            >
              <img src={ic_D_logo} alt={'stripe'} className="ml-1" />
            </span>
            <div>
              <p className={'font-maison-neue-medium text-16-20 text-neutral-800 flex items-center'}>
                <span>Dtravel API</span>
              </p>
              <p className={'font-maison-neue text-14-18 text-neutral-600 mt-[4px]'}>
                Use your Dtravel API key to connect to other third-party apps.
              </p>
            </div>
          </div>
          <BasicButton size={'md'} variant={'outlined'} onClick={onClickViewDtravelApi} isRadius100>
            View
          </BasicButton>
        </div>
        <div className={'w-full h-[1px] bg-neutral-300'} />

        {LIST_PMS.map((pmsType) => {
          const viaGuestSmile = pmsType === PMS_TYPE.GUEST_SMILES || pmsType === PMS_TYPE.NEXTPAX
          const isConnected =
            pmsTypeConnected === pmsType ||
            (pmsType === PMS_TYPE.GUESTY && dataCount.guesty > 0) ||
            (pmsType === PMS_TYPE.RENTAL_WISE && dataCount.rentalwise > 0)
          // ((pmsType === PMS_TYPE.AIRBNB || pmsType === PMS_TYPE.HOSPITABLE) && isConnectedAirbnb)
          const count = dataCount[pmsType] || 0
          return (
            <React.Fragment key={pmsType}>
              <div className={`flex flex-col md:flex-row gap-[12px] justify-between ${isConnected ? '' : 'hidden'}`}>
                <div className={'flex items-center gap-[12px]'}>
                  <span
                    className={
                      'flex items-center justify-center w-[48px] h-[48px] min-w-[48px] rounded-full border border-neutral-300'
                    }
                  >
                    <img src={renderLogo(pmsType)} alt="" className="w-[28px] h-[28px] min-w-[28px]" />
                  </span>
                  <div>
                    <div className={'font-maison-neue-medium text-16-20 text-neutral-800'}>
                      <span className={'mr-[8px] capitalize'}>{renderPmsLabel(pmsType)}</span>
                      <span className="font-maison-neue-medium text-forest-6 uppercase text-12-16 bg-forest-1 px-2 py-[1px] rounded-[100px] tracking-[.04em]">
                        {[
                          PMS_TYPE.HOSTAWAY,
                          PMS_TYPE.UPLISTING,
                          PMS_TYPE.GUEST_SMILES,
                          PMS_TYPE.NEXTPAX,
                          // PMS_TYPE.HOSPITABLE,
                        ].includes(pmsType)
                          ? 'CONNECTED'
                          : 'IMPORTED'}
                      </span>
                    </div>
                    {viaGuestSmile && (
                      <p className={'font-maison-neue text-14-18 text-neutral-600 mt-[4px]'}>via GuestSmiles</p>
                    )}
                  </div>
                </div>
                <div className={'flex items-center gap-4'}>
                  <span
                    className={clsx(
                      'flex items-center justify-center font-maison-neue text-14-18 text-neutral-600 h-[40px]',
                      // 'border border-neutral-300 rounded-[100px] shadow-basic px-[16px]',
                      'w-full md:w-auto'
                    )}
                  >
                    {count} {count > 1 ? 'properties' : 'property'}
                  </span>
                  {pmsTypeConnected && pmsType !== PMS_TYPE.AIRBNB && pmsType !== PMS_TYPE.HOSPITABLE && (
                    <DisconnectPms />
                  )}
                  {/*{(pmsType === PMS_TYPE.AIRBNB || pmsType === PMS_TYPE.HOSPITABLE) && isConnectedAirbnb && (*/}
                  {/*  <DisconnectAirbnb />*/}
                  {/*)}*/}
                  {/*{isHA ? <DisconnectHostaway /> : isGS ? <DisconnectGS /> : isUplisting ? <DisconnectUplisting /> : ''}*/}
                </div>
              </div>
              <div className={`w-full h-[1px] bg-neutral-300 ${isConnected ? '' : 'hidden'}`} />
            </React.Fragment>
          )
        })}

        <ConnectAirbnbViaHospitable countHospitable={countHospitableByChannel} countAirbnb={dataCount.airbnb} />

        <div className={'w-full h-[1px] bg-neutral-300'} />

        <SelectPms
          title={'Import your listings:'}
          countProperty={dataCount}
          maxWidth={320}
          otherLabel={'Request a PMS'}
          disabledOptionIfConnected
        />
        <ViewDtravelApi />
      </div>
    </>
  )
}

export default SettingPmsIntegration
