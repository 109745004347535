import { useAppSelector } from '../../redux/hooks'
import { convertPriceByCurrency, convertPriceWithCurrency } from '../../utils/common'
import IconButton from '@mui/material/IconButton'
import clsx from 'clsx'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import ic_arrow_left from 'src/assets/icons/ic_arrow_left_16_16.svg'
import ic_arrow_right from 'src/assets/icons/ic_arrow_right_16_16.svg'
import LoadingSection from 'src/components/common/LoadingSection'
import TooltipCalendar from 'src/components/ui/TooltipCalendar'
import { DATE_FORMAT, MONTH_FORMAT } from 'src/contants/common'

interface Props {
  month?: string // format YYYY-MM
  isShowDoubleMonth?: boolean
  startDate: Moment | null
  endDate: Moment | null
  onDatesChange?: (startDate: Moment | null, endDate: Moment | null) => void
  dataCalendar: any // object
  onNextAndPrev?: (type: 'next' | 'prev', newMonth: Moment) => void
  isLoading?: boolean
  // isShowTooltipBookedDate?: boolean
  priceData?: any
  hiddenPrice?: boolean
}

const BasicCalendar: React.FC<Props> = ({
  month,
  isShowDoubleMonth,
  onDatesChange,
  startDate,
  endDate,
  dataCalendar,
  onNextAndPrev,
  isLoading,
  priceData,
  hiddenPrice,
}) => {
  const [currentMonth, setCurrentMonth] = useState<Moment>(moment())
  const [dateFrom, setDateFrom] = useState<Moment | null>(null)
  const [dateTo, setDateTo] = useState<Moment | null>(null)
  const [datesBetweenHoveredAndFrom, setDatesBetweenHoveredAndFrom] = useState<string[]>([])
  const [currency, setCurrency] = useState<string>('')
  const [pricePerDate, setPricePerDate] = useState<any>({})
  const { rateObjBaseOnUsd } = useAppSelector((state) => state.common)

  useEffect(() => {
    setDateFrom(startDate)
    setDateTo(endDate)
  }, [startDate, endDate])

  useEffect(() => {
    setCurrentMonth(month ? moment(month, MONTH_FORMAT) : moment())
  }, [month])

  useEffect(() => {
    if (!dateFrom) {
      setDatesBetweenHoveredAndFrom([])
    }
  }, [dateFrom])

  useEffect(() => {
    // Rate rule hierarchy when multiple rates are set for the same date
    // Custom rates (per date range) -- this would override any base or day of week rate.
    // Base rate - Day of week rates -- would only override the baserate
    // Base rate -- default rate if no other rules apply
    if (priceData) {
      const { basePrice, currency, priceDayOfWeek, priceWeekdayInRange } = priceData
      const firstDayCurrentMonth = moment(currentMonth).startOf('months')
      const endDayNextMonth = moment(currentMonth).add(1, 'month').endOf('months')
      const diffDay = endDayNextMonth.diff(firstDayCurrentMonth, 'days')
      let dataPrice: any = {}

      // get array dates to detect dates between startDate, endDate
      const arrDatesFromTo: Array<string> = []
      if (dateFrom && dateTo) {
        const diffDay = dateTo.diff(dateFrom, 'days')
        for (let i = 0; i <= diffDay; i++) {
          const dateFormated = moment(dateFrom).add(i, 'days').format(DATE_FORMAT)
          arrDatesFromTo.push(dateFormated)
        }
      }

      for (let i = 0; i <= diffDay; i++) {
        const momentDay = moment(firstDayCurrentMonth).add(i, 'day').startOf('days')
        const key = momentDay.format(DATE_FORMAT) // date formated: YYYY-MM-DD
        const dayOfWeekName = momentDay.isoWeekday(momentDay.isoWeekday()).format('dddd').toLowerCase() // sunday, monday, tuesday, wednesday, thursday, friday, saturday
        dataPrice[key] = basePrice // set price for each day: basePrice
        if (priceDayOfWeek) {
          // set price for each day: price from config per day of week
          for (let dayName in priceDayOfWeek) {
            if (dayName === dayOfWeekName && priceDayOfWeek[dayName] !== undefined) {
              dataPrice[key] = priceDayOfWeek[dayName]
            }
          }
        }
        if (dataCalendar && Object.prototype.hasOwnProperty.call(dataCalendar, key) && dataCalendar[key]) {
          // set price for each day: price from calendar
          if (dataCalendar[key]['price'] !== 0) {
            dataPrice[key] = convertPriceByCurrency(
              rateObjBaseOnUsd,
              dataCalendar[key]['currency'],
              currency,
              dataCalendar[key]['price']
            )
          }
        }

        if (arrDatesFromTo.includes(key)) {
          // set price for each day: price from startDate, endDate
          if (priceWeekdayInRange[dayOfWeekName] !== undefined) {
            dataPrice[key] = priceWeekdayInRange[dayOfWeekName]
          }
        }
      }
      setPricePerDate(dataPrice)
      setCurrency(currency)
    }
  }, [currentMonth, dateFrom, dateTo, priceData, month, currency, rateObjBaseOnUsd, dataCalendar])

  const renderDaysOfWeek = () => {
    return moment.weekdaysShort().map((item) => {
      return (
        <div className={'w-[46px] text-center'} key={item}>
          {item}
        </div>
      )
    })
  }

  const getDaysByMonth = (_currentMonth: Moment) => {
    const firstDayOfMonth = Number(_currentMonth.startOf('month').format('d')) // 0-> 6
    const numberDaysInMonth = _currentMonth.daysInMonth() // 28 | 30 | 31 days
    const monthFormated = _currentMonth.format(MONTH_FORMAT)

    let blanks = []
    for (let i = 0; i < firstDayOfMonth; i++) {
      blanks.push({})
    }
    let _daysInMonth = []
    for (let d = 1; d <= numberDaysInMonth; d++) {
      const dateFormated = `${monthFormated}-${d > 9 ? d : '0' + d}`
      _daysInMonth.push({
        day: d,
        date: new Date(dateFormated),
        moment: moment(dateFormated, DATE_FORMAT),
        dateFormated: dateFormated,
      })
    }
    let totalSlots = [...blanks, ..._daysInMonth]
    let rows: any[] = []
    let cells: any[] = []
    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row) // if index not equal 7 that means not go to next week
      } else {
        rows.push(cells) // when reach next week we contain all td in last week to rows
        cells = [] // empty container
        cells.push(row) // in current loop we still push current row to new container
      }
      if (i === totalSlots.length - 1) {
        // when end loop we add remain date
        rows.push(cells)
      }
    })
    return rows
  }

  const handleClickPrevAndNext = async (type: 'next' | 'prev') => {
    const addedMonth = type === 'prev' ? -1 : 1
    const newMonth = moment(currentMonth).add(addedMonth, 'months')
    if (typeof onNextAndPrev === 'function') {
      await onNextAndPrev(type, newMonth)
    }
    setCurrentMonth((prevState) => {
      return moment(prevState).add(addedMonth, 'months')
    })
  }

  const isDateBetweenFromAndTo = (dateFormated: string) => {
    const date = moment(dateFormated, DATE_FORMAT).startOf('days')
    if (dateFrom && dateTo) {
      return dateFrom.isBefore(date) && date.isBefore(dateTo)
    }
    return false
  }

  const handleClickDate = (dateFormated: string) => {
    const selectedDate = moment(dateFormated, DATE_FORMAT).startOf('days')
    if ((dateFrom && dateTo) || (!dateFrom && !dateTo)) {
      setDateFrom(selectedDate)
      setDateTo(null)
      if (typeof onDatesChange === 'function') {
        onDatesChange(selectedDate, null)
      }
    }
    if (dateFrom && !dateTo) {
      const isBeforeDateFrom = selectedDate.isBefore(dateFrom)
      if (isBeforeDateFrom) {
        setDateFrom(selectedDate)
        if (typeof onDatesChange === 'function') {
          onDatesChange(selectedDate, null)
        }
      } else {
        setDateTo(selectedDate)
        setDatesBetweenHoveredAndFrom([]) // clear date have red border
        if (typeof onDatesChange === 'function') {
          onDatesChange(dateFrom, selectedDate)
        }
      }
    }
  }

  const handleHoverDate = (dateFormated: string) => {
    const hoveredDate = moment(dateFormated, DATE_FORMAT)
    const isAfterDateFrom = hoveredDate.isAfter(dateFrom)
    if (dateFrom && !dateTo && isAfterDateFrom) {
      const diffDay = hoveredDate.diff(dateFrom, 'days')
      const _list: string[] = []
      for (let i = 1; i < diffDay; i++) {
        _list.push(moment(dateFrom).add(i, 'days').format(DATE_FORMAT))
      }
      setDatesBetweenHoveredAndFrom(_list)
    } else {
      setDatesBetweenHoveredAndFrom([])
    }
  }

  const renderDate = (
    d: any,
    { isSelectedDate, isGoingToSelect, isPastDate, isShowPrice, isBlocked, isReserved }: any
  ) => {
    return (
      <button
        className={clsx('w-[46px] h-full flex flex-col items-center justify-center rounded-[4px]', {
          'hover:bg-red-300 text-neutral-900': !isSelectedDate,
          'bg-red-700 text-white': isSelectedDate,
          'border border-red-700': isGoingToSelect,
          'bg-neutral-300 text-neutral-500': (isBlocked || isReserved) && !isSelectedDate,
        })}
        data-date={d.dateFormated}
        onClick={() => handleClickDate(d.dateFormated)}
        onMouseEnter={() => handleHoverDate(d.dateFormated)}
        disabled={isPastDate}
      >
        <span className={'text-14-18'}>{d.day}</span>
        {!hiddenPrice && isShowPrice && (
          <span
            className={clsx('text-10-12 text-neutral-500', {
              'text-red-300': isSelectedDate,
            })}
          >
            {convertPriceWithCurrency(pricePerDate[d.dateFormated], currency)}
          </span>
        )}
      </button>
    )
  }

  const renderPastDate = (day: string, dateFormated: string) => {
    return (
      <button
        className={clsx('w-[46px] h-full flex items-center justify-center rounded-[4px] text-14-18', {
          'line-through cursor-not-allowed text-neutral-500': true,
        })}
        onMouseEnter={() => handleHoverDate(dateFormated)}
        data-date={dateFormated}
      >
        {day}
      </button>
    )
  }

  const renderDaysOfMonth = (_currentMonth: Moment, showPrev: boolean, showNext: boolean) => {
    const monthFormated = moment(_currentMonth).format(MONTH_FORMAT)
    return (
      <div>
        <div
          className={
            'w-full h-[40px] flex items-center justify-between font-maison-neue-demibold text-14-18 text-neutral-900 px-[6px]'
          }
        >
          <div>
            {showPrev ? (
              <IconButton size={'small'} onClick={() => handleClickPrevAndNext('prev')}>
                <img src={ic_arrow_left} alt={'ic_arrow_left'} width={'16px'} height={'16px'} />
              </IconButton>
            ) : (
              ''
            )}
          </div>
          <div>{_currentMonth.format('MMMM YYYY')}</div>
          <div>
            {showNext ? (
              <IconButton size={'small'} onClick={() => handleClickPrevAndNext('next')}>
                <img src={ic_arrow_right} alt={'ic_arrow_right'} width={'16px'} height={'16px'} />
              </IconButton>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className={'flex gap-[4px] font-maison-neue-medium text-14-18 mb-[4px]'}>{renderDaysOfWeek()}</div>
        <div className={'flex flex-col gap-[4px]'}>
          {getDaysByMonth(_currentMonth).map((row, index) => {
            if (row.length === 0) return null
            return (
              <div key={`row_${index}_${monthFormated}`} className={'w-auto h-[42px] flex gap-[4px] items-center'}>
                {row.map((d: any, rowIndex: number) => {
                  const isPastDate =
                    d.dateFormated &&
                    moment(d.dateFormated, DATE_FORMAT).startOf('days').isBefore(moment().startOf('days'))
                  let isBlocked = false
                  let isReserved = false
                  if (dataCalendar && Object.prototype.hasOwnProperty.call(dataCalendar, d.dateFormated)) {
                    const dateInCalendar = dataCalendar[d.dateFormated]
                    isBlocked = !dateInCalendar.isAvailable && dateInCalendar.status === 'blocked'
                    isReserved = !dateInCalendar.isAvailable && dateInCalendar.status === 'reserved'
                  }
                  const isFromOrToDate =
                    (d.dateFormated && d.dateFormated === dateFrom?.format(DATE_FORMAT)) ||
                    (d.dateFormated && d.dateFormated === dateTo?.format(DATE_FORMAT))
                  const isBetween = isDateBetweenFromAndTo(d.dateFormated)
                  const isSelectedDate = isFromOrToDate || isBetween
                  const isGoingToSelect = datesBetweenHoveredAndFrom.includes(d.dateFormated)
                  const isShowPrice = !!d.dateFormated && !!priceData && pricePerDate[d.dateFormated] >= 0
                  if (isPastDate) {
                    return (
                      <React.Fragment key={d.dateFormated ? d.dateFormated : `row_${index}_${rowIndex}`}>
                        {renderPastDate(d.day, d.dateFormated)}
                      </React.Fragment>
                    )
                  }
                  if (isReserved || isBlocked) {
                    return (
                      <TooltipCalendar
                        key={d.dateFormated ? d.dateFormated : `row_${index}_${rowIndex}`}
                        arrow
                        placement="top"
                        title={'This date is blocked'}
                      >
                        {renderDate(d, {
                          isSelectedDate,
                          isGoingToSelect,
                          isPastDate,
                          isShowPrice,
                          isBlocked,
                          isReserved,
                        })}
                      </TooltipCalendar>
                    )
                  }
                  return (
                    <React.Fragment key={d.dateFormated ? d.dateFormated : `row_${index}_${rowIndex}`}>
                      {renderDate(d, { isSelectedDate, isGoingToSelect, isPastDate, isShowPrice })}
                    </React.Fragment>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className={'flex gap-[24px] relative'}>
      <LoadingSection isLoading={isLoading} />
      {isShowDoubleMonth ? (
        <>
          {renderDaysOfMonth(currentMonth, true, false)}
          {renderDaysOfMonth(moment(currentMonth).add(1, 'months'), false, true)}
        </>
      ) : (
        <>{renderDaysOfMonth(currentMonth, true, true)}</>
      )}
    </div>
  )
}

export default BasicCalendar
