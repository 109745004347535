import useWindowDimensions from '../../hooks/useWindowDimensions'
import { IconSearch } from '../common/Icons'
import BasicInput from '../ui/BasicInput'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import React, { useEffect, useRef, useState } from 'react'
import ic_close from 'src/assets/icons/ic_close.svg'
import ic_search_input from 'src/assets/icons/ic_search_input.svg'

interface Props {
  filters: any
  handleChangeSearchAndPaging: (field: string, value: string) => void
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PropertySearch: React.FC<Props> = ({ filters, handleChangeSearchAndPaging }) => {
  const timer = useRef<any>()
  const inputMobile = useRef<any>(null)
  const [isOpenSearchMobile, setIsOpenSearchMobile] = useState<boolean>(false)
  const [search, setSearch] = useState<string>(filters.search || '')
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768

  useEffect(() => {
    setSearch(filters.search || '')
  }, [filters.search])

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearch(value)
    // debounce
    if (timer.current) clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      handleChangeSearchAndPaging('search', value)
    }, 800)
  }

  const handleClearSearch = () => {
    setSearch('')
    handleChangeSearchAndPaging('search', '')
  }

  const handleOpenSearchMobile = () => {
    setIsOpenSearchMobile(true)
    setTimeout(() => {
      inputMobile.current.focus()
    }, 200)
  }

  const handleCloseSearchMobile = () => {
    setIsOpenSearchMobile(false)
  }

  return (
    <>
      <div className={'bg-neutral-100 z-10'}>
        {isMobile ? (
          <div className={''}>
            <button className={'w-[25%]'} onClick={handleOpenSearchMobile}>
              <img src={ic_search_input} alt={'ic_search_input'} className={'min-w-[24px]'} />
            </button>
          </div>
        ) : (
          <div className={'flex items-center justify-between gap-[8px] w-full h-[48px]'}>
            <div className={'flex items-center gap-[24px]'}>
              <div className={'relative w-full'}>
                <BasicInput
                  placeholder={'Search'}
                  placeholderClasses={'text-neutral-500'}
                  classes={
                    'border-none placeholder:text-neutral-500 font-maison-neue-medium text-16-20 text-neutral-800 w-full shadow-none w-[250px] lg:w-[320px] pl-[48px]'
                  }
                  value={search}
                  onChange={handleChangeSearch}
                />
                <span className={'absolute left-[16px] top-[12px]'}>
                  <IconSearch />
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      <Dialog
        open={isOpenSearchMobile}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseSearchMobile}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        sx={{ '& .MuiDialog-paper': { width: '100%', margin: '0px' }, '& .MuiDialog-container': { height: '40%' } }}
      >
        <div className={'w-full h-[64px] flex gap-[8px] items-center px-[16px]'}>
          <img src={ic_search_input} alt={'ic_search_input'} />
          <input
            ref={inputMobile}
            className={
              'w-full h-full border-none font-maison-neue-medium text-neutral-8 text-14-18 placeholder:text-neutral-6 focus:outline-none'
            }
            placeholder={'Listing title goes here...'}
            value={search}
            onChange={handleChangeSearch}
          />

          {search && (
            <button className={'p-[8px] active:bg-neutral-3 rounded-full'} onClick={handleClearSearch}>
              <img src={ic_close} alt={'ic_close'} />
            </button>
          )}
        </div>
      </Dialog>
    </>
  )
}

export default PropertySearch
