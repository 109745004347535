import { ReservationStatusEnum } from '../../interfaces/reservations'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import BasicCardV2 from 'src/components/common/BasicCardV2'
import BasicButton from 'src/components/ui/BasicButton'
import { PMS } from 'src/contants/common'
import { RESERVATION_STATUS, SUPERHOG_STATUS, SUPER_HOG_LINK_TO_CLAIM } from 'src/contants/property'
import CancelReservationGuestScreening from './CancelReservationGuestScreening'

interface Props {
  data: any
  handleChangeStatus: (callback?: () => void) => void
}

const ReservationRiskManagement: React.FC<Props> = ({ data, handleChangeStatus }) => {
  const {
    superHogStayState,
    superHogVerifyStatus,
    superHogSetting,
    checkinDate,
    timezone,
    pmsType,
    reservationId,
    status: reservationStatus,
    payoutStatus,
    paymentMethod,
    hostWallet,
    guestWallet,
    propertyContract,
  } = data
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isShowCancel, setIsShowCancel] = useState<boolean>(false)
  const [isEnableFileAClaim, setIsEnableFileAClaim] = useState<boolean>(false)

  useEffect(() => {
    setIsEnableFileAClaim(
      superHogVerifyStatus === true &&
      superHogSetting === SUPERHOG_STATUS.KYG_DAMAGE &&
      reservationStatus !== ReservationStatusEnum.CANCELLED
    )
  }, [superHogVerifyStatus, superHogSetting, reservationStatus])

  useEffect(() => {
    const isBeforeCheckIn = moment().startOf('days').isBefore(momentTZ.tz(checkinDate, timezone).startOf('days'))
    const isFailedSuperHog = typeof superHogVerifyStatus === 'boolean' && superHogVerifyStatus === false
    const pmsIsDtravelOrOpenApi = pmsType !== PMS.UPLISTING
    const isValidStatus =
      reservationStatus === RESERVATION_STATUS.NEW || reservationStatus === RESERVATION_STATUS.MODIFIED
    const isNotPayout = !payoutStatus || payoutStatus.toUpperCase() !== 'PAYOUT'
    setIsShowCancel(isBeforeCheckIn && isFailedSuperHog && pmsIsDtravelOrOpenApi && isValidStatus && isNotPayout)
  }, [checkinDate, timezone, pmsType, superHogVerifyStatus, superHogStayState, reservationStatus, payoutStatus])

  const handleOpenPopupCancel = () => {
    setIsOpen(true)
  }

  const handleClosePopupCancel = () => {
    setIsOpen(false)
  }

  const renderSuperHogVerifyStatus = () => {
    const isNotKygSuperHog = superHogStayState === null && superHogVerifyStatus === null
    const isPendingReview = superHogStayState === 'NotApproved' && superHogVerifyStatus === null
    const isPassed = superHogVerifyStatus === true
    // const isFailed = superHogVerifyStatus === false
    let classes = isNotKygSuperHog
      ? 'text-neutral-700 bg-neutral-100'
      : isPendingReview
        ? 'text-sun-700 bg-sun-50'
        : isPassed
          ? 'text-forest-700 bg-forest-50'
          : 'text-red-700 bg-red-50'

    return (
      <span
        className={
          'px-[10px] py-[2px] font-maison-neue-demibold text-12-16 rounded-[16px] tracking-[0.04em] uppercase' +
          ' ' +
          classes
        }
      >
        {isNotKygSuperHog ? 'N/A' : isPendingReview ? 'pending review' : isPassed ? 'Passed' : 'Failed'}
      </span>
    )
  }
  return (
    <BasicCardV2 title={'Risk Management'}>
      <div className={'flex flex-col md:flex-row justify-between items-start md:items-center gap-[16px] md:gap-[24px]'}>
        <div className={'flex items-center gap-[8px]'}>
          <span className={'font-maison-neue-medium text-16-20 text-neutral-800'}>Guest Screening</span>
          {renderSuperHogVerifyStatus()}
        </div>
        {isShowCancel && (
          <BasicButton
            variant={'outlined'}
            color={'black'}
            size={'md'}
            isRadius100={true}
            clases={'w-full md:w-auto whitespace-nowrap'}
            onClick={handleOpenPopupCancel}
          >
            Cancel reservation
          </BasicButton>
        )}
      </div>

      <div className={'my-[16px] w-full h-[1px] bg-neutral-300'} />

      <div className={'flex flex-col md:flex-row justify-between items-start md:items-center gap-[16px] md:gap-[24px]'}>
        <div>
          <p className={'font-maison-neue-medium text-16-20 text-neutral-800 mb-[8px]'}>Damage Protection Plan</p>
          <p className={'font-maison-neue text-16-20 text-neutral-600'}>
            The window to file a resolution for damage protection plan is limited to 10 days following the guest’s
            checkout.
          </p>
        </div>
        <BasicButton
          variant={'outlined'}
          color={'black'}
          size={'md'}
          isRadius100={true}
          clases={'w-full md:w-auto whitespace-nowrap'}
          disabled={!isEnableFileAClaim}
          href={SUPER_HOG_LINK_TO_CLAIM}
          target={'_blank'}
        >
          File a resolution
        </BasicButton>
      </div>

      <CancelReservationGuestScreening
        isOpen={isOpen}
        onClose={handleClosePopupCancel}
        reservationId={reservationId}
        paymentMethod={paymentMethod}
        hostWallet={hostWallet}
        guestWallet={guestWallet}
        propertyContract={propertyContract}
        handleChangeStatus={handleChangeStatus}
        reservationChainId={data?.chainId}
      />
    </BasicCardV2>
  )
}

export default ReservationRiskManagement