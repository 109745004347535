import { FormError } from '../../interfaces'
import Error from './Error'
import axios from 'axios'
import clsx from 'clsx'
import { CountryCode } from 'libphonenumber-js/types'
import React, { useEffect, useRef, useState } from 'react'
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input'

interface Props {
  phone: string
  onChange: any
  handleFocus?: any
  handleBlur?: any
  defaultCountry?: CountryCode
  error?: FormError
}

const InputPhoneNumber: React.FC<Props> = ({ phone, onChange, handleBlur, handleFocus, defaultCountry, error }) => {
  const trackHasCountry = useRef<any>(null)
  const [isFocus, setFocus] = useState<boolean>(false)
  const [country, setCountry] = useState<CountryCode>(
    localStorage.getItem('countryCode') ? (localStorage.getItem('countryCode')?.toUpperCase() as CountryCode) : 'US'
  )

  async function fetchCountry(_defaultCountry?: CountryCode) {
    try {
      const res = await axios
        .get('https://ipinfo.io', {
          headers: {
            Authorization: 'Bearer 3365590177ae18',
          },
        })
        .then((res) => res.data)
      if (_defaultCountry) setCountry(_defaultCountry)
      if (res.country) {
        setTimeout(() => {
          if (!trackHasCountry.current) {
            setCountry(res.country)
            localStorage.setItem('countryCode', res.country)
          }
        }, 200)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (defaultCountry) {
      trackHasCountry.current = true
      setCountry(defaultCountry)
    } else {
      trackHasCountry.current = false
      fetchCountry(defaultCountry)
    }
    // fetchCountry(defaultCountry)
  }, [defaultCountry])

  return (
    <div className="relative">
      <span className="text-12-16 text-neutral-600 absolute top-[14px] left-[92px] font-maison-neue tracking-[.48px]">
        PHONE NUMBER
      </span>
      {country && (
        <span className="text-16-20 text-neutral-700 absolute top-[32px] left-[92px]">
          (+{getCountryCallingCode(country)})
        </span>
      )}
      <PhoneInput
        countrySelectProps={{
          onFocus: () => {
            setFocus(true)
          },
          onBlur: () => {
            setFocus(false)
          },
        }}
        defaultCountry={country}
        onCountryChange={(countryValue: CountryCode) => {
          setCountry(countryValue)
        }}
        countryCallingCodeEditable={false}
        placeholder="Phone number"
        value={phone}
        onChange={onChange}
        initialValueFormat="national"
        onFocus={(inputFocusProps: any) => {
          setFocus(true)
          if (handleFocus) handleFocus(inputFocusProps)
        }}
        onBlur={(inputBlurProps: any) => {
          setFocus(false)
          if (handleBlur) handleBlur(inputBlurProps)
        }}
        className={clsx('rounded-2xl pr-6 font-maison-neue bg-white', {
          'border border-red-700 hover:border-red-700': error?.show && !isFocus,
          'border border-neutral-300 hover:text-neutral-900 hover:border-neutral-800': !error?.show && !isFocus,
          'border border-red-700': isFocus && error?.show,
          'border border-neutral-800': isFocus && !error?.show,
        })}
        addInternationalOption={false}
      />
      {error && <Error {...error} />}
    </div>
  )
}
export default InputPhoneNumber
