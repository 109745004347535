import { cancelFiatBooking, getCustomerInfoFiatPayment } from '../../api/reservation'
import React, { useEffect, useState } from 'react'
import BasicButton from 'src/components/ui/BasicButton'
// import BasicDialog from 'src/components/ui/BasicDialog'
import { useAppDispatch } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { getSymbolCurrencyByKey, handleErrorMessage, isEmpty, numberWithCommas } from 'src/utils/common'
import { ReservationStatusEnum } from 'src/interfaces/reservations'
import ic_arrow_right from 'src/assets/icons/ic_arrow_right_16_16.svg'
import ic_stripe from 'src/assets/icons/ic_stripe_circle.svg'

interface Props {
  data: any
  isNoRefund?: boolean
  handleChangeStatus: (callback?: () => void) => void
  handleClose: any
  handleOpenInsufficientBalance: any
}

const CancelReservationFiat: React.FC<Props> = ({
  data,
  isNoRefund,
  handleChangeStatus,
  handleClose,
  handleOpenInsufficientBalance
}) => {
  const { reservationId, priceInfoV2, currency, source } = data
  const isManualReservation = source === 'manual'
  const isInquiry = data?.status === ReservationStatusEnum.INQUIRY
  const isInquiryManual = isManualReservation && isInquiry

  const dispatch = useAppDispatch()
  const [userPaymentInfo, setUserPaymentInfo] = useState<any>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorStripe, setErrorStripe] = useState<boolean>(false)

  useEffect(() => {
    async function fetchCustomerInfoFiatPayment() {
      try {
        const res = await getCustomerInfoFiatPayment(reservationId)
        setUserPaymentInfo(res?.data)
      } catch (error: any) {
        if (error.message === "Stripe account disconnected") setErrorStripe(true)
      }
    }
    if (errorStripe) setErrorStripe(false)
    fetchCustomerInfoFiatPayment()
  }, [])

  const handleCancelReservation = async () => {
    try {
      setIsLoading(true)
      await cancelFiatBooking(reservationId)
      handleChangeStatus(() => {
        dispatch(setToastSuccess({ message: `Your reservation ${reservationId} has been cancelled.` }))
        setIsLoading(false)
        handleClose() // close popup
      })
    } catch (err: any) {
      console.log(err)
      if (err?.error?.message === "insufficient balance") {
        handleOpenInsufficientBalance()
        handleClose() // close popup
      } else {
        handleErrorMessage(err)
      }
    } finally {
      setIsLoading(false)
    }

  }

  const showPrice = () => {
    const currencySymbol = getSymbolCurrencyByKey(currency)
    const isSpecialCurrency = currency === 'VND' || currency === 'KRW'
    const numberCommas = isSpecialCurrency ? 0 : 2
    const price = Number(isInquiryManual ? data?.finalPrice : priceInfoV2?.finalPrice?.[currency || 'USD'] || 0)
    const amount = Number(price).toFixed(numberCommas)
    const amountToString = String(Number(amount) < 0 ? -1 * Number(amount) : Number(amount))
    const arrSplit = amountToString.split('.')
    if (arrSplit[1]) {
      // case số thập phân, có phần nguyên
      if (arrSplit[1].length === 1) {
        arrSplit[1] = arrSplit[1] + '0'
      } else {
        // case có từ 2 chữ số sau dấu phẩy trở lên => GIỮ NGUYÊN
      }
    } else {
      // case số nguyên, ko có phần thập phân sau dấu phẩy
      arrSplit[1] = isSpecialCurrency ? '' : '00'
    }
    let newAmount = isNoRefund ? 0 : arrSplit.filter((v) => v !== '').join('.')
    return `<b>${currencySymbol}${numberWithCommas(newAmount)}</b>`
  }

  const renderCardInfo = () => {
    let brandName = userPaymentInfo?.card?.brand || ''
    if (brandName?.toLowerCase() === 'jcb') brandName = 'JCB'
    if (brandName?.toLowerCase() === 'amex') brandName = 'American Express'
    if (brandName?.toLowerCase() === 'diners') brandName = 'Diners Club'
    if (brandName?.toLowerCase() === 'unionpay') brandName = 'UnionPay'

    let type = userPaymentInfo?.type || ''
    const isSpecialStripe = type === 'affirm' || type === 'afterpay_clearpay' || type === 'klarna'
    if (type === 'affirm') brandName = 'Affirm'
    if (type === 'afterpay_clearpay') brandName = 'Afterpay'
    if (type === 'klarna') brandName = 'Klarna'
    return (
      <div className='px-[14px] py-[10px] rounded-[12px] border-[0.5px] border-[rgba(0,0,0,0.15)] flex items-center gap-2'>
        <img
          src={
            !isEmpty(brandName) && brandName?.toLowerCase() !== 'unknown' ?
              `https://static.dtravel.com/payment/${brandName?.toLowerCase()}.png` :
              ic_stripe
          }
          alt=""
          className='w-auto h-[24px]'
        />
        <span className={`text-16-20 text-neutral-800 font-maison-neue ${isSpecialStripe ? '' : 'capitalize'}`}>
          {isSpecialStripe ?
            `${userPaymentInfo?.billingDetails?.email}` :
            `${brandName} ${!isEmpty(userPaymentInfo?.card?.last4) ? `**** ${userPaymentInfo?.card?.last4}` : ''}`
          }
        </span>
      </div>
    )
  }
  if (errorStripe) {
    return (
      <div>
        <p className='text-16-24 font-maison-neue text-neutral-800 mb-6 custom-text text-center'>
          This reservation cannot be canceled since you disconnected Stripe account on Dravel. Please re-connect Stripe account again to cancel it.
        </p>
        <div className='w-full flex flex-col gap-4'>
          <BasicButton
            variant={'contained'}
            color={'red'}
            isRadius100={true}
            clases={'w-full'}
            onClick={() => {
              setErrorStripe(false)
              handleClose()
            }}
          >
            Close
          </BasicButton>
        </div>
      </div>
    )
  }
  return (
    <>
      <div>
        <p
          className='text-16-24 font-maison-neue text-neutral-800 text-center mb-6 custom-text'
          dangerouslySetInnerHTML={{ __html: `By canceling this reservation, you’ll refund ${showPrice()} to your guest credit card. Refunds are processed within 5-10 business days.` }}
        />
        <div className='flex flex-col gap-2 mb-6'>
          <p className='text-14-18 font-maison-neue-medium text-neutral-800'>From</p>
          <div className='px-[14px] py-[10px] rounded-[12px] border-[0.5px] border-[rgba(0,0,0,0.15)] flex items-center justify-between'>
            <div className='flex items-center gap-2'>
              <img src={ic_stripe} alt="" />
              <span className='text-16-20 font-maison-neue text-neutral-800'>{userPaymentInfo?.stripeConnected?.displayName || userPaymentInfo?.stripeConnected?.platformAccount}</span>
            </div>
            <div className='flex items-center'>
              <p
                className='text-16-20 font-maison-neue text-neutral-500 w-[160px] whitespace-nowrap overflow-hidden text-ellipsis'
                title={userPaymentInfo?.stripeConnected?.email}
              >
                {userPaymentInfo?.stripeConnected?.email}
              </p>
            </div>
          </div>
          <div className='flex items-center justify-center w-full'>
            <img src={ic_arrow_right} alt="" className='rotate-[90deg]' />
          </div>
          {renderCardInfo()}
        </div>
        <p className='text-16-24 font-maison-neue text-neutral-800 text-center mb-6'>
          Once the transaction is initiated, your guest will be notified of the cancelation and the stay dates will be re-opened for booking.
        </p>
        <div className='w-full flex flex-col gap-4'>
          <BasicButton
            variant={'contained'}
            color={'red'}
            isRadius100={true}
            clases={'w-full'}
            onClick={handleCancelReservation}
            loading={isLoading}
          >
            Initiate refund
          </BasicButton>
          <BasicButton
            variant={'outlined'}
            color={'black'}
            isRadius100={true}
            clases={'w-full'}
            onClick={handleClose}
          >
            Cancel
          </BasicButton>
        </div>
      </div>

    </>
  )
}

export default CancelReservationFiat
