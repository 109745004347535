import AddCustomRate from './AddCustomRate'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import clsx from 'clsx'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { updateBulkProperties, updateRates } from 'src/api/native-listing'
import ic_chevron_up_000 from 'src/assets/icons/ic_chevron_up_000.svg'
import ic_delete_trash from 'src/assets/icons/ic_delete_trash.svg'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import { DATE_FORMAT } from 'src/contants/common'
import { useAppDispatch } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { getSymbolCurrencyByKey, handleErrorMessage, isEmpty, numberWithCommas } from 'src/utils/common'
import { getAndSortDaysOfWeek } from 'src/utils/property'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import EditBulkConfirmDialog from '../EditBulkConfirmDialog'

interface Props {
  propertyId: string
  currency: string
  basePrice: number | undefined
  dateRanges: any[]
  updateDateRanges: (newRanges: any[]) => void
  fetchRates: (id: string) => void
  priceDayOfWeek: any
  minPrice: number
  maxPrice: number
  fetchStatusStep: any
  handleCloseEdit?: any
}

const RateCustom: React.FC<Props> = ({
  propertyId,
  currency,
  basePrice,
  dateRanges,
  updateDateRanges,
  fetchRates,
  priceDayOfWeek,
  minPrice,
  maxPrice,
  fetchStatusStep,
  handleCloseEdit
}) => {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const [indexExpanded, setIndexExpanded] = useState<number[]>([])
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false)
  const [isOpenPopupDelete, setIsOpenPopupDelete] = useState<boolean>(false)
  const [indexDelete, setIndexDelete] = useState<number>(-1)
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false)
  const [symbolCurrency, setSymbolCurrency] = useState<string>('$')
  const [isResetCalendar, setIsResetCalendar] = useState<boolean>(false)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)

  useEffect(() => {
    setSymbolCurrency(getSymbolCurrencyByKey(currency))
  }, [currency])

  const handleOpenPopup = () => {
    setIsOpenPopup(true)
  }

  const handleClosePopup = () => {
    setIsOpenPopup(false)
  }

  const handleToggleRow = (index: number) => {
    setIndexExpanded((prevState) => {
      if (prevState.includes(index)) {
        return prevState.filter((item) => item !== index)
      }
      return [...prevState, index]
    })
  }

  const handleClickDelete = (_index: number) => {
    // if (isEditBulk) {
    //   const newDateRanges = [...dateRanges]
    //   newDateRanges.splice(indexDelete, 1) // remove this range from dateRanges by index
    //   updateDateRanges(newDateRanges)
    // } else {
    //   handleOpenPopupDelete(_index)
    // }
    handleOpenPopupDelete(_index)
  }

  const handleOpenPopupDelete = (_index: number) => {
    setIndexDelete(_index)
    setIsOpenPopupDelete(true)
  }

  const handleClosePopupDelete = () => {
    setIsOpenPopupDelete(false)
    setIndexDelete(-1)
  }

  const handleDeleteDateRanges = async () => {
    if (indexDelete < 0) {
      handleErrorMessage({ message: 'Please select a date range to delete.' })
      return
    }
    try {
      setIsLoadingDelete(true)
      const newDateRanges = [...dateRanges]
      newDateRanges.splice(indexDelete, 1) // remove this range from dateRanges by index
      if (isEditBulk) {
        const propertyIds = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
        await updateBulkProperties({
          propertyIds,
          rate: {
            weekday: priceDayOfWeek,
            pricePerNight: {
              price: basePrice,
              currency: currency || 'USD',
            },
            dateRanges: newDateRanges,
            delete: dateRanges[indexDelete],
          }
        })
        newDateRanges.splice(indexDelete, 1) // remove this range from dateRanges by index
        updateDateRanges(newDateRanges)
        if (handleCloseEdit) handleCloseEdit()
      } else {
        await updateRates({
          listingId: Number(propertyId),
          weekday: priceDayOfWeek,
          pricePerNight: {
            price: basePrice,
            currency: currency || 'USD',
          },
          dateRanges: newDateRanges,
          delete: dateRanges[indexDelete],
        })
        await fetchRates(propertyId)
      }
      dispatch(setToastSuccess({ message: 'Update calendar success' }))
      // update UI
      triggerResetCalendar() // set trigger to call api fetch calendar when open popup
      handleClosePopupDelete()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoadingDelete(false)
    }
  }

  const renderStartToEndDate = (startDate: string, endDate: string) => {
    return (
      moment(startDate, DATE_FORMAT).format('D MMMM YYYY') + ' - ' + moment(endDate, DATE_FORMAT).format('D MMMM YYYY')
    )
  }

  const renderExtraTitleDeleteDateRange = () => {
    if (indexDelete > -1) {
      const dateRange = dateRanges[indexDelete]
      return renderStartToEndDate(dateRange.startDate, dateRange.endDate)
    }
    return ''
  }

  const triggerResetCalendar = () => {
    setIsResetCalendar(true)
  }

  return (
    <div>
      {/* <p className="text-neutral-800 text-20-32 font-maison-neue mb-[4px]">Custom rates</p> */}
      {/* <p className="text-neutral-600 text-14-20 font-maison-neue mb-[16px]">
        Override your base rate and rate per day of the week for specific date ranges
      </p> */}
      <div className="flex flex-col gap-[16px]">
        {Array.isArray(dateRanges) &&
          dateRanges.map((range, index) => {
            const isShow = indexExpanded.includes(index)
            const listDay = getAndSortDaysOfWeek(range.weekday)
            return (
              <div key={`date_range_${index}`} className={'flex items-start gap-[16px]'}>
                <button
                  className={clsx(
                    'w-[36px] h-[36px] min-w-[36px] rounded-full border border-neutral-300 shadow-xs flex items-center justify-center',
                    'hover:bg-neutral-200'
                  )}
                  onClick={() => handleClickDelete(index)}
                >
                  <img alt={'ic_delete'} src={ic_delete_trash} />
                </button>
                <Accordion
                  expanded={isShow}
                  onChange={() => handleToggleRow(index)}
                  className={clsx('bg-white w-full')}
                  style={{ boxShadow: 'none', border: 'none' }}
                  sx={{
                    '&:before': {
                      backgroundColor: 'transparent !important',
                    },
                    '&.Mui-expanded': { margin: 0 },
                    '&.MuiAccordion-root': {
                      backgroundColor: 'white',
                      borderRadius: '16px',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<img alt={'icon'} src={ic_chevron_up_000} />}
                    className="p-0 m-0"
                    style={{ padding: 0, margin: 0, minHeight: '0' }}
                    sx={{
                      '.MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded': { margin: 0 },
                    }}
                  >
                    <p className={'text-left w-full pt-[8px] font-maison-neue-medium text-16-20 text-neutral-900'}>
                      {moment(range.startDate, DATE_FORMAT).format('D MMMM YYYY')} -{' '}
                      {moment(range.endDate, DATE_FORMAT).format('D MMMM YYYY')}
                    </p>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      '&.MuiAccordionDetails-root': { padding: 0, marginTop: '16px' },
                    }}
                  >
                    {Array.isArray(listDay) &&
                      listDay.map((day) => {
                        return (
                          <div
                            key={day}
                            className={
                              'flex items-center justify-between h-[44px] font-maison-neue text-16-20 text-neutral-800'
                            }
                          >
                            <p className={'capitalize'}>{day}</p>
                            <p>
                              {symbolCurrency}
                              {numberWithCommas(range.weekday[day])}
                              /night
                            </p>
                          </div>
                        )
                      })}
                  </AccordionDetails>
                </Accordion>
              </div>
            )
          })}
      </div>

      <div className="mt-[16px]">
        <BasicButton variant="outlined" size={'md'} color={'black'} isRadius100={true} onClick={handleOpenPopup}>
          <span className="text-14-18 font-maison-neue-medium text-neutral-900">Add a date range</span>
        </BasicButton>

        {/* ---Popup add custom rate--- */}
        <AddCustomRate
          propertyId={propertyId}
          isOpen={isOpenPopup}
          onClose={handleClosePopup}
          currency={currency}
          basePrice={basePrice}
          dateRanges={dateRanges}
          updateDateRanges={updateDateRanges}
          fetchRates={fetchRates}
          priceDayOfWeek={priceDayOfWeek}
          isResetCalendar={isResetCalendar}
          triggerResetCalendar={triggerResetCalendar}
          minPrice={minPrice}
          maxPrice={maxPrice}
          fetchStatusStep={fetchStatusStep}
          handleCloseEdit={handleCloseEdit}
        />
      </div>

      {/* ----Start Popup Confirm Delete range block dates---- */}
      <BasicDialog
        isOpen={isOpenPopupDelete}
        onClose={handleClosePopupDelete}
        title={'Are you sure to delete this range?'}
        extraTitle={renderExtraTitleDeleteDateRange()}
      >
        <div className={'flex flex-col gap-[8px]'}>
          <BasicButton
            variant={'contained'}
            isRadius100={true}
            color={'red'}
            onClick={() => {
              if (isEditBulk) {
                setOpenConfirm(true)
                setIsOpenPopupDelete(false)
              }
              else handleDeleteDateRanges()
            }}
            loading={isLoadingDelete}
          >
            Confirm
          </BasicButton>
        </div>
      </BasicDialog>
      <EditBulkConfirmDialog
        openConfirm={openConfirm}
        handleClose={() => {
          setOpenConfirm(false)
          handleClosePopupDelete()
        }}
        handleSubmit={handleDeleteDateRanges}
      />
    </div>
  )
}

export default RateCustom
