import React, { useState } from 'react'
import BasicButton from 'src/components/ui/BasicButton'
import { handleErrorMessage, numberWithCommas, shorterAddress } from 'src/utils/common'
import { cancelCryptoBooking } from 'src/api/reservation'
import ic_arrow_right from 'src/assets/icons/ic_arrow_right_16_16.svg'
import { useAppSelector } from 'src/redux/hooks'

interface Props {
  data: any
  handleChangeStatus: (callback?: () => void) => void
  handleClose: any
  setTitleDialog: any
}

const CancelReservationCryptoNoRefund: React.FC<Props> = ({
  data,
  handleChangeStatus,
  handleClose,
  setTitleDialog
}) => {
  const {
    reservationId,
    paymentReceiverWallet,
    guestWallet,
    currency,
    guestInfo
  } = data
  const { profile } = useAppSelector((state) => state.user)

  const [loading, setLoading] = useState<boolean>(false)
  const [isSuccess, setSuccess] = useState<boolean>(false)

  const handleCancelCryptoAfterTransferSuccess = async () => {
    try {
      setLoading(true)
      await cancelCryptoBooking(reservationId)
      setLoading(false)
      setTitleDialog('Reservation successfully canceled')
      setSuccess(true)
      handleChangeStatus()
    } catch (err: any) {
      handleErrorMessage(err)
      setLoading(false)
    }
  }
  const showPrice = (price: number) => {
    const isETH = currency === 'ETH'
    const numberCommas = isETH ? 6 : 2
    const amount = Number(price).toFixed(numberCommas)
    const amountToString = String(Number(amount) < 0 ? -1 * Number(amount) : Number(amount))
    const arrSplit = amountToString.split('.')
    if (arrSplit[1]) {
      // case số thập phân, có phần nguyên
      if (arrSplit[1].length === 1) {
        arrSplit[1] = arrSplit[1] + '0'
      } else {
        // case có từ 2 chữ số sau dấu phẩy trở lên => GIỮ NGUYÊN
      }
    } else {
      // case số nguyên, ko có phần thập phân sau dấu phẩy
      arrSplit[1] = '00'
    }
    let newAmount = arrSplit.filter((v) => v !== '').join('.')
    return `<b>${numberWithCommas(newAmount)} ${currency}</b>`
  }
  const finalPriceShow = 0
  if (isSuccess) {
    return (
      <div>
        <p
          className='text-16-24 font-maison-neue text-neutral-800 mb-6 custom-text text-center'
          dangerouslySetInnerHTML={{ __html: `You’ve refunded ${showPrice(finalPriceShow)} to ${guestInfo?.name}'s wallet and notified your guest of the cancelation.` }}
        />
        <div className='w-full flex flex-col gap-4'>
          <BasicButton
            variant={'contained'}
            color={'red'}
            isRadius100={true}
            clases={'w-full'}
            onClick={handleClose}
          >
            Close
          </BasicButton>
        </div>
      </div>
    )
  }
  const isMetaMaskWallet = profile?.user?.loginWallet && !profile?.user?.hasSmartWallet && profile?.user?.loginWallet?.toLowerCase() === paymentReceiverWallet?.toLowerCase()
  return (
    <div>
      <p
        className='text-16-24 font-maison-neue text-neutral-800 mb-6 custom-text text-center'
        dangerouslySetInnerHTML={{ __html: `By canceling this reservation, you’ll refund ${showPrice(finalPriceShow)} to your guest wallet.` }}
      />
      <div className='flex flex-col gap-2 mb-6'>
        <div className='flex items-center justify-between gap-2'>
          <p className='text-14-18 font-maison-neue-medium text-neutral-800'>From</p>
        </div>
        <div className='px-[14px] py-[10px] rounded-[12px] border-[0.5px] border-[rgba(0,0,0,0.15)] flex items-center justify-between'>
          <div className='flex items-center gap-2'>
            <span className='text-16-20 font-maison-neue text-neutral-800'>
              {isMetaMaskWallet ? "Your Metamask Wallet" : "Your Dtravel Smartwallet"}
            </span>
          </div>
          <div className='flex items-center'>
            <span className='text-16-20 font-maison-neue text-neutral-500 capitalize'>
              {shorterAddress(paymentReceiverWallet)}
            </span>
          </div>
        </div>
        <div className='flex items-center justify-center w-full'>
          <img src={ic_arrow_right} alt="" className='rotate-[90deg]' />
        </div>
        <div className='px-[14px] py-[10px] rounded-[12px] border-[0.5px] border-[rgba(0,0,0,0.15)] flex items-center justify-between'>
          <div className='flex items-center gap-2'>
            <span className='text-16-20 font-maison-neue text-neutral-800'>
              {guestInfo?.name}
            </span>
          </div>
          <div className='flex items-center'>
            <span className='text-16-20 font-maison-neue text-neutral-500 capitalize'>
              {shorterAddress(guestWallet)}
            </span>
          </div>
        </div>
      </div>
      <p className='text-16-24 font-maison-neue text-neutral-800 mb-6 custom-text text-center'>
        Once the transaction is approved, your guest will be notified of the cancelation and the stay dates will be re-opened for booking.
      </p>

      <div className='w-full flex flex-col gap-4'>
        <BasicButton
          variant={'contained'}
          color={'red'}
          isRadius100={true}
          clases={'w-full'}
          onClick={async () => {
            handleCancelCryptoAfterTransferSuccess()
          }}
          loading={loading}
          disabled={loading}
        >
          Initiate refund
        </BasicButton>
        <BasicButton
          variant={'outlined'}
          color={'black'}
          isRadius100={true}
          clases={'w-full'}
          onClick={handleClose}
        >
          Cancel
        </BasicButton>
      </div>
    </div>
  )
}

export default CancelReservationCryptoNoRefund
