import { updateMinimumStay } from '../../../api/listings'
import { handleErrorMessage, isEmpty } from '../../../utils/common'
import AddMinimumStayDateRange from './AddMinimumStayDateRange'
import clsx from 'clsx'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import ic_delete_trash from 'src/assets/icons/ic_delete_trash.svg'
// import PopupConfirm from 'src/components/common/PopupConfirm'
import BasicButton from 'src/components/ui/BasicButton'
import { DATE_FORMAT } from 'src/contants/common'
import { setToastSuccess } from 'src/redux/slices/common'
import { updateBulkProperties } from 'src/api/native-listing'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import EditBulkConfirmDialog from '../EditBulkConfirmDialog'
import BasicDialog from 'src/components/ui/BasicDialog'

interface Props {
  propertyId: string
  globalMinNights: number
  dateRanges: any[]
  fetchMinimumStay?: (_propertyId: string) => void
  fetchStatusStep: any
  updateDateRanges: any
  handleCloseEdit?: any
}

const MinimumStayDateRange: React.FC<Props> = ({ propertyId, dateRanges, fetchMinimumStay, globalMinNights, fetchStatusStep, updateDateRanges, handleCloseEdit }) => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false)
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [isOpenPopupDelete, setIsOpenPopupDelete] = useState<boolean>(false)
  const [indexDelete, setIndexDelete] = useState<number>(-1)

  const handleOpenPopupDelete = (_index: number) => {
    setIndexDelete(_index)
    setIsOpenPopupDelete(true)
  }

  const handleClosePopupDelete = () => {
    setIsOpenPopupDelete(false)
    setIndexDelete(-1)
  }

  const onClosePopup = () => {
    setIsOpenPopup(false)
  }

  const renderStartToEndDate = (startDate: string, endDate: string) => {
    return (
      moment(startDate, DATE_FORMAT).format('D MMMM YYYY') + ' - ' + moment(endDate, DATE_FORMAT).format('D MMMM YYYY')
    )
  }

  const onConfirmDelete = async () => {
    try {
      setDeleteLoading(true)
      const newDateRanges = [...dateRanges]
      newDateRanges.splice(indexDelete, 1) // remove this range from dateRanges by index
      if (isEditBulk) {
        const propertyIds = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
        let dataDTO: any = {
          propertyIds,
          minNightInfo: {
            dateRanges: newDateRanges,
            delete: dateRanges[indexDelete],
          },
        }
        if (globalMinNights !== -1) dataDTO.minNightInfo.minNights = globalMinNights
        await updateBulkProperties(dataDTO)
        if (handleCloseEdit) handleCloseEdit()
      } else {
        const bodyData = {
          listingId: Number(propertyId),
          dateRanges: newDateRanges,
          minNights: globalMinNights,
          delete: dateRanges[indexDelete],
        }
        await updateMinimumStay(bodyData)
        if (typeof fetchMinimumStay === 'function') {
          await fetchMinimumStay(propertyId)
        }
      }
      handleClosePopupDelete()
      updateDateRanges(newDateRanges)
      dispatch(setToastSuccess({ message: 'Delete date range successfully' }))
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setDeleteLoading(false)
    }
  }
  const itemDeleted = indexDelete > -1 ? dateRanges[indexDelete] : null
  return (
    <div className="">
      {/* <p className={'font-maison-neue text-20-32 text-neutral-800'}>Minimum stay per date range</p> */}
      <p className={'font-maison-neue text-14-20 text-neutral-600 mb-[24px]'}>
        Override the minimum amount of nights guest stay for a certain date range
      </p>
      {!isEmpty(dateRanges) &&
        <div className={'mb-[24px] flex flex-col gap-[16px]'}>
          {Array.isArray(dateRanges) &&
            dateRanges.map((item, index) => {
              return (
                <div key={`date_range_${index}`} className={`flex items-center gap-[16px]`}>
                  {/* <PopupConfirm
                    title={'Are you sure to delete this range?'}
                    extraTitle={renderStartToEndDate(item.startDate, item.endDate)}
                    onConfirm={() => {
                      onConfirmDelete(index)
                    }}
                    confirmLoading={deleteLoading}
                  > */}
                  <button
                    className={clsx(
                      'w-[36px] h-[36px] min-w-[36px] rounded-full border border-neutral-300 shadow-xs flex items-center justify-center',
                      'hover:bg-neutral-200'
                    )}
                    onClick={() => handleOpenPopupDelete(index)}
                  >
                    <img alt={'ic_delete'} src={ic_delete_trash} />
                  </button>
                  {/* </PopupConfirm> */}

                  <div
                    className={
                      'flex flex-col md:flex-row items-start md:items-center justify-between w-full gap-[8px] font-maison-neue text-neutral-800 text-16-20'
                    }
                  >
                    <span className="">{renderStartToEndDate(item.startDate, item.endDate)}</span>
                    <span className={''}>
                      {item.minNights} {item.minNights > 1 ? 'nights' : 'night'}
                    </span>
                  </div>
                </div>
              )
            })}
        </div>
      }
      <div>
        <BasicButton
          variant={'outlined'}
          isRadius100={true}
          size={'md'}
          color={'black'}
          clases={'w-full md:w-auto'}
          disabled={globalMinNights === -1}
          onClick={() => setIsOpenPopup(true)}
        >
          Add a date range
        </BasicButton>
      </div>

      <AddMinimumStayDateRange
        propertyId={propertyId}
        isOpen={isOpenPopup}
        onClose={onClosePopup}
        currentDateRanges={dateRanges}
        globalMinNights={globalMinNights}
        fetchMinimumStay={fetchMinimumStay}
        fetchStatusStep={fetchStatusStep}
        updateDateRanges={updateDateRanges}
        handleCloseEdit={handleCloseEdit}
      />
      <BasicDialog
        isOpen={isOpenPopupDelete}
        onClose={handleClosePopupDelete}
        title={'Are you sure to delete this range?'}
        extraTitle={itemDeleted ? renderStartToEndDate(itemDeleted?.startDate, itemDeleted.endDate) : ''}
      >
        <BasicButton
          variant={'contained'}
          isRadius100={true}
          color={'red'}
          onClick={() => {
            if (isEditBulk) {
              setOpenConfirm(true)
              setIsOpenPopupDelete(false)
            }
            else onConfirmDelete()
          }}
          loading={deleteLoading}
          clases={'w-full'}
        >
          Confirm
        </BasicButton>
      </BasicDialog>
      <EditBulkConfirmDialog
        openConfirm={openConfirm}
        handleClose={() => {
          setOpenConfirm(false)
          handleClosePopupDelete()
        }}
        handleSubmit={onConfirmDelete}
      />
    </div >
  )
}

export default MinimumStayDateRange
