import React, { useEffect, useState } from 'react'
import { getSymbolCurrencyByKey, numberWithCommas } from 'src/utils/common'

interface Props {
  price: number
  currency: string
  isRounded?: boolean
  isUseSymbol?: boolean
}

const RenderPrice: React.FC<Props> = ({ price, currency, isRounded, isUseSymbol }) => {
  const [converted, setConverted] = useState('')
  const symbol = getSymbolCurrencyByKey(currency)
  const isETH = currency === 'ETH'

  useEffect(() => {
    if (price !== null && price !== undefined) {
      const amount = isRounded ? price.toFixed(0) : isETH ? price.toFixed(6) : price.toFixed(2)
      const amountToString = String(Number(amount) < 0 ? -1 * Number(amount) : Number(amount))
      const arrSplit = amountToString.split('.')
      if (arrSplit[1]) {
        // case số thập phân, có phần nguyên
        if (arrSplit[1].length === 1) {
          arrSplit[1] = arrSplit[1] + '0'
        } else {
          // case có từ 2 chữ số sau dấu phẩy trở lên => GIỮ NGUYÊN
        }
      } else {
        // case số nguyên, ko có phần thập phân sau dấu phẩy
        arrSplit[1] = isRounded ? '' : '00'
      }
      let newAmount = arrSplit.filter((v) => v !== '').join('.')
      setConverted(newAmount)
    }
  }, [price, isRounded])

  if (isUseSymbol && symbol) {
    return (
      <>
        {symbol}
        {numberWithCommas(converted.toString())}
      </>
    )
  }

  return currency && currency.toUpperCase() === 'USD' ? (
    <>${numberWithCommas(converted.toString())}</>
  ) : (
    <>
      {numberWithCommas(converted.toString())} {currency}
    </>
  )
}

export default RenderPrice
