import { getImportProgress } from '../../../api/listings'
import { PMS_TYPE } from '../../../contants/common'
import { SYNC_STATUS } from '../../../contants/property'
import { ONBOARDING_STEP } from '../../../contants/user'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { handleNextOnboardingStep } from '../../../redux/slices/user'
import BasicButton from '../../ui/BasicButton'
import LayoutOnboarding from '../LayoutOnboarding'
import RightContentPmsCm from './RightContentPmsCm'
import ShowImportListing from './ShowImportListing'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'

const OnboardingPmsConnectSuccess = () => {
  const { pmsTypeConnected } = useAppSelector((state) => state.user)
  const intervalSyncProgress = useRef<any>(null)
  const count = useRef<number>(0)
  const dispatch = useAppDispatch()
  const [listings, setListings] = useState<any[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    return () => {
      clearInterval(intervalSyncProgress.current)
    }
  }, [])

  useEffect(() => {
    async function fetchImportProgress(type?: string) {
      if (intervalSyncProgress.current) {
        setLoading(false)
      } else {
        intervalSyncProgress.current = setInterval(async () => {
          try {
            setLoading(true)
            let params: any = { pageSize: 3 }
            if (type) {
              params.type = type
            }
            const res = await getImportProgress(params)
            count.current++
            const listingsResult = res.data.result
            setListings(listingsResult)
            setTotalCount(res.data.totalCount)
            const isEveryNotPending = listingsResult.every((item: any) => item.status !== SYNC_STATUS.PENDING)
            if (isEveryNotPending) {
              clearInterval(intervalSyncProgress.current)
              intervalSyncProgress.current = null
              count.current = 0
            }
          } catch (err: any) {
            count.current++
            if (count.current > 10) {
              clearInterval(intervalSyncProgress.current)
              intervalSyncProgress.current = null
              count.current = 0
            }
          } finally {
            setLoading(false)
          }
        }, 3000)
      }
    }
    if (pmsTypeConnected) {
      fetchImportProgress(pmsTypeConnected)
    }
  }, [pmsTypeConnected])

  const onSubmit = async () => {
    dispatch(handleNextOnboardingStep(ONBOARDING_STEP.SITE_DETAILS_SITE_NAME))
  }

  const renderSubmitBtn = () => {
    return (
      <BasicButton variant={'contained'} isRadius100={true} color={'red'} size={'xl'} onClick={onSubmit}>
        Continue
      </BasicButton>
    )
  }

  return (
    <LayoutOnboarding
      headerTitle={'Listings'}
      rightContent={
        [PMS_TYPE.HOSTAWAY, PMS_TYPE.UPLISTING].includes(pmsTypeConnected) ? (
          <ShowImportListing listings={listings} loading={loading} total={totalCount} />
        ) : (
          <RightContentPmsCm />
        )
      }
      // isDisableBack={pmsTypeConnected === PMS || isConnectedUplisting || isConnectedGS}
    >
      <div className={'mt-[38px] lg:mt-[70px] w-full mx-auto md:max-w-[524px]'}>
        <h2 className={'font-maison-neue text-24-36 text-neutral-800'}>Success! Your listings are being synced.</h2>
        <p className={'font-maison-neue text-16-24 text-neutral-800 mt-[4px]'}>
          This process can take up to a few minutes.
        </p>

        <div className={'mt-[24px] lg:hidden'}>
          <ShowImportListing listings={listings} loading={loading} total={totalCount} />
        </div>

        <p className={'font-maison-neue text-16-24 text-neutral-800 mt-[24px]'}>
          Add a few more details while we finish importing listings.
        </p>

        <div className={'mt-[24px] hidden lg:block'}>{renderSubmitBtn()}</div>
      </div>
      <div
        className={clsx(
          'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
          'border-t border-neutral-300 px-4 md:px-8',
          'shadow-footer'
        )}
      >
        {renderSubmitBtn()}
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingPmsConnectSuccess
