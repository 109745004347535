import ic_add_plus from '../../assets/icons/ic_add_plus_16_16.svg'
import ic_edit_pencil_line from '../../assets/icons/ic_edit_pencil_line.svg'
import ic_trash_full from '../../assets/icons/ic_trash_full.svg'
import { FEES_AND_DISCOUNT, MAPPING_TAX_APPLIED_TO, PMS_TYPE } from '../../contants/common'
import { CustomFeeAndDiscount, PriceType } from '../../interfaces/reservations'
import { useAppSelector } from '../../redux/hooks'
import RenderPrice from '../common/RenderPrice'
import TooltipClick from '../ui/TooltipClick'
import AddTaxFeeOrDiscount from './AddTaxFeeOrDiscount'
import ReservationCustomSwitch from './ReservationCustomSwitch'
import IconButton from '@mui/material/IconButton'
import React, { useState } from 'react'

interface Props {
  currency: string
  onTogglePropertyData: (index: number, isChecked: boolean, priceType: PriceType) => void
  onChangeCustomData: (newData: CustomFeeAndDiscount[], priceType: PriceType) => void
  propertyTax: any[]
  propertyFee: any[]
  propertyDiscount: any[]
  customFee: CustomFeeAndDiscount[]
  customDiscount: CustomFeeAndDiscount[]
}

const ReservationTaxFeeAndDiscount: React.FC<Props> = ({
  currency,
  propertyTax,
  propertyFee,
  propertyDiscount,
  customFee,
  customDiscount,
  onTogglePropertyData,
  onChangeCustomData,
}) => {
  const { pmsTypeConnected } = useAppSelector((state) => state.user)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [editItemData, setEditItemData] = useState<CustomFeeAndDiscount | null>(null)
  const [editIndex, setEditIndex] = useState<number>(-1)
  const [addType, setAddType] = useState<PriceType>('fee')

  const onOpenPopup = (type: PriceType) => {
    setAddType(type)
    setIsOpen(true)
  }

  const onClosePopup = () => {
    setIsOpen(false)
    setEditItemData(null)
    setEditIndex(-1)
  }

  const onConfirmAddNew = (
    {
      type,
      name,
      value,
      isPercent,
      configFee,
    }: { isPercent: boolean; name: string; value: number; type: PriceType; configFee?: any },
    index?: number
  ) => {
    const itemData: any = {
      type,
      name,
      title: name,
      value: type === 'discount' ? -value : value,
      configAmount: type === 'discount' ? -value : value,
      isIncludedInTotalPrice: true,
      isPercent,
      configFee,
    }
    const rootData = type === 'fee' ? [...customFee] : [...customDiscount]
    if (typeof index === 'number' && index > -1) {
      const newData = rootData.map((item, _index) => {
        if (_index === index) return itemData
        return item
      })
      onChangeCustomData([...newData], type)
    } else {
      onChangeCustomData([...rootData, itemData], type)
    }
  }

  const onEdit = (index: number, _itemData: CustomFeeAndDiscount, type: PriceType) => {
    setAddType(type)
    setEditIndex(index)
    setEditItemData(_itemData)
    setIsOpen(true)
  }

  const onDelete = (index: number, priceType: PriceType) => {
    const newData: any = priceType === 'fee' ? [...customFee] : [...customDiscount]
    newData.splice(index, 1)
    onChangeCustomData(newData, priceType)
  }

  const onToggleOnOffCustomData = (index: number, isChecked: boolean, priceType: PriceType) => {
    const newData: any = priceType === 'fee' ? [...customFee] : [...customDiscount]
    newData[index]['isIncludedInTotalPrice'] = isChecked
    onChangeCustomData(newData, priceType)
  }

  // https://dtravelnetwork.atlassian.net/browse/DTRAV-1921
  const renderTaxAppliedPer = (amountType: string, appliedTo: string, taxType: string, taxNme: string) => {
    if (pmsTypeConnected === PMS_TYPE.UPLISTING) {
      if (taxType === 'per_booking_percentage') return 'entire reservation amount'
      if (taxType === 'per_person_per_night') return 'per guest per night'
      if (taxType === 'per_night') return 'per night'
      if (taxType === 'per_booking_amount') return 'per reservation'
      return ''
    }
    if (amountType === 'percent') {
      const arrAppliedTo = Array.isArray(appliedTo) ? [...appliedTo] : JSON.parse(appliedTo)
      const arrAppliedToCustomFee = customFee.filter((item) => {
        const configFee = item.configFee
        return configFee && configFee.isCheckedFlatFeeApply && configFee.flatFeeApplyOnTaxes.includes(taxNme)
      })
      const strPmsFee = Array.isArray(arrAppliedTo)
        ? arrAppliedTo.map((item) => MAPPING_TAX_APPLIED_TO[item] || item).join(', ')
        : ''
      const strCustomFee = arrAppliedToCustomFee.map((item) => item.name).join(', ')
      return strPmsFee + (strCustomFee ? ', ' : '') + strCustomFee
    }
    return 'per reservation'
  }

  const renderFeeAppliedPer = (amountType: string, appliedPer: string) => {
    if (pmsTypeConnected === PMS_TYPE.UPLISTING)
      return amountType === 'percent' ? 'entire reservation amount' : 'per reservation'
    if (appliedPer === 'reservation') return amountType === 'percent' ? 'entire reservation amount' : 'per reservation'
    if (appliedPer === 'base_rate') return 'base rate'
    if (appliedPer === 'person') return 'per guest'
    if (appliedPer === 'night') return 'per night'
    if (appliedPer === 'person_per_night') return 'per guest per night'
    return ''
  }

  const renderItemProperty = ({ index, hasBorderTop, priceType, ...data }: any) => {
    return (
      <div
        key={`propertyFee_${index}`}
        className={`flex justify-between items-center py-[24px] ${hasBorderTop ? 'border-t border-neutral-300' : ''}`}
      >
        <div>
          <p className={'font-maison-neue-medium text-12-16 text-neutral-600'}>
            {data.title || FEES_AND_DISCOUNT[data.name]}
          </p>
          <p className={'font-maison-neue text-16-20 text-neutral-900 mt-[2px] line-clamp-2'}>
            <span>
              {data.amountType === 'percent' ? (
                data.amount + '%'
              ) : (
                <RenderPrice price={Number(data.amount)} currency={currency} />
              )}
            </span>
            <span>
              {priceType === 'tax' && (
                <span>&nbsp;{renderTaxAppliedPer(data.amountType, data.applyTo, data.taxType, data.name)}</span>
              )}
              {priceType === 'fee' && <span>&nbsp;{renderFeeAppliedPer(data.amountType, data.feeAppliedPer)}</span>}
            </span>
          </p>
        </div>
        <div className={'flex items-center gap-[40px]'}>
          {priceType === 'fee' && ![PMS_TYPE.GUEST_SMILES, PMS_TYPE.NEXTPAX].includes(pmsTypeConnected) && (
            <ReservationCustomSwitch
              checked={data.isIncludedInTotalPrice}
              onChange={(event) => onTogglePropertyData(index, event.target.checked, priceType)}
            />
          )}
        </div>
      </div>
    )
  }

  const renderItemCustom = ({ index, hasBorderTop, priceType, ...data }: any) => {
    return (
      <div
        key={`propertyFee_${index}`}
        className={`flex justify-between items-center py-[24px] ${hasBorderTop ? 'border-t border-neutral-300' : ''}`}
      >
        <div>
          <p className={'font-maison-neue-medium text-12-16 text-neutral-600'}>{data.name}</p>
          <p className={'font-maison-neue text-16-20 text-neutral-900 mt-[2px]'}>
            {data.isPercent ? (
              Math.abs(data.configAmount) + '%'
            ) : (
              <RenderPrice price={Number(data.value)} currency={currency} />
            )}
          </p>
        </div>
        <div className={'flex items-center gap-[40px]'}>
          <IconButton size={'small'} onClick={() => onEdit(index, data, priceType)}>
            <img src={ic_edit_pencil_line} alt={'ic_edit_pencil_line'} />
          </IconButton>
          <IconButton size={'small'} onClick={() => onDelete(index, priceType)}>
            <img src={ic_trash_full} alt={'ic_trash_full'} />
          </IconButton>
          <ReservationCustomSwitch
            checked={data.isIncludedInTotalPrice}
            onChange={(event) => onToggleOnOffCustomData(index, event.target.checked, priceType)}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      {/* ----- Fees-----*/}
      <div>
        <h3 className={'font-maison-neue text-20-24 text-neutral-900'}>Fees</h3>
        <div className={'mt-[16px] px-[24px] bg-white rounded-[16px]'}>
          {/* Fax, fee, discount from property */}
          {Array.isArray(propertyFee) &&
            propertyFee.map((item, index) => {
              return renderItemProperty({
                ...item,
                index,
                hasBorderTop: index !== 0,
                priceType: 'fee',
              })
            })}

          {/* Tax, fee, discount custom */}
          {Array.isArray(customFee) &&
            customFee.map((item, index) => {
              return renderItemCustom({
                ...item,
                index,
                hasBorderTop: propertyFee.length > 0 || index !== 0,
                priceType: 'fee',
              })
            })}
        </div>

        <div className={'mt-[16px] font-maison-neue-medium text-12-12 text-neutral-800'}>
          <button
            className={
              'flex justify-between items-center gap-[4px] h-[32px] pl-[12px] pr-[8px] rounded-[100px] bg-white disabled:opacity-50 disabled:cursor-not-allowed'
            }
            style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)' }}
            onClick={() => onOpenPopup('fee')}
            disabled={customFee.length >= 20}
          >
            <span>New fee</span>
            <span>
              <img src={ic_add_plus} alt={'ic_add_plus'} />
            </span>
          </button>
        </div>
      </div>
      <div className={'my-[40px] w-full h-[0.5px] bg-[#00000026]'} />

      {/* ----- Taxes-----*/}
      {Array.isArray(propertyTax) && propertyTax.length > 0 && (
        <>
          <div>
            <h3 className={'font-maison-neue text-20-24 text-neutral-900'}>Taxes</h3>
            <div className={'mt-[16px] px-[24px] bg-white rounded-[16px]'}>
              {propertyTax.map((item, index) => {
                return renderItemProperty({
                  ...item,
                  index,
                  hasBorderTop: index !== 0,
                  priceType: 'tax',
                })
              })}
            </div>
          </div>
          <div className={'my-[40px] w-full h-[0.5px] bg-[#00000026]'} />
        </>
      )}

      {/* ----- Discount -----*/}
      <div>
        <div className={'flex justify-between items-center'}>
          <span className={'font-maison-neue text-20-24 text-neutral-900'}>Discounts</span>
          <TooltipClick
            placement="bottom"
            maxWidth={294}
            title={
              "By default, your nightly rates are being pulled directly from your PMS. If you would like to offer a lower rate than what's being pulled in, you can offer a custom discount"
            }
          >
            <span className={'font-maison-neue-medium text-14-14 text-neutral-6'}>How do discounts work?</span>
          </TooltipClick>
        </div>
        <div className={'mt-[16px] px-[24px] bg-white rounded-[16px]'}>
          {/* Fax, fee, discount from property */}
          {Array.isArray(propertyDiscount) &&
            propertyDiscount.map((item, index) => {
              return renderItemProperty({
                ...item,
                index,
                hasBorderTop: index !== 0,
                priceType: 'discount',
              })
            })}

          {/* Tax, fee, discount custom */}
          {Array.isArray(customDiscount) &&
            customDiscount.map((item, index) => {
              return renderItemCustom({
                ...item,
                index,
                hasBorderTop: propertyDiscount.length > 0 || index !== 0,
                priceType: 'discount',
              })
            })}
        </div>

        <div className={'mt-[16px] font-maison-neue-medium text-12-12 text-neutral-800'}>
          <button
            className={
              'flex justify-between items-center gap-[4px] h-[32px] pl-[12px] pr-[8px] rounded-[100px] bg-white disabled:opacity-50 disabled:cursor-not-allowed'
            }
            style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)' }}
            onClick={() => onOpenPopup('discount')}
            disabled={customDiscount.length >= 20}
          >
            <span>New discount</span>
            <span>
              <img src={ic_add_plus} alt={'ic_add_plus'} />
            </span>
          </button>
        </div>
      </div>

      {/*----- Popup -----*/}
      <AddTaxFeeOrDiscount
        editIndex={editIndex}
        editData={editItemData}
        addType={addType}
        isOpen={isOpen}
        onClose={onClosePopup}
        onConfirm={onConfirmAddNew}
        currency={currency}
        customFee={customFee}
        customDiscount={customDiscount}
        propertyTax={propertyTax}
      />
    </>
  )
}

export default ReservationTaxFeeAndDiscount
