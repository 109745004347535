import { getImportProgressHospitable, importByIds } from '../../api/listings'
import { SYNC_STATUS } from '../../contants/property'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setTriggerListingProperty } from '../../redux/slices/common'
import { setImportingPmsIds, setIsOpenImportProgressBackground } from '../../redux/slices/listing'
import { handleErrorMessage } from '../../utils/common'
import {
  clearDataImportAirbnb,
  getDataImportAirbnb,
  handleShowListingStatusAfterImport,
  saveDataImportAirbnb,
} from '../../utils/property'
import { IcChervonDown, IconClose, IcLabelInfo, IcChervonUp } from '../common/Icons'
import LoadingSpinner from '../common/LoadingSpinner'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import no_image from 'src/assets/images/no_image_listing.jpg'

const AirbnbImportProgressBackground = () => {
  const navigate = useNavigate()
  const intervalSyncProgress = useRef<any>(null)
  const countEmpty = useRef<number>(0)
  const prevDone = useRef<number>(0)
  const dispatch = useAppDispatch()
  const { isOpenImportProgressBackground, importingPmsIds, importingPropertyStatus } = useAppSelector(
    (state) => state.listing
  )
  const [isShowFull, setIsShowFull] = useState<boolean>(false)
  const [listProperty, setListProperty] = useState<any[]>([])
  const [numberOfListingDone, setNumberOfListingDone] = useState<number>(0)
  const [isEveryNotPending, setIsEveryNotPending] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isAllDone = numberOfListingDone > 0 && numberOfListingDone === listProperty.length
  const { pathname } = useLocation()
  const isNativeListingPage = pathname.includes('/native-listing')

  const clearImportProgress = () => {
    setIsLoading(false)
    clearInterval(intervalSyncProgress.current)
    intervalSyncProgress.current = null
    countEmpty.current = 0
  }

  async function fetchImportProgress(importingPmsIds: string[]) {
    if (!intervalSyncProgress.current) {
      intervalSyncProgress.current = setInterval(async () => {
        try {
          setIsLoading(true)
          const localData = getDataImportAirbnb()
          const localProperties = localData?.listProperty
          // const res = await getImportProgressByListIds(importingPmsIds)
          const res = await getImportProgressHospitable({
            requestId: localData?.requestId || '',
            listPmsId: importingPmsIds,
          })
          let listDtravelPropertyId = []
          if (Array.isArray(res.data)) {
            if (res.data.length === 0) {
              countEmpty.current++
            }
            listDtravelPropertyId = res.data
              .filter((item) => item.dtravelPropertyId)
              .map((item) => item.dtravelPropertyId)
            if (Array.isArray(localProperties) && localProperties.length > 0) {
              const newList = localProperties.map((item: any) => {
                const clone = { ...item }
                const newData = res.data.find((i: any) => i.propertyId === item.pmsPropertyId)
                return newData ? { ...clone, ...newData } : { ...clone }
              })
              setListProperty(newList)
              // save in localStorage
              saveDataImportAirbnb({
                requestId: localData.requestId,
                listProperty: newList,
                channelId: localData?.channelId || '',
                isToggleOnPopup: localData?.isToggleOnPopup,
              })
            } else {
              setListProperty(res.data)
            }

            const _isEveryNotPending =
              res.data.length === importingPmsIds.length &&
              res.data.every(
                (item: any) => item.status !== SYNC_STATUS.PENDING && item.status !== SYNC_STATUS.PROCESSING
              )

            const _countDone = res.data.filter((item) => item.status === SYNC_STATUS.DONE).length
            if (_countDone > prevDone.current) {
              dispatch(setTriggerListingProperty()) // to recall api
            }
            prevDone.current = _countDone
            setNumberOfListingDone(_countDone)
            setIsEveryNotPending(_isEveryNotPending)
            // case import done
            if (_isEveryNotPending) {
              clearImportProgress()
            }
            // if (_countDone === res.data.length) {
            //   setIsShowFull(!isEditListingPage)
            // }
          }
          if (listDtravelPropertyId.length > 0) {
            await handleShowListingStatusAfterImport(listDtravelPropertyId.join(',')) // fetch status
          }
          if (countEmpty.current >= 40) {
            handleClosePopup()
          }
        } catch (err: any) {
          handleErrorMessage(err)
          setIsEveryNotPending(true)
          clearImportProgress()
        }
      }, 3000)
    }
  }

  useEffect(() => {
    // check request id in local storage
    const localData = getDataImportAirbnb()
    const localProperties = localData?.listProperty
    setIsShowFull(!!localData?.isToggleOnPopup)
    if (localData && Array.isArray(localProperties) && localProperties.length > 0) {
      dispatch(setIsOpenImportProgressBackground(true))
      setListProperty(localProperties)
      const _countDoneLocalProperty = Array.isArray(localProperties)
        ? localProperties.filter((item) => item.status === SYNC_STATUS.DONE).length
        : 0
      setNumberOfListingDone(_countDoneLocalProperty)

      const pmsIds = localProperties.map((item: any) => item.pmsPropertyId)
      dispatch(setImportingPmsIds(pmsIds))
    }
  }, [])

  useEffect(() => {
    if (Array.isArray(importingPmsIds) && importingPmsIds.length > 0) {
      fetchImportProgress(importingPmsIds)
    }
  }, [importingPmsIds])

  const toggleIsShowFull = () => {
    setIsShowFull((preveState) => {
      const localData = getDataImportAirbnb()
      if (localData) {
        saveDataImportAirbnb({
          requestId: localData.requestId,
          listProperty: localData.listProperty,
          channelId: localData?.channelId || '',
          isToggleOnPopup: !preveState,
        })
      }
      return !preveState
    })
  }

  const handleClosePopup = () => {
    clearImportProgress()
    setListProperty([])
    clearDataImportAirbnb()
    dispatch(setImportingPmsIds([]))
    dispatch(setIsOpenImportProgressBackground(false))
  }

  const handleClickEditAll = () => {
    const ids = listProperty.map((item) => String(item.dtravelPropertyId))
    navigate(`/native-listing/amenities?ids=${ids.join(',')}&type=airbnb`)
  }

  const handleClickTryAgain = async (data: any) => {
    try {
      const localData = getDataImportAirbnb()
      await importByIds([data.id], localData.channelId || '')
      // update UI: show syncStatus from FAIL -> PENDING
      setListProperty((prevStatus) => {
        return prevStatus.map((item) => {
          const clone = { ...item }
          if (data.propertyId === item.propertyId) {
            clone.status = SYNC_STATUS.PENDING
          }
          return clone
        })
      })
      // fetch import progress
      fetchImportProgress(importingPmsIds)
    } catch (err: any) {
      handleErrorMessage(err)
    }
  }

  const renderListedStatus = () => {
    return (
      <div
        className={clsx(
          'font-maison-neue-demibold text-10-12 tracking-[0.4px] uppercase',
          'px-[8px] py-[4px]',
          'rounded-[48px] bg-forest-100 text-forest-700'
        )}
      >
        Listed
      </div>
    )
  }

  const renderActionByStatus = (item: any) => {
    const isActive = importingPropertyStatus[item.dtravelPropertyId]?.isActive
    const publicStatus = item.publicStatus
    if (isActive || publicStatus === 'listed') {
      return renderListedStatus()
    }
    const syncStatus = item.status
    switch (syncStatus) {
      case SYNC_STATUS.PENDING:
        return (
          <div className={'flex items-center gap-[8px]'}>
            <LoadingSpinner />
            <span className={'font-maison-neue-medium'}>Importing...</span>
          </div>
        )
      case SYNC_STATUS.PROCESSING:
        return (
          <div className={'flex items-center gap-[8px]'}>
            <LoadingSpinner />
            <span className={'font-maison-neue-medium'}>Importing...</span>
          </div>
        )
      case SYNC_STATUS.ERROR:
        return (
          <div className={'font-maison-neue-medium'}>
            <span>Error importing. </span>
            <button className={'font-maison-neue-medium underline'} onClick={() => handleClickTryAgain(item)}>
              Try again
            </button>
          </div>
        )
      case SYNC_STATUS.FAIL:
        return (
          <div className={'font-maison-neue-medium'}>
            <span>Error importing. </span>
            <button className={'font-maison-neue-medium underline'} onClick={() => handleClickTryAgain(item)}>
              Try again
            </button>
          </div>
        )
      default:
        return (
          <div className={'flex items-center gap-[12px]'}>
            <span
              className={clsx(
                'font-maison-neue-demibold text-10-12 tracking-[0.4px] uppercase',
                'px-[8px] py-[4px]',
                'rounded-[48px] bg-sun-400 text-sun-700'
              )}
            >
              Incomplete
            </span>
            {/*<button className={'font-maison-neue-medium p-[4px]'}>Edit</button>*/}
          </div>
        )
    }
  }

  const renderWarningBanner = () => {
    return (
      <div className={clsx('flex items-center', 'min-h-[44px]', 'px-4 gap-4 py-3', 'bg-sun-400')}>
        <div className={'min-w-[20px]'}>
          <IcLabelInfo color={'#292926'} width={20} height={20} />
        </div>
        <p className={'text-neutral-800 text-12-16 flex-1'}>
          Dtravel wasn’t able to import all content. Please edit the missing info to complete your listing(s).
        </p>
        <div className={'block lg:hidden'}>{renderEditAllBtn()}</div>
      </div>
    )
  }

  const renderCloseIcon = () => {
    return (
      <button
        className={clsx(
          'flex items-center justify-center',
          'w-[24px] min-w-[24px] h-[24px]',
          'bg-neutral-900',
          'rounded-full'
        )}
        onClick={handleClosePopup}
      >
        <IconClose color={'#fff'} width={18} height={18} />
      </button>
    )
  }

  const renderToggleIcon = () => {
    return (
      <button
        className={clsx(
          'flex items-center justify-center',
          'w-[24px] min-w-[24px] h-[24px]',
          'bg-neutral-900',
          'rounded-full'
        )}
        onClick={toggleIsShowFull}
      >
        {isShowFull ? (
          <IcChervonDown color={'#fff'} width={16} height={16} />
        ) : (
          <IcChervonUp color={'#fff'} width={20} height={20} />
        )}
      </button>
    )
  }

  const renderEditAllBtn = () => {
    return (
      <button
        className={clsx(
          'flex items-center justify-center',
          'h-[32px]',
          'px-[14px] rounded-[100px]',
          'shadow-xs',
          'bg-neutral-900',
          'font-maison-neue-medium text-14-18 text-white',
          'disabled:text-neutral-600 disabled:cursor-not-allowed'
        )}
        disabled={!isAllDone || isNativeListingPage}
        onClick={handleClickEditAll}
      >
        Edit all
      </button>
    )
  }

  return (
    <div
      className={clsx(
        'fixed w-full md:w-[640px]',
        'left-0 md:left-[calc(50%-320px)]',
        'bg-grayscale-900',
        'z-[1000]',
        'text-white',
        'text-14-18',
        'rounded-t-[8px]',
        'max-h-[480px]',
        {
          'bottom-0 lg:bottom-0': isNativeListingPage,
          'bottom-[72px] lg:bottom-0': !isNativeListingPage,
          hidden: !isOpenImportProgressBackground,
        }
      )}
    >
      <div className={'relative flex flex-col'}>
        {/* ---TOP--- */}
        <div
          className={clsx(
            'flex items-center justify-between',
            'bg-grayscale-800',
            'px-4 py-3',
            'rounded-t-[8px]',
            'gap-3',
            'sticky top-0 left-0'
          )}
        >
          {isLoading ? <LoadingSpinner /> : <></>}
          <div className={'flex-1'}>
            {isAllDone ? (
              <p className={'font-maison-neue-medium text-14-18'}>
                {numberOfListingDone} {numberOfListingDone > 1 ? 'listings' : 'listing'} imported
              </p>
            ) : (
              <>
                <p className={'font-maison-neue-medium text-14-18 mb-[4px]'}>Importing your listings from Airbnb</p>
                <p className={'font-maison-neue text-12-16'}>
                  {numberOfListingDone}/{listProperty.length} {listProperty.length > 1 ? 'listings' : 'listing'}{' '}
                  imported
                </p>
              </>
            )}
          </div>
          {/**/}
          <div className={'hidden lg:block'}>{renderEditAllBtn()}</div>

          {renderToggleIcon()}
          {isEveryNotPending && renderCloseIcon()}

          {/*{isAllDone && !isEditListingPage ? renderCloseIcon() : renderToggleIcon()}*/}
        </div>
        {/* ---BODY--- */}
        <div
          className={clsx('flex flex-col overflow-y-auto max-h-[calc(480px-62px)]', 'hidden-scroll-bar', {
            hidden: !isShowFull,
          })}
        >
          {isAllDone && renderWarningBanner()}
          {listProperty.map((item) => {
            return (
              <div key={item.id} className={'flex items-center gap-[12px] py-[12px] px-4'}>
                <div className={'w-[40px] h-[40px] min-w-[40px] rounded-[8px] bg-neutral-200'}>
                  <img
                    src={item.thumbnailUrl || no_image}
                    alt={item.name}
                    className={'w-full h-full rounded-[8px] object-cover'}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null // prevents looping
                      currentTarget.src = no_image
                    }}
                  />
                </div>
                <div className={'flex-1'}>
                  <p className={'font-maison-neue-medium line-clamp-1'}>{item.name}</p>
                  <p className={'font-maison-neue line-clamp-1'}>{item.address}</p>
                </div>
                <div>{renderActionByStatus(item)}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default AirbnbImportProgressBackground
