import Popover from '@mui/material/Popover'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ic_close from 'src/assets/icons/ic_close.svg'
import SelectGuestItem from 'src/components/common/SelectGuestItem'
import BasicSwipeDrawer from 'src/components/ui/BasicSwipeDrawer'
import { GUEST_TYPE } from 'src/contants/common'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { useAppSelector } from 'src/redux/hooks'
import { setAdults, setChildren, setInfants, setIsOpenSelectGuest, setPets } from 'src/redux/slices/common'

interface Props {
  personCapacity: number
  loading?: boolean
  anchorElement: HTMLElement | null
  transformOrigin?: any
  anchorOrigin?: any
  // eslint-disable-next-line no-unused-vars
  afterGuestChange?: (_: any) => void
  // eslint-disable-next-line no-unused-vars
  onSaveGuest?: (_: any) => void
  allowZeroAdult?: boolean
  maxPet?: number | null
}

const SelectGuests: React.FC<Props> = ({
  personCapacity,
  anchorElement,
  allowZeroAdult,
  transformOrigin,
  anchorOrigin,
  afterGuestChange,
  onSaveGuest,
  maxPet,
}) => {
  const dispatch = useDispatch()

  const { isOpenSelectGuest, adults, children, infants, pets } = useAppSelector((state) => state.common)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768

  useEffect(() => {
    dispatch(setAdults(allowZeroAdult ? 0 : 1))
  }, [allowZeroAdult])

  useEffect(() => {
    setAnchorEl(isOpenSelectGuest ? anchorElement : null)
  }, [isOpenSelectGuest, anchorElement])

  const handleClose = () => {
    setAnchorEl(null)
    dispatch(setIsOpenSelectGuest(false))
  }

  const handleApply = () => {
    if (typeof onSaveGuest === 'function') {
      onSaveGuest({ adults, children, infants, pets })
    }
    handleClose()
  }

  const handleClear = () => {
    dispatch(setAdults(1))
    dispatch(setChildren(0))
    dispatch(setInfants(0))
    dispatch(setPets(0))
    if (typeof onSaveGuest === 'function') {
      onSaveGuest({ adults: 1, children: 0, infants: 0, pets: 0 })
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover-guest' : undefined

  const countGuest = () => adults + children + infants

  const renderContent = () => {
    return (
      <>
        <div className={'hidden md:flex justify-between items-center h-[64px] px-[24px]'}>
          <span className={'font-maison-neue-medium text-neutral-900 text-16-20 md:text-16-16'}>
            {countGuest() > 0 ? `${countGuest()} ${countGuest() > 1 ? 'Guests' : 'Guest'} selected` : 'Select guest'}
          </span>
          <button
            className={'w-[40px] h-[40px] flex items-center justify-center rounded-full hover:bg-neutral-200'}
            onClick={() => handleClose()}
          >
            <img src={ic_close} width={20} height={20} alt="" />
          </button>
        </div>
        <div className={'h-[1px] bg-[#E9E9E4] hidden md:block'} />
        <div className={'px-[24px] py-[16px] bg-neutral-100'}>
          {GUEST_TYPE.map((type, index) => {
            return (
              <SelectGuestItem
                key={type}
                type={type}
                // hasBorderBot={index !== GUEST_TYPE.length - 1}
                hasBorderBot
                pt={index === 0 ? 0 : 24}
                pb={24}
                personCapacity={personCapacity}
                afterGuestChange={afterGuestChange}
                allowZeroAdult={allowZeroAdult}
                maxPet={maxPet}
              />
            )
          })}
          <div className={`flex justify-between items-center`} style={{ paddingTop: 24, paddingBottom: 0 }}>
            <p className={'font-maison-neue text-14-18 text-neutral-600'}>
              This listing has a maximum of {personCapacity} {personCapacity > 1 ? 'guests' : 'guest'}, not including
              infants. Pets are {maxPet ? 'allowed' : 'not allowed'}.
            </p>
          </div>
        </div>

        <div className={'h-[1px] bg-[#E9E9E4] '} />
        {/*---Footer---*/}
        <div className={'flex justify-between items-center h-[80px] md:h-[76px] px-[24px] gap-[12px]'}>
          <button
            className={
              'bg-white font-maison-neue-medium text-neutral-900 flex items-center justify-center ' +
              'h-[56px] md:h-[40px] ' +
              'rounded-[16px] md:rounded-[12px] ' +
              'text-16-16 md:text-14-14 ' +
              'w-1/3 md:w-auto ' +
              'border border-neutral-300 md:border-none ' +
              'md:active:underline ' +
              'md:disabled:active:no-underline ' +
              'disabled:opacity-50 ' +
              'disabled:cursor-not-allowed'
            }
            disabled={countGuest() <= 0}
            onClick={handleClear}
          >
            Clear
          </button>
          <button
            className={
              'bg-neutral-900 font-maison-neue-medium text-white px-[16px] flex items-center justify-center ' +
              'h-[56px] md:h-[40px] ' +
              'rounded-[16px] md:rounded-[12px] ' +
              'text-16-16 md:text-14-14 ' +
              'w-2/3 md:w-auto ' +
              'disabled:opacity-50 ' +
              'disabled:cursor-not-allowed'
            }
            disabled={countGuest() <= 0}
            onClick={handleApply}
          >
            Apply
          </button>
        </div>
      </>
    )
  }

  const swipeTitle =
    countGuest() > 0 ? `${countGuest()} ${countGuest() > 1 ? 'Guests' : 'Guest'} selected` : 'Select guest'

  return (
    <>
      {isMobile ? (
        <BasicSwipeDrawer
          isOpen={isOpenSelectGuest}
          onClose={() => handleClose()}
          title={swipeTitle}
          bodyStyle={{ padding: 0 }}
        >
          {renderContent()}
        </BasicSwipeDrawer>
      ) : (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => handleClose()}
          anchorOrigin={
            anchorOrigin
              ? { ...anchorOrigin }
              : {
                  vertical: 'bottom',
                  horizontal: 'right',
                }
          }
          transformOrigin={
            transformOrigin
              ? { ...transformOrigin }
              : {
                  vertical: -8,
                  horizontal: 'right',
                }
          }
          sx={{
            '& .MuiPaper-root': {
              width: '400px',
              boxShadow: '0px 16px 48px rgba(0, 0, 0, 0.2)',
              borderRadius: '24px',
              backgroundColor: '#FFFFFF',
              padding: '0',
            },
          }}
        >
          {renderContent()}
        </Popover>
      )}
    </>
  )
}

export default SelectGuests
