import { getHostSettings, getUserProfile } from '../../../api/user'
import { SETTINGS_SIDE_BAR } from '../../../contants/common'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { setSettingData } from '../../../redux/slices/user'
import { getUserInfo, saveUserInfo } from '../../../utils/user'
import DeleteAccount from './DeleteAccount'
import SettingAccountlInformation from './SettingAccountInformation'
import React from 'react'

const SettingPersonalInfo = () => {
  const dispatch = useAppDispatch()
  const { profile, settingData } = useAppSelector((state) => state.user)

  const fetchUserProfile = async () => {
    try {
      const res = await getUserProfile()
      const { data } = res
      const user = getUserInfo()
      saveUserInfo({ ...user, ...data })
    } catch (err) {
      console.log(err)
    }
  }

  const fetchHostSettings = async () => {
    try {
      const res = await getHostSettings()
      if (res.success) {
        dispatch(setSettingData(res.data || {}))
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <h2
        id={SETTINGS_SIDE_BAR.ACCOUNT_INFORMATION}
        className={'font-maison-neue text-neutral-800 text-20-24 capitalize'}
      >
        Personal Info
      </h2>
      <p className={'font-maison-neue text-neutral-500 text-14-18 mt-[4px]'}>
        This information is private to you and your account
      </p>

      <div>
        <SettingAccountlInformation
          data={profile}
          settingData={settingData}
          fetchUserProfile={fetchUserProfile}
          fetchHostSettings={fetchHostSettings}
        />
      </div>

      <div className={'mt-[48px]'}>
        <DeleteAccount />
      </div>
    </>
  )
}

export default SettingPersonalInfo
