import clsx from 'clsx'
import React, { InputHTMLAttributes, useRef } from 'react'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  classes?: string
  color?: 'black' | 'red'
  size20?: boolean
}

const BasicCheckbox: React.FC<CheckboxProps> = ({ classes, size20, ...props }) => {
  const ref = useRef<any>(null)
  const color = props.color || 'red'
  return (
    <label
      className={clsx(`checkboxContainer checkboxContainer-${color}`, {
        size20: size20,
      })}
    >
      <input ref={ref} {...props} type="checkbox" className={clsx('', classes)} />
      <span
        className={clsx('checkboxCheckmark', {
          size20: size20,
        })}
      />
    </label>
  )
}

export default BasicCheckbox
