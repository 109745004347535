import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import React, { useState } from 'react'
import ic_arrow from 'src/assets/icons/ic_arrow_forward.svg'
import ic_checked_active_24_24 from 'src/assets/icons/ic_checked_active_24_24.svg'
import ic_close from 'src/assets/icons/ic_close_md.svg'
import ic_unchecked_24_24 from 'src/assets/icons/ic_unchecked_24_24.svg'
import { IconPin } from 'src/components/common/Icons'
import BasicButton from 'src/components/ui/BasicButton'
import BasicSwipeDrawer from 'src/components/ui/BasicSwipeDrawer'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

interface Props {
  stepList: any[]
  stepProgress: any
  numberOfStepFinished: number
  onClickStepItem: (key: string) => void
  btnStyle?: any
}

const GetStartedQuickAccess: React.FC<Props> = ({
  stepList,
  stepProgress,
  numberOfStepFinished,
  onClickStepItem,
  btnStyle = {},
}) => {
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onOpen = () => {
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const renderContent = () => {
    return (
      <div className={'pt-0'}>
        <div className={'flex items-start gap-[8px]'}>
          <div className={'flex-1'}>
            <p className={'font-maison-neue text-13-20 md:text-16-20 text-neutral-800 text-left'}>
              Set up your account to get started
            </p>
            <div className={'flex items-center gap-[12px] mt-[8px] mb-[16px]'}>
              <span className={'font-maison-neue-medium text-14-18 text-neutral-600 whitespace-nowrap'}>
                {numberOfStepFinished}/{stepList.length} tasks
              </span>
              <div className={'w-full'}>
                <LinearProgress
                  value={numberOfStepFinished * (100 / stepList.length)}
                  variant={'determinate'}
                  sx={{
                    '&.MuiLinearProgress-root': { backgroundColor: '#CFCFCB' },
                    '& .MuiLinearProgress-bar': { backgroundColor: '#4B9548' },
                  }}
                />
              </div>
            </div>
          </div>
          <IconButton onClick={onClose} size={'small'}>
            <img src={ic_close} alt={'ic_close'} />
          </IconButton>
        </div>

        <div>
          {stepList.map((item, index) => {
            const isFinished = stepProgress[item.key]
            return (
              <button
                key={item.key}
                className={`w-full flex items-center gap-[16px] h-[56px] ${
                  index === stepList.length - 1 ? '' : 'border-neutral-300 border-b'
                } ${isFinished ? 'cursor-auto' : ''}`}
                onClick={() => {
                  if (!isFinished) {
                    onClickStepItem(item.key)
                    onClose()
                  }
                }}
              >
                <div className={'min-w-[24px]'}>
                  <img src={isFinished ? ic_checked_active_24_24 : ic_unchecked_24_24} alt={'ic_checked_guide'} />
                </div>
                <div className={`font-maison-neue text-14-18 text-neutral-800 ${isFinished ? 'line-through' : ''}`}>
                  {item.label}
                </div>
                {isFinished ? (
                  ''
                ) : (
                  <div className={'ml-auto'}>
                    <IconButton size={'small'}>
                      <img src={ic_arrow} alt={'ic_arrow'} />
                    </IconButton>
                  </div>
                )}
              </button>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <>
      <BasicButton
        size={'md'}
        variant={'contained'}
        color={'white'}
        isRadius100
        onClick={onOpen}
        style={isOpen ? { boxShadow: '0px 0px 0px 4px rgba(0, 0, 0, 0.08)', ...btnStyle } : { ...btnStyle }}
        clases="w-[40px] md:w-auto px-0 flex items-center justify-center"
      >
        <div className="flex items-center justify-center gap-2 md:pl-3 md:pr-4">
          <IconPin width={20} height={20} />
          <span className="hidden md:block">Setup guide</span>
        </div>
      </BasicButton>
      {isMobile ? (
        <BasicSwipeDrawer isOpen={isOpen} onClose={onClose}>
          {renderContent()}
        </BasicSwipeDrawer>
      ) : (
        <Drawer
          sx={{
            '& .MuiDrawer-paper': {
              width: '320px',
              padding: '16px 16px 0px 16px',
            },
          }}
          open={isOpen}
          anchor={'right'}
          onClose={onClose}
        >
          {renderContent()}
        </Drawer>
      )}
    </>
  )
}

export default GetStartedQuickAccess
