import { useContext } from 'react'
import { ConnectWalleContext } from 'src/components/connect-wallet/ConnectWalletProvider'

const useWallet = () => {
  const walletContext = useContext(ConnectWalleContext)
  if (!walletContext) {
    throw 'walletContext not found'
  }
  return walletContext
}

export default useWallet
