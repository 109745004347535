import AdvanceNotice from '../native-listing/availability/AdvanceNotice'
import AvailabilityWindow from '../native-listing/availability/AvailabilityWindow'
import React from 'react'

interface Props {
  data: any
  onChangeData: (field: string, value: string | number) => void
}

const EditLosAndAvailability: React.FC<Props> = ({ data, onChangeData }) => {
  const onChangeAdvanceNotice = (type: 'plus' | 'minus') => {
    const currentValue = data.advanceNotice
    if (type === 'plus') {
      onChangeData('advanceNotice', currentValue + 1)
    } else {
      onChangeData('advanceNotice', currentValue === 0 ? 0 : currentValue - 1)
    }
  }

  return (
    <div>
      <p className="text-14-18 text-neutral-800 font-maison-neue-medium tracking-[-0.01em] mb-[16px]">
        Length of Stay & Availability
        {/*Availability*/}
      </p>

      <div className={'p-[24px] bg-white w-full mb-[16px] rounded-[16px]'}>
        {/*---Availability window---*/}
        <AvailabilityWindow
          availabilityWindow={data.availabilityWindow}
          onChange={(val) => onChangeData('availabilityWindow', val)}
        />
        <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
        {/*---Advance notice---*/}
        <AdvanceNotice advanceNotice={data.advanceNotice} onChange={onChangeAdvanceNotice} />
      </div>
    </div>
  )
}

export default EditLosAndAvailability
