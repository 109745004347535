import { handleShowListingStatusAfterImport } from '../../utils/property'
import BasicButton from '../ui/BasicButton'
import BasicDialog from '../ui/BasicDialog'
import { nativeMenu } from './NativeListingSideBar'
import queryString from 'query-string'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

interface Props {
  openConfirm: boolean
  handleClose: any
  handleSubmit: any
}
const EditBulkConfirmDialog = ({ openConfirm, handleClose, handleSubmit }: Props) => {
  // use for case open confirm dialog from popup
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const { type } = useParams()
  const ids = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
  const item = nativeMenu.find((v: any) => v.id === type)

  const triggerCallStatusForImportHc = async () => {
    // trigger call api status for import HC
    const parseQuery = queryString.parse(search)
    const propertyId = parseQuery.id ? String(parseQuery.id) : ''
    const ids = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
    const listId = ids.join(',')
    await handleShowListingStatusAfterImport(listId || propertyId, true) // fetch status to show on import background
  }

  return (
    <>
      <BasicDialog
        isOpen={openConfirm}
        onClose={handleClose}
        title={`Are you sure to want to apply these changes to ${ids?.length} listings?`}
        extraTitle={`Once confirmed, the following section will be updated: ${item?.name || ''}`}
        titleAlign={'center'}
        hiddenTitleDivider
      >
        <div className={'flex flex-col md:flex-row justify-end gap-4'}>
          <BasicButton
            size="md"
            variant={'outlined'}
            clases="w-auto !rounded-[100px]"
            onClick={async () => {
              await handleClose()
              await triggerCallStatusForImportHc()
              // navigate(`/listings`)
            }}
          >
            Undo changes
          </BasicButton>
          <BasicButton
            size="md"
            variant={'contained'}
            clases="w-auto"
            color="red"
            onClick={() => {
              handleSubmit()
            }}
            isRadius100={true}
          >
            Confirm
          </BasicButton>
        </div>
      </BasicDialog>
    </>
  )
}

export default EditBulkConfirmDialog
