import { PropertyDetailProps } from '../../interfaces/listing'
import { useAppSelector } from '../../redux/hooks'
import { setLoading, setToastSuccess } from '../../redux/slices/common'
import { handleErrorMessage, isEmpty } from '../../utils/common'
import NativeListingBackAndContinue from './NativeListingBackAndContinue'
import RenderListAmenities from './RenderListAmenities'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { updateBulkProperties, updateProperty } from 'src/api/native-listing'
import { NATIVE_LISTING_MENU } from 'src/contants/common'
import { PROPERTY_STATUS } from 'src/contants/native'
import DisabledSectionOnEdit from '../common/DisabledSectionOnEdit'
import { setBulkListingData, setBulkListingSection } from 'src/redux/slices/listing'

interface Props {
  propertyId: string
  listingData: PropertyDetailProps | null
  fetchStatusStep: any
}

const NativeListingAmenities: React.FC<Props> = ({ propertyId, listingData, fetchStatusStep }) => {
  const isDraft = listingData?.status === PROPERTY_STATUS.DRAFT
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isOnboarding = parseQuery?.f === 'onboarding'
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const { isLoading } = useAppSelector((state) => state.common)
  const { bulkListingData, bulkListingSection } = useAppSelector((state) => state.listing)
  const [amenitiesSelected, setAmenitiesSelected] = useState<number[]>([])
  const [toggleEdit, setToggleEdit] = useState<any>({ amenities: false })

  useEffect(() => {
    const amenities = listingData?.amenities || []
    const selected = amenities.map((item) => item.id)
    setAmenitiesSelected(selected)
  }, [listingData?.amenities])
  useEffect(() => {
    if (!isEmpty(bulkListingData) && isEditBulk) {
      setAmenitiesSelected(bulkListingData?.amenities || [])
    }
  }, [bulkListingData])
  const onChangeAmenity = (newAmenities: number[]) => {
    setAmenitiesSelected(newAmenities)
  }

  const handleSubmit = async (isExit?: boolean) => {
    try {
      if (!propertyId && !isEditBulk) {
        handleErrorMessage({ message: 'Missing property id' })
        return
      }
      dispatch(setLoading(true))
      const amenities = amenitiesSelected.map((id) => ({ amenityId: id }))
      let res: any = null
      if (isEditBulk) {
        if (toggleEdit.amenities) {
          const propertyIds = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
          dispatch(setBulkListingData({ ...bulkListingData, amenities: amenitiesSelected }))
          res = await updateBulkProperties({ propertyIds, amenities })
          setToggleEdit({ amenities: false })
        }
      } else {
        const bodyData: any = { amenities }
        if (isDraft) bodyData.settingSteps = 5
        res = await updateProperty(propertyId, bodyData)
      }
      if (res?.data) {
        if (isExit) navigate(isOnboarding ? '/' : `/listings`)
        else if (isDraft) navigate(`/native-listing/${NATIVE_LISTING_MENU.DESCRIPTION}` + search)
        else dispatch(setToastSuccess({ message: 'Your changes have been applied.' }))
        fetchStatusStep()
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      dispatch(setLoading(false))
    }
  }

  return (
    <>
      <p className="text-24-32 md:text-28-36 text-neutral-800 font-maison-neue tracking-[-0.01em] mb-8">Amenities</p>
      <DisabledSectionOnEdit
        title={isEditBulk ? 'What amenities do these listings offer?' : 'What amenities does this listing offer?'}
        openEdit={toggleEdit.amenities}
        handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, amenities: value })}
      >
        <RenderListAmenities amenitiesSelected={amenitiesSelected} onChange={onChangeAmenity} />
      </DisabledSectionOnEdit>
      <NativeListingBackAndContinue
        isLoading={isLoading}
        onSubmit={handleSubmit}
        isEdit={!isDraft}
        isDisabledSubmit={isEditBulk ? !toggleEdit.amenities : false}
      />
      <div
        id={`${NATIVE_LISTING_MENU.AMENITIES}_save_exit`}
        className="hidden"
        onClick={() => handleSubmit(true)}
        role="presentation"
      />
      <div
        id={`${NATIVE_LISTING_MENU.AMENITIES}_save`}
        className="hidden"
        onClick={() => {
          if (isEditBulk) {
            if (isEmpty(bulkListingSection) && toggleEdit.amenities) {
              let result: string[] = []
              if (toggleEdit.amenities) result.push('What amenities do these listings offer')
              dispatch(setBulkListingSection(result.join(', ')))
            } else handleSubmit()
          } else handleSubmit()
        }}
        role="presentation"
      />
    </>
  )
}

export default NativeListingAmenities
