/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import * as React from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import ic_close from 'src/assets/icons/ic_close.svg'

interface Props extends TooltipProps {
  header?: string
  maxWidth?: number
}

const TooltipClick = (props: Props) => {
  const isArrow = props.arrow
  const maxWidth = props.maxWidth
  const LightTooltip = styled(({ className, ...tooltipProps }: TooltipProps) => (
    <Tooltip {...tooltipProps} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
      '&::before': {
        backgroundColor: theme.palette.common.black,
        border: '1px solid black',
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: isArrow ? theme.palette.common.black : theme.palette.common.white,
      boxShadow: isArrow ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : '0px 6px 24px rgba(0, 0, 0, 0.16)',
      padding: '8px 12px',
      borderRadius: '8px',
      maxWidth: maxWidth || 'initial',
      color: isArrow ? 'white' : '#50504E',
      margin: '0 16px',
      // textAlign: 'center',
      fontSize: '12px',
      lineHeight: '16px',
      fontFamily: 'Maison Neue Medium',
      fontWeight: 500
    },
  }))

  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const title = props.header ? (
    <div className="font-inter-500">
      <div
        // style={{ width: 'calc(100% + 48px)' }}
        className="flex items-center justify-between border-b border-b-neutral-300 mb-6 pb-6 "
      >
        <img src={ic_close} alt="" width={16} height={16} onClick={handleClose} className="cursor-pointer" role="presentation" />
        <span className="text-16-20 text-neutral-800 mx-10 whitespace-nowrap font-inter-500">{props.header}</span>
        <div className="w-4 h-4" />
      </div>
      {props.title}
    </div>
  ) : (
    props.title
  )
  return (
    <div>
      <ClickAwayListener onClickAway={handleClose}>
        <div className="flex items-start">
          <LightTooltip
            PopperProps={{ disablePortal: true }}
            onClose={handleClose}
            open={open}
            disableFocusListener
            disableHoverListener={props?.disableHoverListener}
            disableTouchListener
            title={title}
            arrow={props.arrow}
          >
            <div
              onClick={handleOpen}
              onMouseOver={() => {
                if (!open && !props?.disableHoverListener) handleOpen()
              }}
              onMouseLeave={() => {
                if (open && !props?.disableHoverListener) handleOpen()
              }}
              className={`w-auto flex items-start cursor-pointer ${open ? 'text-ocean-800' : 'text-neutral-600'}`}
              role="presentation"
            >
              {props.children}
            </div>
          </LightTooltip>
        </div>
      </ClickAwayListener>
    </div>
  )
}
export default TooltipClick
