import {
  getUserProfile,
  updateHostSettings,
  updateUserProfile,
  uploadLogoHost,
  uploadSettingImages,
} from '../../../api/user'
import ic_media_40_40 from '../../../assets/icons/ic_media_40_40.svg'
import ic_trash_full from '../../../assets/icons/ic_trash_full.svg'
import { ONBOARDING_STEP } from '../../../contants/user'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { handleNextOnboardingStep, setSettingData } from '../../../redux/slices/user'
import { getWidthHeightImage, handleErrorMessage } from '../../../utils/common'
import DisabledWrapper from '../../common/DisabledWrapper'
import BasicButton from '../../ui/BasicButton'
import LayoutOnboarding from '../LayoutOnboarding'
import RightContentLandingPage from './RightContentLandingPage'
import Popover from '@mui/material/Popover'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { ColorResult, SketchPicker } from 'react-color'
import ic_close from 'src/assets/icons/ic_close_md.svg'
import { saveUserInfo } from 'src/utils/user'

const DEFAULT_COLOR = '#000000'

const OnboardingCustomizeSite = () => {
  const dispatch = useAppDispatch()
  const inputLogo = useRef<HTMLInputElement | null>(null)
  const inputFavicon = useRef<HTMLInputElement | null>(null)
  const { settingData, profile } = useAppSelector((state) => state.user)
  const [isLoading, setIsLoading] = useState(false)
  const [logo, setLogo] = useState<string>()
  const [favicon, setFavicon] = useState<string>()
  const [color, setColor] = useState(settingData.primaryColor || DEFAULT_COLOR)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const isShowLandingPage = settingData.status === 'active'

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const onChangeComplete = (_color: ColorResult) => {
    if (_color) setColor(_color.hex)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClear = () => {
    setColor(settingData && settingData.primaryColor ? settingData.primaryColor : DEFAULT_COLOR)
  }

  const handleSaveColor = async () => {
    // save color
    try {
      const _primaryColor = color.toUpperCase() === '#FFFFFF' ? '#0F0F0F' : color
      const res = await updateHostSettings({ primaryColor: _primaryColor })
      if (res.success) {
        dispatch(setSettingData({ ...settingData, ...res.data }))
        handleClose()
      }
    } catch (err: any) {
      handleErrorMessage(err)
    }
  }

  useEffect(() => {
    setFavicon(settingData?.favicon || '')
  }, [settingData])
  useEffect(() => {
    setLogo(profile.hostLogo || '')
  }, [profile])

  const handleUploadLogo = async (e: any) => {
    const file: File = e.target.files[0]
    if (file) {
      const { width, height }: any = await getWidthHeightImage(file)
      if (Number(width) > 1920 || Number(height) > 1080) {
        handleErrorMessage({ message: 'Maximum logo image size: 1920x1080px' })
        return
      }
      if (Number(width) < 128 || Number(height) < 80) {
        handleErrorMessage({ message: 'Minimum logo image size: 128x80px' })
        return
      }
      try {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('file', file)
        const res: any = await uploadLogoHost(formData)
        if (res.success) {
          const hostLogo = res.data.hostLogo
          setLogo(hostLogo)
          saveUserInfo({ ...profile, hostLogo })
        }
      } catch (error: any) {
        handleErrorMessage(error)
      } finally {
        setIsLoading(false)
      }
    }
  }
  const handleUploadFavicon = async (e: any) => {
    const file: File = e.target.files[0]
    if (file) {
      const { width, height }: any = await getWidthHeightImage(file)
      if (width !== height) {
        handleErrorMessage({ message: 'Please use a photo with ratio 1:1 for favicon.' })
        return
      }
      if (Number(width) > 192 || Number(height) > 192) {
        handleErrorMessage({ message: 'The favicon cannot be bigger than 192x192.' })
        return
      }
      try {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('file', file)
        const res = await uploadSettingImages('favicon', formData)
        if (res.success) {
          setFavicon(res.data)
        }
      } catch (error: any) {
        handleErrorMessage(error)
      } finally {
        setIsLoading(false)
      }
    }
  }
  const renderFooterBtn = () => {
    return (
      <div className="flex items-center gap-4 pb-0 md:pb-6">
        <BasicButton
          size={'xl'}
          variant={'contained'}
          clases={'w-auto h-[48px] rounded-[100px_!important] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]'}
          color="red"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={!logo || !favicon}
        >
          Continue
        </BasicButton>
        <BasicButton
          size={'xl'}
          variant={'contained'}
          clases={
            'w-auto h-[48px] rounded-[100px_!important] border border-neutral-300 shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]'
          }
          color="white"
          onClick={handleNext}
        >
          Skip
        </BasicButton>
      </div>
    )
  }

  const handleNext = async () => {
    dispatch(handleNextOnboardingStep(ONBOARDING_STEP.SITE_DETAILS_CONTACT_INFO))
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const data: any = { favicon }
      const res = await updateHostSettings(data)
      const resUpdateProfile = await updateUserProfile({ ...profile, hostLogo: logo })
      if (res.success && res.data) {
        dispatch(setSettingData({ ...settingData, ...res.data }))
      }
      if (resUpdateProfile.success) {
        const resProfile: any = await getUserProfile()
        saveUserInfo({ ...profile, ...resProfile?.data })
      }
      if (res.success && resUpdateProfile.success) handleNext()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }
  const renderColorSection = () => {
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    const dontChangeColor = color === (settingData?.primaryColor || DEFAULT_COLOR)
    return (
      <>
        <div className="flex items-center justify-between gap-[24px] mb-[24px]">
          <div>
            <p className={'font-maison-neue text-20-24 text-neutral-800 mb-1'}>Color</p>
            <p className={'font-maison-neue text-14-18 text-neutral-500'}>Define your accent color</p>
          </div>
          <div>
            <button
              className={clsx(
                'w-[150px] min-w-[150px] h-[48px] bg-white rounded-[12px] px-[14px] py-[10px]',
                'flex items-center justify-between hover:bg-neutral-50 shadow-xs',
                'border-[0.5px] border-[rgba(0,0,0,0.15)]'
              )}
              onClick={handleOpen}
            >
              <span className="text-16-20 font-maison-neue text-neutral-800">{color}</span>
              <div className="w-5 h-5 rounded-[4px]" style={{ background: color }} />
            </button>
          </div>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              transform: 'translateY(8px)',
              padding: 0,
              boxShadow: '0px 10px 25px -5px rgba(0, 0, 0, 0.06), 0px 20px 50px -12px rgba(0, 0, 0, 0.1)',
              borderRadius: '12px',
              width: '280px',
              backgroundColor: '#F7F7F4',
            },
          }}
        >
          <div className={'bg-white flex items-center justify-between h-[68px] p-[24px]'}>
            <span>Primary Color</span>
            <button onClick={handleClose}>
              <img src={ic_close} alt={'ic_close'} />
            </button>
          </div>
          <div className={'p-[8px] flex items-center justify-center'}>
            <SketchPicker color={color} onChangeComplete={onChangeComplete} width={'278px'} />
          </div>
          <div className={'bg-white flex items-center justify-between h-[76px] px-[24px]'}>
            <BasicButton size={'md'} isRadius100={true} disabled={dontChangeColor} onClick={handleClear}>
              Clear
            </BasicButton>
            <BasicButton variant={'outlined'} size={'md'} isRadius100={true} onClick={handleSaveColor}>
              Apply
            </BasicButton>
          </div>
        </Popover>
      </>
    )
  }
  const renderHeroSection = () => {
    return (
      <>
        <div>
          <div className={'flex items-center justify-between gap-[24px] mb-[24px]'}>
            <div>
              <p className={'font-maison-neue text-20-24 text-neutral-800 mb-1'}>Logo</p>
              <p className={'font-maison-neue text-14-18 text-neutral-500'}>
                This will be displayed on your website and in email confirmation to your guests
              </p>
            </div>
            {logo && (
              <button
                className={
                  'w-[32px] min-w-[32px] h-[32px] bg-white rounded-full flex items-center justify-center hover:bg-neutral-50 shadow-xs'
                }
                onClick={() => setLogo('')}
              >
                <img src={ic_trash_full} alt={'ic_trash_full'} width={16} height={16} />
              </button>
            )}
          </div>
          <div
            className={'flex justify-center items-center w-full h-[240px] rounded-[24px]'}
            style={{
              border: '4px solid rgba(255, 255, 255, 1)',
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
              background: logo ? '#FFF' : '#F6F6F4',
            }}
          >
            {logo ? (
              <img src={logo} alt="" className="w-auto h-auto max-h-[100%] max-w-[100%]" />
            ) : (
              <button
                className={'flex justify-center items-center'}
                onClick={() => {
                  const inputEle = inputLogo.current as HTMLInputElement
                  inputEle.click()
                }}
              >
                <img src={ic_media_40_40} alt={'ic_media'} className={''} />
              </button>
            )}
          </div>
          <input
            ref={inputLogo}
            type={'file'}
            multiple={true}
            accept={'image/*'}
            className={'opacity-0 w-full h-full z-50 hidden'}
            onChange={handleUploadLogo}
            onClick={() => {
              const element = inputLogo.current as HTMLInputElement
              if (element) element.value = ''
            }}
          />
        </div>
        <div className={'w-full h-[0.5px] bg-neutral-900 opacity-[0.15] my-[32px]'} />

        <div>
          <div className={'flex items-center justify-between gap-[24px] mb-[24px]'}>
            <div>
              <p className={'font-maison-neue text-20-24 text-neutral-800 mb-1'}>Favicon</p>
              <p className={'font-maison-neue text-14-18 text-neutral-500'}>
                Build brand awareness and grow online credibility.
              </p>
            </div>
            {favicon && (
              <button
                className={
                  'w-[32px] min-w-[32px] h-[32px] bg-white rounded-full flex items-center justify-center hover:bg-neutral-50 shadow-xs'
                }
                onClick={() => setFavicon('')}
              >
                <img src={ic_trash_full} alt={'ic_trash_full'} width={16} height={16} />
              </button>
            )}
          </div>
          <div
            className={'flex justify-center items-center w-full h-[240px] rounded-[24px]'}
            style={{
              border: '4px solid rgba(255, 255, 255, 1)',
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
              background: favicon ? '#FFF' : '#F6F6F4',
            }}
          >
            {favicon ? (
              <img src={favicon} alt="hero" className="w-auto h-auto max-h-[100%] max-w-[100%]" />
            ) : (
              <button
                className={'flex justify-center items-center'}
                onClick={() => {
                  const inputEle = inputFavicon.current as HTMLInputElement
                  inputEle.click()
                }}
              >
                <img src={ic_media_40_40} alt={'ic_media'} className={''} />
              </button>
            )}
          </div>
          <input
            ref={inputFavicon}
            type={'file'}
            multiple={true}
            accept={'image/*'}
            className={'opacity-0 w-full h-full z-50 hidden'}
            onChange={handleUploadFavicon}
            onClick={() => {
              const element = inputFavicon.current as HTMLInputElement
              if (element) element.value = ''
            }}
          />
        </div>
      </>
    )
  }

  return (
    <LayoutOnboarding headerTitle={'Customize site'} rightContent={<RightContentLandingPage logo={logo} />}>
      <div className={'mt-[38px] lg:mt-[70px] w-full mx-auto md:max-w-[524px]'}>
        <h2 className={'font-maison-neue text-24-36 text-neutral-800 mb-[24px]'}>Customize your website brand</h2>

        {renderColorSection()}
        <div className={'w-full h-[0.5px] bg-neutral-900 opacity-[0.15] my-[32px]'} />

        <div>
          {isShowLandingPage ? renderHeroSection() : <DisabledWrapper>{renderHeroSection()}</DisabledWrapper>}
          <div className={'mt-[24px] hidden lg:block'}>{renderFooterBtn()}</div>
        </div>

        <div
          className={clsx(
            'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full z-[102]',
            'border-t border-neutral-300 px-4 md:px-8',
            'shadow-footer'
          )}
        >
          {renderFooterBtn()}
        </div>
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingCustomizeSite
