import React from 'react'

interface Props {
  children: React.ReactNode
}

const DisabledWrapper: React.FC<Props> = ({ children }) => {
  return (
    <div className={'relative'}>
      {children}
      <div
        className={
          'w-full h-full absolute top-0 bottom-0 left-0 right-0 opacity-60 z-[99] cursor-not-allowed bg-neutral-100'
        }
      />
    </div>
  )
}

export default DisabledWrapper
