import { getImportProgressByListIds } from '../../../api/listings'
import { getUserProfile } from '../../../api/user'
import { SYNC_STATUS } from '../../../contants/property'
import { ONBOARDING_STEP } from '../../../contants/user'
import { useAppDispatch } from '../../../redux/hooks'
import { handleNextOnboardingStep } from '../../../redux/slices/user'
import { clearAllIntervals, handleErrorMessage } from '../../../utils/common'
import LoadingSection from '../../common/LoadingSection'
import AirbnbPopupEditListing from '../../property-import-airbnb/AirbnbPopupEditListing'
import RenderListingItem from '../../property-import-airbnb/RenderListingItem'
import BasicButton from '../../ui/BasicButton'
import LayoutOnboarding from '../LayoutOnboarding'
import RightContentPmsCm from './RightContentPmsCm'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'

interface Props {
  editListings: any[]
  updateEditListings: (newListings: any[]) => void
  listIdImporting: string[]
  updateListIdImporting: (newListings: any[]) => void
}

const OnboardingAirbnbEditPrompt: React.FC<Props> = ({
  editListings,
  updateEditListings,
  listIdImporting,
  updateListIdImporting,
}) => {
  const intervalSyncProgress = useRef<any>(null)
  const dispatch = useAppDispatch()
  const [selectedListingToEdit, setSelectedListingToEdit] = useState<any>(null)
  const [isOpenEditData, setIsOpenEditData] = useState<boolean>(false)
  const [currentListIdEdited, setCurrentListIdEdited] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  async function fetchImportProgress(listPmsId: string[], listIdEdited: (string | number)[]) {
    setIsLoading(true)
    const convertListEdited = Array.isArray(listIdEdited) ? listIdEdited.map((item) => item.toString()) : []
    intervalSyncProgress.current = setInterval(async () => {
      try {
        const res = await getImportProgressByListIds(listPmsId)
        if (Array.isArray(res.data) && res.success) {
          const newListings = res.data.map((item) => {
            return {
              ...item,
              publicName: item.name,
              images: [item.thumbnailUrl],
              address: item.address,
              isSaved: convertListEdited.includes(item.dtravelPropertyId?.toString()),
            }
          })
          updateEditListings(newListings)
          const isEveryNotPending = res.data.every((item: any) => item.status !== SYNC_STATUS.PENDING)
          // case import done
          if (res.data.length > 0 && res.data.length === listPmsId.length && isEveryNotPending) {
            clearInterval(intervalSyncProgress.current)
            clearAllIntervals()
            intervalSyncProgress.current = null
            setIsLoading(false)
          }
        }
      } catch (err: any) {
        handleErrorMessage(err)
      }
    }, 3000)
  }

  useEffect(() => {
    async function fetchListImportingFromProfile() {
      const res: any = await getUserProfile()
      const gettingStarted = res.data?.gettingStarted
      console.log('-gettingStarted-', gettingStarted)
      if (Array.isArray(gettingStarted?.listIdImporting) && gettingStarted?.listIdImporting.length > 0) {
        // edge case: user stop and logout this step, then log in again -> get edit listings by listPmsId (in profile)
        // setListIdImporting(gettingStarted?.listIdImporting)
        setCurrentListIdEdited(
          Array.isArray(gettingStarted?.listIdEdited)
            ? gettingStarted?.listIdEdited.map((id: string | number) => String(id))
            : []
        )
        updateListIdImporting(
          Array.isArray(listIdImporting) && listIdImporting.length > 0
            ? listIdImporting
            : gettingStarted?.listIdImporting
        )
        fetchImportProgress(gettingStarted?.listIdImporting, gettingStarted?.listIdEdited)
      }
    }
    fetchListImportingFromProfile()
  }, [])

  useEffect(() => {
    return () => {
      clearAllIntervals()
    }
  }, [])

  const togglePopupEditData = () => {
    setIsOpenEditData((prevState) => !prevState)
  }

  const onClickEdit = (selctedData: any) => {
    setSelectedListingToEdit(selctedData)
    togglePopupEditData()
  }

  const afterEditListings = () => {
    togglePopupEditData()
  }

  const handleNext = async () => {
    dispatch(handleNextOnboardingStep(ONBOARDING_STEP.AIRBNB_CONNECT_SUCCESS))
  }

  const isHasSaved = editListings.some((item) => item.isSaved)

  const renderSubmitBtn = () => {
    return (
      <div className={'flex gap-[16px]'}>
        <BasicButton
          variant={'contained'}
          isRadius100={true}
          color={'red'}
          size={'xl'}
          onClick={handleNext}
          disabled={!isHasSaved}
        >
          {isHasSaved ? 'Finish' : 'Save'}
        </BasicButton>
        <BasicButton
          size={'xl'}
          variant={'contained'}
          clases={
            'w-auto h-[48px] rounded-[100px_!important] border border-neutral-300 shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]'
          }
          color="white"
          onClick={handleNext}
        >
          Skip
        </BasicButton>
      </div>
    )
  }

  return (
    <LayoutOnboarding headerTitle={'Listings'} rightContent={<RightContentPmsCm />}>
      <div className={'mt-[38px] lg:mt-[70px] w-full mx-auto md:max-w-[524px] pb-[112px]'}>
        <h2 className={'font-maison-neue text-24-36 text-neutral-800 mb-[4px]'}>
          Add missing info to your Dtravel listing(s)
        </h2>
        <p className={'font-maison-neue text-16-24 text-neutral-800 mb-[24px]'}>
          Dtravel wasn’t able to import all content so please fill in the missing info to complete your listing(s).
        </p>

        <div
          className={
            'rounded-[16px] bg-neutral-100 border border-neutral-300 h-auto max-h-[543px] overflow-y-auto min-h-[72px] relative'
          }
        >
          <LoadingSection isLoading={isLoading} size={30} />
          {Array.isArray(editListings) &&
            editListings.map((item, index) => {
              return <RenderListingItem key={item.id || index} item={item} onClickEdit={onClickEdit} />
            })}
        </div>

        <div className={'mt-[24px] hidden lg:block'}>{renderSubmitBtn()}</div>
      </div>

      <AirbnbPopupEditListing
        isOpen={isOpenEditData}
        onClose={togglePopupEditData}
        selectedListing={selectedListingToEdit}
        listings={editListings}
        updateListings={updateEditListings}
        onBack={togglePopupEditData}
        afterSubmit={afterEditListings}
        listIdImporting={listIdImporting}
        isOnboarding={true}
        currentListIdEdited={currentListIdEdited}
      />

      <div
        className={clsx(
          'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
          'border-t border-neutral-300 px-4 md:px-8',
          'shadow-footer'
        )}
      >
        {renderSubmitBtn()}
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingAirbnbEditPrompt
