import ic_minus from '../../../assets/icons/ic_minus.svg'
import ic_plus from '../../../assets/icons/ic_plus.svg'
import IconButton from '@mui/material/IconButton'
import React from 'react'

interface Props {
  advanceNotice: number | null | undefined
  onChange: (type: 'plus' | 'minus') => void
  isHospitable?: boolean
}

const AdvanceNotice: React.FC<Props> = ({ advanceNotice, onChange }) => {
  return (
    <div className={'flex flex-col md:flex-row justify-between items-center gap-[8px] md:gap-[32px] flex-1'}>
      <div>
        <p className={'font-maison-neue text-16-24 text-neutral-800'}>Advance notice</p>
        <p className={'font-maison-neue text-14-20 text-neutral-600'}>
          Define the minimum number of days between a guest&apos;s booking and their arrival
        </p>
      </div>
      <div className={'w-full md:w-auto min-w-[140px] flex items-center justify-between gap-[8px]'}>
        <IconButton size={'small'} onClick={() => onChange('minus')} disabled={advanceNotice === 0 || advanceNotice === -1}>
          <img src={ic_minus} alt={''} className={'w-[36px] h-[36px] min-w-[36px]'} />
        </IconButton>
        <div className={'px-[12px] text-center font-maison-neue text-24-36 text-neutral-800'}>{advanceNotice === -1 ? '' : advanceNotice}</div>
        <IconButton size={'small'} onClick={() => onChange('plus')} disabled={advanceNotice === 100}>
          <img src={ic_plus} alt={''} className={'w-[36px] h-[36px] min-w-[36px]'} />
        </IconButton>
      </div>
    </div>
  )
}

export default AdvanceNotice
