import { getUserInfo, saveUserInfo } from '../../../utils/user'
import RightContentIdentity from './RightContentIdentity'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { updateOnboardingInfo } from 'src/api/user'
import { CONNECT_BY } from 'src/components/connect-wallet/ConnectWalletProvider'
import LayoutOnboarding from 'src/components/onboarding/LayoutOnboarding'
import BasicButton from 'src/components/ui/BasicButton'
import BasicInput from 'src/components/ui/BasicInput'
import { ONBOARDING_STEP } from 'src/contants/user'
import { FormError } from 'src/interfaces'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { handleNextOnboardingStep, setProfile, updateOnboardingData } from 'src/redux/slices/user'
import { handleErrorMessage, isEmailValid, isOnlyLetters } from 'src/utils/common'

type IdentityData = {
  firstName: string
  lastName: string
  email: string
}

const OnboardingCreateAccount = () => {
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector((state) => state.user)
  const [data, setData] = useState<IdentityData>({
    firstName: '',
    lastName: '',
    email: '',
  })
  const [errors, setErrors] = useState<{ firstName: FormError; lastName: FormError; email: FormError }>({
    firstName: { show: false, message: '' },
    lastName: { show: false, message: '' },
    email: { show: false, message: '' },
  })
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (profile) {
      setData({
        firstName: profile.firstName || '',
        lastName: profile.lastName || '',
        email: profile.user?.email || '',
      })
    }
  }, [profile])

  const onChangeData = (fiield: string, value: string) => {
    setData((prevState) => ({ ...prevState, [fiield]: value }))
    setErrors((prevState) => ({ ...prevState, [fiield]: { show: false, message: '' } })) // clear error
    const additionData = { [fiield]: value }
    dispatch(updateOnboardingData(additionData))
  }

  const validateForm = () => {
    let key: keyof IdentityData
    let _errors = { ...errors }
    let isValid = true
    for (key in data) {
      if (key === 'firstName' || key === 'lastName') {
        if (typeof data[key] === 'string' && data[key] !== '' && !isOnlyLetters(data[key])) {
          _errors[key] = { show: true, message: 'Please enter letters only' }
          isValid = false
        } else if (!data[key] || !data[key].trim()) {
          _errors[key] = { show: true, message: 'Please enter valid value' }
          isValid = false
        }
      }
      if (key === 'email') {
        if (!data[key] || !data[key].trim() || !isEmailValid(data[key])) {
          _errors[key] = { show: true, message: 'Please enter a valid email' }
          isValid = false
        }
      }
    }
    setErrors(_errors)
    return isValid
  }

  const onSubmit = async () => {
    if (!validateForm()) return
    try {
      setLoading(true)
      await updateOnboardingInfo(data)
      const user = getUserInfo()
      const profile = { ...user, firstName: data.firstName, lastName: data.lastName }
      profile.user.email = data.email
      saveUserInfo(profile)
      dispatch(setProfile(profile))
      dispatch(handleNextOnboardingStep(ONBOARDING_STEP.PERSONA))
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setLoading(false)
    }
  }

  const renderSubmitBtn = () => {
    return (
      <BasicButton
        variant={'contained'}
        isRadius100={true}
        color={'red'}
        size={'xl'}
        onClick={onSubmit}
        loading={loading}
        disabled={!data.firstName || !data.lastName || !data.email}
      >
        Continue
      </BasicButton>
    )
  }

  return (
    <LayoutOnboarding headerTitle={'Create account'} rightContent={<RightContentIdentity />}>
      <div className={'mt-[38px] lg:mt-[70px] flex justify-center'}>
        <div className={' w-full md:max-w-[524px]'}>
          <h2 className={'font-maison-neue text-24-36 text-neutral-800'}>Welcome! Let’s create your account:</h2>
          <div className={'flex gap-[16px] mt-[16px]'}>
            <div className={'w-1/2'}>
              <BasicInput
                label={'First name'}
                placeholder={'Add a first name...'}
                value={data.firstName}
                onChange={(event) => onChangeData('firstName', event.target.value)}
                onBlur={(event) => onChangeData('firstName', event.target.value.trim())}
                error={errors.firstName}
                name={'firstName'}
              />
            </div>
            <div className={'w-1/2'}>
              <BasicInput
                label={'Last name'}
                placeholder={'Add a last name...'}
                value={data.lastName}
                onChange={(event) => onChangeData('lastName', event.target.value)}
                onBlur={(event) => onChangeData('lastName', event.target.value.trim())}
                error={errors.lastName}
                name={'lastName'}
              />
            </div>
          </div>
          <div className={'mt-[16px]'}>
            <BasicInput
              label={'Email'}
              placeholder={'Enter your email'}
              value={data.email}
              onChange={(event) => onChangeData('email', event.target.value)}
              onBlur={(event) => onChangeData('email', event.target.value.trim())}
              disabled={profile?.connectBy === CONNECT_BY.EMAIL || profile?.user?.email}
              error={errors.email}
            />
          </div>
          <div className={'mt-[24px] hidden lg:block'}>{renderSubmitBtn()}</div>
        </div>
      </div>

      <div
        className={clsx(
          'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
          'border-t border-neutral-300 px-4 md:px-8',
          'shadow-footer'
        )}
      >
        {renderSubmitBtn()}
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingCreateAccount
