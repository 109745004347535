import IconButton from '@mui/material/IconButton'
import React from 'react'
import ic_minus from 'src/assets/icons/ic_minus.svg'
import ic_plus from 'src/assets/icons/ic_plus.svg'

interface Props {
  minNights: number
  onChangeMinNights: (type: 'plus' | 'minus') => void
}

const MinimumStayGlobal: React.FC<Props> = ({ minNights, onChangeMinNights }) => {
  return (
    <div className="flex flex-col md:flex-row md:items-center justify-between gap-[8px] md:gap-[32px]">
      {/* <div> */}
      <p className={'font-maison-neue text-16-24 text-neutral-800'}>Minimum nights per stay</p>
      {/* <p className={'font-maison-neue text-14-20 text-neutral-600'}>
          Define the minimum number of nights guest have to stay
        </p> */}
      {/* </div> */}
      <div className={'flex items-center justify-between gap-[8px]'}>
        <IconButton size={'small'} onClick={() => onChangeMinNights('minus')} disabled={minNights === 1 || minNights === -1}>
          <img src={ic_minus} alt={''} className={'w-[36px] h-[36px] min-w-[36px]'} />
        </IconButton>
        <div className={'px-[12px] text-center font-maison-neue text-24-36 text-neutral-800'}>{minNights === -1 ? '' : minNights}</div>
        <IconButton size={'small'} onClick={() => onChangeMinNights('plus')} disabled={minNights === 100}>
          <img src={ic_plus} alt={''} className={'w-[36px] h-[36px] min-w-[36px]'} />
        </IconButton>
      </div>
    </div>
  )
}

export default MinimumStayGlobal
