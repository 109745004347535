import ic_arrow_right_white from '../../../assets/icons/ic_arrow_right_white.svg'
import ic_one_line_44 from '../../../assets/icons/ic_one_line_44.svg'
import onboarding_group_133516 from '../../../assets/images/onboarding_group_133516.png'
import onboarding_group_133517 from '../../../assets/images/onboarding_group_133517.png'
import SettingChannelManagement from '../../settings/pms-and-channel/SettingChannelManagement'
import BasicButton from '../../ui/BasicButton'
import LayoutOnboarding from '../LayoutOnboarding'
import clsx from 'clsx'
import React from 'react'
import ic_gvr from 'src/assets/icons/ic_gvr.svg'

interface Props {
  handleFinish: () => void
}

const OnboardingDistributionChannels: React.FC<Props> = ({ handleFinish }) => {
  const renderRightContent = () => {
    return (
      <div className={'flex items-center'}>
        <div className={'relative flex items-center justify-center '}>
          <div
            className={
              'z-20 flex flex-col gap-[10px] border-[5.3px] border-black rounded-[21.5px] px-[10px] pt-[10px] bg-neutral-100'
            }
          >
            <img src={onboarding_group_133516} alt={''} />
            <img src={onboarding_group_133517} alt={''} />
          </div>
        </div>
        <div>
          <img src={ic_one_line_44} alt={'ic_line_44'} className={'w-full'} />
        </div>
        <div
          className={
            'w-[64px] min-w-[64px] h-[64px] flex items-center justify-center border border-neutral-300 rounded-full bg-white ml-[12px]'
          }
        >
          <img src={ic_gvr} alt={'ic_gvr'} width={54} height={54} className={'rounded-full'} />
        </div>
        {/*<div>*/}
        {/*  <div className={'relative mb-[-1.5px]'}>*/}
        {/*    <img src={ic_line_44} alt={'ic_line_44'} className={'w-full'} />*/}
        {/*    <div*/}
        {/*      className={*/}
        {/*        'w-[64px] min-w-[64px] h-[64px] flex items-center justify-center border border-neutral-300 rounded-full absolute right-[-75px] top-[-25px] bg-white'*/}
        {/*      }*/}
        {/*    >*/}
        {/*      <img src={ic_gvr} alt={'ic_gvr'} width={54} height={54} className={'rounded-full'} />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className={'relative'}>*/}
        {/*    <div>*/}
        {/*      <img src={ic_line_45} alt={'ic_line_45'} className={'w-full'} />*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*      className={*/}
        {/*        'w-[64px] min-w-[64px] h-[64px] flex items-center justify-center border border-neutral-300 rounded-full absolute right-[-75px] bottom-[-24px] bg-white'*/}
        {/*      }*/}
        {/*    >*/}
        {/*      <img src={ic_sensei} alt={'ic_sensei'} width={28} height={34} className={'rounded-full'} />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    )
  }

  const renderSubmitBtn = () => {
    return (
      <BasicButton variant={'contained'} isRadius100={true} color={'red'} size={'xl'} onClick={handleFinish}>
        Finish
        <img src={ic_arrow_right_white} alt={'ic_arrow_right'} className="w-5 h-5 ml-2" />
      </BasicButton>
    )
  }

  return (
    <LayoutOnboarding headerTitle={'Distribution channels'} rightContent={renderRightContent()}>
      <div className={'mt-[38px] lg:mt-[70px] w-full mx-auto md:max-w-[524px]'}>
        <h2 className={'font-maison-neue text-24-36 text-neutral-800 mb-[24px]'}>
          Which channels would you like to distribute your listings to?
        </h2>
        <SettingChannelManagement isOnboarding />

        <div className={'mt-[24px] hidden lg:block'}>{renderSubmitBtn()}</div>
      </div>

      <div
        className={clsx(
          'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
          'border-t border-neutral-300 px-4 md:px-8',
          'shadow-footer'
        )}
      >
        {renderSubmitBtn()}
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingDistributionChannels
