import { getSupportCurrencies } from '../../../api/reservation'
// import { SUPPORTED_CURRENCIES } from '../../../contants/property'
import { FormError } from '../../../interfaces'
import BasicInputPrice from '../../ui/BasicInputPrice'
import Popover from '@mui/material/Popover'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import ic_chevron_down from 'src/assets/icons/ic_chevron_down_neutral_500.svg'

interface Props {
  currency: string
  onChangeCurrency: (_curr: string) => void
  basePrice: number | undefined
  onChangeBasePrice: (rate?: string) => void
  onBlurBasePrice: any
  error: FormError
}

const RateBase: React.FC<Props> = ({
  currency,
  onChangeCurrency,
  basePrice,
  onChangeBasePrice,
  onBlurBasePrice,
  error,
}) => {
  const btnRef = useRef<HTMLButtonElement | null>(null)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  // eslint-disable-next-line
  const [supportedCurrencies, setSupportedCurrencies] = useState<Array<{ key: string; sumbol: string }>>([])

  useEffect(() => {
    async function fetchSupportedCurrencies() {
      const res = await getSupportCurrencies()
      if (Array.isArray(res.data)) {
        setSupportedCurrencies(res.data.filter((item: any) => item.type === 'FIAT'))
      }
    }
    fetchSupportedCurrencies()
  }, [])

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <>
      <p className="font-maison-neue text-20-32 text-neutral-800 mb-[8px]">Base rates</p>
      <div className="flex items-start gap-4">
        <div className="w-[110px] min-w-[110px]">
          <button
            className={clsx(
              'flex items-center justify-between gap-[8px] px-[16px] h-[64px] rounded-[12px]',
              'border-[0.5px] border-[rgba(0, 0, 0, 0.15)]',
              'shadow-xs',
              'min-w-[110px]'
            )}
            onClick={handleOpen}
          >
            <span className={'font-maison-neue text-24-28 text-neutral-900'}>{currency}</span>
            <span className="w-[24px] min-w-[24px]">
              <img src={ic_chevron_down} alt={'ic_chevron_down'} />
            </span>
          </button>

          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: -4,
              horizontal: 'right',
            }}
            PaperProps={{
              style: {
                padding: '0',
                boxShadow: '0px 20px 24px -4px rgba(0, 0, 0, 0.06), 0px 8px 8px -4px rgba(0, 0, 0, 0.03)',
                borderRadius: '12px',
                border: '1px solid #F7F7F4',
                width: btnRef && btnRef.current ? btnRef.current.offsetWidth + 'px' : '110px',
              },
            }}
          >
            <div>
              {supportedCurrencies.map((item) => {
                {
                  /*{supportedCurrencies.map((item) => {*/
                }
                return (
                  <button
                    key={item.key}
                    className={clsx(
                      'px-[16px] text-16-20 w-full py-[8px] text-left',
                      `${currency === item.key
                        ? 'text-neutral-900 font-maison-neue-medium'
                        : 'text-neutral-600 font-maison-neue'
                      }`
                    )}
                    onClick={() => {
                      onChangeCurrency(item.key)
                      handleClose()
                    }}
                  >
                    {item.key}
                  </button>
                )
              })}
            </div>
          </Popover>
        </div>
        <div className="w-full flex items-center justify-center text-24-36 text-neutral-800 font-maison-neue">
          <BasicInputPrice
            size={'lg'}
            clases={'text-right'}
            value={basePrice}
            onValueChange={(values) => onChangeBasePrice(values.value)}
            onBlur={onBlurBasePrice}
            error={error}
          />
        </div>
      </div>
    </>
  )
}

export default RateBase
