import clsx from 'clsx'
import React, { InputHTMLAttributes, useRef } from 'react'

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  classes?: string
  isWidthHeight20px?: boolean
}

const BasicRadio: React.FC<RadioProps> = ({ classes, isWidthHeight20px, ...props }) => {
  const ref = useRef<any>(null)

  return (
    <span
      className={clsx('radioContainer', {
        size20: isWidthHeight20px,
      })}
    >
      <input ref={ref} {...props} type="radio" className={clsx('', classes)} />
      <span className={isWidthHeight20px ? 'radioCheckmark20px' : 'radioCheckmark'}></span>
    </span>
  )
}

export default BasicRadio
