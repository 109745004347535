import { getRateCurrency } from '../../api/integration'
import { updateListingHospitable } from '../../api/listings'
import { getFeeSettings, getPropertyDetail, getTaxSettings } from '../../api/native-listing'
import { updateStepOnboarding } from '../../api/user'
import { DEFAULT_CHECKIN_TIME, DEFAULT_CHECKOUT_TIME } from '../../contants/common'
import { AMOUNT_TYPE_VALUE, MAP_FEES_APPLIED_PER } from '../../contants/native'
import { FormError } from '../../interfaces'
import { PropertyDetailProps, UpdateListingHospitable } from '../../interfaces/listing'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setLoading, setRateObjBaseOnUsd, setRatesBaseOnUsd, setToastSuccess } from '../../redux/slices/common'
import { handleErrorMessage, isHourFormat } from '../../utils/common'
import { IcArrowLeft } from '../common/Icons'
import CustomFees from '../native-listing/fees/CustomFees'
import CustomTaxes from '../native-listing/fees/CustomTaxes'
import RenderCleaningFee from '../native-listing/fees/RenderCleaningFee'
import RenderExtraGuestFee from '../native-listing/fees/RenderExtraGuestFee'
import BasicButton from '../ui/BasicButton'
import BasicCheckbox from '../ui/BasicCheckbox'
import BasicDialog from '../ui/BasicDialog'
import EditAmenities from './EditAmenities'
import EditHouseRules from './EditHouseRules'
import EditLosAndAvailability from './EditLosAndAvailability'
import RenderListingItem from './RenderListingItem'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  afterSubmit?: () => void
  selectedListing: any
  listings: any[]
  updateListings: (newListings: any[]) => void
  onBack: () => void
  listIdImporting?: string[]
  isOnboarding?: boolean
  currentListIdEdited?: string[]
}

const TABS = [
  {
    key: 'house_rules',
    label: 'House rules',
  },
  {
    key: 'amenities',
    label: 'Amenities',
  },
  // {
  //   key: 'los_and_availability',
  //   label: 'LoS & Availability',
  // },
  {
    key: 'fees_and_taxes',
    label: 'Fees & Taxes',
  },
]

const DEFAULT_DATA: UpdateListingHospitable = {
  id: 0,
  checkinTime: DEFAULT_CHECKIN_TIME,
  checkoutTime: DEFAULT_CHECKOUT_TIME,
  houseRules: '',
  cancellationPolicy: '',
  maxPet: 0,
  amenities: [],
  // availabilityWindow: 0,
  // advanceNotice: 0,
  taxSettings: [],
  feeSettings: [],
}

const MIN_GUEST = 1

const AirbnbPopupEditListing: React.FC<Props> = ({
  isOpen,
  onClose,
  onBack,
  selectedListing,
  listings,
  updateListings,
  afterSubmit,
  listIdImporting,
  isOnboarding,
  currentListIdEdited = [],
}) => {
  const dispatch = useAppDispatch()
  const { onboardingStep } = useAppSelector((state) => state.user)
  // eslint-disable-next-line
  const { rateObjBaseOnUsd } = useAppSelector((state) => state.common)
  const [selectedTab, setSelectedTab] = useState<string>('house_rules')
  const [data, setData] = useState<UpdateListingHospitable>(DEFAULT_DATA)
  const [listingDataDtravel, setListingDataDtravel] = useState<PropertyDetailProps | null>(null) // listing data in Dtravel
  const [amenitiesSelected, setAmenitiesSelected] = useState<number[]>([])
  const [isLoadingSubmitSingle, setIsLoadingSubmitSingle] = useState<boolean>(false)
  const [cleaningFee, setCleaningFee] = useState<{
    id: number
    type: string
    value: number | string | undefined
    appliedPer: string
  }>({
    id: 0,
    type: 'cleaningFee',
    value: undefined,
    appliedPer: 'reservation',
  })
  const [extraGuestFee, setExtraGuestFee] = useState<{
    type: string
    value: number | string | undefined
    appliedPer: string
    additionalGuestAfter: number
  }>({
    type: 'extraGuestFee',
    value: undefined,
    appliedPer: 'night',
    additionalGuestAfter: 1,
  })
  const [errors, setErrors] = useState<{ cleaningFee: FormError; extraGuestFee: FormError }>({
    cleaningFee: { message: '', show: false },
    extraGuestFee: { message: '', show: false },
  })
  const [isEnable, setIsEnable] = useState<{ cleaningFee: boolean; extraGuestFee: boolean }>({
    cleaningFee: false,
    extraGuestFee: false,
  })
  const [maxGuest, setMaxGuest] = useState<number>(100)
  const [dtravelPropertyId, setDtravelPropertyId] = useState<number>(0)
  const [isApplyAll, setIsApplyAll] = useState<boolean>(false)
  const currency = listingDataDtravel?.propertyPrice?.currency || 'USD'

  const updateDataFromDtravelDetail = (
    listingDataDtravel: PropertyDetailProps,
    taxSettings: any[],
    feeSettings: any[]
  ) => {
    const {
      defaultCheckInTime,
      defaultCheckOutTime,
      houseRules,
      amenities,
      // advanceNotice,
      // availabilityWindow,
      propertyPrice,
      personCapacity,
      maxPet,
    } = listingDataDtravel
    let checkInTime = defaultCheckInTime || DEFAULT_CHECKIN_TIME
    if (isHourFormat(defaultCheckInTime)) {
      checkInTime = DEFAULT_CHECKIN_TIME
    }
    if (checkInTime === '00' || checkInTime === '00:00') {
      checkInTime = '0'
    }
    let checkOutTime = defaultCheckOutTime || DEFAULT_CHECKOUT_TIME
    if (checkOutTime === '00' || checkOutTime === '00:00') {
      checkOutTime = '0'
    }
    if (isHourFormat(defaultCheckOutTime)) {
      checkOutTime = DEFAULT_CHECKOUT_TIME
    }
    let cancelPolicyDescription = listingDataDtravel.cancelPolicyDescription
    setData((prevState) => {
      return {
        ...prevState,
        id: listingDataDtravel.id,
        checkinTime: checkInTime,
        checkoutTime: checkOutTime,
        houseRules: houseRules || '',
        cancellationPolicy: cancelPolicyDescription || '',
        maxPet: maxPet || 0,
        taxSettings: Array.isArray(taxSettings) ? taxSettings : [],
        feeSettings: Array.isArray(feeSettings) ? feeSettings : [],
      }
    })
    // update extra guest fees
    const { extraPersonFee, guestsIncludedInRegularFee } = propertyPrice || {}
    setIsEnable((prevState) => ({ ...prevState, extraGuestFee: Number(extraPersonFee) > 0 }))
    setExtraGuestFee({
      type: 'extraGuestFee',
      value: Number(extraPersonFee) === 0 ? '' : Number(extraPersonFee), // do not show 0 in case disabled
      appliedPer: 'night',
      additionalGuestAfter: Number(guestsIncludedInRegularFee) || 1,
    })
    // update amenities
    const selected = (Array.isArray(amenities) ? amenities : []).map((item) => item.id)
    setAmenitiesSelected(selected)
    // update max guest
    setMaxGuest(personCapacity)
  }

  async function fetchPropertyDetail(_propertyId: number | string) {
    try {
      const [resDetail, resTax, resFee] = await Promise.all([
        getPropertyDetail(_propertyId),
        getTaxSettings(_propertyId),
        getFeeSettings(_propertyId),
      ])
      setListingDataDtravel(resDetail.success && resDetail.data ? resDetail.data : null)
      const taxSettings = resTax.data.filter((item: any) => item.taxType === 'otherTaxes')
      const feeSettings = resFee.data.filter((item: any) => item.feeType === 'otherFees')
      updateDataFromDtravelDetail(resDetail.data, taxSettings, feeSettings)

      // cleaning fee
      const _cleaningFee = resFee.data.find((item: any) => item.feeType === 'cleaningFee')
      setIsEnable((prevState) => ({ ...prevState, cleaningFee: !!_cleaningFee }))
      if (_cleaningFee) {
        const appliedPer = MAP_FEES_APPLIED_PER[_cleaningFee.feeAppliedPer] || _cleaningFee.feeAppliedPer
        setCleaningFee({
          id: _cleaningFee.id,
          type: 'cleaningFee',
          value: Number(_cleaningFee.amount),
          appliedPer: appliedPer,
        })
      } else {
        // set to default
        setCleaningFee({ id: 0, type: 'cleaningFee', value: undefined, appliedPer: 'reservation' })
      }
    } catch (err: any) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (selectedListing && selectedListing.dtravelPropertyId) {
      setDtravelPropertyId(selectedListing.dtravelPropertyId)
    }
  }, [selectedListing])

  useEffect(() => {
    if (dtravelPropertyId) {
      fetchPropertyDetail(dtravelPropertyId)
    }
  }, [dtravelPropertyId, isOpen])

  useEffect(() => {
    async function fetchRateCurrency() {
      try {
        dispatch(setLoading(true))
        const res = await getRateCurrency()
        if (Array.isArray(res.data)) {
          dispatch(setRatesBaseOnUsd(res.data))
          const rateObj: any = {}
          for (let item of res.data) {
            rateObj[item.key] = item
          }
          dispatch(setRateObjBaseOnUsd(rateObj))
        }
      } finally {
        dispatch(setLoading(false))
      }
    }

    fetchRateCurrency()
  }, [])

  const onChangeData = (field: string, value: any) => {
    setData((prevState: any) => {
      return { ...prevState, [field]: value }
    })
  }

  const onChangeAmenity = (newAmenities: number[]) => {
    setAmenitiesSelected(newAmenities)
  }

  const onChangeExtraGuest = (type: 'plus' | 'minus') => {
    if (type === 'plus') {
      setExtraGuestFee((prevState) => {
        const currenVal = prevState.additionalGuestAfter
        const newVal = currenVal === maxGuest ? maxGuest : currenVal + 1
        return { ...prevState, additionalGuestAfter: newVal }
      })
    } else {
      setExtraGuestFee((prevState) => {
        const currenVal = prevState.additionalGuestAfter
        const newVal = currenVal === MIN_GUEST ? MIN_GUEST : currenVal - 1
        return { ...prevState, additionalGuestAfter: newVal }
      })
    }
  }

  const onChangeCleaningFee = (field: 'value' | 'appliedPer', value: number | string | undefined) => {
    setCleaningFee((prevState) => ({ ...prevState, [field]: value }))
    if (field === 'value') {
      setErrors((prevState) => ({ ...prevState, cleaningFee: { show: false, message: '' } }))
    }
  }

  const onChangeExtraGuestFee = (field: 'value' | 'appliedPer', value: number | string | undefined) => {
    setExtraGuestFee((prevState) => ({ ...prevState, [field]: value }))
    if (field === 'value') {
      setErrors((prevState) => ({ ...prevState, extraGuestFee: { show: false, message: '' } }))
    }
  }

  const onOnOffFee = (type: 'cleaningFee' | 'extraGuestFee', checked: boolean) => {
    setIsEnable((prevState) => ({ ...prevState, [type]: checked }))
    setErrors((prevState) => ({ ...prevState, [type]: { show: false, message: '' } }))
  }

  const onClickBack = () => {
    onBack()
    // reset data
    setData(DEFAULT_DATA)
    setDtravelPropertyId(0)
  }

  const onChangeApplyAll = (isChecked: boolean) => {
    setIsApplyAll(isChecked)
  }

  const handleBackAndContinueStep = (type: 'back' | 'continue') => {
    switch (selectedTab) {
      case 'house_rules':
        type === 'back' ? onClickBack() : setSelectedTab('amenities')
        return
      case 'amenities':
        type === 'back' ? setSelectedTab('house_rules') : setSelectedTab('fees_and_taxes')
        return
      case 'fees_and_taxes':
        type === 'back' ? setSelectedTab('amenities') : onSubmit()
        return
    }
  }

  const onSubmit = async () => {
    try {
      let payloadData: any[] = []
      const objAmenityIds = amenitiesSelected.map((id) => ({ amenityId: id }))
      const convertData = { ...data, amenities: objAmenityIds }
      if (isEnable.extraGuestFee) {
        convertData.price = {
          priceForExtraPerson: extraGuestFee.value,
          guestsIncludedInRegularFee: extraGuestFee.additionalGuestAfter,
        }
      }
      if (Array.isArray(convertData.feeSettings) && convertData.feeSettings.length > 0) {
        convertData.feeSettings = convertData.feeSettings.map((item) => {
          const appliedPer = item.amountType === AMOUNT_TYPE_VALUE.FLAT ? item.feeAppliedPer : 'reservation'
          return {
            ...item,
            amount: Number(item.amount),
            feeType: 'otherFees',
            feeAppliedPer: appliedPer,
            isMandatory: true,
            isQuantitySelectable: false,
          }
        })
      }
      if (isEnable.cleaningFee) {
        // update cleaning fee via Array feeSettings
        convertData.feeSettings = [
          ...convertData.feeSettings,
          {
            feeTitle: 'Cleaning fee',
            amountType: AMOUNT_TYPE_VALUE.FLAT,
            feeAppliedPer: cleaningFee.appliedPer,
            amount: Number(cleaningFee.value),
            feeType: 'cleaningFee',
            isMandatory: true,
            isQuantitySelectable: false,
          },
        ]
      }
      if (Array.isArray(convertData.taxSettings) && convertData.taxSettings.length > 0) {
        convertData.taxSettings = convertData.taxSettings.map((item) => {
          const appliedPer = item.amountType === AMOUNT_TYPE_VALUE.FLAT ? item.taxAppliedPer : 'reservation'
          return { ...item, amount: Number(item.amount), taxType: 'otherTaxes', taxAppliedPer: appliedPer }
        })
      }
      if (isApplyAll) {
        payloadData = listings.map((item) => {
          return { ...convertData, id: item.dtravelPropertyId }
        })
      } else {
        setIsLoadingSubmitSingle(true)
        payloadData = [convertData]
      }

      await updateListingHospitable(payloadData)
      const ids = payloadData.map((item) => String(item.id))
      const savedIds = [...new Set([...ids, ...currentListIdEdited])]
      updateListings(
        listings.map((item) => {
          if (savedIds.includes(String(item.dtravelPropertyId))) {
            item.isSaved = true
          }
          return item
        })
      )
      if (isOnboarding) {
        // handle save current selected ids to DB (for case user logout this step -> lost requestId -> can't show Edit listing screen)
        // const listIdImporting = payload.map((item) => item.pmsPropertyId)
        await updateStepOnboarding(onboardingStep, listIdImporting || [], savedIds)
      }
      if (typeof afterSubmit === 'function') afterSubmit()
      setSelectedTab('house_rules')
      dispatch(setToastSuccess({ message: 'Update missing data success!' }))
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoadingSubmitSingle(false)
    }
  }

  const handleClosePopup = () => {
    onClose()
    // clear listings
    updateListings([])
  }

  return (
    <BasicDialog isOpen={isOpen} onClose={handleClosePopup} maxWidth={'md'}>
      <div className={clsx('')}>
        <div className={'flex items-center justify-between'}>
          <button className={'w-[24px] h-[24px] gap-[16px]'} onClick={() => handleBackAndContinueStep('back')}>
            <IcArrowLeft />
          </button>
          <div className={'flex-1 text-center font-maison-neue text-24-28 text-neutral-800'}>
            Edit {selectedListing?.publicName}
          </div>
          <div className={'w-[24px] h-[24px] gap-[16px]'} />
        </div>
        <div
          className={clsx(
            'mt-[24px] rounded-[16px]',
            'bg-neutral-100 border border-neutral-300',
            // 'h-[640px]',
            'h-auto'
          )}
        >
          {selectedListing && <RenderListingItem item={selectedListing} />}

          <div className={'p-[16px] flex flex-col md:flex-row gap-[24px]'}>
            <div className={'flex flex-row md:flex-col justify-start md:items-center w-full md:w-[140px] gap-[16px]'}>
              {TABS.map((item) => {
                const isActive = selectedTab === item.key
                return (
                  <button
                    key={item.key}
                    className={clsx(
                      'h-[34px] md:h-auto rounded-[40px] px-[12px] py-[8px] md:px-0 md:py-0',
                      'text-14-18',
                      'hover:bg-neutral-300 md:hover:bg-inherit',
                      'w-full text-center md:text-left',
                      'md:hover:font-maison-neue-demibold',
                      'cursor-auto',
                      {
                        'font-maison-neue-medium bg-neutral-300 md:bg-inherit': isActive,
                        'font-maison-neue': !isActive,
                        'md:font-maison-neue-demibold': isActive,
                        'md:font-maison-neue': !isActive,
                      }
                    )}
                    // onClick={() => setSelectedTab(item.key)}
                  >
                    {item.label}
                  </button>
                )
              })}
            </div>

            <div className={'overflow-y-auto hidden-scroll-bar min-h-[400px] max-h-[400px] flex-1'}>
              {selectedTab === 'house_rules' && <EditHouseRules data={data} onChangeData={onChangeData} />}
              {selectedTab === 'amenities' && (
                <EditAmenities amenitiesSelected={amenitiesSelected} onChangeAmenity={onChangeAmenity} />
              )}
              {selectedTab === 'los_and_availability' && (
                <EditLosAndAvailability data={data} onChangeData={onChangeData} />
              )}
              {selectedTab === 'fees_and_taxes' && (
                <>
                  {/* ---Cleaning fee--- */}
                  <RenderCleaningFee
                    isEnable={isEnable.cleaningFee}
                    toggleFee={(checked) => onOnOffFee('cleaningFee', checked)}
                    cleaningFee={cleaningFee}
                    onChangeCleaningFee={onChangeCleaningFee}
                    currency={currency}
                    error={errors.cleaningFee}
                    hiddenTitle
                  />

                  {/* ---Extra guest fee--- */}
                  <RenderExtraGuestFee
                    isEnable={isEnable.extraGuestFee}
                    toggleFee={(checked) => onOnOffFee('extraGuestFee', checked)}
                    extraGuestFee={extraGuestFee}
                    onChangeFee={onChangeExtraGuestFee}
                    onChangeExtraGuest={onChangeExtraGuest}
                    currency={currency}
                    error={errors.extraGuestFee}
                    maxGuest={maxGuest}
                  />

                  {/* ---Custom fees--- */}
                  <CustomFees
                    propertyId={selectedListing?.dtravelPropertyId}
                    currency={currency}
                    feeSettings={data.feeSettings}
                    updateFees={(newData: any[]) => onChangeData('feeSettings', newData)}
                  />

                  {/* ---Custom taxes--- */}
                  <CustomTaxes
                    propertyId={selectedListing?.dtravelPropertyId}
                    currency={currency}
                    taxSettings={data.taxSettings}
                    updateTaxes={(newData: any[]) => onChangeData('taxSettings', newData)}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        {/*  ---Footer--- */}
        <div className={'flex items-center justify-between gap-[16px] mt-[24px]'}>
          <label htmlFor={'apply_all_listing'} className={'flex items-center gap-[8px]'}>
            <BasicCheckbox
              id={'apply_all_listing'}
              checked={isApplyAll}
              onChange={(event) => onChangeApplyAll(event.target.checked)}
            />
            <span className={'ml-[16px] mt-[6px] font-maison-neue text-16-20 text-neutral-800'}>
              Apply to all imported listings
            </span>
          </label>
          <BasicButton
            variant={'contained'}
            size={'md'}
            isRadius100={true}
            color={'red'}
            // onClick={() => onSubmit()}
            onClick={() => handleBackAndContinueStep('continue')}
            loading={isLoadingSubmitSingle}
          >
            {['house_rules', 'amenities'].includes(selectedTab) && 'Continue'}
            {['fees_and_taxes'].includes(selectedTab) && 'Finish'}
          </BasicButton>
        </div>
      </div>
    </BasicDialog>
  )
}

export default AirbnbPopupEditListing
