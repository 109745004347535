import { getMinimumStay, updateMinimumStay } from '../../../api/listings'
import { handleErrorMessage } from '../../../utils/common'
import ICalListing from './ICalListing'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getICalCalendar, updateProperty } from 'src/api/native-listing'
import NativeListingBackAndContinue from 'src/components/native-listing/NativeListingBackAndContinue'
// import ExportICalCalendar from 'src/components/native-listing/availability/ExportICalCalendar'
import ImportICalCalendar from 'src/components/native-listing/availability/ImportICalCalendar'
import { NATIVE_LISTING_MENU, PMS_TYPE } from 'src/contants/common'
import { PROPERTY_STATUS } from 'src/contants/native'
import { PropertyDetailProps } from 'src/interfaces/listing'
import { useAppSelector } from 'src/redux/hooks'
import { setLoading, setToastSuccess } from 'src/redux/slices/common'
import { isChannexAirbnb, isPropertyAirbnb } from 'src/utils/property'
import DisabledSectionOnEdit from 'src/components/common/DisabledSectionOnEdit'
import BasicInput from 'src/components/ui/BasicInput'

interface Props {
  propertyId: string
  listingData: PropertyDetailProps | null
  currentMinNights?: number
  currentMaxNights?: number
  fetchStatusStep: any
}

const NativeListingICal: React.FC<Props> = ({ propertyId, listingData, fetchStatusStep }) => {
  const isDraft = listingData?.status === PROPERTY_STATUS.DRAFT
  const isAirbnb = isPropertyAirbnb(listingData)
  const isChannex = isChannexAirbnb(listingData)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isOnboarding = parseQuery?.f === 'onboarding'
  const { isLoading } = useAppSelector((state) => state.common)
  const { profile } = useAppSelector((state) => state.user)
  const [listICal, setListICal] = useState<any[]>([])
  const [minNights, setMinNights] = useState<number>(1)
  const [advanceNotice, setAdvanceNotice] = useState<number | null | undefined>(0)
  const [availabilityWindow, setAvailabilityWindow] = useState<number | null | undefined>(0)
  const isHospitable = listingData?.pmsType === PMS_TYPE.HOSPITABLE

  useEffect(() => {
    if (propertyId) {
      fetchICalCalendar(propertyId)
      fetchMinimumStay(propertyId)
    }
  }, [propertyId])

  useEffect(() => {
    if (listingData) {
      if (isHospitable) {
        setAdvanceNotice(listingData.advanceNotice)
        setAvailabilityWindow(listingData.availabilityWindow)
      } else {
        setAdvanceNotice(listingData.advanceNotice ?? 0)
        setAvailabilityWindow(listingData.availabilityWindow ?? 730)
      }
    }
  }, [listingData, isHospitable])

  async function fetchICalCalendar(_propertyId: string) {
    const res = await getICalCalendar(_propertyId)
    setListICal(res.data)
  }

  async function fetchMinimumStay(_propertyId: string) {
    try {
      const res = await getMinimumStay(_propertyId)
      if (res.success) {
        setMinNights(res.data.minNights)
      }
    } catch (err: any) {
      console.log(err)
    }
  }

  const handleSubmit = async (isExit?: boolean) => {
    try {
      dispatch(setLoading(true))
      // update minNights global
      if (!isHospitable && !isAirbnb && !isChannex) {
        await updateMinimumStay({ listingId: Number(propertyId), minNights })
      }
      const bodyData: any = { maxNights: 90, advanceNotice, availabilityWindow }
      if (isDraft) {
        bodyData.settingSteps = 9
      }
      await updateProperty(propertyId, bodyData)
      if (isExit) navigate(isOnboarding ? '/' : `/listings`)
      else if (isDraft) navigate(`/native-listing/${NATIVE_LISTING_MENU.FEES}` + search)
      else dispatch(setToastSuccess({ message: 'Your changes have been applied.' }))
      fetchStatusStep()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      dispatch(setLoading(false))
    }
  }
  const onCopy = (url: string) => {
    navigator.clipboard.writeText(url)
    dispatch(setToastSuccess({ message: `Copied!`, duration: 2000 }))
  }
  const icalURL = process.env.REACT_APP_API_SERVICE_URL + `/listing-service/v1/native-property/${profile?.user?.icalKey || ''}/${propertyId}/ical`
  return (
    <>
      <div className="flex flex-col gap-8">
        <p className="text-28-36 text-neutral-800 font-maison-neue tracking-[-0.01em]">
          Sync availability via iCal
        </p>

        <DisabledSectionOnEdit
          title="Inbound calendar"
          subTitle='Add an external calendar to import your listing availability on Dtravel'
          isDisable={isHospitable}
        >
          <div className="p-[24px] bg-white w-full mb-[16px] rounded-[16px] flex flex-col gap-6">
            {/* List iCal */}
            <ICalListing propertyId={propertyId} listICal={listICal} fetchICal={fetchICalCalendar} fetchStatusStep={fetchStatusStep} />

            <div className="flex flex-col md:flex-row gap-4">
              <ImportICalCalendar propertyId={propertyId} fetchICal={fetchICalCalendar} fetchStatusStep={fetchStatusStep} />
              {/* <ExportICalCalendar propertyId={propertyId} /> */}
            </div>
          </div>
        </DisabledSectionOnEdit>
        <DisabledSectionOnEdit
          title="Outbound calendar"
          subTitle='Export this list calendar to other booking platforms to avoid double bookings'
        >
          <div className="p-[24px] bg-white w-full mb-[16px] rounded-[16px] flex flex-col">
            <p className="mb-2 text-neutral-800 text-14-18 font-maison-neue-medium">iCal</p>
            <div className="flex flex-col md:flex-row gap-4">
              <BasicInput
                label={''}
                value={icalURL}
                showIconCopy={!!icalURL}
                onCopy={() => onCopy(icalURL)}
                style={{
                  color: '#8C8C88',
                  fontSize: '16px',
                  lineHeight: '20px',
                  fontFamily: 'Maison Neue Regular',
                }}
              />
            </div>
          </div>
        </DisabledSectionOnEdit>
      </div>

      {/*--- Footer: Back and Preview ---*/}
      <NativeListingBackAndContinue isLoading={isLoading} onSubmit={handleSubmit} isEdit={!isDraft} />
      <div
        id={`${NATIVE_LISTING_MENU.ICAL}_save_exit`}
        className="hidden"
        onClick={() => handleSubmit(true)}
        role="presentation"
      />
      <div
        id={`${NATIVE_LISTING_MENU.ICAL}_save`}
        className="hidden"
        onClick={() => handleSubmit()}
        role="presentation"
      />
    </>
  )
}

export default NativeListingICal
