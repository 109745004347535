import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import BasicCard from 'src/components/common/BasicCard'
import RenderCryptoCurrency from 'src/components/common/RenderCryptoCurrency'
import TooltipClick from 'src/components/ui/TooltipClick'
import { FEES_AND_DISCOUNT } from 'src/contants/common'
import { ReservationInfo, ReservationStatusEnum } from 'src/interfaces/reservations'
import { isEmpty } from 'src/utils/common'

interface Props {
  data: ReservationInfo
}

const ReservationChargeToGuest: React.FC<Props> = ({ data }) => {
  const { priceInfoV2, currency, source } = data
  const isManualReservation = source === 'manual'
  const isInquiry = data?.status === ReservationStatusEnum.INQUIRY
  const isManualCancelled = data?.status === ReservationStatusEnum.MANUAL_CANCELLED
  const isExpired = data?.status === ReservationStatusEnum.EXPIRED
  const isInquiryManual = isManualReservation && isInquiry
  const hiddenCreditCardFee = isManualReservation && (isInquiryManual || isManualCancelled || isExpired)
  if (!priceInfoV2) {
    return null
  }
  const { exchangeRate } = priceInfoV2
  const ratePaymentCurrency = Number(exchangeRate?.rates[currency])

  const { fee, tax, discount, addons, promotionDiscount } = priceInfoV2.priceDetail
  const discountCreditCardProcessingFee: any =
    Array.isArray(discount) && discount.find((it) => it.name === 'creditCardProcessingFee')

  const feeData: Array<any> = (fee || []).filter(
    (el: any) => el.isIncludedInTotalPrice && !(hiddenCreditCardFee && el?.name === 'creditCardProcessingFee')
  )
  const taxData: Array<any> = (tax || []).filter((el: any) => el.isIncludedInTotalPrice)
  const addonsData: Array<any> = (addons || []).filter((el: any) => el.isIncludedInTotalPrice)
  const discountData: Array<any> = (discount || []).filter(
    (el: any) =>
      el.isIncludedInTotalPrice &&
      el.name !== 'creditCardProcessingFee' &&
      !(hiddenCreditCardFee && el?.name === 'creditCardFeesAbsorbedByHost')
  )
  const promotionDiscountData: Array<any> = (promotionDiscount || []).filter((el: any) => el.isIncludedInTotalPrice)

  const convertValueCurrency = (val: number) => {
    return val * ratePaymentCurrency
  }

  const renderGroupContent = (data: Array<any>, title: string) => {
    const isPromoCode = title === 'Promotion'
    if (isPromoCode) {
      return (
        <>
          {data?.map((el: any, idx: number) => {
            return (
              <div
                className={`flex items-center justify-between mb-6 text-14-18 text-grayscale-900 font-inter-400`}
                key={idx}
              >
                <div className="flex items-center gap-2">
                  <span className="text-neutral-900 font-maison-neue text-16-20">Promo code</span>
                  <div className='flex items-center gap-2 bg-forest-50 rounded-[8px] p-2'>
                    {/* <img src={ic_promotion} alt="" width={16} height={16} /> */}
                    <span className='text-16-20 font-maison-neue-medium text-forest-700'>{el?.name}</span>
                  </div>
                </div>
                <span className="text-neutral-900 font-maison-neue-medium text-16-20">
                  <RenderCryptoCurrency price={convertValueCurrency(el?.value || 0)} currency={currency || 'USD'} isDiscount />
                </span>
              </div>
            )
          })}
        </>
      )
    }
    return (
      <>
        <p className="border-t border-t-grayscale-200 pt-[16px] font-maison-neue text-16-20 text-neutral-900 mb-[12px]">
          {title}
        </p>
        <ul className="list-none custom-ul-price">
          {!isEmpty(data) &&
            data.map((el, index) => {
              return (
                <li key={`${title}-${index}`} className={'flex mb-[12px]'}>
                  <div className="flex justify-between w-full">
                    <span className="text-neutral-600 font-maison-neue text-16-20">
                      {el.title || FEES_AND_DISCOUNT[el.name] || el.name}
                    </span>
                    <span className="text-neutral-900 font-maison-neue-medium text-16-20">
                      <RenderCryptoCurrency price={convertValueCurrency(el?.value || 0)} currency={currency || 'USD'} />
                    </span>
                  </div>
                </li>
              )
            })}
        </ul>
      </>
    )
  }

  return (
    <>
      <div className="flex justify-between items-center mb-[16px] mt-[40px] md:mt-0">
        <p className=" font-maison-neue text-20-24 text-neutral-900">{'Price breakdown'}</p>
        {isManualReservation && (
          <TooltipClick
            placement="bottom"
            maxWidth={294}
            title={`This amount is subject to change, pending guest add-ons, card processing fees, etc., and will be finalized once your guest completes the booking.`}
          >
            <span className="text-grayscale-600 font-maison-neue-medium text-14-14 pr-[8px]">
              How manual reservations work
            </span>
          </TooltipClick>
        )}
      </div>
      <BasicCard>
        {/* <p className={'text-16-24 mt-[-16px]'}>Payment made by guest in {currency}</p> */}
        <div className={'flex justify-between mb-[16px]'}>
          <span className={'flex items-center text-neutral-900 font-maison-neue text-16-20'}>
            <RenderCryptoCurrency
              price={convertValueCurrency(priceInfoV2.basePricePerNight)}
              currency={currency || 'USD'}
            />
            &nbsp;x&nbsp;{priceInfoV2.nights} {priceInfoV2.nights > 1 ? 'nights' : 'night'}
          </span>
          <span className="text-neutral-900 font-maison-neue-medium text-16-20">
            <RenderCryptoCurrency price={convertValueCurrency(priceInfoV2.price)} currency={currency || 'USD'} />
          </span>
        </div>
        {!isEmpty(discountData) && renderGroupContent(discountData, 'Discount')}
        {!isEmpty(feeData) && renderGroupContent(feeData, 'Fees')}
        {!isEmpty(addonsData) && !isInquiryManual && renderGroupContent(addonsData, 'Additional services')}
        {!isEmpty(taxData) && renderGroupContent(taxData, 'Taxes')}
        {!isEmpty(promotionDiscountData) && renderGroupContent(promotionDiscountData, 'Promotion')}

        {/* Show Credit card processing fee waived */}
        {discountCreditCardProcessingFee && !hiddenCreditCardFee && (
          <div className={'flex justify-between w-full border-t border-t-grayscale-200 pt-[16px]'}>
            <span className="font-maison-neue text-16-20 text-neutral-900">
              Credit card processing fee waived{' '}
              <Tooltip
                placement="top"
                title={'This fee is discounted when you book with crypto.'}
                sx={{ fontSize: 16 }}
                arrow
              >
                <HelpOutlineIcon />
              </Tooltip>
            </span>
            <span className="text-neutral-900 font-maison-neue-medium text-16-20">
              <RenderCryptoCurrency
                price={
                  convertValueCurrency(discountCreditCardProcessingFee?.value) ||
                  priceInfoV2?.platformFee?.[currency || 'USD'] ||
                  0
                }
                currency={currency || 'USD'}
                isDiscount
              />
            </span>
          </div>
        )}

        <div className={'flex justify-between bg-grayscale-100 rounded-[16px] p-[24px] mt-[24px]'}>
          <span className="text-neutral-900 font-maison-neue-medium text-16-20">Total charge to guest</span>
          <span className={'text-neutral-900 font-maison-neue-medium text-16-20'}>
            <RenderCryptoCurrency
              price={Number(isInquiryManual ? data?.finalPrice : priceInfoV2?.finalPrice?.[currency || 'USD'] || 0)}
              currency={currency || 'USD'}
              isDynamic
            />
          </span>
        </div>
      </BasicCard>
    </>
  )
}

export default ReservationChargeToGuest