import { Drawer } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import React from 'react'
import ic_close from 'src/assets/icons/ic_close.svg'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

interface Props {
  isOpen: boolean
  onClose: () => void
  children?: any
  title?: string
  extraTitle?: string
}

const BasicDialogCalendar: React.FC<Props> = ({ children, isOpen, onClose, title, extraTitle }) => {
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768

  const renderContent = () => {
    return (
      <>
        <div className={'flex items-center justify-between bg-white p-[24px]'}>
          <div className={'w-full p-[8px]'}>
            <p className={'font-maison-neue-medium text-16-20 text-neutral-900'}>{title}</p>
            {extraTitle && <p className={'font-maison-neue text-14-20 text-neutral-600 mt-[4px]'}>{extraTitle}</p>}
          </div>
          <IconButton size={'small'} onClick={onClose}>
            <img src={ic_close} alt="" style={{ width: 20, height: 20 }} role="presentation" />
          </IconButton>
        </div>
        {children}
      </>
    )
  }

  if (isMobile) {
    return (
      <Drawer
        anchor={'bottom'}
        open={isOpen}
        onClose={onClose}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            borderRadius: '24px 24px 0px 0px',
            height: 'auto',
            maxHeight: 'calc(100% - 16px)',
          },
        }}
      >
        {renderContent()}
      </Drawer>
    )
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={'md'}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '24px',
          padding: 0,
          position: 'relative',
          backgroundColor: '#F7F7F4',
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.04)',
          overflowY: 'auto',
          scrollbarWidth: 'none',
          minWidth: '768px',
          '-ms-overflow-style': 'none' /* IE 10+ */,
          '&::-webkit-scrollbar': {
            background: 'transparent',
            width: 0,
          },
          'scroll-behavior': 'smooth',
        },
        '& .MuiDialogContent-root': {
          padding: 0,
          overflowY: 'initial',
        },
      }}
    >
      <DialogContent>{renderContent()}</DialogContent>
    </Dialog>
  )
}

export default BasicDialogCalendar
