import { handleErrorMessage } from '../../../utils/common'
import { renderPmsLabel } from '../../../utils/user'
import Popover from '@mui/material/Popover'
import clsx from 'clsx'
import React, { useRef } from 'react'
import ic_check from 'src/assets/icons/ic_check_black_20_20.svg'
import ic_chevron_down from 'src/assets/icons/ic_chevron_down_20_20.svg'
import ic_chevron_up from 'src/assets/icons/ic_chevron_up_20_20.svg'
import ConnectPms from 'src/components/connect-pms/ConnectPms'
import { LIST_PMS, PMS_TYPE } from 'src/contants/common'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setConnectDtravelApiType, setIsOpenPopupViewDtravelApi } from 'src/redux/slices/common'
import { setIsOpenPopupConnectPms, setPmsTypeSelected } from 'src/redux/slices/user'

interface Props {
  countProperty?: any
  title?: string
  placeholder?: string
  maxWidth?: number
  onSelect?: (pmsType: string) => void
  pmsSelected?: string
  otherLabel?: string
  disabledOptionIfConnected?: boolean
  disabled?: boolean
}

const SelectPms: React.FC<Props> = ({
  countProperty,
  title,
  maxWidth,
  placeholder,
  onSelect,
  pmsSelected,
  otherLabel,
  disabledOptionIfConnected,
  disabled,
}) => {
  const btnRef = useRef<HTMLButtonElement | null>(null)
  const dispatch = useAppDispatch()
  const { pmsTypeConnected } = useAppSelector((state) => state.user)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  const handleOpenListPms = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseListPms = () => {
    setAnchorEl(null)
  }

  const handleClickOnePms = async (pmsType: string) => {
    if (typeof onSelect === 'function') {
      onSelect(pmsType)
      handleCloseListPms()
      return
    } else {
      if (pmsType !== PMS_TYPE.OTHER && pmsTypeConnected) {
        handleErrorMessage({ message: 'Please disconnect your current PMS to connect with a new one.' })
        return
      }
      // connect via clientId and clientSecret key
      if ([PMS_TYPE.HOSTAWAY, PMS_TYPE.UPLISTING, PMS_TYPE.GUEST_SMILES, PMS_TYPE.NEXTPAX].includes(pmsType)) {
        dispatch(setIsOpenPopupConnectPms(true))
        dispatch(setPmsTypeSelected(pmsType))
        handleCloseListPms()
        return
      }
      // connect via open api
      if ([PMS_TYPE.GUESTY, PMS_TYPE.RENTAL_WISE].includes(pmsType)) {
        dispatch(setIsOpenPopupViewDtravelApi(true)) // open popup dtravel api
        dispatch(setConnectDtravelApiType(pmsType))
        handleCloseListPms()
        return
      }
      // open link to documents
      if (pmsType === PMS_TYPE.OTHER) {
        window.open('https://dtravel.featureos.app/b/feedback?tag_id=46603', '_blank')
        handleCloseListPms()
        return
      }
    }
  }

  return (
    <>
      <div
        className={clsx(
          'flex flex-col md:flex-row gap-[12px] justify-between items-start md:items-center bg-white rounded-[12px]',
          {
            'justify-between': !!title,
          }
        )}
      >
        {title && <div className={'font-maison-neue-medium text-16-20 text-neutral-800'}>{title}</div>}
        <button
          ref={btnRef}
          className={clsx(
            'h-[48px] rounded-[12px] w-full flex items-center justify-between px-[14px]',
            'font-maison-neue text-16-20',
            'disabled:cursor-not-allowed disabled:text-neutral-500 disabled:bg-neutral-50',
            {
              'border border-neutral-300 shadow-basic': !open,
              'border border-neutral-900 shadow-active': open,
              'text-neutral-500': !pmsSelected,
              'text-neutral-900': !!pmsSelected,
            }
          )}
          style={maxWidth ? { maxWidth: `${maxWidth}px` } : {}}
          onClick={handleOpenListPms}
          disabled={disabled}
        >
          <span>
            {pmsSelected ? renderPmsLabel(pmsSelected, otherLabel) : placeholder ? placeholder : 'Choose from...'}
          </span>
          <span>
            <img src={open ? ic_chevron_up : ic_chevron_down} alt={'ic_chevron'} />
          </span>
        </button>
      </div>
      <Popover
        id={'simple-popover'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseListPms}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            padding: '12px 8px',
            boxShadow: '0px 20px 24px -4px rgba(0, 0, 0, 0.06), 0px 8px 8px -4px rgba(0, 0, 0, 0.03)',
            borderRadius: '12px',
            border: '1px solid #F7F7F4',
            width: btnRef && btnRef.current ? btnRef.current.offsetWidth + 'px' : '320px',
          },
        }}
      >
        <div>
          {LIST_PMS.map((pmsType) => {
            const isConnected =
              pmsTypeConnected === pmsType ||
              (![PMS_TYPE.HOSTAWAY, PMS_TYPE.UPLISTING, PMS_TYPE.GUEST_SMILES, PMS_TYPE.NEXTPAX].includes(pmsType) &&
                countProperty &&
                countProperty[pmsType] > 0)
            if (pmsType === PMS_TYPE.HOSPITABLE || pmsType === PMS_TYPE.AIRBNB) return <></>
            return (
              <button
                key={pmsType}
                className={clsx(
                  'flex justify-between items-center gap-[8px] h-[44px] px-[14px] rounded-[12px]',
                  'font-maison-neue-medium text-16-20 text-neutral-800',
                  'w-full',
                  'hover:bg-neutral-50',
                  'disabled:cursor-not-allowed'
                )}
                disabled={disabledOptionIfConnected && isConnected}
                onClick={() => handleClickOnePms(pmsType)}
              >
                <span className={''}>{renderPmsLabel(pmsType, otherLabel)}</span>
                {isConnected && (
                  <span>
                    <img src={ic_check} alt={'ic_check'} />
                  </span>
                )}
              </button>
            )
          })}
        </div>
      </Popover>

      <ConnectPms />
    </>
  )
}

export default SelectPms
