import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import React from 'react'

const TooltipCalendar = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#FFFFFF',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    padding: '8px 12px',
    borderRadius: '8px',
    color: '#50504E',
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'Maison Neue Medium',
  },
  [`& .${tooltipClasses.tooltipPlacementTop}`]: {
    marginBottom: '8px !important',
  },
}))

export default TooltipCalendar
