import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { confirmAccountEmail, confirmContactEmail, confirmEmail, confirmUnifyEmail } from 'src/api/user'
import ic_alarm from 'src/assets/icons/ic_alarm.svg'
// import { logOut } from 'src/utils/user'
import ic_dtravel_black from 'src/assets/icons/ic_dtravel_black.svg'
import ic_mail_open from 'src/assets/icons/ic_mail_open.svg'
import { isEmpty } from 'src/utils/common'

const ConfirmPage = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const searchParams = queryString.parse(search)
  const [expired, setExpired] = useState<string | null | boolean>(null)
  const [isLoading, setLoading] = useState<boolean>(true)
  const [errorMsg, setErrorMsg] = useState<string>('')

  const isConfirmContactEmail = searchParams.type === 'email'
  const isConfirmUnifyEmail = searchParams.type === 'unify'
  const isConfirmUpdateAccountEmail = searchParams.type === 'update_email'

  const handleError = (error: any) => {
    if (error?.code === 'U1001') {
      setExpired(error?.email || true)
      setErrorMsg('')
    } else {
      setErrorMsg(error?.message)
    }
  }
  const handleConfirmUnifyEmail = async () => {
    try {
      setLoading(true)
      await confirmUnifyEmail({ token: searchParams?.token as string, context: searchParams?.context as string })
    } catch (error: any) {
      handleError(error)
    } finally {
      setLoading(false)
    }
  }
  const handleConfirmContactEmail = async () => {
    try {
      setLoading(true)
      await confirmContactEmail({ token: searchParams?.token as string, context: searchParams?.context as string })
    } catch (error: any) {
      handleError(error)
    } finally {
      setLoading(false)
    }
  }
  const handleConfirmEmail = async () => {
    try {
      setLoading(true)
      await confirmEmail({ token: searchParams?.token as string, context: searchParams?.context as string })
    } catch (error: any) {
      handleError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleConfirmUpdateEmail = async () => {
    try {
      setLoading(true)
      await confirmAccountEmail({ token: searchParams?.token as string, context: searchParams?.context as string })
    } catch (error: any) {
      handleError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (searchParams.token) {
      if (isConfirmContactEmail) handleConfirmContactEmail()
      else if (isConfirmUnifyEmail) handleConfirmUnifyEmail()
      else if (isConfirmUpdateAccountEmail) handleConfirmUpdateEmail()
      else handleConfirmEmail()
    }
  }, [search])

  const handleResendEmail = () => {
    navigate(`/login?email=${expired}`, { replace: true })
  }

  if (isLoading) return <></>
  return (
    <>
      <div className="flex w-full h-screen bg-black items-center justify-center">
        <div
          className="bg-white px-8 py-12 rounded-[32px] flex items-center justify-center flex-col max-w-[448px] min-w-[448px]"
          style={{ boxShadow: '0px 24px 88px rgba(0, 0, 0, 0.08)' }}
        >
          {expired ? (
            <img src={ic_alarm} alt="" style={{ width: 48, height: 48 }} />
          ) : isConfirmContactEmail ? (
            <img src={ic_mail_open} alt="" style={{ width: 48, height: 48 }} />
          ) : (
            <img src={ic_dtravel_black} alt="" style={{ width: 'auto', height: 16 }} />
          )}
          <p
            className={`mb-4 font-editorial-new text-28-36 text-center ${
              expired ? 'mt-[16px] text-neutral-8' : 'text-black-400 mt-[48px]'
            }`}
          >
            {!isEmpty(errorMsg)
              ? errorMsg
              : expired
              ? 'Your link has expired'
              : isConfirmContactEmail
              ? 'Your email was successfully verified!'
              : 'You’re Logged in!'}
          </p>
          {expired ? (
            <>
              {isConfirmContactEmail ? (
                <>
                  <p className="text-neutral-8 text-16-24 font-maison-neue text-center">
                    Go back to your original tab and request a new <br /> authentication link.
                  </p>
                  <p className="text-neutral-7 text-14-20 font-maison-neue text-center">You can close this window.</p>
                </>
              ) : (
                <>
                  <p className="text-neutral-6 text-16-24 font-maison-neue text-center">
                    Send another authentication link to <br />
                    <span className="text-neutral-8">{expired}</span>. Tap the button in that email to
                    <br /> log in or sign up.
                  </p>
                  <button
                    className="text-white text-14-14 font-maison-neue-medium bg-neutral-8 rounded-2xl py-5 w-full mt-6 cursor-pointer"
                    onClick={handleResendEmail}
                  >
                    Resend email
                  </button>
                </>
              )}
            </>
          ) : (
            <p className="text-neutral-6 text-16-24 font-maison-neue text-center">
              {isConfirmUnifyEmail ? (
                <>
                  Go back to your original tab to complete the account
                  <br />
                  verification process. You can close this window.
                </>
              ) : (
                'Go back to your original tab. You can close this window.'
              )}
            </p>
          )}
        </div>
      </div>
    </>
  )
}

export default ConfirmPage
