import React from 'react'

interface Props {
  message: string
  onChangeMessage: (mess: string) => void
}

const ReservationMessage: React.FC<Props> = ({ message, onChangeMessage }) => {
  return (
    <div>
      <p className={'flex justify-between items-center'}>
        <span className={'font-maison-neue text-20-24 text-neutral-900'}>Message to your guest</span>
      </p>
      <div className={'mt-[16px]'}>
        <textarea
          className={
            'bg-white h-[272px] font-maison-neue text-16-20 text-neutral-900 w-full rounded-[12px] p-[16px] focus:outline-none'
          }
          style={{ border: '0.5px solid rgba(0, 0, 0, 0.15)', minHeight: '160px' }}
          value={message}
          onChange={(event) => onChangeMessage(event.target.value)}
        />
      </div>
    </div>
  )
}

export default ReservationMessage
