import LayoutOnboarding from '../LayoutOnboarding'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getListings } from 'src/api/listings'
import ic_edit_black from 'src/assets/icons/ic_edit_black.svg'
import ic_plus_black from 'src/assets/icons/ic_plus_black.svg'
import ic_home_listing from 'src/assets/images/ic_home_listing.svg'
import onboarding_native_listing from 'src/assets/images/onboarding_native_listing.png'
import RenderPrice from 'src/components/common/RenderPrice'
import BasicButton from 'src/components/ui/BasicButton'
import { NATIVE_LISTING_MENU, NATIVE_LISTING_STEPS } from 'src/contants/common'
import { PROPERTY_SOURCE, PROPERTY_STATUS } from 'src/contants/native'
import { ONBOARDING_STEP } from 'src/contants/user'
import { useAppDispatch } from 'src/redux/hooks'
import { handleNextOnboardingStep } from 'src/redux/slices/user'
import { isEmpty } from 'src/utils/common'

const OnboardingNativeListing = () => {
  const dispatch = useAppDispatch()
  const [listing, setListing] = useState<Array<any>>([])
  const fetchData = async () => {
    try {
      const res: any = await getListings({ page: 1, pageSize: 1000, channel: 'native' })
      if (res?.data) setListing(res?.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  const onSubmit = async () => {
    dispatch(handleNextOnboardingStep(ONBOARDING_STEP.SITE_DETAILS_SITE_NAME))
  }
  const renderRightContent = () => {
    return (
      <div className="h-full flex pt-[300px] overflow-hidden">
        <img src={onboarding_native_listing} alt="" className="h-[868px] w-[400px]" />
      </div>
    )
  }
  const renderFooterBtn = () => {
    return (
      <div className="flex items-center gap-4">
        <BasicButton
          size={'xl'}
          variant={'contained'}
          clases={'w-auto h-[48px] rounded-[100px_!important] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]'}
          color="red"
          onClick={() => onSubmit()}
          disabled={isEmpty(listing)}
        >
          Continue
        </BasicButton>
        {isEmpty(listing) && (
          <BasicButton
            size={'xl'}
            variant={'contained'}
            clases={
              'w-auto h-[48px] rounded-[100px_!important] border border-neutral-300 shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]'
            }
            color="white"
            onClick={() => onSubmit()}
          >
            Skip
          </BasicButton>
        )}
      </div>
    )
  }
  return (
    <LayoutOnboarding rightContent={renderRightContent()} headerTitle="Listings">
      <div className="pb-[136px] lg:pb-[64px] pt-[38px] lg:pt-[64px] max-w-[524px] mx-auto overflow-auto">
        <p className="mb-[4px] text-24-36 text-neutral-800">Add your listings manually</p>
        <p className="mb-[24px] text-16-24 text-neutral-800">
          Create your listings by filling in your property details
        </p>
        <div className="flex flex-col gap-4">
          {!isEmpty(listing) &&
            listing.map((el: any, idx: number) => {
              const isDraft = el?.status === PROPERTY_STATUS.DRAFT
              const settingSteps = el?.settingSteps || 1
              const nativeURL = isDraft
                ? `/native-listing/${NATIVE_LISTING_STEPS[String(settingSteps)]}?id=${el?.id}`
                : `/native-listing/${NATIVE_LISTING_MENU.PROPERTY}?id=${el?.id}`
              return (
                <div
                  className={clsx(
                    'w-ful rounded-[16px] flex items-center justify-between gap-4 px-4 py-6 bg-white',
                    'shadow-[0px_1px_2px_0px_rgba(0,0,0,0.04),0px_1px_3px_0px_rgba(0,0,0,0.04)]'
                  )}
                  key={idx}
                >
                  <div className="flex items-center gap-3">
                    <img
                      src={el?.thumbnail || ic_home_listing}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = ic_home_listing
                      }}
                      alt="thumb"
                      className="rounded-[8px] min-w-[40px] w-[40px] h-[40px] object-cover"
                    />
                    <div className="flex flex-col">
                      <span className="text-14-18 text-neutral-900 font-maison-neue-medium">{el?.externalName}</span>
                      {!isDraft && (
                        <span className="text-14-18 text-neutral-600 font-maison-neue">
                          {[el?.address?.city, el?.address?.country].join(', ')}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    {!isDraft && (
                      <div className="flex flex-col">
                        <span className="text-12-16 text-neutral-800 font-maison-neue">Starting from</span>
                        <span className="text-14-20 text-neutral-800 font-maison-neue-medium">
                          {Number(el.propertyPrice?.basePrice) >= 0 ? (
                            <RenderPrice
                              price={Number(el.propertyPrice?.basePrice)}
                              currency={el.propertyPrice?.currency || 'USD'}
                              isRounded={true}
                              isUseSymbol={true}
                            />
                          ) : (
                            '--'
                          )}
                          /night
                        </span>
                      </div>
                    )}
                    {el?.source === PROPERTY_SOURCE.DTRAVEL && (
                      <Link to={`${nativeURL}&f=onboarding`}>
                        <img src={ic_edit_black} alt={'ic_edit_black'} />
                      </Link>
                    )}
                  </div>
                </div>
              )
            })}
          <Link to={`/native-listing/property?f=onboarding`}>
            <div
              className={clsx(
                'w-ful rounded-[16px] flex items-center justify-between gap-4 px-4 py-6',
                'border-dashed border-[2px] border-neutral-400 mb-[72px] lg:mb-[8px]'
              )}
            >
              <span className="text-16-20 text-neutral-500 font-maison-neue-medium">Create a new listing</span>
              <img src={ic_plus_black} alt={'ic_plus_black'} />
            </div>
          </Link>
          <div
            className={clsx(
              'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
              'border-t border-neutral-300 px-4 md:px-8',
              'shadow-[0px_-2px_4px_-2px_rgba(0,0,0,0.08),0px_-4px_8px_-2px_rgba(0,0,0,0.06)]'
            )}
          >
            {renderFooterBtn()}
          </div>
          <div className="hidden lg:block">{renderFooterBtn()}</div>
        </div>
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingNativeListing
