import useWindowDimensions from '../../hooks/useWindowDimensions'
import BasicSwipeDrawer from '../ui/BasicSwipeDrawer'
import BasicDateRangePicker from './BasicDateRangePicker'
import Popover from '@mui/material/Popover'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ic_close from 'src/assets/icons/ic_close.svg'
import BasicButton from 'src/components/ui/BasicButton'
import { DATE_FORMAT, START_DATE } from 'src/contants/common'
import { useAppSelector } from 'src/redux/hooks'
import { setCheckIn, setCheckOut, setDatePickerFocusedId, setIsOpenDatePicker } from 'src/redux/slices/common'

interface Props {
  hostId?: string
  listingId?: string
  loading?: boolean
  anchorElement: HTMLElement | null
  transformOrigin?: any
  anchorOrigin?: any
  mustHaveDatesBeforeClose?: boolean
  // eslint-disable-next-line no-unused-vars
  afterDatesChange?: (_startDate: string | null, _endDate: string | null) => void
  // eslint-disable-next-line no-unused-vars
  onSaveDates?: (_startDate: string | null, _endDate: string | null) => void
  isDisabledAllDate?: boolean
  isNativeListing?: boolean
}

const SelectDates: React.FC<Props> = ({
  hostId,
  listingId,
  anchorElement,
  transformOrigin,
  anchorOrigin,
  mustHaveDatesBeforeClose,
  afterDatesChange,
  onSaveDates,
  isDisabledAllDate,
  isNativeListing,
}) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { isOpenDatePicker, checkIn, checkOut } = useAppSelector((state) => state.common)
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768

  useEffect(() => {
    dispatch(setDatePickerFocusedId(null))
  }, [])

  useEffect(() => {
    if (isOpenDatePicker && anchorElement) {
      const rect = anchorElement.getBoundingClientRect() // button element, where popup appear
      // popover height = 541
      // distance between popover and anchor element = 12
      const bottomPopover = rect.bottom + 541 + 12
      if (bottomPopover > window.innerHeight) {
        // popover missing content
        window.scrollTo({
          top: window.scrollY + (bottomPopover - window.innerHeight) + 20,
          behavior: 'smooth',
        })

        window.setTimeout(() => {
          setAnchorEl(anchorElement)
        }, 500)
      } else {
        setAnchorEl(anchorElement)
      }
    } else {
      setAnchorEl(null)
    }
  }, [isOpenDatePicker, anchorElement])

  const handleClose = () => {
    if (checkIn && !checkOut) {
      dispatch(setCheckIn(null))
      dispatch(setCheckOut(null))
    }
    if (mustHaveDatesBeforeClose) {
      if (checkIn && checkOut) {
        dispatch(setIsOpenDatePicker(false))
        dispatch(setDatePickerFocusedId(null))
        setAnchorEl(null)
      }
    } else {
      dispatch(setIsOpenDatePicker(false))
      dispatch(setDatePickerFocusedId(null))
      setAnchorEl(null)
    }
  }

  const handleClearDate = () => {
    dispatch(setCheckIn(null))
    dispatch(setCheckOut(null))
    dispatch(setDatePickerFocusedId(START_DATE))
    if (typeof onSaveDates === 'function') {
      onSaveDates(null, null)
    }
  }

  const handleApply = () => {
    if (typeof onSaveDates === 'function') {
      onSaveDates(checkIn, checkOut)
    }
    handleClose()
  }

  const renderLabel = () => {
    return checkIn && checkOut
      ? moment(checkIn, DATE_FORMAT).format('MMM D') + ' - ' + moment(checkOut, DATE_FORMAT).format('MMM D, YYYY')
      : 'Select dates'
  }

  const open = Boolean(anchorEl)
  const id = open ? 'datepickerPopover' : undefined

  return (
    <>
      {isMobile ? (
        <BasicSwipeDrawer
          isOpen={isOpenDatePicker}
          onClose={handleClose}
          height={'100%'}
          noScroll
          hideClose={true}
          bodyStyle={{ padding: 0 }}
        >
          <div className={'customDateRange h-full pb-[240px]'} style={{ backgroundColor: '#FFF' }}>
            <div className={'flex justify-between items-center px-[16px] py-[24px]'}>
              <span className={'font-maison-neue-medium text-16-16 text-neutral-900'}>{renderLabel()}</span>
              <button
                className={'w-[24px] h-[24px] flex items-center justify-center rounded-full hover:bg-neutral-200'}
                onClick={handleClose}
              >
                <img src={ic_close} width={20} height={20} alt="" />
              </button>
            </div>
            <BasicDateRangePicker
              isMobile={true}
              startDate={checkIn ? moment(checkIn, DATE_FORMAT) : null}
              endDate={checkOut ? moment(checkOut, DATE_FORMAT) : null}
              hostId={hostId}
              listingId={listingId}
              afterDatesChange={afterDatesChange}
              isDisabledAllDate={isDisabledAllDate}
              isNativeListing={isNativeListing}
            />
          </div>
          <div
            className={
              'h-[80px] fixed w-full bottom-0 left-0 right-0 border-y border-sand-3 flex justify-between items-center px-[16px] py-[12px] bg-white z-10 gap-[12px]'
            }
            style={{
              boxShadow: '0px -1px 16px rgba(0, 0, 0, 0.04), 0px -1px 0px rgba(0, 0, 0, 0.04)',
              zIndex: 9999,
            }}
          >
            <button
              className={
                'bg-white font-maison-neue-medium text-neutral-900 flex items-center justify-center ' +
                'h-[56px] md:h-[40px] ' +
                'rounded-[16px] md:rounded-[12px] ' +
                'text-16-16 md:text-14-14 ' +
                'w-1/3 md:w-auto ' +
                'border border-neutral-300 md:border-none ' +
                'md:active:underline ' +
                'md:disabled:active:no-underline ' +
                'disabled:opacity-50 ' +
                'disabled:cursor-not-allowed'
              }
              disabled={!checkIn && !checkIn}
              onClick={handleClearDate}
            >
              Clear x
            </button>
            <button
              className={
                'bg-neutral-900 font-maison-neue-medium text-white px-[16px] flex items-center justify-center ' +
                'h-[56px] md:h-[40px] ' +
                'rounded-[16px] md:rounded-[12px] ' +
                'text-16-16 md:text-14-14 ' +
                'w-2/3 md:w-auto ' +
                'disabled:opacity-50 ' +
                'disabled:cursor-not-allowed'
              }
              disabled={!checkIn || !checkOut}
              onClick={handleApply}
            >
              Apply
            </button>
          </div>
        </BasicSwipeDrawer>
      ) : (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={
            anchorOrigin
              ? { ...anchorOrigin }
              : {
                  vertical: 'bottom',
                  horizontal: 'right',
                }
          }
          transformOrigin={
            transformOrigin
              ? { ...transformOrigin }
              : {
                  vertical: window && window.innerHeight < 800 ? 68 : -8,
                  horizontal: 'right',
                }
          }
          sx={{
            '& .MuiPaper-root': {
              width: 'auto',
              height: 'auto',
              boxShadow: '0px 16px 48px rgba(0, 0, 0, 0.2)',
              borderRadius: '24px',
              backgroundColor: '#FFFFFF',
              padding: '0px',
            },
          }}
        >
          <div id={'dateWrapper'}>
            <div className={'flex justify-between items-center px-[24px] h-[68px]'}>
              <span className={'font-maison-neue-medium text-16-16 text-neutral-900'}>{renderLabel()}</span>
              <button
                className={'w-[40px] h-[40px] flex items-center justify-center rounded-full hover:bg-neutral-200'}
                onClick={handleClose}
              >
                <img src={ic_close} alt={'ic_close'} />
              </button>
            </div>
            <div className={'customDateRange'} style={{ backgroundColor: '#FCFAFA' }}>
              <BasicDateRangePicker
                startDate={checkIn ? moment(checkIn, DATE_FORMAT) : null}
                endDate={checkOut ? moment(checkOut, DATE_FORMAT) : null}
                hostId={hostId}
                listingId={listingId}
                afterDatesChange={afterDatesChange}
                isDisabledAllDate={isDisabledAllDate}
                isKeepPopupAfterDatesChange={true}
                isNativeListing={isNativeListing}
              />
            </div>

            <div className={'flex justify-between items-center h-[76px] px-[16px] md:px-[24px]'}>
              <button
                className={
                  'bg-white font-maison-neue-medium text-neutral-900 flex items-center justify-center ' +
                  'h-[56px] md:h-[40px] ' +
                  'rounded-[16px] md:rounded-[12px] ' +
                  'text-16-16 md:text-14-14 ' +
                  'w-1/3 md:w-auto ' +
                  'border border-neutral-300 md:border-none ' +
                  'md:active:underline ' +
                  'md:disabled:active:no-underline ' +
                  'disabled:opacity-50 ' +
                  'disabled:cursor-not-allowed'
                }
                onClick={handleClearDate}
                disabled={!checkIn && !checkIn}
              >
                Clear
              </button>
              <BasicButton variant={'contained'} onClick={handleApply} size={'md'} disabled={!checkIn || !checkOut}>
                <span className={'font-maison-neue-medium text-14-14 text-white'}>Apply</span>
              </BasicButton>
            </div>
          </div>
        </Popover>
      )}
    </>
  )
}

export default SelectDates
