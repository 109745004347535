import { MAX_LENGTH_HERO_CONTENT } from '../../../contants/common'
import IconButton from '@mui/material/IconButton'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { updateHostSettings, uploadSettingImages } from 'src/api/user'
import ic_media_40_40 from 'src/assets/icons/ic_media_40_40.svg'
import ic_trash_empty from 'src/assets/icons/ic_trash_empty.svg'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import BasicInput from 'src/components/ui/BasicInput'
import BasicTextarea from 'src/components/ui/BasicTextarea'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { setSettingData } from 'src/redux/slices/user'
import { handleErrorMessage, validateWidthHeightImage } from 'src/utils/common'

interface Props {}

const DEFAULT_GALERY = process.env.REACT_APP_STATIC_PATH + '/guest/hero-image.webp'

const SettingHeroContent: React.FC<Props> = () => {
  const { settingData } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const inputPhoto = useRef<HTMLInputElement | null>(null)
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const [headline, setHeadline] = useState<string>('')
  const [subHeadline, setSubHeadline] = useState<string>('')
  const [gallery, setGallery] = useState<string>()

  useEffect(() => {
    setHeadline(settingData.headline || '')
    setSubHeadline(settingData.subHeadline || '')
    setGallery(settingData.gallery || DEFAULT_GALERY || '')
  }, [settingData])

  const handleOpen = () => {
    setIsOpenPopup(true)
  }

  const handleClose = () => {
    setIsOpenPopup(false)
    // clear data
    setHeadline(settingData.headline || '')
    setSubHeadline(settingData.subHeadline || '')
    setGallery(settingData.gallery || DEFAULT_GALERY || '')
  }

  const onChangeHeadline = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeadline(event.target.value)
  }

  const onChangeSubHeadline = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSubHeadline(event.target.value)
  }

  const handleUpload = async (e: any) => {
    const file: File = e.target.files[0]
    const isValid = await validateWidthHeightImage(file, 1280, 720)
    if (!isValid) {
      handleErrorMessage({ message: 'Image must be at least 1280 x 720.' })
      return
    }
    if (file) {
      try {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('file', file)
        const res = await uploadSettingImages('gallery', formData)
        if (res.success) {
          setGallery(res.data)
        }
      } catch (error: any) {
        handleErrorMessage(error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const data: any = { headline, subHeadline, gallery }
      const res = await updateHostSettings(data)
      if (res.success && res.data) {
        dispatch(setToastSuccess({ message: `Update success!` }))
        dispatch(setSettingData({ ...settingData, ...res.data }))
        handleClose()
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const isHasData = settingData.headline || settingData.subHeadline || settingData.gallery

  console.log()

  const renderAddButton = () => (
    <BasicButton size={'md'} variant={'outlined'} clases={'w-full md:w-auto'} isRadius100 onClick={handleOpen}>
      {isHasData ? 'Update' : 'Add'}
    </BasicButton>
  )

  return (
    <>
      <div className={'flex flex-col md:flex-row justify-between items-start gap-[12px]'}>
        <div>
          <p className={'font-maison-neue-medium text-neutral-800 text-16-20'}>Hero Content</p>
          <p className={'font-maison-neue text-neutral-600 text-14-18 mt-[4px]'}>
            Set your business apart with eye-catching content at the top of your landing page
          </p>
        </div>
        {/* For Desktop, Table */}
        <div className={'hidden md:block'}>{renderAddButton()}</div>

        <BasicDialog
          isOpen={isOpenPopup}
          title={'Hero Content'}
          extraTitle={'Set your business apart with eye-catching content at the top of your landing page'}
          onClose={handleClose}
          allowBackdropClick
          titleAlign={'center'}
        >
          <p className={'font-maison-neue-medium text-14-18 text-neutral-800'}>Photo</p>
          {gallery ? (
            <div
              className={'relative w-[160px] h-[120px] mt-[8px]'}
              onFocus={() => setIsHover(true)}
              onMouseOver={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              <img src={gallery} alt="hero" className="min-w-[160px] w-[160px] h-[120px] rounded-[8px] object-cover" />
              <div
                className={
                  'flex items-center justify-center bg-[#000000] absolute opacity-60 absolute top-0 w-full h-full rounded-[8px] ' +
                  `${isHover ? '' : 'hidden'}`
                }
              >
                <IconButton size={'small'} onClick={() => setGallery('')}>
                  <img src={ic_trash_empty} alt={'ic_trash_empty'} />
                </IconButton>
              </div>
            </div>
          ) : (
            <button
              className={clsx(
                'flex justify-center items-center border border-dashed border-neutral-800 w-full h-[128px] rounded-[12px] shadow-basic mt-[8px]'
              )}
              style={{ border: '0.5px dashed rgba(0, 0, 0, 0.15)' }}
              onClick={() => {
                const inputEle = inputPhoto.current as HTMLInputElement
                inputEle.click()
              }}
            >
              <img src={ic_media_40_40} alt={'ic_media'} className={''} />
            </button>
          )}

          <input
            ref={inputPhoto}
            type={'file'}
            multiple={true}
            accept={'image/*'}
            className={'opacity-0 w-full h-full z-50 hidden'}
            onChange={handleUpload}
            onClick={() => {
              const element = inputPhoto.current as HTMLInputElement
              if (element) element.value = ''
            }}
          />

          <div className={'mt-[24px]'}>
            <BasicInput
              label={'Headline'}
              placeholder={'Write your headline here'}
              value={headline}
              onChange={onChangeHeadline}
              onBlur={() => setHeadline((prevState) => prevState.trim())}
              maxLength={MAX_LENGTH_HERO_CONTENT.HEADLINE}
              showCount
            />
          </div>
          <div className={'mt-[24px]'}>
            <BasicTextarea
              label={'Sub-Headline'}
              rows={3}
              placeholder={'Write your sub-headline here'}
              value={subHeadline}
              onChange={onChangeSubHeadline}
              onBlur={() => setSubHeadline((prevState) => prevState.trim())}
              showCount
              maxLength={MAX_LENGTH_HERO_CONTENT.SUB_HEADLINE}
            />
          </div>

          <div className={'mt-[24px]'}>
            <BasicButton
              variant={'contained'}
              color={'red'}
              clases={'w-full'}
              isRadius100={true}
              onClick={handleSubmit}
              loading={isLoading}
              disabled={!headline || !subHeadline}
            >
              Save
            </BasicButton>
          </div>
        </BasicDialog>
      </div>
      <div
        className={
          'mt-[16px] bg-neutral-50 rounded-[16px] p-[16px] md:p-[24px] flex flex-col md:flex-row items-start md:items-center gap-[16px] md:gap-[24px]'
        }
      >
        <div>
          <img
            src={settingData.gallery || DEFAULT_GALERY}
            alt="hero"
            className="min-w-[160px] w-[160px] h-[120px] rounded-[8px] object-cover"
          />
        </div>
        <div>
          <p className={'font-maison-neue-medium text-neutral-800 text-16-20'}>{settingData.headline}</p>
          <p className={'font-maison-neue text-neutral-600 text-14-18 break-all'}>{settingData.subHeadline}</p>
        </div>
      </div>
      {/* For Mobile */}
      <div className={'block md:hidden w-full mt-[12px]'}>{renderAddButton()}</div>
    </>
  )
}

export default SettingHeroContent
