import { ONBOARDING_STEP } from '../../../contants/user'
import { useAppDispatch } from '../../../redux/hooks'
import { handleNextOnboardingStep } from '../../../redux/slices/user'
import BasicButton from '../../ui/BasicButton'
import LayoutOnboarding from '../LayoutOnboarding'
import RightContentPmsCm from './RightContentPmsCm'
import clsx from 'clsx'
import React from 'react'

const OnboardingAirbnbConnectError = () => {
  const dispatch = useAppDispatch()
  const moveToPmsCmStep = () => {
    dispatch(handleNextOnboardingStep(ONBOARDING_STEP.PMS_SELECT))
  }

  const handleTryAgain = () => {
    dispatch(handleNextOnboardingStep(ONBOARDING_STEP.AIRBNB_IMPORT))
  }

  const handleMoveToCreateManualListing = () => {
    dispatch(handleNextOnboardingStep(ONBOARDING_STEP.NATIVE_LISTING))
  }

  const renderSubmitBtn = () => {
    return (
      <div className={'flex items-center gap-[16px]'}>
        <BasicButton variant={'contained'} isRadius100={true} color={'red'} size={'xl'} onClick={handleTryAgain}>
          Try again
        </BasicButton>
        <span className={'lg:hidden'}>
          <BasicButton
            variant={'outlined'}
            isRadius100={true}
            color={'white'}
            size={'xl'}
            onClick={handleMoveToCreateManualListing}
          >
            Create a manual listing
          </BasicButton>
        </span>
        <span className={'hidden lg:block'}>
          <BasicButton
            variant={'contained'}
            isRadius100={true}
            color={'white'}
            size={'xl'}
            onClick={handleMoveToCreateManualListing}
          >
            Create a manual listing
          </BasicButton>
        </span>
      </div>
    )
  }

  return (
    <LayoutOnboarding headerTitle={'Listings'} rightContent={<RightContentPmsCm />}>
      <div className={'mt-[38px] lg:mt-[70px] w-full mx-auto md:max-w-[524px]'}>
        <h2 className={'font-maison-neue text-24-36 text-neutral-800 mb-[16px]'}>Uh-oh...</h2>
        <p className={'font-maison-neue text-16-24 text-neutral-800 mb-[24px]'}>
          It looks like we have a problem connecting to your Airbnb account.
        </p>
        <div className={'px-[24px] py-[16px] bg-red-100 rouned-[8px]'}>
          <span className={'font-maison-neue text-14-20 text-black'}>
            {/*Import via {airbnbPmsType === PMS_TYPE.AIRBNB ? 'Channex' : 'Hospitable'} is only possible for Airbnb*/}
            Import via Hospitable is only possible for Airbnb accounts not connected to a PMS or CM. If you are using
            listing management software, try our{' '}
            <span role={'presentation'} className={'underline cursor-pointer'} onClick={moveToPmsCmStep}>
              PMS/CM connect
            </span>{' '}
            option.
          </span>
        </div>

        <div className={'mt-[24px] hidden lg:block'}>{renderSubmitBtn()}</div>
      </div>
      <div
        className={clsx(
          'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
          'border-t border-neutral-300 px-4 md:px-8',
          'shadow-footer'
        )}
      >
        {renderSubmitBtn()}
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingAirbnbConnectError
