import { RESERVATION_STATUS } from '../../contants/property'
import DetailCancelPolicy from '../property/DetailCancelPolicy'
// import PropertyAirbnbCancelPolicy from '../property/PropertyAirbnbCancelPolicy'
import CancelManualReservation from './CancelManualReservation'
import CancelReservation from './CancelReservation'
import ReservationRiskManagement from './ReservationRiskManagement'
import { EventSourcePolyfill } from 'event-source-polyfill'
import momentTZ from 'moment-timezone'
import queryString from 'query-string'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { getReservationDetail } from 'src/api/integration'
import ic_arrow_back from 'src/assets/icons/ic_arrow_left.svg'
import ic_copy from 'src/assets/icons/ic_copy.svg'
import ic_eye_show from 'src/assets/icons/ic_eye_show.svg'
import BasicCard from 'src/components/common/BasicCard'
import LayoutV2 from 'src/components/layout/LayoutV2'
// import CancelManualReservation from 'src/components/reservation/CancelManualReservation'
import ReservationChargeToGuest from 'src/components/reservation/ReservationChargeToGuest'
import ReservationDetailInfo from 'src/components/reservation/ReservationDetailInfo'
import ReservationPayout from 'src/components/reservation/ReservationPayout'
import ReservationTransactionHash from 'src/components/reservation/ReservationTransactionHash'
import { DATE_FORMAT, PAYMENT_METHOD, DOMAIN_TYPE } from 'src/contants/common'
import { ServerResponse } from 'src/interfaces'
import { ReservationStatusEnum } from 'src/interfaces/reservations'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setLoading, setToastSuccess } from 'src/redux/slices/common'
import { getDomain } from 'src/utils/common'
import { isPropertyDtravel } from 'src/utils/property'

const ReservationDetail = () => {
  const evtSourceRef = useRef<any>(null)
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector((state) => state.user)
  const { reservationId } = useParams()
  const [data, setData] = useState<any>(null)
  const [reservationStatus, setReservationStatus] = useState<string>('')
  const [isNativeListing, setIsNativeListing] = useState<boolean>(false)

  const fetchData = async () => {
    if (reservationId) {
      try {
        dispatch(setLoading(true))
        const res: ServerResponse = await getReservationDetail(reservationId)
        setData(res.data)
        setReservationStatus(res.data.status)
        const listingInfo = res.data.listingInfo
        setIsNativeListing(isPropertyDtravel(listingInfo))
      } catch (err) {
        console.log(err)
      } finally {
        dispatch(setLoading(false))
      }
    }
  }

  const handleChangeStatus = (callback?: () => void) => {
    evtSourceRef.current = new EventSourcePolyfill(
      `${process.env.REACT_APP_API_SERVICE_URL}/reservation-service/reservation/verify/${reservationId}`
    )
    evtSourceRef.current.addEventListener('message', function (res: any) {
      const parsedData = JSON.parse(res.data)
      if (parsedData && parsedData.status && reservationStatus !== parsedData.status) {
        if (parsedData.status === RESERVATION_STATUS.CANCELLED) {
          setReservationStatus(parsedData.status)
          fetchData() // recall api detail
          if (typeof callback === 'function') {
            callback()
          }
        }
        evtSourceRef.current.close()
      }
    })
  }

  useEffect(() => {
    return () => {
      if (evtSourceRef.current) {
        evtSourceRef.current.close()
      }
    }
  }, [])

  useEffect(() => {
    if (reservationId) fetchData()
  }, [reservationId])

  // const convertTimeZone = (timezone: string) => {
  //   const d = DateTime.local().setZone(timezone)
  //   return d.offsetNameShort
  // }
  const getLink = () => {
    const customUrl = (profile?.user?.userCustomUrl || []).find((v: any) => v?.isPrimary)
    const listingId = data?.listingId
    const hostId = data?.hostId
    const params: string = queryString.stringify({
      // check_in: moment(data?.checkinDate).format(DATE_FORMAT),
      // check_out: moment(data?.checkoutDate).format(DATE_FORMAT),
      check_in: momentTZ.tz(data?.checkinDate, data?.timezone).utc().format(DATE_FORMAT),
      check_out: momentTZ.tz(data?.checkoutDate, data?.timezone).utc().format(DATE_FORMAT),
      adults: data?.adults,
      children: data?.children,
      guests: data?.guestCount,
      infants: data?.infants,
      pets: data?.pets,
      reservationId,
    })
    if (customUrl) {
      if (customUrl?.type === DOMAIN_TYPE.CUSTOM)
        return `https://${customUrl?.customId}.${getDomain()}/booking/${listingId}?${params}`
      if (customUrl?.type === DOMAIN_TYPE.SELF_HOSTED)
        return `https://${customUrl?.customId}/booking/${listingId}?${params}`
    }
    return `${process.env.REACT_APP_GUEST_DOMAIN}/${hostId}/booking/${listingId}?${params}`
  }

  // const isManualReservation = data?.source === 'manual'
  const isInquiryManual = data?.status === ReservationStatusEnum.INQUIRY && data?.source === 'manual'

  return (
    <LayoutV2
      childrenHeader={
        <>
          <Link to={'/reservations'}>
            <button
              className={'rounded-full flex items-center justify-center bg-white w-[40px] h-[40px] cursor-pointer'}
            >
              <img alt={'ic_exit'} src={ic_arrow_back} />
            </button>
          </Link>
          <div className={'flex gap-[8px] items-center'}>
            {isInquiryManual && (
              <>
                <a href={getLink()} target="_blank" rel="noreferrer">
                  <button className={'rounded-full bg-white w-[40px] h-[40px] flex items-center justify-center'}>
                    <img src={ic_eye_show} alt={'ic_eye_show'} width={20} height={20} />
                  </button>
                </a>
                <button
                  className={'rounded-full bg-white w-[40px] h-[40px] flex items-center justify-center'}
                  onClick={() => {
                    const url = getLink()
                    navigator.clipboard.writeText(url)
                    dispatch(setToastSuccess({ message: `Copied!`, duration: 2000 }))
                  }}
                >
                  <img src={ic_copy} alt={'copy'} width={20} height={20} />
                </button>
                <CancelManualReservation fetchData={fetchData} />
              </>
            )}
            <CancelReservation data={{ ...data, status: reservationStatus }} handleChangeStatus={handleChangeStatus} />
          </div>
        </>
      }
    >
      <div className={'flex flex-col xl:flex-row lg:my-[24px]'}>
        {/* leftside: property thumb */}
        <ReservationDetailInfo data={data} reservationStatus={reservationStatus} />

        {/* right side: other content */}
        <div className={'w-full xl:w-2/3 ml-0 xl:ml-[48px] text-16-24'}>
          {data && <ReservationChargeToGuest data={data} />}
          {data && (data.paymentMethod === PAYMENT_METHOD.CRYPTO || isInquiryManual) && (
            <ReservationPayout
              payoutPolicies={data.cancellationPolicy?.payoutPolicies}
              currency={data.currency}
              payFor={'host'}
              timezone={data.timezone}
              isInquiryManual={isInquiryManual}
              canceledAt={data.canceledAt}
              rawPaymentInfo={data.rawPaymentInfo}
            />
          )}

          {/* Contribution to Community Treasury */}
          {data && (data.paymentMethod === PAYMENT_METHOD.CRYPTO || isInquiryManual) && (
            <ReservationPayout
              payoutPolicies={data.cancellationPolicy?.payoutPolicies}
              currency={data.currency}
              payFor={'dtravel'}
              timezone={data.timezone}
              isInquiryManual={isInquiryManual}
              canceledAt={data.canceledAt}
              rawPaymentInfo={data.rawPaymentInfo}
            />
          )}
          {data && (
            <div className={'mt-[40px]'}>
              <ReservationRiskManagement
                data={{ ...data, status: reservationStatus }}
                handleChangeStatus={handleChangeStatus}
              />
            </div>
          )}

          <p className="mb-[16px] font-maison-neue text-20-24 text-neutral-900 mt-[40px]">{'TRVL token rewards'}</p>
          <BasicCard>
            <p className={'text-16-24 text-neutral-600'}>
              Operators on Dtravel earn a token bonus with every booking.&nbsp;
              <a
                className={'underline text-neutral-700'}
                href="https://dtravel.notion.site/Host-Payouts-Fees-and-Rewards-ea943be334b84c748a39fcfd904dcddf#:~:text=Fees%20%26%20TRVL%20givebacks"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
            </p>
          </BasicCard>

          <DetailCancelPolicy
            isNativeListing={isNativeListing}
            airbnbCancellationPolicies={data?.listingInfo?.airbnbCancellationPolicies}
            cancelPolicies={data?.listingInfo?.cancelPolicies}
            cancelPolicyDescription={data?.listingInfo?.cancelPolicyDescription}
            pmsType={data?.listingInfo?.pmsType}
            from={'RESERVATION'}
          />
          {/*{(!isNativeListing || hasAirbnbCancellationPolicies) && (*/}
          {/*  <>*/}
          {/*    <p className="mb-[16px] font-maison-neue text-20-24 text-neutral-900 mt-[40px]">*/}
          {/*      {'Cancellation policy'}*/}
          {/*      {hasAirbnbCancellationPolicies ? '' : `: ${data?.cancellationPolicy?.name}`}*/}
          {/*    </p>*/}
          {/*    {hasAirbnbCancellationPolicies ? (*/}
          {/*      <PropertyAirbnbCancelPolicy*/}
          {/*        airbnbCancellationPolicies={data?.listingInfo?.airbnbCancellationPolicies}*/}
          {/*      />*/}
          {/*    ) : (*/}
          {/*      <BasicCard>*/}
          {/*        <p className={'text-16-24 text-neutral-600'}>*/}
          {/*          {data?.cancellationPolicy?.description || 'No cancellation policy is defined for this listing.'}*/}
          {/*        </p>*/}
          {/*      </BasicCard>*/}
          {/*    )}*/}
          {/*  </>*/}
          {/*)}*/}

          {data && data.paymentMethod === PAYMENT_METHOD.CRYPTO && <ReservationTransactionHash data={data} />}
        </div>
      </div>
    </LayoutV2>
  )
}

export default ReservationDetail
