import React from 'react'

const ReservationListNoResult = () => {
  return (
    <div
      className={
        'w-full py-[32px] px-[16px] lg:bg-sand-3 lg:rounded-[24px] lg:w-full lg:h-[350px] lg:py-[72px] lg:px-[64px]'
      }
    >
      <p className={'font-editorial-new text-24-32 text-grey-700 mb-3'}>You don’t have any reservations</p>
      <p className="text-18-28 font-maison-neue text-grey-700 max-w-[450px]">
        Share your listings today and start driving more Dtravel Direct bookings. Once that happens, your upcoming
        reservations will show up here.
      </p>
    </div>
  )
}

export default ReservationListNoResult
