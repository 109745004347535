import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import { getFeeOptions, updateFeeOptions } from 'src/api/integration'
import BasicButton from 'src/components/ui/BasicButton'
import BasicRadio from 'src/components/ui/BasicRadio'
import { useAppDispatch } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { handleErrorMessage } from 'src/utils/common'

interface Props {
  handleNext: () => void
  isGuestPayFee: boolean
  handleUpdateGuestPayFee: any
}

const OnboardingSiteDetailsFees = ({ handleNext, isGuestPayFee, handleUpdateGuestPayFee }: Props) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    async function fetchFeeOptions() {
      try {
        setIsLoading(true)
        const res = await getFeeOptions()
        const { data } = res
        if (data) {
          handleUpdateGuestPayFee(data.isGuestPayFee)
        } else {
          handleUpdateGuestPayFee(true)
        }
      } finally {
        setIsLoading(false)
      }
    }

    fetchFeeOptions()
  }, [])

  const onChangeChargedFee = async (event: React.ChangeEvent<HTMLInputElement>) => {
    handleUpdateGuestPayFee(event.target.value === 'yes')
  }

  const handleSave = async () => {
    try {
      setIsLoading(true)
      await updateFeeOptions(isGuestPayFee)
      dispatch(setToastSuccess({ message: 'success' }))
      handleNext()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const renderFooterBtn = () => {
    return (
      <div className="flex items-center gap-4">
        <BasicButton
          size={'xl'}
          variant={'contained'}
          clases={'w-auto h-[48px] rounded-[100px_!important] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]'}
          color="red"
          onClick={handleSave}
          disabled={isLoading}
        >
          Continue
        </BasicButton>
      </div>
    )
  }
  return (
    <>
      <p className="mb-1 text-24-36 text-neutral-800">Set up your fees:</p>
      <p className="mb-6 text-16-24 text-neutral-800">
        Charge guests an extra 2.9% + $0.30 fee for credit card payments? Stripe will deduct this fee from your payout
        regardless.
      </p>
      <div className="flex flex-col gap-6">
        <div className={'flex flex-col gap-[8px] rounded-[16px]'}>
          <label
            className={
              'p-[24px] rounded-[16px] flex gap-[4px] items-center justify-between w-full cursor-pointer bg-white ' +
              `${isGuestPayFee ? 'border-[2px] border-neutral-800' : 'border-[1px] border-neutral-300'}`
            }
            htmlFor={'setting_fee_yes'}
          >
            <div>
              <p className={`font-maison-neue-medium text-16-20 text-neutral-800`}>Yes</p>
              <p className={'font-maison-neue text-14-18 text-neutral-6 mt-[4px]'}>
                Guests will be charged an extra fee for credit/debit card payments.
              </p>
            </div>
            <div>
              <BasicRadio
                id={'setting_fee_yes'}
                name={'fee_options'}
                value={'yes'}
                checked={isGuestPayFee}
                onChange={onChangeChargedFee}
              />
            </div>
          </label>

          <label
            className={
              'p-[24px] rounded-[16px] flex gap-[4px] items-center justify-between w-full cursor-pointer bg-white ' +
              `${isGuestPayFee ? 'border-[1px] border-neutral-300' : 'border-[2px] border-neutral-800'}`
            }
            htmlFor={'setting_fee_no'}
          >
            <div>
              <p className={`font-maison-neue-medium text-16-20 text-neutral-800`}>No</p>
              <p className={'font-maison-neue text-14-18 text-neutral-6 mt-[4px]'}>
                Guests will not be charged an extra fee for credit/debit card payments.
              </p>
            </div>
            <div>
              <BasicRadio
                id={'setting_fee_no'}
                name={'fee_options'}
                value={'no'}
                checked={!isGuestPayFee}
                onChange={onChangeChargedFee}
              />
            </div>
          </label>
        </div>
        <div
          className={clsx(
            'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
            'border-t border-neutral-300 px-4 md:px-8',
            'shadow-[0px_-2px_4px_-2px_rgba(0,0,0,0.08),0px_-4px_8px_-2px_rgba(0,0,0,0.06)]'
          )}
        >
          {renderFooterBtn()}
        </div>
        <div className="hidden lg:block">{renderFooterBtn()}</div>
      </div>
    </>
  )
}

export default OnboardingSiteDetailsFees
