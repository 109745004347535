import { handleErrorMessage } from '../../../utils/common'
import React, { useEffect, useState } from 'react'
import { getUserProfile, updateUserProfile } from 'src/api/user'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import BasicInput from 'src/components/ui/BasicInput'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { saveUserInfo } from 'src/utils/user'

interface Props { }

const SettingSiteDisplayName: React.FC<Props> = () => {
  const { profile } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const [hostName, setHostName] = useState<string>('')
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setHostName(profile?.hostName || '')
  }, [profile?.hostName, isOpenPopup])

  const handleOpen = () => {
    setIsOpenPopup(true)
  }

  const handleClose = () => {
    setIsOpenPopup(false)
  }

  const handleChangeHostName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHostName(event.target.value)
  }

  const handleSave = async () => {
    try {
      setIsLoading(true)
      await updateUserProfile({ hostName })
      const res: any = await getUserProfile()
      saveUserInfo({ ...profile, ...res?.data })
      setIsLoading(false)
      dispatch(setToastSuccess({ message: 'success' }))
      handleClose()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={'flex flex-col md:flex-row justify-between items-start gap-[12px]'}>
      <div>
        <p className={'font-maison-neue-medium text-neutral-800 text-16-20'}>Brand Name</p>
        <p className={'font-maison-neue text-neutral-600 text-14-18'}>
          {profile?.hostName
            ? profile?.hostName
            : 'This will be seen by your guests. We recommend your brand or business name.'}
        </p>
      </div>
      <BasicButton size={'md'} variant={'outlined'} clases={'w-full md:w-auto'} isRadius100 onClick={handleOpen}>
        {profile?.hostName ? 'Update' : 'Add'}
      </BasicButton>

      <BasicDialog
        isOpen={isOpenPopup}
        title={'Brand Name'}
        extraTitle={'This will be seen by users and guests.'}
        onClose={handleClose}
        allowBackdropClick
        titleAlign={'center'}
      >
        <BasicInput
          label={'Name'}
          value={hostName}
          onChange={handleChangeHostName}
          onBlur={() => setHostName((prevState) => prevState.trim())}
        />
        <BasicButton
          variant={'contained'}
          color={'red'}
          clases={'w-full mt-[24px]'}
          isRadius100={true}
          onClick={handleSave}
          loading={isLoading}
          disabled={!hostName}
        >
          Save
        </BasicButton>
      </BasicDialog>
    </div>
  )
}

export default SettingSiteDisplayName
