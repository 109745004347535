import React, { ReactElement } from 'react'

interface Props {
  children: ReactElement
}

const BlankLayout: React.FC<Props> = ({ children }) => {
  return <>{children}</>
}

export default BlankLayout
