import ic_chevron_down from '../../assets/icons/ic_chevron_down_20_20.svg'
import ic_edit_pencil from '../../assets/icons/ic_edit_pencil_01.svg'
import { FormError } from '../../interfaces'
import Error from '../common/Error'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Tooltip from '@mui/material/Tooltip'
import clsx from 'clsx'
import React, { SelectHTMLAttributes, forwardRef, useState, useEffect } from 'react'

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string
  isRequired?: boolean
  tooltip?: string
  classes?: string
  options: {
    value: string
    label?: string
  }[]
  showIconEdit?: boolean
  error?: FormError
  placeholder?: string
}

const BasicSelect = forwardRef<HTMLSelectElement, SelectProps>(
  (
    { label, options, tooltip, isRequired, classes, placeholder, value, showIconEdit, error, onChange, ...props },
    ref
  ) => {
    const [isEdit, setIsEdit] = useState<boolean>(!showIconEdit)
    const [disabled, setDisabled] = useState<boolean | undefined>(props.disabled)

    useEffect(() => {
      setDisabled(props.disabled || (!props.disabled && !!showIconEdit))
    }, [showIconEdit, props.disabled])

    const onToggleEdit = () => {
      setIsEdit((prevState) => {
        setDisabled(prevState)
        return !prevState
      })
    }

    return (
      <label className="inputLabel block relative">
        {label && (
          <span className={'block font-maison-neue-medium text-14-18 text-neutral-800 mb-[8px]'}>
            {isRequired && <span className={'text-red'}>*</span>}
            &nbsp;
            {label}
            &nbsp;
            {tooltip && (
              <Tooltip placement="top" title={tooltip} sx={{ fontSize: 16 }} arrow>
                <HelpOutlineIcon />
              </Tooltip>
            )}
          </span>
        )}
        {/* ⌄ -> code: \2304*/}
        <div
          className={clsx({
            'relative after:content-down after:absolute after:right-[12px] after:translate-y-1/2 after:pointer-events-none':
              !showIconEdit,
          })}
        >
          <select
            ref={ref}
            className={clsx(
              'appearance-none w-full h-[48px] text-16-20 px-4 py-3 rounded-[12px] text-ellipsis overflow-hidden whitespace-nowrap bg-white',
              `${value === '' ? 'text-neutral-500' : 'text-neutral-800'}`,
              `placeholder:text-neutral-500`,
              'shadow-xs',
              'bg-white',
              'border-[1px] border-neutral-300',
              'hover:text-neutral-900',
              'focus:border-neutral-800 focus:outline-none focus:text-neutral-900',
              'disabled:cursor-not-allowed disabled:text-neutral-500',
              'disabled:bg-neutral-50',
              classes,
              {
                'border border-red-600 hover:border-red-600 focus:border-red-600': error?.show,
              }
            )}
            value={value}
            disabled={disabled}
            onChange={(event) => {
              setIsEdit(false)
              if (typeof onChange === 'function') {
                onChange(event)
              }
            }}
            {...props}
          >
            {placeholder && (
              <option value="" disabled hidden>
                {placeholder}
              </option>
            )}
            {Array.isArray(options) &&
              options.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label || opt.value}
                </option>
              ))}
          </select>
          {showIconEdit && (
            <button
              className={`${isEdit ? 'ic_checked' : 'ic_edit'
                } btnEditable absolute right-[12px] bottom-[4px] -translate-y-1/2`}
              onClick={() => onToggleEdit()}
            >
              <img
                src={isEdit ? ic_chevron_down : ic_edit_pencil}
                alt={'ic_edit_checked'}
                style={{ width: '20px', height: '20px' }}
              />
            </button>
          )}
          {error && <Error {...error} />}
        </div>
      </label>
    )
  }
)

export default BasicSelect
