import { getListingHospitable } from '../../api/integration'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setCountPropertyFromHospitable, setIsFinishProperyAllHospitable } from '../../redux/slices/common'
import { getNameByCountryCode, handleErrorMessage } from '../../utils/common'
import { IcBuilding04, IcArrowRight, IcArrowSubDownRight } from '../common/Icons'
import LoadingSection from '../common/LoadingSection'
import BasicCheckbox from '../ui/BasicCheckbox'
import PopoverSelectMergeListing from './PopoverSelectMergeListing'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

interface Props {
  listings: any[]
  updateListings: (newListings: any[]) => void
  isOnboarding?: boolean
  allowFetchListing?: boolean
}

const RenderChooseListings: React.FC<Props> = ({ listings, updateListings, allowFetchListing }) => {
  const dispatch = useAppDispatch()
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false)
  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const isCheckedAll = Array.isArray(listings) && listings.length > 0 ? listings.every((item) => item.checked) : false
  const { airbnbChannelId } = useAppSelector((state) => state.listing)

  useEffect(() => {
    async function fetchListting() {
      try {
        dispatch(setIsFinishProperyAllHospitable(false))
        setIsLoadingList(true)
        const res = await getListingHospitable(airbnbChannelId)
        const _properties = Array.isArray(res.data.result) ? res.data.result : Array.isArray(res.data) ? res.data : []
        const countProperty = res.data.countPropertyFromHospitable
        dispatch(setCountPropertyFromHospitable(countProperty))
        if (Array.isArray(_properties) && _properties.length > 0) {
          let imporObj: any = {}
          _properties
            .filter((item: any) => item.type === 'imported')
            .forEach((item: any) => {
              imporObj[item.platformId] = item
            })
          // setImportedObj(imporObj)
          const _prepareListing = _properties
            .filter((item: any) => item.type === 'prepare-import')
            .map((item: any) => {
              const clone = { ...item, pmsPropertyId: item.id, checked: false }
              const itemImported = imporObj[item.platformId]
              if (itemImported) {
                clone.mergeType = 'merge'
                clone.hasSelectOptions = true
                clone.dtravelPropertyId = itemImported.id
                clone.importedName = itemImported.publicName
                clone.dtravelId = itemImported.id
              } else {
                clone.mergeType = 'add_new'
                clone.hasSelectOptions = false
                clone.dtravelPropertyId = null
                clone.dtravelId = null
              }
              clone.checked = true // set checked is default
              return clone
            })
          updateListings(_prepareListing)
        } else {
          updateListings([])
          setIsEmpty(true)
        }
      } catch (err: any) {
        handleErrorMessage(err)
        setIsEmpty(true)
      } finally {
        setIsLoadingList(false)
        dispatch(setIsFinishProperyAllHospitable(true))
      }
    }
    if (allowFetchListing && airbnbChannelId) {
      fetchListting()
    }
  }, [allowFetchListing, airbnbChannelId])

  const onChangeCheckbox = (checked: boolean, itemData: any) => {
    const prevState = [...listings]
    updateListings(
      prevState.map((item) => {
        const clone = { ...item }
        if (item.id === itemData.id) {
          clone.checked = checked
        }
        return clone
      })
    )
  }

  const onCheckAllListing = (checked: boolean) => {
    const prevState = [...listings]
    updateListings(
      prevState.map((item) => {
        const clone = { ...item }
        clone.checked = checked
        return clone
      })
    )
  }

  const onSelectMergeListing = (listingId: string, mergeType: 'add_new' | 'merge') => {
    const prevState = [...listings]
    updateListings(
      prevState.map((item) => {
        const clone = { ...item }
        if (listingId === item.id) {
          clone.mergeType = mergeType
          clone.dtravelPropertyId = mergeType === 'merge' ? item.dtravelId : null
          // clone.dtravelPropertyId = mergeType === 'merge' ? item.dtravelPropertyId : null
        }
        return clone
      })
    )
  }

  const renderThumbImage = (images: any) => {
    if (Array.isArray(images) && images.length > 0) {
      return (
        <img
          src={images[0]?.thumbnail_url || images[0]?.url || images[0]}
          alt={images[0].caption}
          className={'object-cover w-full h-full rounded-[8px]'}
        />
      )
    }
    return ''
  }

  const renderAddress = (address: any) => {
    if (address) {
      const { state, country_code } = address
      const countryName = getNameByCountryCode(country_code)
      return [state, countryName || country_code].join(', ')
    }
    return ''
  }

  const isHasScroll = listings.length > 7

  const renderOptions = (item: any) => {
    if (item.hasSelectOptions) {
      return (
        <PopoverSelectMergeListing
          listingId={item.id}
          listingName={item.importedName || item.publicName || item.privateName}
          images={item.images}
          mergeType={item.mergeType}
          onSelectType={onSelectMergeListing}
          disabled={!item.checked}
        />
      )
    } else {
      return (
        <div className={'flex items-center gap-[8px]'}>
          <div className={'w-[32px] h-[32px] rounded-[8px] bg-neutral-200 flex items-center justify-center'}>
            <IcBuilding04 />
          </div>
          <div className={'font-maison-neue text-14-18 text-neutral-800'}>Add as a new listing</div>
        </div>
      )
    }
  }

  return (
    <>
      <label className={'flex items-center gap-[8px] mb-[16px]'}>
        <BasicCheckbox
          color={'black'}
          checked={isCheckedAll}
          onChange={(event) => onCheckAllListing(event.target.checked)}
        />
        <span className={'font-maison-neue text-14-18 text-neutral-600'}>Select all listings</span>
      </label>
      <div
        className={clsx(
          'relative flex flex-col border border-grayscale-300 rounded-[16px] max-h-[400px] overflow-y-auto',
          {
            'h-[512px]': isHasScroll && !isLoadingList,
            'h-auto': !isHasScroll && !isLoadingList,
            'h-[120px]': isLoadingList || (Array.isArray(listings) && listings.length === 0),
            'bg-white': true,
          }
        )}
      >
        <LoadingSection isLoading={isLoadingList} size={30} />

        {isEmpty && (
          <div className={'font-maison-neue text-14-20 text-neutral-600 p-[40px] text-center'}>
            No property found in your Airbnb account
          </div>
        )}

        {Array.isArray(listings) &&
          listings.map((item, index) => {
            const isLastItem = index === listings.length - 1
            return (
              <div
                key={item.id}
                className={clsx(
                  'flex flex-col md:flex-row items-start md:items-center',
                  'gap-[12px] p-[16px] md:p-[20px] border-[#00000026] box-border',
                  {
                    'border-b-[0.5px]': !isLastItem,
                    // 'h-[88px]': item.hasSelectOptions,
                    // 'h-[80px]': !item.hasSelectOptions,
                  }
                )}
              >
                <div
                  className={clsx('flex gap-[12px] items-center w-full md:w-[calc(100%/2-24px)]', {
                    'opacity-30': !item.checked,
                  })}
                >
                  <BasicCheckbox
                    color={'black'}
                    checked={item.checked}
                    onChange={(event) => onChangeCheckbox(event.target.checked, item)}
                  />
                  <div className={'w-[40px] min-w-[40px] h-[40px] rounded-[8px]'}>{renderThumbImage(item.images)}</div>
                  <div className={'text-left'}>
                    <p className={'font-maison-neue-medium text-16-20 text-neutral-800 line-clamp-1'}>
                      {item.publicName || item.privateName}
                    </p>
                    <p className={'font-maison-neue text-14-18 text-neutral-600'}>{renderAddress(item.address)}</p>
                  </div>
                </div>
                <div
                  className={clsx('hidden md:block', {
                    'opacity-20': !item.checked,
                  })}
                >
                  <IcArrowRight color={'#CFCFCB'} width={24} height={24} />
                </div>
                <div
                  className={clsx('w-full md:w-[calc(100%/2-24px)]', 'flex items-center gap-[20px] pl-[28px] md:pl-0', {
                    'opacity-30': !item.checked,
                  })}
                >
                  <div className={'w-[32px] h-[32px] flex md:hidden items-center justify-center '}>
                    <IcArrowSubDownRight />
                  </div>
                  {renderOptions(item)}
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}

export default RenderChooseListings
