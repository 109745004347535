import { ErrorDayOfWeek } from '../interfaces/listing'

export const PROPERTY_BOOKING_INSTRUCTIONS_FIELD = [
  {
    label: 'Nightly price',
    tooltip: '',
    fieldType: 'input',
  },
  {
    label: 'Check-in time',
    tooltip: '',
    fieldType: 'select',
  },
]

export const CONTRACT_DEPLOY_STATUS = {
  NEW: 'new',
  PROCESSING: 'processing',
  PUBLISHED: 'published',
  ERROR: 'error',
}

export const SYNC_STATUS = {
  PENDING: 'pending',
  DONE: 'done',
  FAIL: 'fail',
  IGNORED: 'ignored',
  ERROR: 'error',
  PROCESSING: 'processing',
}

export const SUPERHOG_STATUS = {
  KYG: 'kyg',
  KYG_DAMAGE: 'kyg_damage',
  OFF: 'off',
}

export const SUPER_HOG_LIST_TYPE = [
  {
    type: 'kyg',
    title: 'Guest Screening',
    description: 'Included for no additional cost',
  },
  {
    type: 'kyg_damage',
    title: 'Guest Screening + Damage Protection Plan',
    description: '$12 per stay',
  },
]

export const RESERVATION_STATUS = {
  DRAFT: 'draft',
  INQUIRY: 'inquiry',
  AWAITING_PAYMENT: 'awaiting_payment',
  NEW: 'new', // mean reservation is successful created, payment is paid
  MODIFIED: 'modified', // After a NEW reservation is create, if you change some info, status must update to modified
  CANCELLED: 'cancelled', //Cancelled by host/guest
  COMPLETED: 'completed', // == CLOSED, when current date is pass the checkout
  CONFIRMED: 'confirmed',
  REJECTED: 'rejected',
  EXPIRED: 'expired',
  FAILED: 'failed',
  MANUAL_CANCELLED: 'manual_cancelled',
}

export const SUPER_HOG_LINK_TO_CLAIM = 'https://forms.gle/zvuFfmtwbUPC4sPJ6'

export const SUPPORTED_CURRENCIES = [
  {
    key: 'USD',
    symbol: '$',
  },
  {
    key: 'GBP',
    symbol: '£',
  },
  {
    key: 'EUR',
    symbol: '€',
  },
  {
    key: 'AUD',
    symbol: 'AU$',
  },
  {
    key: 'SGD',
    symbol: 'S$',
  },
  {
    key: 'CAD',
    symbol: 'CA$',
  },
  {
    key: 'HKD',
    symbol: 'HK$',
  },
  {
    key: 'CNY',
    symbol: '¥',
  },
  {
    key: 'NZD',
    symbol: 'NZ$',
  },
  {
    key: 'INR',
    symbol: '₹',
  },
  {
    key: 'VND',
    symbol: '₫',
  },
  {
    key: 'AED',
    symbol: 'د.إ',
  },
  {
    key: 'KRW',
    symbol: '₩',
  },
  {
    key: 'THB',
    symbol: '฿',
  },
  {
    key: 'BRL',
    symbol: 'R$',
  },
  {
    key: 'PLN',
    symbol: 'zł',
  },
  {
    key: 'JPY',
    symbol: '¥',
  },
]

export const DEFULT_ERROR_DAY_OF_WEEK: ErrorDayOfWeek = {
  monday: {
    show: false,
    message: '',
    showTooltip: true,
  },
  tuesday: {
    show: false,
    message: '',
    showTooltip: true,
  },
  wednesday: {
    show: false,
    message: '',
    showTooltip: true,
  },
  thursday: {
    show: false,
    message: '',
    showTooltip: true,
  },
  friday: {
    show: false,
    message: '',
    showTooltip: true,
  },
  saturday: {
    show: false,
    message: '',
    showTooltip: true,
  },
  sunday: {
    show: false,
    message: '',
    showTooltip: true,
  },
}

export const CREATE_LISTING_TYPES: { type: string; title: string; description: string; labelButton: string }[] = [
  {
    type: 'airbnb',
    title: 'Import directly from Airbnb',
    description: 'Let Dtravel import your content by connecting your Airbnb host account',
    labelButton: 'Connect account',
  },
  {
    type: 'pms',
    title: 'Connect via a PMS/CMS',
    description: 'Create your listings in minutes by connecting to one of Dtravel’s software partners listed below.',
    labelButton: 'Go to settings',
  },
  {
    type: 'manual',
    title: 'Create listings manually',
    description: 'Create your listings by filling in your property details',
    labelButton: 'Add a listing',
  },
]
