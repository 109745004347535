import ic_media_40_40 from '../../../assets/icons/ic_media_40_40.svg'
import clsx from 'clsx'
import React from 'react'
import { useAppSelector } from 'src/redux/hooks'

interface Props {
  headline?: string
  hostName?: string
  subHeadline?: string
  gallery?: string
  logo?: string
}

const RightContentLandingPage: React.FC<Props> = ({ headline, hostName, subHeadline, gallery, logo }) => {
  const { profile, settingData } = useAppSelector((state) => state.user)
  const hostNameText = hostName !== undefined ? hostName : profile?.hostName
  const headlineText = headline !== undefined ? headline : settingData.headline
  const subHeadlineText = subHeadline !== undefined ? subHeadline : settingData.subHeadline
  const galleryUrl = gallery !== undefined ? gallery : settingData.gallery
  const color = settingData?.primaryColor || '#000000'
  const logoUrl = logo !== undefined ? logo : profile?.hostLogo
  const SELECTS = [
    {
      type: 'location',
      label: 'All locations',
      icon: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none">
          <path
            d="M7.8465 7.43568C8.55456 7.43568 9.12856 6.86169 9.12856 6.15363C9.12856 5.44558 8.55456 4.87158 7.8465 4.87158C7.13845 4.87158 6.56445 5.44558 6.56445 6.15363C6.56445 6.86169 7.13845 7.43568 7.8465 7.43568Z"
            stroke={color}
            strokeWidth="1.53846"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.84607 13.8461C7.84607 13.8461 12.3332 10.7396 12.3332 6.74549C12.3332 5.56849 11.8605 4.4397 11.019 3.60744C10.1775 2.77518 9.03614 2.30762 7.84607 2.30762C6.65599 2.30762 5.51466 2.77518 4.67315 3.60744C3.83164 4.4397 3.35889 5.56849 3.35889 6.74549C3.35889 10.7396 7.84607 13.8461 7.84607 13.8461Z"
            stroke={color}
            strokeWidth="1.53846"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      type: 'dates',
      label: 'Any dates',
      icon: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} viewBox="0 0 16 17" fill="none">
          <path
            d="M2.71826 5.82031H12.9747"
            stroke={color}
            strokeWidth="1.53846"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.3336 3.25635H3.35929C3.00526 3.25635 2.71826 3.54334 2.71826 3.89737V12.8717C2.71826 13.2258 3.00526 13.5128 3.35929 13.5128H12.3336C12.6877 13.5128 12.9747 13.2258 12.9747 12.8717V3.89737C12.9747 3.54334 12.6877 3.25635 12.3336 3.25635Z"
            stroke={color}
            strokeWidth="1.53846"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.4102 1.97461V3.25666"
            stroke={color}
            strokeWidth="1.53846"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.28174 1.97461V3.25666"
            stroke={color}
            strokeWidth="1.53846"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      type: 'guest',
      label: '2 guests',
      icon: () => (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none">
          <path
            d="M11.0513 10.4023C12.5451 10.7543 13.6154 11.7041 13.6154 12.8205"
            stroke={color}
            strokeWidth="1.53846"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.07715 12.8204C2.07715 11.4043 3.79913 10.2563 5.9233 10.2563C8.04747 10.2563 9.76946 11.4043 9.76946 12.8204"
            stroke={color}
            strokeWidth="1.53846"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.76904 8.33328C11.1852 8.33328 12.3331 7.1853 12.3331 5.76918C12.3331 4.35307 11.1852 3.20508 9.76904 3.20508"
            stroke={color}
            strokeWidth="1.53846"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.92299 8.33328C7.3391 8.33328 8.48709 7.1853 8.48709 5.76918C8.48709 4.35307 7.3391 3.20508 5.92299 3.20508C4.50687 3.20508 3.35889 4.35307 3.35889 5.76918C3.35889 7.1853 4.50687 8.33328 5.92299 8.33328Z"
            stroke={color}
            strokeWidth="1.53846"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ]
  return (
    <div className={'flex justify-between items-center bg-white '}>
      <div
        className={clsx(
          'w-[300px] h-[640px]',
          'rounded-[12px] border-[4px] border-black',
          'px-[12px] py-[16px]',
          'relative'
        )}
      >
        <div className={'flex justify-between items-center h-[61.5px]'}>
          <div
            className={'font-inter-400 text-black'}
            style={{
              fontSize: '12.31px',
              lineHeight: '15.38px',
            }}
          >
            {logoUrl ?
              <img src={logoUrl} alt="hero" className="h-[20px] w-auto" /> :
              <span className='font-inter-600'>{hostNameText}</span>
            }
          </div>
          <button
            className={clsx(
              'h-[30px] px-[18px] py-[8px] rounded-[9.23px] border-[0.38px] border-neutral-300',
              'font-inter-500'
            )}
            style={{
              fontSize: '10.77px',
              lineHeight: '13.85px',
              color
            }}
          >
            Contact
          </button>
        </div>

        <div className={clsx('w-full h-[180px] rounded-[18.46px] flex items-center justify-center bg-neutral-100')}>
          {galleryUrl ? (
            <img src={galleryUrl} alt="hero" className="w-full h-full object-cover rounded-[24px] box-border" />
          ) : (
            <img src={ic_media_40_40} alt={'ic_media_40_40'} width={23} height={20} />
          )}
        </div>

        {headlineText && headlineText?.trim() ? (
          <div
            className={'font-inter-600 text-neutral-900 mt-[12px] w-[260px] line-clamp-2'}
            style={{ fontSize: '30.77px', lineHeight: '30.77px' }}
          >
            {headlineText}
          </div>
        ) : (
          <>
            <div className={'mt-[12px] w-[260px] h-[27.5px] rounded-[37px] bg-neutral-400'} />
            <div className={'mt-[6px] w-[178px] h-[27.5px] rounded-[37px] bg-neutral-400'} />
          </>
        )}
        {subHeadlineText && subHeadlineText?.trim() ? (
          <div
            className={'font-inter-400 text-[#5A5A5A] mt-[12px] w-[250px] line-clamp-3'}
            style={{ fontSize: '15.38px', lineHeight: '21.54px' }}
          >
            {subHeadlineText}
          </div>
        ) : (
          <>
            <div className={'mt-[12px] w-[250px] h-[18.5px] rounded-[37px] bg-neutral-400'} />
            <div className={'mt-[4.62px] w-[250px] h-[18.5px] rounded-[37px] bg-neutral-400'} />
            <div className={'mt-[4.62px] w-[110px] h-[18.5px] rounded-[37px] bg-neutral-400'} />
          </>
        )}

        <div className={'flex flex-col gap-[9px] mt-[18.46px]'}>
          {SELECTS.map((item) => {
            return (
              <button
                key={item.type}
                className={clsx(
                  'h-[30px] px-[9px] py-[8px] rounded-[9.23px] border-[0.38px] border-neutral-300',
                  'font-inter-500 text-black w-full',
                  'flex items-center gap-[9.23px]'
                )}
                style={{
                  fontSize: '10.77px',
                  lineHeight: '13.85px',
                }}
              >
                <span>{item.icon()}</span>
                {item.label}
                <span className={'ml-auto'}>
                  <svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} viewBox="0 0 19 19" fill="none">
                    <path
                      d="M12.3077 7.84619L9.23073 10.9231L6.15381 7.84619"
                      stroke={color}
                      strokeWidth="1.15385"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </button>
            )
          })}

          <button
            className={'w-[calc(100%-24.62px)] h-[43px] rounded-[9.23px] absolute bottom-[12.31px]'}
            style={{
              boxShadow: '0px 0.7692307233810425px 1.538461446762085px 0px rgba(0, 0, 0, 0.05)',
              backgroundColor: color
            }}
          >
            <span className={'font-inter-500 text-white text-12-16'}>Explore properties</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default RightContentLandingPage
