import React from 'react'
import ic_external_link from 'src/assets/icons/ic_external_link.svg'
import BasicCardV2 from 'src/components/common/BasicCardV2'
import BasicButton from 'src/components/ui/BasicButton'
import { PROVIDER_NETWORKS } from 'src/contants/common'
import { isEmpty } from 'src/utils/common'

interface Props {
  propertyContract?: string
  propertyNiteContracts: any[]
}

const DetailSmartContract: React.FC<Props> = ({ propertyNiteContracts }) => {
  const renderLinkContract = (propertyNiteContracts: any[]) => {
    if (Array.isArray(propertyNiteContracts) && propertyNiteContracts.length > 0) {
      const polygonChainId = PROVIDER_NETWORKS.find((v) => v.hex === process.env.REACT_APP_POLYGON_CHAIN_ID)?.decimal // Polygon or Amoy
      const baseChainId = PROVIDER_NETWORKS.find((v) => v.hex === process.env.REACT_APP_BASE_CHAIN_ID)?.decimal // Polygon or Amoy
      const contractPolygon = propertyNiteContracts.find((item) => item.chainId === polygonChainId)
      const contractBase = propertyNiteContracts.find((item) => item.chainId === baseChainId)
      const contract = contractBase || contractPolygon
      const isPolygonChainId = propertyNiteContracts.length === 1 && !isEmpty(contractPolygon)

      if (contract && contract.niteContract) {
        const url = `${isPolygonChainId ? process.env.REACT_APP_POLYGON_SCAN_URL : process.env.REACT_APP_BASE_SCAN_URL}/address/${contract.niteContract}`
        return (
          <a
            href={url}
            className={'flex items-center text-14-18 text-neutral-600 font-maison-neue-medium hover:underline'}
            target="_blank"
            rel="noreferrer"
          >
            <span className="mr-2">Show on {isPolygonChainId ? 'Polygon' : 'Base'} Scan</span>
            <span>
              <img src={ic_external_link} alt={'ic_external_link'} />
            </span>
          </a>
        )
      }
    }
    return (
      <BasicButton
        endIcon={<img src={ic_external_link} alt={'ic_external_link'} />}
        disabled={true}
        size={'md'}
        clases={'text-14-18 text-neutral-600 font-maison-neue-medium'}
      >
        Show on Polygon/Base Scan
      </BasicButton>
    )
  }

  return (
    <>
      <BasicCardV2 title={'Smart contracts'} extraTitle={renderLinkContract(propertyNiteContracts)}>
        <div className={'mb-6'}>
          <p className={'text-neutral-700 text-16-24'}>
            When a guest pays with cryptocurrency, the reservation is powered by a smart contract. Smart contracts
            automate bookings with specific instructions, such as the payment type, payment amount and cancellation
            window. With smart contracts, payment instructions are clear and chargebacks are avoided.&nbsp;
            <a
              href="https://dtravel.notion.site/How-Dtravel-Deploys-a-Smart-Contract-When-You-List-a-Property-824c55843c804a62b4678f4619273f45"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              Learn more.
            </a>
          </p>
        </div>
      </BasicCardV2>
    </>
  )
}

export default DetailSmartContract
