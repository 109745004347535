import { Tooltip } from '@mui/material'
import React from 'react'
import ic_info_circle from 'src/assets/icons/ic_info_circle.svg'
import { isEmpty } from 'src/utils/common'

interface Props {
  airbnbCancellationPolicies: any
}

export const renderStandardDescription = (airbnbCancellationPolicies: any) => {
  const isNonRefundable = !isEmpty(airbnbCancellationPolicies?.nonRefundablePriceFactor)
  const { cancellationPolicyCategory } = airbnbCancellationPolicies
  let title: string = ''
  let content: string = ''
  if (cancellationPolicyCategory === 'flexible') {
    title = !isNonRefundable ? 'Flexible' : 'Flexible or Non-refundable'
    content = !isNonRefundable
      ? 'Full refund 1 day prior to arrival'
      : 'In addition to Flexible, offer a non-refundable option—guests pay 10% less, but you keep your payout no matter when they cancel'
  }
  if (cancellationPolicyCategory === 'moderate') {
    title = !isNonRefundable ? 'Moderate' : 'Moderate or Non-refundable'
    content = !isNonRefundable
      ? 'Full refund 5 days prior to arrival'
      : 'In addition to Moderate, offer a non-refundable option—guests pay 10% less, but you keep your payout no matter when they cancel.'
  }
  if (cancellationPolicyCategory === 'better_strict_with_grace_period') {
    title = !isNonRefundable ? 'Strict' : 'Strict or Non-refundable'
    content = !isNonRefundable
      ? 'Full refund for cancellations up to 30 days before check-in. If booked fewer than 30 days before check-in, full refund for cancellations made within 48 hours of booking and at least 14 days before check-in. After that, 50% refund up to 7 days before check-in. No refund after that.'
      : 'In addition to Firm, offer a non-refundable option—guests pay 10% less, but you keep your payout no matter when they cancel.'
  }
  if (cancellationPolicyCategory === 'strict_14_with_grace_period') {
    title = !isNonRefundable ? 'Flexible or Non-refundable' : 'Flexible'
    content = !isNonRefundable
      ? 'Full refund for cancellations made within 48 hours of booking, if the check-in date is at least 14 days away. 50% refund for cancellations made at least 7 days before check-in. No refunds for cancellations made within 7 days of check-in.'
      : 'In addition to Strict, offer a non-refundable option—guests pay 10% less, but you keep your payout no matter when they cancel.'
  }
  if (cancellationPolicyCategory === 'super_strict_30') {
    title = 'Super Strict 30 days'
    content =
      'Guests must cancel at least 30 days before check-in to receive a 50% refund for all nights. If they cancel after that, you’ll be paid 100% for all nights.'
  }
  if (cancellationPolicyCategory === 'super_strict_60') {
    title = 'Super Strict 60 days'
    content =
      'Guests must cancel at least 60 days before check-in to receive a 50% refund for all nights. If they cancel after that, you’ll be paid 100% for all nights.'
  }
  return { title, content }
}

export const renderLongTermDescription = (airbnbCancellationPolicies: any) => {
  const { ltsCancellationPolicyId } = airbnbCancellationPolicies
  let title: string = ''
  let content: string = ''
  if (ltsCancellationPolicyId === 'CANCEL_LONG_TERM_WITH_GRACE_PERIOD') {
    title = 'Strict'
    content = `Full refund if cancelled within 48 hours of booking and at least 28 days before check-in.<br/>After that, the first 30 days of the stay are non-refundable.`
  }
  if (ltsCancellationPolicyId === 'CANCEL_LONG_TERM_FAIR') {
    title = 'Firm'
    content = `Full refund up to 30 days before check-in.<br/>After that, the first 30 days of the stay are non-refundable.`
  }
  return { title, content }
}

export const renderCancelPolicyToText = (airbnbCancellationPolicies: any) => {
  const isStandard = !isEmpty(airbnbCancellationPolicies?.cancellationPolicyCategory)
  const isLongTerm = !isEmpty(airbnbCancellationPolicies?.ltsCancellationPolicyId)
  const dataStandard = renderStandardDescription(airbnbCancellationPolicies)
  const dataLongTerm = renderLongTermDescription(airbnbCancellationPolicies)
  const tag = isStandard ? 'Standard' : 'Long-term'
  let text = ''
  if (isStandard) {
    text += `${dataStandard.title} - ${tag}\n` + `${dataStandard.content}\n`
  }
  if (isLongTerm) {
    text += `\n ${dataLongTerm.title} - ${tag}\n` + `${dataLongTerm.content}\n`
  }
  return text
}
const PropertyAirbnbCancelPolicy = ({ airbnbCancellationPolicies }: Props) => {
  const isLongTerm = !isEmpty(airbnbCancellationPolicies?.ltsCancellationPolicyId)
  const isStandard = !isEmpty(airbnbCancellationPolicies?.cancellationPolicyCategory)

  const renderTag = (title: string, isStandard: boolean) => {
    return (
      <div className="pl-[10px] pr-2 py-[2px] rounded-[16px] bg-neutral-100 flex items-center gap-[2px]">
        <span className="uppercase text-12-16 font-maison-neue-demibold text-neutral-700 tracking-[0.48px]">
          {isStandard ? 'Standard' : 'Long-term'}
        </span>
        <Tooltip
          placement="top"
          // title={<span dangerouslySetInnerHTML={{ __html: title }} />}
          title={isStandard ? 'Applies for stays of under 28 nights' : 'Applies for stays of 28+ nights'}
          sx={{ fontSize: 16 }}
          arrow
        >
          <img src={ic_info_circle} alt="" style={{ width: 12, height: 12 }} />
        </Tooltip>
      </div>
    )
  }
  const renderItem = (data: any, isStandard: boolean) => {
    return (
      <div className="bg-white flex flex-col gap-1 rounded-[16px] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)] p-8">
        <div className="flex flex-col-reverse md:flex-row items-start md:items-center gap-2">
          <span className="text-16-24 font-maison-neue-medium text-neutral-800">{data?.title}</span>
          {renderTag((data?.title || '') + '<br/>' + (data?.content || ''), isStandard)}
        </div>
        <p
          className="text-16-20 font-maison-neue text-neutral-600"
          dangerouslySetInnerHTML={{ __html: data?.content }}
        />
      </div>
    )
  }
  return (
    <div className="flex flex-col gap-4">
      {isStandard && renderItem(renderStandardDescription(airbnbCancellationPolicies), true)}
      {isLongTerm && renderItem(renderLongTermDescription(airbnbCancellationPolicies), false)}
    </div>
  )
}

export default PropertyAirbnbCancelPolicy
