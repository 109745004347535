import { getListings, stripeConnectMultiListing } from '../../../api/listings'
import ic_stripe from '../../../assets/icons/ic_stripe.svg'
import no_image from '../../../assets/images/no_image_listing.jpg'
import { useAppDispatch } from '../../../redux/hooks'
import { setToastSuccess } from '../../../redux/slices/common'
import { handleErrorMessage } from '../../../utils/common'
import { renderLocation } from '../../../utils/property'
import LoadingSection from '../../common/LoadingSection'
import BasicButton from '../../ui/BasicButton'
import BasicCheckbox from '../../ui/BasicCheckbox'
import BasicDialog from '../../ui/BasicDialog'
import BasicInput from '../../ui/BasicInput'
import clsx from 'clsx'
import React, { useRef, useState } from 'react'

interface Props {
  data: any
  stripeAccounts: any[]
}

const EditStripeAccount: React.FC<Props> = ({ data, stripeAccounts }) => {
  const dispatch = useAppDispatch()
  const timerDebouce = useRef<any>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [listings, setListings] = useState<any[]>([])
  const [rootListings, setRootListings] = useState<any[]>([])
  const [selectedIds, setSelectedIds] = useState<any[]>([])
  const [disabledIds, setDisabledIds] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')

  const handleOpenPopup = () => {
    setIsOpen(true)
    fetchListing()
  }

  const handleClosePopup = () => {
    setIsOpen(false)
    setListings([])
    setDisabledIds([])
    setRootListings([])
    setSelectedIds([])
    setSearchValue('')
  }

  const onSelectedAll = () => {
    setSelectedIds((prevState) => {
      // for case search filter property: must keep prevState
      const newIds = listings.map((item) => item.id)
      return [...new Set([...prevState, ...newIds])]
    })
  }

  const onUnselectedAll = () => {
    setSelectedIds([...disabledIds])
  }

  const fetchListing = async () => {
    try {
      setLoading(true)
      const res = await getListings({ pageSize: 1000 })
      if (res.success && Array.isArray(res.data)) {
        setListings(res.data)
        setRootListings(res.data)
        // const filterListings = res.data.filter((item) => {
        //   if (data.isPrimary) {
        //     return !!item.stripeAccountId && item.stripeAccountId !== data.id
        //   }
        //   return item.stripeAccountId !== data.id
        // })
        const stripeIds = res.data
          .filter((item) => {
            return item.stripeAccountId === data.id || (!item.stripeAccountId && data.isPrimary)
          })
          .map((item) => item.id)
        setSelectedIds((prevState) => [...prevState, ...stripeIds])
        setDisabledIds((prevState) => [...prevState, ...stripeIds])
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setLoading(false)
    }
  }

  const onSelectOneListing = (listingId: number) => {
    setSelectedIds((prevState) => {
      if (prevState.includes(listingId)) {
        return prevState.filter((id) => id !== listingId)
      } else {
        return [...prevState, listingId]
      }
    })
  }

  const handleSearch = (_value: string) => {
    if (_value === '') {
      setListings(rootListings)
    } else {
      // handle search
      setListings(
        rootListings.filter((property) => {
          const externalName = property.externalName ? property.externalName.toLowerCase() : ''
          const internalName = property.internalName ? property.internalName.toLowerCase() : ''
          const searchExternal = externalName.search(_value)
          const searchInternal = internalName.search(_value)
          return searchExternal > -1 || searchInternal > -1
        })
      )
    }
  }

  const onChangeSearchValue = (value: string) => {
    setSearchValue(value)
    const _value = value.trim().toLowerCase()
    // debounce
    if (timerDebouce.current) clearTimeout(timerDebouce.current)
    timerDebouce.current = setTimeout(() => {
      handleSearch(_value)
    }, 800)
  }

  const onSave = async () => {
    try {
      setLoadingSubmit(true)
      await stripeConnectMultiListing({
        stripeId: data.id,
        propertyIds: selectedIds,
      })
      dispatch(
        setToastSuccess({
          message: 'Successfully',
        })
      )
      handleClosePopup()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setLoadingSubmit(false)
    }
  }

  const renderStripeInfo = (_stripeAccountId: string | null) => {
    if (Array.isArray(stripeAccounts)) {
      let stripeInfo = stripeAccounts.find((acc) => acc.id === _stripeAccountId)
      if (!stripeInfo) {
        stripeInfo = stripeAccounts.find((acc) => acc.isPrimary)
      }
      if (stripeInfo) {
        return (
          <>
            <p className={'font-maison-neue text-12-16 text-neutral-800'}>{stripeInfo.displayName}</p>
            <p className={'font-maison-neue text-12-16 text-neutral-600 truncate'}>{stripeInfo.email}</p>
          </>
        )
      }
      return null
    }
    return null
  }

  return (
    <div>
      <BasicButton variant={'outlined'} size={'md'} isRadius100={true} onClick={handleOpenPopup}>
        Edit
      </BasicButton>

      <BasicDialog
        isOpen={isOpen}
        onClose={handleClosePopup}
        title={'Edit Stripe account'}
        extraTitle={'Select listings to associate to this Stripe account:'}
      >
        <div
          className={'flex justify-center items-center gap-[16px] p-[16px] rounded-[16px] bg-neutral-100 mt-[-16px]'}
        >
          <div className={'rounded-full w-[32px] h-[32px] min-w-[32px] flex items-center justify-center'}>
            <img src={ic_stripe} alt={'stripe'} width={32} height={32} className={'rounded-full'} />
          </div>
          <div className={''}>
            <span>{data.displayName}:&nbsp;</span>
            <span>{data.email}</span>
          </div>
        </div>

        <div className={'mt-[24px]'}>
          <BasicInput
            label={'Name'}
            value={searchValue}
            placeholder={'Search by listing name'}
            onChange={(event) => onChangeSearchValue(event.target.value)}
          />
        </div>

        <div
          className={
            'mt-[24px] rounded-[16px] bg-white border border-grayscale-300 max-h-[440px] overflow-y-auto custom-scrollbar'
          }
        >
          <LoadingSection isLoading={loading} />
          {listings.map((item) => {
            return (
              <div
                key={item.id}
                className={clsx(
                  'flex items-center gap-[12px] p-[16px] border-b-[0.5px] border-[rgba(0, 0, 0, 0.15)] w-full text-left',
                  'hover:bg-neutral-100',
                  {
                    'bg-neutral-100': selectedIds.includes(item.id),
                  }
                )}
                // onClick={() => onSelectOneListing(item.id)}
              >
                <div className={'pr-[16px]'}>
                  <BasicCheckbox
                    checked={selectedIds.includes(item.id)}
                    disabled={disabledIds.includes(item.id)}
                    onChange={() => onSelectOneListing(item.id)}
                  />
                </div>
                <div>
                  <img
                    src={item?.thumbnail || no_image}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null // prevents looping
                      currentTarget.src = no_image
                    }}
                    alt="thumb"
                    className="rounded-[8px] min-w-[32px] w-[32px] h-[32px] object-cover"
                  />
                </div>
                <div>
                  <div
                    className={'line-clamp-2 font-maison-neue-medium text-neutral-900 text-14-18'}
                    data-hover={item.externalName}
                  >
                    {item.externalName}
                  </div>
                  <div className={'line-clamp-1 text-14-18'}>{renderLocation(item.address)}</div>
                </div>

                <div className={'text-right max-w-[128px] ml-auto'}>{renderStripeInfo(item.stripeAccountId)}</div>
              </div>
            )
          })}
        </div>

        <div className={'mt-[24px] flex items-center gap-[24px]'}>
          <button className={'hover:underline active:underline'} onClick={onSelectedAll}>
            Select all
          </button>
          <button className={'hover:underline active:underline'} onClick={onUnselectedAll}>
            Unselect all
          </button>
        </div>

        <div className={'mt-[24px]'}>
          <BasicButton
            variant={'contained'}
            size={'xl'}
            isRadius100={true}
            color={'red'}
            clases={'w-full'}
            onClick={onSave}
            loading={loadingSubmit}
            disabled={selectedIds.length === 0}
          >
            Save
          </BasicButton>
        </div>
      </BasicDialog>
    </div>
  )
}

export default EditStripeAccount
