import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { getBusinessAccounts, getUserProfile, selectBusinessAccounts } from 'src/api/user'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { isEmpty } from 'src/utils/common'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { IconCaretRight } from '../common/Icons'
import { BUSINESS_USER_ROLE } from 'src/contants/common'
import { getUserInfo, saveAccessToken, saveRefreshToken, saveUserInfo } from 'src/utils/user'
import { setIsSurveySubmitted } from 'src/redux/slices/common'

const SelectAccount = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector((state) => state.user)
  const windowDimensions = useWindowDimensions()
  const screenHeight = windowDimensions.height
  const [accounts, setAccounts] = useState<any[]>([])

  const fetchAccounts = async () => {
    try {
      const res: any = await getBusinessAccounts()
      if (res?.data) setAccounts(res?.data)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    fetchAccounts()
  }, [])

  const handleLoginSuccess = async (item: any) => {
    const isAdmin = item?.role === BUSINESS_USER_ROLE.ADMIN
    if (isAdmin) {
      navigate(`/`, { replace: true })
    } else {
      const res: any = await selectBusinessAccounts(item?.businessId)
      if (res?.data) {
        let user = getUserInfo()
        user = { ...user, ...res.data }
        saveAccessToken(res.data.token)
        saveRefreshToken(res.data.refreshToken)
        saveUserInfo(user)
        const resProfile = await getUserProfile()
        const newProfile = { ...user, ...resProfile.data }
        saveUserInfo(newProfile)
        const isSurveySubmitted = newProfile?.gettingStarted?.isSurveySubmitted
        dispatch(setIsSurveySubmitted(isSurveySubmitted))
      }
      setTimeout(() => {
        window.location.href = process.env.REACT_APP_BASE_PATH || "/"
      }, 500);
      // navigate(`/`)
    }
  }

  const renderItem = (item: any) => {
    const isAdmin = item?.role === BUSINESS_USER_ROLE.ADMIN
    const name = isAdmin && !profile?.gettingStarted?.isSurveySubmitted ? "New portfolio…" : item?.name
    return (
      <div
        onClick={() => handleLoginSuccess(item)}
        role="presentation"
        className='flex items-center justify-between gap-4 cursor-pointer'
      >
        <div className={'flex items-center gap-4'}>
          <div className='flex items-center justify-center'>
            <div className='w-[64px] h-[64px] p-[16px] rounded-[8px] bg-red-700 flex items-center justify-center'>
              <span className='uppercase text-20-24 font-maison-neue-demibold text-white text-center'>
                {name.split(' ').filter((v: string, idx: number) => idx < 2).map((v: string) => v?.substring(0, 1)).join('')}
              </span>
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <span className='text-neutral-800 text-16-20 font-maison-neue-medium'>{name}</span>
            <span className='text-14-18 font-maison-neue text-neutral-600'>{item?.listingNumber} listing{item?.listingNumber > 1 ? 's' : ''}</span>
          </div>
        </div>
        <div className={'w-[24px] min-w-[24px] hidden md:block'}>
          <IconCaretRight color='#292926' />
        </div>
      </div>
    )
  }
  const name = [profile?.firstName, profile?.lastName].join(" ")?.trim()
  return (
    <div className="w-full h-full flex justify-center items-start">
      <div
        className={clsx('w-full px-[16px] md:px-0 md:max-w-[524px]', 'xl:pt-[calc((100vh-80px-588px-60px)/2)]', {
          'pt-[56px]': screenHeight > 670,
          'pt-[24px]': screenHeight <= 670,
        })}
      >
        <div className={'bg-white p-[32px] rounded-[16px] w-full'}>
          <div className='flex items-center justify-between gap-6 mb-8'>
            <div className='flex flex-col gap-1'>
              <p className="font-maison-neue text-24-28 text-neutral-800">
                Welcome{!isEmpty(name) ? `, ${name}` : ''}
              </p>
              <p className="font-maison-neue text-14-18 text-neutral-600">
                Choose a portfolio to manage
              </p>
            </div>
            <div
              className={clsx(
                'w-[48px] h-[48px] rounded-full flex items-center justify-center',
                profile?.hostLogo ? 'bg-white' : 'bg-red-700'
              )}
            >
              {profile?.hostLogo ? (
                <img src={profile?.hostLogo} alt={'logo'} className={'w-[48px] h-[48px] object-cover rounded-full'} />
              ) : (
                <span className='uppercase text-20-24 font-maison-neue-demibold text-white text-center'>
                  {[profile?.firstName, profile?.lastName].join(" ")?.split(' ')
                    .filter((v: string, idx: number) => idx < 2)
                    .map((v: string) => v?.substring(0, 1))
                    .join('')
                  }
                </span>
              )}
            </div>
          </div>

          <div className='flex flex-col gap-4'>
            {!isEmpty(accounts) && accounts.map((el: any) => {
              return (
                <Fragment key={el.businessId}>{renderItem(el)}</Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectAccount
