import { PAYMENT_FILTER, PROPERTY_STATUS } from '../../contants/native'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { useAppSelector } from '../../redux/hooks'
import { renderChannelLogo, renderSourceType } from '../../utils/property'
import { IconChecked, IcChervonDown, IconClose } from '../common/Icons'
import BasicButton from '../ui/BasicButton'
import BasicCheckbox from '../ui/BasicCheckbox'
import BasicRadio from '../ui/BasicRadio'
import CloseIcon from '@mui/icons-material/Close'
import { Drawer } from '@mui/material'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

interface Props {
  sourcesList?: any[]
  filters: any
  handleApply: (_currentFilters: any) => void
}

const GROUP_FILTERS = [
  {
    key: 'guestScreening',
    label: 'Guest Screening',
    options: [
      { value: '1', label: 'On' },
      { value: '0', label: 'Off' },
    ],
  },
  {
    key: 'damageProtection',
    label: 'Damage protection',
    options: [
      { value: '1', label: 'On' },
      { value: '0', label: 'Off' },
    ],
  },
  {
    key: 'channelStatus', // GVR
    label: 'Google vacation rentals',
    options: [
      { value: '1', label: 'Listed' },
      { value: '0', label: 'Unlisted' },
    ],
  },
]

const DEFAULT_FILTERS = {
  publishedStatus: [],
  channel: [],
  payment: '',
  damageProtection: '',
  channelStatus: '',
  fiat: '',
  crypto: '',
}

const PropertyFilters: React.FC<Props> = ({ sourcesList, filters, handleApply }) => {
  const { isMobile } = useWindowDimensions()
  const { isLoading } = useAppSelector((state) => state.common)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [localFilters, setLocalFilters] = useState<any>({ ...DEFAULT_FILTERS })

  useEffect(() => {
    setLocalFilters({ ...DEFAULT_FILTERS, ...filters })
  }, [filters])

  const handleOpen = () => {
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  const onChangeCheckbox = (field: string, checked: boolean, value: string) => {
    setLocalFilters((prevState: any) => {
      const clone = { ...prevState }
      let currentValue = [...clone[field]]
      if (checked) {
        currentValue.push(value)
      } else {
        currentValue = currentValue.filter((item: string) => item !== value)
      }
      clone[field] = currentValue
      return { ...clone }
    })
  }

  const onChangeRadio = (field: string, value: string) => {
    if (field === 'payment') {
      let paymentValue = {}
      if (value === PAYMENT_FILTER.FIAT) {
        paymentValue = { fiat: '1', crypto: '0' }
      } else if (value === PAYMENT_FILTER.CRYPTO) {
        paymentValue = { fiat: '0', crypto: '1' }
      } else if (value === PAYMENT_FILTER.BOTH) {
        paymentValue = { fiat: '1', crypto: '1' }
      } else if (value === PAYMENT_FILTER.NONE) {
        paymentValue = { fiat: '0', crypto: '0' }
      }
      setLocalFilters((prevState: any) => {
        return { ...prevState, ...paymentValue }
      })
    } else {
      setLocalFilters((prevState: any) => {
        const clone = { ...prevState }
        clone[field] = value
        return clone
      })
    }
  }

  const getOptionsFilter = (field: string) => {
    if (field === 'publishedStatus') {
      return [
        PROPERTY_STATUS.LISTED,
        PROPERTY_STATUS.PENDING_REVIEW,
        PROPERTY_STATUS.INCOMPLETE,
        PROPERTY_STATUS.UNLISTED,
      ]
    }
    if (field === 'channel') {
      return sourcesList || []
    }
    if (field === 'payment') {
      return [PAYMENT_FILTER.FIAT, PAYMENT_FILTER.CRYPTO, PAYMENT_FILTER.BOTH, PAYMENT_FILTER.NONE]
    }
    return ['1', '0']
  }

  const countFilters = () => {
    let count = 0
    for (let key in filters) {
      if (filters[key] && ['guestScreening', 'damageProtection', 'channelStatus'].includes(key)) count++
    }
    if (filters.fiat || filters.crypto) {
      count++
    }
    if (Array.isArray(filters.channel) && filters.channel.length > 0) {
      count++
    }
    if (Array.isArray(filters.publishedStatus) && filters.publishedStatus.length > 0) {
      count++
    }
    return count
  }

  const handleClear = () => {
    setLocalFilters({ ...DEFAULT_FILTERS })
  }

  const renderLabel = (field: string, value: string) => {
    const isListed = value === PROPERTY_STATUS.LISTED
    const isDraft = value === PROPERTY_STATUS.DRAFT || value === PROPERTY_STATUS.INCOMPLETE
    const isPendingReview = value === PROPERTY_STATUS.PENDING_REVIEW
    const isUnListed = value === PROPERTY_STATUS.UNLISTED
    switch (field) {
      case 'publishedStatus':
        return (
          <span
            className={clsx(
              'px-[8px] py-[2px] font-maison-neue-demibold text-10-12 tracking-[.4px] uppercase w-fit rounded-full',
              isListed && 'bg-forest-50 text-forest-700',
              (isDraft || isPendingReview) && 'bg-sun-50 text-sun-700',
              isUnListed && 'bg-red-50 text-red-700'
            )}
          >
            {isDraft ? 'incomplete' : value.replace('_', ' ')}
          </span>
        )
      case 'channel':
        return (
          <span className={'flex items-center gap-[8px] font-maison-neue text-14-18 text-neutral-600'}>
            <div className="w-4 h-4 rounded-[50%] flex items-center justify-center">
              <img src={renderChannelLogo(value)} alt="" className="w-full h-full" />
            </div>
            {renderSourceType(value)}
          </span>
        )
      case 'payment':
        return <span className={'flex items-center gap-[8px]'}>{value}</span>
      default:
        return (
          <span className={'flex items-center gap-[8px]'}>
            <span>{value === '0' ? 'Unlisted' : 'Listed'}</span>
          </span>
        )
    }
  }

  const checkValueFilterPayment = (item: any) => {
    if (item === PAYMENT_FILTER.FIAT && localFilters.fiat === '1' && localFilters.crypto === '0') return true
    if (item === PAYMENT_FILTER.CRYPTO && localFilters.fiat === '0' && localFilters.crypto === '1') return true
    if (item === PAYMENT_FILTER.BOTH && localFilters.fiat === '1' && localFilters.crypto === '1') return true
    return item === PAYMENT_FILTER.NONE && localFilters.fiat === '0' && localFilters.crypto === '0'
  }

  const renderCheckUncheckIcon = (value: string) => {
    if (value === '1')
      return (
        <div className={'flex items-center justify-center rounded-full bg-forest-50 w-[24px] h-[24px]'}>
          <IconChecked width={16} height={16} color={'#3F753C'} />
        </div>
      )
    return (
      <div className={'flex items-center justify-center rounded-full bg-red-50 w-[24px] h-[24px]'}>
        <IconClose width={16} height={16} color={'#A32A30'} />
      </div>
    )
  }

  return (
    <>
      <div className={'flex items-center gap-[12px]'}>
        <div
          className={clsx(
            'min-w-[20px] w-[20px] h-[20px]',
            'bg-neutral-300',
            'rounded-full',
            'flex items-center justify-center'
          )}
        >
          {countFilters()}
        </div>
        <button
          onClick={handleOpen}
          className={clsx(
            'flex items-center justify-center gap-[8px]',
            'font-maison-neue-medium text-16-20 text-neutral-500'
          )}
        >
          <span>Filters</span>
          <span className={'w-[20px] h-[20px] flex items-center justify-center'}>
            <IcChervonDown width={18} height={18} color={'#6A6A68'} />
          </span>
        </button>
      </div>
      <Drawer
        anchor={'right'}
        open={isOpen}
        onClose={handleClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: isMobile ? '100svw' : '298px',
            // padding: '16px 24px',
            // overflow: 'hidden',
          },
        }}
      >
        <div className="w-full h-screen relative flex flex-col">
          {/*---Header---*/}
          <div className={'flex items-center justify-between gap-[16px] px-[24px] py-[16px]'}>
            <span className={'font-maison-neue text-16-24 text-neutral-800'}>Filters</span>
            <button onClick={handleClose}>
              <CloseIcon />
            </button>
          </div>

          {/*---Body---*/}
          <div className={'p-[24px] flex flex-col gap-[32px] flex-1'}>
            {/*---Filter by status---*/}
            <div className={'flex flex-col gap-[16px]'}>
              <p className={'font-maison-neue-demibold text-12-16 text-neutral-600 tracking-[0.48px] uppercase'}>
                STATUS
              </p>
              {getOptionsFilter('publishedStatus').map((item) => {
                return (
                  <label
                    key={item}
                    className={'flex justify-between items-center gap-[16px] rounded-[16px] cursor-pointer'}
                  >
                    <div className={''}>{renderLabel('publishedStatus', item)}</div>
                    <div className={''}>
                      <BasicCheckbox
                        size20={true}
                        color={'black'}
                        checked={localFilters.publishedStatus.includes(item)}
                        onChange={(event) => onChangeCheckbox('publishedStatus', event.target.checked, item)}
                      />
                    </div>
                  </label>
                )
              })}
            </div>

            {/*---Filter by payment---*/}
            <div className={'flex flex-col gap-[16px]'}>
              <p className={'font-maison-neue-demibold text-12-16 text-neutral-600 tracking-[0.48px] uppercase'}>
                Payments
              </p>
              {getOptionsFilter('payment').map((item) => {
                return (
                  <label
                    key={item}
                    className={'flex justify-between items-center gap-[16px] rounded-[16px] cursor-pointer'}
                  >
                    <div className={'flex flex-col gap-[8px] font-maison-neue text-14-18 text-neutral-600'}>
                      {renderLabel('payment', item)}
                    </div>
                    <BasicRadio
                      isWidthHeight20px
                      id={'payment-' + item}
                      type={'radio'}
                      className={'w-[20px] h-[20px] cursor-pointer'}
                      name={'filter_property_payment'}
                      // checked={field === 'payment' ? checkValueFilterPayment(item) : filters['payment'] === item}
                      checked={checkValueFilterPayment(item)}
                      value={item}
                      onChange={() => onChangeRadio('payment', item)}
                    />
                  </label>
                )
              })}
            </div>

            {/*---Filter by Source---*/}
            <div className={'flex flex-col gap-[16px]'}>
              <p className={'font-maison-neue-demibold text-12-16 text-neutral-600 tracking-[0.48px] uppercase'}>
                Source
              </p>
              {getOptionsFilter('channel').map((item) => {
                return (
                  <label
                    key={item}
                    className={'flex justify-between items-center gap-[16px] rounded-[16px] cursor-pointer relative'}
                  >
                    <div className={'flex flex-col gap-[8px]'}>{renderLabel('channel', item)}</div>
                    <BasicCheckbox
                      size20={true}
                      color={'black'}
                      checked={localFilters.channel.includes(item)}
                      onChange={(event) => onChangeCheckbox('channel', event.target.checked, item)}
                    />
                  </label>
                )
              })}
            </div>

            {/*---Filter by Guest screening---*/}
            {GROUP_FILTERS.map((group) => {
              return (
                <div key={group.key} className={'flex flex-col gap-[16px]'}>
                  <p className={'font-maison-neue-demibold text-12-16 text-neutral-600 tracking-[0.48px] uppercase'}>
                    {group.label}
                  </p>
                  {group.options.map((item) => {
                    return (
                      <label
                        key={item.value}
                        className={'flex justify-between items-center gap-[16px] rounded-[16px] cursor-pointer'}
                      >
                        <div className={'flex items-center gap-[8px] font-maison-neue text-14-18 text-neutral-600'}>
                          <span>{renderCheckUncheckIcon(item.value)}</span>
                          <span>{item.label}</span>
                        </div>
                        <BasicRadio
                          isWidthHeight20px
                          id={'filter_' + group.key + '_' + item.value}
                          type={'radio'}
                          className={'w-[20px] h-[20px] cursor-pointer'}
                          name={`filter_property_${group.key}`}
                          checked={localFilters[group.key] === item.value}
                          onChange={() => onChangeRadio(group.key, item.value)}
                        />
                      </label>
                    )
                  })}
                </div>
              )
            })}
          </div>

          {/*---Footer---*/}
          <div
            className={'sticky bottom-0 bg-white px-[24px] py-[16px] flex items-center justify-between w-full'}
            style={{
              boxShadow: '0px -20px 24px -4px rgba(0, 0, 0, 0.06), 0px -8px 8px -4px rgba(0, 0, 0, 0.03)',
            }}
          >
            <BasicButton variant={'outlined'} size={'md'} isRadius100={true} onClick={handleClear}>
              <span className={'text-neutral-700'}>Clear all</span>
            </BasicButton>
            <BasicButton
              variant={'contained'}
              color={'red'}
              size={'md'}
              isRadius100={true}
              onClick={() => {
                handleApply(localFilters)
                handleClose()
              }}
              loading={isLoading}
            >
              Apply
            </BasicButton>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default PropertyFilters
