import { getCalendar } from '../../../api/listings'
import { updateBulkProperties, updateRates } from '../../../api/native-listing'
import { getUserProfile } from '../../../api/user'
import { DATE_FORMAT, MONTH_FORMAT } from '../../../contants/common'
import { DEFULT_ERROR_DAY_OF_WEEK } from '../../../contants/property'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { FormError, PriceWeekDay } from '../../../interfaces'
import { ErrorDayOfWeek } from '../../../interfaces/listing'
import { useAppDispatch } from '../../../redux/hooks'
import { setToastSuccess } from '../../../redux/slices/common'
import { handleErrorMessage, isEmpty, numberWithCommas } from '../../../utils/common'
import { getValidWeekdayBetweenStartAndEndDate } from '../../../utils/property'
import BasicCalendar from '../../common/BasicCalendar'
import BasicButton from '../../ui/BasicButton'
import BasicInputPrice from '../../ui/BasicInputPrice'
import BasicSwitch from '../../ui/BasicSwitch'
import clsx from 'clsx'
import moment, { Moment } from 'moment'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import BasicDialogCalendar from 'src/components/ui/BasicDialogForCalendar'
import EditBulkConfirmDialog from '../EditBulkConfirmDialog'

interface Props {
  propertyId: string
  currency: string
  isOpen: boolean
  onClose: () => void
  basePrice: number | undefined
  dateRanges: any[]
  updateDateRanges: (newRanges: any[]) => void
  fetchRates: (id: string) => void
  priceDayOfWeek: any
  isResetCalendar?: boolean
  triggerResetCalendar?: () => void
  minPrice: number
  maxPrice: number
  fetchStatusStep: any
  handleCloseEdit?: any
}

const AddCustomRate: React.FC<Props> = ({
  propertyId,
  currency,
  isOpen,
  onClose,
  isResetCalendar,
  basePrice,
  dateRanges,
  updateDateRanges,
  fetchRates,
  priceDayOfWeek,
  triggerResetCalendar,
  minPrice,
  maxPrice,
  fetchStatusStep,
  handleCloseEdit
}) => {
  const dispatch = useAppDispatch()
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const [startDate, setStartDate] = useState<Moment | null>(null)
  const [endDate, setEndDate] = useState<Moment | null>(null)
  const [isLoadingCalendar, setIsLoadingCalendar] = useState<boolean>(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [monthsFetched, setMonthFetched] = useState<string[]>([])
  const [priceWeekdayInRange, setPriceWeekdayInRange] = useState<PriceWeekDay>({
    sunday: undefined,
    monday: undefined,
    tuesday: undefined,
    wednesday: undefined,
    thursday: undefined,
    friday: undefined,
    saturday: undefined,
  })
  const [errorDayOfWeek, setErrorDayOfWeek] = useState<ErrorDayOfWeek>(DEFULT_ERROR_DAY_OF_WEEK)
  const [weekdayInDateRanges, setWeekdayInDateRanges] = useState<any>(null)
  const [weekday1] = useState<Array<keyof PriceWeekDay>>(['sunday', 'monday', 'tuesday', 'wednesday'])
  const [weekday2] = useState<Array<keyof PriceWeekDay>>(['thursday', 'friday', 'saturday'])
  const [dataCalendar, setDataCalendar] = useState<any>({})
  const [priceData, setPriceData] = useState<any>({})
  const [isDisabledSubmit, setIsDisabledSubmit] = useState<boolean>(false)
  const [useCommonPrice, setUseCommonPrice] = useState<boolean>(true)
  const [commonPrice, setCommonPrice] = useState<number | undefined>(undefined)
  const [errorCommonPrice, setErorCommonPrice] = useState<FormError>({ show: false, message: '', showTooltip: true })
  const [hostAddress, setHostAddress] = useState<string>('')
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)

  useEffect(() => {
    if (propertyId && hostAddress) {
      // first time fetch calendar
      fetchCurrentAndNextMonthCalendar(hostAddress, propertyId)
    }
  }, [propertyId, hostAddress])

  useEffect(() => {
    if (propertyId && hostAddress && isOpen && isResetCalendar) {
      // for case: delete date range => need call api calendar again
      // when delete a date range => set isResetCalendar = true
      setMonthFetched([])
      setDataCalendar({})
      fetchCurrentAndNextMonthCalendar(hostAddress, propertyId)
    }
  }, [isOpen, isResetCalendar, propertyId, hostAddress])

  useEffect(() => {
    const data: any = { currency, basePrice, priceDayOfWeek, priceWeekdayInRange }
    setPriceData(data)
  }, [currency, basePrice, priceDayOfWeek, priceWeekdayInRange])

  useEffect(() => {
    // let isDisabled: boolean = false
    if (startDate && endDate) {
      if (useCommonPrice) {
        setIsDisabledSubmit(errorCommonPrice.show || !commonPrice)
      } else {
        let hasError = false
        let hasAtleastOnePrice = false
        const weekDays = Array.from(weekdayInDateRanges)
        for (let key of weekDays) {
          // @ts-ignore
          if (errorDayOfWeek[key].show) {
            hasError = true
          }
          // @ts-ignore
          if (priceWeekdayInRange[key]) {
            hasAtleastOnePrice = true
          }
        }
        setIsDisabledSubmit(hasError || !hasAtleastOnePrice)
      }
    } else {
      // do not have startDate || endDate
      setIsDisabledSubmit(true)
    }
  }, [
    startDate,
    endDate,
    errorDayOfWeek,
    priceWeekdayInRange,
    useCommonPrice,
    errorCommonPrice,
    commonPrice,
    weekdayInDateRanges,
  ])

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const res: any = await getUserProfile()
        setHostAddress(res?.data?.user?.id || '')
      } catch (err) {
        console.log(err)
      }
    }
    fetchUserProfile()
  }, [])

  async function fetchCalendar(hostAddress: string, listingId: string, fromDate: string, toDate: string) {
    if (isEditBulk) return
    try {
      setIsLoadingCalendar(true)
      const res = await getCalendar(hostAddress, listingId, fromDate, toDate)
      if (Array.isArray(res.data)) {
        let _dataCalendar: any = {}
        for (let item of res.data) {
          _dataCalendar[item.date] = item
        }
        setDataCalendar((prevState: any) => ({ ...prevState, ..._dataCalendar }))
      }
    } finally {
      setIsLoadingCalendar(false)
    }
  }

  async function fetchCurrentAndNextMonthCalendar(hostAddress: string, listingId: string) {
    const currentDay: Moment = moment()
    const endDayOfNextMonth: Moment = moment().add(1, 'months').endOf('months')
    await fetchCalendar(hostAddress, listingId, currentDay.format(DATE_FORMAT), endDayOfNextMonth.format(DATE_FORMAT))
    setMonthFetched((prevState) => [
      ...prevState,
      currentDay.format(MONTH_FORMAT),
      endDayOfNextMonth.format(MONTH_FORMAT),
    ])
  }

  const handleNextAndPrevCalendar = async (type: 'next' | 'prev', newMonth: Moment) => {
    const monthFormated: string = moment(newMonth).format(MONTH_FORMAT)
    if (!monthsFetched.includes(monthFormated)) {
      const from: string = moment(newMonth).startOf('months').format(DATE_FORMAT)
      const to: string = moment(newMonth).endOf('months').format(DATE_FORMAT)
      await fetchCalendar(hostAddress, propertyId, from, to)
      setMonthFetched((prevState) => [...prevState, monthFormated])
    }
    const nextOrPrevMonth = moment(newMonth).add(type === 'next' ? 1 : -1, 'months')
    const nextOrPrevMonthFormated = nextOrPrevMonth.format(MONTH_FORMAT)
    if (!monthsFetched.includes(nextOrPrevMonthFormated)) {
      const from: string = moment(nextOrPrevMonth).startOf('months').format(DATE_FORMAT)
      const to: string = moment(nextOrPrevMonth).endOf('months').format(DATE_FORMAT)
      await fetchCalendar(hostAddress, propertyId, from, to)
      setMonthFetched((prevState) => [...prevState, nextOrPrevMonthFormated])
    }
  }

  const onChangePriceWeekDay = (day: string, value: string) => {
    // const price = value ? Number(value.replaceAll(',', '')) : undefined
    const price = value && !isNaN(Number(value)) ? Math.abs(Number(value)) : undefined
    if (day) {
      setPriceWeekdayInRange((prevState) => ({ ...prevState, [day]: price }))
    } else {
      setCommonPrice(price)
    }

    const basePriceIsNotInteger = !Number.isInteger(Number(price))
    if (price !== undefined && (basePriceIsNotInteger || Number(price) < minPrice || Number(price) > maxPrice)) {
      const errMess = `The base rate must be an integer value between ${numberWithCommas(
        minPrice
      )} and ${numberWithCommas(maxPrice)}`
      if (day) {
        setErrorDayOfWeek((prevState) => {
          const clone = { ...prevState }
          // @ts-ignore
          clone[day] = {
            show: true,
            message: errMess,
            showTooltip: true,
          }
          return clone
        })
      } else {
        setErorCommonPrice({
          show: true,
          message: errMess,
          showTooltip: true,
        })
      }
    } else {
      // case no error => clear error
      if (day) {
        setErrorDayOfWeek((prevState) => ({ ...prevState, [day]: { show: false, message: '', showTooltip: true } }))
      } else {
        setErorCommonPrice({ show: false, message: '', showTooltip: true })
      }
    }
  }

  const onDatesChange = (_startDate: Moment | null, _endDate: Moment | null) => {
    setStartDate(_startDate)
    setEndDate(_endDate)
    if (_startDate && _endDate) {
      const diffDay = _endDate.diff(_startDate, 'days')
      let _weekday: any = new Set()
      for (let i = 0; i <= diffDay; i++) {
        const momentDay = moment(_startDate).add(i, 'day').startOf('days')
        const dayOfWeekName = momentDay.isoWeekday(momentDay.isoWeekday()).format('dddd').toLowerCase() // sunday, monday, tuesday, wednesday, thursday, friday, saturday
        _weekday.add(dayOfWeekName)
      }
      setWeekdayInDateRanges(_weekday)
      setErrorDayOfWeek((prevState) => {
        const clone = { ...prevState }
        for (let day in clone) {
          if (!_weekday.has(day)) {
            // @ts-ignore
            clone[day] = {
              show: false,
              message: '',
              showTooltip: true,
            }
          }
        }
        return clone
      })
    } else {
      setWeekdayInDateRanges(null)
      setErrorDayOfWeek((prevState) => {
        const clone = { ...prevState }
        for (let day in clone) {
          // @ts-ignore
          const price = priceWeekdayInRange[day]
          const basePriceIsNotInteger = !Number.isInteger(Number(price))
          if (
            price !== undefined &&
            price !== '' &&
            (basePriceIsNotInteger || Number(price) < minPrice || Number(price) > maxPrice)
          ) {
            // @ts-ignore
            clone[day] = {
              show: true,
              message: `The base rate must be an integer value between ${numberWithCommas(
                minPrice
              )} and ${numberWithCommas(maxPrice)}`,
              showTooltip: true,
            }
          }
        }
        return clone
      })
    }
  }

  const onClearDates = () => {
    onDatesChange(null, null)
    setWeekdayInDateRanges(null)
  }

  const onToggleCommonPrice = (checked: boolean) => {
    setUseCommonPrice(!checked)
  }

  const handleClosePopup = () => {
    onClearDates()
    setPriceWeekdayInRange({
      sunday: undefined,
      monday: undefined,
      tuesday: undefined,
      wednesday: undefined,
      thursday: undefined,
      friday: undefined,
      saturday: undefined,
    }) // clear price
    setErrorDayOfWeek(DEFULT_ERROR_DAY_OF_WEEK) // clear error
    setCommonPrice(undefined)
    setErorCommonPrice({
      show: false,
      message: '',
      showTooltip: true,
    })
    setUseCommonPrice(true)
    onClose()
  }

  const onSaveCustomRate = async () => {
    if (!startDate || !endDate) {
      return
    }
    try {
      setIsLoadingSubmit(true)
      const oldDateRanges = Array.isArray(dateRanges) ? dateRanges : []
      let weekdayPrice: any = { ...priceWeekdayInRange }
      if (useCommonPrice) {
        for (let key in weekdayPrice) {
          weekdayPrice[key] = commonPrice
        }
      }
      const newItem = {
        startDate: startDate.format(DATE_FORMAT),
        endDate: endDate.format(DATE_FORMAT),
        weekday: getValidWeekdayBetweenStartAndEndDate(
          weekdayPrice,
          startDate.format(DATE_FORMAT),
          endDate.format(DATE_FORMAT)
        ),
      }
      let dataDTO: any = {
        weekday: priceDayOfWeek,
        pricePerNight: {
          price: basePrice,
          currency: currency,
        },
        // dateRanges: newDateRanges,
        insert: { ...newItem },
      }
      if (isEditBulk) {
        const newDateRanges = [{ ...newItem }]
        dataDTO = { ...dataDTO, dateRanges: newDateRanges }
        const propertyIds = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
        updateDateRanges(newDateRanges)
        await updateBulkProperties({ propertyIds, rate: dataDTO })
        if (handleCloseEdit) handleCloseEdit()
      } else {
        const newDateRanges = [...oldDateRanges, { ...newItem }]
        dataDTO = { ...dataDTO, dateRanges: newDateRanges }
        await updateRates({ listingId: Number(propertyId), ...dataDTO })
        fetchRates(propertyId)
        if (typeof triggerResetCalendar === 'function') {
          triggerResetCalendar()
        }
        dispatch(setToastSuccess({ message: 'Update calendar success' }))
      }
      fetchStatusStep()
      handleClosePopup()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const renderDayOfWeekItem = (day: keyof PriceWeekDay) => {
    return (
      <div key={day} className={'flex items-start justify-between gap-[16px]'}>
        <p className={'w-full font-maison-neue text-16-20 text-neutral-900 mt-[14px] capitalize'}>{day}</p>
        <div className={'w-[222px] min-w-[222px]'}>
          <BasicInputPrice
            currency={currency}
            value={priceWeekdayInRange[day]}
            onValueChange={(values) => onChangePriceWeekDay(day, values.value)}
            disabled={weekdayInDateRanges && !weekdayInDateRanges.has(day)}
            error={errorDayOfWeek[day]}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <BasicDialogCalendar
        isOpen={isOpen}
        onClose={handleClosePopup}
        title={'Add a custom rate'}
        extraTitle={'Add a different price per night for specific date ranges'}
      >
        <div className={'h-[382px] p-[24px] bg-neutral-100 shadow-sm flex items-start justify-center'}>
          <BasicCalendar
            isShowDoubleMonth={!isMobile}
            startDate={startDate}
            endDate={endDate}
            onDatesChange={onDatesChange}
            dataCalendar={dataCalendar}
            onNextAndPrev={handleNextAndPrevCalendar}
            isLoading={isLoadingCalendar}
            priceData={priceData}
            hiddenPrice={isEditBulk}
          />
        </div>

        <div className={'p-[24px] pb-0 bg-white'}>
          <div className={'flex flex-col md:flex-row items-start md:items-center justify-between gap-[8px]'}>
            <p className={'font-maison-neue-medium text-16-20 text-neutral-900'}>
              {startDate ? moment(startDate).format('D MMMM YYYY') : ''} {startDate && endDate ? '-' : ''}{' '}
              {endDate ? moment(endDate).format('D MMMM YYYY') : ''}
              {startDate && endDate ? ':' : ''} &nbsp;
            </p>

            <div className={'flex justify-between gap-[16px] w-full md:w-auto'}>
              <span className={'font-maison-neue text-14-18 text-neutral-600'}>Adjust per day of the week</span>
              <BasicSwitch checked={!useCommonPrice} onChange={(event) => onToggleCommonPrice(event.target.checked)} />
            </div>
          </div>

          {!useCommonPrice && (
            <div className={'flex flex-col md:flex-row gap-[8px] md:gap-[32px] pt-[16px]'}>
              <div className={'flex flex-col gap-[8px] w-full md:w-1/2'}>
                {weekday1.map((item) => {
                  return renderDayOfWeekItem(item)
                })}
              </div>
              <div className={'flex flex-col gap-[8px] w-full md:w-1/2'}>
                {weekday2.map((item) => {
                  return renderDayOfWeekItem(item)
                })}
              </div>
            </div>
          )}
        </div>
        <div
          className={clsx(
            `p-[24px] pt-[16px] bg-white flex flex-col md:flex-row justify-between`,
            useCommonPrice ? 'gap-[16px]' : ''
          )}
        >
          <div className={'w-full md:w-1/2'}>
            {useCommonPrice && (
              <BasicInputPrice
                currency={currency}
                value={commonPrice}
                onValueChange={(values) => onChangePriceWeekDay('', values.value)}
                error={errorCommonPrice}
              />
            )}
          </div>
          <div className={'flex gap-[16px]'}>
            <BasicButton variant={'outlined'} isRadius100={true} onClick={onClearDates} disabled={!startDate && !endDate}>
              Clear dates
            </BasicButton>
            <BasicButton
              variant={'contained'}
              color={'red'}
              isRadius100={true}
              clases={'w-full md:w-auto'}
              onClick={() => {
                if (isEditBulk) {
                  setOpenConfirm(true)
                  onClose()
                }
                else onSaveCustomRate()
              }}
              loading={isLoadingSubmit}
              disabled={isDisabledSubmit}
            >
              Add custom rate
            </BasicButton>
          </div>
        </div>
      </BasicDialogCalendar>
      <EditBulkConfirmDialog
        openConfirm={openConfirm}
        handleClose={() => {
          setOpenConfirm(false)
          handleClosePopup()
        }}
        handleSubmit={onSaveCustomRate}
      />
    </>
  )
}

export default AddCustomRate
