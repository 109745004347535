import { SETTINGS_SIDE_BAR, SETUP_GUIDE_V2, TABS_SETTING_KEYS, TABS_SETTINGS } from '../../contants/common'
import { useAppSelector } from '../../redux/hooks'
import { IconCaretRight, IcLabelInfo } from '../common/Icons'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface Props {
  activeTab: string
}

const SideBarTabs: React.FC<Props> = ({ activeTab }) => {
  const navigate = useNavigate()
  const { hash, search } = useLocation()
  const { numberOfGenesysAccount } = useAppSelector((state) => state.user)

  useEffect(() => {
    function mappingHastToScroll(_hash: string) {
      let hash = _hash.replace('#', '')
      if (hash === SETUP_GUIDE_V2.CONNECT_A_STRIPE_ACCOUNT || hash === SETUP_GUIDE_V2.CREATE_A_DTRAVEL_SMARTWALLET) {
        hash = SETTINGS_SIDE_BAR.PAYMENT_INTEGRATION
      }
      if (hash === SETUP_GUIDE_V2.IMPORT_LISTING) {
        hash = SETTINGS_SIDE_BAR.CHANNEL_INTEGRATION
      }
      // if (hash === SETUP_GUIDE_V2.ADD_EXTERNAL_ACCOUNTS) {
      //   hash = SETUP_GUIDE_V2.ADD_EXTERNAL_ACCOUNTS
      // }
      //SETUP_GUIDE.CONNECT_SOCIAL_MEDIA
      if (
        hash === SETUP_GUIDE_V2.ADD_A_SITE_DETAIL ||
        hash === SETUP_GUIDE_V2.CUSTOMIZE_YOUR_LANDING_PAGE ||
        hash === SETUP_GUIDE_V2.CUSTOMIZE_YOUR_WEBSITE ||
        hash === SETUP_GUIDE_V2.ADD_CONTACT_INFO ||
        hash === SETUP_GUIDE_V2.CUSTOMIZE_SITE_URL
      ) {
        hash = SETTINGS_SIDE_BAR.BOOKING_SITE_DISPLAY
      }
      const hanldeClickItem = (id: string) => {
        const element = document.getElementById(id)
        if (element) {
          setTimeout(() => {
            window.scrollTo({ top: element.offsetTop - (80 + 16), behavior: 'smooth' })
            navigate('/settings' + search) // remove hash from Url
          }, 100)
        }
      }
      setTimeout(() => {
        hanldeClickItem(hash)
      }, 250)
    }

    if (hash) mappingHastToScroll(hash)
  }, [hash])

  const handleClickTabItem = (key: string) => {
    navigate('/settings?tab=' + key)
  }

  return (
    <>
      <h3 className={'font-maison-neue text-28-36 text-neutral-800 tracking-[-0.28px] mb-[40px]'}>Account settings</h3>
      <ul className={'flex flex-row lg:flex-col gap-[8px] lg:gap-[16px] overflow-y-auto mb-[24px] lg:mb-0'}>
        {TABS_SETTINGS.map((item: any) => {
          const isActive = activeTab === item.key
          return (
            <li
              key={item.key}
              className={clsx('text-neutral-600 lg:text-neutral-700 lg:bg-inherit mb-[16px] lg:mb-0', {
                'text-14-18 font-maison-neuue lg:text-16-20 lg:font-maison-neue-demibold': isActive,
                'text-14-18 font-maison-neuue lg:text-16-24 lg:font-maison-neue': !isActive,
                'px-[12px] py-[8px] lg:px-0 lg:py-0 rounded-[40px] whitespace-nowrap': true,
                'bg-neutral-300': isActive,
              })}
            >
              <button
                className={'w-full flex justify-between items-center capitalize'}
                onClick={() => handleClickTabItem(item.key)}
              >
                <span>{item.title}</span>

                <div className={'flex items-center'}>
                  {item.key === TABS_SETTING_KEYS.PAYMENTS && numberOfGenesysAccount > 0 && (
                    <span className={'w-[20px] min-w-[20px] hidden lg:block'}>
                      <IcLabelInfo width={20} height={20} />
                    </span>
                  )}
                  <span className={'w-[24px] min-w-[24px] hidden lg:block'}>
                    <IconCaretRight color={isActive ? '#292926' : '#CFCFCB'} />
                  </span>
                </div>
              </button>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default SideBarTabs
