import clsx from 'clsx'
import React from 'react'
import { changePaymentMethodHost } from 'src/api/integration'
import ic_busd from 'src/assets/icons/ic_busd.svg'
import ic_usdc from 'src/assets/icons/ic_usdc.svg'
import ic_usdt from 'src/assets/icons/ic_usdt.svg'
import BasicButton from 'src/components/ui/BasicButton'
import BasicSwitch from 'src/components/ui/BasicSwitch'
import BasicTooltip from 'src/components/ui/BasicTooltip'
import { useAppSelector } from 'src/redux/hooks'
import { handleErrorMessage, removeDuplicate } from 'src/utils/common'

interface Props {
  paymentMethod: any[]
  handleNext: () => void
  fetchPaymentMethodHost: any
}

const OnboardingSiteDetailsCurrencies = ({ paymentMethod, handleNext, fetchPaymentMethodHost }: Props) => {
  const { profile } = useAppSelector((state) => state.user)
  const hasWallet = profile?.user?.walletId

  const handleChangePaymentMethodHost = async (id: string, status: boolean) => {
    try {
      const res = await changePaymentMethodHost({ id, status })
      if (res.success) fetchPaymentMethodHost()
    } catch (error: any) {
      handleErrorMessage(error)
    }
  }

  const renderFooterBtn = () => {
    return (
      <div className="flex items-center gap-4">
        <BasicButton
          size={'xl'}
          variant={'contained'}
          clases={'w-auto h-[48px] rounded-[100px_!important] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]'}
          color="red"
          onClick={handleNext}
        >
          Continue
        </BasicButton>
      </div>
    )
  }
  return (
    <>
      <p className="mb-6 text-24-36 text-neutral-800">Choose which cryptocurrencies to accept:</p>
      <div className="flex flex-col gap-6">
        <div className={'w-full bg-white rounded-[16px] shadow-basic px-[24px] mt-[8px]'}>
          {removeDuplicate(paymentMethod, "key").map((item: any, index: number) => {
            if (item.type !== 'CRYPTO') return null
            let icon =
              item.key === 'BUSD' ? ic_busd : item.key === 'USDC' ? ic_usdc : item.key === 'USDT' ? ic_usdt : null
            const isFirstItem = index === 0
            return (
              <div
                key={item.id}
                className={clsx('flex justify-between items-center py-[24px]', {
                  ['border-t border-neutral-300 ']: !isFirstItem,
                })}
              >
                <div className="flex items-center gap-[12px]">
                  <img src={item.icon || icon} alt={'stripe'} width={42} height={42} className={'rounded-full'} />
                  <div>
                    <p className={'flex flex-col md:flex-row justify-start gap-[4px] md:gap-[8px]'}>
                      <span className={'font-maison-neue-medium text-16-20 text-neutral-8'}>{item.key}</span>
                    </p>
                    <p className={'font-maison-neue text-14-18 text-neutral-6 hidden md:block mt-[4px]'}>{item.name}</p>
                  </div>
                </div>
                <>
                  {hasWallet ? (
                    <BasicSwitch
                      checked={item.status}
                      onChange={() => {
                        handleChangePaymentMethodHost(item.id, !item.status)
                      }}
                      checkedColor="#292926"
                    />
                  ) : (
                    <BasicTooltip
                      placement="top"
                      title={'Please connect to your wallet first'}
                      sx={{ '& .MuiTooltip-tooltip': { color: '#FBFAFA', fontSize: '12px', fontWeight: '16px' } }}
                      arrow
                    >
                      <div className={'w-[36px] h-[20px] rounded-full bg-neutral-300 p-[2px] cursor-not-allowed'}>
                        <div
                          className={'w-[16px] h-[16px] bg-white rounded-full'}
                          style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)' }}
                        />
                      </div>
                    </BasicTooltip>
                  )}
                </>
              </div>
            )
          })}
        </div>
        <div
          className={clsx(
            'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
            'border-t border-neutral-300 px-4 md:px-8',
            'shadow-[0px_-2px_4px_-2px_rgba(0,0,0,0.08),0px_-4px_8px_-2px_rgba(0,0,0,0.06)]'
          )}
        >
          {renderFooterBtn()}
        </div>
        <div className="hidden lg:block">{renderFooterBtn()}</div>
      </div>
    </>
  )
}

export default OnboardingSiteDetailsCurrencies
