
import React from 'react'
import { ThirdwebProvider } from "thirdweb/react";
// import { ThirdwebProvider, PayEmbed } from "thirdweb/react";
// import { useClientThirdweb } from './useClientThirdweb';

interface Props {
  children: any
}

// migrate: https://portal.thirdweb.com/typescript/v5/migrate
const ThirdwebWalletProvider = ({ children }: Props) => {
  // const client = useClientThirdweb()
  return (
    <ThirdwebProvider>
      {children}
      {/* <PayEmbed client={client} /> */}
    </ThirdwebProvider>
  );
}
export default ThirdwebWalletProvider