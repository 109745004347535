import BasicCard from '../common/BasicCard'
import RenderCryptoCurrency from '../common/RenderCryptoCurrency'
import TooltipClick from '../ui/TooltipClick'
import React, { useEffect, useState } from 'react'

interface Props {
  payoutPolicies: any[]
  currency: string
  payFor: 'host' | 'dtravel'
  timezone: string
  isInquiryManual: boolean
  canceledAt?: Date
  rawPaymentInfo: any
}

const ReservationPayout: React.FC<Props> = ({ currency, payFor, rawPaymentInfo }) => {
  const [treasuryAmount, setTreasuryAmount] = useState<string>('')
  const [hostAmount, setHostAmount] = useState<string>('')
  const isPayForHost = payFor === 'host'

  useEffect(() => {
    if (rawPaymentInfo && rawPaymentInfo.payments) {
      const payments = rawPaymentInfo.payments
      if (Array.isArray(payments)) {
        const treasuryItem = payments.find((item) => !!item.treasuryAmount)
        const hostItem = payments.find((item) => !!item.hostAmount)
        if (treasuryItem) {
          setTreasuryAmount(treasuryItem.treasuryAmount)
        }
        if (hostItem) {
          setHostAmount(hostItem.hostAmount)
        }
      }
    }
  }, [rawPaymentInfo])

  const getTreasuryPercentage = () => {
    if (rawPaymentInfo && treasuryAmount) {
      const finalPrice = rawPaymentInfo.finalPrice
      const percent = (Number(treasuryAmount) / Number(finalPrice)) * 100
      return Math.ceil(percent) + '%'
    }
    return '3%'
  }

  const title = isPayForHost ? 'Payout to you' : 'Contribution to Community Treasury'

  return (
    <>
      <div className="flex justify-between items-end md:items-center mb-[16px] mt-[40px]">
        <p className="w-1/2 font-maison-neue text-20-24 text-neutral-900">{title}</p>
        <TooltipClick
          placement="bottom"
          maxWidth={294}
          title={
            isPayForHost
              ? `Your payout schedule is based on your cancellation policy. Please see more details below.`
              : `${getTreasuryPercentage()} of booking proceeds go to the Community Treasury. As a Dtravel owner, you get to vote on how these funds are used.`
          }
        >
          <span className="text-grayscale-600 font-maison-neue-medium text-14-14 pr-[8px]">
            {isPayForHost ? 'How payouts work' : 'How the Treasury works'}
          </span>
        </TooltipClick>
      </div>
      <BasicCard>
        {/*{payoutList.map((item, index) => {*/}
        {/*  const isLast = index === payoutPolicies.length - 1*/}
        {/*  return (*/}
        {/*    <div*/}
        {/*      key={index}*/}
        {/*      className={`flex justify-between text-16-20 ${*/}
        {/*        isLast ? '' : 'pb-[24px] border-b border-solid border-sand-3 mb-[24px]'*/}
        {/*      }`}*/}
        {/*    >*/}
        {/*      <div className="flex flex-col md:flex-row items-start md:items-center">*/}
        {/*        <span className={'mr-2 font-maison-neue text-16-20 text-neutral-600 mb-[4px] md:mb-0'}>*/}
        {/*          {isPayForHost ? 'Payout' : 'Contribution'} #{index + 1} {renderTime(item)}*/}
        {/*        </span>*/}
        {/*        {renderPayoutStatus(item.status, isPayForHost, isInquiryManual)}*/}
        {/*      </div>*/}
        {/*      <RenderCryptoCurrency*/}
        {/*        price={isPayForHost ? item.hostAmount : -item.treasuryAmount}*/}
        {/*        currency={currency}*/}
        {/*        isDynamic*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  )*/}
        {/*})}*/}
        <div className={'flex justify-between text-16-20'}>
          <div className="flex flex-col md:flex-row items-start md:items-center">
            <span className={'mr-2 font-maison-neue text-16-20 text-neutral-600 mb-[4px] md:mb-0'}>
              {isPayForHost ? 'Payout' : 'Contribution'} #1
            </span>
          </div>
          <RenderCryptoCurrency
            price={isPayForHost ? Number(hostAmount) : -Number(treasuryAmount)}
            currency={currency}
            isDynamic
          />
        </div>
      </BasicCard>
    </>
  )
}

export default ReservationPayout
