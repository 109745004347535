import { handleErrorMessage, isEmpty } from '../../../utils/common'
import AddBlockDates from './AddBlockDates'
import clsx from 'clsx'
import moment from 'moment/moment'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getAvailability, updateAvailability, updateBulkProperties } from 'src/api/native-listing'
import ic_delete_trash from 'src/assets/icons/ic_delete_trash.svg'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import { DATE_FORMAT } from 'src/contants/common'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import EditBulkConfirmDialog from '../EditBulkConfirmDialog'

interface Props {
  propertyId: string
  hasViewMore?: boolean
  hasPaddingItem16px?: boolean
  fetchStatusStep?: any
  handleCloseEdit?: any
  dateRanges: any[]
  updateDateRanges: (newDateRanges: any[]) => void
}

const BlockUnavailableDates: React.FC<Props> = ({
  propertyId,
  hasViewMore,
  fetchStatusStep,
  handleCloseEdit,
  dateRanges,
  updateDateRanges,
}) => {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isEditBulk = !isEmpty(parseQuery?.ids)
  // const [dateRanges, setDateRange] = useState<any[]>([])
  const [isOpenPopupDelete, setIsOpenPopupDelete] = useState<boolean>(false)
  const [isOpenPopupBlockDates, setIsOpenPopupBlockDates] = useState<boolean>(false)
  const [indexDelete, setIndexDelete] = useState<number>(-1)
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false)
  const [isResetCalendar, setIsResetCalendar] = useState<boolean>(false)
  const [isViewMore, setIsViewMore] = useState<boolean>(false)
  const { triggerResetCalendar } = useAppSelector((state) => state.listing)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)

  async function fetchAvailablility(id: string) {
    const res = await getAvailability(id)
    if (res.success && res.data) {
      const _dateRanges = res.data
      updateDateRanges(Array.isArray(_dateRanges) ? _dateRanges : [])
    }
  }

  useEffect(() => {
    if (triggerResetCalendar) {
      setIsResetCalendar(true)
    }
  }, [triggerResetCalendar])

  useEffect(() => {
    setIsViewMore(!hasViewMore)
  }, [hasViewMore])

  useEffect(() => {
    if (propertyId) {
      fetchAvailablility(propertyId)
    }
  }, [propertyId])

  // const updateDateRanges = (newDateRanges: any[]) => {
  //   setDateRange(newDateRanges)
  // }

  const handleOpenPopupBlockDates = () => {
    setIsOpenPopupBlockDates(true)
  }

  const handleClosePopupBlockDates = () => {
    setIsOpenPopupBlockDates(false)
    setIsResetCalendar(false) // clear trigger to call api fetch calendar when open popup
  }

  const handleOpenPopupDelete = (_index: number) => {
    setIndexDelete(_index)
    setIsOpenPopupDelete(true)
  }

  const handleClosePopupDelete = () => {
    setIsOpenPopupDelete(false)
    setIndexDelete(-1)
  }

  const handleDeleteDateRanges = async () => {
    if (indexDelete < 0) {
      handleErrorMessage({ message: 'Please select a date range to delete.' })
      return
    }
    try {
      setIsLoadingDelete(true)
      const bodyData = dateRanges.map((item, index) => {
        if (index === indexDelete) {
          return { ...item, isAvailable: true }
        }
        return item
      })
      if (isEditBulk) {
        const propertyIds = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
        let dataDTO: any = {
          propertyIds,
          availabilitySettings: {
            dateRanges: bodyData,
            delete: { ...dateRanges[indexDelete] },
          },
        }
        await updateBulkProperties(dataDTO)
        if (handleCloseEdit) handleCloseEdit()
      } else {
        await updateAvailability({
          listingId: Number(propertyId),
          dateRanges: bodyData,
          delete: { ...dateRanges[indexDelete] },
        })
      }
      dispatch(setToastSuccess({ message: 'Update calendar success' }))
      // update UI

      const newDateRanges = [...dateRanges]
      newDateRanges.splice(indexDelete, 1) // remove this range from dateRanges by index
      updateDateRanges(newDateRanges) // update date range in UI
      setIsResetCalendar(true) // set trigger to call api fetch calendar when open popup
      handleClosePopupDelete()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoadingDelete(false)
    }
  }

  const renderStartToEndDate = (startDate: string, endDate: string) => {
    return (
      moment(startDate, DATE_FORMAT).format('D MMMM YYYY') + ' - ' + moment(endDate, DATE_FORMAT).format('D MMMM YYYY')
    )
  }

  const renderExtraTitleDeleteDateRange = () => {
    if (indexDelete > -1) {
      const dateRange = dateRanges[indexDelete]
      return renderStartToEndDate(dateRange.startDate, dateRange.endDate)
    }
    return ''
  }

  const handleToggleViewMore = () => {
    setIsViewMore((prevState) => !prevState)
  }

  return (
    <>
      {Array.isArray(dateRanges) && dateRanges.length > 0 && (
        <div className={'mb-[24px] flex flex-col gap-[16px]'}>
          {dateRanges.map((item, index) => {
            if (index > 1 && !isViewMore) {
              return null
            }
            return (
              <div key={`date_range_${index}`} className={`flex items-center gap-[16px]`}>
                <button
                  className={clsx(
                    'w-[36px] h-[36px] rounded-full border border-neutral-300 shadow-xs flex items-center justify-center',
                    'hover:bg-neutral-200'
                  )}
                  onClick={() => handleOpenPopupDelete(index)}
                >
                  <img alt={'ic_delete'} src={ic_delete_trash} />
                </button>
                <p className="text-16-20 text-neutral-900 font-maison-neue">
                  {renderStartToEndDate(item.startDate, item.endDate)}
                </p>
              </div>
            )
          })}
        </div>
      )}
      <div className={`flex flex-col md:flex-row gap-[8px]`}>
        <BasicButton
          variant="outlined"
          size={'md'}
          color={'black'}
          clases={'w-full md:w-auto'}
          disabled={false}
          isRadius100={true}
          onClick={handleOpenPopupBlockDates}
        >
          Select dates to block
        </BasicButton>
        {hasViewMore && Array.isArray(dateRanges) && dateRanges.length > 2 ? (
          <BasicButton
            variant="outlined"
            size={'md'}
            color={'black'}
            clases={'w-full md:w-auto'}
            disabled={false}
            isRadius100={true}
            onClick={handleToggleViewMore}
          >
            {isViewMore ? 'View less' : 'View more'}
          </BasicButton>
        ) : (
          ''
        )}
      </div>

      {/*--- popup Select block dates ---*/}
      <AddBlockDates
        propertyId={propertyId}
        isOpen={isOpenPopupBlockDates}
        onClose={handleClosePopupBlockDates}
        dateRanges={dateRanges}
        updateDateRanges={updateDateRanges}
        isResetCalendar={isResetCalendar}
        fetchStatusStep={fetchStatusStep}
        handleCloseEdit={handleCloseEdit}
      />

      {/* ----Start Popup Confirm Delete range block dates---- */}
      <BasicDialog
        isOpen={isOpenPopupDelete}
        onClose={handleClosePopupDelete}
        title={'Are you sure to unblock this range?'}
        extraTitle={renderExtraTitleDeleteDateRange()}
      >
        <div className={'flex flex-col gap-[8px]'}>
          <BasicButton
            variant={'contained'}
            isRadius100={true}
            color={'red'}
            onClick={() => {
              if (isEditBulk) {
                setOpenConfirm(true)
                setIsOpenPopupDelete(false)
              }
              else handleDeleteDateRanges()
            }}
            loading={isLoadingDelete}
          >
            Confirm
          </BasicButton>
        </div>
      </BasicDialog>
      <EditBulkConfirmDialog
        openConfirm={openConfirm}
        handleClose={() => {
          setOpenConfirm(false)
          handleClosePopupDelete()
        }}
        handleSubmit={handleDeleteDateRanges}
      />
    </>
  )
}

export default BlockUnavailableDates
