import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setIsOpenPopupAddListing } from '../../redux/slices/listing'
import BasicDialog from '../ui/BasicDialog'
import ThreeWaysAddListing from './ThreeWaysAddListing'
import React from 'react'

interface Props {}

const PopupAddListing: React.FC<Props> = () => {
  const dispatch = useAppDispatch()
  const { isOpenPopupAddListing } = useAppSelector((state) => state.listing)

  const onClose = () => {
    dispatch(setIsOpenPopupAddListing(false)) // open popup airbnb info
  }

  return (
    <BasicDialog
      isOpen={isOpenPopupAddListing}
      onClose={onClose}
      title={'Add listings'}
      maxWidth={'md'}
      extraTitle={'Create your listings manually, or save time by importing an existing account.'}
    >
      <ThreeWaysAddListing inPopup={true} />
    </BasicDialog>
  )
}

export default PopupAddListing
