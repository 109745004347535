import React, { useEffect, useState } from 'react'
import BasicButton from 'src/components/ui/BasicButton'
// import BasicDialog from 'src/components/ui/BasicDialog'
import useWallet from 'src/hooks/useWallet'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setToastError } from 'src/redux/slices/common'
import { handleErrorMessage, isEmpty, isPolygon, numberWithCommas, shorterAddress } from 'src/utils/common'
import { connectWallet, getCurrentAccount, switchToPolygonNetwork, switchToBaseNetwork } from 'src/utils/ether'
import { ReservationStatusEnum } from 'src/interfaces/reservations'
import { cancelCryptoBooking } from 'src/api/reservation'
import { ethers } from 'ethers'
import { MIN_ABI, TOKEN_CONTRACT_ABI, TRANSFER_TOKEN_ABI } from 'src/utils/abi'
import { PROVIDER_NETWORKS } from 'src/contants/common'
import { getTokenContract } from 'src/api/integration'
import ConnectWalletThirdWeb from '../thirdweb/ConnectWalletThirdWeb'
import ic_arrow_right from 'src/assets/icons/ic_arrow_right_16_16.svg'
import { utils } from 'ethers'
import { base, baseSepolia, polygonAmoy, polygon } from 'thirdweb/chains'
import { useActiveAccount, useActiveWalletChain, useSwitchActiveWalletChain, useWalletBalance, useActiveWallet } from 'thirdweb/react'
import { useClientThirdweb } from '../thirdweb/useClientThirdweb'
import { getContract, prepareContractCall, prepareTransaction, sendAndConfirmTransaction, sendTransaction } from 'thirdweb'

interface Props {
  data: any
  handleChangeStatus: (callback?: () => void) => void
  handleClose: any
  setTitleDialog: any
}

const CancelReservationCrypto: React.FC<Props> = ({
  data,
  handleChangeStatus,
  handleClose,
  setTitleDialog
}) => {
  const { chainId, ethereum, setChainId } = useWallet()
  const activeWalletThirdweb = useActiveWallet()
  const activeChainThirdweb = useActiveWalletChain()
  const chainIdThirdweb = activeChainThirdweb?.id
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production'
  const {
    reservationId,
    // hostWallet,
    guestWallet,
    priceInfoV2,
    currency,
    source,
    currencyAddress, // token contract address payment
    paymentInfo,
    paymentReceiverWallet,
    guestInfo
  } = data
  const { finalPrice, paymentContract } = paymentInfo?.paymentInfo as any // price convert crypto decimal
  const isManualReservation = source === 'manual'
  const isInquiry = data?.status === ReservationStatusEnum.INQUIRY
  const isInquiryManual = isManualReservation && isInquiry
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector((state) => state.user)
  const reservationChainId = data?.chainId
  const isSmartWallet = profile?.user?.hasSmartWallet
  const isWrongNetwork = isSmartWallet ? `${chainIdThirdweb}` !== `${reservationChainId}` : `${chainId}` !== `${reservationChainId}`  // wrong net work 
  const walletConnected = useActiveAccount()
  const switchChain = useSwitchActiveWalletChain();
  const address = walletConnected?.address || ''
  const [curentWallet, setCurrentWallet] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [isConnecting, setConnecting] = useState<boolean>(false)
  const [tokenAddressList, setTokenAddressList] = useState<any[]>([])
  const [balanceToken, setBalanceToken] = useState<number>(0) // balance metamask wallet
  const [transactionHash, setTransactionHash] = useState<string>('')
  const client = useClientThirdweb()
  // @ts-ignore
  const contract = getContract({
    client,
    chain: activeChainThirdweb,
    address: currencyAddress,
  })

  const { data: balance } = useWalletBalance({
    chain: activeChainThirdweb,
    address,
    client,
    tokenAddress: currencyAddress?.startsWith('0x0') ? undefined : currencyAddress,
  }); // balance smart wallet

  const balanceSmartWallet = balance && balance?.symbol === currency ? Number(utils.formatUnits(balance?.value, balance?.decimals)) : 0
  const finalBalance = isSmartWallet ? balanceSmartWallet : balanceToken
  const isWrongWallet = isSmartWallet ?
    !isEmpty(address) && address.toLowerCase() !== paymentReceiverWallet.toLowerCase() :
    !isEmpty(curentWallet) && curentWallet.toLowerCase() !== paymentReceiverWallet.toLowerCase() //   wrong wallet

  useEffect(() => {
    setLoading(false)
    if (ethereum && !isSmartWallet) {
      ethereum.on('accountsChanged', (accounts: string[]) => {
        setCurrentWallet(accounts.length > 0 ? accounts[0] : '')
      })
    }
  }, [chainId])
  const getChainId = async () => {
    const newChainId = await ethereum.request({ method: 'eth_chainId' })
    if (chainId !== parseInt(newChainId)) setChainId(parseInt(newChainId))
  }

  const handleConnectMetamask = async () => {
    try {
      setConnecting(true)
      const accounts = await connectWallet()
      let address = accounts.length > 0 ? accounts[0] : ''
      if (address) {
        setCurrentWallet(accounts.length > 0 ? accounts[0] : '')
        const newChainHex = await ethereum.request({ method: 'eth_chainId' })
        const newChainId = PROVIDER_NETWORKS.find((v: any) => v.hex === newChainHex)?.decimal || parseInt(newChainHex)
        setChainId(Number(newChainId))
      } else {
        dispatch(setToastError({ message: 'Please select an account' }))
        return
      }
    } catch (err: any) {
      dispatch(setToastError({ message: typeof err === 'string' ? err : err.message }))
    } finally {
      setConnecting(false)
    }
  }
  const fetchTokenContract = async () => {
    const networkItem = PROVIDER_NETWORKS.find((v) => `${v.decimal}` === `${reservationChainId}`)
    if (networkItem) {
      try {
        const res: any = await getTokenContract(networkItem?.decimal)
        if (res?.success) setTokenAddressList(res?.data)
      } catch (error) {
        console.log(error)
      }
    }
  }
  const getBalanceToken = async (tokenAddress: string, walletAddress: string) => {
    const provider = new ethers.providers.Web3Provider((window as any)?.ethereum)
    const contract = new ethers.Contract(tokenAddress, MIN_ABI, provider)
    return await contract.balanceOf(walletAddress)
  }
  const getBalanceNativeToken = async (walletAddress: string) => {
    const provider = new ethers.providers.Web3Provider((window as any)?.ethereum)
    const balance = await provider.getBalance(walletAddress)
    return balance
  }
  const fetchBalancesAll = async () => {
    const tokenActive = tokenAddressList.find((v) => v.symbol === currency)
    let balances: any = null
    try {
      if (tokenActive.address?.startsWith('0x0')) {
        balances = await getBalanceNativeToken(curentWallet)
      } else {
        balances = await getBalanceToken(tokenActive.address, curentWallet)
      }
      const balanceValue = utils.formatUnits(balances?.toString(), tokenActive?.decimal)
      setBalanceToken(Number(balanceValue))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!isEmpty(tokenAddressList) && curentWallet && !isSmartWallet) fetchBalancesAll()
  }, [tokenAddressList])
  useEffect(() => {
    fetchTokenContract()
    // eslint-disable-next-line
  }, [reservationChainId, chainId, curentWallet])

  useEffect(() => {
    async function fetchMetamaskAccount() {
      const account = await getCurrentAccount()
      setCurrentWallet(account)
    }
    if (!isSmartWallet) fetchMetamaskAccount()
    getChainId()
    if (ethereum && !isSmartWallet) {
      ethereum.on('chainChanged', async (newChainHex: string) => {
        const newChainId = PROVIDER_NETWORKS.find((v: any) => v.hex === newChainHex)?.decimal || parseInt(newChainHex)
        setChainId(Number(newChainId))
      })
    }
  }, [])

  const handleCancelReservation = async () => {
    const isNeedApproved = !currencyAddress?.startsWith('0x0') // native coin
    setLoading(true)
    try {
      const provider = new ethers.providers.Web3Provider((window as any)?.ethereum)
      let baseNonce = provider.getTransactionCount(paymentReceiverWallet)
      let nonceOffset = 0
      const getNonce = () => {
        return baseNonce.then((nonce: any) => nonce + nonceOffset++)
      }
      const signer = provider.getSigner(paymentReceiverWallet)
      const contract = new ethers.Contract(currencyAddress, TOKEN_CONTRACT_ABI, signer)

      if (isNeedApproved) {
        // book with polygon network
        await contract.approve(paymentContract, finalPrice, {
          nonce: getNonce(),
        })
        contract.on('Approval', async (from, to) => {
          const isSuccess =
            from.toLowerCase() === paymentReceiverWallet.toLowerCase() && String(to).toLowerCase() === paymentContract.toLowerCase()
          if (isSuccess) {
            contract.removeAllListeners('Approval')
            // handle payment
            const newContract = new ethers.Contract(currencyAddress, TRANSFER_TOKEN_ABI, signer)
            const tx = await newContract
              .transfer(guestWallet, finalPrice, { nonce: getNonce(), gasLimit: 5000000 })
              .catch((err: any) => {
                setLoading(false)
                dispatch(setToastError({ message: err?.data?.message || err?.message }))
              })
            const book = await tx.wait() // wait transfer success 
            // setLoading(false)
            if (book) {
              setTransactionHash(book.transactionHash)
              handleCancelCryptoAfterTransferSuccess(book.transactionHash)
            }
          }
        })
      } else {
        // book with native token
        const tx = await signer.sendTransaction({
          to: guestWallet,
          value: finalPrice,
        })
        const book: any = await tx.wait() // wait transfer success 
        // setLoading(false)
        if (book) {
          setTransactionHash(book.transactionHash)
          handleCancelCryptoAfterTransferSuccess(book.transactionHash)
        }
      }
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      dispatch(setToastError({ message: error?.data?.message || error?.message }))
    }
  }
  const handleCancelCryptoAfterTransferSuccess = async (transactionHash: string) => {
    try {
      await cancelCryptoBooking(reservationId, transactionHash)
      setLoading(false)
      setTitleDialog('Reservation successfully canceled')
      // handleClose() // close popup
      handleChangeStatus()
    } catch (err: any) {
      handleErrorMessage(err)
      setLoading(false)
    }
  }
  const showPrice = (price: number) => {
    const isETH = currency === 'ETH'
    const numberCommas = isETH ? 6 : 2
    const amount = Number(price).toFixed(numberCommas)
    const amountToString = String(Number(amount) < 0 ? -1 * Number(amount) : Number(amount))
    const arrSplit = amountToString.split('.')
    if (arrSplit[1]) {
      // case số thập phân, có phần nguyên
      if (arrSplit[1].length === 1) {
        arrSplit[1] = arrSplit[1] + '0'
      } else {
        // case có từ 2 chữ số sau dấu phẩy trở lên => GIỮ NGUYÊN
      }
    } else {
      // case số nguyên, ko có phần thập phân sau dấu phẩy
      arrSplit[1] = '00'
    }
    let newAmount = arrSplit.filter((v) => v !== '').join('.')
    return `<b>${numberWithCommas(newAmount)} ${currency}</b>`
  }
  const isConnected = isSmartWallet ? !isEmpty(address) : !isEmpty(curentWallet)
  const isActiveRefund = !isWrongNetwork && !isWrongWallet
  const isNotEnoughBalance = finalBalance < Number(data.finalPrice)
  const finalPriceShow = Number(isInquiryManual ? data?.finalPrice : priceInfoV2?.finalPrice?.[currency || 'USD'] || 0)
  if (!isEmpty(transactionHash)) {
    return (
      <div>
        <p
          className='text-16-24 font-maison-neue text-neutral-800 mb-6 custom-text text-center'
          dangerouslySetInnerHTML={{
            __html: `You’ve refunded ${showPrice(finalPriceShow)} to ${guestInfo?.name}'s wallet and notified your guest of the cancelation.`
          }}
        />
        <div className='w-full flex flex-col gap-4'>
          <BasicButton
            variant={'contained'}
            color={'red'}
            isRadius100={true}
            clases={'w-full'}
            onClick={handleClose}
          >
            Close
          </BasicButton>
          <BasicButton
            variant={'outlined'}
            color={'black'}
            isRadius100={true}
            clases={'w-full'}
            onClick={() => {
              window.open(`${isPolygon(reservationChainId) ? process.env.REACT_APP_POLYGON_SCAN_URL : process.env.REACT_APP_BASE_SCAN_URL}/tx/${transactionHash}`, '_blank')
            }}
          >
            View the refund transaction
          </BasicButton>
        </div>
      </div>
    )
  }
  const targetActiveChain = isProduction ?
    isPolygon(reservationChainId) ? polygon : base :
    isPolygon(reservationChainId) ? polygonAmoy : baseSepolia
  const isMetaMaskWallet = profile?.user?.loginWallet && !profile?.user?.hasSmartWallet && profile?.user?.loginWallet?.toLowerCase() === paymentReceiverWallet?.toLowerCase()
  return (
    <div>
      <p
        className='text-16-24 font-maison-neue text-neutral-800 mb-6 custom-text text-center'
        dangerouslySetInnerHTML={{ __html: `By canceling this reservation, you’ll refund ${showPrice(finalPriceShow)} to your guest wallet.` }}
      />
      <div className='flex flex-col gap-2 mb-6'>
        <div className='flex items-center justify-between gap-2'>
          <p className='text-14-18 font-maison-neue-medium text-neutral-800'>From</p>
          {isConnected &&
            <p
              className={`text-14-18 font-maison-neue-medium ${isNotEnoughBalance ? 'text-red-600' : 'text-neutral-800'}`}
              dangerouslySetInnerHTML={{ __html: `Balance: ${showPrice(finalBalance)}` }}
            />
          }
        </div>
        <div className='px-[14px] py-[10px] rounded-[12px] border-[0.5px] border-[rgba(0,0,0,0.15)] flex items-center justify-between'>
          <div className='flex items-center gap-2'>
            <span className='text-16-20 font-maison-neue text-neutral-800'>
              {isMetaMaskWallet ? "Your Metamask Wallet" : "Your Dtravel Smartwallet"}
            </span>
          </div>
          <div className='flex items-center'>
            <span className='text-16-20 font-maison-neue text-neutral-500 capitalize'>
              {shorterAddress(paymentReceiverWallet)}
            </span>
          </div>
        </div>
        <div className='flex items-center justify-center w-full'>
          <img src={ic_arrow_right} alt="" className='rotate-[90deg]' />
        </div>
        <div className='px-[14px] py-[10px] rounded-[12px] border-[0.5px] border-[rgba(0,0,0,0.15)] flex items-center justify-between'>
          <div className='flex items-center gap-2'>
            <span className='text-16-20 font-maison-neue text-neutral-800'>
              {guestInfo?.name}
            </span>
          </div>
          <div className='flex items-center'>
            <span className='text-16-20 font-maison-neue text-neutral-500 capitalize'>
              {shorterAddress(guestWallet)}
            </span>
          </div>
        </div>
      </div>
      <p className='text-16-24 font-maison-neue text-neutral-800 mb-6 custom-text text-center'>
        Once the transaction is approved, your guest will be notified of the cancelation and the stay dates will be re-opened for booking.
      </p>
      {!isActiveRefund &&
        <p
          className='text-16-24 font-maison-neue text-neutral-800 mb-6 custom-text text-center'
          dangerouslySetInnerHTML={{ __html: `To start the cancelation process, connect the wallet which received the reservation payment: <b>${shorterAddress(paymentReceiverWallet)}</b>` }}
        />
      }
      {isConnected && (isWrongNetwork || isWrongWallet) &&
        <p
          className='text-16-24 font-maison-neue text-neutral-800 mb-6 custom-text text-center p-4 rounded-[16px] bg-red-100'
          dangerouslySetInnerHTML={{
            __html:
              isWrongNetwork ?
                `Wrong network connected. In order to cancel this reservation, you'll need to switch to ${isPolygon(reservationChainId) ? 'Polygon' : 'Base'}` :
                `Wrong wallet connected. Please connect the wallet which received the reservation payment: <b>${shorterAddress(paymentReceiverWallet)}</b>`
          }}
        />
      }
      <div className='w-full flex flex-col gap-4'>
        {isConnected ?
          <>
            {isNotEnoughBalance && !(isWrongNetwork || isWrongWallet) &&
              <p className='text-14-18 font-maison-neue text-red-600 text-center'>
                Balance too low. You do not have sufficient funds.
              </p>
            }
            {isWrongNetwork ?
              <BasicButton
                variant={'contained'}
                color={'red'}
                isRadius100={true}
                clases={'w-full'}
                onClick={async () => {
                  setLoading(true)
                  if (isSmartWallet) {
                    await switchChain(targetActiveChain)
                    activeWalletThirdweb?.disconnect()
                    setLoading(false)
                  } else {
                    if (isPolygon(reservationChainId)) await switchToPolygonNetwork()
                    else await switchToBaseNetwork()
                  }
                }}
                loading={loading || isConnecting}
              >
                Switch network
              </BasicButton> :
              <BasicButton
                variant={'contained'}
                color={'red'}
                isRadius100={true}
                clases={'w-full'}
                onClick={async () => {
                  if (isSmartWallet) {
                    try {
                      setLoading(true)
                      const isNativeToken = currencyAddress?.startsWith('0x0') // native coin
                      let tx: any = null
                      if (isNativeToken) {
                        // @ts-ignore
                        const transaction = prepareTransaction({
                          to: guestWallet,
                          chain: activeChainThirdweb,
                          client,
                          value: finalPrice,
                        });
                        if (walletConnected) {
                          tx = await sendAndConfirmTransaction({ transaction, account: walletConnected });
                        }
                      } else {
                        // @ts-ignore
                        const transaction = prepareContractCall({
                          contract,
                          method: "function transfer(address to, uint256 value)",
                          params: [guestWallet, finalPrice],
                        });
                        if (walletConnected) {
                          tx = await sendTransaction({ transaction, account: walletConnected });
                          // const transactionResult = await sendTransaction({
                          //   transaction,
                          //   wallet: walletConnected,
                          // });
                          // const receipt = await waitForReceipt(transactionResult);
                        }
                      }
                      if (tx) {
                        setTransactionHash(tx.transactionHash)
                        handleCancelCryptoAfterTransferSuccess(tx.transactionHash)
                      }
                    } catch (error: any) {
                      console.log(error)
                      dispatch(setToastError({
                        // message: error?.data?.message || error?.message 
                        message: 'We couldn’t cancel reservation due to an error occured with your wallet. Please ensure you have enough balance to cover transaction amount and gas fee.'
                      }))
                      setLoading(false)
                    }
                  } else {
                    handleCancelReservation()
                  }
                }}
                loading={loading}
                disabled={loading || isNotEnoughBalance || isWrongNetwork || isWrongWallet}
              >
                Initiate refund
              </BasicButton>
            }
            <BasicButton
              variant={'outlined'}
              color={'black'}
              isRadius100={true}
              clases={'w-full'}
              onClick={handleClose}
            >
              Cancel
            </BasicButton>
            <p className='text-14-20 font-maison-neue text-neutral-500'>
              You’ll be asked to approve the refund transaction in the next screen
            </p>
          </> :
          <>
            {isSmartWallet ?
              <ConnectWalletThirdWeb btnTitle="Connect wallet" className="custom-smartwallet-red" targetActiveChain={targetActiveChain} /> :
              <BasicButton
                variant={'contained'}
                color={'red'}
                isRadius100={true}
                clases={'w-full'}
                onClick={async () => {
                  handleConnectMetamask()
                }}
                loading={loading || isConnecting}
              >
                Connect wallet
              </BasicButton>
            }
          </>
        }

      </div>
    </div>
  )
}

export default CancelReservationCrypto
