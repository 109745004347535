import ic_close from '../../assets/icons/ic_close.svg'
import { BUSINESS_USER_ROLE, EXTERNAL_LINK } from '../../contants/common'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setIsOpenConfirmLogout } from '../../redux/slices/user'
import BasicButton from '../ui/BasicButton'
import BasicSwipeDrawer from '../ui/BasicSwipeDrawer'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import React, { useState } from 'react'
import {
  IconDiscord,
  IconLogOut,
  IconBlogV2,
  IcChervonDown,
  IconFeedback,
  IconHelpQuestion,
  IconX,
  IconUser,
} from 'src/components/common/Icons'
import { isEmpty } from 'src/utils/common'

export const SUB_PAGES = [
  { path: EXTERNAL_LINK.FEEDBACK, label: 'Feedback' },
  { path: EXTERNAL_LINK.RESOURCES, label: 'Help center' },
  { path: EXTERNAL_LINK.DISCORD, label: 'Discord' },
  { path: EXTERNAL_LINK.TWITTER, label: 'X (Twitter)' },
  { path: EXTERNAL_LINK.BLOG, label: 'Blog' },
]

const UserInfoNew = () => {
  const dispatch = useAppDispatch()
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768
  const { profile } = useAppSelector((state) => state.user)
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  // const [isShowMore, setIsShowMore] = useState<boolean>(false)

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickLogout = () => {
    dispatch(setIsOpenConfirmLogout(true))
    handleClose()
  }

  const renderIcon = (path: string) => {
    switch (path) {
      case EXTERNAL_LINK.RESOURCES:
        return <IconHelpQuestion width={16} height={16} color={'#292926'} />
      case EXTERNAL_LINK.DISCORD:
        return <IconDiscord width={16} height={16} color={'#292926'} />
      case EXTERNAL_LINK.TWITTER:
        return <IconX />
      // return <IconTwitter width={16} height={16} color={'#292926'} />
      case EXTERNAL_LINK.BLOG:
        return <IconBlogV2 width={16} height={16} color={'#292926'} />
      case EXTERNAL_LINK.FEEDBACK:
        return <IconFeedback width={16} height={16} color={'#292926'} />
    }
  }

  const open = Boolean(anchorEl)

  const renderContent = () => {
    return (
      <div className={'relative'}>
        <div className={'flex md:hidden justify-end items-center absolute top-[16px] right-[16px]'}>
          <IconButton onClick={handleClose} sx={{ padding: 0, minWidth: 24 }}>
            <img src={ic_close} alt="" width={24} height={24} />
          </IconButton>
        </div>
        <div className={'flex flex-col items-center px-[24px] py-[20px] gap-[12px]'}>
          <div
            className={
              'w-[40px] h-[40px] rounded-full shadow-xs border border-neutral-300 flex items-center justify-center'
            }
          >
            {profile?.hostLogo ? (
              <img src={profile?.hostLogo} alt={'logo'} className={'w-[40px] h-[40px] object-cover rounded-full'} />
            ) : (
              <IconUser />
            )}
          </div>
          <div className={'flex flex-col items-center'}>
            <p className={'font-maison-neue-medium text-16-20 text-neutral-700'}>
              {!isEmpty(profile?.editorProfile)
                ? [profile?.editorProfile?.firstName, profile?.editorProfile?.lastName].join(' ')
                : [profile?.firstName, profile?.lastName].join(' ')}
            </p>
            <p className={'font-maison-neue text-14-18 text-neutral-500 mt-[2px]'}>
              {!isEmpty(profile?.editorProfile) ? profile?.editorProfile?.hostName : profile?.hostName}
            </p>
            <div className="bg-neutral-100 rounded-[16px] py-[2px] px-[10px] h-[20px]">
              <span className="text-neutral-700 uppercase text-12-16 font-maison-neue-demibold">
                {profile?.editorProfile?.type === BUSINESS_USER_ROLE.EDITOR ? 'Editor' : 'Admin'}
              </span>
            </div>
          </div>
        </div>

        <div className={'h-[1px] w-full bg-neutral-300'} />

        {SUB_PAGES.map((item) => {
          return (
            <div key={item.path}>
              <a
                href={item.path}
                target={'_blank'}
                rel={'noreferrer'}
                className={'flex justify-between items-center px-[24px] py-[20px] w-full text-left hover:bg-neutral-50'}
              >
                <span className={'font-maison-neue-medium text-14-18 text-neutral-800'}>{item.label}</span>
                <span className={''}>{renderIcon(item.path)}</span>
              </a>
              <div className={'h-[1px] w-full bg-neutral-300'} />
            </div>
          )
        })}
        {/*{!isShowMore && (*/}
        {/*  <div>*/}
        {/*    <button*/}
        {/*      className={*/}
        {/*        'px-[24px] py-[19px] flex justify-between items-center w-full text-left hover:bg-neutral-50 font-maison-neue-medium text-14-18 text-neutral-800'*/}
        {/*      }*/}
        {/*      onClick={() => setIsShowMore(true)}*/}
        {/*    >*/}
        {/*      <span>More</span>*/}
        {/*      <span>*/}
        {/*        <img src={ic_arrow_down} alt={'ic_arrow_down'} />*/}
        {/*      </span>*/}
        {/*    </button>*/}
        {/*    <div className={'h-[1px] w-full bg-neutral-300'} />*/}
        {/*  </div>*/}
        {/*)}*/}

        <button
          className={
            'px-[24px] py-[20px] flex justify-between items-center w-full text-left hover:bg-neutral-50 font-maison-neue-medium text-14-18 text-red-700'
          }
          onClick={handleClickLogout}
        >
          <span>Logout</span>
          <span>
            <IconLogOut />
          </span>
        </button>
      </div>
    )
  }

  return (
    <>
      <BasicButton
        size={'md'}
        variant={'contained'}
        color={'white'}
        isRadius100
        endIcon={<IcChervonDown width={16} height={16} color={'#6A6A68'} />}
        onClick={handleOpen}
        style={open ? { boxShadow: '0px 0px 0px 4px rgba(0, 0, 0, 0.08)' } : {}}
      >
        <span className={'text-12-16 text-neutral-600'}>
          {!isEmpty(profile?.editorProfile)
            ? profile?.editorProfile?.firstName || 'Account'
            : profile?.firstName || 'Account'}
        </span>
      </BasicButton>
      {isMobile ? (
        <BasicSwipeDrawer isOpen={open} onClose={handleClose} bodyStyle={{ padding: 0 }}>
          {renderContent()}
        </BasicSwipeDrawer>
      ) : (
        <Popover
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: -4,
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              padding: '0',
              boxShadow: '0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.06)',
              borderRadius: '16px',
              width: '320px',
            },
          }}
          className="mobile-hidden"
        >
          {renderContent()}
        </Popover>
      )}
    </>
  )
}

export default UserInfoNew
