import { connectPms } from '../../api/integration'
import { importListing } from '../../api/listings'
import { PMS_TYPE } from '../../contants/common'
import { CONFIG_PMS } from '../../contants/user'
import { FormError } from '../../interfaces'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setToast } from '../../redux/slices/common'
import { setImportRequestId } from '../../redux/slices/listing'
import {
  setIsOpenPopupConnectPms,
  setPmsTypeConnected,
  setPmsTypeSelected,
  setTotalPropertyAfterImport,
} from '../../redux/slices/user'
import { getKeys, handleErrorMessage } from '../../utils/common'
import BasicButton from '../ui/BasicButton'
import BasicInput from '../ui/BasicInput'
import BasicPopup from '../ui/BasicPopup'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import React, { useEffect, useState } from 'react'

const ConnectPms = () => {
  const dispatch = useAppDispatch()
  const { isOpenPopupConnectPms, pmsTypeSelected } = useAppSelector((state) => state.user)
  const [error, setError] = useState<{
    show: boolean
    code: string
    message: string
  }>({
    show: false,
    code: '',
    message: '',
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [data, setData] = useState<{
    clientId: string
    clientSecret: string
  }>({
    clientId: '',
    clientSecret: '',
  })
  const [errors, setErrors] = useState<{
    clientId: FormError
    clientSecret: FormError
  }>({
    clientId: {
      show: false,
      message: '',
    },
    clientSecret: {
      show: false,
      message: '',
    },
  })

  useEffect(() => {
    let isErrorData = false
    let allowKeys = ['clientId', 'clientSecret']
    if (pmsTypeSelected === PMS_TYPE.UPLISTING) {
      allowKeys = ['clientSecret']
    }
    if (pmsTypeSelected === PMS_TYPE.GUEST_SMILES || pmsTypeSelected === PMS_TYPE.NEXTPAX) {
      allowKeys = ['clientId']
    }
    for (const key of getKeys(data)) {
      if (allowKeys.includes(key)) {
        if (!data[key] || !data[key].trim()) {
          isErrorData = true
        }
      }
    }
    for (const key of getKeys(errors)) {
      if (allowKeys.includes(key)) {
        if (errors[key].show) {
          isErrorData = true
        }
      }
    }
    setIsDisabled(isErrorData)
  }, [data, pmsTypeSelected])

  const handleClose = () => {
    dispatch(setIsOpenPopupConnectPms(false))
    dispatch(setPmsTypeSelected(''))
    // clear data
    setData({
      clientId: '',
      clientSecret: '',
    })
    setErrors({
      clientId: {
        show: false,
        message: '',
      },
      clientSecret: {
        show: false,
        message: '',
      },
    })
    setError({
      show: false,
      code: '',
      message: '',
    })
  }

  const handleChangeData = (field: string, value: string) => {
    // only allow number for clientId ( Hostaway)
    if (pmsTypeSelected === PMS_TYPE.HOSTAWAY && field === 'clientId' && value && !/^[0-9]+$/.test(value)) {
      return
    }
    setData((prevState) => ({ ...prevState, [field]: value }))
    setError({
      show: false,
      code: '',
      message: '',
    })
    if (!value || !value.trim()) {
      setErrors((prevState) => ({
        ...prevState,
        [field]: {
          show: true,
          message: 'This field is required',
        },
      }))
    } else {
      setErrors((prevState) => ({
        ...prevState,
        [field]: {
          show: false,
          message: '',
        },
      }))
    }
  }

  const handleConnect = async () => {
    try {
      setIsLoading(true)
      let bodyData: any = {}
      let sucMsg = ''
      if (pmsTypeSelected === PMS_TYPE.HOSTAWAY) {
        bodyData = { clientId: data.clientId, clientSecret: data.clientSecret }
        sucMsg = 'Hostaway connected successfully'
      }
      if (pmsTypeSelected === PMS_TYPE.UPLISTING) {
        bodyData = { clientSecret: data.clientSecret }
        sucMsg = 'Uplisting connected successfully'
      }
      if (pmsTypeSelected === PMS_TYPE.GUEST_SMILES) {
        bodyData = { clientId: data.clientId }
        sucMsg = 'Track via GuestSmiles connected successfully'
      }
      if (pmsTypeSelected === PMS_TYPE.NEXTPAX) {
        bodyData = { clientId: data.clientId }
        sucMsg = 'Nextpax via GuestSmiles connected successfully'
      }
      await connectPms({ type: pmsTypeSelected, ...bodyData })
      const resImport = await importListing(pmsTypeSelected) // call import listing after connected
      dispatch(setTotalPropertyAfterImport(resImport?.data?.result || 0))
      dispatch(setImportRequestId(resImport?.data?.requestId || ''))
      dispatch(setToast({ show: true, message: sucMsg, type: 'success' }))
      dispatch(setPmsTypeConnected(pmsTypeSelected)) // set pms connected

      handleClose()
    } catch (err: any) {
      if (err.error) {
        let errMsg = err.error.message
        let errCdoe = err.error.code || err.error.statusCode
        if (err.error.code === 'H002' || err.error.code === 'H003') {
          errCdoe = 'Incorrect credentials'
        }
        if (err.error.code === 'H003') {
          if (pmsTypeSelected === PMS_TYPE.HOSTAWAY) {
            errMsg = 'Please add the correct account number and API key'
          }
          if (pmsTypeSelected === PMS_TYPE.UPLISTING) {
            errMsg = 'Please add the correct API key'
          }
          if (pmsTypeSelected === PMS_TYPE.GUEST_SMILES) {
            errMsg = 'Please add the correct GuestSmiles API Identifier'
          }
        }
        setError({
          show: true,
          code: errCdoe,
          message: errMsg,
        })
      } else {
        handleErrorMessage(err)
      }
    } finally {
      setIsLoading(false)
    }
  }

  if (!pmsTypeSelected && !CONFIG_PMS[pmsTypeSelected]) {
    return null
  }

  return (
    <BasicPopup
      isOpen={isOpenPopupConnectPms}
      onClose={handleClose}
      title={CONFIG_PMS[pmsTypeSelected].title}
      extraTitle={
        <p className={'font-maison-neue text-16-24 text-grayscale-800 text-center mt-[8px] whitespace-pre-line'}>
          {CONFIG_PMS[pmsTypeSelected].description}{' '}
          {CONFIG_PMS[pmsTypeSelected]?.link && (
            <a className={'underline'} target={'_blank'} rel="noreferrer" href={CONFIG_PMS[pmsTypeSelected].link}>
              Learn how to connect.
            </a>
          )}
        </p>
      }
    >
      <div className={'grid grid-cols-1 gap-4'}>
        {[PMS_TYPE.HOSTAWAY, PMS_TYPE.GUEST_SMILES, PMS_TYPE.NEXTPAX].includes(pmsTypeSelected) && (
          <div>
            <BasicInput
              label={
                [PMS_TYPE.GUEST_SMILES, PMS_TYPE.NEXTPAX].includes(pmsTypeSelected)
                  ? 'GuestSmiles API Identifier'
                  : 'Account ID'
              }
              pattern="[0-9]*"
              inputMode="numeric"
              type="password"
              name="clientId"
              autoComplete="false"
              showIconPassword
              showPasswordFirstTime
              value={data.clientId}
              onChange={(event) => handleChangeData('clientId', event.target.value)}
              error={errors.clientId}
            />
          </div>
        )}
        {[PMS_TYPE.HOSTAWAY, PMS_TYPE.UPLISTING].includes(pmsTypeSelected) && (
          <div>
            <BasicInput
              label={'API key'}
              type="password"
              name="clientSecret"
              autoComplete="false"
              showIconPassword
              showPasswordFirstTime
              value={data.clientSecret}
              onChange={(event) => handleChangeData('clientSecret', event.target.value)}
              error={errors.clientSecret}
            />
          </div>
        )}

        {error.show && (
          <div className={'p-4 bg-red-1 rounded-xl flex gap-3'}>
            <span>
              <ErrorOutlineOutlinedIcon sx={{ color: '#A32A30' }} />
            </span>
            <div className={'w-full break-all'}>
              <p className={'text-16-20 text-red-6'}>{error.code}</p>
              <p className={'text-12-16 text-neutral-700 '}>{error.message}</p>
            </div>
          </div>
        )}

        <div className={'mt-2'}>
          <BasicButton
            variant={'contained'}
            color={'red'}
            clases={'w-full'}
            loading={isLoading}
            disabled={isDisabled}
            onClick={handleConnect}
            isRadius100
          >
            {isLoading ? 'Connecting...' : 'Connect'}
          </BasicButton>
        </div>
      </div>
    </BasicPopup>
  )
}

export default ConnectPms
