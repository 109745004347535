import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import React from 'react'

interface Props extends TooltipProps { }

const BootstrapTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    padding: '8px 12px',
    borderRadius: '8px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: '#50504E',
  },
}))

const BasicTooltipWhite: React.FC<Props> = ({ children, ...props }) => {
  return <BootstrapTooltip {...props}>{children}</BootstrapTooltip>
}

export default BasicTooltipWhite
