import { TABS_SETTING_KEYS } from '../../contants/common'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import ic_arrow_right from 'src/assets/icons/ic_arrow_right.svg'
import BasicCardV2 from 'src/components/common/BasicCardV2'

const DetailPaymentOptions = () => {
  const navigate = useNavigate()
  return (
    <BasicCardV2
      title={'Guest payment options'}
      extraTitle={
        <button
          className={'flex items-center text-14-18 text-neutral-600 font-maison-neue-medium hover:underline'}
          onClick={() => navigate('/settings?tab=' + TABS_SETTING_KEYS.PAYMENTS)}
        >
          <span className={'mr-2'}>Go to settings</span>
          <img src={ic_arrow_right} alt="" style={{ width: 16, height: 16 }} />
        </button>
      }
    >
      <p className={'text-neutral-700 text-16-24'}>
        Payment options for all of your listings are controlled in your settings page.
      </p>
    </BasicCardV2>
  )
}

export default DetailPaymentOptions
