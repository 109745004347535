import { importHospitableAirbnb } from '../../api/integration'
import { PMS_TYPE } from '../../contants/common'
import { setImportRequestId } from '../../redux/slices/listing'
import { handleErrorMessage } from '../../utils/common'
import React, { useEffect, useRef, useState } from 'react'
import { getImportProgressByRequestId, syncListing } from 'src/api/listings'
import ic_arrows_reload from 'src/assets/icons/ic_arrows_reload.svg'
import ic_hostaway_short from 'src/assets/icons/ic_hostaway_short.svg'
import ic_uplisting from 'src/assets/icons/ic_uplisting_logo.svg'
import BasicButton from 'src/components/ui/BasicButton'
import { SYNC_STATUS } from 'src/contants/property'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setToast, setToastSuccess, setTriggerListingProperty } from 'src/redux/slices/common'
import { convertSyncedTimeAgo } from 'src/utils/property'

const PropertySync = () => {
  const dispatch = useAppDispatch()
  const intervalSyncPms = useRef<any>(null)
  const countFailed = useRef<number>(0)
  const { pmsTypeConnected, pmsSyncAt } = useAppSelector((state) => state.user)
  const { importRequestId } = useAppSelector((state) => state.listing)
  const [isSyncing, setIsSyncing] = useState<boolean>(false)
  const [syncingText, setSyncingText] = useState<string>('')
  const [syncedTime, setSyncedTime] = useState<Date | null>(null)

  async function fetchImportProgress(requestId: string, isClickedSync?: boolean) {
    if (!intervalSyncPms.current) {
      intervalSyncPms.current = setInterval(
        async () => {
          try {
            // show toast message for case after connect PMS
            if (!isClickedSync) {
              dispatch(
                setToast({
                  show: true,
                  message:
                    'Your listings are being imported. If any issues occur, please contact us at support@dtravel.com.',
                  type: 'loading',
                })
              )
              dispatch(setTriggerListingProperty()) // to recall api
            }
            const res = await getImportProgressByRequestId(requestId)

            if (res.success) {
              // get synced time
              const finishedItem = res.data.find((item: any) => item.status !== SYNC_STATUS.PENDING)
              if (finishedItem) {
                setSyncedTime(finishedItem.updatedAt)
              }
              const total = res.data.length
              const totalComplete = res.data.filter((item: any) => item.status !== SYNC_STATUS.PENDING).length
              const totalFail = res.data.filter(
                (item: any) => item.status === SYNC_STATUS.FAIL || item.status === SYNC_STATUS.IGNORED
              ).length
              setSyncingText(
                `${totalComplete}/${total}  ${totalComplete > 1 ? 'listings' : 'listing'} have completed syncing...`
              )
              const isEveryNotPending = res.data.every((item: any) => item.status !== SYNC_STATUS.PENDING)
              // case import done
              if (isEveryNotPending) {
                clearInterval(intervalSyncPms.current)
                intervalSyncPms.current = null
                setIsSyncing(false)
                dispatch(setImportRequestId('')) // clear request Id
                dispatch(setTriggerListingProperty()) // to recall api
                setTimeout(() => {
                  if (totalFail > 0) {
                    handleErrorMessage({
                      message: `${totalFail}/${total} listing syncing failed.`,
                    })
                  } else {
                    dispatch(
                      setToastSuccess({
                        message: `Your listings have been ${isClickedSync ? 'synced' : 'imported'} successfully.`,
                      })
                    )
                  }
                }, 1500)
              }
            }
          } catch (err: any) {
            countFailed.current++
            if (countFailed.current > 10 || !isClickedSync) {
              clearInterval(intervalSyncPms.current)
              intervalSyncPms.current = null
              countFailed.current = 0
              setIsSyncing(false)
              dispatch(setImportRequestId('')) // clear request Id
            }
          }
        },
        isClickedSync ? 3000 : 5000
      )
    }
  }

  useEffect(() => {
    if (importRequestId) {
      fetchImportProgress(importRequestId)
    }
  }, [importRequestId])

  const handleSyncPmsLising = async () => {
    try {
      // clear interval if the interval is running
      if (intervalSyncPms.current) {
        clearInterval(intervalSyncPms.current)
        intervalSyncPms.current = null
      }
      setIsSyncing(true)
      setSyncingText('0 listing synced successfully...')
      const res = await syncListing()
      if (res.success && res.data && res.data.requestId) {
        const requestId = res.data.requestId
        await fetchImportProgress(requestId, true)
      } else {
        setIsSyncing(false)
      }
    } catch (err) {
      handleErrorMessage(err)
      setIsSyncing(false)
    }
  }

  // eslint-disable-next-line
  const handleSyncHospitableLising = async () => {
    try {
      setIsSyncing(true)
      setSyncingText('0 listing synced successfully...')
      const res = await importHospitableAirbnb()
      if (res.success && res.data && res.data.requestId) {
        const requestId = res.data.requestId
        await fetchImportProgress(requestId, true)
      } else {
        setIsSyncing(false)
      }
    } catch (err) {
      handleErrorMessage(err)
      setIsSyncing(false)
    }
  }

  const handleClickSync = async () => {
    // if (pmsTypeConnected) handleSyncPmsLising()
    // if (isConnectHospitable) handleSyncHospitableLising()
    handleSyncPmsLising()
  }

  const renderSyncedTime = () => {
    if (syncedTime) {
      return convertSyncedTimeAgo(syncedTime)
    } else {
      return <>{pmsTypeConnected && convertSyncedTimeAgo(pmsSyncAt)}</>
    }
  }

  return (
    <div className={'flex items-center gap-[16px] whitespace-nowrap'}>
      {pmsTypeConnected && (
        <div className={'flex items-center gap-[8px] w-auto '}>
          <span
            className={
              'font-maison-neue-medium text-12-16 text-neutral-800 md:max-w-full line-clamp-1 flex max-w-[100px] md:max-w-auto'
            }
          >
            {isSyncing ? (syncingText ? syncingText : renderSyncedTime()) : renderSyncedTime()}
          </span>

          <span className={'min-w-[24px] w-[24px] h-[24px] lg:w-[16px] lg:h-[16px]'}>
            {pmsTypeConnected === PMS_TYPE.HOSTAWAY && (
              <img className={'rounded-[4px] w-full h-full'} src={ic_hostaway_short} alt={'ic_hostaway'} />
            )}
            {pmsTypeConnected === PMS_TYPE.UPLISTING && (
              <img className={'rounded-[4px] w-full h-full'} src={ic_uplisting} alt={'ic_uplisting'} />
            )}
          </span>

          {/*---Button Sync Desktop---*/}
          <BasicButton
            clases={'hidden lg:flex'}
            variant={'contained'}
            size={'md'}
            color={'white'}
            isRadius100={true}
            loading={isSyncing}
            onClick={handleClickSync}
          >
            Sync listings
          </BasicButton>
          {/*---Button Sync Mobile---*/}
          <button
            className={
              'flex lg:hidden min-w-[36px] h-[36px] items-center justify-center rounded-full border border-neutral-300 bg-white ' +
              'hover:bg-neutral-200 ' +
              'disabled:cursor-not-allowed disabled:opacity-50'
            }
            disabled={isSyncing}
            onClick={handleClickSync}
          >
            <img src={ic_arrows_reload} alt={'ic_arrows_reload'} />
          </button>
        </div>
      )}
    </div>
  )
}

export default PropertySync
