import { useState, useEffect } from 'react'

const MOBILE_BREAK_POINT = 768
function getWindowDimensions(breakPoint?: number) {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
    isMobile: width < (breakPoint || MOBILE_BREAK_POINT),
  } as any
}

export default function useWindowDimensions() {
  if (typeof window === 'undefined') return { width: 0, height: 0 }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
