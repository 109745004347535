import ic_minus from '../../assets/icons/ic_minus.svg'
import ic_plus from '../../assets/icons/ic_plus.svg'
import BasicSelect from '../ui/BasicSelect'
import BasicTextarea from '../ui/BasicTextarea'
import IconButton from '@mui/material/IconButton'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

interface Props {
  data: any
  onChangeData: (field: string, value: string | number) => void
}

const EditHouseRules: React.FC<Props> = ({ data, onChangeData }) => {
  const [timeOptions, setTimeOptions] = useState<{ value: string; label: string }[]>([])

  useEffect(() => {
    let _options: { value: string; label: string }[] = []
    for (let i = 0; i <= 23; i++) {
      const label = (i > 9 ? i.toString() : '0' + i.toString()) + ':00'
      _options.push({ value: i.toString(), label })
    }
    setTimeOptions(_options)
  }, [])

  const onChangeMaxPet = (type: 'decrese' | 'increse') => {
    const currentMaxPet = data.maxPet
    if (type === 'decrese') {
      if (currentMaxPet > 0) {
        onChangeData('maxPet', currentMaxPet - 1)
      }
    } else {
      onChangeData('maxPet', currentMaxPet + 1)
    }
  }

  return (
    <div className={'flex flex-col gap-[24px]'}>
      <div className="flex gap-[16px]">
        <div className="w-1/2">
          <BasicSelect
            options={[...timeOptions, { value: '25', label: '01:00 (next day)' }]}
            label={'Check-in'}
            value={data.checkinTime}
            onChange={(event) => onChangeData('checkinTime', event.target.value)}
          />
        </div>
        <div className="w-1/2">
          <BasicSelect
            options={timeOptions}
            label={'Check-out'}
            value={data.checkoutTime}
            onChange={(event) => onChangeData('checkoutTime', event.target.value)}
          />
        </div>
      </div>

      <div>
        <BasicTextarea
          label={'House rules'}
          placeholder="Add any house rules..."
          rows={5}
          value={data.houseRules}
          onChange={(event) => onChangeData('houseRules', event.target.value)}
        />
        <p className={'font-maison-neue text-14-18 text-neutral-500 mt-[8px]'}>
          Operators typically mention noise rules, trash restrictions, etc...
        </p>
      </div>
      <div>
        <BasicTextarea
          label={'Cancelation policy'}
          placeholder="Add any cancelation policy..."
          rows={5}
          value={data.cancellationPolicy}
          onChange={(event) => onChangeData('cancellationPolicy', event.target.value)}
        />
      </div>
      <div className="flex items-center justify-start gap-2 md:gap-[32px] py-[16px]">
        <div className={'font-maison-neue text-16-24 text-neutral-800 flex-1'}>Maximum number of pets allowed</div>
        <div className={'flex items-center gap-2'}>
          <IconButton
            size={'small'}
            onClick={() => onChangeMaxPet('decrese')}
            disabled={data.maxPet <= 0}
            sx={{
              '&.Mui-disabled': {
                cursor: 'not-allowed',
                pointerEvents: 'auto',
              },
            }}
          >
            <img src={ic_minus} alt={''} className={clsx('w-[36px] h-[36px] min-w-[36px]')} />
          </IconButton>
          <span className={'min-w-[54px] font-maison-neue text-24-36 text-neutral-800 text-center'}>{data.maxPet}</span>
          <IconButton
            size={'small'}
            onClick={() => onChangeMaxPet('increse')}
            disabled={data.maxPet >= 20}
            sx={{
              '&.Mui-disabled': {
                cursor: 'not-allowed',
                pointerEvents: 'auto',
              },
            }}
          >
            <img src={ic_plus} alt={''} className={clsx('w-[36px] h-[36px] min-w-[36px]')} />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default EditHouseRules
