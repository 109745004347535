import {IResponse} from 'src/interfaces'
import moment, {Moment} from 'moment'
import {nanoid} from 'nanoid'
import {store} from 'src/redux/store'
import queryString from 'query-string'
import {DATE_FORMAT, MONTH_FORMAT} from 'src/contants/common'
import {PriceAvailability} from 'src/interfaces/listing'
import { getCalendarHost} from "../api/listings";
import {setCalendarDatesMap, setDisabledDatesMap, setToastError} from "../redux/slices/common";
import {DateTime} from "luxon";
import { parse } from "psl";
import momentTZ from 'moment-timezone'
import {countries} from "./country";
import {SUPPORTED_CURRENCIES} from "../contants/property";

const has = Object.prototype.hasOwnProperty

export const isEmpty = (prop: any) => {
  return (
    prop === null ||
    prop === undefined ||
    (has.call(prop, 'length') && prop.length === 0) ||
    (prop.constructor === Object && Object.keys(prop).length === 0)
  )
}

export function isEmailValid(email: string) {
  // let re = /^[a-zA-Z0-9_\\.\\+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\\.]+$/
  let re = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,}$/
  return re.test(email)
}

export function isOTPValid(otp: string) {
  let re = /^(\d(\s+)?){6}$/
  return re.test(otp)
}

export const isURL = (url: string) => {
  const expression =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  const regex = new RegExp(expression)
  return url.match(regex)
}

export const shorterAddress = (address: string) => {
  if (address) {
    return address.substring(0, 10) + '...' + address.substring(address.length - 5)
  }
  return ''
}

export const isOnlyLetters = (str: string) => {
  // const regex = /^[A-Za-z ]+$/ // only letters
  const regex = /^[a-zA-Z0-9_\s\u00C0-\u1FFF]+$/ // letter + number
  // return str.match(letters)
  return regex.test(str)
}

export const output = (res?: any): IResponse => ({
  ok: true,
  result: res,
})

export const error = (code?: number, msg?: string, data?: any): IResponse => ({
  ok: false,
  code,
  msg,
  data,
})

export const getRandomInteger1To5 = () => {
  return (new Date().getTime() % 5) + 1
}

export const getKeys = <T extends {}>(o: T): Array<keyof T> => <Array<keyof T>>Object.keys(o)

export const isMobileDevice = () => {
  try {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
  } catch (e) {
    return false
  }
}

export const renderTimeMobile = (checkIn: Date | string, checkOut: Date | string) => {
  const checkInToMoment = moment(checkIn)
  const checkOutToMoment = moment(checkOut)
  // same month, same year
  if (checkInToMoment.isSame(checkOutToMoment, 'month') && checkInToMoment.isSame(checkOutToMoment, 'year')) {
    return `${checkInToMoment.format('MMM')} ${checkInToMoment.format('D')} - ${checkOutToMoment.format(
      'D'
    )}, ${checkInToMoment.format('YYYY')}`
  }
  // diff month, same year
  if (!checkInToMoment.isSame(checkOutToMoment, 'month') && checkInToMoment.isSame(checkOutToMoment, 'year')) {
    return `${checkInToMoment.format('MMM D')} - ${checkOutToMoment.format('MMM D')}, ${checkInToMoment.format('YYYY')}`
  }
  return checkInToMoment.format('MMM D, YYYY') + ' - ' + checkOutToMoment.format('MMM D, YYYY')
}
export const renderTimeMobileTimezone = (checkIn: Date | string, checkOut: Date | string, timezone: string) => {
  const checkInToMoment = momentTZ.tz(checkIn, timezone).utc()
  const checkOutToMoment = momentTZ.tz(checkOut, timezone).utc()
  // same month, same year
  if (checkInToMoment.isSame(checkOutToMoment, 'month') && checkInToMoment.isSame(checkOutToMoment, 'year')) {
    return `${checkInToMoment.format('MMM')} ${checkInToMoment.format('D')} - ${checkOutToMoment.format(
      'D'
    )}, ${checkInToMoment.format('YYYY')}`
  }
  // diff month, same year
  if (!checkInToMoment.isSame(checkOutToMoment, 'month') && checkInToMoment.isSame(checkOutToMoment, 'year')) {
    return `${checkInToMoment.format('MMM D')} - ${checkOutToMoment.format('MMM D')}, ${checkInToMoment.format('YYYY')}`
  }
  return checkInToMoment.format('MMM D, YYYY') + ' - ' + checkOutToMoment.format('MMM D, YYYY')
}

export const isScrolledIntoView = (el: HTMLElement, thresholdTop?: number , thresholdBot?: number) => {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;
  return (elemTop - (thresholdTop || 0) >= 0) && (elemBottom - (thresholdBot || 0) <= window.innerHeight);
}

export const numberWithCommas = (x: number | string) => {
  let result = x
    .toString()
    .split('.')
    .map((el: string, idx: number) => (idx === 0 ? el?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : el))
  return result.join('.')
}

export const convertCurrency = (amount: string) => {
  const result = amount.toString().split('.')
  return `${numberWithCommas(Number(result[0]))}.${result[1] || '00'}`
}

export const convertAmPm = (hour: number | string | null | undefined, removeAt?: boolean) => {
  if (hour !== null && hour !== undefined) {
    const _hour = Number(hour)
    if (Number.isNaN(_hour)) {
      return hour
    }
    if (_hour > 12 && _hour < 24) return `${removeAt ? '' : 'at '}` + `${_hour - 12}` + ':00 PM'
    if (_hour === 12) return `${removeAt ? '' : 'at '}12:00 PM`
    if (_hour === 24) return `${removeAt ? '' : 'at '}00:00 AM`
    if (_hour === 25) return `${removeAt ? '' : 'at '}01:00 AM (next day)`
    return (removeAt ? '' : 'at ') + _hour + ':00 AM'
  }
  return ''
}

export const genClientID = () => {
  return nanoid(64)
}

export const getDomain = () => {
  return process.env.REACT_APP_DOMAIN
}

export const validateWidthHeightImage = async (file: File, widthValid?: number, heightValid?: number) => {
  const reader = new FileReader();

//Read the contents of Image File.
  return new Promise((resolve) => {
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      const image: any = new Image();
      //Set the Base64 string return from FileReader as source.
      // @ts-ignore
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        const height = this.height;
        const width = this.width;
        const isWidthValid = !widthValid || widthValid && width >= widthValid
        const isHeightValid = !heightValid || heightValid && height >= heightValid
        resolve(isWidthValid && isHeightValid)
      }
    }
  })
}

export const getWidthHeightImage = async (file: File) => {
  const reader = new FileReader();

//Read the contents of Image File.
  return new Promise((resolve) => {
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      const image: any = new Image();
      //Set the Base64 string return from FileReader as source.
      // @ts-ignore
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        const height = this.height;
        const width = this.width;
        resolve({width, height} as {width: number, height: number})
      }
    }
  })
}

export const convertDomain = (url: string) => {
  let urlValue = url?.replace('http://', '')?.replace('https://', '')
  if (urlValue?.slice(0, 4) === 'www.') urlValue = urlValue?.replace('www.', '')
  return urlValue
}

export const firstTimeLoadCalendar = async (hostId: string, listingId: string) => {
  const parsed = queryString.parse(window.location.search)
  const checkIn = parsed.check_in ? moment(parsed.check_in as string, DATE_FORMAT) : moment()

  const paramsStartDate = moment(checkIn).startOf('months').format(DATE_FORMAT)
  const paramsEndDate = moment(checkIn).add(3, 'month').endOf('months').format(DATE_FORMAT)
  // call api get calendar availability
  try {
    const res = await getCalendarHost(hostId, listingId, paramsStartDate, paramsEndDate)
    const keyCurrentMonth = moment(checkIn).format(MONTH_FORMAT)
    const keyAddOneMonth = moment(checkIn).add(1, 'month').format(MONTH_FORMAT)
    const keyAddTwoMonth = moment(checkIn).add(2, 'month').format(MONTH_FORMAT)
    const keyAddThreeMonth = moment(checkIn).add(3, 'month').format(MONTH_FORMAT)
    // disabled dates
    const disabledDatesCurrentMonth: any = new Set<string>()
    const disabledDatesAddOneMonth: any = new Set<string>()
    const disabledDatesAddTwoMonth: any = new Set<string>()
    const disabledDatesAddThreeMonth: any = new Set<string>()
    // all calenar dates
    const calendarDatesCurrentMonth: any[] = []
    const calendarDatesAddOneMonth: any[] = []
    const calendarDatesAddTwoMonth: any[] = []
    const calendarDatesAddThreeMonth: any[] = []

    res.data.forEach((item: PriceAvailability) => {
      // const isBlocked = item.status === 'reserved'
      // const isBlocked = item.closedOnCheckin && item.closedOnCheckout
      const isBlocked = !item.bookable && item.closedOnCheckout
      if (isBlocked) {
        if (item.date.includes(keyCurrentMonth)) {
          disabledDatesCurrentMonth.add(item.date)
        }
        if (item.date.includes(keyAddOneMonth)) {
          disabledDatesAddOneMonth.add(item.date)
        }
        if (item.date.includes(keyAddTwoMonth)) {
          disabledDatesAddTwoMonth.add(item.date)
        }
        if (item.date.includes(keyAddThreeMonth)) {
          disabledDatesAddThreeMonth.add(item.date)
        }
      }
      if (item.date.includes(keyCurrentMonth)) {
        calendarDatesCurrentMonth.push(item)
      }
      if (item.date.includes(keyAddOneMonth)) {
        calendarDatesAddOneMonth.push(item)
      }
      if (item.date.includes(keyAddTwoMonth)) {
        calendarDatesAddTwoMonth.push(item)
      }
      if (item.date.includes(keyAddThreeMonth)) {
        calendarDatesAddThreeMonth.push(item)
      }
    })
    const disabledMap = new Map()
    disabledMap.set(keyCurrentMonth, disabledDatesCurrentMonth)
    disabledMap.set(keyAddOneMonth, disabledDatesAddOneMonth)
    disabledMap.set(keyAddTwoMonth, disabledDatesAddTwoMonth)
    disabledMap.set(keyAddThreeMonth, disabledDatesAddThreeMonth)
    store.dispatch(setDisabledDatesMap(disabledMap))

    const calendardMap = new Map()
    calendardMap.set(keyCurrentMonth, calendarDatesCurrentMonth)
    calendardMap.set(keyAddOneMonth, calendarDatesAddOneMonth)
    calendardMap.set(keyAddTwoMonth, calendarDatesAddTwoMonth)
    calendardMap.set(keyAddThreeMonth, calendarDatesAddThreeMonth)
    store.dispatch(setCalendarDatesMap(calendardMap))
  } catch (err: any) {
    console.log(err)
  }
}

export const loadCalendarNextAndPrev = async ({
                                                currentDate,
                                                type,
                                                currentCalendardMap,
                                                currentDisabledMap,
                                                hostId,
                                                listingId,
                                              }: {
  currentDate: Moment
  type: 'next' | 'prev'
  currentCalendardMap: any
  currentDisabledMap: any
  hostId: string
  listingId: string
}) => {
  let start = moment(currentDate).add(0, 'month').startOf('month')
  let end = moment(currentDate).add(3, 'month').endOf('month')
  if (type === 'prev') {
    // click preview month
    start = moment(currentDate).add(-3, 'month').startOf('month')
    end = moment(currentDate).add(0, 'month').endOf('month')
  }
  const paramsStartDate = moment(start).format(DATE_FORMAT)
  const paramsEndDate = moment(end).format(DATE_FORMAT)

  const disabledDates: any = {}
  const calendarDates: any = {}
  const months = type === 'prev' ? [-3, -2, -1, 0] : [0, 1, 2, 3]
  for (let m of months) {
    const key = moment(currentDate).add(m, 'month').format(MONTH_FORMAT)
    disabledDates[key] = new Set()
    calendarDates[key] = []
  }

  // call api get calendar availability
  try {
    const res = await getCalendarHost(hostId, listingId, paramsStartDate, paramsEndDate)
    res.data.forEach((item: PriceAvailability) => {
      for (let key in calendarDates) {
        if (item.date.includes(key)) {
          calendarDates[key].push(item)
          // const isBlocked = item.closedOnCheckin && item.closedOnCheckout
          const isBlocked = !item.bookable && item.closedOnCheckout
          if (isBlocked) {
            disabledDates[key].add(item.date)
          }
        }
      }
    })
    const cloneDisabledMap = new Map(currentDisabledMap)
    for (let key in disabledDates) {
      cloneDisabledMap.set(key, disabledDates[key])
    }
    store.dispatch(setDisabledDatesMap(cloneDisabledMap))

    const cloneCalendarMap = new Map(currentCalendardMap)
    for (let key in calendarDates) {
      cloneCalendarMap.set(key, calendarDates[key])
    }
    store.dispatch(setCalendarDatesMap(cloneCalendarMap))
  } catch (err: any) {
    console.log(err)
  }
}

export const getNearestInValidDate = (checkIn: Moment, calendarDatesMap: any) => {
  const groupCalendar = []
  for (let i = 0; i < 12; i++) {
    const nextMonth = moment(checkIn).add(i, 'month')
    const keyMonth = nextMonth.format('YYYY-MM')
    if (Array.isArray(calendarDatesMap.get(keyMonth))) {
      groupCalendar.push(calendarDatesMap.get(keyMonth))
    }
  }
  const fullCalendar = groupCalendar.flatMap((x) => x)
  const indexCheckIn = fullCalendar.findIndex((item: any) => item.date === checkIn.format(DATE_FORMAT))
  if (indexCheckIn === -1) {
    return null
  }
  const length = fullCalendar.length
  let indexNearestInValid
  for (let i = indexCheckIn; i < length; i++) {
    if (!fullCalendar[i].bookable) {
      if (fullCalendar[i].closedOnCheckout) {
        indexNearestInValid = i
      } else {
        indexNearestInValid = i + 1
      }
      break
    }
  }
  return indexNearestInValid && fullCalendar[indexNearestInValid] ? fullCalendar[indexNearestInValid].date : null
}

export const showDateRange = (checkIn: string | null, checkOut: string | null) => {
  const showMiddle = checkIn && checkOut ? '-' : ' '
  if (moment(checkIn).format('YYYY') !== moment(checkOut).format('YYYY'))
    return `${checkIn ? moment(checkIn).format('MMM DD, YYYY') : ''} ${showMiddle} ${checkOut ? moment(checkOut).format('MMM DD, YYYY') : ''}`
  if (moment(checkIn).format('MMM') !== moment(checkOut).format('MMM'))
    return `${checkIn ? moment(checkIn).format('MMM DD') : ''} ${showMiddle} ${checkOut ? moment(checkOut).format('MMM DD, YYYY') : ''}`
  return `${checkIn ? moment(checkIn).format('MMM DD') : ''} ${showMiddle} ${checkOut ? moment(checkOut).format('DD, YYYY') : ''}`
}

export const renderDurationText = (_hour: number) => {
  if (_hour) {
    if (_hour < 1) {
      return _hour * 60 + ' minutes'
    }
    if (_hour === 1) {
      return '1 hour'
    }
    return _hour + ' hours'
  }
  return ''
}

export const convertFileToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const convertTimeZone = (timezone: string) => {
  const d = DateTime.local().setZone(timezone)
  return d.offsetNameShort
}

export const hasSubdomain = (domain: string) => {
  const res: any = parse(domain);
  const subdomain = res?.subdomain
  return  subdomain!== null;
}
export const isNumber = (str: string) => {
  const numberRegex = /^[0-9]+$/
  return str.match(numberRegex)
}
export const isPostalCode = (str: string) => {
  const postalPattern = /^[0-9A-Za-z\- ]+$/;
  return postalPattern.test(str)
}

export const getCountryCodeFromName = (countryName: string) => {
  if (countryName) {
    const item = countries.find(item => item.name === countryName || item.name.replace(' ', '').toLowerCase() === countryName.toLowerCase())
    return item ? item.iso : ''
  }
  return ''
}

export const getNameByCountryCode = (countryCode: string) => {
  if (countryCode) {
    const item = countries.find(item => item.iso === countryCode)
    return item ? item.name : ''
  }
  return ''
}

export const isValidHttpUrl = (inputStr: string) => {
  let url;
  try {
    url = new URL(inputStr);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export const getSymbolCurrencyByKey = (key?: string) => {
  if (key) {
    const currency = SUPPORTED_CURRENCIES.find(curr => curr.key === key || curr.key.toUpperCase() === key.toUpperCase())
    if (currency) {
      return currency.symbol
    }
    return ''
  }
  return ''
}

export const convertPriceWithCurrency = (price: string | number, currency: string, mustHasCurrency?: boolean) =>{
  const symbol = getSymbolCurrencyByKey(currency)
  if (mustHasCurrency && !symbol) {
    return currency + ' '+ abbreviateNumber(Number(price))
  }
  return symbol + abbreviateNumber(Number(price))
}
export const abbreviateNumber = (number: number) => {
  const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
  // what tier? (determines SI symbol)
  const tier = Math.log10(Math.abs(number)) / 3 | 0;

  // if zero, we don't need a suffix
  if(tier == 0) return number;

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
}

export const convertRateByCurrency = (rateObjBaseOnUsd: any, oldCurrency: string, newCurrency: string) => {
  const rateBaseOnUsd = Number(rateObjBaseOnUsd[oldCurrency]?.rate || 1)
  return rateBaseOnUsd / Number(rateObjBaseOnUsd[newCurrency]?.rate || 1)
}

export const convertPriceByCurrency = (rateObjBaseOnUsd: any, oldCurrency: string, newCurrency: string, price: number) => {
  const rateBaseOnUsd = Number(rateObjBaseOnUsd[oldCurrency]?.rate || 1)
  const newRate =  rateBaseOnUsd / Number(rateObjBaseOnUsd[newCurrency]?.rate || 1)
  return Math.round(Number((Number(price)*newRate)))
}

export const isBSC = (chainId: string | number) => {
  return `${chainId}` === '0x38' || `${chainId}` === '56' || `${chainId}` === '0x61' || `${chainId}` === '97'
}


export const capitalizeString = (str: string) => {
  const arr = str.split(" ");
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(" ")
}

export const handleErrorMessage = (err: any) => {
  console.log(err)
  if (err.response) {
    const error = err.response.data.error
    if (error) {
      if (typeof error === 'object') {
        store.dispatch(setToastError({ message: error?.message }))
      } else {
        store.dispatch(setToastError({ message: err.response.data.message || error}))
      }
    } else {
      store.dispatch(setToastError({ message: err.response.data.message }))
    }
  } else {
    store.dispatch(setToastError({ message: err?.error?.message ||  err?.message || err?.error }))
  }
}
export const getTimeZoneName = () => {
  return DateTime.local()?.zoneName || moment.tz.guess() || Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const removeAtStart = (inputString: string,) => {
  return inputString.replace(/^@+/, '');
}

export const clearAllIntervals = () => {
  for (let i = 1; i < 99999; i++) {
    window.clearInterval(i)
  }
}

export const isHourFormat = (timeStr: string) => {
  const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
  return regex.test(timeStr)
}
 
export const removeDuplicate = (arr: any[], key?: string) => {
  return [...new Map(arr.map((item) => [item[key || 'id'], item])).values()]
}
export const isPolygon = (chainId: string | number) => {
  return `${chainId}` === '0x89' || `${chainId}` === '137' || `${chainId}` === '0x13882' || `${chainId}` === '80002'
}
export const isBase = (chainId: string | number) => {
  return `${chainId}` === '0x14a34' || `${chainId}` === '84532' || `${chainId}` === '0x2105' || `${chainId}` === '8453'
}