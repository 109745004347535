import React, { useState } from 'react'
import { createBusinessUser, editBusinessUser } from 'src/api/user'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import BasicInput from 'src/components/ui/BasicInput'
import BasicSelect from 'src/components/ui/BasicSelect'
import { BUSINESS_USER_ROLE } from 'src/contants/common'
import { useAppDispatch } from 'src/redux/hooks'
import { setToastError, setToastSuccess } from 'src/redux/slices/common'
import { isEmailValid, isEmpty, isOnlyLetters } from 'src/utils/common'

interface Props {
  fetchData: () => void
  user?: any
}

const defaultErrorMsg = { name: '', email: '', role: '' }

const AddEditUser = ({ fetchData, user }: Props) => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [role, setRole] = useState<string>(BUSINESS_USER_ROLE.EDITOR)
  const [errorMsg, setErrorMsg] = useState(defaultErrorMsg)
  const [loading, setLoading] = useState<boolean>(false)
  const isEdit = !isEmpty(user)

  const isEditChanged = () => {
    if (isEdit) {
      return (
        name.trim() !== user?.name?.trim() ||
        email.trim() !== user?.email?.trim() ||
        role.trim() !== user?.role?.trim()
      )
    }
    return false
  }

  const handleOpen = () => {
    setOpen(true)
    if (isEdit) {
      setName(user?.name || '')
      setEmail(user?.email || '')
      setRole(user?.role || '')
    }
  }

  const handleClose = () => {
    setOpen(false)
    setName('')
    setEmail('')
    setRole(BUSINESS_USER_ROLE.EDITOR)
    setErrorMsg(defaultErrorMsg)
  }

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
    setErrorMsg((prevState) => ({ ...prevState, name: '' }))
  }

  const onBlurName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isOnlyLetters(event.target.value)) {
      setErrorMsg((prevState) => ({
        ...prevState,
        name: 'Please enter letters only.',
      }))
    }
  }
  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
    setErrorMsg((prevState) => ({ ...prevState, email: '' }))
  }
  const onBlurEmail = () => {
    if (!isEmpty(email.trim()) && !isEmailValid(email || '')) {
      setErrorMsg((prevState) => ({ ...prevState, email: 'Please enter a valid email.' }))
    }
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const dataDTO = { name, email, role }
      if (isEdit) await editBusinessUser(user?.id, dataDTO)
      else await createBusinessUser(dataDTO)
      fetchData()
      handleClose()
      dispatch(setToastSuccess({ message: isEdit ? `Edit user successfully.` : `Invite user successfully.` }))
    } catch (error: any) {
      dispatch(setToastError({ message: error?.message }))
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {isEdit ?
        <BasicButton
          onClick={handleOpen}
          size="sm"
          variant={'outlined'}
          isRadius100={true}
        >
          Edit
        </BasicButton> :
        <BasicButton
          onClick={handleOpen}
          color={'red'}
          size="lg"
          variant={'contained'}
          isRadius100={true}
        >
          Add a user
        </BasicButton>
      }
      <BasicDialog
        isOpen={open}
        onClose={handleClose}
        title={isEdit ? "Edit a user account" : "Add a user account"}
        extraTitle="Invite additional users to your account and set their role"
        titleAlign={'center'}
      >
        <div className={'flex flex-col gap-[25px]'}>
          <div>
            <BasicInput
              label={'Name'}
              placeholder={'Add a name...'}
              value={name}
              onChange={onChangeName}
              onBlur={onBlurName}
              maxLength={50}
            />
            {errorMsg.name && <span className={'text-red mt-2 ml-2 text-12-12'}>{errorMsg.name}</span>}
          </div>
          <div>
            <BasicInput
              label={'Email'}
              placeholder={'Add a email...'}
              value={email}
              onChange={onChangeEmail}
              onBlur={onBlurEmail}
            />
            {errorMsg.name && <span className={'text-red mt-2 ml-2 text-12-12'}>{errorMsg.email}</span>}
          </div>
          <div>
            <BasicSelect
              label='Role'
              placeholder='Select a role'
              options={[
                { label: 'Editor', value: BUSINESS_USER_ROLE.EDITOR },
              ]}
              value={role}
              onChange={(event) => setRole(event.target.value)}
            />
          </div>
          <BasicButton
            onClick={handleSubmit}
            color={'red'}
            size="lg"
            variant={'contained'}
            clases="w-full"
            isRadius100={true}
            disabled={
              loading ||
              isEmpty(name) ||
              isEmpty(email) ||
              isEmpty(role) ||
              (!isEmpty(name.trim()) && !isEmpty(errorMsg.name)) ||
              (!isEmpty(email.trim()) && !isEmpty(errorMsg.email)) ||
              (isEdit && !isEditChanged())
            }
          >
            {isEdit ? 'Save changes' : "Invite user"}
          </BasicButton>
        </div>
      </BasicDialog>
    </>
  )
}

export default AddEditUser
