import { importHospitableAirbnb } from '../../api/integration'
import { useAppSelector } from '../../redux/hooks'
import { handleErrorMessage } from '../../utils/common'
import LoadingThreeDots from '../common/LoadingThreeDots'
import BasicButton from '../ui/BasicButton'
import BasicDialog from '../ui/BasicDialog'
import RenderChooseListings from './RenderChooseListings'
import queryString from 'query-string'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface Props {
  isOpen: boolean
  onClose: () => void
  afterSubmit: (requestId: string, selectedListings: any[], channelId: string) => void
  loadingImport?: boolean
  updateLoadingImport: (_isLoading: boolean) => void
  prepareListings: any[]
  updatePrepareListings: (_listings: any[]) => void
}

const AirbnbChooseListingHospitable: React.FC<Props> = ({
  isOpen,
  onClose,
  afterSubmit,
  loadingImport,
  updateLoadingImport,
  prepareListings,
  updatePrepareListings,
}) => {
  const navigate = useNavigate()
  const { search, pathname } = useLocation()
  const { airbnbChannelId } = useAppSelector((state) => state.listing)

  const handdleClose = () => {
    // clear data before close popup
    updatePrepareListings([])
    updateLoadingImport(false)
    onClose()
    const params: any = queryString.parse(search)
    let newParams: any = { ...params }
    if (params.airbnbChannelId) {
      delete newParams.airbnbChannelId
      const stringified = queryString.stringify(newParams, { skipEmptyString: true, skipNull: true })
      navigate(pathname + stringified ? '?' + stringified : '')
    }
  }

  const onSubmit = async (channelId: string) => {
    const selectedListings = prepareListings.filter((item) => item.checked)
    const payload = selectedListings.map((item) => {
      return {
        dtravelPropertyId:
          item.dtravelPropertyId !== null && item.dtravelPropertyId !== undefined
            ? Number(item.dtravelPropertyId)
            : null,
        pmsPropertyId: item.pmsPropertyId,
        platformId: item.platformId,
      }
    })
    try {
      updateLoadingImport(true)
      const res = await importHospitableAirbnb({ selectedIds: payload, channelId })
      // old flow
      // dispatch(setImportRequestId(res.data?.requestId))
      // next step
      // const idsImporting = payload.map((item) => item.pmsPropertyId)
      afterSubmit(res.data?.requestId, selectedListings, channelId)
    } catch (err: any) {
      handleErrorMessage(err)
    }
    // finally {
    //   updateLoadingImport(false)
    // }
  }

  const isSubmitDisabled = () => {
    return prepareListings.filter((item: any) => item.checked).length === 0
  }

  const countSelected = prepareListings.filter((item) => item.checked).length

  return (
    <BasicDialog
      isOpen={isOpen}
      onClose={handdleClose}
      title={'Choose listing(s) to add to Dtravel'}
      extraTitle={'Select which listing(s) to import and/or merge with existing ones'}
      maxWidth={'md'}
    >
      <RenderChooseListings
        listings={prepareListings}
        updateListings={updatePrepareListings}
        allowFetchListing={isOpen}
      />

      <div className={'mt-[24px]'}>
        <BasicButton
          variant={'contained'}
          color={'red'}
          size={'xl'}
          isRadius100={true}
          clases={'w-full'}
          disabled={isSubmitDisabled()}
          onClick={() => onSubmit(airbnbChannelId)}
          loading={loadingImport}
        >
          {loadingImport ? (
            <span className={'flex items-center gap-[8px]'}>
              <LoadingThreeDots color={'#ffffff'} />
              <span>{`Import ${countSelected} listing(s)`}</span>
            </span>
          ) : (
            `Import ${countSelected} listing(s)`
          )}
        </BasicButton>
      </div>
    </BasicDialog>
  )
}

export default AirbnbChooseListingHospitable
