import { EXTERNAL_LINK } from '../../../contants/common'
import React from 'react'

const DeleteAccount = () => {
  return (
    <div className={''}>
      <h2 id={'account'} className={'font-maison-neue-medium text-neutral-800 text-18-24'}>
        Delete Account
      </h2>
      <p className={'font-maison-neue text-neutral-5 text-16-20 mt-[4px]'}>
        If you have any questions about your account, please contact us via{' '}
        <a href="mailto:support@dtravel.com" className="underline text-neutral-6">
          email
        </a>
        &nbsp;or on&nbsp;
        <a href={EXTERNAL_LINK.DISCORD} target="_blank" rel="noreferrer" className="underline text-neutral-6">
          Discord
        </a>
        .
      </p>
    </div>
  )
}

export default DeleteAccount
