import { NATIVE_LISTING_MENU } from '../../contants/common'
import { isEmpty } from '../../utils/common'
import { handleShowListingStatusAfterImport } from '../../utils/property'
import BasicButton from '../ui/BasicButton'
import clsx from 'clsx'
import queryString from 'query-string'
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

interface Props {
  isLoading?: boolean
  onSubmit?: () => void
  onBack?: () => void
  isSkip?: boolean
  isDisabledSubmit?: boolean
  isDisabledBack?: boolean
  submitText?: string
  isEdit?: boolean
}

const NativeListingBackAndContinue: React.FC<Props> = ({
  isLoading,
  onSubmit,
  onBack,
  isSkip,
  isDisabledSubmit,
  isDisabledBack,
  submitText,
  isEdit,
}) => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { type } = useParams()
  const parseQuery = queryString.parse(search)
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const ids = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
  const propertyId = parseQuery.id ? String(parseQuery.id) : ''
  const isShowBack = type !== NATIVE_LISTING_MENU.PROPERTY && !isEdit && !isEditBulk

  const handleClickSave = async (element: HTMLElement) => {
    element.click()
    if (!isEditBulk) {
      const listId = ids.join(',')
      await handleShowListingStatusAfterImport(listId || propertyId, true) // fetch status to show on import background
    }
  }

  const handleSubmit = () => {
    if (isEditBulk) {
      const saveExitEl = document.getElementById(`${type}_save`)
      if (saveExitEl) handleClickSave(saveExitEl)
    } else {
      if (typeof onSubmit === 'function') {
        onSubmit()
      }
    }
  }

  const handleBack = () => {
    if (typeof onBack === 'function') {
      onBack()
      return
    }
    // default back: handle back prev step here ...
    switch (type) {
      case NATIVE_LISTING_MENU.LOCATION:
        navigate(`/native-listing/${NATIVE_LISTING_MENU.PROPERTY}` + search)
        break
      case NATIVE_LISTING_MENU.GUEST_ROOMS:
        navigate(`/native-listing/${NATIVE_LISTING_MENU.LOCATION}` + search)
        break
      case NATIVE_LISTING_MENU.PHOTOS:
        navigate(`/native-listing/${NATIVE_LISTING_MENU.GUEST_ROOMS}` + search)
        break
      case NATIVE_LISTING_MENU.AMENITIES:
        navigate(`/native-listing/${NATIVE_LISTING_MENU.PHOTOS}` + search)
        break
      case NATIVE_LISTING_MENU.DESCRIPTION:
        navigate(`/native-listing/${NATIVE_LISTING_MENU.AMENITIES}` + search)
        break
      case NATIVE_LISTING_MENU.HOUSE_RULES:
        navigate(`/native-listing/${NATIVE_LISTING_MENU.DESCRIPTION}` + search)
        break
      case NATIVE_LISTING_MENU.AVAILABILITY:
        navigate(`/native-listing/${NATIVE_LISTING_MENU.HOUSE_RULES}` + search)
        break
      case NATIVE_LISTING_MENU.ICAL:
        navigate(`/native-listing/${NATIVE_LISTING_MENU.AVAILABILITY}` + search)
        break
      case NATIVE_LISTING_MENU.FEES:
        navigate(`/native-listing/${NATIVE_LISTING_MENU.ICAL}` + search)
        break
      case NATIVE_LISTING_MENU.DISCOUNTS:
        navigate(`/native-listing/${NATIVE_LISTING_MENU.FEES}` + search)
        break
      case NATIVE_LISTING_MENU.RATES:
        navigate(`/native-listing/${NATIVE_LISTING_MENU.DISCOUNTS}` + search)
        break
      case NATIVE_LISTING_MENU.PUBLISH:
        navigate(`/native-listing/${NATIVE_LISTING_MENU.RATES}` + search)
        break
    }
  }

  // if (isEdit || isEditBulk) return null
  return (
    <div
      className="fixed h-[92px] w-full bottom-0 right-0 flex items-center justify-between bg-neutral-100 z-[110]"
      style={{ background: 'linear-gradient(180deg, rgba(10, 10, 9, 0) 0%, rgba(10, 10, 9, 0.16) 100%)' }}
    >
      <div
        className={clsx(
          'w-full',
          'm-auto',
          'px-[16px]',
          'md:px-[32px]',
          'lg:px-[48px]',
          'xl:px-0',
          'xl:max-w-[1136px]',
          '2xl:max-w-[1284px]',
          'flex gap-[12px]'
        )}
      >
        <div className={'hidden lg:block basis-1/4 min-w-[296px]'} />
        <div
          className={clsx(
            `w-full lg:basis-1/2 flex items-center`,
            isShowBack || isEdit || isEditBulk ? 'justify-between' : 'justify-center'
          )}
        >
          {isShowBack ? (
            <BasicButton
              variant="contained"
              size={'md'}
              color={'white'}
              clases={clsx(
                `w-auto h-[44px] flex justify-center`,
                isLoading || isDisabledBack ? 'cursor-not-allowed opacity-60' : 'cursor-pointer opacity-100'
              )}
              // loading={isLoading}
              disabled={isDisabledBack}
              isRadius100={true}
              onClick={() => {
                if (isEdit) navigate('/listings')
                else handleBack()
              }}
            >
              <span className="text-16-20 font-maison-neue-medium">{isEdit ? 'Exit' : 'Back'}</span>
            </BasicButton>
          ) : (
            <div />
          )}
          <BasicButton
            variant="contained"
            size={'md'}
            color={'red'}
            clases={clsx(
              `w-auto h-[44px] flex justify-center`,
              isLoading || isDisabledSubmit ? 'cursor-not-allowed opacity-60' : 'cursor-pointer opacity-100'
            )}
            onClick={handleSubmit}
            loading={isLoading}
            disabled={isDisabledSubmit}
            isRadius100={true}
          >
            <span className="text-16-20 font-maison-neue-medium">
              {(isEdit || isEditBulk) ? 'Save' : submitText || (isSkip ? 'Skip' : 'Continue')}
            </span>
          </BasicButton>
        </div>
      </div>
    </div>
  )
}

export default NativeListingBackAndContinue
