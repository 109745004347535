import useWindowDimensions from '../../hooks/useWindowDimensions'
import BasicSwipeDrawer from '../ui/BasicSwipeDrawer'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import moment, { Moment } from 'moment/moment'
import React, { useState } from 'react'
import { DayPickerSingleDateController, isInclusivelyAfterDay, ModifiersShape } from 'react-dates'
import { useDispatch } from 'react-redux'
import ic_arrow_back from 'src/assets/icons/ic_arrow_back.svg'
import ic_arrow_forward from 'src/assets/icons/ic_arrow_forward.svg'
import ic_close from 'src/assets/icons/ic_close.svg'
import { useAppSelector } from 'src/redux/hooks'
import { setDateTime } from 'src/redux/slices/common'

interface Props {
  anchorEl: HTMLElement | null
  onClose: () => void
  maxDate?: moment.Moment | null
}

const SelectDateTime: React.FC<Props> = ({ anchorEl, onClose, maxDate }) => {
  const dispatch = useDispatch()
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768
  const { dateTime } = useAppSelector((state) => state.common)
  const [hour, setHour] = useState<number>(0)
  const [isAm, setIsAm] = useState<boolean>(true)
  const [date, setDate] = useState<Moment | null>(null)

  const onDateChange = (date: moment.Moment | null) => {
    setDate(date)
  }

  const handleSelectTime = (_hour: number) => {
    setHour(_hour)
  }

  const toggleAmPm = (_isAm: boolean) => {
    setIsAm(_isAm)
  }

  const onFocusChange = () => {}

  const handleClose = () => {
    // reset time
    const _hour = dateTime ? dateTime.get('hour') : 0
    setHour(_hour)
    setIsAm(_hour <= 12)
    setDate(dateTime)
    onClose()
  }

  const handleClear = () => {
    setHour(0)
    setIsAm(true)
    setDate(null)
    dispatch(setDateTime(null))
  }

  const handleApply = () => {
    const _dateTime: Moment = moment(date)
    let newHour = isAm ? hour : hour + 12
    if (hour === 12) {
      newHour = isAm ? 0 : 12
    }
    _dateTime.set('hour', newHour)
    dispatch(setDateTime(_dateTime))
    onClose()
  }

  const isOutsideRange = (day: Moment) => {
    const isAfterMaxDate = maxDate ? day.isAfter(moment(maxDate)) : true
    return isAfterMaxDate || !isInclusivelyAfterDay(day, moment())
  }

  const renderDayContent = (day: moment.Moment, modifiers: ModifiersShape) => {
    const renderDayClass = () => {
      if (
        (modifiers.has('blocked-out-of-range') || modifiers.has('blocked-calendar')) &&
        !modifiers.has('selected-start') &&
        !modifiers.has('selected-end')
      ) {
        return 'line-through'
      }
      if (modifiers.has('selected')) {
        return 'rounded-full w-full h-full flex items-center justify-center bg-sand-8 text-white'
      }
      if (modifiers.has('hovered')) {
        return 'rounded-full w-full h-full flex items-center justify-center bg-white border-2 border-solid border-sand-8 text-neutral-700'
      }
      return 'text-neutral-700'
    }
    return (
      <div className={'w-full h-full flex items-center justify-center font-inter-500 ' + ' ' + renderDayClass()}>
        {day.format('D')}
      </div>
    )
  }

  const renderContent = () => {
    return (
      <>
        <div
          className={
            'hidden md:flex items-center justify-between h-[68px] px-[24px] font-maison-neue-medium text-16-16 text-neutral-900'
          }
        >
          <span>Select date & time</span>
          <IconButton onClick={handleClose}>
            <img src={ic_close} alt={'ic_close'} />
          </IconButton>
        </div>

        <div className={'flex flex-col md:flex-row items-center h-full bg-neutral-100 px-[24px]'}>
          <div className={'customDateRange'}>
            <DayPickerSingleDateController
              navPosition={'navPositionTop'}
              onFocusChange={onFocusChange}
              hideKeyboardShortcutsPanel={true}
              numberOfMonths={1}
              isOutsideRange={isOutsideRange}
              noBorder={true}
              daySize={45}
              withPortal={false}
              initialVisibleMonth={null}
              date={date}
              onDateChange={onDateChange}
              focused={true}
              renderDayContents={renderDayContent}
              navPrev={
                <IconButton classes={{ root: 'absolute top-[22px] left-[20px]' }}>
                  <img src={ic_arrow_back} alt={'ic_arrow_back'} />
                </IconButton>
              }
              navNext={
                <IconButton classes={{ root: 'absolute top-[22px] right-[20px]' }}>
                  <img src={ic_arrow_forward} alt={'ic_arrow_forward'} />
                </IconButton>
              }
            />
          </div>
          <div
            className={
              'h-[1px] md:h-[240px] w-full md:w-[1px] bg-neutral-300 ml-0 md:ml-[16px] mr-0 md:mr-[45px] mb-[44px] md:mb-0'
            }
          />
          <div
            className={
              'h-[240px] flex flex-col md:flex-row items-center gap-[24px] font-maison-neue-medium text-14-18 text-neutral-500'
            }
          >
            <div className={'grid grid-cols-4 md:grid-cols-2 gap-x-[24px] gap-y-[24px]'}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((_hour) => {
                return (
                  <button
                    key={_hour}
                    className={
                      'active:bg-neutral-300 px-[4px] py-[4px] rounded-[8px] ' +
                      `${hour === _hour ? 'text-neutral-900 bg-neutral-300' : 'bg-none'}`
                    }
                    onClick={() => handleSelectTime(_hour)}
                  >
                    {_hour}:00
                  </button>
                )
              })}
            </div>
            <div className={'flex flex-row md:flex-col gap-[24px]'}>
              <button
                className={
                  'active:bg-neutral-300 px-[8px] py-[4px] rounded-[8px] ' +
                  `${isAm ? 'text-neutral-900 bg-neutral-300' : ''}`
                }
                onClick={() => toggleAmPm(true)}
              >
                AM
              </button>
              <button
                className={
                  'active:bg-neutral-300 px-[8px] py-[4px] rounded-[8px] ' +
                  `${isAm ? '' : 'text-neutral-900 bg-neutral-300'}`
                }
                onClick={() => toggleAmPm(false)}
              >
                PM
              </button>
            </div>
          </div>
        </div>

        <div className={'flex items-center justify-between h-[80px] md:h-[76px] px-[24px] gap-[12px]'}>
          <button
            className={
              'h-[56px] md:h-[40px] flex items-center justify-center font-maison-neue-medium text-neutral-900 ' +
              'border border-neutral-300 md:border-none ' +
              'bg-white  bg-none ' +
              'rounded-[16px] md:rounded-[12px] ' +
              'px-[32px] md:px-[16px] ' +
              'text-16-16 md:text-14-14 ' +
              'active:underline ' +
              'disabled:opacity-50 disabled:cursor-not-allowed'
            }
            onClick={handleClear}
          >
            Clear
          </button>
          <button
            className={
              'h-[56px] md:h-[40px] flex items-center justify-center font-maison-neue-medium text-white ' +
              'bg-neutral-900 ' +
              'text-16-16 md:text-14-14 ' +
              'rounded-[16px] md:rounded-[12px] ' +
              'px-[16px] ' +
              'w-full md:w-auto ' +
              'disabled:opacity-50 disabled:cursor-not-allowed'
            }
            disabled={!date}
            onClick={handleApply}
          >
            Apply
          </button>
        </div>
      </>
    )
  }

  return (
    <>
      {isMobile ? (
        <BasicSwipeDrawer
          isOpen={Boolean(anchorEl)}
          onClose={handleClose}
          title={'Select dates'}
          bodyStyle={{ padding: 0 }}
        >
          {renderContent()}
        </BasicSwipeDrawer>
      ) : (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: -8,
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPaper-root': {
              width: '638px',
              borderRadius: '24px',
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 16px 48px rgba(0, 0, 0, 0.16)',
            },
          }}
        >
          {renderContent()}
        </Popover>
      )}
    </>
  )
}

export default SelectDateTime
