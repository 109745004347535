import { disconnectStripe } from '../../../api/integration'
import { getListingByStripeId } from '../../../api/listings'
import ic_check from '../../../assets/icons/ic_check_black_20_20.svg'
import ic_chevron_down from '../../../assets/icons/ic_chevron_down_20_20.svg'
import ic_chevron_up from '../../../assets/icons/ic_chevron_up_20_20.svg'
import { useAppDispatch } from '../../../redux/hooks'
import { setToastSuccess } from '../../../redux/slices/common'
import { handleErrorMessage } from '../../../utils/common'
import BasicButton from '../../ui/BasicButton'
import BasicDialog from '../../ui/BasicDialog'
import Popover from '@mui/material/Popover'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import ic_arrow_down_sm from 'src/assets/icons/ic_arrow_down_sm.svg'

interface Props {
  data: any
  stripeAccounts: any[]
  fetchStripe?: () => void
}

// const NONE_ACCOUNT = {
//   id: '',
//   displayName: 'None',
//   email: 'These listings will not be bookable with fiat payments',
// }

const DisconnectStripeAccount: React.FC<Props> = ({ data, stripeAccounts, fetchStripe }) => {
  const dispatch = useAppDispatch()
  const btnRef = useRef<HTMLButtonElement | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [accounts, setAccounts] = useState<any[]>([])
  const [associatedAccount, setAssociatedAccount] = useState<any>(null)
  const [totalListingsTransfer, setTotalListingsTransfer] = useState<number>(0)
  const open = Boolean(anchorEl)

  useEffect(() => {
    async function fetchListingByStripeId(_stripeId: string) {
      const res = await getListingByStripeId({ stripeId: _stripeId })
      if (res.success && res.paging) {
        setTotalListingsTransfer(res.paging.total)
      }
    }
    if (isOpen && data && data.id) {
      fetchListingByStripeId(data.id)
    }
  }, [data, isOpen])

  useEffect(() => {
    if (Array.isArray(stripeAccounts) && data) {
      const filteredAccounts = stripeAccounts.filter((item) => item.id !== data.id)
      setAccounts([...filteredAccounts])
      // setAccounts([...filteredAccounts, NONE_ACCOUNT])
    }
  }, [stripeAccounts, data])

  const handleOpenPopup = () => {
    // find default account
    const defaultAccount = stripeAccounts.find((item) => item.isPrimary && item.id !== data.id)
    if (defaultAccount) {
      setAssociatedAccount(defaultAccount)
    } else {
      const filteredAccounts = stripeAccounts.filter((item) => item.id !== data.id)
      setAssociatedAccount(filteredAccounts.length > 0 ? filteredAccounts[0] : null)
    }
    setIsOpen(true)
  }

  const handleClosePopup = () => {
    setIsOpen(false)
    setAssociatedAccount(null)
  }

  const onSelectAssociatedAccount = (stripeAccount: any) => {
    setAssociatedAccount(stripeAccount)
    setAnchorEl(null)
  }

  const handleConfirm = async () => {
    try {
      // if (!associatedAccount || !associatedAccount.id) {
      //   handleErrorMessage({
      //     message: 'Please select one associated account',
      //   })
      // }
      setLoading(true)
      const res = await disconnectStripe({
        stripeId: data.id,
        stripeIdChange: associatedAccount ? associatedAccount.id : null,
      })
      if (res.success) {
        if (typeof fetchStripe === 'function') {
          await fetchStripe()
        }
        dispatch(
          setToastSuccess({
            message: 'Successfully',
          })
        )
        handleClosePopup()
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <BasicButton variant={'outlined'} size={'md'} isRadius100={true} onClick={handleOpenPopup}>
        Disconnect
      </BasicButton>

      <BasicDialog
        isOpen={isOpen}
        onClose={handleClosePopup}
        title={'Confirm Stripe disconnect?'}
        extraTitle={'By disconnecting, all listings will be associated with the remaining account'}
      >
        {Array.isArray(stripeAccounts) && stripeAccounts.length > 1 && (
          <>
            <div
              className={
                'mt-[-16px] px-[16px] py-[24px] flex items-center justify-between bg-red-100 rounded-[16px] border border-red-700 w-full'
              }
            >
              <span className={'max-w-[calc(100%-40px)] truncate'}>
                <span className={'font-maison-neue text-16-20 text-red-700'}>
                  {data.displayName} {data.displayName ? ':' : ''}&nbsp;
                </span>
                <span className={'font-maison-neue text-16-20 text-neutral-500'}>{data.email}</span>
              </span>
              <span className={'font-maison-neue text-14-18 text-neutral-500 ml-auto whitespace-nowrap text-right'}>
                {totalListingsTransfer} {totalListingsTransfer > 1 ? 'listings' : 'listing'}
              </span>
            </div>

            <div className={'mt-[24px] flex items-center justify-center'}>
              <img src={ic_arrow_down_sm} alt={'ic_arrow_down_sm'} />
            </div>

            <div className={'mt-[24px]'}>
              <button
                ref={btnRef}
                className={
                  'px-[16px] py-[24px] flex items-center justify-between rounded-[16px] border border-grayscale-300 w-full text-16-20 text-left'
                }
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <span
                  className={`flex items-center ${associatedAccount?.isPrimary ? 'max-w-[calc(100%-112px)]' : 'max-w-[calc(100%-32px)]'
                    } `}
                >
                  <span className={'font-maison-neue text-16-20 text-neutral-800'}>
                    {associatedAccount?.displayName} {associatedAccount?.displayName ? ':' : ''}&nbsp;
                  </span>
                  <span className={'font-maison-neue text-16-20 text-neutral-500 truncate'}>
                    {associatedAccount?.email}
                  </span>
                </span>
                <span className={'flex items-center gap-[16px]'}>
                  {associatedAccount?.isPrimary && (
                    <span
                      className={
                        'font-maison-neue-medium text-12-16 text-neutral-6 tracking-[0.48px] uppercase px-[8px] py-[1px] bg-neutral-2 rounded-[100px]'
                      }
                    >
                      Default
                    </span>
                  )}
                  <span className={'min-w-[20px]'}>
                    <img src={open ? ic_chevron_up : ic_chevron_down} alt={'ic_chevron'} />
                  </span>
                </span>
              </button>
              <Popover
                id={'simple-popover'}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: -4,
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: {
                    boxShadow: '0px 20px 50px -12px rgba(0, 0, 0, 0.10), 0px 10px 25px -5px rgba(0, 0, 0, 0.06);',
                    borderRadius: '16px',
                    border: '1px solid #F7F7F4',
                    width: btnRef && btnRef.current ? btnRef.current.offsetWidth + 'px' : '576px',
                  },
                }}
              >
                <div className={'flex flex-col'}>
                  {accounts.map((item) => {
                    return (
                      <button
                        key={item.id}
                        className={clsx(
                          'px-[16px] py-[24px] flex items-center justify-between w-full text-16-20 text-left',
                          'hover:bg-neutral-100 hover:my-[1px]',
                          {
                            'bg-neutral-100': associatedAccount?.id === item.id,
                          }
                        )}
                        onClick={() => onSelectAssociatedAccount(item)}
                      >
                        <span>
                          <span className={'font-maison-neue text-16-20 text-neutral-800'}>
                            {item.displayName}
                            {item.displayName ? ':' : ''}&nbsp;
                          </span>
                          <span className={'font-maison-neue text-16-20 text-neutral-500'}>{item.email}</span>
                        </span>
                        <span className={'flex items-center gap-[16px]'}>
                          {item.isPrimary && (
                            <span
                              className={
                                'font-maison-neue-medium text-12-16 text-neutral-6 tracking-[0.48px] uppercase px-[8px] py-[1px] bg-neutral-2 rounded-[100px]'
                              }
                            >
                              Default
                            </span>
                          )}
                          {associatedAccount?.id === item.id && (
                            <span className={'min-w-[20px]'}>
                              <img src={ic_check} alt={'ic_check'} />
                            </span>
                          )}
                        </span>
                      </button>
                    )
                  })}
                </div>
              </Popover>
            </div>
          </>
        )}

        <div className={`${stripeAccounts.length === 1 ? 'mt-[-16px]' : 'mt-[24px]'} flex gap-[16px]`}>
          <BasicButton variant={'outlined'} size={'lg'} isRadius100={true} clases={'w-1/2'} onClick={handleClosePopup}>
            Cancel
          </BasicButton>
          <BasicButton
            variant={'contained'}
            size={'lg'}
            isRadius100={true}
            clases={'w-1/2'}
            color={'red'}
            onClick={handleConfirm}
            loading={loading}
          >
            Confirm
          </BasicButton>
        </div>
      </BasicDialog>
    </div>
  )
}

export default DisconnectStripeAccount
