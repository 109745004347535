import ic_check_only_black from '../../assets/icons/ic_check_only_black.svg'
import { useAppSelector } from '../../redux/hooks'
import { setDurationHour } from '../../redux/slices/common'
import { renderDurationText } from '../../utils/common'
import Popover from '@mui/material/Popover'
import React from 'react'
import { useDispatch } from 'react-redux'

interface Props {
  anchorEl: HTMLElement | null
  onClose: () => void
}

const DURATIONS = [3, 12, 24]

const SelectDuration: React.FC<Props> = ({ anchorEl, onClose }) => {
  const dispatch = useDispatch()
  const { durationHour } = useAppSelector((state) => state.common)

  const onSelectDuration = (_hour: number) => {
    dispatch(setDurationHour(_hour))
    onClose()
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: -8,
        horizontal: 'right',
      }}
      sx={{
        '& .MuiPaper-root': {
          width: '564px',
          borderRadius: '24px',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 16px 48px rgba(0, 0, 0, 0.16)',
        },
      }}
    >
      <div className={'font-maison-neue text-16-20 text-neutral-900 px-[24px]'}>
        {DURATIONS.map((hour, index) => {
          const isLastItem = index === DURATIONS.length - 1
          return (
            <button
              key={hour}
              className={`w-full flex justify-between items-center py-[22px] ${
                isLastItem ? '' : 'border-b border-[#E5E5E0]'
              }`}
              onClick={() => onSelectDuration(hour)}
            >
              <span>{renderDurationText(hour)}</span>
              <span>{durationHour === hour && <img src={ic_check_only_black} alt={'ic_check_only_black'} />}</span>
            </button>
          )
        })}
      </div>
    </Popover>
  )
}

export default SelectDuration
