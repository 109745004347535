import { handleErrorMessage } from '../../utils/common'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { superHogDisableListing } from 'src/api/listings'
import BasicCardV2 from 'src/components/common/BasicCardV2'
import PopupInfoSupertHog from 'src/components/property/PopupInfoSupertHog'
import BasicButton from 'src/components/ui/BasicButton'
import BasicCheckbox from 'src/components/ui/BasicCheckbox'
import BasicDialog from 'src/components/ui/BasicDialog'
import BasicRadio from 'src/components/ui/BasicRadio'
import { SUPERHOG_STATUS } from 'src/contants/property'
import { SUPER_HOG_LIST_TYPE } from 'src/contants/property'
import { Address } from 'src/interfaces/listing'
import { useAppDispatch } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'

interface Props {
  propertyId: string
  externalName: string
  address: Address
  superhogStatus?: string
  isDisabledGetStarted?: boolean
}

const DetailRiskManagement: React.FC<Props> = ({
  propertyId,
  externalName,
  address,
  superhogStatus,
  isDisabledGetStarted,
}) => {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOpenPopupInfor, setIsOpenPopupInfor] = useState<boolean>(false)
  const [isOpenPopupDisable, setIsOpenPopupDisable] = useState<boolean>(false)
  const [isAgree, setIsAgree] = useState<boolean>(true)
  const [kycType, setKycType] = useState<string>('kyg')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [status, setStatus] = useState<string>('')

  useEffect(() => {
    if (superhogStatus) setStatus(superhogStatus)
  }, [superhogStatus])

  const handleOpenPopup = () => {
    setIsOpen(true)
  }

  const handleClosePopup = () => {
    setIsOpen(false)
    // clear data
    setIsAgree(true)
  }

  const handleOpenPopupInfor = () => {
    setIsOpenPopupInfor(true)
  }

  const handleClosePopupInfor = () => {
    setIsOpenPopupInfor(false)
  }

  const handleOpenPopupDisable = () => {
    setIsOpenPopupDisable(true)
  }

  const handleClosePopupDisable = () => {
    setIsOpenPopupDisable(false)
  }

  const handleKycTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setKycType(value)
  }

  const onConfirmSelectType = () => {
    handleClosePopup()
    handleOpenPopupInfor()
  }

  const onChangeStatus = (_status: string) => {
    setStatus(_status)
  }

  const handleDisableSuperHog = async () => {
    setIsLoading(true)
    try {
      setIsLoading(true)
      await superHogDisableListing(propertyId)
      onChangeStatus('') // reset superHogStatus
      dispatch(setToastSuccess({ message: 'Success!' }))
      handleClosePopupDisable()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const isKygSuperHog = status === SUPERHOG_STATUS.KYG || status === SUPERHOG_STATUS.KYG_DAMAGE

  return (
    <BasicCardV2
      title={'Risk Management'}
      // extraTitle={<a className={'font-maison-neue-medium text-14-18 text-neutral-600'}>Learn more</a>}
    >
      <div className={'flex flex-col md:flex-row justify-between items-center gap-[16px] md:gap-[24px]'}>
        <div className={'text-neutral-700'}>
          Choose your level of protection from our partner SUPERHOG to mitigate the risk of fraudulent bookings and
          property damage up to $5M USD.
        </div>
        {isKygSuperHog ? (
          <BasicButton
            variant={'outlined'}
            color={'black'}
            size={'md'}
            isRadius100={true}
            clases={'w-full md:w-auto'}
            onClick={handleOpenPopupDisable}
          >
            Disable
          </BasicButton>
        ) : (
          <BasicButton
            variant={'contained'}
            color={'black'}
            size={'md'}
            isRadius100={true}
            clases={'w-full md:w-auto'}
            onClick={handleOpenPopup}
            disabled={isDisabledGetStarted}
          >
            Get started
          </BasicButton>
        )}
      </div>
      {isKygSuperHog && (
        <>
          <div className={'bg-neutral-50 p-[24px] mt-[16px]'}>
            <p className={'font-maison-neue-medium text-14-18 text-neutral-900'}>
              {SUPER_HOG_LIST_TYPE[status === SUPERHOG_STATUS.KYG ? 0 : 1]?.title}
            </p>
            <p className={'font-maison-neue text-14-18 text-neutral-600 mt-[8px]'}>
              {SUPER_HOG_LIST_TYPE[status === SUPERHOG_STATUS.KYG ? 0 : 1]?.description}
            </p>
          </div>
          <p className={'font-maison-neue text-16-24 text-neutral-700 mt-[16px]'}>
            To file a resolution for a completed stay,{' '}
            <Link to={'/reservations'} className={'text-neutral-900 underline'}>
              visit the reservation details page
            </Link>
            .
          </p>
        </>
      )}

      {/* ----Start Popup Select Type---- */}
      <BasicDialog
        isOpen={isOpen}
        onClose={handleClosePopup}
        title={'Risk management'}
        extraTitle={'Please choose your level of protection below'}
      >
        <div className={'flex flex-col gap-[8px]'}>
          {SUPER_HOG_LIST_TYPE.map((item) => {
            return (
              <label
                key={item.type}
                htmlFor={item.type}
                className={clsx(
                  'flex justify-between items-center gap-[16px] rounded-[16px] cursor-pointer p-[24px] ',
                  {
                    ['border border-neutral-300']: item.type !== kycType,
                    ['border border-neutral-900']: item.type === kycType,
                  }
                )}
              >
                <div>
                  <p className={'font-maison-neue-medium text-14-18 text-neutral-900'}>{item.title}</p>
                  <p className={'font-maison-neue text-14-18 text-neutral-600 mt-[8px]'}>{item.description}</p>
                </div>
                <BasicRadio
                  type={'radio'}
                  id={item.type}
                  value={item.type}
                  name={'superhog_kyc_type'}
                  checked={item.type === kycType}
                  onChange={handleKycTypeChange}
                />
              </label>
            )
          })}
        </div>
        <div className={'mt-[24px]'}>
          <label htmlFor={'agree_terms_and_condition'} className={'flex items-center gap-[24px]'}>
            <BasicCheckbox
              id={'agree_terms_and_condition'}
              color={'black'}
              checked={isAgree}
              onChange={(event) => setIsAgree(event.target.checked)}
            />
            <span className={'font-maison-neue-medium text-14-18 text-neutral-900 mt-[4px]'}>
              I have read and agreed to{' '}
              <a
                href={'https://www.dtravel.com/terms-of-service/superhog'}
                target={'_blank'}
                rel={'noreferrer'}
                className={'text-red-700'}
              >
                Terms & Conditions
              </a>
              .
            </span>
          </label>
        </div>
        <div className={'mt-[24px]'}>
          <BasicButton
            variant={'contained'}
            color={'red'}
            isRadius100={true}
            clases={'w-full'}
            disabled={!kycType || !isAgree}
            onClick={onConfirmSelectType}
          >
            Confirm
          </BasicButton>
        </div>
      </BasicDialog>
      {/* ----End Popup Select Type---- */}

      {/* ----Start Popup Confirm Information---- */}
      <PopupInfoSupertHog
        propertyId={propertyId}
        isOpen={isOpenPopupInfor}
        onClose={handleClosePopupInfor}
        externalName={externalName}
        address={address}
        superHogStatus={kycType}
        onChangeStatus={onChangeStatus}
      />
      {/* ----End Popup Confirm Information---- */}

      {/* ----Start Popup Confirm Ddissabled SuperHog---- */}
      <BasicDialog
        isOpen={isOpenPopupDisable}
        onClose={handleClosePopupDisable}
        title={`Are you sure to disable ${SUPER_HOG_LIST_TYPE[status === SUPERHOG_STATUS.KYG ? 0 : 1]?.title}?`}
        extraTitle={'Your listing will no longer be protected with SUPERHOG risk management.'}
      >
        <div className={'flex flex-col gap-[8px]'}>
          <BasicButton
            variant={'contained'}
            isRadius100={true}
            color={'red'}
            onClick={handleDisableSuperHog}
            loading={isLoading}
          >
            Confirm
          </BasicButton>
        </div>
      </BasicDialog>
    </BasicCardV2>
  )
}

export default DetailRiskManagement
