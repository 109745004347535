import { getUserProfile } from '../../../api/user'
import { handleErrorMessage } from '../../../utils/common'
import BasicButton from '../../ui/BasicButton'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import BasicDialog from 'src/components/ui/BasicDialog'
import BasicInput from 'src/components/ui/BasicInput'
import { setLoading, setToastSuccess } from 'src/redux/slices/common'
import { getUserInfo } from 'src/utils/user'

interface Props {
  propertyId: string
}

const ExportICalCalendar: React.FC<Props> = ({ propertyId }) => {
  const dispatch = useDispatch()
  const [url, setUrl] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        dispatch(setLoading(true))
        const res = await getUserProfile()
        if (res.data) {
          const icalKey = res.data.user?.icalKey
          setUrl(
            process.env.REACT_APP_API_SERVICE_URL + `/listing-service/v1/native-property/${icalKey}/${propertyId}/ical`
          )
        }
      } catch (err) {
        handleErrorMessage(err)
      } finally {
        dispatch(setLoading(false))
      }
    }
    if (propertyId) {
      fetchUserProfile()
    }
  }, [propertyId])

  useEffect(() => {
    const user = getUserInfo()
    const icalKey = user?.user?.icalKey || ''
    setUrl(process.env.REACT_APP_API_SERVICE_URL + `/listing-service/v1/native-property/${icalKey}/${propertyId}/ical`)
  }, [propertyId])

  const handleOpenPopup = () => {
    setIsOpen(true)
  }

  const handleClosePopup = () => {
    setIsOpen(false)
  }

  const onCopy = () => {
    navigator.clipboard.writeText(url)
    dispatch(setToastSuccess({ message: `Copied!`, duration: 2000 }))
  }

  return (
    <>
      <BasicButton
        variant="outlined"
        size={'md'}
        color={'black'}
        clases={'w-full md:w-auto'}
        onClick={handleOpenPopup}
        disabled={false}
        isRadius100={true}
      >
        <span className="text-14-18 font-maison-neue-medium text-neutral-900">Export calendar</span>
      </BasicButton>
      <BasicDialog
        isOpen={isOpen}
        onClose={handleClosePopup}
        title={'Export your availabilities as a calendar'}
        extraTitle={
          <p className={'font-maison-neue text-14-20 text-neutral-600 text-center mt-[8px]'}>
            Use this calendar to export your listings availability to other booking services.
          </p>
        }
        maxWidth={'md'}
      >
        <div className={''}>
          <BasicInput
            label={'iCal address (URL)'}
            value={url}
            showIconCopy={!!url}
            onCopy={onCopy}
            style={{
              color: '#8C8C88',
              fontSize: '16px',
              lineHeight: '20px',
              fontFamily: 'Maison Neue Regular',
            }}
          />
        </div>
      </BasicDialog>
    </>
  )
}

export default ExportICalCalendar
