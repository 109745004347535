import Api from './index'

//https://api.dataismist.com/chatbot-service/v1/user/setting
export const getChatBotSetting = async () => {
  return Api.get('/chatbot-service/v1/user/setting').then((res) => res.data)
}

export const updateChatBotSetting = async (isEnabled: boolean) => {
  return Api.post('/chatbot-service/v1/user/setting', { isEnabled }).then((res) => res.data)
}
