import { IconClose } from '../common/Icons'
import { Drawer } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import React, { ReactElement, ReactNode } from 'react'
import clsx from 'clsx'

export interface BasicSwipeDrawerProps {
  isOpen: boolean
  onClose: () => void
  title?: string | ReactNode | ReactElement
  extraTitle?: string | ReactNode | ReactElement
  children?: any
  hideClose?: boolean
  titleAlign?: 'left' | 'right' | 'center'
  height?: number | string
  maxHeight?: number | string
  noScroll?: boolean
  padding?: string
  bodyStyle?: any
  classContainer?: string
  anchor?: 'left' | 'top' | 'right' | 'bottom'
  hiddenTitleDivider?: boolean
}

const BasicSwipeDrawer: React.FC<BasicSwipeDrawerProps> = ({
  isOpen,
  onClose,
  children,
  title,
  extraTitle,
  titleAlign,
  height,
  maxHeight,
  hideClose,
  noScroll,
  bodyStyle,
  classContainer,
  hiddenTitleDivider,
  anchor = 'bottom',
}) => {
  return (
    <Drawer
      anchor={anchor}
      open={isOpen}
      onClose={onClose}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        '& .MuiDrawer-paper': {
          borderRadius: '16px 16px 0px 0px',
          height: height || 'auto',
          maxHeight: maxHeight || 'calc(100% - 16px)',
        },
      }}
      className={`custom-drawer ${classContainer || ''}`}
    >
      {title && (
        <div
          className={clsx(
            "flex items-start gap-[8px] pt-6 px-[16px]",
            hiddenTitleDivider ? 'pb-0' : 'pb-6'
          )}
          style={{ boxShadow: hiddenTitleDivider ? 'none' : '0px 1px 0px #E9E9E4' }}
        >
          <div className="flex justify-center items-center w-full" style={{ textAlign: titleAlign || 'center' }}>
            {typeof title === 'string' ? (
              <h3 className={'font-maison-neue text-24-36 text-neutral-800'}>{title}</h3>
            ) : (
              <>{title}</>
            )}
          </div>
          {!hideClose && (
            <div className={'ml-auto'}>
              <IconButton onClick={onClose} sx={{ padding: 0, minWidth: 20 }}>
                <IconClose />
              </IconButton>
            </div>
          )}
        </div>
      )}

      <div
        className={`px-[16px] py-[16px] ${noScroll ? 'overflow-y-hidden' : 'overflow-y-auto'} h-full overflow-x-hidden`}
        style={bodyStyle ? { ...bodyStyle } : {}}
      >
        {typeof extraTitle === 'string' ? (
          <p className={'font-maison-neue text-14-20 text-neutral-600 mb-[24px]'}>{extraTitle}</p>
        ) : (
          extraTitle
        )}
        {children}
      </div>
    </Drawer>
  )
}

export default BasicSwipeDrawer
