import React from 'react'
import queryString from 'query-string'
import DisabledSection from './DisabledSection'
import BasicSwitch from '../ui/BasicSwitch'
import { useLocation } from 'react-router-dom'
import { isEmpty } from 'src/utils/common'

interface Props {
  children: React.ReactNode
  isDisable?: boolean // disable content
  disabledEdit?: boolean
  title?: string
  subTitle?: string
  handleChangeEdit?: any
  openEdit?: boolean
}

const DisabledSectionOnEdit: React.FC<Props> = ({ children, isDisable, title, subTitle, openEdit, handleChangeEdit, disabledEdit }) => {
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const isDisabled = isEditBulk ? !openEdit : isDisable

  return (
    <div>
      <div className='flex justify-between gap-4 mb-4'>
        <div className='flex flex-col gap-1'>
          {title &&
            <p className={'font-maison-neue text-20-24 text-neutral-800'}>{title}</p>
          }
          {subTitle &&
            <p className="text-neutral-600 text-14-20 font-maison-neue">{subTitle}</p>
          }
        </div>
        {isEditBulk &&
          <div className="flex gap-2">
            <span className='text-14-18 text-neutral-600 font-maison-neue whitespace-nowrap'>
              Toggle to edit
            </span>
            <BasicSwitch
              checked={!!openEdit}
              disabled={disabledEdit}
              showCloseIcon={!openEdit}
              onChange={(event) => {
                if (handleChangeEdit) handleChangeEdit(event.target.checked)
              }}
            />
          </div>
        }
      </div>
      <DisabledSection isDisable={isDisabled || disabledEdit}>{children}</DisabledSection>
    </div>

  )
}

export default DisabledSectionOnEdit
