import RenderListAmenities from '../native-listing/RenderListAmenities'
import React from 'react'

interface Props {
  amenitiesSelected: number[]
  onChangeAmenity: (newAmenities: number[]) => void
}

const EditAmenities: React.FC<Props> = ({ amenitiesSelected, onChangeAmenity }) => {
  return (
    <div>
      <RenderListAmenities amenitiesSelected={amenitiesSelected} onChange={onChangeAmenity} isSmallSize={true} />
    </div>
  )
}

export default EditAmenities
