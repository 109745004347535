import { getPmsIntegrated } from '../../api/integration'
import { SYNC_STATUS } from '../../contants/property'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setTriggerListingProperty } from '../../redux/slices/common'
import {
  setAirbnbChannelId,
  setImportingPmsIds,
  setIsOpenChooseListingHospitable,
  setIsOpenChooseListingChannex,
  setIsOpenImportProgressBackground,
} from '../../redux/slices/listing'
import { getNameByCountryCode } from '../../utils/common'
import { saveDataImportAirbnb } from '../../utils/property'
import AirbnbChooseListingChannex from './AirbnbChooseListingChannex'
import AirbnbChooseListingHospitable from './AirbnbChooseListingHospitable'
import AirbnbImportInfo from './AirbnbImportInfo'
import AirbnbImportSuccess from './AirbnbImportSuccess'
import AirbnbPopupEditListing from './AirbnbPopupEditListing'
import AirbnbSelectAccount from './AirbnbSelectAccount'
import AirbnbPopupEditPrompt from './AirnbnbPopupEditPrompt'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface Props {}

const AirbnbImportFlow: React.FC<Props> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const { isOpenChooseListingHospitable, isOpenChooseListingChannex } = useAppSelector((state) => state.listing)
  const [isOpenEditPrompt, setIsOpenEditPrompt] = useState<boolean>(false)
  const [isOpenEditData, setIsOpenEditData] = useState<boolean>(false)
  const [isOpenImportSuccess, setIsOpenImportSuccess] = useState<boolean>(false)
  const [selectedListingToEdit, setSelectedListingToEdit] = useState<any>(null)
  const [listings, setListings] = useState<any[]>([])
  const [prepareListings, setPrepareListings] = useState<Array<any>>([])
  const [isHasAirbnbViaChannex, setIsHasAirbnbViaChannex] = useState<boolean>(false)
  const [loadingImport, setLoadingImport] = useState<boolean>(false)

  useEffect(() => {
    fetchPmsIntegrated()
  }, [])

  useEffect(() => {
    const params: any = queryString.parse(search)
    if (params.airbnbChannelId) {
      dispatch(setAirbnbChannelId(params.airbnbChannelId))
      toggleChooseListingHospitable()
      let newParams: any = { ...params }
      delete newParams.airbnbChannelId
      const stringified = queryString.stringify(newParams, { skipEmptyString: true, skipNull: true })
      navigate('/listings' + stringified ? '?' + stringified : '')
    }
  }, [search])

  async function fetchPmsIntegrated() {
    const res = await getPmsIntegrated()
    if (res.data) {
      const airbnbViaChannex = res.data.airbnbViaChannex
      setIsHasAirbnbViaChannex(!!(airbnbViaChannex && airbnbViaChannex.airbnbChannelId))
    }
  }

  const onOkImport = () => {
    isHasAirbnbViaChannex ? toggleChooseListingChannex() : toggleChooseListingHospitable()
  }

  const toggleChooseListingChannex = () => {
    dispatch(setIsOpenChooseListingChannex(!setIsOpenChooseListingChannex))
  }

  const toggleChooseListingHospitable = () => {
    dispatch(setIsOpenChooseListingHospitable(!isOpenChooseListingHospitable))
  }

  const toggleEditPrompt = () => {
    setIsOpenEditPrompt((prevState) => !prevState)
  }

  const toggleEditData = () => {
    setIsOpenEditData((prevState) => !prevState)
  }

  const toggleImportSuccess = () => {
    setIsOpenImportSuccess((prevState) => !prevState)
  }

  const afterImportChannex = () => {
    toggleChooseListingChannex() // close popup choose listing
  }
  const renderAddress = (address: any) => {
    if (address) {
      const { state, country_code } = address
      const countryName = getNameByCountryCode(country_code)
      return [state, countryName || country_code].join(', ')
    }
    return ''
  }

  const afterImportHospitable = async (_requestId: string, _selectedListings: any[], _channelId: string) => {
    const _listProperty = _selectedListings.map((item) => {
      // map to same field with api import progress
      return {
        id: item.id,
        platformId: item.platformId,
        dtravelId: item.dtravelId,
        dtravelPropertyId: item.dtravelPropertyId,
        pmsPropertyId: item.pmsPropertyId,
        thumbnailUrl: item.images?.[0],
        address: renderAddress(item.address),
        name: item.publicName || item.privateName,
        status: SYNC_STATUS.PENDING,
      }
    })
    // pmsPropertyIds: trigger and use as params to call api import progress
    const pmsPropertyIds = _selectedListings.map((item) => item.pmsPropertyId)
    saveDataImportAirbnb({
      requestId: _requestId,
      listProperty: _listProperty,
      channelId: _channelId,
      isToggleOnPopup: true,
    })
    dispatch(setImportingPmsIds(pmsPropertyIds))
    setTimeout(() => {
      updateLoadingImport(false)
      toggleChooseListingHospitable() // close popup choose listing
      dispatch(setIsOpenImportProgressBackground(true))
    }, 3000)
  }

  const onClickEdit = (listingData: any) => {
    setSelectedListingToEdit(listingData)
    toggleEditPrompt()
    toggleEditData()
  }

  const onBackToEditPrompt = () => {
    toggleEditData()
    toggleEditPrompt()
    setSelectedListingToEdit(null)
  }

  const updateListings = (newListings: any[]) => {
    setListings(newListings)
  }

  const updatePrepareListings = (newListings: any[]) => {
    setPrepareListings(newListings)
  }

  const updateLoadingImport = (_isLoading: boolean) => {
    setLoadingImport(_isLoading)
  }

  const afterEditListings = () => {
    toggleEditData() // close popup edit
    toggleEditPrompt() // open popup edit prompt
  }

  const onFinishEdit = () => {
    dispatch(setTriggerListingProperty()) // to recall api
    toggleEditPrompt() // close popup edit prompt
    toggleImportSuccess() // open popup alert success
    navigate('/listings')
  }

  const onSkipEdit = () => {
    dispatch(setTriggerListingProperty()) // to recall api
    toggleEditPrompt() // close popup edit prompt
    navigate('/listings')
  }

  return (
    <div>
      <AirbnbSelectAccount />

      <AirbnbImportInfo afterSubmit={onOkImport} isHasAirbnbViaChannex={isHasAirbnbViaChannex} />

      <AirbnbChooseListingChannex
        isOpen={isOpenChooseListingChannex}
        onClose={toggleChooseListingChannex}
        afterSubmit={afterImportChannex}
      />

      <AirbnbChooseListingHospitable
        isOpen={isOpenChooseListingHospitable}
        onClose={toggleChooseListingHospitable}
        afterSubmit={afterImportHospitable}
        loadingImport={loadingImport}
        updateLoadingImport={updateLoadingImport}
        prepareListings={prepareListings}
        updatePrepareListings={updatePrepareListings}
      />

      <AirbnbPopupEditPrompt
        isOpen={isOpenEditPrompt}
        listings={listings}
        updateListings={updateListings}
        onClose={toggleEditPrompt}
        onClickEdit={onClickEdit}
        onSubmit={onFinishEdit}
        onSkip={onSkipEdit}
      />

      <AirbnbPopupEditListing
        isOpen={isOpenEditData}
        onClose={toggleEditData}
        selectedListing={selectedListingToEdit}
        listings={listings}
        updateListings={updateListings}
        onBack={onBackToEditPrompt}
        afterSubmit={afterEditListings}
      />

      <AirbnbImportSuccess isOpen={isOpenImportSuccess} onClose={toggleImportSuccess} />
    </div>
  )
}

export default AirbnbImportFlow
