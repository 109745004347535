import { deleteListing, listingCanBeDelete, updateManualStatus } from '../../api/listings'
import { PMS_TYPE } from '../../contants/common'
import { PROPERTY_STATUS } from '../../contants/native'
import { useAppDispatch } from '../../redux/hooks'
import { setToastSuccess } from '../../redux/slices/common'
import { setIsOpenPopupDeleteListingSuccess } from '../../redux/slices/listing'
import { capitalizeString, handleErrorMessage } from '../../utils/common'
import BasicButton from '../ui/BasicButton'
import BasicDialog from '../ui/BasicDialog'
import BasicRadio from '../ui/BasicRadio'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
  listingId: string
  status: string
  channel: string
  isActive: boolean
  fetchData?: (id: number) => Promise<any>
}
const OPTIONS = [
  {
    value: 'on',
    label: 'Listed',
    desc: 'Guests can visit your direct booking page and book.',
  },
  {
    value: 'off',
    label: 'Unlisted',
    desc: 'Guests can visit your direct booking page, but cannot book.',
  },
]

const DeleteListing: React.FC<Props> = ({ listingId, status, isActive, channel, fetchData }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [canBeDeleteByReservation, setCanBeDeleteByReservation] = useState<boolean>(true)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isPmsListing, setIsPmsListing] = useState<boolean>(false)
  const [isListed, setIsListed] = useState<boolean>(false)
  const [value, setValue] = useState<string>('')

  useEffect(() => {
    const isPms = [
      PMS_TYPE.HOSTAWAY,
      PMS_TYPE.UPLISTING,
      PMS_TYPE.GUEST_SMILES,
      PMS_TYPE.NEXTPAX,
      PMS_TYPE.RENTAL_WISE,
      PMS_TYPE.GUESTY,
      PMS_TYPE.OTHER,
    ].includes(channel || '')
    setIsPmsListing(isPms)
  }, [channel])

  useEffect(() => {
    const _isListed = isActive && status !== PROPERTY_STATUS.DRAFT && status !== PROPERTY_STATUS.PENDING_REVIEW
    setIsListed(_isListed)
    setValue(_isListed ? 'on' : 'off')
  }, [status, isActive])

  useEffect(() => {
    async function fetchListingCanBeListing(id: string) {
      const res = await listingCanBeDelete(id)
      setCanBeDeleteByReservation(res.data?.canBeDeleted)
    }
    if (isOpen && listingId) {
      fetchListingCanBeListing(listingId)
    }
  }, [listingId, isOpen])

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setValue(value)
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  const handleDeleteListing = async () => {
    try {
      setIsLoading(true)
      await deleteListing(listingId)
      handleClose()
      navigate('/listings')
      dispatch(setIsOpenPopupDeleteListingSuccess(true))
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpdateUnListedStatus = async () => {
    try {
      setIsLoading(true)
      await updateManualStatus(listingId)
      if (typeof fetchData === 'function') {
        await fetchData(Number(listingId))
      }
      dispatch(
        setToastSuccess({
          message: 'Unlisted listing successfully',
        })
      )
      handleClose()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const renderDeleteForm = () => {
    return (
      <div className={''}>
        <div
          className={
            'p-[16px] font-maison-neue-medium text-16-20 text-neutral-800 rounded-[8px] bg-red-100 text-center'
          }
        >
          By deleting this listing, all associated content will be removed from Dtravel.
        </div>
        <div className={'mt-[24px] w-full'}>
          <BasicButton
            variant={'contained'}
            color={'red'}
            clases={'w-full'}
            isRadius100={true}
            loading={isLoading}
            onClick={handleDeleteListing}
          >
            Confirm
          </BasicButton>
        </div>
      </div>
    )
  }

  const renderUnlistedForm = () => {
    return (
      <div className={'flex flex-col gap-[24px]'}>
        <div
          className={clsx(
            'p-[16px] font-maison-neue-medium text-16-20 text-neutral-800 rounded-[8px] bg-red-100 text-left'
          )}
        >
          {isPmsListing ? (
            <>
              Listings imported from a PMS cannot be deleted on Dtravel. To remove, please visit{' '}
              {channel ? capitalizeString(channel) : ''}.
            </>
          ) : (
            'Listings with active, upcoming reservations cannot be deleted. Please visit the reservations section for more\n' +
            '          info.'
          )}
        </div>

        {isListed && (
          <>
            <div className={'font-maison-neue text-16-24 text-neutral-800'}>
              In the meantime, you can prevent future bookings by editing its listing status:
            </div>
            <div className={'flex flex-col gap-[24px]'}>
              {OPTIONS.map((option) => {
                return (
                  <label
                    key={option.value}
                    className={'flex justify-between items-center gap-[32px] rounded-[16px] cursor-pointer'}
                    htmlFor={`property_status_${option.value}`}
                  >
                    <div className={'flex flex-col gap-[8px]'}>
                      <span
                        className={`px-[8px] pt-[2px] pb-[1px] font-maison-neue-medium text-12-16 tracking-[.04em] uppercase w-fit rounded-full ${
                          option.value === 'on' ? 'bg-forest-50 text-forest-700' : 'bg-red-50 text-red-700'
                        }`}
                      >
                        {option.label}
                      </span>
                      <span className={'font-maison-neue text-14-18 text-neutral-800'}>{option.desc}</span>
                    </div>
                    <BasicRadio
                      id={`property_status_${option.value}`}
                      type={'radio'}
                      classes={'w-[24px] h-[24px] cursor-pointer'}
                      name={`property_status`}
                      value={option.value}
                      checked={value === option.value}
                      onChange={onChangeValue}
                    />
                  </label>
                )
              })}
            </div>
          </>
        )}

        <div className={'flex flex-col gap-[16px]'}>
          {isListed && (
            <BasicButton
              variant={'contained'}
              color={'red'}
              isRadius100={true}
              clases={'w-full'}
              onClick={handleUpdateUnListedStatus}
              loading={isLoading}
              disabled={value === 'on'}
            >
              Apply
            </BasicButton>
          )}

          <BasicButton variant={'outlined'} color={'black'} isRadius100={true} clases={'w-full'} onClick={handleClose}>
            Cancel
          </BasicButton>
        </div>
      </div>
    )
  }

  return (
    <>
      <BasicButton size={'md'} color={'red'} variant={'outlined'} isRadius100={true} onClick={() => setIsOpen(true)}>
        Delete listing
      </BasicButton>
      <BasicDialog isOpen={isOpen} onClose={handleClose} title={'Delete this listing'}>
        {isPmsListing ? renderUnlistedForm() : canBeDeleteByReservation ? renderDeleteForm() : renderUnlistedForm()}
        {/*{hasUpcomingReservation || isPmsListing ? renderUnlistedForm() : renderDeleteForm()}*/}
      </BasicDialog>
    </>
  )
}

export default DeleteListing
