import no_image_listing from '../../assets/images/no_image_listing.jpg'
import { ListingInfo, ReservationInfo, ReservationStatusEnum } from '../../interfaces/reservations'
import { convertAmPm, renderTimeMobile } from '../../utils/common'
import RenderPrice from '../common/RenderPrice'
import RenderReservationStatus from './RenderReservationStatus'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import React from 'react'
import no_image_detail from 'src/assets/images/no_image_detail.jpg'
import { DATE_FORMAT } from 'src/contants/common'

interface Props {
  data: ReservationInfo | null
  reservationStatus: string
}
const ReservationDetailInfo = ({ data, reservationStatus }: Props) => {
  const isProcessing = data?.status === ReservationStatusEnum.AWAITING_PAYMENT
  const isInquiryManual = data?.status === ReservationStatusEnum.INQUIRY && data?.source === 'manual'

  const renderThumbImage = (listingInfo: ListingInfo | undefined) => {
    return listingInfo?.thumbnailUrl || no_image_detail
  }
  const renderItem = (name: string, value: any, isLast?: boolean) => {
    const isDate = name === 'Dates' || name === 'Booking date'
    return (
      <div className={`flex flex-col md:flex-row justify-between text-14-18 ${isLast ? '' : 'mb-[12px]'}`}>
        <p className="font-maison-neue text-neutral-600 first-letter:capitalize w-full md:w-1/2 mb-[4px] md:mb-0">
          {name}
        </p>
        <p
          className={`font-maison-neue-medium text-neutral-900 md:text-right w-full md:w-1/2 break-words ${isDate ? 'whitespace-nowrap' : ''
            }`}
          title={value}
        >
          {value}
        </p>
      </div>
    )
  }
  const renderNumberOfGuest = () => {
    if (data) {
      const result = [
        `${data.adults > 0 ? `${data.adults} adult${data.adults > 1 ? 's' : ''}` : ''}`,
        `${data.children > 0 ? `${data.children} ${data.children > 1 ? 'children' : 'child'}` : ''}`,
        `${data.infants > 0 ? `${data.infants} infant${data.infants > 1 ? 's' : ''}` : ''}`,
        `${data.pets > 0 ? `${data.pets} pet${data.pets > 1 ? 's' : ''}` : ''}`,
      ]
      return result.filter((v) => v !== '').join(', ')
    }
    return ''
  }
  const renderCityCountry = () => {
    if (data && data.listingInfo) {
      const { listingInfo } = data
      const result = [listingInfo?.address?.city, listingInfo?.address?.country]
      return result.filter((v) => v !== '').join(', ')
    }
    return ''
  }

  return (
    <>
      <div className={'w-full mt-[-16px] mb-[16px] xl:w-1/3 lg:mt-0 relative'}>
        <div
          className={'sticky top-[96px] left-0 bg-white rounded-[16px] py-[32px] px-[32px]'}
          style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)' }}
        >
          <div className="flex justify-between">
            <div>
              {data && <RenderReservationStatus status={reservationStatus} />}
              <p
                className="mt-[8px] font-maison-neue-medium text-20-24 text-neutral-900 line-clamp-2"
                title={`${data?.nights} night${Number(data?.nights) > 1 ? 's' : ''
                  } in ${renderCityCountry()} hosted by ${data?.listingInfo?.externalName}`}
              >
                {`${data?.nights} night${Number(data?.nights) > 1 ? 's' : ''} in ${renderCityCountry()} hosted by ${data?.listingInfo?.externalName
                  }`}
              </p>
            </div>
            <div
              className={'w-[76px] min-w-[76px] h-[76px] rounded-[12px] ml-[24px]'}
              style={{
                backgroundImage: `url('${renderThumbImage(data?.listingInfo)}')`,
                boxShadow: '0px 8px 16px -4px rgba(0, 0, 0, 0.06), 0px 4px 6px -2px rgba(0, 0, 0, 0.04)',
              }}
            >
              <img
                className={'min-w-[76px] min-h-[76px] max-w-[76px] max-h-[76px] rounded-[12px]'}
                src={renderThumbImage(data?.listingInfo)}
                alt={data?.listingInfo.externalName}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.src = no_image_listing
                }}
              />
            </div>
          </div>

          {data?.guestInfo && (
            <>
              <div className="w-full h-[0.5px] my-[24px]" style={{ background: 'rgba(0, 0, 0, 0.15)' }} />

              {renderItem('Guest name', data?.guestInfo?.name)}
              {renderItem('Guest email', data?.guestInfo?.email)}
              {renderItem('Guest phone', data?.guestInfo?.phone)}
            </>
          )}
          <div className="w-full h-[0.5px] my-[24px]" style={{ background: 'rgba(0, 0, 0, 0.15)' }} />

          {renderItem('Listing', data?.listingInfo?.externalName)}
          {data &&
            renderItem(
              'Dates',
              renderTimeMobile(
                `${momentTZ.tz(data?.checkinDate, data?.timezone).utc().format(DATE_FORMAT)}`,
                `${momentTZ.tz(data?.checkoutDate, data?.timezone).utc().format(DATE_FORMAT)}`
              )
            )}
          {data && renderItem('Number of guests', renderNumberOfGuest())}
          {data && renderItem('Check-in', convertAmPm(data?.listingInfo?.defaultCheckInTime, true))}
          {data && renderItem('Check-out', convertAmPm(data?.listingInfo?.defaultCheckOutTime, true))}
          {data && !isInquiryManual && renderItem('Booking date', moment(data.createdAt).format('MMM D, YYYY h:mm A'))}
          {data && renderItem('Price', <RenderPrice price={Number(data.finalPrice)} currency={data.currency} />)}
          {!isProcessing && !isInquiryManual && (
            <>
              <div className="w-full h-[0.5px] my-[24px]" style={{ background: 'rgba(0, 0, 0, 0.15)' }} />
              {data?.pmsReservationId && renderItem(`${data?.pmsType} reservation ID`, data?.pmsReservationId)}
              {renderItem('Channel reservation ID', data?.reservationId)}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ReservationDetailInfo
