import { PROPERTY_STATUS } from '../../contants/native'
import LayoutV2 from '../layout/LayoutV2'
import BasicButton from '../ui/BasicButton'
import DeleteListing from './DeleteListing'
import DetailCancelPolicy from './DetailCancelPolicy'
import DetailListingNative from './DetailListingNative'
import DetailRiskManagement from './DetailRiskManagement'
import PropertyStatus from './PropertyStatus'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getImportProgressDetail, getListingDetail, syncListing } from 'src/api/listings'
import { getUserProfile } from 'src/api/user'
import ic_arrow_back from 'src/assets/icons/ic_arrow_left.svg'
import ic_arrows_reload from 'src/assets/icons/ic_arrows_reload.svg'
import ic_eye_show from 'src/assets/icons/ic_eye_show.svg'
import no_image from 'src/assets/images/no_image_detail.jpg'
import DetailActivities from 'src/components/property/DetailActivities'
import DetailListing from 'src/components/property/DetailListing'
import DetailPaymentOptions from 'src/components/property/DetailPaymentOptions'
import DetailPayoutAndFees from 'src/components/property/DetailPayoutAndFees'
import DetailPerformance from 'src/components/property/DetailPerformance'
import DetailSmartContract from 'src/components/property/DetailSmartContract'
import { NATIVE_LISTING_MENU, NATIVE_LISTING_STEPS, PMS_TYPE, DOMAIN_TYPE } from 'src/contants/common'
import { SYNC_STATUS } from 'src/contants/property'
import { PropertyDetailProps } from 'src/interfaces/listing'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setLoading, setToastSuccess } from 'src/redux/slices/common'
import { getDomain, handleErrorMessage } from 'src/utils/common'
import { isChannexAirbnb, isPropertyDtravel } from 'src/utils/property'

const PropertyDetail = () => {
  const intervalSyncProgress = useRef<any>(null)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { propertyId } = useParams()
  const { isLoading } = useAppSelector((state) => state.common)
  const [data, setData] = useState<PropertyDetailProps | null>(null)
  const [userId, setUserId] = useState<string>('')
  const [loadingProfile, seLoadingProfile] = useState<boolean>(false)
  const [customUrl, seCustomUrl] = useState<any>(null)
  const [isNativeListing, setIsNativeListing] = useState<boolean>(false)
  const [isSyncing, setIsSyncing] = useState<boolean>(false)

  async function fetchData(id: number) {
    try {
      dispatch(setLoading(true))
      const res = await getListingDetail(id)
      if (res.data) {
        setData(res.success && res.data ? res.data : null)
        setIsNativeListing(
          isPropertyDtravel(res.data) || isChannexAirbnb(res.data) || res.data.pmsType === PMS_TYPE.HOSPITABLE
        )
      }
    } catch (error) {
      console.log(error)
      setData(null)
    } finally {
      dispatch(setLoading(false))
    }
  }

  useEffect(() => {
    return () => {
      clearInterval(intervalSyncProgress.current)
    }
  }, [])

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        seLoadingProfile(true)
        const res = await getUserProfile()
        const { data } = res
        setUserId(data.userId)
        const userCustomUrl = (data?.user?.userCustomUrl || []).find((v: any) => v?.isPrimary)
        if (userCustomUrl) seCustomUrl(userCustomUrl)
      } catch (err) {
        console.log(err)
      } finally {
        seLoadingProfile(false)
      }
    }
    fetchUserProfile()
  }, [])

  useEffect(() => {
    if (propertyId) {
      fetchData(Number(propertyId))
    }
  }, [propertyId])

  const getPreviewUrl = () => {
    if (customUrl) {
      if (customUrl?.type === DOMAIN_TYPE.CUSTOM)
        return `https://${customUrl?.customId}.${getDomain()}/property/${propertyId}`
      if (customUrl?.type === DOMAIN_TYPE.SELF_HOSTED) return `https://${customUrl?.customId}/property/${propertyId}`
    }
    return `${process.env.REACT_APP_GUEST_DOMAIN}/${userId}/property/${propertyId}`
  }

  const handleClickSyncProperty = async () => {
    if (!(data && data.pmsPropertyId)) {
      return
    }
    try {
      setIsSyncing(true)
      const pmsPropertyId = data.pmsPropertyId
      await syncListing(pmsPropertyId)
      if (!intervalSyncProgress.current) {
        intervalSyncProgress.current = setInterval(async () => {
          const res = await getImportProgressDetail({ pmsListingId: pmsPropertyId })
          if (res.success) {
            const listings = Array.isArray(res.data?.result) ? res.data.result : res.data
            const item = listings.find((item: any) => String(item.propertyId) === String(pmsPropertyId))
            // case deleted
            if (!item) {
              clearInterval(intervalSyncProgress.current)
              intervalSyncProgress.current = null
              setIsSyncing(false)
              fetchData(Number(propertyId))
              return
            }
            if (item.status !== SYNC_STATUS.PENDING) {
              clearInterval(intervalSyncProgress.current)
              intervalSyncProgress.current = null
              setIsSyncing(false)
              if (item.status === SYNC_STATUS.FAIL) {
                handleErrorMessage({ message: 'You have synced listing failed.' })
                return
              }
              if (item.status === SYNC_STATUS.DONE) {
                fetchData(Number(propertyId))
                dispatch(setToastSuccess({ message: 'You have synced listing successfully.' }))
                return
              }
            }
          }
        }, 3000)
      }
    } catch (err: any) {
      // handleErrorMessage({ message: 'You have synced listing failed.' })
      handleErrorMessage(err)
    }
  }

  const handleClickEdit = () => {
    if (!data) {
      return
    }
    if (data.status === PROPERTY_STATUS.DRAFT) {
      const settingSteps = data.settingSteps || 1
      navigate(`/native-listing/${NATIVE_LISTING_STEPS[String(settingSteps)]}?id=${propertyId}`)
      return
    }
    navigate(`/native-listing/${NATIVE_LISTING_MENU.PROPERTY}?id=${propertyId}`)
    return
  }

  const hasSyncBtn = [PMS_TYPE.HOSTAWAY, PMS_TYPE.UPLISTING, PMS_TYPE.GUEST_SMILES, PMS_TYPE.NEXTPAX].includes(
    data?.channel || ''
  )

  return (
    <LayoutV2
      childrenHeader={
        <>
          <Link to={'/listings'}>
            <button
              className={
                'rounded-full flex items-center justify-center bg-white w-[40px] h-[40px] hover:bg-neutral-200'
              }
            >
              <img alt={'ic_exit'} src={ic_arrow_back} />
            </button>
          </Link>
          {data && propertyId && (
            <div className={'flex items-center gap-[8px]'}>
              <DeleteListing
                listingId={propertyId}
                status={data.status}
                channel={data.channel}
                isActive={data.isActive}
                fetchData={fetchData}
              />
              <a
                className={
                  'rounded-full flex items-center justify-center bg-white w-[40px] h-[40px] hover:bg-neutral-200'
                }
                href={getPreviewUrl()}
                target={'_blank'}
                rel="noreferrer"
                title={'Preview listing'}
              >
                <img src={ic_eye_show} alt={'ic_eye_show'} />
              </a>
              {hasSyncBtn && (
                <BasicButton
                  clases={'w-[40px] px-0 md:px-[24px] md:w-auto'}
                  variant={'contained'}
                  size={'md'}
                  color={'white'}
                  isRadius100={true}
                  loading={loadingProfile || isSyncing}
                  onClick={handleClickSyncProperty}
                >
                  <span className={'hidden md:block font-maison-neue-medium text-14-18 text-neutral-900'}>
                    Sync listing
                  </span>
                  <span className={'block md:hidden'}>
                    <img alt={'ic_arrows_reload'} src={ic_arrows_reload} />
                  </span>
                </BasicButton>
              )}
              {isNativeListing && (
                <BasicButton
                  variant={'contained'}
                  color={'white'}
                  size={'md'}
                  isRadius100={true}
                  onClick={() => handleClickEdit()}
                >
                  Edit listing
                </BasicButton>
              )}
            </div>
          )}
        </>
      }
    >
      <div className={'flex flex-col xl:flex-row lg:my-[24px]'}>
        {/* leftside: property thumb */}
        <div className={'w-full mt-[-16px] mb-[16px] lg:mt-0 xl:w-1/3 relative'}>
          <div className={'sticky top-[96px] left-0'}>
            {!isLoading && (
              <div
                className={'w-full h-[260px] lg:h-[237px] bg-auto bg-no-repeat bg-center bg-cover rounded-t-[24px]'}
                style={{
                  backgroundImage: `url('${data && Array.isArray(data.propertyImages) && data.propertyImages.length > 0
                    ? data.propertyImages[0].url
                    : no_image
                    }')`,
                }}
              />
            )}
            <div className={'flex flex-col p-[24px] bg-white rounded-b-[24px]'}>
              {data && propertyId && (
                <div className={'mb-[8px]'}>
                  <PropertyStatus status={data.status} isActive={data.isActive} />
                </div>
              )}

              <div className={'font-maison-neue-medium text-14-18 text-neutral-600 mb-[8px]'}>
                {data?.address?.address}
              </div>
              <div className={'font-maison-neue-medium text-20-24 text-neutral-900 mb-[8px]'}>{data?.internalName}</div>
              <div className={'font-maison-neue text-14-18 text-neutral-600'}>{data?.externalName}</div>
            </div>
          </div>
        </div>

        {/* right side: other content */}
        <div className={'w-full xl:w-2/3 xl:ml-[40px] text-16-24'}>
          <DetailPerformance />
          {data && isNativeListing ? (
            <DetailListingNative data={data} onClickEdit={handleClickEdit} userId={userId} />
          ) : (
            <DetailListing pmsType={data?.pmsType || ''} />
          )}

          <DetailPaymentOptions />
          {data && (
            <DetailRiskManagement
              propertyId={propertyId || ''}
              externalName={data.externalName}
              address={data.address}
              superhogStatus={data.superhogStatus}
              isDisabledGetStarted={
                isNativeListing && [PROPERTY_STATUS.DRAFT, PROPERTY_STATUS.PENDING_REVIEW].includes(data.status)
              }
            />
          )}
          <DetailPayoutAndFees />
          <DetailActivities propertyId={propertyId || ''} />

          <DetailCancelPolicy
            pmsType={data?.pmsType || ''}
            isNativeListing={isNativeListing}
            airbnbCancellationPolicies={data?.airbnbCancellationPolicies}
            cancelPolicies={data?.cancelPolicies}
            cancelPolicyDescription={data?.cancelPolicyDescription || ''}
          />

          <DetailSmartContract
            propertyContract={data?.propertyContract}
            propertyNiteContracts={data ? data.propertyNiteContracts : []}
          />
        </div>
      </div>
    </LayoutV2>
  )
}

export default PropertyDetail
