import { checkUserExisted, confirmUnifyWallet, getUserProfile, unifyRequest } from '../../api/user'
import { useAppDispatch } from '../../redux/hooks'
import { getUserInfo, handleAuthen, handleRegister, logOut, saveUserInfo } from '../../utils/user'
import detectEthereumProvider from '@metamask/detect-provider'
import WalletConnect from '@walletconnect/client'
import QRCodeModal from '@walletconnect/qrcode-modal'
import React, { createContext, useEffect, useState, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { setToast } from 'src/redux/slices/common'
import { openSelectProfile, setProfilesData } from 'src/redux/slices/user'
import { getTimeZoneName, handleErrorMessage } from 'src/utils/common'
import { getSignature } from 'src/utils/ether'
import { PROVIDER_NETWORKS } from 'src/contants/common'

const ethereum = (window as any).ethereum

const ConnectWalleContext = createContext<any>(null)

export const CONNECT_BY = {
  WALLETCONNECT: 'walletconnect',
  METAMASK: 'metamask',
  EMAIL: 'email',
}

interface Props {
  children?: any
}

const ConnectWalletProvider: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch()
  const [isConnectingMetamask, setIsConnectingMetamask] = useState<boolean>(false)
  const [isConnectingWalletConnect, setIsConnectingWalletConnect] = useState<boolean>(false)
  const [address, setAddress] = useState<string>('')
  const [chainId, setChainId] = useState<number>(Number(process.env.REACT_APP_POLYGON_CHAIN_ID))
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const wcConnector = useRef<any>()

  useEffect(() => {
    setIsMounted(true)
  }, [])

  // handle reconnect
  useEffect(() => {
    const user = getUserInfo()
    if (user && window.location.pathname !== `${process.env.REACT_APP_BASE_PATH || ''}/login`) {
      if (user && user.connectBy) {
        if (user.connectBy === CONNECT_BY.WALLETCONNECT) {
          handleConnectWalletConnect()
        }
        if (user.connectBy === CONNECT_BY.METAMASK) {
          handleReconnectMetamask()
        }
      } else {
        logOut()
      }
    }
  }, [])

  async function initMetamaskApp() {
    // this returns the provider, or null if it wasn't detected
    const provider = await detectEthereumProvider()

    if (provider) {
      // If the provider returned by detectEthereumProvider is not the same as
      // window.ethereum, something is overwriting it, perhaps another wallet.
      if (!provider) {
        handleErrorMessage({ message: 'Please install MetaMask!' })
        return
      }
      if (provider !== window.ethereum) {
        handleErrorMessage({ message: 'Do you have multiple wallets installed?' })
        return
      }
    } else {
      console.log('Please install MetaMask!')
      if (!isMobile) {
        dispatch(setToast({ message: 'Please install MetaMask!', show: true }))
      }
    }
  }

  async function handleReconnectMetamask() {
    ethereum.on('chainChanged', async (newChainHex: string) => {
      const newChainId = PROVIDER_NETWORKS.find((v: any) => v.hex === newChainHex)?.decimal || newChainHex
      setChainId(Number(newChainId))
    })
    ethereum.on('accountsChanged', () => {
      if (window.location.pathname !== `${process.env.REACT_APP_BASE_PATH || ''}/login`) {
        const user = getUserInfo()
        if (user && user.connectBy === CONNECT_BY.METAMASK) {
          logOut()
        }
      }
    })

    const accounts = await ethereum.request({ method: 'eth_accounts' })
    setAddress(accounts.length > 0 ? accounts[0] : '')
    const chainId = await ethereum.request({ method: 'eth_chainId' })
    setChainId(parseInt(chainId))
  }

  const handleUnifyWallet = async (_address: string) => {
    try {
      const res: any = await unifyRequest({ walletId: _address, timezone: getTimeZoneName() })
      if (res?.data?.nonce) {
        const signature = await getSignature(_address, res?.data?.nonce)
        if (signature) {
          const resConfirmWallet: any = await confirmUnifyWallet({ walletId: _address, signature })
          if (resConfirmWallet?.data?.existedProfile) {
            dispatch(setProfilesData(resConfirmWallet?.data?.profiles))
            dispatch(openSelectProfile(true))
            setIsConnectingMetamask(false)
          } else {
            let newToken: any = { ...resConfirmWallet?.data }
            delete newToken?.existedProfile
            const user = getUserInfo()
            saveUserInfo({ ...user, ...newToken })
            const resProfile: any = await getUserProfile()
            saveUserInfo({ ...user, ...newToken, ...resProfile?.data })
            setIsConnectingMetamask(false)
          }
        }
      }
    } catch (error: any) {
      setIsConnectingMetamask(false)
      handleErrorMessage(error)
    }
  }
  const handleRegisterAndLogin = async (_address: string, isUnify: boolean, _connector?: any) => {
    if (isUnify) {
      handleUnifyWallet(_address)
    } else {
      const res: any = await checkUserExisted(_address)
      if (res.data === null) {
        await handleRegister(_address, _connector) // registeraww
      } else {
        await handleAuthen(_address, res.data.nonce, _connector) // login
      }
    }
  }

  const handleConnectMetamask = async (isUnify?: boolean) => {
    try {
      await initMetamaskApp()
      setIsConnectingMetamask(true)
      const ethProvider = await detectEthereumProvider()
      if (!ethProvider) {
        // open deeplink
        const url = `https://metamask.app.link/dapp/${window.location.host}/`
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
        const a = document.createElement('a')
        a.href = url
        a.target = isSafari ? '_self' : '_blank'
        document.body.appendChild(a)
        a.click()
        a.remove()
      }
      if (!ethProvider) {
        handleErrorMessage({ message: 'Please install MetaMask!' })
        return
      }
      if (ethProvider !== window.ethereum) {
        handleErrorMessage({ message: 'Do you have multiple wallets installed?' })
        return
      }
      let accounts = await ethereum.request({ method: 'eth_accounts' })
      if (accounts.length === 0) {
        accounts = await ethereum.request({ method: 'eth_requestAccounts' })
        setAddress(accounts[0])
      } else {
        setAddress(accounts[0])
      }
      const chainId = await ethereum.request({ method: 'eth_chainId' })
      setChainId(parseInt(chainId))
      await handleRegisterAndLogin(accounts[0], Boolean(isUnify))

      return accounts
    } catch (error) {
      setIsConnectingMetamask(false)
    } finally {
      if (!isUnify) setIsConnectingMetamask(false)
    }
  }

  const handleConnectWalletConnect = async () => {
    setIsConnectingWalletConnect(true)
    wcConnector.current = new WalletConnect({
      bridge: 'https://bridge.walletconnect.org', // Required
      qrcodeModal: QRCodeModal,
    })
    // Check if connection is already established
    const { connected, accounts, chainId } = wcConnector.current
    const _address = accounts[0]
    if (connected) {
      const user = getUserInfo()
      if (!user) {
        await handleRegisterAndLogin(_address, false, wcConnector.current)
      }
      setAddress(_address)
      setChainId(chainId)
    } else {
      // create new session
      wcConnector.current.createSession()
    }
    // Subscribe to connection events
    wcConnector.current.on('connect', async (error: any, payload: any) => {
      if (error) {
        throw error
      }
      // Get provided accounts and chainId
      const { accounts, chainId } = payload.params[0]
      setAddress(accounts[0])
      setChainId(chainId)
      await handleRegisterAndLogin(accounts[0], false, wcConnector.current)
      setIsConnectingWalletConnect(false)
    })

    wcConnector.current.on('session_update', (error: any, payload: any) => {
      if (error) {
        throw error
      }
      const { accounts, chainId } = payload.params[0]
      if (accounts[0] && accounts[0].toLowerCase() !== _address.toLowerCase()) {
        // change account
        if (window.location.pathname !== `${process.env.REACT_APP_BASE_PATH || ''}/login`) {
          logOut(wcConnector.current)
        }
      }
      setAddress(accounts[0])
      setChainId(chainId)
    })
    wcConnector.current.on('disconnect', () => {
      setAddress('')
      setChainId(0)
      logOut()
    })
  }

  return (
    <ConnectWalleContext.Provider
      value={{
        connector: wcConnector.current,
        ethereum: ethereum,
        isConnectingMetamask,
        isConnectingWalletConnect,
        isConnecting: isConnectingMetamask || isConnectingWalletConnect,
        handleConnectMetamask,
        handleConnectWalletConnect,
        address,
        walletAddress: address,
        chainId,
        setChainId,
        isMounted,
      }}
    >
      {children}
    </ConnectWalleContext.Provider>
  )
}

export { ConnectWalleContext, ConnectWalletProvider }
