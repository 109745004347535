import { TABS_SETTING_KEYS } from '../../contants/common'
import SettingPayment from './payments/SettingPayment'
import SettingPersonalInfo from './personal-info/SettingPersonalInfo'
import SettingPmsAndChannel from './pms-and-channel/PmsAndChannel'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getHostSettings, getUserProfile } from 'src/api/user'
import LayoutV2 from 'src/components/layout/LayoutV2'
import SideBarTabs from 'src/components/settings/SideBarTabs'
import SettingBookingSite from 'src/components/settings/booking-site/SettingBookingSite'
import SettingPromoCode from 'src/components/settings/promo-code/SettingPromoCode'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setSettingData } from 'src/redux/slices/user'
import { getUserInfo, saveUserInfo } from 'src/utils/user'
import SettingAccountUsers from './account-users/SettingAccountUsers'

const Settings = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const dispatch = useAppDispatch()

  const { profile } = useAppSelector((state) => state.user)
  const { triggerTime } = useAppSelector((state) => state.common)
  const [tab, setTab] = useState(TABS_SETTING_KEYS.PERSONAL_INFO)

  const fetchUserProfile = async () => {
    try {
      const res = await getUserProfile()
      const { data } = res
      const user = getUserInfo()
      saveUserInfo({ ...user, ...data })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchUserProfile()
    setTimeout(() => {
      const focusId = localStorage.getItem('settings_focus')
      if (focusId) {
        const el = document.getElementById(`${focusId}`)
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'start' })
          localStorage.removeItem('settings_focus')
        }
      }
    }, 500)
  }, [triggerTime, profile?.userId])

  const fetchHostSettings = async () => {
    try {
      const res = await getHostSettings()
      if (res.success) {
        dispatch(setSettingData(res.data || {}))
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchHostSettings()
  }, [])

  useEffect(() => {
    const parseQuery = queryString.parse(search)
    if (parseQuery.code && !parseQuery.tab) {
      // handle case has `code` in params (for connect stripe)
      const newQuery = { ...parseQuery }
      newQuery.tab = TABS_SETTING_KEYS.PAYMENTS
      navigate('/settings?' + queryString.stringify(newQuery))
    } else {
      if (parseQuery.tab) {
        setTab(parseQuery.tab.toString())
      } else {
        setTab(TABS_SETTING_KEYS.PERSONAL_INFO)
      }
    }
  }, [search])

  return (
    <LayoutV2 showDefaultHeader={true}>
      <div className={'flex flex-col lg:flex-row gap-[12px]'}>
        {/* Left side (sidebar) */}
        <div className={'basis-1/4 lg:min-w-[296px] lg:pr-[16px] relative'}>
          <div className={'lg:sticky top-[102px]'}>
            {/*<SideBarSettings />*/}
            <SideBarTabs activeTab={tab} />
          </div>
        </div>

        {/* Right side (content) */}
        <div className={'w-full pb-[32px] lg:pb-0'}>
          {!tab || (tab === TABS_SETTING_KEYS.PERSONAL_INFO && <SettingPersonalInfo />)}
          {tab === TABS_SETTING_KEYS.ACCOUNT_USERS && <SettingAccountUsers />}
          {tab === TABS_SETTING_KEYS.PMS_AND_CHANNELS && <SettingPmsAndChannel />}
          {tab === TABS_SETTING_KEYS.PAYMENTS && <SettingPayment />}
          {tab === TABS_SETTING_KEYS.BOOKING_SITE && <SettingBookingSite />}
          {tab === TABS_SETTING_KEYS.PROMO_CODES && <SettingPromoCode />}
        </div>
      </div>
    </LayoutV2>
  )
}

export default Settings
