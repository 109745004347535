import { useAppSelector } from '../../../redux/hooks'
import SettingAnalyticsAndSeoItem from './SettingAnalyticsAndSeoItem'
import SettingDisplayLandingPage from './SettingDisplayLandingPage'
import SettingFavicon from './SettingFavicon'
import React from 'react'
import DisabledWrapper from 'src/components/common/DisabledWrapper'
import SettingAboutUs from 'src/components/settings/booking-site/SettingAboutUs'
import SettingContact from 'src/components/settings/booking-site/SettingContact'
import SettingFaqs from 'src/components/settings/booking-site/SettingFaqs'
import SettingHeroContent from 'src/components/settings/booking-site/SettingHeroContent'
import SettingSiteCustomUrl from 'src/components/settings/booking-site/SettingSiteCustomUrl'
import SettingSiteDisplayName from 'src/components/settings/booking-site/SettingSiteDisplayName'
import SettingSiteLogo from 'src/components/settings/booking-site/SettingSiteLogo'
import SettingSitePrimaryColor from 'src/components/settings/booking-site/SettingSitePrimaryColor'
import SettingSocialMedia from 'src/components/settings/booking-site/SettingSocialMedia'
import SettingTrustAndSafety from 'src/components/settings/booking-site/SettingTrustAndSafety'
import { SETTINGS_SIDE_BAR, SETUP_GUIDE } from 'src/contants/common'

interface Props { }

const SettingBookingSite: React.FC<Props> = () => {
  const { settingData } = useAppSelector((state) => state.user)
  const isShowLandingPage = settingData.status === 'active'
  return (
    <>
      <h2 id={SETTINGS_SIDE_BAR.BOOKING_SITE_DISPLAY} className={'font-maison-neue text-20-24 text-neutral-800'}>
        Branding
      </h2>
      <p className={'font-maison-neue text-14-18 text-neutral-500 mt-[4px]'}>
        Customize your website to reflect your brand
      </p>

      <div className={'mt-[16px] bg-white p-[24px] rounded-[16px] shadow-basic'}>
        <SettingSiteDisplayName />
        <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
        <SettingSiteLogo />
        <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
        <SettingFavicon />
        <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
        <SettingSiteCustomUrl />
        <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
        <SettingSitePrimaryColor />
      </div>

      <h2 className={'font-maison-neue text-20-24 text-neutral-800 mt-[48px]'}>Content</h2>
      <p className={'font-maison-neue text-14-18 text-neutral-500 mt-[4px]'}>
        Customize your landing page content and let guests know how to contact you
      </p>
      <div className={'mt-[16px] bg-white p-[24px] rounded-[16px] shadow-xs'}>
        <SettingDisplayLandingPage />
        {isShowLandingPage ? (
          <>
            <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
            <SettingHeroContent />
            <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
            <SettingAboutUs />
            <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
            <SettingFaqs />
          </>
        ) : (
          <DisabledWrapper>
            <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
            <SettingHeroContent />
            <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
            <SettingAboutUs />
            <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
            <SettingFaqs />
          </DisabledWrapper>
        )}
        <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
        <SettingContact />
        <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
        <SettingSocialMedia />
        <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
        <SettingTrustAndSafety />
      </div>

      <div id={SETUP_GUIDE.OPTIMIZE_YOUR_SEO}>
        <h2
          id={SETTINGS_SIDE_BAR.ANALYTICS_AND_SEO}
          className={'font-maison-neue text-20-24 text-neutral-800 mt-[48px]'}
        >
          Analytics & SEO
        </h2>
        <p className={'font-maison-neue text-16-20 text-neutral-500 mt-[4px]'}>
          Drive online traffic and measure success
        </p>

        <div className={'mt-[16px] bg-white p-[24px] rounded-[16px] shadow-basic'}>
          <SettingAnalyticsAndSeoItem field={'gTag'} />
          <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
          <SettingAnalyticsAndSeoItem field={'pixel'} />
          <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
          <SettingAnalyticsAndSeoItem field={'metaTitle'} isRequired />
          <div className={'w-full h-[1px] bg-neutral-300 my-[24px]'} />
          <SettingAnalyticsAndSeoItem field={'metaDescription'} />
        </div>
      </div>
    </>
  )
}

export default SettingBookingSite
