import SettingFeeOptions from './SettingFeeOptions'
import SettingPaymentIntegration from './SettingPaymentIntegration'
import SettingPaymentOptions from './SettingPaymentOptions'
import React from 'react'

const SettingPayment = () => {
  return (
    <>
      <SettingPaymentIntegration />

      <div className={'mt-[48px]'}>
        <SettingPaymentOptions />
      </div>

      <div className={'mt-[48px]'}>
        <SettingFeeOptions />
      </div>
    </>
  )
}

export default SettingPayment
