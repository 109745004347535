import { cancelManualReservation } from '../../api/integration'
import { useAppDispatch } from '../../redux/hooks'
import { setToastSuccess } from '../../redux/slices/common'
import { handleErrorMessage } from '../../utils/common'
import BasicDialog from '../ui/BasicDialog'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import ic_delete_red from 'src/assets/icons/ic_delete_red.svg'

interface Props {
  fetchData: any
}
const CancelManualReservation = ({ fetchData }: Props) => {
  const dispatch = useAppDispatch()
  const { reservationId } = useParams()
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const handleOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const handleCancelManual = async () => {
    if (reservationId) {
      try {
        setLoading(true)
        await cancelManualReservation(reservationId)
        dispatch(setToastSuccess({ message: 'Cancel successfully!' }))
        fetchData()
        onClose()
      } catch (err: any) {
        handleErrorMessage(err)
      } finally {
        setLoading(false)
      }
    }
  }
  return (
    <>
      <button
        className={'rounded-full bg-white w-[40px] h-[40px] flex items-center justify-center'}
        onClick={handleOpen}
      >
        <img src={ic_delete_red} alt={'copy'} width={20} height={20} />
      </button>
      <BasicDialog
        isOpen={open}
        onClose={onClose}
        title={'Cancel this reservation'}
        extraTitle={'Are you sure you want to cancel this reservation?'}
      >
        <div className={'flex flex-col gap-[24px]'}>
          <div>
            <button
              className={clsx(
                'w-full flex justify-center items-center h-[48px]',
                'bg-red-700 rounded-[100px]',
                'font-maison-neue-medium text-16-16 text-white',
                'disabled:opacity-50 disabled:cursor-not-allowed'
              )}
              disabled={loading}
              style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)' }}
              onClick={handleCancelManual}
            >
              Confirm
            </button>
          </div>
        </div>
      </BasicDialog>
    </>
  )
}

export default CancelManualReservation
