import { getListings } from '../../api/listings'
import ic_check_only_black from '../../assets/icons/ic_check_only_black.svg'
import ic_chevron_down from '../../assets/icons/ic_chevron_down_neutral_600.svg'
import ic_close from '../../assets/icons/ic_close.svg'
import ic_search_input from '../../assets/icons/ic_search_input.svg'
import no_image from '../../assets/images/no_image_listing.jpg'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { Paging, ServerResponse } from '../../interfaces'
import { ListingItem } from '../../interfaces/listing'
import { useAppSelector } from '../../redux/hooks'
import { renderLocation } from '../../utils/property'
import BasicSwipeDrawer from '../ui/BasicSwipeDrawer'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { PROPERTY_STATUS } from 'src/contants/native'

interface Props {
  onSelectProperty: (data: ListingItem) => void
}

const DEFAULT_WIDTH = 654

const SearchProperty: React.FC<Props> = ({ onSelectProperty }) => {
  const timer = useRef<any>()
  const btnRef = useRef<HTMLButtonElement | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [popupWidth, setPopupWidth] = useState<number>(DEFAULT_WIDTH)
  const [listProperty, setListProperty] = useState<ListingItem[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [hasMore, setHasMore] = useState(true)
  const [flag, setFlag] = useState(0)
  const [paging, setPaging] = useState<Paging>({ page: 1, pageSize: 1, total: 0, totalPages: 0 })
  const { listingData } = useAppSelector((state) => state.common)
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768

  const fetchProperties = async (params?: any) => {
    const newParams = params ? { ...params } : {}
    newParams.status = PROPERTY_STATUS.LISTED
    if (!newParams.page) newParams.page = 1
    if (!newParams.pageSize) newParams.pageSize = 5
    setLoading(true)
    const res: ServerResponse = await getListings(newParams)
    setListProperty(res.data)
    setPaging(res.paging)
    setFlag(1)
    setLoading(false)
  }

  useEffect(() => {
    fetchProperties()
  }, [])

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    const btnWidth = event.currentTarget.offsetWidth
    setPopupWidth(btnWidth)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelectOneRow = (data: ListingItem) => {
    onSelectProperty(data)
    handleClose()
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchValue(value)
    // debounce
    if (timer.current) clearInterval(timer.current)
    timer.current = setTimeout(() => {
      fetchProperties({ search: value })
    }, 800)
  }

  const handleLoadMore = async () => {
    if (paging.totalPages > 0 && flag < paging.totalPages && !loading) {
      const params: any = { status: PROPERTY_STATUS.LISTED }
      const newFlag = flag + 1
      params.page = newFlag
      params.pageSize = 5
      setFlag(newFlag)
      try {
        setLoading(true)
        const res: ServerResponse = await getListings(params)
        const { data, paging } = res
        setPaging(paging)
        setListProperty((prevState) => [...prevState, ...data])
        setHasMore(newFlag < paging.totalPages)
      } finally {
        setLoading(false)
      }
    }
  }

  const renderContent = () => {
    return (
      <div>
        <div className={'text-right mb-[16px] md:hidden'}>
          <IconButton onClick={handleClose}>
            <img src={ic_close} alt={'ic_close'} />
          </IconButton>
        </div>
        <div className={'w-full relative mb-[8px] '}>
          <input
            className={clsx(
              'w-full h-[42px] text-14-18 pl-[46px] pr-[16px] py-[12px] rounded-[12px] text-neutral-900',
              `placeholder:text-neutral-600`,
              `border border-[0.5px] border-[#00000026] hover:border-grey-700`,
              'focus:border-neutral-900 focus:border-[1px] focus:outline-none'
            )}
            placeholder={'Search'}
            value={searchValue}
            onChange={handleChangeSearch}
          />
          <img
            src={ic_search_input}
            alt={'ic_search_input'}
            className={'absolute left-[16px] top-0 bottom-0 mx-0 my-auto'}
          />
        </div>
        <div className={'h-auto max-h-[390px] overflow-y-auto md:mx-[-24px] md:px-[24px]'}>
          <InfiniteScroll pageStart={1} loadMore={handleLoadMore} hasMore={hasMore} threshold={100} useWindow={false}>
            {listProperty.map((item, index) => {
              const isLastItem = index === listProperty.length - 1
              return (
                <div
                  key={item.id}
                  className={`flex items-center gap-[16px] py-[16px] ${isLastItem ? 'border-none pb-0' : 'border-b'
                    } border-neutral-300 cursor-pointer`}
                  role={'button'}
                  tabIndex={0}
                  onClick={() => {
                    handleSelectOneRow(item)
                  }}
                  onKeyUp={() => {
                    handleSelectOneRow(item)
                  }}
                >
                  <img
                    src={item.thumbnail || no_image}
                    alt={item.externalName}
                    className={'w-[64px] h-[48px] rounded-[8px]'}
                  />

                  <div className={'max-w-[calc(100vw-148px)] md:max-w-full overflow-x-hidden'}>
                    <p className={'font-maison-neue-medium text-14-18 text-grayscale-800 truncate pr-[16px]'}>
                      {item.internalName}
                    </p>
                    <p className={'font-maison-neue text-12-16 text-neutral-6 truncate pr-[16px]'}>
                      <span>{item.externalName}</span>
                      {' - '}
                      <span>{renderLocation(item.address)}</span>
                    </p>
                  </div>
                  <div className={'ml-auto min-w-[20px]'}>
                    {listingData?.id === item.id && <img src={ic_check_only_black} alt={'ic_check_only_black'} />}
                  </div>
                </div>
              )
            })}
          </InfiniteScroll>
        </div>

        {loading && (
          <div
            className={
              'absolute w-full h-full top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center opacity-30 bg-neutral-200 cursor-not-allow'
            }
          >
            <CircularProgress size={50} style={{ color: '#0B2336' }} />
          </div>
        )}
      </div>
    )
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <button
        ref={btnRef}
        className={'w-full bg-white rounded-[16px] flex justify-between items-center gap-[16px] p-[24px]'}
        onClick={handleOpen}
      >
        <div className={'text-left min-w-0'}>
          <p className={'font-maison-neue-medium text-12-16 text-neutral-600 mb-[2px] truncate'}>
            {/*{listingData?.internalName || 'Listing'}*/}
            Listing
          </p>
          <p className={'font-maison-neue text-16-20 text-neutral-900 truncate'}>
            <span>{listingData?.externalName}</span>
            {listingData ? ' - ' : <>&nbsp;</>}
            <span>{listingData ? renderLocation(listingData.address) : ''}</span>
          </p>
        </div>
        <span>
          <img src={ic_chevron_down} alt={'ic_chevron_down'} />
        </span>
      </button>

      {isMobile ? (
        <BasicSwipeDrawer isOpen={open} onClose={() => handleClose()}>
          {renderContent()}
        </BasicSwipeDrawer>
      ) : (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 4,
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              transform: 'translateY(8px)',
              padding: '24px',
              boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)',
              borderRadius: '12px',
              width: `${popupWidth}px`,
            },
          }}
        >
          {renderContent()}
        </Popover>
      )}
    </>
  )
}

export default SearchProperty
