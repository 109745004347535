import { updateHostSettings } from '../../../api/user'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { useAppSelector } from '../../../redux/hooks'
import { handleErrorMessage } from '../../../utils/common'
import { DOMAIN_TYPE } from './SettingSiteCustomUrl'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import BasicSwitch from 'src/components/ui/BasicSwitch'

const BootstrapTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#ffffff',
    boxShadow: '0px 20px 25px -5px rgba(0, 0, 0, 0.08), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff', // neutral-8
    padding: '8px 12px',
    borderRadius: '8px',
    color: '#50504E',
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'Maison Neue Medium',
    boxShadow: '0px 20px 25px -5px rgba(0, 0, 0, 0.08), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
  },
}))

const SettingTrustAndSafety = () => {
  const { settingData } = useAppSelector((state) => state.user)
  const { profile } = useAppSelector((state) => state.user)
  const hasSelfHostedDomain: boolean = !!(profile?.user?.userCustomUrl || []).find(
    (v: any) => v?.type === DOMAIN_TYPE.SELF_HOSTED
  )
  const [isEnabled, setIsEnabled] = useState<boolean>(true)
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 1024

  useEffect(() => {
    if (settingData) {
      setIsEnabled(settingData.safetySignals)
    }
  }, [settingData])

  const onChange = async (checked: boolean) => {
    setIsEnabled(checked)
    try {
      await updateHostSettings({ safetySignals: checked })
    } catch (err: any) {
      handleErrorMessage(err)
    }
  }

  return (
    <div className={'flex flex-row justify-between items-center gap-[12px]'}>
      <div className={'w-full'}>
        <p className={'font-maison-neue-medium text-neutral-800 text-16-20'}>Trust & Safety Signals</p>
        <p className={'font-maison-neue text-neutral-600 text-14-18 mt-[4px]'}>
          Display your external accounts in the About Us section to increase guests&apos; trust.
        </p>
      </div>

      {hasSelfHostedDomain ? (
        <div>
          <BasicSwitch
            checked={isEnabled}
            onChange={(event) => onChange(event.target.checked)}
            disabled={!hasSelfHostedDomain}
          />
        </div>
      ) : (
        <BootstrapTooltip
          title={'Add your own self-hosted domain to disable this feature'}
          arrow={true}
          placement={isMobile ? 'left' : 'right'}
        >
          <div>
            <BasicSwitch
              checked={isEnabled}
              onChange={(event) => onChange(event.target.checked)}
              disabled={!hasSelfHostedDomain}
            />
          </div>
        </BootstrapTooltip>
      )}
    </div>
  )
}

export default SettingTrustAndSafety
