import { GuestType } from '../interfaces'

export const INIT_PAGING = {
  page: 1,
  pageSize: 20,
}
export const PAGE_SIZE_DEFAULT = 15
export const WALLET = 'wallet'

export const USER = 'user'
export const TOKEN = 'token'
export const REFRESH_TOKEN = 'refresh_token'

export const SUPPORT_EMAIL = 'support@dtravel.com'

export const SORT_RESERVATIONS = [
  { value: 'arrivalDate', label: 'Arrival Date' },
  { value: 'arrivalDateDesc', label: 'Arrival Date Desc' },
  { value: 'lastConversationMessageSent', label: 'Last Conversation Message Sent' },
  { value: 'lastConversationMessageSentDesc', label: 'Last Conversation Message Sent Desc' },
  { value: 'lastConversationMessageReceived', label: 'Last Conversation Message Received' },
  { value: 'lastConversationMessageReceivedDesc', label: 'Last Conversation Message Received Desc' },
  { value: 'latestActivity', label: 'Latest Activity' },
  { value: 'latestActivityDesc', label: 'Latest Activity Desc' },
]

export const EXTERNAL_LINK = {
  DISCORD: 'https://discord.com/invite/dtravel',
  TWITTER: 'https://twitter.com/DtravelDAO',
  BLOG: 'https://dtravel.com/blog',
  RESOURCES: 'https://dtravel.com/help',
  TYPE_FORM: process.env.REACT_APP_TYPE_FORM,
  // FEEDBACK: 'https://dtravel.featureos.app/',
  FEEDBACK: 'https://dtravel.featureos.app/b/feedback',
}

export const PMS = {
  HOSTAWAY: 'hostaway',
  UPLISTING: 'uplisting',
  GUEST_SMILES: 'guestsmiles',
  GUESTY: 'guesty',
  RENTAL_WISE: 'rentalwise',
  DTRAVEL: 'dtravel',
}

export const PMS_TYPE = {
  HOSTAWAY: 'hostaway',
  UPLISTING: 'uplisting',
  GUEST_SMILES: 'guestsmiles',
  NEXTPAX: 'nextpax',
  GUESTY: 'guesty',
  RENTAL_WISE: 'rentalwise',
  HOSPITABLE: 'hospitable', // airbnb via hospitable
  AIRBNB: 'airbnb', // airbnb via channex
  OTHER: 'other',
}

export const LIST_PMS = [
  PMS_TYPE.GUESTY,
  PMS_TYPE.HOSTAWAY,
  PMS_TYPE.RENTAL_WISE,
  PMS_TYPE.GUEST_SMILES,
  PMS_TYPE.NEXTPAX,
  PMS_TYPE.UPLISTING,
  PMS_TYPE.HOSPITABLE,
  // PMS_TYPE.AIRBNB,
  PMS_TYPE.OTHER,
]

export const PMS_INFO: any = {
  hostaway: {
    label: 'Hostaway',
    url: 'https://dashboard.hostaway.com/overview',
  },
  uplisting: {
    label: 'Uplisting',
    url: 'https://app.uplisting.io/',
  },
  rentalwise: {
    label: 'RentalWise',
  },
}

export const PROVIDER_NETWORKS = [
  {
    hex: '0x1',
    decimal: 1,
    name: 'Ethereum Network',
  },
  {
    hex: '0xaa36a7',
    decimal: 11155111,
    name: 'Sepolia Testnet',
  },
  {
    hex: '0x3',
    decimal: 3,
    name: 'Ropsten Test Network',
  },
  {
    hex: '0x4',
    decimal: 4,
    name: 'Rinkeby Test Network',
  },
  {
    hex: '0x5',
    decimal: 5,
    name: 'Goerli Test Network',
  },
  {
    hex: '0x2a',
    decimal: 42,
    name: 'Kovan Test Network',
  },
  {
    hex: '0x38',
    decimal: 56,
    name: 'BSC Main Network',
  },
  {
    hex: '0x61',
    decimal: 97,
    name: 'BSC Test Network',
  },
  {
    hex: '0x89',
    decimal: 137,
    name: 'Polygon Main Network',
  },
  {
    hex: '0x13881',
    decimal: 80001,
    name: 'Mumbai Test Network',
  },
  {
    hex: '0x13882',
    decimal: 80002,
    name: 'Polygon Amoy Testnet',
  },
  {
    hex: ' 0xaa36a7',
    decimal: '11155111',
    name: 'Sepolia Testnet',
  },
  {
    hex: '0x14a34',
    decimal: '84532',
    name: 'Base Sepolia Testnet',
  },
  {
    hex: '0x2105',
    decimal: '8453',
    name: 'Base Mainnet',
  },
]
export const ACTIVITIES = {
  ACCOUNT_CREATED: 'account_created',
  WALLET_CONNECTED: 'wallet_connected',
  NEW_RESERVATION: 'new_reservation',
  CANCEL_RESERVATION_REQUESTED: 'cancel_reservation_requested',
  CANCEL_RESERVATION_CONFIRMED: 'cancel_reservation_confirmed',
  RESERVATION_CONFIRMED: 'reservation_confirmed',
  PAYOUT: 'payout',
  PAYMENT: 'payment',
  EMAIL_VERIFIED: 'email_verified',
}

export const FEES_AND_DISCOUNT: any = {
  cleaningFee: 'Cleaning fee',
  additionalCleaningFee: 'Additional cleaning fee',
  parkingFee: 'Parking fee',
  towelChangeFee: 'Towel change fee',
  midstayCleaningFee: 'Mid- stay cleaning fee',
  roomRequestFee: 'Room request fee',
  reservationChangeFee: 'Reservation change fee',
  lateCheckoutFee: 'Late check - out fee',
  otherFees: 'Other fees',
  creditCardFee: 'Card processing fee',
  kitchenLinenFee: 'Kitchen linen fee',
  linenPackageFee: 'Linen package fee',
  transferFee: 'Transfer fee',
  wristbandFee: 'Wristband fee',
  extraBedsFee: 'Extra beds fee',
  serviceFee: 'Service fee',
  bedLinenFee: 'Bed linen fee',
  bookingFee: 'Booking fee',
  petFee: 'Pet fee',
  skiPassFee: 'Ski pass fee',
  tourismFee: 'Tourism fee',
  childrenExtraFee: 'Extra children fee',
  resortFee: 'Resort fee',
  weeklyDiscount: 'Weekly discount',
  monthlyDiscount: 'Monthly discount',
  priceForExtraPerson: 'Price for extra person',
  refundableDamageDeposit: 'Refundable damage deposit',
  couponDiscount: 'Coupon discount',
  checkinFee: 'Checkin fee',
  shareholderDiscount: 'Share holder discount',
  lastMinuteDiscount: 'Last minute discount',
  employeeDiscount: 'Employee discount',
  otherSpecialDiscount: 'Other special discount',
  salesTax: 'Sales tax',
  hotelTax: 'Hotel tax',
  vat: 'VAT',
  lodgingTax: 'Lodging tax',
  occupancyTax: 'Occupancy tax',
  transientOccupancyTax: 'Transient occupancy tax',
  cityTax: 'City tax',
  roomTax: 'Room tax',
  otherTaxes: 'Other taxes',
  per_booking_percentage: 'Property rent tax',
  per_person_per_night: 'Fixed guest tax per-person, per-night',
  per_night: 'Fixed nightly tax',
  per_booking_amount: 'Fixed tax per booking',
}

export const PAYOUT_EVENT = {
  ARRIVAL: 'arrival',
  RESERVATION: 'reservation',
}

export const PAYOUT_TYPE = {
  AMOUNT: 'amount',
  PERCENTAGE: 'percentage',
}

export const PAYMENT_METHOD = {
  FIAT: 'fiat',
  CRYPTO: 'crypto',
}

export const DATE_FORMAT = 'YYYY-MM-DD'
export const MONTH_FORMAT = 'YYYY-MM'
export const START_DATE = 'startDate'
export const END_DATE = 'endDate'
export const DAY_OF_WEEK: Array<string> = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

export const GUEST_TYPE: GuestType[] = ['adult', 'child', 'infant', 'pet']

export const DISCOUNT_TYPE = {
  FLAT: 'FLAT',
  PERCENT: 'PERCENTAGE',
}

export const MAP_ICON_CRYPTO_CURRENCY: any = {
  USDT: 'https://static.dtravel.com/coin-logo/usdt.svg',
  BTC: 'https://static.dtravel.com/coin-logo/btc.svg',
  ETH: 'https://static.dtravel.com/coin-logo/eth.svg',
  BUSD: 'https://static.dtravel.com/coin-logo/busd.svg',
  USDC: 'https://static.dtravel.com/coin-logo/usdc.svg',
  TRVL: 'https://static.dtravel.com/coin-logo/trvl-logo.svg',
}

export const ADDON_PRICE_STRUCTURE = {
  PER_DAY: 'per_day',
  PER_RESERVATION: 'per_reservation',
}

export const ADDON_NAMES_SUGGESTION = [
  'Early check-in',
  'Late check-out',
  'Luggage storage',
  'Welcome basket',
  'Health kit',
  // 'Transport',
  'Sports equipment',
  'Extra cleaning',
  'Stocked pantry',
  'Bike rental',
  'Car rental',
]

export const MAPPING_TAX_APPLIED_TO: any = {
  cleaningFee: 'Cleaning fee',
  securityDepositFee: 'Security deposit fee',
  additionalCleaningFee: 'Additional cleaning fee',
  parkingFee: 'Parking fee',
  towelChangeFee: 'Towel change fee',
  midstayCleaningFee: 'Mid- stay cleaning fee',
  roomRequestFee: 'Room request fee',
  reservationChangeFee: 'Reservation change fee',
  lateCheckoutFee: 'Late check - out fee',
  otherFees: 'Other fees',
  creditCardFee: 'Card processing fee',
  kitchenLinenFee: 'Kitchen linen fee',
  linenPackageFee: 'Linen package fee',
  transferFee: 'Transfer fee',
  wristbandFee: 'Wristband fee',
  extraBedsFee: 'Extra beds fee',
  serviceFee: 'Service fee',
  bedLinenFee: 'Bed linen fee',
  bookingFee: 'Booking fee',
  petFee: 'Pet fee',
  skiPassFee: 'Ski pass fee',
  tourismFee: 'Tourism fee',
  childrenExtraFee: 'Extra children fee',
  resortFee: 'Resort fee',
  weeklyDiscount: 'Weekly discount',
  monthlyDiscount: 'Monthly discount',
  priceForExtraPerson: 'Price for extra person',
  refundableDamageDeposit: 'Refundable damage deposit',
  couponDiscount: 'Coupon discount',
  checkinFee: 'Checkin fee',
  shareholderDiscount: 'Share holder discount',
  lastMinuteDiscount: 'Last minute discount',
  employeeDiscount: 'Employee discount',
  otherSpecialDiscount: 'Other special discount',
  salesTax: 'Sales tax',
  hotelTax: 'Hotel tax',
  vat: 'VAT',
  lodgingTax: 'Lodging tax',
  occupancyTax: 'Occupancy tax',
  transientOccupancyTax: 'Transient occupancy tax',
  cityTax: 'City tax',
  roomTax: 'Room tax',
  otherTaxes: 'Other taxes',
  per_booking_percentage: 'Property rent tax',
  per_person_per_night: 'Fixed guest tax per-person, per-night',
  per_night: 'Fixed nightly tax',
  per_booking_amount: 'Fixed tax per booking',
  propertyRentTax: 'Property rent tax',
  stayTax: 'Stay tax',
  extraPersonFee: 'Price for extra person',
  LOCAL_TAX: 'Local tax',
  CITY_TAX: 'City tax',
  VAT: 'VAT',
  GOODS_AND_SERVICES_TAX: 'Goods and services tax',
  TOURISM_TAX: 'Tourism tax',
  OTHER: 'Other',
  STATE_TAX: 'State tax',
  COUNTY_TAX: 'County tax',
  OCCUPANCY_TAX: 'Occupancy tax',
  TRANSIENT_OCCUPANCY_TAX: 'Transient occupancy tax',
  HOME_SHARING_TAX: 'Home sharing tax',
  HARMONIZED_SALES_TAX: 'Harmonized sales tax',
  MINIMUM_ALTERNATE_TAX: 'Minumum alternate tax',
  nightlyTax: 'Nightly tax',
  perPersonPerNightTax: 'Per person per night tax',
  baseRate: 'Base rate',
  ADDITIONAL_BED: 'Addition bed',
  AIR_CONDITIONING: 'Air conditioning',
  EARLY_CHECK_IN: 'Early check-in',
  LATE_CHECK_IN: 'Late check-in',
  BABY_BED: 'Baby bed',
  CLEANING: 'Cleaning',
  CLUB_CARD: 'Club card',
  CONCIERGE: 'Concierge',
  EARLY_CHECKOUT: 'Early check-out',
  LATE_CHECKOUT: 'Late checkout',
  DEPOSIT: 'Deposit',
  ELECTRICITY: 'Electricity',
  FOOD: 'Food',
  HEATING: 'Heatong',
  INTERNET: 'Internet',
  LAUNDRY: 'Laundry',
  LINENS: 'Linens',
  TOWELS: 'Towels',
  MANAGEMENT: 'Management',
  OIL: 'Oil',
  PARKING: 'Parking',
  PET: 'Pet',
  POOL: 'Pool',
  POOL_HEATING: 'Pool heating',
  RESORT: 'Resort',
  SERVICE: 'Service',
  TOILETRIES: 'Toiletries',
  TOUR: 'Trour',
  TRANSPORTATION: 'Transportation',
  CAR_RENTAL: 'Car rental',
  WATER: 'Water',
  WOOD: 'Wood',
  TRANSFER: 'Transfer',
  HOUSEKEEPING: 'Housekeeping',
  INSURANCE: 'Insurance',
  COMMUNITY: 'Community',
  CREDIT_CARD_PROCESSING_FEE: 'Credit card processing fee',
  DAMAGE_WAIVER: 'Damange waiver',
  pricePerExtraPerson: 'price for extra person',
  insuranceFee: 'insurance fee',
}

export const SETTINGS_SIDE_BAR: any = {
  ACCOUNT_INFORMATION: 'Account Information',
  CHANNEL_INTEGRATION: 'PMS/CMS Integration',
  CHANNEL_MANAGEMENT: 'Channel Management',
  PAYMENT_INTEGRATION: 'Payment Integration',
  PAYMENT_METHODS: 'Payment Methods',
  FEE_OPTIONS: 'Fee Options',
  BOOKING_SITE_DISPLAY: 'Booking Site Display',
  ANALYTICS_AND_SEO: 'Analytics & SEO',
  PROMO_CODE: 'Promo Codes',
}

export const TABS_SETTING_KEYS = {
  PERSONAL_INFO: 'personal_info',
  ACCOUNT_USERS: 'account_users',
  PMS_AND_CHANNELS: 'pms_and_channels',
  PAYMENTS: 'payments',
  BOOKING_SITE: 'booking_site',
  PROMO_CODES: 'promo_codes',
}

export const TABS_SETTINGS: any = [
  {
    key: TABS_SETTING_KEYS.PERSONAL_INFO,
    title: 'Personal info',
  },
  {
    key: TABS_SETTING_KEYS.ACCOUNT_USERS,
    title: 'Account users',
  },
  {
    key: TABS_SETTING_KEYS.PMS_AND_CHANNELS,
    title: 'PMS & channels',
  },
  {
    key: TABS_SETTING_KEYS.PAYMENTS,
    title: 'Payments',
  },
  {
    key: TABS_SETTING_KEYS.BOOKING_SITE,
    title: 'Booking site',
  },
  {
    key: TABS_SETTING_KEYS.PROMO_CODES,
    title: 'Promo codes',
  },
]

export const MAPPING_SETUP_GUIDE_TO_SETTINGS_TAB: any = {
  ADD_CONTACT_INFO: TABS_SETTING_KEYS.BOOKING_SITE,
  ADD_PAYMENT_OPTIONS: TABS_SETTING_KEYS.PAYMENTS,
  SET_WEBSITE_URL: TABS_SETTING_KEYS.BOOKING_SITE,
  ADD_A_LOGO: TABS_SETTING_KEYS.BOOKING_SITE,
  OPTIMIZE_YOUR_SEO: TABS_SETTING_KEYS.BOOKING_SITE,
  CONNECT_SOCIAL_MEDIA: TABS_SETTING_KEYS.BOOKING_SITE,
  ADD_EMAIL_LOGIN: TABS_SETTING_KEYS.PERSONAL_INFO,
  CONNECT_METAMASK_WALLET: TABS_SETTING_KEYS.PAYMENTS,
  ADD_SITE_NAME: TABS_SETTING_KEYS.BOOKING_SITE,
  ADD_A_BIO: TABS_SETTING_KEYS.BOOKING_SITE,
}

export const SETUP_GUIDE = {
  IMPORT_LISTING: 'import_listing',
  ADD_CONTACT_INFO: 'add_contact_info',
  ADD_PAYMENT_OPTIONS: 'add_payment_options',
  SET_WEBSITE_URL: 'set_website_url',
  ADD_A_LOGO: 'add_a_logo',
  OPTIMIZE_YOUR_SEO: 'optimize_your_seo',
  CONNECT_SOCIAL_MEDIA: 'connect_social_media',
  ADD_EMAIL_LOGIN: 'add_email_login',
  CONNECT_METAMASK_WALLET: 'connect_metamask_wallet',
  ADD_SITE_NAME: 'add_site_name',
  ADD_A_BIO: 'add_a_bio',
  CREATE_MANUAL_RESERVATION: 'create_manual_reservation',
}

export const SETUP_GUIDE_MAPPING_TO_SECTIONS_SETTING: any = {
  IMPORT_LISTING: SETTINGS_SIDE_BAR.CHANNEL_INTEGRATION,
  ADD_CONTACT_INFO: SETTINGS_SIDE_BAR.BOOKING_SITE_DISPLAY,
  ADD_PAYMENT_OPTIONS: SETTINGS_SIDE_BAR.PAYMENT_METHODS,
  SET_WEBSITE_URL: SETTINGS_SIDE_BAR.BOOKING_SITE_DISPLAY,
  ADD_A_LOGO: SETTINGS_SIDE_BAR.BOOKING_SITE_DISPLAY,
  OPTIMIZE_YOUR_SEO: SETTINGS_SIDE_BAR.ANALYTICS_AND_SEO,
  CONNECT_SOCIAL_MEDIA: SETTINGS_SIDE_BAR.BOOKING_SITE_DISPLAY,
  ADD_EMAIL_LOGIN: SETTINGS_SIDE_BAR.ACCOUNT_INFORMATION,
  CONNECT_METAMASK_WALLET: SETTINGS_SIDE_BAR.PAYMENT_INTEGRATION,
}

export const SETUP_GUIDE_CHECK_LIST_GROUP = [
  {
    title: 'Essentials',
    children: [
      {
        key: SETUP_GUIDE.ADD_SITE_NAME,
        title: 'Add site name',
        desc: 'Set your brand or business name',
      },
      {
        key: SETUP_GUIDE.IMPORT_LISTING,
        title: 'Import or create listings',
        desc: 'Quickly create your Dtravel listings',
      },
      {
        key: SETUP_GUIDE.ADD_CONTACT_INFO,
        title: 'Add contact info',
        desc: 'Let guests know how to contact you',
      },
      {
        key: SETUP_GUIDE.ADD_PAYMENT_OPTIONS,
        title: 'Add fiat payment option',
        desc: 'Increase the ways guests can pay you',
      },
      {
        key: SETUP_GUIDE.ADD_EMAIL_LOGIN,
        title: 'Add email login support',
        desc: 'Login to Dtravel with an email',
      },
    ],
  },
  {
    title: 'Enhancements',
    children: [
      {
        key: SETUP_GUIDE.CONNECT_METAMASK_WALLET,
        title: 'Connect MetaMask wallet',
        desc: 'Accept crypto payments and receive TRVL rewards',
      },
      {
        key: SETUP_GUIDE.SET_WEBSITE_URL,
        title: 'Set website URL',
        desc: 'Quickly create your Dtravel listings',
      },
      {
        key: SETUP_GUIDE.ADD_A_LOGO,
        title: 'Add a logo',
        desc: 'Make your brand stand-out on your brand',
      },
      {
        key: SETUP_GUIDE.ADD_A_BIO,
        title: 'Add a bio',
        desc: 'Tell your guests what makes you and your business unique.',
      },
      {
        key: SETUP_GUIDE.OPTIMIZE_YOUR_SEO,
        title: 'Optimize your SEO',
        desc: 'Ensure your website appears in search results',
      },
      {
        key: SETUP_GUIDE.CONNECT_SOCIAL_MEDIA,
        title: 'Connect social media',
        desc: 'Grow your brand’s online presence',
      },
    ],
  },
]
export const SETUP_GUIDE_CHECK_LIST_V3 = [
  {
    key: SETUP_GUIDE.IMPORT_LISTING,
    label: 'Import or create a listing',
  },
  {
    key: SETUP_GUIDE.ADD_PAYMENT_OPTIONS,
    label: 'Connect your Stripe account',
  },
  {
    key: SETUP_GUIDE.CONNECT_METAMASK_WALLET,
    label: 'Connect MetaMask wallet',
  },
  {
    key: SETUP_GUIDE.ADD_SITE_NAME,
    label: 'Customize site name',
  },
  {
    key: SETUP_GUIDE.ADD_CONTACT_INFO,
    label: 'Add contact info',
  },
  {
    key: SETUP_GUIDE.SET_WEBSITE_URL,
    label: 'Customize site URL',
  },
  {
    key: SETUP_GUIDE.ADD_A_LOGO,
    label: 'Add a logo',
  },
  {
    key: SETUP_GUIDE.CONNECT_SOCIAL_MEDIA,
    label: 'Connect social media',
  },
  {
    key: SETUP_GUIDE.CREATE_MANUAL_RESERVATION,
    label: 'Create a manual reservation',
  },
]
export const NATIVE_LISTING_MENU = {
  PROPERTY: 'property',
  LOCATION: 'location',
  GUEST_ROOMS: 'guest-rooms',
  PHOTOS: 'photos',
  AMENITIES: 'amenities',
  DESCRIPTION: 'description',
  HOUSE_RULES: 'house-rules',
  AVAILABILITY: 'availability',
  ICAL: 'ical',
  FEES: 'fees',
  DISCOUNTS: 'discounts',
  RATES: 'rates',
  PUBLISH: 'publish',
}

export const NATIVE_LISTING_STEPS: any = {
  '1': NATIVE_LISTING_MENU.PROPERTY,
  '2': NATIVE_LISTING_MENU.LOCATION,
  '3': NATIVE_LISTING_MENU.GUEST_ROOMS,
  '4': NATIVE_LISTING_MENU.PHOTOS,
  '5': NATIVE_LISTING_MENU.AMENITIES,
  '6': NATIVE_LISTING_MENU.DESCRIPTION,
  '7': NATIVE_LISTING_MENU.HOUSE_RULES,
  '8': NATIVE_LISTING_MENU.AVAILABILITY,
  '9': NATIVE_LISTING_MENU.ICAL,
  '10': NATIVE_LISTING_MENU.FEES,
  '11': NATIVE_LISTING_MENU.DISCOUNTS,
  '12': NATIVE_LISTING_MENU.RATES,
  '13': NATIVE_LISTING_MENU.PUBLISH,
}

export const DEFAULT_CHECKIN_TIME = '15'
export const DEFAULT_CHECKOUT_TIME = '11'
export const PROMO_CODE_TYPE = {
  FIXED: 'fixed',
  PERCE: 'percentage',
}

export const DOMAIN_TYPE = {
  CUSTOM: 'custom',
  SELF_HOSTED: 'self_hosted',
}

export const BUSINESS_USER_ROLE = {
  ADMIN: 'admin',
  EDITOR: 'editor',
}

export const MAX_LENGTH_HERO_CONTENT = {
  HEADLINE: 30,
  SUB_HEADLINE: 105,
}

export const SETUP_GUIDE_V2 = {
  IMPORT_LISTING: 'IMPORT_LISTING',
  ADD_A_SITE_DETAIL: 'ADD_A_SITE_DETAIL',
  CUSTOMIZE_YOUR_LANDING_PAGE: 'CUSTOMIZE_YOUR_LANDING_PAGE',
  CUSTOMIZE_YOUR_WEBSITE: 'CUSTOMIZE_YOUR_WEBSITE',
  ADD_CONTACT_INFO: 'ADD_CONTACT_INFO',
  CONNECT_A_STRIPE_ACCOUNT: 'CONNECT_A_STRIPE_ACCOUNT',
  CREATE_A_DTRAVEL_SMARTWALLET: 'CREATE_A_DTRAVEL_SMARTWALLET',
  ADD_EXTERNAL_ACCOUNTS: 'ADD_EXTERNAL_ACCOUNTS',
  CREATE_A_MANUAL_RESERVATION: 'CREATE_A_MANUAL_RESERVATION',
  CUSTOMIZE_SITE_URL: 'CUSTOMIZE_SITE_URL',
}

export const SETUP_GUIDE_STEP_LIST_BY_INTEREST = {
  CREATE_DIRECT_BOOKING: [
    {
      key: SETUP_GUIDE_V2.IMPORT_LISTING,
      label: 'Import or create a listing',
    },
    {
      key: SETUP_GUIDE_V2.ADD_A_SITE_DETAIL,
      label: 'Add site details',
    },
    {
      key: SETUP_GUIDE_V2.CUSTOMIZE_YOUR_LANDING_PAGE,
      label: 'Customize your landing page',
    },
    {
      key: SETUP_GUIDE_V2.CUSTOMIZE_YOUR_WEBSITE,
      label: 'Customize your website',
    },
    {
      key: SETUP_GUIDE_V2.ADD_CONTACT_INFO,
      label: 'Add contact info',
    },
    {
      key: SETUP_GUIDE_V2.CONNECT_A_STRIPE_ACCOUNT,
      label: 'Connect a Stripe account',
    },
    {
      key: SETUP_GUIDE_V2.CREATE_A_DTRAVEL_SMARTWALLET,
      label: 'Create a Dtravel Smartwallet',
    },
    {
      key: SETUP_GUIDE_V2.ADD_EXTERNAL_ACCOUNTS,
      label: 'Add external accounts',
    },
    {
      key: SETUP_GUIDE_V2.CREATE_A_MANUAL_RESERVATION,
      label: 'Create a manual reservation',
    },
  ],
  LIST_ON_META_SEARCH_ENGINES: [
    {
      key: SETUP_GUIDE_V2.IMPORT_LISTING,
      label: 'Import or create a listing',
    },
    {
      key: SETUP_GUIDE_V2.ADD_A_SITE_DETAIL,
      label: 'Add site details',
    },
    {
      key: SETUP_GUIDE_V2.CONNECT_A_STRIPE_ACCOUNT,
      label: 'Connect a Stripe account',
    },
    {
      key: SETUP_GUIDE_V2.CREATE_A_DTRAVEL_SMARTWALLET,
      label: 'Create a Dtravel Smartwallet',
    },
    {
      key: SETUP_GUIDE_V2.ADD_CONTACT_INFO,
      label: 'Add contact info',
    },
  ],
}
export const MAPPING_SETUP_GUIDE_TO_SETTINGS_TAB_V2: any = {
  ADD_A_SITE_DETAIL: TABS_SETTING_KEYS.BOOKING_SITE,
  CUSTOMIZE_YOUR_LANDING_PAGE: TABS_SETTING_KEYS.BOOKING_SITE,
  CUSTOMIZE_YOUR_WEBSITE: TABS_SETTING_KEYS.BOOKING_SITE,
  ADD_CONTACT_INFO: TABS_SETTING_KEYS.BOOKING_SITE,
  CONNECT_A_STRIPE_ACCOUNT: TABS_SETTING_KEYS.PAYMENTS,
  CREATE_A_DTRAVEL_SMARTWALLET: TABS_SETTING_KEYS.PAYMENTS,
  ADD_EXTERNAL_ACCOUNTS: TABS_SETTING_KEYS.BOOKING_SITE,
  CUSTOMIZE_SITE_URL: TABS_SETTING_KEYS.BOOKING_SITE,
}
export const TYPE_EDIT_LISTINGS = {
  ALL: 'all',
  NATIVE: 'native',
  AIRBNB: 'airbnb',
  CHANNEX: 'channex', // property channex, edit as native but disabled edit minumum_stay
}
