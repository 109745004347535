import ic_copy from '../../assets/icons/ic_copy_2.svg'
import { useAppSelector } from '../../redux/hooks'
import { setToastSuccess } from '../../redux/slices/common'
import { IconChecked } from '../common/Icons'
import ConnectWalletThirdWeb from '../thirdweb/ConnectWalletThirdWeb'
import BasicButton from '../ui/BasicButton'
import BasicDialog from '../ui/BasicDialog'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useActiveAccount, useConnectModal } from 'thirdweb/react'

const REASONS_CREATE_SMARTWALLET = [
  'Non-custodial wallet requiring no private keys or seed phrases',
  'Accept cryptocurrency payments',
  'Collect TRVL token rewards for each booking',
  'Gasless transactions',
]

const LearnMoreDtravelSmartWallet = () => {
  const dispatch = useDispatch()
  const { profile } = useAppSelector((state) => state.user)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false)
  const walletConnected: any = useActiveAccount()
  const address = walletConnected?.address || ''
  const { isConnecting } = useConnectModal();
  const isWalletModalOpen = isConnecting
  const trackOpenThirdWeb = useRef<boolean>(false)

  useEffect(() => {
    if (address && !isWalletModalOpen && trackOpenThirdWeb.current) {
      // open popup confirm
      handleOpenPopupConfirm()
      handleClosePopup()
    }
  }, [address, isWalletModalOpen])

  const handleOpenPopup = () => {
    setIsOpen(true)
  }

  const handleClosePopup = () => {
    setIsOpen(false)
  }

  const handleOpenPopupConfirm = () => {
    setIsOpenConfirm(true)
  }

  const handleClosePopupConfirm = () => {
    setIsOpenConfirm(false)
    trackOpenThirdWeb.current = false
  }

  const handleClickCreateSmartWallet = () => {
    trackOpenThirdWeb.current = true
    // close current popup
    // handleClosePopup()
  }

  const onCopy = (value: string) => {
    navigator.clipboard.writeText(value)
    dispatch(setToastSuccess({ message: `Copied!`, duration: 2000 }))
  }

  return (
    <>
      <div
        className={
          'bg-white p-[24px] rounded-[16px] flex flex-col md:flex-row items-center justify-between gap-[24px] shadow-xl ' +
          `${profile?.user?.hasSmartWallet ? 'hidden' : ''}`
        }
      >
        <div>
          <p className={'font-maison-neue text-20-24 text-neutral-800'}>Create a non-custodial Dtravel Smartwallet</p>
          <p className={'font-maison-neue text-14-20 text-neutral-600 mt-[4px]'}>
            Accept cryptocurrencies payments, collect web3 rewards, benefit from gasless transaction, and more.
          </p>
        </div>
        <BasicButton
          color={'red'}
          variant={'contained'}
          isRadius100={true}
          clases={'w-full md:w-auto'}
          onClick={handleOpenPopup}
        >
          Learn more
        </BasicButton>
      </div>
      <BasicDialog
        isOpen={isOpen}
        onClose={handleClosePopup}
        title={'Create a Dtravel Smartwallet'}
        extraTitle={
          'Accept cryptocurrencies payments, collect web3 rewards, benefit from gasless transaction, and more.'
        }
      >
        <div className={''}>
          <p className={'font-maison-neue text-16-20 text-neutral-800'}>Why create a smartwallet?</p>

          <ul className={'mt-[16px] flex flex-col gap-[8px]'}>
            {REASONS_CREATE_SMARTWALLET.map((item, index) => (
              <li key={index} className={'font-maison-neue text-14-18 text-neutral-800 flex items-center gap-[8px]'}>
                <IconChecked width={16} height={16} />
                <span>{item}</span>
              </li>
            ))}
          </ul>
          <p className={'mt-[16px] font-maison-neue text-14-18 text-neutral-500'}>
            Note: Dtravel will never be able to access your wallet or funds.
          </p>

          <div className={'mt-[24px]'}>
            <div role={'presentation'} onClick={handleClickCreateSmartWallet}>
              <ConnectWalletThirdWeb btnTitle="Create a Dtravel Smartwallet" className="custom-smartwallet-red" disableAutoConnect />
            </div>
          </div>
        </div>
      </BasicDialog>

      <BasicDialog
        isOpen={isOpenConfirm}
        onClose={handleClosePopupConfirm}
        title={'Confirmation'}
        extraTitle={'Your Dtravel Smartwallet is now active'}
      >
        <div className={'mt-[-16px]'}>
          <p className={'font-maison-neue text-16-20 text-neutral-800'}>
            Cryptocurrencies payments and web3 rewards will now be deposited in your new smartwallet.
          </p>
          <div className={'mt-[16px]'}>
            <p className={'block font-maison-neue-medium text-14-18 text-neutral-800 mb-[8px]'}>
              Your Smartwallet address
            </p>

            <label className={'block relative w-full'} htmlFor={'addressSmartWallet'}>
              <input
                id={'addressSmartWallet'}
                className={
                  'shadow-xs bg-neutral-50 rounded-[12px] px-[16px] py-[10px] font-maison-neue text-16-20 text-neutral-500 w-full border-[0.5px] border-[rgba(0_0_0_0.15)] pr-[42px] focus:outline-none'
                }
                readOnly={true}
                value={address}
              />
              <button className={'absolute right-[12px] bottom-[2px] -translate-y-1/2'} onClick={() => onCopy(address)}>
                <img src={ic_copy} alt={'ic_copy'} style={{ width: '20px', height: '20px' }} />
              </button>
            </label>
          </div>
          <p className={'font-maison-neue text-14-18 text-neutral-500 mt-[8px]'}>
            You can access your wallet from the top-right menu
          </p>

          <div className={'mt-[24px]'}>
            <BasicButton
              isRadius100={true}
              color={'red'}
              variant={'contained'}
              clases={'w-full'}
              onClick={handleClosePopupConfirm}
            >
              OK
            </BasicButton>
          </div>
        </div>
      </BasicDialog>
    </>
  )
}

export default LearnMoreDtravelSmartWallet
