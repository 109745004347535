import { SETUP_GUIDE } from '../../../contants/common'
import { FormError } from '../../../interfaces'
import { handleErrorMessage, isEmpty, removeAtStart } from '../../../utils/common'
import { getUserInfo, saveUserInfo } from '../../../utils/user'
import SettingContactEmail from './SettingContactEmail'
import SettingContactPhoneWithoutVerify from './SettingContactPhoneWithoutVerify'
import IconButton from '@mui/material/IconButton'
import React, { useEffect, useState } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { getUserProfile, updateHostSettings, updateUserProfile } from 'src/api/user'
import ic_add_plus_circle from 'src/assets/icons/ic_add_plus_circle.svg'
import ic_edit_pencil_line from 'src/assets/icons/ic_edit_pencil_line.svg'
import ic_trash_full from 'src/assets/icons/ic_trash_full.svg'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import BasicInput from 'src/components/ui/BasicInput'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { setSettingData } from 'src/redux/slices/user'

const CONTACT_TYPE = {
  EMAIL: 'email',
  PHONE: 'phone',
  WHATSAPP: 'whatsapp',
  TELEGRAM: 'telegram',
}

const SettingContact = () => {
  const dispatch = useAppDispatch()
  const { settingData } = useAppSelector((state) => state.user)
  const { profile } = useAppSelector((state) => state.user)
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [contactType, setContactType] = useState<string>('email')
  const [whatsApp, setWhatsApp] = useState<string>('')
  const [telegram, setTelegram] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorWhatsApp, setErrorWhatsApp] = useState<FormError>({ show: false, message: '' })
  const [errorTelegram, setErrorTelegram] = useState<FormError>({ show: false, message: '' })

  useEffect(() => {
    setWhatsApp(settingData?.whatsapp || '')
    setTelegram(settingData?.telegram || '')
  }, [settingData])

  const fetchUserProfile = async () => {
    try {
      const res = await getUserProfile()
      const { data } = res
      const user = getUserInfo()
      saveUserInfo({ ...user, ...data })
    } catch (err) {
      console.log(err)
    }
  }

  const handleOpen = () => {
    setIsOpenPopup(true)
  }

  const handleClose = () => {
    setIsOpenPopup(false)
    // reset data
    setContactType('email')
    setWhatsApp(settingData?.whatsapp || '')
    setTelegram(settingData?.telegram || '')
    setErrorWhatsApp({ show: false, message: '' })
    setErrorTelegram({ show: false, message: '' })
  }

  const onChangeWhatsApp = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWhatsApp(event.target.value)
    setErrorWhatsApp({ show: false, message: '' })
  }

  const onChangeTelegram = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value?.trim()
    setTelegram(removeAtStart(value))
    setErrorTelegram({ show: false, message: '' })
  }

  const onClickEdit = (type: string) => {
    setContactType(type)
    handleOpen()
  }

  const onClickDelete = async (type: string) => {
    try {
      if ([CONTACT_TYPE.WHATSAPP, CONTACT_TYPE.TELEGRAM].includes(type)) {
        let data: any = {}
        if (type === CONTACT_TYPE.WHATSAPP) data.whatsapp = ''
        if (type === CONTACT_TYPE.TELEGRAM) data.telegram = ''
        const res = await updateHostSettings(data)
        dispatch(setToastSuccess({ message: 'Delete successfully!' }))
        dispatch(setSettingData({ ...settingData, ...res.data }))
        fetchUserProfile()
      }
      if (type === CONTACT_TYPE.EMAIL) {
        await updateUserProfile({ email: null })
        dispatch(setToastSuccess({ message: 'Delete successfully!' }))
        fetchUserProfile()
      }
      if (type === CONTACT_TYPE.PHONE) {
        await updateUserProfile({ phone: null })
        dispatch(setToastSuccess({ message: 'Delete successfully!' }))
        fetchUserProfile()
      }
    } catch (err) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSaveContact = async (type: string) => {
    if (type === CONTACT_TYPE.WHATSAPP && !isValidPhoneNumber(whatsApp)) {
      setErrorWhatsApp({ show: true, message: 'Please enter a valid WhatsApp number' })
      return
    }
    setIsLoading(true)
    try {
      let data: any = { whatsapp: whatsApp }
      if (type === CONTACT_TYPE.TELEGRAM) {
        data = { telegram: telegram }
      }
      const res = await updateHostSettings(data)
      dispatch(setToastSuccess({ message: 'Update successfully!' }))
      dispatch(setSettingData({ ...settingData, ...res.data }))
      fetchUserProfile()
      handleClose()
    } catch (err) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div
        className={'flex flex-col md:flex-row justify-between items-start gap-[12px]'}
        id={SETUP_GUIDE.ADD_CONTACT_INFO}
      >
        <div>
          <p className={'font-maison-neue-medium text-neutral-800 text-16-20'}>Contact Methods</p>
          <p className={'font-maison-neue text-neutral-600 text-14-18 mt-[4px]'}>
            Add ways for your guests to contact you. We recommend adding at least 3 methods.
          </p>
        </div>

        <BasicDialog
          isOpen={isOpenPopup}
          title={'Contact Methods'}
          extraTitle={'Staying Connected with Your Audience'}
          onClose={handleClose}
          allowBackdropClick
          titleAlign={'center'}
        >
          <div className={''}>
            {contactType === CONTACT_TYPE.PHONE && <SettingContactPhoneWithoutVerify onClose={handleClose} />}
            {contactType === CONTACT_TYPE.EMAIL && <SettingContactEmail onClose={handleClose} />}
            {contactType === CONTACT_TYPE.WHATSAPP && (
              <>
                <div>
                  <BasicInput
                    label={'WhatsApp'}
                    placeholder={'+1 (555) 000-0000'}
                    value={whatsApp}
                    onChange={onChangeWhatsApp}
                    onBlur={() => setWhatsApp((prevState) => prevState.trim())}
                    error={errorWhatsApp}
                  />
                </div>
              </>
            )}
            {contactType === CONTACT_TYPE.TELEGRAM && (
              <>
                <div>
                  <BasicInput
                    label={'Telegram'}
                    placeholder={'Telegram username'}
                    value={telegram}
                    onChange={onChangeTelegram}
                    onBlur={() => {
                      setTelegram((prevState) => prevState.trim())
                      if (telegram.startsWith('@'))
                        setErrorTelegram({ show: true, message: 'Please enter valid Telegram username' })
                    }}
                    error={errorTelegram}
                  />
                </div>
              </>
            )}
            {[CONTACT_TYPE.WHATSAPP, CONTACT_TYPE.TELEGRAM].includes(contactType) && (
              <div className={'mt-[24px]'}>
                <BasicButton
                  variant={'contained'}
                  color={'red'}
                  isRadius100={true}
                  clases={'w-full'}
                  onClick={() => handleSaveContact(contactType)}
                  disabled={
                    (contactType === CONTACT_TYPE.WHATSAPP && !whatsApp) ||
                    (contactType === CONTACT_TYPE.TELEGRAM && (!telegram || !isEmpty(errorTelegram.message)))
                  }
                  loading={isLoading}
                >
                  Add
                </BasicButton>
              </div>
            )}
          </div>
        </BasicDialog>
      </div>
      <div className={'p-[16px] md:p-[24px] bg-neutral-50 rounded-[16px] mt-[12px] flex justify-between items-center'}>
        <div>
          <p className={'font-maison-neue-medium text-16-20 text-neutral-800'}>Email</p>
          <p className={'font-maison-neue text-14-18 text-neutral-600 mt-[4px]'}>{profile?.email}</p>
        </div>
        <div className={'flex items-center gap-[12px]'}>
          <IconButton size={'small'} onClick={() => onClickEdit(CONTACT_TYPE.EMAIL)}>
            <img src={profile?.email ? ic_edit_pencil_line : ic_add_plus_circle} alt={'ic_add_plus_circle'} />
          </IconButton>
          {profile?.email && (
            <IconButton size={'small'} onClick={() => onClickDelete(CONTACT_TYPE.EMAIL)}>
              <img src={ic_trash_full} alt={'ic_trash_full'} />
            </IconButton>
          )}
        </div>
      </div>
      <div className={'p-[16px] md:p-[24px] bg-neutral-50 rounded-[16px] mt-[12px] flex justify-between items-center'}>
        <div>
          <p className={'font-maison-neue-medium text-16-20 text-neutral-800'}>Phone</p>
          <p className={'font-maison-neue text-14-18 text-neutral-600 mt-[4px]'}>{profile?.phone}</p>
        </div>
        <div className={'flex items-center gap-[12px]'}>
          <IconButton size={'small'} onClick={() => onClickEdit(CONTACT_TYPE.PHONE)}>
            <img src={profile?.phone ? ic_edit_pencil_line : ic_add_plus_circle} alt={'ic_add_plus_circle'} />
          </IconButton>
          {profile?.phone && (
            <IconButton size={'small'} onClick={() => onClickDelete(CONTACT_TYPE.PHONE)}>
              <img src={ic_trash_full} alt={'ic_trash_full'} />
            </IconButton>
          )}
        </div>
      </div>
      {/*---Whatsapp---*/}
      <div className={'p-[16px] md:p-[24px] bg-neutral-50 rounded-[16px] mt-[12px] flex justify-between items-center'}>
        <div>
          <p className={'font-maison-neue-medium text-16-20 text-neutral-800'}>WhatsApp</p>
          <p className={'font-maison-neue text-14-18 text-neutral-600 mt-[4px]'}>
            {settingData?.[CONTACT_TYPE.WHATSAPP]}
          </p>
        </div>
        <div className={'flex items-center gap-[12px]'}>
          <IconButton size={'small'} onClick={() => onClickEdit(CONTACT_TYPE.WHATSAPP)}>
            <img
              src={settingData?.[CONTACT_TYPE.WHATSAPP] ? ic_edit_pencil_line : ic_add_plus_circle}
              alt={'ic_add_plus_circle'}
            />
          </IconButton>
          {settingData?.[CONTACT_TYPE.WHATSAPP] && (
            <IconButton size={'small'} onClick={() => onClickDelete(CONTACT_TYPE.WHATSAPP)}>
              <img src={ic_trash_full} alt={'ic_trash_full'} />
            </IconButton>
          )}
        </div>
      </div>

      {/*---Telegram---*/}
      <div className={'p-[16px] md:p-[24px] bg-neutral-50 rounded-[16px] mt-[12px] flex justify-between items-center'}>
        <div>
          <p className={'font-maison-neue-medium text-16-20 text-neutral-800'}>Telegram</p>
          <p className={'font-maison-neue text-14-18 text-neutral-600 mt-[4px]'}>
            {settingData?.[CONTACT_TYPE.TELEGRAM]}
          </p>
        </div>
        <div className={'flex items-center gap-[12px]'}>
          <IconButton size={'small'} onClick={() => onClickEdit(CONTACT_TYPE.TELEGRAM)}>
            <img
              src={settingData?.[CONTACT_TYPE.TELEGRAM] ? ic_edit_pencil_line : ic_add_plus_circle}
              alt={'ic_add_plus_circle'}
            />
          </IconButton>
          {settingData?.[CONTACT_TYPE.TELEGRAM] && (
            <IconButton size={'small'} onClick={() => onClickDelete(CONTACT_TYPE.TELEGRAM)}>
              <img src={ic_trash_full} alt={'ic_trash_full'} />
            </IconButton>
          )}
        </div>
      </div>
    </>
  )
}

export default SettingContact
