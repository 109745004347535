import { FormError } from '../../interfaces'
import { CONNECT_BY } from '../connect-wallet/ConnectWalletProvider'
import BasicButton from '../ui/BasicButton'
import BasicInput from '../ui/BasicInput'
import ConfirmEmail from './ConfirmEmail'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { getUserProfileWithToken, sendEmail, statusEmail } from 'src/api/user'
import { ConfirmEmailData, SendEmailData } from 'src/interfaces/user'
import { genClientID, getTimeZoneName, handleErrorMessage, isEmailValid, isEmpty } from 'src/utils/common'
import { saveAccessToken, saveRefreshToken, saveUserInfo } from 'src/utils/user'

let interval: any = null

interface Props {
  handleLoginSuccess: any
}

const ConnectViaEmail = ({ handleLoginSuccess }: Props) => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const searchParams = queryString.parse(search)

  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  // const [errorMsg, setErrorMsg] = useState<string>('')
  const [error, setError] = useState<FormError>({
    show: false,
    message: '',
  })

  useEffect(() => {
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (searchParams.email) {
      navigate(`/login`, { replace: true })
      setEmail(searchParams?.email as string)
      setOpen(true)
      handleSendEmail(searchParams?.email as string)
    } else if (searchParams?.['email-login']) {
      setEmail((searchParams?.['email-login'] as string) || '')
    }
  }, [searchParams])

  const handleClose = () => {
    setOpen(false)
    setEmail('')
  }
  const handleOpen = () => {
    setOpen(true)
  }

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim()
    setEmail(value)
    setError({ show: false, message: '' })
  }

  const onBlurEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim()
    if (!isEmpty(value) && !isEmailValid(value)) {
      setError({ show: true, message: 'Please enter a valid email.' })
    }
  }

  const handleTriggerStatus = (data: ConfirmEmailData) => {
    if (interval) clearInterval(interval)
    interval = setInterval(async () => {
      const resStatus: any = await statusEmail(data)
      if (resStatus?.data && resStatus?.success) {
        if (interval) clearInterval(interval)
        saveUserInfo({ ...resStatus.data })
        saveAccessToken(resStatus.data.token)
        saveRefreshToken(resStatus.data.refreshToken)
        const profile = await getUserProfileWithToken(resStatus.data.token)
        const newProfile = { ...resStatus.data, ...profile.data, connectBy: CONNECT_BY.EMAIL }
        saveUserInfo(newProfile)
        handleLoginSuccess()
        handleClose()
      }
    }, 500)
  }
  const handleSendEmail = async (newEmail?: string) => {
    setLoading(true)
    const grecaptcha = (window as any).grecaptcha
    if (grecaptcha) {
      const recapchaToken = await grecaptcha.execute(process.env.REACT_APP_RECAPCHA_SITE_KEY, { action: 'submit' })
      const clientToken = genClientID()
      try {
        let bodyData: SendEmailData = {
          email: newEmail || email,
          type: 'host',
          timezone: getTimeZoneName(),
          clientToken,
        }
        if (searchParams?.ID) bodyData = { ...bodyData, referralRef: searchParams?.ID as string }
        const res: any = await sendEmail(
          {
            ...bodyData,
          },
          recapchaToken
        )
        if (res.data) {
          handleOpen()
          handleTriggerStatus({ token: res?.data?.token, clientToken })
        }
      } catch (err: any) {
        handleErrorMessage(err)
      } finally {
        setTimeout(() => {
          setLoading(false)
        }, 300)
      }
    } else {
      console.error(`grecaptcha doesn't exist`)
      setLoading(false)
    }
  }

  const isDisabled = !(!isEmpty(email) && isEmailValid(email)) || loading

  return (
    <>
      <div className="flex flex-col md:flex-row gap-[16px] items-start">
        <div className={'w-full'}>
          <BasicInput
            label={'Connect with email'}
            placeholder={'Email address'}
            value={email}
            onChange={onChangeEmail}
            onBlur={onBlurEmail}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter' && !isDisabled) handleSendEmail()
            }}
            error={error}
          />
        </div>
        <div className={'w-full md:w-auto'}>
          <div className={'hidden md:block h-[24px]'} />
          <BasicButton
            size={'xl'}
            variant={'contained'}
            color={'red'}
            clases={'w-full md:w-auto'}
            disabled={isDisabled || loading}
            onClick={() => {
              if (!isDisabled && !loading) handleSendEmail()
            }}
            loading={loading}
            isRadius100={true}
          >
            Connect
          </BasicButton>
        </div>
      </div>
      {/*{errorMsg && <span className={'text-red pt-[8px] ml-2 text-12-12'}>{errorMsg}</span>}*/}
      <ConfirmEmail open={open} handleClose={handleClose} email={email} />
    </>
  )
}

export default ConnectViaEmail
