import { useAppSelector } from '../../redux/hooks'
import { setBulkListingData, setBulkListingSection, setExternalNameNativeListing } from '../../redux/slices/listing'
import { handleErrorMessage, isEmpty } from '../../utils/common'
import DisabledSectionOnEdit from '../common/DisabledSectionOnEdit'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { updateBulkProperties, updateProperty } from 'src/api/native-listing'
import NativeListingBackAndContinue from 'src/components/native-listing/NativeListingBackAndContinue'
import BasicInput from 'src/components/ui/BasicInput'
import BasicTextarea from 'src/components/ui/BasicTextarea'
import { NATIVE_LISTING_MENU, PMS_TYPE } from 'src/contants/common'
import { PROPERTY_STATUS } from 'src/contants/native'
import { PropertyDetailProps } from 'src/interfaces/listing'
import { setLoading, setToastSuccess } from 'src/redux/slices/common'
import { isPropertyAirbnb } from 'src/utils/property'

interface Props {
  propertyId: string
  listingData: PropertyDetailProps | null
  fetchStatusStep: any
}

const NativeListingDescription: React.FC<Props> = ({ propertyId, listingData, fetchStatusStep }) => {
  const isDraft = listingData?.status === PROPERTY_STATUS.DRAFT
  const isAirbnb = isPropertyAirbnb(listingData)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isOnboarding = parseQuery?.f === 'onboarding'
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const { isLoading } = useAppSelector((state) => state.common)
  const { bulkListingData, bulkListingSection } = useAppSelector((state) => state.listing)
  const [data, setData] = useState<any>({
    internalName: '',
    externalName: '',
    description: '',
  })
  const [defaultName, setDefaultName] = useState<string>('')
  const [toggleEdit, setToggleEdit] = useState<any>({ description: false })
  const isHospitable = listingData?.pmsType === PMS_TYPE.HOSPITABLE

  useEffect(() => {
    if (listingData) {
      const { externalName, internalName, description, propertyTypeName, address } = listingData

      const defaultName = `${propertyTypeName} in ${address?.city}`
      setDefaultName(defaultName)
      setData({
        externalName: isAirbnb ? externalName : externalName || defaultName,
        internalName: isAirbnb ? internalName : internalName || defaultName,
        description: description || '',
      })
    }
  }, [listingData, isAirbnb])
  useEffect(() => {
    if (!isEmpty(bulkListingData) && isEditBulk) {
      setData({
        externalName: bulkListingData?.externalName || '',
        internalName: bulkListingData?.internalName || '',
        description: bulkListingData?.description || '',
      })
    }
  }, [bulkListingData])
  const handleChangeData = (field: string, value: string) => {
    setData((prevState: any) => {
      return { ...prevState, [field]: value }
    })
  }

  const handleSubmit = async (isExit?: boolean) => {
    try {
      dispatch(setLoading(true))
      const bodyData: any = { ...data }
      if (isDraft) {
        bodyData.settingSteps = 6
      }
      if (!data.externalName && !isAirbnb) {
        bodyData.externalName = defaultName
      }
      if (!data.internalName && !isAirbnb) {
        bodyData.internalName = defaultName
      }
      let res: any = null
      if (isEditBulk) {
        if (toggleEdit.description) {
          const propertyIds = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
          let dataDTO: any = { propertyIds, propertyInfo: bodyData }
          dispatch(setBulkListingData({ ...bulkListingData, ...bodyData }))
          res = await updateBulkProperties(dataDTO)
          setToggleEdit({ description: false })
        }
      } else {
        res = await updateProperty(propertyId, bodyData)
      }
      if (res?.data) {
        if (isExit) navigate(isOnboarding ? '/' : `/listings`)
        else if (isDraft) navigate(`/native-listing/${NATIVE_LISTING_MENU.AVAILABILITY}` + search)
        else dispatch(setToastSuccess({ message: 'Your changes have been applied.' }))
        fetchStatusStep()
      }
      if (res?.success && res?.data) {
        dispatch(setExternalNameNativeListing(res.data?.externalName || ''))
        setData((prevState: any) => ({
          ...prevState,
          externalName: bodyData.externalName,
          internalName: bodyData.internalName,
        }))
        fetchStatusStep()
        if (isExit) navigate(isOnboarding ? '/' : `/listings`)
        else if (isDraft) navigate(`/native-listing/${NATIVE_LISTING_MENU.HOUSE_RULES}` + search)
        else dispatch(setToastSuccess({ message: 'Your changes have been applied.' }))
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      dispatch(setLoading(false))
    }
  }

  return (
    <>
      <div className="flex flex-col gap-8">
        <p className="text-28-36 text-neutral-800 font-maison-neue tracking-[-0.01em]">
          {isEditBulk ? `Let's describe your listings` : `Let's describe your listing`}
        </p>
        <DisabledSectionOnEdit
          isDisable={isHospitable}
          title="Description"
          openEdit={toggleEdit.description}
          handleChangeEdit={(value: boolean) => setToggleEdit({ ...toggleEdit, description: value })}
        >
          <div className="flex flex-col bg-white p-4 rounded-[16px] mb-8">
            <p className={'text-neutral-800 text-14-18 font-maison-neue-medium mb-2'}>Public title</p>
            <BasicInput
              placeholder="Add a public title..."
              value={data.externalName}
              onChange={(event) => handleChangeData('externalName', event.target.value)}
              maxLength={50}
            />
            <p className="text-14-18 text-neutral-500 font-maison-neue mt-2">
              50 characters max. Short titles work better
            </p>

            <p className={'text-neutral-800 text-14-18 font-maison-neue-medium mb-2 mt-4'}>Internal title</p>
            <BasicInput
              placeholder="Add a title..."
              value={data.internalName}
              onChange={(event) => handleChangeData('internalName', event.target.value)}
              maxLength={40}
            />
            <p className="text-14-18 text-neutral-500 font-maison-neue mt-2">
              40 characters max. Guests will not see this
            </p>

            <p className={'text-neutral-800 text-14-18 font-maison-neue-medium mb-2 mt-4'}>Description</p>
            <BasicTextarea
              placeholder="Describe your place..."
              rows={8}
              value={data.description}
              onChange={(event) => handleChangeData('description', event.target.value)}
              maxLength={20000}
            />
            {/*<p className="text-14-18 text-neutral-500 font-maison-neue mt-2">*/}
            {/*  500 characters max. Share what makes your place special*/}
            {/*</p>*/}
          </div>
        </DisabledSectionOnEdit>
      </div>

      <NativeListingBackAndContinue
        isLoading={isLoading}
        onSubmit={handleSubmit}
        isEdit={!isDraft}
        isDisabledSubmit={isEditBulk ? !toggleEdit.description : false}
      />
      <div
        id={`${NATIVE_LISTING_MENU.DESCRIPTION}_save_exit`}
        className="hidden"
        onClick={() => handleSubmit(true)}
        role="presentation"
      />
      <div
        id={`${NATIVE_LISTING_MENU.DESCRIPTION}_save`}
        className="hidden"
        onClick={() => {
          if (isEditBulk) {
            if (isEmpty(bulkListingSection) && toggleEdit.description) {
              let result: string[] = []
              if (toggleEdit.description) result.push('Description')
              dispatch(setBulkListingSection(result.join(', ')))
            } else handleSubmit()
          } else handleSubmit()
        }}
        role="presentation"
      />
    </>
  )
}

export default NativeListingDescription
