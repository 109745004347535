import { handleErrorMessage } from '../../../utils/common'
import React, { useEffect, useState } from 'react'
import { updateHostSettings } from 'src/api/user'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import BasicInput from 'src/components/ui/BasicInput'
import BasicTextarea from 'src/components/ui/BasicTextarea'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { setSettingData } from 'src/redux/slices/user'

interface Props {
  field: 'gTag' | 'pixel' | 'metaTitle' | 'metaDescription'
  isRequired?: boolean
}

const MAPPING_FIELD_TO_CONTENT: any = {
  gTag: {
    title: 'Google Analytics Tracking ID',
    desciption: 'Get insights into your site’s performance.',
    popupTitle: 'Google Analytics Tracking ID',
    popupDescription: 'Get insights into your site’s performance.',
    fieldLabel: 'Google Tracking ID',
    maxLength: 20,
  },
  pixel: {
    title: 'Facebook/Meta Pixel',
    desciption: 'Measure, optimize, and build audiences for your ad campaigns across Facebook and Instagram.',
    popupTitle: 'Facebook/Meta Pixel',
    popupDescription: 'Measure, optimize, and build audiences for your ad campaigns across Facebook and Instagram.',
    fieldLabel: 'Meta Pixel ID',
    maxLength: 20,
  },
  metaTitle: {
    title: 'Meta Title',
    desciption: 'Increase organic traffic and bring more eyes to your site. We recommend between 30-60 characters.',
    popupTitle: 'Meta Title',
    popupDescription:
      'Increase organic traffic and bring more eyes to your site. We recommend between 30-60 characters.',
    fieldLabel: 'Meta title',
    maxLength: 80,
    showCount: true,
  },
  metaDescription: {
    title: 'Meta Description',
    desciption: 'Improve click-through rates. We recommend between 120-160 characters.',
    popupTitle: 'Meta Description',
    popupDescription: 'Improve click-through rates. We recommend between 120-160 characters.',
    fieldLabel: 'Meta description',
    maxLength: 200,
    showCount: true,
  },
}

const SettingAnalyticsAndSeoItem: React.FC<Props> = ({ field, isRequired }) => {
  const dispatch = useAppDispatch()
  const { settingData } = useAppSelector((state) => state.user)
  const [value, setValue] = useState<string>('')
  const [currentValue, setCurrentValue] = useState<string>('')
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setValue(settingData?.[field] || '')
    setCurrentValue(settingData?.[field] || '')
  }, [settingData?.[field]])

  const handleOpen = () => {
    setIsOpenPopup(true)
  }

  const handleClose = () => {
    setIsOpenPopup(false)
    setValue(settingData?.[field] || '')
  }

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value)
  }

  const handleSave = async () => {
    try {
      setIsLoading(true)
      const data: any = { [field]: value }
      const res = await updateHostSettings(data)
      if (res.success && res.data) {
        dispatch(setToastSuccess({ message: `Update success!` }))
        dispatch(setSettingData({ ...settingData, ...res.data }))
        handleClose()
      }
    } catch (error: any) {
      handleErrorMessage(error)
    } finally {
      setIsLoading(false)
    }
  }
  const renderAddButton = () => (
    <BasicButton size={'md'} variant={'outlined'} clases={'w-full md:w-auto'} isRadius100 onClick={handleOpen}>
      {settingData?.[field] ? 'Update' : 'Add'}
    </BasicButton>
  )
  return (
    <>
      <div className={'flex flex-col md:flex-row justify-between items-start gap-[12px]'}>
        <div>
          <p className={'font-maison-neue-medium text-neutral-800 text-16-20'}>
            {MAPPING_FIELD_TO_CONTENT[field]?.title}
          </p>
          <p className={'font-maison-neue text-neutral-600 text-14-18 mt-[4px]'}>
            {MAPPING_FIELD_TO_CONTENT[field]?.desciption}
          </p>
        </div>
        {/* For Desktop, Table */}
        <div className={'hidden md:block'}>{renderAddButton()}</div>

        <BasicDialog
          isOpen={isOpenPopup}
          title={MAPPING_FIELD_TO_CONTENT[field]?.popupTitle}
          extraTitle={MAPPING_FIELD_TO_CONTENT[field]?.popupDescription}
          onClose={handleClose}
          allowBackdropClick
          titleAlign={'center'}
        >
          {field === 'metaDescription' ? (
            <BasicTextarea
              label={MAPPING_FIELD_TO_CONTENT[field]?.fieldLabel}
              value={value}
              onChange={onChangeValue}
              onBlur={() => setValue((prevState) => prevState.trim())}
              rows={4}
              maxLength={MAPPING_FIELD_TO_CONTENT[field]?.maxLength}
              showCount={MAPPING_FIELD_TO_CONTENT[field]?.showCount}
            />
          ) : (
            <BasicInput
              label={MAPPING_FIELD_TO_CONTENT[field]?.fieldLabel}
              value={value}
              onChange={onChangeValue}
              onBlur={() => setValue((prevState) => prevState.trim())}
              maxLength={MAPPING_FIELD_TO_CONTENT[field]?.maxLength}
              showCount={MAPPING_FIELD_TO_CONTENT[field]?.showCount}
            />
          )}

          <BasicButton
            variant={'contained'}
            color={'red'}
            clases={'w-full mt-[24px]'}
            isRadius100={true}
            onClick={handleSave}
            loading={isLoading}
            disabled={isRequired && !value}
          >
            Save
          </BasicButton>
        </BasicDialog>
      </div>

      {currentValue && (
        <div
          className={
            'mt-[16px] bg-neutral-50 rounded-[16px] p-[16px] md:p-[24px] flex flex-col md:flex-row items-start md:items-center gap-[16px] md:gap-[24px] ' +
            'font-maison-neue text-neutral-600 text-14-18'
          }
        >
          {currentValue}
        </div>
      )}
      {/* For Mobile */}
      <div className={'block md:hidden w-full mt-[12px]'}>{renderAddButton()}</div>
    </>
  )
}

export default SettingAnalyticsAndSeoItem
