import { updateHostSettings } from '../../../api/user'
import { ONBOARDING_STEP } from '../../../contants/user'
import { FormError } from '../../../interfaces'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { handleNextOnboardingStep, setSettingData, updateOnboardingData } from '../../../redux/slices/user'
import { handleErrorMessage } from '../../../utils/common'
import { IconCircleWarning } from '../../common/Icons'
import BasicButton from '../../ui/BasicButton'
import BasicInput from '../../ui/BasicInput'
import BasicRadio from '../../ui/BasicRadio'
import LayoutOnboarding from '../LayoutOnboarding'
import RightContentIdentity from './RightContentIdentity'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

const BootstrapTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#ffffff',
    boxShadow: '0px 20px 25px -5px rgba(0, 0, 0, 0.08), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff', // neutral-8
    padding: '8px 12px',
    borderRadius: '8px',
    color: '#50504E',
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'Maison Neue Medium',
    boxShadow: '0px 20px 25px -5px rgba(0, 0, 0, 0.08), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
    width: '220px',
  },
}))

export const OPTIONS = [
  {
    value: 'owner',
    title: 'Owner',
    desc: 'I mostly manage my own properties',
  },
  {
    value: 'property_manager',
    title: 'Property manager',
    desc: 'I mostly manage properties on behalf of others',
  },
]

const OnboardingPersona = () => {
  const dispatch = useAppDispatch()
  const { settingData } = useAppSelector((state) => state.user)
  const [inventory, setInventory] = useState<number>()
  const [value, setValue] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [inventoryError, setInventoryError] = useState<FormError>({ show: false, message: '' })

  useEffect(() => {
    if (settingData) {
      setValue(settingData.persona || '')
      setInventory(settingData.inventory || undefined)
    }
  }, [settingData])

  const onSubmit = async () => {
    if (!inventory) {
      setInventoryError({ show: true, message: 'Pleae enter number of listings' })
      return
    }
    const isInteger = Number.isInteger(Number(inventory))
    if (!isInteger || inventory < 1 || inventory > 999999) {
      setInventoryError({ show: true, message: 'Number of listings must be an integer value between 1 and 999999' })
      return
    }
    try {
      setIsLoading(true)
      const bodyData = { persona: value, inventory: Number(inventory) }
      const resSetting = await updateHostSettings(bodyData)
      if (resSetting.data) {
        dispatch(setSettingData(resSetting.data))
      }
      dispatch(updateOnboardingData(bodyData))
      dispatch(handleNextOnboardingStep(ONBOARDING_STEP.INTEREST))
    } catch (err) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const renderSubmitBtn = () => {
    return (
      <BasicButton
        variant={'contained'}
        isRadius100={true}
        color={'red'}
        size={'xl'}
        onClick={onSubmit}
        disabled={!value || !inventory}
        loading={isLoading}
      >
        Continue
      </BasicButton>
    )
  }

  return (
    <LayoutOnboarding headerTitle={'Persona'} rightContent={<RightContentIdentity />}>
      <div className={'mt-[38px] lg:mt-[70px] w-full mx-auto md:max-w-[524px]'}>
        <h2 className={'font-maison-neue text-24-36 text-neutral-800 mb-[16px]'}>Which best describes you?</h2>
        <div className={'flex flex-col gap-[16px]'}>
          {OPTIONS.map((item) => {
            return (
              <label
                key={item.value}
                className={clsx(
                  'flex justify-between items-center gap-[16px] rounded-[16px] cursor-pointer bg-white p-[24px]',
                  'border border-neutral-300',
                  {
                    'border-[2px] border-neutral-800': value === item.value,
                  }
                )}
                htmlFor={`persona_${item.value}`}
              >
                <div className={'flex flex-col gap-[4px]'}>
                  <span className={'font-maison-neue text-16-24 text-neutral-800'}>{item.title}</span>
                  <span className={'font-maison-neue text-14-20 text-neutral-500'}>{item.desc}</span>
                </div>
                <BasicRadio
                  id={`persona_${item.value}`}
                  type={'radio'}
                  classes={'w-[24px] h-[24px] cursor-pointer'}
                  name={'persona_describe'}
                  value={item.value}
                  checked={value === item.value}
                  onChange={(event) => {
                    setValue(event.target.value)
                    dispatch(updateOnboardingData({ persona: event.target.value }))
                  }}
                />
              </label>
            )
          })}
        </div>

        <div className={`mt-[24px] ${value ? 'block' : 'hidden'}`}>
          <p className={'mb-[8px] font-maison-neue text-neutral-800 text-16-24 flex justify-between items-center'}>
            <span>How many listings do you currently manage?</span>
            <span>
              <BootstrapTooltip
                title={'Unique units that had a check-in within the last 30 days'}
                arrow={true}
                placement={'top-end'}
              >
                <span>
                  <IconCircleWarning width={16} height={16} color={'black'} />
                </span>
              </BootstrapTooltip>
            </span>
          </p>
          <BasicInput
            type={'number'}
            classes={'inputNumberHideArrow'}
            placeholder={'Specify a number...'}
            value={inventory}
            onChange={(event) => {
              const value = event.target.value
              const _num = value === '' ? undefined : Number(value)
              setInventory(_num)
              setInventoryError({ show: false, message: '' })
              dispatch(updateOnboardingData({ inventory: _num }))
            }}
            error={inventoryError}
          />
        </div>

        <div className={'mt-[24px] hidden lg:block'}>{renderSubmitBtn()}</div>
      </div>

      <div
        className={clsx(
          'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
          'border-t border-neutral-300 px-4 md:px-8',
          'shadow-footer'
        )}
      >
        {renderSubmitBtn()}
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingPersona
