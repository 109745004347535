import { handleErrorMessage, isEmpty } from '../../../utils/common'
import IconButton from '@mui/material/IconButton'
import moment, { Moment } from 'moment'
import React, { useState } from 'react'
import { updateMinimumStay } from 'src/api/listings'
import ic_minus from 'src/assets/icons/ic_minus.svg'
import ic_plus from 'src/assets/icons/ic_plus.svg'
import BasicCalendar from 'src/components/common/BasicCalendar'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialogCalendar from 'src/components/ui/BasicDialogForCalendar'
import { DATE_FORMAT } from 'src/contants/common'
import { MAX_NIGHTS, MIN_NIGHTS } from 'src/contants/native'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { useAppDispatch } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { updateBulkProperties } from 'src/api/native-listing'
import EditBulkConfirmDialog from '../EditBulkConfirmDialog'

interface Props {
  propertyId: string
  isOpen: boolean
  onClose: () => void
  isResetCalendar?: boolean
  fetchMinimumStay?: (_propertyId: string) => void
  currentDateRanges: any[]
  globalMinNights: number
  fetchStatusStep: any
  updateDateRanges: any
  handleCloseEdit?: any
}

const AddMinimumStayDateRange: React.FC<Props> = ({
  propertyId,
  isOpen,
  onClose,
  currentDateRanges,
  fetchMinimumStay,
  globalMinNights,
  fetchStatusStep,
  updateDateRanges,
  handleCloseEdit
}) => {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768
  const [startDate, setStartDate] = useState<Moment | null>(null)
  const [endDate, setEndDate] = useState<Moment | null>(null)
  const [minNights, setMinNights] = useState<number>(1)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)

  const onChangeMinNights = (type: 'plus' | 'minus') => {
    if (type === 'plus') {
      setMinNights((prevState) => (prevState === MAX_NIGHTS ? MAX_NIGHTS : prevState + 1))
    } else {
      setMinNights((prevState) => (prevState === MIN_NIGHTS ? MIN_NIGHTS : prevState - 1))
    }
  }

  const onClosePopup = () => {
    onClose()
    onClearDates() // clear start date, end date
  }

  const onClearDates = () => {
    setStartDate(null)
    setEndDate(null)
  }

  const onDatesChange = (_startDate: Moment | null, _endDate: Moment | null) => {
    setStartDate(_startDate)
    setEndDate(_endDate)
  }

  const handleNextAndPrevCalendar = () => { }

  const onSubmit = async () => {
    try {
      setIsLoadingSubmit(true)
      const oldRanges = Array.isArray(currentDateRanges) ? [...currentDateRanges] : []
      const newItem = {
        startDate: moment(startDate).format(DATE_FORMAT),
        endDate: moment(endDate).format(DATE_FORMAT),
        minNights: minNights,
      }
      if (isEditBulk) {
        const propertyIds = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
        let dataDTO: any = {
          propertyIds,
          minNightInfo: {
            dateRanges: [{ ...newItem }],
            insert: { ...newItem },
          },
        }
        if (globalMinNights !== -1) dataDTO.minNightInfo.minNights = globalMinNights
        await updateBulkProperties(dataDTO)
        updateDateRanges([{ ...newItem }])
        if (handleCloseEdit) handleCloseEdit()
      } else {
        const bodyData = {
          listingId: Number(propertyId),
          dateRanges: [...oldRanges, { ...newItem }],
          minNights: globalMinNights,
          insert: { ...newItem },
        }
        await updateMinimumStay(bodyData)
      }
      dispatch(setToastSuccess({ message: 'Update minimum stay successfully' }))
      if (typeof fetchMinimumStay === 'function' && propertyId) {
        await fetchMinimumStay(propertyId)
      }
      onClosePopup()
      fetchStatusStep()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const renderMinNightSection = () => {
    return (
      <div className={'flex items-center justify-between gap-[8px] w-full md:w-auto'}>
        <IconButton size={'small'} onClick={() => onChangeMinNights('minus')} disabled={minNights === 1}>
          <img src={ic_minus} alt={''} className={'w-[36px] h-[36px] min-w-[36px]'} />
        </IconButton>
        <div className={'w-[64px] min-w-[64px] text-center font-maison-neue text-24-36 text-neutral-800'}>
          {minNights}
        </div>
        <IconButton size={'small'} onClick={() => onChangeMinNights('plus')} disabled={minNights === 100}>
          <img src={ic_plus} alt={''} className={'w-[36px] h-[36px] min-w-[36px]'} />
        </IconButton>
      </div>
    )
  }

  return (
    <>
      <BasicDialogCalendar
        isOpen={isOpen}
        onClose={onClosePopup}
        title={'Set the minimum stay for a date range'}
        extraTitle={'Select a date range and define the minimum amount of nights guests should book'}
      >
        <div className={'h-[382px] p-[24px] bg-neutral-100 shadow-sm flex items-start justify-center'}>
          <BasicCalendar
            isShowDoubleMonth={!isMobile}
            startDate={startDate}
            endDate={endDate}
            onDatesChange={onDatesChange}
            dataCalendar={{}}
            onNextAndPrev={handleNextAndPrevCalendar}
          />
        </div>

        {/*  Footer*/}
        <div className={'flex flex-col gap-[16px] justify-between p-[24px] bg-white'}>
          <div className={'w-full md:w-auto'}>
            <span className={'font-maison-neue text-16-20 text-neutral-500'}>Selected dates:</span>&nbsp;
            <span className={'font-maison-neue text-16-20 text-neutral-800'}>
              {startDate ? moment(startDate).format('D MMMM YYYY') : ''} {startDate && endDate ? '-' : ''}{' '}
              {endDate ? moment(endDate).format('D MMMM YYYY') : ''}
            </span>
          </div>
          <div className={'block md:hidden'}>{renderMinNightSection()}</div>
          <div className={'flex justify-between gap-[8px]'}>
            <div className={'w-[50%] md:w-auto'}>
              <BasicButton
                variant={'outlined'}
                isRadius100={true}
                onClick={onClearDates}
                disabled={!startDate && !endDate}
                clases={'w-full md:w-auto'}
              >
                Clear dates
              </BasicButton>
            </div>
            <div className={'flex flex-col md:flex-row items-center justify-between gap-[16px] w-[50%] md:w-auto'}>
              <div className={'hidden md:block'}>{renderMinNightSection()}</div>
              <BasicButton
                variant={'contained'}
                isRadius100={true}
                color={'red'}
                onClick={() => {
                  if (isEditBulk) {
                    setOpenConfirm(true)
                    onClose()
                  }
                  else onSubmit()
                }}
                loading={isLoadingSubmit}
                disabled={!startDate || !endDate}
                clases={'w-full md:w-auto'}
              >
                Set as minimum
              </BasicButton>
            </div>
          </div>
        </div>
      </BasicDialogCalendar>
      <EditBulkConfirmDialog
        openConfirm={openConfirm}
        handleClose={() => {
          setOpenConfirm(false)
          onClosePopup()
        }}
        handleSubmit={onSubmit}
      />
    </>
  )
}

export default AddMinimumStayDateRange
