import { ACCOUNT_TYPE } from '../contants/user'
import {
  ConfirmEmailData,
  ConfirmUnifyWalletData,
  SendEmailData,
  UnifyProfileSelectData,
  UnifyRequestData,
  UserProfile,
} from '../interfaces/user'
import Api from './index'
import { getCookie } from 'cookies-next'
import queryString from 'query-string'

export const checkUserExisted = async (address: string) => {
  const res = await Api.get('account-service/v1/user', {
    params: {
      address,
      type: ACCOUNT_TYPE.HOST,
    },
  })
  return res.data
}

export const registerUser = async (address: string) => {
  const bodyData: any = {
    address,
    type: ACCOUNT_TYPE.HOST,
  }
  const toltReferral = getCookie('tolt_referral')
  if (toltReferral) {
    bodyData.toltReferral = toltReferral
  }
  const res = await Api.post('account-service/v1/user', bodyData)
  return res.data
}

export const authenUser = async (address: string, signature: any) => {
  const res = await Api.post('account-service/v1/auth/authentication', {
    address,
    signature,
    type: ACCOUNT_TYPE.HOST,
  })
  return res.data
}

export const getRefreshTokenApi = async (refreshToken: string) => {
  const res = await Api.post('account-service/v1/auth/refresh-token', {
    token: refreshToken,
  })
  return res.data
}

export const getUserProfile = async () => {
  const res = await Api.get('account-service/v1/user/profile')
  return res.data
}

export const getUserProfileWithToken = async (token: string) => {
  const res = await Api.get('account-service/v1/user/profile', {
    headers: { Authorization: 'Bearer ' + token },
  })
  return res.data
}

export const updateUserProfile = async (data: UserProfile) => {
  const res = await Api.post('account-service/v1/user/update-profile', data)
  return res.data
}

export const sendEmail = (data: SendEmailData, reCapchaToken?: string) => {
  const bodyData: any = { ...data }
  const toltReferral = getCookie('tolt_referral')
  if (toltReferral) {
    bodyData.toltReferral = toltReferral
  }
  const params = queryString.parse(window.location.search)
  const loginAs = params.view_as ? String(params.view_as) : ''
  if (loginAs) {
    bodyData.loginAs = loginAs
    bodyData.type = 'admin'
  }
  return Api.post('/account-service/v1/auth/email', bodyData, {
    headers: {
      recaptcha: reCapchaToken || '',
    },
  }).then((res) => res.data)
}

export const confirmEmail = async (data: ConfirmEmailData) => {
  const res = await Api.post('/account-service/v1/auth/confirm', data)
  return res.data
}

export const statusEmail = async (data: ConfirmEmailData) => {
  const res = await Api.post('/account-service/v1/auth/status', data)
  return res.data
}
export const uploadLogoHost = async (data: any) => {
  const res = await Api.post('/account-service/v1/user/update-logo', data)
  return res.data
}
export const sendVerifyContactEmail = async (data: any) => {
  const res = await Api.post('/account-service/v1/user/verify-contact-email', data)
  return res.data
}
export const confirmContactEmail = async (data: any) => {
  const res = await Api.post('/account-service/v1/user/confirm-contact-email', data)
  return res.data
}
export const sendVerifyContactPhone = async (data: any) => {
  const res = await Api.post('/account-service/v1/user/verify-contact-phone', data)
  return res.data
}
export const confirmContactPhone = async (data: any) => {
  const res = await Api.post('/account-service/v1/user/confirm-contact-phone', data)
  return res.data
}
export const addContactPhone = async (data: any) => {
  const res = await Api.post('/account-service/v1/user/add-contact-phone', data)
  return res.data
}
export const unifyRequest = async (data: UnifyRequestData) => {
  const res = await Api.post('/account-service/v1/user/unify-request', data)
  return res.data
}
export const unifyProfileSelect = (data: UnifyProfileSelectData) => {
  return Api.post('/account-service/v1/user/unify-profile-select', data).then((res) => res.data)
}
export const unifyOldProfileSelect = (selectedProfileId: string) => {
  return Api.post('/account-service/v1/user/selected-profile', { selectedProfileId }).then((res) => res.data)
}
export const confirmUnifyEmail = (data: ConfirmEmailData) => {
  return Api.post('/account-service/v1/user/unify-email-confirm', data).then((res) => res.data)
}
export const unifyEmailStatus = (data: ConfirmEmailData) => {
  return Api.post('/account-service/v1/user/unify-email-status', data).then((res) => res.data)
}
export const confirmUnifyWallet = (data: ConfirmUnifyWalletData) => {
  return Api.post('/account-service/v1/user/unify-wallet-confirm', data).then((res) => res.data)
}
export const createApiKey = async () => {
  const res = await Api.post('/account-service/v1/auth/client-id')
  return res.data
}
export const createCustomUrl = async (customUrl: string) => {
  const res = await Api.post('/account-service/v1/user/subdomain', { customUrl })
  return res.data
}
export const updateCustomUrl = async (customUrlId: string, customUrl: string) => {
  const res = await Api.post('/account-service/v1/user/subdomain/' + customUrlId, { customUrl })
  return res.data
}
export const updateHeadLineLandingPage = (data: { headline?: string; subHeadline?: string }) => {
  return Api.post('/account-service/v1/user/landing-page/setting/', data).then((res) => res.data)
}
export const updateGalleryLandingPage = (data: any) => {
  return Api.post('/account-service/v1/user/landing-page/gallery/', data).then((res) => res.data)
}
export const getSettingsLandingPage = async () => {
  return Api.get('/account-service/v1/user/landing-page/').then((res) => res.data)
}
export const updateCustomUrlPrimary = async (id: string) => {
  const res = await Api.post(`/account-service/v1/user/update-custom-url-primary/${id}`)
  return res.data
}
export const verifyCustomUrl = async (domain: string) => {
  const res = await Api.post(`/account-service/v1/dns/verify`, { domain })
  return res.data
}
export const getDNSTypeCustomUrl = async (domain: string) => {
  const res = await Api.post(`/account-service/v1/dns/type`, { domain })
  return res.data
}
export const deleteCustomUrl = async (id: string) => {
  const res = await Api.post(`/account-service/v1/user/delete-custom-url/${id}`)
  return res.data
}
export const uploadSettingImages = async (type: 'aboutUs' | 'favicon' | 'gallery', data: FormData) => {
  const res = await Api.post('/account-service/v1/user/setting/images', data, {
    params: { type },
  })
  return res.data
}
export const updateHostSettings = async (data: any) => {
  const res = await Api.post('/account-service/v1/user/landing-page/setting', data)
  return res.data
}
export const getHostSettings = async () => {
  const res = await Api.get('/account-service/v1/user/landing-page/')
  return res.data
}
export const updateStepOnboarding = async (step: string, listIdImporting?: string[], listIdEdited?: string[]) => {
  let data: { step: string; listIdImporting?: string[]; listIdEdited?: string[] } = { step: step }
  if (listIdImporting) {
    data.listIdImporting = listIdImporting
  }
  if (listIdEdited) {
    data.listIdEdited = listIdEdited
  }
  const res = await Api.post('/account-service/v1/user/step-onboarding/', data)
  return res.data
}
export const getStepSettings = async () => {
  const res = await Api.get('/account-service/v1/user/step-setting')
  return res.data
}
export const updateOnboardingInfo = async (data: any) => {
  const res = await Api.post('/account-service/v1/user/onboarding-info', data)
  return res.data
}

export const updateSmartWallet = async (data: any) => {
  const res = await Api.post('/account-service/v1/smart-wallet', data)
  return res.data
}
export const updateAccountEmail = async (data: { email: string; timezone: string }) => {
  const res = await Api.post('account-service/v1/user/update-account-email', data)
  return res.data
}
//https://api.dataismist.com/account-service/v1/user/confirm-account-email
export const confirmAccountEmail = async (data: { token: string; context: string }) => {
  const res = await Api.post('account-service/v1/user/confirm-account-email', data)
  return res.data
}

// BusinessUser
export const getBusinessUsers = async (params: any) => {
  const res = await Api.get('/account-service/v1/user/get-list-business-user', { params })
  return res.data
}
export const createBusinessUser = async (data: { email: string; name: string; role: string }) => {
  const res = await Api.post('/account-service/v1/user/create-business-user', data)
  return res.data
}
export const editBusinessUser = async (userId: string, data: { email: string; name: string; role: string }) => {
  const res = await Api.post(`/account-service/v1/user/edit-business-user/${userId}`, data)
  return res.data
}
export const deleteBusinessUser = async (data: string[]) => {
  const res = await Api.post(`/account-service/v1/user/delete-business-user`, data)
  return res.data
}
export const getBusinessAccounts = async () => {
  const res = await Api.get('/account-service/v1/user/get-business-accounts')
  return res.data
}
export const selectBusinessAccounts = async (businessId: string) => {
  const res = await Api.post('/account-service/v1/user/select-business-account', { businessId })
  return res.data
}
