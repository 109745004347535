import { importHospitableAirbnb } from '../../../api/integration'
import { updateStepOnboarding } from '../../../api/user'
import { ONBOARDING_STEP } from '../../../contants/user'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { setAirbnbChannelId, setImportRequestId } from '../../../redux/slices/listing'
import { handleNextOnboardingStep } from '../../../redux/slices/user'
import { handleErrorMessage } from '../../../utils/common'
import LoadingThreeDots from '../../common/LoadingThreeDots'
import RenderChooseListings from '../../property-import-airbnb/RenderChooseListings'
import BasicButton from '../../ui/BasicButton'
import LayoutOnboarding from '../LayoutOnboarding'
import RightContentPmsCm from './RightContentPmsCm'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

interface Props {
  editListings: any[]
  updateEditListings: (newListings: any[]) => void
  updateListIdImporting: (newListings: any[]) => void
}

const OnboardingAirbnbChooseListingHospitable: React.FC<Props> = ({ updateEditListings, updateListIdImporting }) => {
  const { onboardingStep } = useAppSelector((state) => state.user)
  const { airbnbChannelId } = useAppSelector((state) => state.listing)
  const { isFinishProperyAllHospitable, countPropertyFromHospitable } = useAppSelector((state) => state.common)
  const dispatch = useAppDispatch()
  const [prepareListings, setPrepareListings] = useState<Array<any>>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { airbnbViaHospitable } = useAppSelector((state) => state.user)

  useEffect(() => {
    if (Array.isArray(airbnbViaHospitable) && airbnbViaHospitable.length > 0) {
      dispatch(setAirbnbChannelId(airbnbViaHospitable[0]?.channelId))
    }
  }, [])

  const updatePrepareListings = (newListings: any[]) => {
    setPrepareListings(newListings)
  }

  const onSubmit = async (channelId: string) => {
    const selectedListings = prepareListings.filter((item) => item.checked)
    const payload = selectedListings.map((item) => {
      return {
        dtravelPropertyId: Number(item.dtravelPropertyId),
        pmsPropertyId: item.pmsPropertyId,
        platformId: item.platformId,
      }
    })
    try {
      setIsLoading(true)
      const res = await importHospitableAirbnb({ selectedIds: payload, channelId })
      dispatch(setImportRequestId(res.data?.requestId))
      // handle save current selected ids to DB (for case user logout this step -> lost requestId -> can't show Edit listing screen)
      const arrSteps = onboardingStep.split(', ').filter((item: string) => item !== '')
      arrSteps.push(ONBOARDING_STEP.AIRBNB_EDIT_PROMPT)
      const newStep = arrSteps.join(', ')
      const listIdImporting = payload.map((item) => item.pmsPropertyId)
      await updateStepOnboarding(newStep, listIdImporting)
      updateListIdImporting(listIdImporting)
      updateEditListings(selectedListings)
      // next step
      nextStep()
      // await fetchImportProgress(res.data?.requestId, selectedListings)
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      // setIsLoading(false)
    }
  }

  const isSubmitDisabled = () => {
    return prepareListings.filter((item: any) => item.checked).length === 0
  }

  const nextStep = () => {
    dispatch(handleNextOnboardingStep(ONBOARDING_STEP.AIRBNB_EDIT_PROMPT))
  }

  const handleSkip = () => {
    if (countPropertyFromHospitable > 0) {
      // redirect to edit page
      nextStep()
    } else {
      // redirect to site detail
      dispatch(handleNextOnboardingStep(ONBOARDING_STEP.SITE_DETAILS_SITE_NAME))
    }
  }

  const renderSubmitBtn = () => {
    const countSelected = prepareListings.filter((item) => item.checked).length
    return (
      <div className={'flex gap-[16px]'}>
        <BasicButton
          variant={'contained'}
          isRadius100={true}
          color={'red'}
          size={'xl'}
          onClick={() => onSubmit(airbnbChannelId)}
          loading={isLoading}
          disabled={isSubmitDisabled()}
        >
          {isLoading ? (
            <span className={'flex items-center gap-[8px]'}>
              <LoadingThreeDots color={'#ffffff'} />
              <span>{`Import ${countSelected} listing(s)`}</span>
            </span>
          ) : (
            `Import ${countSelected} listing(s)`
          )}
        </BasicButton>

        {isFinishProperyAllHospitable && prepareListings.length === 0 && (
          <BasicButton
            isRadius100={true}
            size={'xl'}
            onClick={handleSkip}
            variant={'contained'}
            clases={
              'w-auto h-[48px] rounded-[100px_!important] border border-neutral-300 shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]'
            }
            color="white"
          >
            Skip
          </BasicButton>
        )}
      </div>
    )
  }

  return (
    <LayoutOnboarding headerTitle={'Listings'} rightContent={<RightContentPmsCm />}>
      <div className={'mt-[38px] lg:mt-[70px] w-full mx-auto md:max-w-[524px] pb-[112px]'}>
        <h2 className={'font-maison-neue text-24-36 text-neutral-800 mb-[24px]'}>
          Choose which listings to add to Dtravel Direct
        </h2>

        <RenderChooseListings
          listings={prepareListings}
          updateListings={updatePrepareListings}
          allowFetchListing={true}
        />

        <div className={'mt-[24px] hidden lg:block'}>{renderSubmitBtn()}</div>
      </div>

      <div
        className={clsx(
          'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
          'border-t border-neutral-300 px-4 md:px-8',
          'shadow-footer'
        )}
      >
        {renderSubmitBtn()}
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingAirbnbChooseListingHospitable
