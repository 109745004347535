import ic_credit_card from '../../assets/icons/ic_credit_card.svg'
import ic_ethereum from '../../assets/icons/ic_ethereum.svg'
import ic_ical_alert from '../../assets/icons/ic_ical_alert.svg'
import ic_off from '../../assets/icons/ic_off.svg'
import ic_on from '../../assets/icons/ic_on.svg'
import ic_warning_info from '../../assets/icons/ic_warning_info.svg'
import no_image from '../../assets/images/no_image_listing.jpg'
import { SUPERHOG_STATUS } from '../../contants/property'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { ListingItem } from '../../interfaces/listing'
import { isEmpty } from '../../utils/common'
import { renderLocation, renderSourceType } from '../../utils/property'
import BasicCheckbox from '../ui/BasicCheckbox'
import BasicTooltip from '../ui/BasicTooltip'
import BasicTooltipWhite from '../ui/BasicTooltipWhite'
import PropertyCheckAll from './PropertyCheckAll'
import PropertyEdit from './PropertyEdit'
import PropertyStatus from './PropertyStatus'
import RenderSyncStatus from './RenderSyncStatus'
import TableCellCustom from './TableCellCustom'
import TablePaging from './TablePaging'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import clsx from 'clsx'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { NATIVE_LISTING_MENU, NATIVE_LISTING_STEPS } from 'src/contants/common'
import { PROPERTY_SOURCE, PROPERTY_STATUS } from 'src/contants/native'

interface Props {
  isEnableGVR: boolean
  properties: ListingItem[]
  allProperties: ListingItem[]
  paging: { page: number; pageSize: number; total: number; totalPages: number }
  afterChangeStatus: (propertyIds: number[], newStatus: string) => void
  afterUpdateSuperhog: (propertyIds: number[], newStatus: string) => void
  countFilter: number
  filters: any
  handleChangeSearchAndPaging: (field: string, value: string) => void
  handleResetFilters: () => void
  onSelectedOneRow: (propertyId: number, isChecked: boolean) => void
  onSlectedAllRow: (isChecked: boolean) => void
  selectedIds: number[]
  toggleSelectedAllPage: (isSelected: boolean) => void
}

const TableCellStickyStyle: any = {
  position: 'sticky',
  left: '0',
  background: '#fff',
  zIndex: '10',
  '@media only screen and (min-width: 768px) and (max-width: 1480px)': {
    '&::before': {
      content: '""',
      height: '100%',
      top: 0,
      position: 'absolute',
      width: '12px',
      right: '-12px',
      background: 'linear-gradient(to left, #F5F5F500, #E9E9E9)',
    },
  },
}

const TableListing: React.FC<Props> = ({
  isEnableGVR,
  properties,
  allProperties,
  afterChangeStatus,
  afterUpdateSuperhog,
  countFilter,
  filters,
  handleChangeSearchAndPaging,
  handleResetFilters,
  paging,
  selectedIds,
  onSelectedOneRow,
  onSlectedAllRow,
  toggleSelectedAllPage,
}) => {
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768
  const [propertyIds, setPropertyIds] = useState<number[]>([])
  const [propertyHover, setPropertyHover] = useState<number>(-1)

  const fields: any[] = [
    {
      key: 'name',
      title: 'Listing name',
      width: '23.5%',
      minWidth: '320px',
      align: 'left',
      renderTitle: () => {
        return (
          <div
            className={
              'font-maison-neue-medium text-12-16 text-neutral-6 py-[12px] lg:py-[6px] gap-[12px] flex items-center'
            }
          >
            {!isMobile && (
              <span className={'hidden lg:block'}>
                <PropertyCheckAll selectedIds={selectedIds} propertyIds={propertyIds} onCheckAll={onSlectedAllRow} />
              </span>
            )}
            <span>Listing name</span>
          </div>
        )
      },
      render: (rowData: any) => {
        const property = { ...rowData }
        const propertyChannel = property.propertyChannelStatus
        const googleChannel = Array.isArray(propertyChannel)
          ? propertyChannel.find((v: any) => v?.channel === 'google')
          : null
        const isGVRSuccess = !googleChannel || googleChannel?.status === 'synced' || googleChannel?.status === 'active'
        return (
          <div className={'flex items-center gap-[28px]'}>
            <BasicCheckbox
              checked={selectedIds.includes(property.id)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSelectedOneRow(property.id, event.target.checked)
              }
            />
            <Link to={`/listings/${property.id}`} className={'w-auto flex-1'}>
              <div className={'flex items-center gap-[12px]'}>
                <div>
                  <img
                    src={property?.thumbnail || no_image}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null // prevents looping
                      currentTarget.src = no_image
                    }}
                    alt="thumb"
                    className="rounded-[8px] min-w-[40px] w-[40px] h-[40px] object-cover"
                  />
                </div>
                <div>
                  <div
                    className={`hover:underline line-clamp-2 font-maison-neue-medium text-neutral-900 text-14-18`}
                    data-hover={property.externalName}
                  >
                    {property.externalName}
                  </div>
                  <div className={'hover:underline line-clamp-1 text-14-18'}>{property.internalName}</div>
                </div>
              </div>
            </Link>
            {/* show Alert GVR (DTRAV-3787) */}
            {isEnableGVR && !isGVRSuccess && (
              <div className={'w-[20px] h-[20px] min-h-[20px] ml-auto'}>
                <BasicTooltipWhite
                  placement="bottom"
                  title={'This listing is not eligible to get listed on Google Vacation Rentals.'}
                  sx={{
                    '& .MuiTooltip-tooltip': { padding: '12px' },
                  }}
                  arrow
                >
                  <img src={ic_ical_alert} alt={'ic_ical_alert'} />
                </BasicTooltipWhite>
              </div>
            )}
          </div>
        )
      },
    },
    {
      key: 'status',
      title: 'Status',
      width: '8.5%',
      minWidth: '120px',
      align: 'left',
      tooltip:
        'At least one payment method is required for your properties to be listed on your Dtravel merchant page. You can also unlist properties at any time.',
      render: (property: any) => {
        return (
          <div className="flex items-center justify-end md:justify-start gap-4 md:gap-0">
            <span className={'font-maison-neue-medium text-12-16 text-neutral-6 capitalize'}>
              <PropertyStatus status={property.status} isActive={property.isActive} />
            </span>
            <div className="w-4 h-4 rounded-[50%] flex gap-[8px] md:hidden items-center justify-center">
              <img src={renderChannelLogo(property.channel)} alt="" className="w-full h-full" />
              <div className={''}>
                <RenderSyncStatus property={property} />
              </div>
            </div>
          </div>
        )
      },
    },
    {
      key: 'payment',
      title: 'Payments',
      width: '8.5%',
      minWidth: '128px',
      align: 'left',
      mobileHidden: '1',
      render: (property: any) => {
        return (
          <div className="flex items-center justify-start gap-[16px]">
            {renderOnOff(property.fiatPaymentEnabled, 'fiat')}
            {renderOnOff(property.cryptoPaymentEnabled, 'crypto')}
          </div>
        )
      },
    },
    {
      key: 'guest_screening',
      title: 'Guest screening',
      tooltip: (
        <span>
          Reduce the likelihood of parties, criminal activity, and fraud with our guest screening. Powered by Superhog
          and completely free for Dtravel users. Learn more{' '}
          <a
            href={'https://dtravel.com/help/know-your-guest-risk-management'}
            target={'_blank'}
            rel="noreferrer"
            className={'text-blue'}
          >
            here
          </a>
          .
        </span>
      ),
      width: '8.5%',
      minWidth: '120px',
      mobileHidden: '1',
      align: 'center',
      render: (property: any) => {
        return property.superhogStatus === SUPERHOG_STATUS.KYG ||
          property.superhogStatus === SUPERHOG_STATUS.KYG_DAMAGE ? (
          <span className={'flex gap-[8px] items-center justify-center'}>
            <img src={ic_on} alt={''} />
            <span>On</span>
          </span>
        ) : (
          <span className={'flex gap-[8px] items-center justify-center'}>
            <img src={ic_off} alt={''} />
            <span>Off</span>
          </span>
        )
      },
    },
    {
      key: 'damge_protection',
      title: 'Damage protection',
      tooltip: (
        <span>
          Up to $5M in damage protection for verified, screened bookings. Powered by Superhog for an additional charge
          per reservation. Learn more &nbsp;
          <a
            href={'https://dtravel.com/help/know-your-guest-risk-management'}
            target={'_blank'}
            rel="noreferrer"
            className={'text-blue'}
          >
            here
          </a>
          .
        </span>
      ),
      width: '8.5%',
      minWidth: '120px',
      mobileHidden: '1',
      align: 'center',
      render: (property: any) => {
        return property.superhogStatus === SUPERHOG_STATUS.KYG_DAMAGE ? (
          <span className={'flex gap-[8px] items-center justify-center'}>
            <img src={ic_on} alt={''} />
            <span>On</span>
          </span>
        ) : (
          <span className={'flex gap-[8px] items-center justify-center'}>
            <img src={ic_off} alt={''} />
            <span>Off</span>
          </span>
        )
      },
    },
    {
      key: 'location',
      title: 'Location',
      tooltip: '',
      width: '8.5%',
      minWidth: '120px',
      mobileHidden: '1',
      render: (property: any) => {
        const isHasAddress = property.address && property.address.address
        return (
          <div>
            {isHasAddress
              ? renderLocation(property.address)
              : "N/A - Please update the property's address in your PMS dashboard."}
          </div>
        )
      },
    },
    {
      key: 'source',
      title: 'Source',
      tooltip: '',
      width: '8.5%',
      minWidth: '128px',
      mobileHidden: '1',
      render: (property: any) => {
        return (
          <div className="flex items-center gap-1">
            <div className="w-4 h-4 rounded-[50%] flex items-center justify-center">
              <img src={renderChannelLogo(property.channel)} alt="" className="w-full h-full" />
            </div>
            <div className={'mt-[2px] flex gap-[8px] items-center'}>
              <div>{renderSourceType(property.channel)}</div>
              <div className={''}>
                <RenderSyncStatus property={property} />
              </div>
            </div>
          </div>
        )
      },
    },
    {
      key: 'google_vr',
      title: 'Google VR',
      width: '8.5%',
      minWidth: '120px',
      tooltip: (
        <span>
          Expand your reach by getting listed on Google Vacation Rentals. Learn more &nbsp;
          <a
            href={'https://dtravel.com/help/google-vacation-rentals'}
            target={'_blank'}
            rel="noreferrer"
            className={'text-blue'}
          >
            here
          </a>
          .
        </span>
      ),
      mobileHidden: '1',
      render: (property: any) => {
        const isListed =
          property.isActive &&
          property.status !== PROPERTY_STATUS.DRAFT &&
          property.status !== PROPERTY_STATUS.PENDING_REVIEW
        return (
          <>
            {!isEmpty(property?.propertyChannelStatus) &&
              renderGoogleVR(property?.propertyChannelStatus || [], isListed)}
          </>
        )
      },
    },
    {
      key: 'updatedAt',
      title: 'Last modified',
      tooltip: '',
      width: '8.5%',
      minWidth: '120px',
      mobileHidden: '1',
      render: (property: any) => {
        return moment(property.updatedAt).format('MMM D, YYYY')
      },
    },
    {
      key: 'action',
      title: '',
      width: '8.5%',
      mobileHidden: '1',
      render: (property: any) => {
        const isDraft = property?.status === PROPERTY_STATUS.DRAFT
        const settingSteps = property?.settingSteps || 1
        const nativeURL = isDraft
          ? `/native-listing/${NATIVE_LISTING_STEPS[String(settingSteps)]}?id=${property?.id}`
          : `/native-listing/${NATIVE_LISTING_MENU.PROPERTY}?id=${property?.id}`
        const isHoverActived = propertyHover === property?.id
        return (
          <>
            {property?.source === PROPERTY_SOURCE.DTRAVEL && (
              <div className="flex items-center justify-end">
                <Link
                  to={nativeURL}
                  className={clsx(
                    'w-auto flex items-center justify-center h-[32px] rounded-[100px] bg-white shadow-xs px-[14px] border border-neutral-300',
                    isHoverActived ? 'opacity-100' : 'opacity-0'
                  )}
                >
                  <span className="text-14-18 font-maison-neue-medium text-neutral-800">Edit</span>
                </Link>
              </div>
            )}
          </>
        )
      },
    },
  ]

  useEffect(() => {
    if (Array.isArray(properties)) {
      setPropertyIds(properties.map((item) => item.id))
    }
  }, [properties])

  const renderChannelLogo = (channel: string) => {
    let _channel = channel === PROPERTY_SOURCE.NEXTPAX ? PROPERTY_SOURCE.GUEST_SMILES : channel
    if (_channel === PROPERTY_SOURCE.HOSPITABLE) {
      _channel = PROPERTY_SOURCE.AIRBNB
    }
    if (
      [
        PROPERTY_SOURCE.HOSTAWAY,
        PROPERTY_SOURCE.UPLISTING,
        PROPERTY_SOURCE.GUEST_SMILES,
        PROPERTY_SOURCE.RENTAL_WISE,
        PROPERTY_SOURCE.GUESTY,
        PROPERTY_SOURCE.BOOSTLY,
        PROPERTY_SOURCE.AIRBNB,
        PROPERTY_SOURCE.NEXTPAX,
      ].includes(_channel)
    ) {
      return `https://static.dtravel.com/logo/${_channel}.png`
    }
    return 'https://static.dtravel.com/logo/dtravel.png'
  }

  const renderOnOff = (isOn: boolean, type: 'fiat' | 'crypto') => {
    return (
      <span>
        {type === 'fiat' ? (
          <img src={ic_credit_card} alt={''} className={`${!isOn ? 'opacity-40' : 'opacity-[1]'}`} />
        ) : (
          <img src={ic_ethereum} alt={''} className={`${!isOn ? 'opacity-40' : 'opacity-[1]'}`} />
        )}
      </span>
    )
  }

  const renderTooltip = (msg: string) => {
    return (
      <div className="flex flex-col gap-1">
        <p className="text-12-18 font-inter-600 text-neutral-700 whitespace-nowrap">
          This listing does not meet Google’s requirements
        </p>
        <p
          className="text-12-18 font-inter-400 text-neutral-500 whitespace-pre-line custom-message-google-vr"
          dangerouslySetInnerHTML={{ __html: msg }}
        />
      </div>
    )
  }

  const renderGoogleVR = (propertyChannel: any[], isListed?: boolean) => {
    const googleChannel = propertyChannel.find((v: any) => v?.channel === 'google')
    const isSuccess = googleChannel?.status === 'synced' || googleChannel?.status === 'active'
    return (
      <div className="flex items-center gap-2">
        {!isSuccess ? (
          <>
            {isListed && !isEmpty(googleChannel?.reason) ? (
              <BasicTooltipWhite
                placement="bottom"
                title={renderTooltip(googleChannel?.reason)}
                sx={{
                  '& .MuiTooltip-tooltip': { padding: '12px' },
                }}
                arrow
              >
                <img src={ic_off} alt={''} />
              </BasicTooltipWhite>
            ) : (
              <img src={ic_off} alt={''} />
            )}
            <span className="text-14-18 font-maison-neue text-neutral-600">Unlisted</span>
          </>
        ) : (
          <>
            <img src={ic_on} alt={''} />
            <span className="text-14-18 font-maison-neue text-neutral-600">Listed</span>
          </>
        )}
      </div>
    )
  }

  const hasFilters =
    (Array.isArray(filters['publishedStatus']) && filters['publishedStatus'].length > 0) ||
    (Array.isArray(filters['channel']) && filters['channel'].length > 0) ||
    filters['channelStatus'] ||
    filters['damageProtection'] ||
    filters['guestScreening'] ||
    filters['fiat'] ||
    filters['crypto']
  const isSelectedAllPage = selectedIds.length > 0 && selectedIds.length === paging.total
  const isSelectedAllOnePage = propertyIds.every((id) => selectedIds.includes(id))
  const isUpdateAllId = isSelectedAllPage && !hasFilters
  const propertySelected = (allProperties || []).filter((item: any) => selectedIds.includes(item.id))

  return (
    <div>
      <div
        className={
          'w-full h-[52px] flex items-center px-[16px] gap-[16px] bg-neutral-50 rounded-tl-[16px] rounded-tr-[16px]'
        }
        style={{ boxShadow: '0px 0.5px 0px 0px rgba(0, 0, 0, 0.15)' }}
      >
        <PropertyEdit
          propertySelected={propertySelected}
          selectedIds={selectedIds}
          afterChangeStatus={afterChangeStatus}
          afterUpdateSuperhog={afterUpdateSuperhog}
          isSelectedAllPage={isUpdateAllId}
        />

        {hasFilters && (
          <button
            className={
              'px-[12px] py-[6px] flex items-center justify-center font-maison-neue-medium text-14-18 text-neutral-6 rounded-[8px] box-border border border-neutral-6 h-[30px] hover:bg-neutral-3 active:bg-neutral-8 active:text-white'
            }
            onClick={handleResetFilters}
          >
            Reset Filters
          </button>
        )}
      </div>
      {selectedIds.length > 0 && (
        <div
          className={
            'font-maison-neue-medium text-14-18 text-neutral-700 w-full flex justify-center items-center h-[50px] bg-white'
          }
          style={{ boxShadow: '0px 0.5px 0px 0px rgba(0, 0, 0, 0.15)' }}
        >
          <span>{`${selectedIds.length} ${selectedIds.length > 1 ? 'listings are' : 'listing is'}  selected.`}</span>
          {isSelectedAllOnePage && !isSelectedAllPage && (
            <button
              onClick={() => toggleSelectedAllPage(true)}
              className={'underline ml-[4px] px-[12px] py-[8px] rounded-[8px] hover:bg-neutral-2 active:bg-neutral-3'}
            >
              {`Select all ${paging.total} listings`}
            </button>
          )}
          {selectedIds.length > 0 && (
            <button
              onClick={() => toggleSelectedAllPage(false)}
              className={'underline ml-[4px] px-[12px] py-[8px] rounded-[8px] hover:bg-neutral-2 active:bg-neutral-3'}
            >
              <span className={'hidden md:block'}>Clear selection</span>
              <span className={'block md:hidden'}>Clear</span>
            </button>
          )}
        </div>
      )}
      <div className={'w-full h-[1px] bg-neutral-3'} /> {/* border top */}
      <div className={'shadow-3xl overflow-x-auto'} id={'table-listing'}>
        <TableContainer>
          <Table aria-label="simple table" classes={{ root: 'w-full' }}>
            <TableHead>
              <TableRow>
                {fields.map((item) => {
                  // if (item.key === 'google_vr' && !isEnableGVR) {
                  //   return null
                  // }
                  return (
                    <TableCellCustom
                      key={item.key}
                      align={item.key === 'status' && isMobile ? 'center' : item.align || 'left'}
                      style={{ width: item.width }}
                      mobilehidden={item.mobileHidden}
                      minwidth={item.minWidth}
                      sx={item.key === 'name' ? { '&.MuiTableCell-root': { ...TableCellStickyStyle } } : {}}
                      className={'customColumn'}
                    >
                      {typeof item.renderTitle === 'function' ? (
                        item.renderTitle()
                      ) : item.tooltip ? (
                        <span className={'flex items-center justify-center gap-[4px] inline-flex'}>
                          <span className={'text-center inline-flex'}>{item.title}</span>
                          <BasicTooltip
                            placement="top"
                            title={item.tooltip}
                            sx={{
                              '& .MuiTooltip-tooltip': { color: '#FBFAFA', fontSize: '12px', fontWeight: '16px' },
                              fontSize: '16px',
                            }}
                            arrow
                          >
                            <span className={'w-[16px] h-[16px]'}>
                              <img src={ic_warning_info} alt={''} className={''} />
                            </span>
                          </BasicTooltip>
                        </span>
                      ) : (
                        item.title
                      )}
                    </TableCellCustom>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody
              onMouseLeave={() => {
                setPropertyHover(-1)
              }}
            >
              {properties.map((property) => {
                const isDraft = property?.status === PROPERTY_STATUS.DRAFT
                const settingSteps = property?.settingSteps || 1
                const nativeURL = isDraft
                  ? `/native-listing/${NATIVE_LISTING_STEPS[String(settingSteps)]}?id=${property?.id}`
                  : `/native-listing/${NATIVE_LISTING_MENU.PROPERTY}?id=${property?.id}`
                const isHoverActived = propertyHover === property?.id
                const isChangeBackground = selectedIds.includes(property.id) || isHoverActived
                return (
                  <TableRow
                    key={property.id}
                    sx={{
                      '&.MuiTableRow-root': {
                        backgroundColor: isChangeBackground ? '#F6F6F4' : '#FFFFFF',
                      },
                    }}
                    onMouseOver={() => {
                      if (nativeURL && propertyHover !== property.id) setPropertyHover(property.id)
                    }}
                  >
                    {fields.map((item) => {
                      // if (item.key === 'google_vr' && !isEnableGVR) {
                      //   return null
                      // }
                      return (
                        <TableCellCustom
                          key={item.key}
                          scope="row"
                          align={item.align || 'left'}
                          mobilehidden={item.mobileHidden}
                          sx={
                            item.key === 'name'
                              ? isChangeBackground
                                ? { '&.MuiTableCell-root': { ...TableCellStickyStyle, background: '#F6F6F4' } }
                                : { '&.MuiTableCell-root': { ...TableCellStickyStyle } }
                              : {}
                          }
                        >
                          {typeof item.render === 'function'
                            ? // @ts-ignore
                              item.render(property, property[item.key])
                            : // @ts-ignore
                              property[item.key]}
                        </TableCellCustom>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePaging
        total={paging.total}
        page={paging.page}
        pageSize={paging.pageSize}
        totalPages={paging.totalPages}
        handleChangeSearchAndPaging={handleChangeSearchAndPaging}
      />
      {properties.length === 0 && countFilter === 0 && (
        <div className={'w-full p-[24px] lg:p-[48px]'}>
          <p className={'font-editorial-new text-24-32 text-neutral-8'}>You don’t have any listings</p>
          <p className={'font-maison-neue text-18-28 text-neutral-6'}>
            Update listing on your PMS account today to start taking advantage of all that Dtravel has to offer.
          </p>
        </div>
      )}
      {properties.length === 0 && countFilter > 0 && (
        <div className={'w-full p-[24px] lg:p-[48px]'}>
          <p className={'font-editorial-new text-24-32 text-neutral-8 mb-[12px]'}>No results found</p>
          <p className={'font-maison-neue text-18-28 text-neutral-6'}>Please try different filters.</p>
        </div>
      )}
      {/*  Check all button for mobile */}
      {properties.length > 0 && (
        <div className={'fixed lg:hidden z-50 bottom-[88px] left-[16px]'}>
          <PropertyCheckAll selectedIds={selectedIds} propertyIds={propertyIds} onCheckAll={onSlectedAllRow} />
        </div>
      )}
    </div>
  )
}

export default TableListing
