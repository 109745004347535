// import { updateUserProfile } from '../../api/user'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setLoading, setToastSuccess } from '../../redux/slices/common'
import BasicDialog from '../ui/BasicDialog'
import CircularProgress from '@mui/material/CircularProgress'
import clsx from 'clsx'
import React, { useState } from 'react'
import { getUserProfile, unifyOldProfileSelect, unifyProfileSelect } from 'src/api/user'
import ic_mail_open from 'src/assets/icons/ic_mail_open.svg'
import ic_wallet from 'src/assets/icons/ic_wallet.svg'
import { openSelectProfile, setProfilesData } from 'src/redux/slices/user'
import { handleErrorMessage, isEmpty, shorterAddress } from 'src/utils/common'
import { getUserInfo, saveUserInfo } from 'src/utils/user'

const ConfirmAccount = () => {
  const dispatch = useAppDispatch()
  const { isLoading } = useAppSelector((state) => state.common)
  const { profiles, isOpenSelectProfile, profile } = useAppSelector((state) => state.user)

  const [account, setAccount] = useState<'email' | 'wallet' | null>(null)

  const handleClose = () => {
    dispatch(setProfilesData([]))
    dispatch(openSelectProfile(false))
  }
  const fetchUserProfile = async () => {
    try {
      const res = await getUserProfile()
      const { data } = res
      const user = getUserInfo()
      saveUserInfo({ ...user, ...data })
    } catch (err) {
      console.log(err)
    }
  }
  const onSave = async () => {
    try {
      dispatch(setLoading(true))
      if (account) {
        let res: any = null
        const userInfo = getUserInfo()
        if (profile?.user?.hasOldContact) {
          const emailAccount = profiles.find((v: any) => v?.email)
          const walletAccount = profiles.find((v: any) => v?.loginWallet)
          res = await unifyOldProfileSelect(account === 'email' ? emailAccount?.id : walletAccount?.id)
        } else {
          res = await unifyProfileSelect({ selectedProfile: account })
        }
        let newUserInfo = { ...userInfo, ...res.data, user: { ...userInfo?.user, hasOldContact: false } } // remove hasOldContact
        dispatch(setToastSuccess({ message: `Select account successfully.` }))
        saveUserInfo(newUserInfo)
        fetchUserProfile()
        handleClose()
        window.location.reload() // reload to fetch settings again
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      dispatch(setLoading(false))
    }
  }
  const renderRadio = (isSelected: boolean) => {
    return (
      <div className="flex items-center justify-center">
        <div
          className={`w-[24px] h-[24px] border-neutral-8 rounded-[50%] ${isSelected ? 'border-[8px]' : 'border-[2px]'}`}
        />
      </div>
    )
  }
  const renderContent = () => {
    const isDisabled = isEmpty(account)
    const emailAccount = profiles.find((v: any) => v?.email)
    const walletAccount = profiles.find((v: any) => v?.loginWallet)
    const listingEmailCount = emailAccount?.listingNumber || 0
    const reservationEmailCount = emailAccount?.bookingCount || 0
    const listingWalletCount = walletAccount?.listingNumber || 0
    const reservationWalletCount = walletAccount?.bookingCount || 0
    return (
      <>
        <div
          className={`flex px-6 py-4 border rounded-2xl mb-4 cursor-pointer ${account === 'email' ? 'border-neutral-8 border-[1.5px]' : 'border-neutral-3 border-[1px]'
            }`}
          onClick={() => setAccount('email')}
          role="presentation"
        >
          <img src={ic_mail_open} alt="" style={{ width: 32, height: 32 }} />
          <div className="flex flex-col pl-4 w-full">
            <p className="mb-1 font-maison-neue-medium text-16-24 text-neutral-7">{emailAccount?.email}</p>
            <p className="flex font-maison-neue text-14-18 text-neutral-6">
              <span>{`${listingEmailCount} listing${listingEmailCount > 1 ? 's' : ''}`}</span>
              <span>&nbsp;.&nbsp;</span>
              <span>{`${reservationEmailCount} reservation${reservationEmailCount > 1 ? 's' : ''}`}</span>
            </p>
          </div>
          {renderRadio(account === 'email')}
        </div>
        <div
          className={`flex px-6 py-4 border rounded-2xl mb-6 cursor-pointer ${account === 'wallet' ? 'border-neutral-8 border-[1.5px]' : 'border-neutral-3 border-[1px]'
            }`}
          onClick={() => setAccount('wallet')}
          role="presentation"
        >
          <img src={ic_wallet} alt="" style={{ width: 32, height: 32 }} />
          <div className="flex flex-col pl-4 w-full">
            <p className="mb-1 font-maison-neue-medium text-16-24 text-neutral-7">
              {shorterAddress(walletAccount?.loginWallet)}
            </p>
            <p className="flex font-maison-neue text-14-18 text-neutral-6">
              <span>{`${listingWalletCount} listing${listingWalletCount > 1 ? 's' : ''}`}</span>
              <span>&nbsp;.&nbsp;</span>
              <span>{`${reservationWalletCount} reservation${reservationWalletCount > 1 ? 's' : ''}`}</span>
            </p>
          </div>
          {renderRadio(account === 'wallet')}
        </div>
        <button
          className={clsx('bg-black px-3 rounded-xl flex justify-center items-center', {
            ['opacity-50']: isDisabled,
            ['cursor-pointer']: !isDisabled,
            ['cursor-not-allowed']: isDisabled,
          })}
          disabled={isDisabled}
          onClick={onSave}
        >
          <p className="text-16-20 font-maison-neue-medium text-white my-[14px]">Verify</p>
          <span>&nbsp;{isLoading && <CircularProgress size={12} style={{ color: '#FFF' }} />}</span>
        </button>
      </>
    )
  }
  const renderContentPopup = () => {
    return {
      title: 'Select your account profile',
      subTitle:
        'You have 02 account profiles associated with your wallet address and your email address. Select one account you want to keep, the other will be deleted from the system.',
    }
  }

  return (
    <>
      <BasicDialog
        isOpen={Boolean(isOpenSelectProfile)}
        onClose={handleClose}
        title={renderContentPopup().title}
        extraTitle={renderContentPopup().subTitle}
        titleAlign={'center'}
        hideClose={profile?.user?.hasOldContact}
      >
        <div className={'flex flex-col'}>{renderContent()}</div>
      </BasicDialog>
    </>
  )
}

export default ConfirmAccount
