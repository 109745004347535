import { handleErrorMessage, isEmpty } from '../../../utils/common'
import EditICalCalendar from './EditICalCalendar'
import clsx from 'clsx'
import React, { useState } from 'react'
import { deleteICalCalendar } from 'src/api/native-listing'
import ic_delete_trash from 'src/assets/icons/ic_delete_trash.svg'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import { useAppDispatch } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { convertSyncedTimeAgo } from 'src/utils/property'

interface Props {
  propertyId: string
  listICal: any[]
  fetchICal?: (propertyId: string) => Promise<void>
  fetchStatusStep?: any
}

const ICalListing: React.FC<Props> = ({ propertyId, listICal, fetchICal, fetchStatusStep }) => {
  const dispatch = useAppDispatch()
  const [isOpenPopupDeleteIcal, setIsOpenPopupDeleteIcal] = useState<boolean>(false)
  const [loadingDeleteIcal, setLoadingDeleteIcal] = useState<boolean>(false)
  const [selectedIcalId, setSelectedIcalId] = useState<string | number>('')

  const handleOpenPopupDeleteIcal = (iCalId: string | number) => {
    setSelectedIcalId(iCalId)
    setIsOpenPopupDeleteIcal(true)
  }

  const handleClosePopupDeleteIcal = () => {
    setSelectedIcalId('')
    setIsOpenPopupDeleteIcal(false)
  }

  const handleDeleteIcal = async () => {
    try {
      setLoadingDeleteIcal(true)
      await deleteICalCalendar(propertyId, selectedIcalId)
      dispatch(setToastSuccess({ message: 'Delete iCal success' }))
      if (typeof fetchICal === 'function') {
        fetchICal(propertyId)
      }
      if (fetchStatusStep) fetchStatusStep()
      handleClosePopupDeleteIcal()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setLoadingDeleteIcal(false)
    }
  }

  return (
    <>
      {!isEmpty(listICal) &&
        <div>
          {Array.isArray(listICal) &&
            listICal.map((item, index) => {
              return (
                <div
                  key={item.id}
                  className={`flex items-center gap-[16px] h-[36px]  ${index === listICal.length - 1 ? 'mb-[8px]' : 'mb-[16px]'
                    }`}
                >
                  <button
                    className={clsx(
                      'w-[36px] h-[36px] rounded-full border border-neutral-300 shadow-xs flex items-center justify-center',
                      'hover:bg-neutral-200'
                    )}
                    onClick={() => handleOpenPopupDeleteIcal(item.id)}
                  >
                    <img alt={'ic_delete'} src={ic_delete_trash} />
                  </button>

                  <EditICalCalendar
                    id={item.id}
                    iCalUrl={item.iCalUrl}
                    iCalName={item.iCalName}
                    propertyId={propertyId}
                    fetchICal={fetchICal}
                  />

                  <div className="flex flex-col">
                    <p className="font-maison-neue text-14-18 text-neutral-800 mb-[2px]">{item.iCalName}</p>
                    <p className="text-12-16 text-neutral-500 font-maison-neue">
                      {convertSyncedTimeAgo(item.updatedAt || item.createdAt, item.lastSyncStatus)}
                    </p>
                  </div>
                </div>
              )
            })}
        </div>
      }
      {/* ----Start Popup Confirm Delete iCal---- */}
      <BasicDialog
        isOpen={isOpenPopupDeleteIcal}
        onClose={handleClosePopupDeleteIcal}
        title={`Remove the calendar`}
        extraTitle={
          "Are you sure you want to remove this calendar? This will stop updating your listing's availability from this calendar."
        }
      >
        <div className={'flex flex-col gap-[8px]'}>
          <BasicButton
            variant={'contained'}
            isRadius100={true}
            color={'red'}
            onClick={handleDeleteIcal}
            loading={loadingDeleteIcal}
          >
            Confirm
          </BasicButton>
        </div>
      </BasicDialog>
    </>
  )
}

export default ICalListing
