import ic_check_only_black from '../../assets/icons/ic_check_only_black.svg'
import ic_check_only_white from '../../assets/icons/ic_check_only_white.svg'
import clsx from 'clsx'
import React from 'react'

interface Props {
  onCheckAll: (isCheckedAll: boolean) => void
  selectedIds: number[]
  propertyIds: number[]
}

const PropertyCheckAll: React.FC<Props> = ({ selectedIds, propertyIds, onCheckAll }) => {
  const isCheckedAll = propertyIds.every((id) => selectedIds.includes(id))

  return isCheckedAll ? (
    <button
      className={clsx(
        'flex items-center justify-center',
        'rounded-[12px]',
        'w-[52px] h-[52px] lg:w-[24px] lg:h-[24px]',
        'bg-red-6 lg:bg-transparent'
      )}
      onClick={() => onCheckAll(false)}
    >
      <div
        className={clsx(
          'w-[16px] h-[16px] rounded-[4px] border-[1.5px] flex items-center justify-center',
          'border-neutral-1 lg:border-neutral-6'
        )}
      >
        <img src={ic_check_only_black} alt={'ic_check_black'} className={'hidden lg:block'} />
        <img src={ic_check_only_white} alt={'ic_check_white'} className={'block lg:hidden'} />
      </div>
    </button>
  ) : (
    <button
      className={clsx(
        'flex items-center justify-center',
        'rounded-[12px]',
        'w-[52px] h-[52px] lg:w-[24px] lg:h-[24px]',
        'bg-neutral-6 lg:bg-transparent'
      )}
      onClick={() => onCheckAll(true)}
    >
      <div className={clsx('w-[16px] h-[16px] rounded-[4px] border-[1.5px]', 'border-neutral-1 lg:border-neutral-6')} />
    </button>
  )
}

export default PropertyCheckAll
