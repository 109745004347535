import useWallet from '../../hooks/useWallet'
import React from 'react'
import ic_metamask from 'src/assets/icons/ic_metamask.svg'

const ConnectViaWallet = () => {
  const { handleConnectMetamask, isConnectingMetamask } = useWallet()

  return (
    <>
      <div className="">
        <p className="font-maison-neue-medium text-14-18 text-neutral-800 mb-[8px]">Connect via wallet</p>
        <button
          className={
            'flex justify-center items-center bg-white w-full rounded-[100px] h-[48px] px-[20px] border border-neutral-300 gap-[16px]'
          }
          onClick={() => handleConnectMetamask()}
          style={isConnectingMetamask ? { background: 'rgba(63, 63, 63, 0.08)' } : {}}
        >
          <img src={ic_metamask} alt="" style={{ width: 20, height: 20, opacity: isConnectingMetamask ? 0.8 : 1 }} />
          <span className={'font-maison-neue-medium text-16-20 text-neutral-700'}>
            {isConnectingMetamask ? 'Connecting...' : 'MetaMask'}
          </span>
        </button>
      </div>
    </>
  )
}

export default ConnectViaWallet
