import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import { getStripeStatus, getStripeUrl } from 'src/api/integration'
import ic_check_neutral_800 from 'src/assets/icons/ic_check_neutral_800.svg'
import ic_copy from 'src/assets/icons/ic_copy.svg'
import ic_link from 'src/assets/icons/ic_link.svg'
import ic_smart_wallet from 'src/assets/icons/ic_smart_wallet.svg'
import ic_stripe from 'src/assets/icons/ic_stripe.svg'
import ConnectWalletThirdWeb from 'src/components/thirdweb/ConnectWalletThirdWeb'
import BasicButton from 'src/components/ui/BasicButton'
import { TABS_SETTING_KEYS } from 'src/contants/common'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setToastSuccess, setUpdateStripePayment } from 'src/redux/slices/common'
import { setIsConnectedStripe, setLoadingStripe } from 'src/redux/slices/user'
import { handleErrorMessage, isEmpty } from 'src/utils/common'

interface Props {
  handleNext: () => void
}

const OnboardingSiteDetailsPaymentMethods = ({ handleNext }: Props) => {
  const { profile } = useAppSelector((state) => state.user)
  const walletId = profile?.user?.walletId
  // const { handleConnectMetamask, isConnectingMetamask } = useWallet()

  const dispatch = useAppDispatch()
  const { isConnectedStripe } = useAppSelector((state) => state.user)
  const [isLoadingStripe, setIsLoadingStripe] = useState<boolean>(false)

  const fetchStripeStatus = async () => {
    try {
      dispatch(setLoadingStripe(true))
      const res: any = await getStripeStatus()
      const status = res?.data?.status
      dispatch(setIsConnectedStripe(status))
      dispatch(setUpdateStripePayment(true))
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(setLoadingStripe(false))
    }
  }

  const fetchStripeUrlAndRedirect = async () => {
    try {
      setIsLoadingStripe(true)
      const res = await getStripeUrl()
      window.location.href =
        res.data.url + `&redirect_uri=${window.location.origin}${process.env.REACT_APP_BASE_PATH || ''}`
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      // setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchStripeStatus()
  }, [])

  const renderFooterBtn = () => {
    return (
      <div className="flex items-center gap-4">
        <BasicButton
          size={'xl'}
          variant={'contained'}
          clases={'w-auto h-[48px] rounded-[100px_!important] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]'}
          color="red"
          onClick={handleNext}
          // disabled={!isConnectedStripe && isEmpty(walletId)}
        >
          Continue
        </BasicButton>
      </div>
    )
  }
  const renderStatus = (isConnected: boolean) => {
    return (
      <>
        {isConnected ? (
          <span className="font-maison-neue-medium text-forest-6 uppercase text-12-16 bg-forest-1 px-2 py-[1px] rounded-[100px] tracking-[.04em] ml-[8px]">
            CONNECTED
          </span>
        ) : (
          <span className="font-maison-neue-medium text-neutral-6 uppercase text-12-16 bg-neutral-2 px-2 py-[1px] rounded-[100px] tracking-[.04em]">
            DISCONNECTED
          </span>
        )}
      </>
    )
  }

  const handleClickDisconnect = () => {
    window.open(process.env.REACT_APP_BASE_PATH + '/settings?tab=' + TABS_SETTING_KEYS.PAYMENTS, '_blank')
  }

  return (
    <>
      <p className="mb-6 text-24-36 text-neutral-800">Add ways for your guests to pay:</p>
      <div className="flex flex-col gap-6">
        <div className={'flex flex-col gap-[24px] w-full bg-white rounded-[16px] mt-[16px] p-[24px]'}>
          {/*----Stripe---- */}
          <div className={'flex flex-col md:flex-row gap-[12px] justify-between items-start md:items-center'}>
            <div className={'flex items-center gap-[12px]'}>
              <div className={'rounded-full w-[42px] min-w-[42px] h-[42px] flex items-center justify-center'}>
                <img src={ic_stripe} alt={'stripe'} className="rounded-full w-[42px] h-[42px]" />
              </div>
              <div>
                <p className={'flex items-center gap-[8px]'}>
                  <span className={'font-maison-neue-medium text-16-20 text-neutral-8'}>Stripe</span>
                  {renderStatus(isConnectedStripe)}
                </p>
                <p className={'font-maison-neue text-14-18 text-neutral-6 mt-[4px]'}>
                  Accept credit card payments, Apple Pay, Google Pay, Affirm, Klarna, and more.
                </p>
              </div>
            </div>
            {isConnectedStripe ? (
              // <DisconnectStripe updateStripeStatus={fetchStripeStatus} />
              <BasicButton
                onClick={handleClickDisconnect}
                clases={'w-full md:w-auto'}
                variant={'outlined'}
                size={'md'}
                isRadius100={true}
              >
                Disconnect
              </BasicButton>
            ) : (
              <BasicButton
                variant={'outlined'}
                size={'md'}
                clases={'w-full md:w-auto'}
                onClick={fetchStripeUrlAndRedirect}
                loading={isLoadingStripe}
                isRadius100={true}
              >
                {isLoadingStripe ? 'Connecting...' : 'Connect'}
              </BasicButton>
            )}
          </div>
          <div className={'w-full h-[1px] bg-neutral-300'} />

          <div className={'flex flex-col md:flex-row gap-[12px] justify-between items-start md:items-center'}>
            <div className={'flex items-center gap-[12px]'}>
              <div className={'rounded-full w-[42px] min-w-[42px] h-[42px] flex items-center justify-center bg-red-50'}>
                <img src={ic_smart_wallet} alt={''} className="rounded-full w-[24px] h-[24px]" />
              </div>
              <div>
                <p className={'flex items-center gap-[8px]'}>
                  <span className={'font-maison-neue-medium text-16-20 text-neutral-8'}>Dtravel Smart Wallet</span>
                  {renderStatus(!isEmpty(walletId))}
                </p>
                <p className={'font-maison-neue text-14-18 text-neutral-6 mt-[4px]'}>
                  Accept cryptocurrencies and web3 rewards via an email.
                </p>
              </div>
            </div>
            {isEmpty(walletId) && <ConnectWalletThirdWeb className="smart-wallet-onboarding" disableAutoConnect />}
          </div>
          <div className={'flex flex-col md:flex-row gap-[12px] justify-between items-start md:items-center'}>
            <div className={'flex items-center gap-[12px] mt-[-8px]'}>
              <div className={'rounded-full w-[42px] min-w-[42px] h-[42px] flex items-center justify-center'} />
              <div className="flex flex-col gap-2">
                {!isEmpty(walletId) ? (
                  <div className="flex flex-col gap-2">
                    <div
                      className="flex items-center gap-2 py-[10px] px-4 bg-neutral-50 rounded-[12px]"
                      style={{
                        border: '0.5px solid rgba(0, 0, 0, 0.15)',
                        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
                      }}
                    >
                      <p
                        className="text-neutral-500 text-16-20 font-maison-neue text-ellipsis whitespace-nowrap overflow-hidden max-w-[calc(100vw_-_168px)]"
                        title={walletId}
                      >
                        {walletId}
                      </p>
                      <img
                        src={ic_copy}
                        alt=""
                        className="w-[20px] h-[20px] cursor-pointer opacity-[0.6]"
                        onClick={() => {
                          navigator.clipboard.writeText(walletId)
                          dispatch(setToastSuccess({ message: 'Copied' }))
                        }}
                        role="presentation"
                      />
                    </div>
                    <p className="text-14-18 font-maison-neue text-neutral-500">
                      You’ll be able to access your wallet from your dashboard
                    </p>
                  </div>
                ) : (
                  <>
                    {[
                      'Non-custodial wallet requiring no private keys or seed phrases',
                      'Accept cryptocurrency payments',
                      'Collect TRVL token rewards for each booking',
                      'Gasless transactions',
                    ].map((el: string, idx: number) => (
                      <div className={'flex items-center gap-2'} key={idx}>
                        <img src={ic_check_neutral_800} alt={''} className="rounded-full w-[16px] h-[16px]" />
                        <p className="text-14-18 font-maison-neue text-neutral-600">{el}</p>
                      </div>
                    ))}
                    <p className="text-14-18 font-maison-neue text-neutral-500 mt-2">
                      Note: Dtravel will never be able to access your wallet or funds.
                      <br />
                      <span
                        className="underline cursor-pointer"
                        onClick={() => {
                          window.open('https://www.dtravel.com/terms-of-service', '_blank')
                        }}
                        role="presentation"
                      >
                        Read more about wallet
                      </span>
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          {/*----Metamask wallet---- */}
          {/* <div
            className={'flex flex-col md:flex-row gap-[12px] justify-between items-start md:items-center'}
            id={SETUP_GUIDE.CONNECT_METAMASK_WALLET}
          >
            <div className={'flex gap-[12px]'}>
              <div className={'flex items-center justify-center rounded-full border border-neutral-3 w-[42px] h-[42px]'}>
                <img src={ic_metamask} alt={'ic_metamask'} className={'w-[34px] h-[34px]'} />
              </div>
              <div>
                <p>
                  <span className={'font-maison-neue-medium text-neutral-8 text-16-20'}>Wallet Address</span>
                  {renderStatus(!isEmpty(walletId))}
                </p>
                <p className={'font-maison-neue text-neutral-6 text-14-18 mt-[4px]'}>
                  {walletId ? (
                    <span className={'flex items-center gap-[10px]'}>
                      <span>{shorterAddress(walletId)}</span>
                      <img
                        src={ic_copy}
                        alt=""
                        className="w-[18px] h-[18px] cursor-pointer"
                        onClick={() => {
                          navigator.clipboard.writeText(walletId)
                          dispatch(setToastSuccess({ message: 'Copied' }))
                        }}
                        role="presentation"
                      />
                    </span>
                  ) : (
                    'Accept crypto payments and earn rewards'
                  )}
                </p>
              </div>
            </div>

            {!walletId && (
              <BasicButton
                onClick={() => handleConnectMetamask(true)}
                size={'md'}
                variant={'contained'}
                id="connect_wallet_unify"
                clases={'w-full md:w-auto'}
                loading={isConnectingMetamask}
                isRadius100={true}
              >
                {isConnectingMetamask ? 'Connecting...' : 'Connect'}
              </BasicButton>
            )}
          </div> */}
        </div>
        <a target={'_blank'} rel="noreferrer" href={'https://dtravel.featureos.app/b/feedback?tag_id=46604'}>
          <div className="flex items-center justify-between gap-4 bg-white rounded-[16px] px-6 py-4 cursor-pointer">
            <div className="flex flex-col">
              <p className="text-16-24 text-neutral-800 font-maison-neue-medium">Request additional payment methods</p>
              <p className="text-14-20 text-neutral-800 font-maison-neue">
                Add or upvote additional payment methods on our request board.
              </p>
            </div>
            <img src={ic_link} alt="" className="w-[16px] h-[16px] cursor-pointer" role="presentation" />
          </div>
        </a>

        <div
          className={clsx(
            'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
            'border-t border-neutral-300 px-4 md:px-8',
            'shadow-[0px_-2px_4px_-2px_rgba(0,0,0,0.08),0px_-4px_8px_-2px_rgba(0,0,0,0.06)]'
          )}
        >
          {renderFooterBtn()}
        </div>
        <div className="hidden lg:block">{renderFooterBtn()}</div>
      </div>
    </>
  )
}

export default OnboardingSiteDetailsPaymentMethods
