import clsx from 'clsx'
import React, { useEffect, useRef } from 'react'
import queryString from 'query-string'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { NATIVE_LISTING_MENU, TYPE_EDIT_LISTINGS } from 'src/contants/common'
import ic_checked from 'src/assets/icons/ic_checked.svg'
import ic_lock from 'src/assets/icons/ic_lock.svg'
import { isEmpty } from 'src/utils/common'
import { PROPERTY_STATUS } from 'src/contants/native'
import { bulkEditEnableAirbnb, bulkEditEnableAll, bulkEditEnableCurrency, bulkEditEnableNative, nativeMenu } from './NativeListingSideBar'
import { checkCompletedStep, isPropertyAirbnb } from 'src/utils/property'
import TooltipClick from '../ui/TooltipClick'

interface Props {
  listingData: any
  statusStep: any
}
const NativeListingStepMobile = ({ listingData, statusStep }: Props) => {
  const stepRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const { type } = useParams()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const typeBulkEdit = parseQuery.type // type bulk edit listings
  const isDraft = listingData?.status === PROPERTY_STATUS.DRAFT || (isEmpty(parseQuery?.id) && !isEditBulk)
  const isAirbnb = isPropertyAirbnb(listingData)

  useEffect(() => {
    const el = document.getElementById(`mobile_${type}`)
    const stepEl = stepRef.current as HTMLDivElement
    if (el) {
      // el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      const elBound = el.getBoundingClientRect()
      if (elBound) {
        const value = elBound?.left
        stepEl.scrollLeft += value - window.innerWidth / 2 + elBound?.width / 2
      }
    }
  }, [type])
  const hanldeClickItem = (id: string) => {
    navigate(`/native-listing/${id}` + search)
  }
  const renderRadio = (isChecked: boolean) => {
    if (isChecked) return <img alt={'checked'} src={ic_checked} className="w-4 h-4" />
    return <div className='w-4 h-4 rounded-[50%] border border-neutral-500 bg-neutral-100' />
  }

  const nativeMenuType = nativeMenu.find((v: any) => v.id === type)
  const activeStep = type === 'publish' ? 12 : nativeMenuType?.index || 0
  const renderTitleTooltip = (id: string) => {
    if (typeBulkEdit === TYPE_EDIT_LISTINGS.NATIVE) return 'This content can only be edited on individual listings'
    if (typeBulkEdit === TYPE_EDIT_LISTINGS.AIRBNB) {
      if (id === NATIVE_LISTING_MENU.ICAL) return 'This content can only be edited on individual listings'
      else return 'This content is automatically imported from Airbnb and cannot be edited'
    }
    // native and airbnb
    return 'This content cannot be edited for some of the listings selected. Edit listings individually to access this section'
  }
  return (
    <div className="flex items-center pb-8 overflow-auto hidden-scroll-bar" ref={stepRef}>
      {nativeMenu.map((el: any, idx: number) => {
        let isLocked = false;
        if (typeBulkEdit === TYPE_EDIT_LISTINGS.NATIVE) isLocked = !bulkEditEnableNative.includes(el.id)
        if (typeBulkEdit === TYPE_EDIT_LISTINGS.AIRBNB || isAirbnb) isLocked = !bulkEditEnableAirbnb.includes(el.id)
        if (typeBulkEdit === TYPE_EDIT_LISTINGS.ALL) isLocked = !bulkEditEnableAll.includes(el.id)
        if (isEditBulk && bulkEditEnableCurrency.includes(el.id) && isEmpty(parseQuery?.currency) && typeBulkEdit !== TYPE_EDIT_LISTINGS.AIRBNB) {
          isLocked = true
        }
        const completedResult = checkCompletedStep(el.id, statusStep)
        const isCreateNativeListing = isEmpty(search) || isDraft
        const isEnabled = isCreateNativeListing ? activeStep >= el.index : !isLocked
        const contentItem = (
          <div className='flex items-center gap-2'>
            <span
              className={clsx(
                'text-12-16 lg:text-16-20 whitespace-nowrap',
                isEnabled ? "cursor-pointer text-neutral-800" : "cursor-not-allowed text-neutral-500",
                type === el?.id ? 'font-maison-neue-demibold' : 'font-maison-neue'
              )}
            >
              {el.name}
            </span>
            {isLocked && <img alt={''} src={ic_lock} className="w-4 h-4" />}
          </div>
        )
        return (
          <div key={el.name} className="flex items-center">
            <div
              className={clsx(
                "w-max flex items-center gap-2 p-2 rounded-[100px]  border border-neutral-300",
                isDraft ? '' : 'cursor-pointer',
                isEnabled ? 'bg-white' : 'bg-neutral-100'
              )}
              role="presentation"
              onClick={() => {
                if (isEnabled && !isCreateNativeListing) hanldeClickItem(el.id)
              }}
              id={`mobile_${el.id}`}
            >
              {renderRadio(
                isEmpty(search) ?
                  activeStep > el.index :
                  (isCreateNativeListing ? completedResult && isEnabled : completedResult)
              )}
              {isLocked ?
                <TooltipClick
                  placement="right"
                  title={renderTitleTooltip(el.id)}
                  sx={{ fontSize: 14, color: 'white !important' }}
                  arrow
                >
                  {contentItem}
                </TooltipClick>
                : contentItem
              }
            </div>
            {idx < nativeMenu.length - 1 && <div className='w-[8px] min-w-2 h-[1px] bg-[#D9D9D9]' />}
          </div>
        )
      })}
    </div>
  )
}

export default NativeListingStepMobile
