import { ONBOARDING_STEP } from '../../../contants/user'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { handleNextOnboardingStep } from '../../../redux/slices/user'
import { IconExternalLink } from '../../common/Icons'
import BasicButton from '../../ui/BasicButton'
import LayoutOnboarding from '../LayoutOnboarding'
import RightContentPmsCm from './RightContentPmsCm'
import clsx from 'clsx'
import React from 'react'

const OnboardingNotSupported = () => {
  const dispatch = useAppDispatch()
  const { onboardingData } = useAppSelector((state) => state.user)
  const pmsName = onboardingData?.pmsUsing || 'PMS'

  const onSubmit = async () => {
    dispatch(handleNextOnboardingStep(ONBOARDING_STEP.NATIVE_LISTING))
  }

  const onClickSyncFromAirbnb = () => {
    dispatch(handleNextOnboardingStep(ONBOARDING_STEP.AIRBNB_IMPORT))
  }

  const renderSubmitBtn = () => {
    return (
      <div className={'flex-col md:flex-row flex gap-[16px]'}>
        <BasicButton variant={'contained'} isRadius100={true} color={'red'} size={'xl'} onClick={onClickSyncFromAirbnb}>
          Sync from Airbnb
        </BasicButton>
        <BasicButton
          size={'xl'}
          variant={'contained'}
          clases={
            'w-auto h-[48px] rounded-[100px_!important] border border-neutral-300 shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]'
          }
          color="white"
          onClick={onSubmit}
        >
          Create a manual listing
        </BasicButton>
      </div>
    )
  }

  return (
    <LayoutOnboarding headerTitle={'Listings'} rightContent={<RightContentPmsCm />}>
      <div className={'mt-[38px] lg:mt-[70px] w-full mx-auto md:max-w-[524px]'}>
        <h2 className={'font-maison-neue text-24-36 text-neutral-800 mb-[16px]'}>{pmsName} isn’t yet supported.</h2>
        <p className={'font-maison-neue text-16-24 text-neutral-800'}>
          We&apos;re always looking to add more PMS and CM partners. In the meantime, learn more about{' '}
          <a
            className={'underline'}
            href={'https://www.dtravel.com/partners/connectivity-options'}
            target={'_blank'}
            rel={'noreferrer'}
          >
            which providers are currently supported.
          </a>
        </p>

        <div
          className={
            'bg-white rounded-[16px] px-[24px] py-[16px] mt-[24px] flex justify-between items-center gap-[16px]'
          }
        >
          <div>
            <p className={'font-maison-neue-medium text-16-24 text-neutral-800'}>Speed up {pmsName} integration</p>
            <p className={'font-maison-neue text-14-20 text-neutral-800'}>
              Add or upvote this provider on our public request board
            </p>
          </div>
          <div className={'min-w-[16px]'}>
            <BasicButton
              size={'sm'}
              href={'https://dtravel.featureos.app/b/feedback?tag_id=46603'}
              target={'_blank'}
              isRadius100={true}
            >
              <IconExternalLink />
            </BasicButton>
          </div>
        </div>

        <div className={'mt-[24px] hidden lg:block'}>{renderSubmitBtn()}</div>
      </div>

      <div
        className={clsx(
          'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
          'border-t border-neutral-300 px-4 md:px-8',
          'shadow-footer'
        )}
      >
        {renderSubmitBtn()}
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingNotSupported
