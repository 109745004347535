import ic_external_link from '../../assets/icons/ic_external_link.svg'
import { PMS_INFO } from '../../contants/common'
import { isEmpty } from '../../utils/common'
import BasicCardV2 from '../common/BasicCardV2'
import PropertyAirbnbCancelPolicy from './PropertyAirbnbCancelPolicy'
import React from 'react'

interface Props {
  isNativeListing: boolean
  airbnbCancellationPolicies: any
  cancelPolicies: any // for PMS
  cancelPolicyDescription: string
  pmsType: string
  from?: string
}

const DetailCancelPolicy: React.FC<Props> = ({
  isNativeListing,
  airbnbCancellationPolicies,
  cancelPolicies,
  cancelPolicyDescription,
  pmsType,
  from,
}) => {
  const isFromReservation = from === 'RESERVATION'
  const hasAirbnbCancellationPolicies = !cancelPolicyDescription && !isEmpty(airbnbCancellationPolicies)

  const getCancelPolicyLastchar = (content: string) => {
    if (isEmpty(content)) return ''
    const lastChar = (content || '').substring((content || '').length - 1)
    return lastChar === '.' ? '' : '.'
  }

  const renderContent = () => {
    if (isNativeListing) {
      if (cancelPolicyDescription) {
        return <div className={'whitespace-pre-line'}>{cancelPolicyDescription}</div>
      }
      if (hasAirbnbCancellationPolicies) {
        return <PropertyAirbnbCancelPolicy airbnbCancellationPolicies={airbnbCancellationPolicies} />
      }
      return <>No cancellation policy is defined for this listing.</>
    } else {
      // for PMS
      return (
        <>
          {cancelPolicies?.name && (
            <p className={'text-16-20 text-neutral-700 mt-[-16px] font-maison-neue-medium'}>{cancelPolicies?.name}</p>
          )}
          <p className={'text-16-24 text-neutral-700'}>
            {`${cancelPolicies?.description || 'No cancellation policy is defined for this listing.'}
            ${getCancelPolicyLastchar(
              cancelPolicies?.description || 'No cancellation policy is defined for this listing.'
            )}`}
          </p>
        </>
      )
    }
  }

  return (
    <BasicCardV2
      wrapperClass={isFromReservation ? 'mt-[40px]' : ''}
      title={'Cancellation policy'}
      noBG={hasAirbnbCancellationPolicies}
      extraTitle={
        !isNativeListing && (
          <button className={'flex items-center text-14-18 text-neutral-600 font-maison-neue-medium hover:underline'}>
            <a href={PMS_INFO[pmsType || '']?.url} target="_blank" rel="noreferrer">
              <span className={'mr-2'}>Show in {PMS_INFO[pmsType || '']?.label}</span>
            </a>
            <img src={ic_external_link} alt={'ic_external_link'} />
          </button>
        )
      }
    >
      <div>{renderContent()}</div>
    </BasicCardV2>
  )
}

export default DetailCancelPolicy
