import { stripeConnectMultiListing } from '../../api/listings'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { useAppDispatch } from '../../redux/hooks'
import { setToastSuccess } from '../../redux/slices/common'
import { handleErrorMessage } from '../../utils/common'
import { IconClose } from '../common/Icons'
import BasicButton from '../ui/BasicButton'
import BasicRadio from '../ui/BasicRadio'
import BasicSwipeDrawer from '../ui/BasicSwipeDrawer'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import React, { useEffect, useState } from 'react'

interface Props {
  anchorEl: HTMLButtonElement | null
  onClose: () => void
  selectedIds: number[]
  afterChangeStatus?: (propertyIds: number[], newStatus: string) => void
  isSelectedAllPage?: boolean
  stripeAccounts: any[]
}

const PropertyEditStripe: React.FC<Props> = ({ anchorEl, onClose, selectedIds, stripeAccounts }) => {
  const dispatch = useAppDispatch()
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const [value, setValue] = useState<string>('')

  useEffect(() => {
    if (Array.isArray(stripeAccounts) && stripeAccounts.length === 1) {
      setValue(stripeAccounts[0].id)
    }
  }, [stripeAccounts])

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setValue(value)
  }

  const handleUpdateStripe = async () => {
    setSubmitLoading(true)
    try {
      await stripeConnectMultiListing({
        stripeId: value,
        propertyIds: selectedIds,
      })
      dispatch(
        setToastSuccess({
          message: 'Successfully',
        })
      )
      onClose()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setSubmitLoading(false)
    }
  }

  const renderContent = () => {
    return (
      <div className={'flex flex-col justify-between h-full'}>
        <div className={'flex gap-[16px] items-start'}>
          <p
            className={
              'mb-[24px] font-maison-neue text-24-36 md:font-maison-neue-demibold md:text-14-18 md:tracking-[0.56px] text-neutral-800 md:uppercase text-center md:text-left flex-1'
            }
          >
            {'Edit stripe account'}
          </p>
          <span className={'block md:hidden'}>
            <IconButton onClick={onClose} sx={{ padding: 0, minWidth: 20, marginLeft: 'auto' }}>
              <IconClose />
            </IconButton>
          </span>
        </div>
        <div className={'font-maison-neue text-14-18 text-neutral-800 mb-[16px]'}>
          Associate these listings to the following Stripe account:
        </div>
        {/*Body*/}
        <div className={'flex flex-col gap-[16px] mb-[24px] max-h-[480px] overflow-y-auto'}>
          {Array.isArray(stripeAccounts) &&
            stripeAccounts.map((item) => {
              return (
                <label
                  key={item.id}
                  className={
                    'flex justify-between items-center gap-[32px] rounded-[16px] p-[24px] cursor-pointer ' +
                    `${value === item.id ? 'border border-[2px] border-neutral-800' : 'border border-grayscale-300'}`
                  }
                  htmlFor={`stripe_account_${item.id}`}
                >
                  <div className={'flex flex-col gap-[4px]'}>
                    <div className={'flex justify-start items-center gap-[8px]'}>
                      <span
                        className={`font-maison-neue-medium text-14-18 w-fit text-neutral-800 truncate ${
                          item.isPrimary ? 'max-w-[110px]' : 'max-w-[180px]'
                        } `}
                      >
                        {item.displayName}
                      </span>
                      {item.isPrimary && (
                        <span
                          className={
                            'font-maison-neue-medium text-12-16 text-neutral-6 tracking-[0.48px] uppercase px-[8px] py-[1px] bg-neutral-2 rounded-[100px]'
                          }
                        >
                          Default
                        </span>
                      )}
                    </div>
                    <span className={'font-maison-neue text-14-18 text-neutral-500 truncate max-w-[180px]'}>
                      {item.email}
                    </span>
                  </div>
                  <BasicRadio
                    id={`stripe_account_${item.id}`}
                    type={'radio'}
                    classes={'w-[24px] h-[24px] cursor-pointer'}
                    name={`stripe_account`}
                    value={item.id}
                    checked={value === item.id}
                    onChange={onChangeValue}
                  />
                </label>
              )
            })}
        </div>

        {/*Footer*/}
        <div className={''}>
          <BasicButton
            size={'md'}
            variant={'outlined'}
            color={'black'}
            onClick={handleUpdateStripe}
            loading={submitLoading}
            disabled={!value}
            isRadius100={true}
            clases={'w-full md:w-auto h-[44px] md:h-[40px]'}
          >
            Apply
          </BasicButton>
        </div>
      </div>
    )
  }
  return (
    <>
      {isMobile ? (
        <BasicSwipeDrawer isOpen={open} onClose={onClose} padding={'32px 24px'}>
          {renderContent()}
        </BasicSwipeDrawer>
      ) : (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              transform: 'translateY(8px)',
              padding: '24px',
              boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)',
              borderRadius: '12px',
              width: '342px',
            },
          }}
        >
          {renderContent()}
        </Popover>
      )}
    </>
  )
}

export default PropertyEditStripe
