import { updateChatBotSetting } from '../../../api/chat-bot'
import { activeChannel, getChannexChannels } from '../../../api/integration'
import { handleErrorMessage } from '../../../utils/common'
import BasicSwitch from '../../ui/BasicSwitch'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import ic_gvr from 'src/assets/icons/ic_gvr.svg'
// import ic_sensei from 'src/assets/icons/ic_sensei.svg'
import { SETTINGS_SIDE_BAR } from 'src/contants/common'

interface Props {
  isOnboarding?: boolean
}

const CHANNELS = [
  {
    channel: 'google',
    title: 'Google Vacation Rentals',
    description: (
      <>
        Automatically publish your eligible listings to Google Vacation Rentals.{' '}
        <a
          href={'https://dtravel.com/help/google-vacation-rentals'}
          target={'_blank'}
          rel={'noreferrer'}
          className={'underline'}
        >
          More info
        </a>
      </>
    ),
    icon: ic_gvr,
    isEnabled: false,
  },
  // {
  //   channel: 'sensei',
  //   title: 'Dtravel Sensei',
  //   description: 'XXXXXXXXXXXXXXXXXX',
  //   icon: ic_sensei,
  //   isEnabled: false,
  // },
]

const SettingChannelManagement: React.FC<Props> = ({ isOnboarding }) => {
  const [channels, setChannels] = useState<any[]>(CHANNELS)

  useEffect(() => {
    async function fetchChannexChannels() {
      try {
        const res1 = await getChannexChannels()
        const googleChannel = Array.isArray(res1.data) ? res1.data.find((item: any) => item.channel === 'google') : null
        const isEnabledGoogle = googleChannel ? googleChannel.status : false
        // const isEnabledSensei = res2?.data?.isEnabled
        // remove sensie in oboarding
        // const _channels = _isOnboarding ? CHANNELS.filter((item) => item.channel !== 'sensei') : [...CHANNELS]
        const _channels = [...CHANNELS]
        setChannels(
          _channels.map((item) => {
            if (item.channel === 'google') {
              item.isEnabled = isEnabledGoogle
            }
            return item
          })
        )
      } catch (err: any) {
        console.log(err)
      }
    }
    fetchChannexChannels()
  }, [])

  const updateChannelOnView = (channel: string, checked: boolean) => {
    setChannels((prevState) => {
      return prevState.map((item) => {
        if (item.channel === channel) {
          return { ...item, isEnabled: checked }
        }
        return item
      })
    })
  }

  const handleActiveChannel = async (channel: string, checked: boolean) => {
    try {
      const res = await activeChannel({ channel, status: checked })
      if (res.success) {
        updateChannelOnView(channel, checked)
      }
    } catch (err: any) {
      handleErrorMessage(err)
    }
  }

  const handleUpdateSenseiSetting = async (isEnabled: boolean) => {
    try {
      const res = await updateChatBotSetting(isEnabled)
      if (res.success) {
        updateChannelOnView('sensei', isEnabled)
      }
    } catch (err: any) {
      handleErrorMessage(err)
    }
  }

  const onToggleChannel = async (channel: string, checked: boolean) => {
    if (channel === 'sensei') {
      await handleUpdateSenseiSetting(checked)
    } else {
      await handleActiveChannel(channel, checked)
    }
  }

  return (
    <div>
      {!isOnboarding && (
        <>
          <h2 id={SETTINGS_SIDE_BAR.CHANNEL_MANAGEMENT} className={'font-maison-neue text-20-24 text-neutral-800'}>
            Channel Management
          </h2>
          <p className={'font-maison-neue text-14-18 text-neutral-500 mt-[4px]'}>
            Increase your exposure by publishing your listings to external booking channels
          </p>
        </>
      )}

      <div className={'flex flex-col w-full bg-white rounded-[16px] mt-[16px] p-[24px]'}>
        {channels.map((item, index) => {
          return (
            <div
              key={item.channel}
              className={clsx('flex justify-between items-center', {
                'border-t border-neutral-300': index !== 0,
                'pt-[24px]': index !== 0,
                'pb-[24px]': channels.length - 1 !== index,
              })}
            >
              <div className={'flex items-center gap-[16px]'}>
                <div
                  className={
                    'w-[48px] min-w-[48px] h-[48px] flex items-center justify-center border border-neutral-300 rounded-full'
                  }
                >
                  {item.channel === 'google' && (
                    <img src={item.icon} alt={'stripe'} width={40} height={40} className={'rounded-full'} />
                  )}
                  {item.channel === 'sensei' && (
                    <img src={item.icon} alt={'stripe'} width={20} height={24} className={'rounded-full'} />
                  )}
                </div>

                <div>
                  <p className={'flex flex-col md:flex-row justify-start gap-[4px] md:gap-[8px]'}>
                    <span className={'font-maison-neue-medium text-16-20 text-neutral-800'}>{item.title}</span>
                  </p>
                  <p className={'font-maison-neue text-14-18 text-neutral-600 hidden md:block mt-[4px]'}>
                    {item.description}
                  </p>
                </div>
              </div>
              <div className={'min-w-[36px]'}>
                <BasicSwitch
                  id={'switch_' + item.channel}
                  checked={item.isEnabled}
                  onChange={(event) => onToggleChannel(item.channel, event.target.checked)}
                  checkedColor="#292926"
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SettingChannelManagement
