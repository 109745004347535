import React from 'react'

interface Props {
  className?: string
  title: string
  percent?: string | number
  growPercent?: string | number
  styles?: any
  stylePercent?: any
}

const PerformanceInfo: React.FC<Props> = ({ className, title, percent, growPercent, styles, stylePercent }) => {
  return (
    <div className={'rounded-[16px] bg-white p-[24px] ' + className} style={{ height: 150, ...styles }}>
      <div className={'text-neutral-800 text-16-20'}>{title}</div>
      <div className={'text-neutral-800 text-24-36 py-1 break-words'} style={stylePercent ? { ...stylePercent } : {}}>
        {percent}
      </div>
      <div className={'text-neutral-800 text-12-16'}>{growPercent}</div>
    </div>
  )
}

export default PerformanceInfo
