import clsx from 'clsx'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { IconHome, IconBuilding, IconCalendarChecked, IconSettings } from 'src/components/common/Icons'

const MENU_BAR: any = [
  { path: '/', label: 'Home' },
  { path: '/listings', label: 'Listings' },
  { path: '/reservations', label: 'Reservations' },
  { path: '/settings', label: 'Settings' },
]

const MenuBarV2 = () => {
  const [pathHovered, setPathHovered] = useState<string>('')

  const renderIcon = (tabPath: string, isActive?: boolean) => {
    switch (tabPath) {
      case '/':
        return <IconHome width={20} height={20} color={isActive || pathHovered === tabPath ? '#A32A30' : '#6A6A68'} />
      case '/reservations':
        return (
          <IconCalendarChecked
            width={20}
            height={20}
            color={isActive || pathHovered === tabPath ? '#A32A30' : '#6A6A68'}
          />
        )
      case '/listings':
        return (
          <IconBuilding width={20} height={20} color={isActive || pathHovered === tabPath ? '#A32A30' : '#6A6A68'} />
        )
      case '/settings':
        return (
          <IconSettings width={20} height={20} color={isActive || pathHovered === tabPath ? '#A32A30' : '#6A6A68'} />
        )
      default:
        return ''
    }
  }
  return (
    <ul
      className={
        'w-auto h-[40px] py-[2px] rounded-[100px] hidden lg:flex justify-center items-center bg-white shadow-xs'
      }
    >
      {MENU_BAR.map((item: any) => {
        const isActive =
          (location.pathname.includes('/listings/') && item.path === '/listings') ||
          location.pathname === item.path ||
          (location.pathname.includes(item.path) && item.path !== '/')
        return (
          <li
            key={item.path}
            className={'pl-[12px] pr-[16px]'}
            onMouseEnter={() => setPathHovered(item.path)}
            onMouseLeave={() => setPathHovered('')}
          >
            <Link className={clsx('flex items-center gap-[8px]')} to={item.path}>
              <span>{renderIcon(item.path, isActive)}</span>
              <span
                className={clsx(
                  {
                    'text-red-700': isActive || pathHovered === item.path,
                  },
                  'font-maison-neue-medium text-12-16 text-neutral-600'
                )}
              >
                {item.label}
              </span>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default MenuBarV2
