import { updateStepOnboarding } from '../../api/user'
import { ONBOARDING_STEP } from '../../contants/user'
import { RootState } from '../store'
import { setToastError } from './common'
import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AirbnbViaChannex, SettingData, UserProfile } from 'src/interfaces/user'

export const handleNextOnboardingStep = createAsyncThunk(
  'user/handleNextOnboardingStep',
  async (step: string, { getState, dispatch }) => {
    const state: RootState = getState()
    const currentStep = state.user.onboardingStep
    const arrSteps = currentStep.split(', ').filter((item: string) => item !== '')
    arrSteps.push(step)
    const newStep = arrSteps.join(', ')
    try {
      await updateStepOnboarding(newStep)
      return newStep
    } catch (err: any) {
      if (err.response) {
        const error = err.response.data.error
        if (error) {
          if (typeof error === 'object') {
            dispatch(setToastError({ message: error?.message }))
          } else {
            dispatch(setToastError({ message: err.response.data.message || error }))
          }
        } else {
          dispatch(setToastError({ message: err.response.data.message }))
        }
      } else {
        dispatch(setToastError({ message: err?.error?.message || err?.message || err?.error }))
      }
      return currentStep
    }
  }
)
export const handleBackOnboardingStep = createAsyncThunk(
  'user/handleBackOnboardingStep',
  async (arg: string, { getState, dispatch }) => {
    const state: RootState = getState()
    const currentStep = state.user.onboardingStep
    const arrSteps = currentStep.split(', ')
    arrSteps.pop()
    const newStep = arrSteps.join(', ')
    try {
      await updateStepOnboarding(newStep)
      return newStep
    } catch (err: any) {
      if (err.response) {
        const error = err.response.data.error
        if (error) {
          if (typeof error === 'object') {
            dispatch(setToastError({ message: error?.message }))
          } else {
            dispatch(setToastError({ message: err.response.data.message || error }))
          }
        } else {
          dispatch(setToastError({ message: err.response.data.message }))
        }
      } else {
        dispatch(setToastError({ message: err?.error?.message || err?.message || err?.error }))
      }
      return currentStep
    }
  }
)

export interface UserState {
  isConnectingMetamask: boolean
  chainId: string
  walletAddress: string
  totalPropertyAfterImport: number
  isConnectedHostaway: boolean
  isConnectedUplisting: boolean
  isConnectedStripe: boolean
  isConnectedRentalWise: boolean
  isConnectedGS: boolean
  isConnectedAirbnb: boolean
  isOpenPopupConnectHostaway: boolean
  isOpenPopupConnectUplisting: boolean
  isOpenPopupConnectGS: boolean
  isOpenConfirmLogout: boolean
  isOpenPopupConnectPms: boolean
  logOutType: '' | 'accountsChanged'
  openUserMenu: boolean
  hostawaySyncAt: Date | null
  uplistingSyncAt: Date | null
  gsSyncAt: Date | null
  isLoadingPms: boolean
  isLoadingStripe: boolean
  profile: UserProfile
  profiles: Array<any> // profile for select account in unify flow
  isOpenSelectProfile: Boolean
  settingData: SettingData | {}
  onBoardingTotalListing: number
  onBoardingFinishedLoadApi: boolean
  pmsTypeSelected: string
  pmsTypeConnected: string
  pmsSyncAt: Date | null
  onboardingStep: string
  onboardingData: any
  numberOfGenesysAccount: number
  airbnbPmsType: string
  airbnbViaHospitable: any[]
  airbnbViaChannex: AirbnbViaChannex | null
}

const initialState: UserState = {
  isConnectingMetamask: false,
  chainId: '',
  walletAddress: '',
  totalPropertyAfterImport: 0,
  isConnectedHostaway: false,
  isConnectedUplisting: false,
  isConnectedGS: false,
  isConnectedStripe: false,
  isConnectedRentalWise: false,
  isConnectedAirbnb: false,
  isOpenPopupConnectHostaway: false,
  isOpenPopupConnectUplisting: false,
  isOpenPopupConnectGS: false,
  isOpenPopupConnectPms: false,
  isOpenConfirmLogout: false,
  logOutType: '',
  openUserMenu: false,
  hostawaySyncAt: null,
  uplistingSyncAt: null,
  gsSyncAt: null,
  isLoadingPms: false,
  isLoadingStripe: false,
  profile: {},
  profiles: [],
  isOpenSelectProfile: false,
  settingData: {},
  onBoardingTotalListing: 0,
  onBoardingFinishedLoadApi: false,
  pmsTypeSelected: '',
  pmsTypeConnected: '',
  pmsSyncAt: null,
  onboardingStep: ONBOARDING_STEP.CREATE_ACCOUNT,
  onboardingData: {},
  numberOfGenesysAccount: 0,
  airbnbPmsType: '', // connect airbnb via channex or hospitable
  airbnbViaHospitable: [],
  airbnbViaChannex: null,
}

export const commonSlices = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsConnectingMetamask: (state, action: PayloadAction<boolean>) => {
      state.isConnectingMetamask = action.payload
    },
    setChainId: (state, action: PayloadAction<string>) => {
      state.chainId = action.payload
    },
    setWalletAddress: (state, action: PayloadAction<string>) => {
      state.walletAddress = action.payload
    },
    setIsConnectedHostaway: (state, action: PayloadAction<boolean>) => {
      state.isConnectedHostaway = action.payload
    },
    setIsConnectedGS: (state, action: PayloadAction<boolean>) => {
      state.isConnectedGS = action.payload
    },
    setIsConnectedUplisting: (state, action: PayloadAction<boolean>) => {
      state.isConnectedUplisting = action.payload
    },
    setTotalPropertyAfterImport: (state, action: PayloadAction<number>) => {
      state.totalPropertyAfterImport = action.payload
    },
    setIsConnectedStripe: (state, action: PayloadAction<boolean>) => {
      state.isConnectedStripe = action.payload
    },
    setIsConnectedRentalWise: (state, action: PayloadAction<boolean>) => {
      state.isConnectedRentalWise = action.payload
    },
    setIsOpenPopupConnectHostaway: (state, action: PayloadAction<boolean>) => {
      state.isOpenPopupConnectHostaway = action.payload
    },
    setIsOpenPopupConnectUplisting: (state, action: PayloadAction<boolean>) => {
      state.isOpenPopupConnectUplisting = action.payload
    },
    setIsConnectedAirbnb: (state, action: PayloadAction<boolean>) => {
      state.isConnectedAirbnb = action.payload
    },
    setAirbnbPmsType: (state, action: PayloadAction<string>) => {
      state.airbnbPmsType = action.payload
    },
    setIsOpenPopupConnectGS: (state, action: PayloadAction<boolean>) => {
      state.isOpenPopupConnectGS = action.payload
    },
    setIsOpenPopupConnectPms: (state, action: PayloadAction<boolean>) => {
      state.isOpenPopupConnectPms = action.payload
    },
    setIsOpenConfirmLogout: (state, action: PayloadAction<boolean>) => {
      state.isOpenConfirmLogout = action.payload
    },
    setLogOutType: (state, action: PayloadAction<'' | 'accountsChanged'>) => {
      state.logOutType = action.payload
    },
    setOpenUserMenu: (state, action: PayloadAction<boolean>) => {
      state.openUserMenu = action.payload
    },
    setHosawaySyncAt: (state, action: PayloadAction<Date>) => {
      state.hostawaySyncAt = action.payload
    },
    setUplistingSyncAt: (state, action: PayloadAction<Date>) => {
      state.uplistingSyncAt = action.payload
    },
    setGSSyncAt: (state, action: PayloadAction<Date>) => {
      state.gsSyncAt = action.payload
    },
    setLoadingPms: (state, action: PayloadAction<boolean>) => {
      state.isLoadingPms = action.payload
    },
    setLoadingStripe: (state, action: PayloadAction<boolean>) => {
      state.isLoadingStripe = action.payload
    },
    setProfile: (state, action: PayloadAction<UserProfile>) => {
      state.profile = action.payload
    },
    setProfilesData: (state, action: PayloadAction<Array<any>>) => {
      state.profiles = action.payload
    },
    openSelectProfile: (state, action: PayloadAction<boolean>) => {
      state.isOpenSelectProfile = action.payload
    },
    setSettingData: (state, action: PayloadAction<SettingData>) => {
      state.settingData = action.payload
    },
    setOnBoardingTotalListing: (state, action: PayloadAction<number>) => {
      state.onBoardingTotalListing = action.payload
    },
    setOnBoardingFinishedLoadApi: (state, action: PayloadAction<boolean>) => {
      state.onBoardingFinishedLoadApi = action.payload
    },
    setPmsTypeSelected: (state, action: PayloadAction<string>) => {
      state.pmsTypeSelected = action.payload
    },
    setPmsTypeConnected: (state, action: PayloadAction<string>) => {
      state.pmsTypeConnected = action.payload
    },
    setPmsSyncAt: (state, action: PayloadAction<Date>) => {
      state.pmsSyncAt = action.payload
    },
    setOnboardingStep: (state, action: PayloadAction<string>) => {
      state.onboardingStep = action.payload
    },
    setOnboardingData: (state, action: PayloadAction<any>) => {
      state.onboardingData = action.payload
    },
    updateOnboardingData: (state, action: PayloadAction<any>) => {
      state.onboardingData = { ...state.onboardingData, ...action.payload }
    },
    setNumberOfGenesysAccount: (state, action: PayloadAction<number>) => {
      state.numberOfGenesysAccount = action.payload
    },
    setAirbnbViaHospitable: (state, action: PayloadAction<any[]>) => {
      state.airbnbViaHospitable = action.payload
    },
    setAirbnbViaChannex: (state, action: PayloadAction<AirbnbViaChannex | null>) => {
      state.airbnbViaChannex = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(handleNextOnboardingStep.fulfilled, (state, action) => {
      state.onboardingStep = action.payload
    }),
      builder.addCase(handleBackOnboardingStep.fulfilled, (state, action) => {
        state.onboardingStep = action.payload
      })
  },
})

// Action creators are generated for each case reducer function
export const {
  setIsConnectingMetamask,
  setChainId,
  setWalletAddress,
  setTotalPropertyAfterImport,
  setIsConnectedHostaway,
  setIsConnectedStripe,
  setIsOpenPopupConnectHostaway,
  setIsOpenPopupConnectUplisting,
  setIsOpenPopupConnectGS,
  setIsConnectedGS,
  setIsConnectedAirbnb,
  setAirbnbPmsType,
  setIsOpenConfirmLogout,
  setLogOutType,
  setOpenUserMenu,
  setHosawaySyncAt,
  setLoadingPms,
  setLoadingStripe,
  setIsConnectedUplisting,
  setIsConnectedRentalWise,
  setUplistingSyncAt,
  setGSSyncAt,
  setProfile,
  setProfilesData,
  openSelectProfile,
  setSettingData,
  setOnBoardingTotalListing,
  setOnBoardingFinishedLoadApi,
  setIsOpenPopupConnectPms,
  setPmsTypeSelected,
  setPmsTypeConnected,
  setPmsSyncAt,
  setOnboardingStep,
  setOnboardingData,
  updateOnboardingData,
  setNumberOfGenesysAccount,
  setAirbnbViaHospitable,
  setAirbnbViaChannex,
} = commonSlices.actions

export default commonSlices.reducer
