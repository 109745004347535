import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
  setAirbnbChannelId,
  setIsOpenChooseListingChannex,
  setIsOpenChooseListingHospitable,
  setIsOpenPopupInfoAirbnb,
  setIsOpenPopupSelectAccountAirbnb,
} from '../../redux/slices/listing'
import { joinChannexAndHospitableChannel } from '../../utils/property'
import BasicButton from '../ui/BasicButton'
import BasicDialog from '../ui/BasicDialog'
import React, { useEffect, useState } from 'react'

const AirbnbSelectAccount = () => {
  const dispatch = useAppDispatch()
  const { isOpenPopupSelectAccountAirbnb } = useAppSelector((state) => state.listing)
  const { airbnbViaHospitable, airbnbViaChannex } = useAppSelector((state) => state.user)
  const [listAirbnbChannel, setListAirbnbChannel] = useState<any[]>([])

  useEffect(() => {
    setListAirbnbChannel(joinChannexAndHospitableChannel(airbnbViaHospitable, airbnbViaChannex))
  }, [airbnbViaHospitable, airbnbViaChannex])

  const handleClose = () => {
    dispatch(setIsOpenPopupSelectAccountAirbnb(false))
  }

  const handleClickAddAnotherAcount = () => {
    dispatch(setIsOpenPopupInfoAirbnb(true)) // open popup airbnb info
    handleClose()
  }

  const handleClickAccountItem = (item: any) => {
    dispatch(setAirbnbChannelId(item.channelId))
    dispatch(item.type === 'channex' ? setIsOpenChooseListingChannex(true) : setIsOpenChooseListingHospitable(true))
    handleClose()
    return
  }

  return (
    <BasicDialog
      isOpen={isOpenPopupSelectAccountAirbnb}
      onClose={handleClose}
      title={<p className={'font-maison-neue text-24-28 text-neutral-800'}>Choose an Airbnb account</p>}
      extraTitle={
        <p className={'font-maison-neue text-14-20 text-neutral-600 w-full text-center mt-[4px]'}>
          Select which account to import from, or add a new one
        </p>
      }
    >
      <div className={'flex flex-col gap-4'}>
        {Array.isArray(listAirbnbChannel) &&
          listAirbnbChannel.length > 0 &&
          listAirbnbChannel.map((item, index) => {
            return (
              <div key={item.id} className={'flex justify-between items-center'}>
                <div>
                  <span className={'font-maison-neue-medium text-16-20 text-neutral-800'}>
                    {item.name || `Account ${index + 1}`}
                  </span>
                </div>
                <BasicButton
                  isRadius100={true}
                  size={'md'}
                  variant={'outlined'}
                  onClick={() => handleClickAccountItem(item)}
                >
                  Select
                </BasicButton>
              </div>
            )
          })}
        <div className={'flex justify-end'}>
          <BasicButton isRadius100={true} size={'md'} variant={'outlined'} onClick={handleClickAddAnotherAcount}>
            <span>{'Add another account'}</span>
          </BasicButton>
        </div>
      </div>
    </BasicDialog>
  )
}

export default AirbnbSelectAccount
