import { unifyEmailStatus, unifyRequest, updateAccountEmail } from '../../../api/user'
import { ConfirmEmailData, UserProfile } from '../../../interfaces/user'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { setLoading } from '../../../redux/slices/common'
import { genClientID, getTimeZoneName, isEmailValid } from '../../../utils/common'
import BasicButton from '../../ui/BasicButton'
import BasicDialog from '../../ui/BasicDialog'
import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import ic_circle_warning from 'src/assets/icons/ic_circle_warning.svg'
import ic_mail_open from 'src/assets/icons/ic_mail_open.svg'
import BasicInput from 'src/components/ui/BasicInput'
import { openSelectProfile, setProfilesData } from 'src/redux/slices/user'
import { getUserInfo, logOut, saveUserInfo } from 'src/utils/user'

interface Props {
  data: UserProfile | null
  fetchUserProfile: () => void
}

let interval: any = null

const defaultErrorMsg = { email: '' }
const SettingPersonalInfoEmail = ({ data, fetchUserProfile }: Props) => {
  const dispatch = useAppDispatch()
  const { isLoading } = useAppSelector((state) => state.common)
  const [email, setEmail] = useState<string>('')
  const [errorMsg, setErrorMsg] = useState(defaultErrorMsg)
  const [open, setOpen] = useState<'email' | 'confirm' | 'error' | null>(null)
  const isHasEmail = data?.user?.email

  useEffect(() => {
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [])

  const clearData = () => {
    setEmail('')
    setErrorMsg(defaultErrorMsg)
  }
  const handleClose = () => {
    setOpen(null)
    clearData()
  }

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.trim())
    setErrorMsg((prevState) => ({ ...prevState, email: '' }))
  }
  const onBlurEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isEmailValid(event.target.value)) {
      setErrorMsg((prevState) => ({
        ...prevState,
        email: 'Please enter a valid email.',
      }))
    }
  }

  // flow unify
  const handleTriggerStatus = (data: ConfirmEmailData) => {
    if (interval) clearInterval(interval)
    interval = setInterval(async () => {
      const res: any = await unifyEmailStatus(data)
      if (res?.data && res?.success) {
        if (interval) clearInterval(interval)
        if (res?.data?.existedProfile) {
          dispatch(setProfilesData(res?.data?.profiles))
          dispatch(openSelectProfile(true))
        } else {
          let newToken: any = { ...res?.data }
          delete newToken?.existedProfile
          const user = getUserInfo()
          saveUserInfo({ ...user, ...newToken })
          fetchUserProfile()
          setOpen(null)
        }
      }
    }, 500)
  }

  const handleUnifyEmail = async () => {
    try {
      setLoading(true)
      const clientToken = genClientID()
      const res: any = await unifyRequest({ email, timezone: getTimeZoneName(), clientToken })
      if (res.data) {
        setOpen('confirm')
        setErrorMsg(defaultErrorMsg)
        handleTriggerStatus({ token: res?.data?.token, clientToken })
      }
    } catch (error) {
      setOpen('error')
    } finally {
      setLoading(false)
    }
  }

  // const handleTriggerConfirmUpdateEmail = () => {}

  const handleUpdateEmail = async () => {
    try {
      setLoading(true)
      const res: any = await updateAccountEmail({ email, timezone: getTimeZoneName() })
      if (res.data) {
        // force logout
        logOut()
        // setOpen('confirm')
        // setErrorMsg(defaultErrorMsg)
      }
    } catch (error) {
      setOpen('error')
    } finally {
      setLoading(false)
    }
  }

  const handleClickVerify = async () => {
    if (data?.user?.email) {
      handleUpdateEmail()
    } else {
      handleUnifyEmail()
    }
  }

  const renderContent = () => {
    const hasChangeEmail = data?.user?.email && data?.user?.email !== email
    const disabledVerifyEmail = (open === 'email' && (!email || !isEmailValid(email))) || !hasChangeEmail
    return (
      <div className={'flex flex-col'}>
        {open === 'email' && (
          <>
            <div className={''}>
              <BasicInput
                label={''}
                placeholder={'Email address'}
                value={email}
                onChange={onChangeEmail}
                onBlur={onBlurEmail}
                maxLength={50}
              />
              {errorMsg.email && <span className={'text-red mt-2 ml-2 text-12-12'}>{errorMsg.email}</span>}
            </div>
            {isHasEmail && (
              <p className={'text-14-18 text-neutral-600 mt-[16px]'}>
                Your Dtravel account will be automatically logged out when you click “Verify” button. We emailed an
                authentication link to <span className={'font-maison-neue-medium text-neutral-800'}>{email}</span>,
                please tap the button in that email to log in again.
              </p>
            )}

            <div className={'mt-6 w-full'}>
              <BasicButton
                disabled={disabledVerifyEmail}
                onClick={handleClickVerify}
                loading={isLoading}
                variant={'contained'}
                color={'red'}
                clases={'w-full'}
                isRadius100
              >
                Verify
              </BasicButton>
            </div>
          </>
        )}
        {open === 'confirm' && (
          <>
            <p className={'font-maison-neue text-16-24 text-neutral-700 text-center'}>
              We emailed an authentication link to
            </p>
            <p className={'font-maison-neue-medium text-16-24 text-neutral-800 text-center'}>{email}.</p>
            <p className={'font-maison-neue text-16-24 text-neutral-700 text-center mb-'}>
              Tap the button in that email to verify.
            </p>
            <div className="flex bg-red-1 p-[16px] rounded-xl mt-[24px]">
              <img src={ic_circle_warning} alt="" style={{ width: 24, height: 24 }} />
              <div className="pl-2">
                <p className="text-14-20 font-maison-neue-medium text-red-6 mb-[2px]">Don’t close this tab!</p>
                <p className="text-12-16 font-maison-neue text-neutral-7">
                  You will be automatically logged in after clicking the authentication link.
                </p>
              </div>
            </div>
          </>
        )}
        {open === 'error' && (
          <>
            {renderIconTitle()}
            <p className="text-center mb-6 mt-4 font-editorial-new text-28-36">
              This email was already
              <br />
              connected with another
              <br />
              account.
            </p>
            <button
              className={clsx('bg-black px-3 rounded-xl flex justify-center items-center', {
                ['opacity-50']: disabledVerifyEmail,
                ['cursor-pointer']: !disabledVerifyEmail,
                ['cursor-not-allowed']: disabledVerifyEmail,
              })}
              disabled={disabledVerifyEmail}
              onClick={() => {
                setOpen('email')
                setEmail('')
                setErrorMsg(defaultErrorMsg)
              }}
            >
              <p className="text-16-20 font-maison-neue-medium text-white my-[14px]">Add another email</p>
            </button>
          </>
        )}
      </div>
    )
  }
  const renderIconTitle = () => {
    if (open === 'confirm' || open === 'error') {
      return (
        <div className="w-full flex items-center justify-center mb-4">
          <img src={ic_mail_open} alt="" style={{ width: 48, height: 48 }} />
        </div>
      )
    }
    return null
  }
  const renderContentPopup = () => {
    if (open === 'email')
      return {
        title: 'Email Address',
        subTitle: 'Used for login, account recovery, and important updates from Dtravel.',
      }
    if (open === 'confirm') return { title: 'Confirm your Email', subTitle: null }
    if (open === 'error') return { title: null, subTitle: null }
    return { title: '', subTitle: '' }
  }

  return (
    <>
      <BasicButton
        size={'md'}
        variant={'outlined'}
        clases={'w-auto'}
        onClick={() => {
          setOpen('email')
          if (data?.user?.email) setEmail(data?.user?.email)
        }}
        isRadius100
      >
        <span className="font-maison-neue-medium text-14-18 text-neutral-900">{isHasEmail ? 'Edit' : 'Add'}</span>
      </BasicButton>

      <BasicDialog
        isOpen={Boolean(open)}
        onClose={handleClose}
        title={renderContentPopup().title}
        extraTitle={renderContentPopup().subTitle}
        titleAlign={'center'}
        allowBackdropClick
      >
        {renderContent()}
      </BasicDialog>
    </>
  )
}

export default SettingPersonalInfoEmail
