import { setExpirationType } from '../../redux/slices/common'
import { renderDurationText } from '../../utils/common'
import SelectDuration from '../common/SelectDuration'
import BasicCheckbox from '../ui/BasicCheckbox'
import TooltipClick from '../ui/TooltipClick'
import Popover from '@mui/material/Popover'
import clsx from 'clsx'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import ic_calendar from 'src/assets/icons/ic_calendar.svg'
import ic_check_only_black from 'src/assets/icons/ic_check_only_black.svg'
import ic_chevron_down from 'src/assets/icons/ic_chevron_down_neutral_600.svg'
import SelectDateTime from 'src/components/common/SelectDateTime'
import { useAppSelector } from 'src/redux/hooks'

const EXPIRATION_TYPES: { type: 'datetime' | 'duration'; label: string }[] = [
  {
    type: 'datetime',
    label: 'Date & Time',
  },
  {
    type: 'duration',
    label: 'Duration',
  },
]

interface Props {
  maxDate?: moment.Moment | null
  timezone?: string
  isBlockedDates?: boolean
  onChangeIsBlockedDates: (_isBlocked: boolean) => void
}

const ReservationExpiration: React.FC<Props> = ({ maxDate, timezone, isBlockedDates, onChangeIsBlockedDates }) => {
  const dispatch = useDispatch()
  const [anchorElementExpirationType, setAnchorElementExpirationType] = useState<HTMLButtonElement | null>(null)
  const [anchorElementDateTime, setAnchorElementDateTime] = useState<HTMLInputElement | null>(null)
  const [anchorElementDuration, setAnchorElementDuration] = useState<HTMLInputElement | null>(null)
  const { dateTime, durationHour, expirationType } = useAppSelector((state) => state.common)

  const onCloseSelectExpirationType = () => {
    setAnchorElementExpirationType(null)
  }

  const onCloseSelectDateTime = () => {
    setAnchorElementDateTime(null)
  }

  const onCloseSelectDuration = () => {
    setAnchorElementDuration(null)
  }

  const onChangeType = (type: 'datetime' | 'duration') => {
    dispatch(setExpirationType(type))
    onCloseSelectExpirationType()
  }

  const onClickInputDateTime = (element: HTMLInputElement) => {
    const rect = element.getBoundingClientRect() // button element, where popup appear
    // popover height = 480
    // distance between popover and anchor element = 12
    const bottomPopover = rect.bottom + 480 + 12
    if (bottomPopover > window.innerHeight) {
      // popover missing content
      window.scrollTo({
        top: window.scrollY + (bottomPopover - window.innerHeight) + 20,
        behavior: 'smooth',
      })

      window.setTimeout(() => {
        setAnchorElementDateTime(element)
      }, 500)
    } else {
      setAnchorElementDateTime(element)
    }
  }

  const getLabelExpirationType = () => {
    const obj = EXPIRATION_TYPES.find((item) => item.type === expirationType)
    if (obj) return obj.label
    return 'Date & Time'
  }

  return (
    <div>
      <div className={'flex justify-between items-center'}>
        <span className={'font-maison-neue text-20-24 text-neutral-900'}>Expiration</span>
        <TooltipClick
          placement="bottom"
          maxWidth={294}
          title={'With an expiration date, this quote will only be available to book for a limited time only.'}
        >
          <span className={'font-maison-neue-medium text-14-14 text-neutral-6'}>Why do I need an expiration?</span>
        </TooltipClick>
      </div>

      <div className={'flex flex-col lg:flex-row gap-[12px] mt-[16px]'}>
        <button
          className={
            'flex items-center justify-between gap-[8px] px-[16px] bg-white rounded-[12px] h-[48px] min-w-[160px]  border-black-0.15 shadow-black-0.15 ' +
            `${anchorElementExpirationType ? 'border-[1px] border-neutral-900' : 'border-[0.5px] border-black-0.15'}`
          }
          onClick={(event) => {
            setAnchorElementExpirationType(event.currentTarget)
          }}
        >
          <span className={'font-maison-neue text-16-20 text-neutral-900'}>{getLabelExpirationType()}</span>
          <span>
            <img src={ic_chevron_down} alt={'ic_chevron_down'} />
          </span>
        </button>

        {/* Input select datetime */}
        <div className={`w-full relative ${expirationType === 'datetime' ? '' : 'hidden'}`}>
          <input
            className={clsx(
              'w-full h-[48px] text-16-20 pl-[48px] pr-[12px] py-3 rounded-[12px] text-neutral-900',
              'placeholder:text-neutral-500',
              'border-[0.5px] border-black-0.15',
              'focus:border-neutral-900 focus:outline-none',
              `${anchorElementDateTime ? 'border-[1px] border-neutral-900' : 'border-[0.5px] border-black-0.15'}`
            )}
            placeholder={'mm/dd/yyyy hh:mm'}
            readOnly={true}
            // onClick={(event) => setAnchorElementDateTime(event.currentTarget)}
            onClick={(event) => onClickInputDateTime(event.currentTarget)}
            value={dateTime ? moment(dateTime).format('MM/DD/YYYY HH:mm') + (timezone ? ` (${timezone})` : '') : ''}
            onChange={() => {}}
          />
          <img
            src={ic_calendar}
            alt={'ic_calendar_event'}
            className={'absolute left-[16px] top-0 bottom-0 mx-0 my-auto'}
          />
        </div>

        {/* Input select duration */}
        <div className={`w-full ${expirationType === 'duration' ? '' : 'hidden'}`}>
          <input
            className={clsx(
              'w-full h-[48px] text-16-20 px-[16px] py-3 rounded-[12px] text-neutral-900',
              'placeholder:text-neutral-600',
              'border-[0.5px] border-black-0.15',
              'focus:border-neutral-900 focus:outline-none',
              `${anchorElementDateTime ? 'border-[1px] border-neutral-900' : 'border-[0.5px] border-black-0.15'}`
            )}
            placeholder={'Choose an option...'}
            readOnly={true}
            onClick={(event) => setAnchorElementDuration(event.currentTarget)}
            value={renderDurationText(durationHour)}
            onChange={() => {}}
          />
        </div>
      </div>

      <div className={'mt-[16px]'}>
        <label
          htmlFor={'isBlockDates'}
          className={'flex items-center gap-[24px] font-maison-neue-medium text-16-20 text-neutral-900'}
        >
          <BasicCheckbox
            id={'isBlockDates'}
            checked={isBlockedDates}
            onChange={(event) => onChangeIsBlockedDates(event.target.checked)}
          />
          <span className={'mt-[8px]'}>Block the stay dates on the calendar until the reservation expires.</span>
        </label>
      </div>

      {/*--Select Type--*/}
      <Popover
        open={Boolean(anchorElementExpirationType)}
        anchorEl={anchorElementExpirationType}
        onClose={onCloseSelectExpirationType}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            width: '248px',
            borderRadius: '24px',
            backgroundColor: '#FFFFFF',
            padding: '0px 24px',
            boxShadow: '0px 16px 48px rgba(0, 0, 0, 0.16)',
          },
        }}
      >
        <div className={'font-maison-neue text-16-20 text-neutral-900'}>
          {EXPIRATION_TYPES.map((item) => (
            <button
              key={item.type}
              className={'flex items-center justify-between h-[64px] w-full'}
              style={{ boxShadow: '0px 1px 0px #E5E5E0' }}
              onClick={() => onChangeType(item.type)}
            >
              <span>{item.label}</span>
              <span>
                {expirationType === item.type && <img src={ic_check_only_black} alt={'ic_check_only_black'} />}
              </span>
            </button>
          ))}
        </div>
      </Popover>

      <SelectDateTime anchorEl={anchorElementDateTime} onClose={onCloseSelectDateTime} maxDate={maxDate} />

      <SelectDuration anchorEl={anchorElementDuration} onClose={onCloseSelectDuration} />
    </div>
  )
}

export default ReservationExpiration
