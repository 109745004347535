import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Tooltip from '@mui/material/Tooltip'
import clsx from 'clsx'
import React, { TextareaHTMLAttributes, forwardRef } from 'react'

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  isRequired?: boolean
  tooltip?: string
  classes?: string
  showCount?: boolean
}

const BasicTextarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ label, isRequired, tooltip, classes, showCount, ...props }, ref) => {
    return (
      <>
        <label className="block">
          {label && (
            <p className={'block font-maison-neue-medium text-14-18 text-neutral-800 mb-[8px]'}>
              {isRequired && <span className={'text-red'}>*</span>}
              &nbsp;
              {label}
              &nbsp;
              {tooltip && (
                <Tooltip placement="top" title={tooltip} sx={{ fontSize: 16 }} arrow>
                  <HelpOutlineIcon />
                </Tooltip>
              )}
            </p>
          )}
          <textarea
            ref={ref}
            className={clsx(
              'w-full text-16-24 px-4 py-3 rounded-xl resize-none',
              'border border-[0.5px] border-[#00000026] hover:border-neutral-900',
              'focus:border-[1px] focus:border-neutral-900 focus:outline-none',
              'shadow-basic',
              'hidden-scroll-bar',
              classes
            )}
            {...props}
          />
        </label>
        {showCount && props.maxLength && (
          <p className={'font-maison-neue text-14-18 text-neutral-500 mt-[8px]'}>
            {props.value ? String(props.value).length : 0}/{props.maxLength} characters
          </p>
        )}
      </>
    )
  }
)

export default BasicTextarea
