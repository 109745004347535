import { connectPms } from '../../../api/integration'
import { importListing } from '../../../api/listings'
import { updateHostSettings } from '../../../api/user'
import { PMS_TYPE } from '../../../contants/common'
import { CONFIG_PMS, ONBOARDING_STEP } from '../../../contants/user'
import { FormError } from '../../../interfaces'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { setToast } from '../../../redux/slices/common'
import {
  handleNextOnboardingStep,
  setPmsTypeConnected,
  setTotalPropertyAfterImport,
  updateOnboardingData,
} from '../../../redux/slices/user'
import { getKeys, handleErrorMessage } from '../../../utils/common'
import { renderPmsLabel } from '../../../utils/user'
import SelectPms from '../../settings/pms-and-channel/SelectPms'
import BasicButton from '../../ui/BasicButton'
import BasicInput from '../../ui/BasicInput'
import LayoutOnboarding from '../LayoutOnboarding'
import OnboardingDtravelApi from './OnboardingDtravelApi'
import RightContentPmsCm from './RightContentPmsCm'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

const OnboardingPmsSelect = () => {
  const dispatch = useAppDispatch()
  const { onboardingData, onboardingStep: step, pmsTypeConnected } = useAppSelector((state) => state.user)
  const [pmsTypeSelected, setPmsTypeSelected] = useState<string>('')
  const [otherPmsName, setOtherPmsName] = useState<string>('')
  const [havingTrouble, setHavingTrouble] = useState<boolean>(false)
  const [error, setError] = useState<{
    show: boolean
    code: string
    message: string
  }>({
    show: false,
    code: '',
    message: '',
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [data, setData] = useState<{
    clientId: string
    clientSecret: string
  }>({
    clientId: '',
    clientSecret: '',
  })
  const [errors, setErrors] = useState<{
    clientId: FormError
    clientSecret: FormError
  }>({
    clientId: {
      show: false,
      message: '',
    },
    clientSecret: {
      show: false,
      message: '',
    },
  })
  const isUseDtravelApi = [PMS_TYPE.GUESTY, PMS_TYPE.RENTAL_WISE].includes(pmsTypeSelected)

  useEffect(() => {
    return () => {
      setHavingTrouble(false)
    }
  }, [])

  useEffect(() => {
    if ([PMS_TYPE.HOSTAWAY, PMS_TYPE.UPLISTING, PMS_TYPE.GUEST_SMILES, PMS_TYPE.NEXTPAX].includes(pmsTypeSelected)) {
      let isErrorData = false
      let allowKeys = ['clientId', 'clientSecret']
      if (pmsTypeSelected === PMS_TYPE.UPLISTING) {
        allowKeys = ['clientSecret']
      }
      if (pmsTypeSelected === PMS_TYPE.GUEST_SMILES || pmsTypeSelected === PMS_TYPE.NEXTPAX) {
        allowKeys = ['clientId']
      }
      for (const key of getKeys(data)) {
        if (allowKeys.includes(key)) {
          if (!data[key] || !data[key].trim()) {
            isErrorData = true
          }
        }
      }
      for (const key of getKeys(errors)) {
        if (allowKeys.includes(key)) {
          if (errors[key].show) {
            isErrorData = true
          }
        }
      }
      setIsDisabled(isErrorData)
    }
    if ([PMS_TYPE.GUESTY, PMS_TYPE.RENTAL_WISE].includes(pmsTypeSelected)) {
      setIsDisabled(false)
    }
    if (pmsTypeSelected === PMS_TYPE.OTHER) {
      setIsDisabled(!otherPmsName)
    }
  }, [data, pmsTypeSelected, otherPmsName])

  useEffect(() => {
    if (pmsTypeSelected === PMS_TYPE.OTHER) {
      const pmsUsing = onboardingData.pmsUsing
      if (
        ![PMS_TYPE.HOSTAWAY, PMS_TYPE.UPLISTING, PMS_TYPE.GUEST_SMILES, PMS_TYPE.GUESTY, PMS_TYPE.RENTAL_WISE].includes(
          pmsUsing
        )
      ) {
        setOtherPmsName(pmsUsing === PMS_TYPE.OTHER ? '' : pmsUsing)
      }
    }
  }, [pmsTypeSelected, onboardingData, step])

  // handle selected PMS when click back
  useEffect(() => {
    // moveToStep(ONBOARDING_STEP.PMS_CONNECT_SUCCESS)
    if (pmsTypeConnected) {
      onSelectPms(pmsTypeConnected)
    } else if (onboardingData.pmsUsing) {
      const pmsUsing = onboardingData.pmsUsing
      if (
        [PMS_TYPE.HOSTAWAY, PMS_TYPE.UPLISTING, PMS_TYPE.GUEST_SMILES, PMS_TYPE.GUESTY, PMS_TYPE.RENTAL_WISE].includes(
          pmsUsing
        )
      ) {
        onSelectPms(pmsUsing)
      } else {
        onSelectPms(PMS_TYPE.OTHER)
      }
    }
  }, [step, pmsTypeConnected])

  const handleChangeData = (field: string, value: string) => {
    // only allow number for clientId ( Hostaway)
    if (pmsTypeSelected === PMS_TYPE.HOSTAWAY && field === 'clientId' && value && !/^[0-9]+$/.test(value)) {
      return
    }
    setData((prevState) => ({ ...prevState, [field]: value }))
    setError({
      show: false,
      code: '',
      message: '',
    })
    if (!value || !value.trim()) {
      setErrors((prevState) => ({
        ...prevState,
        [field]: {
          show: true,
          message: 'This field is required',
        },
      }))
    } else {
      setErrors((prevState) => ({
        ...prevState,
        [field]: {
          show: false,
          message: '',
        },
      }))
    }
  }

  const onSelectPms = async (_type: string) => {
    setPmsTypeSelected(_type)
    dispatch(updateOnboardingData({ pmsType: _type }))
    // clear data
    setData({
      clientId: '',
      clientSecret: '',
    })
    setErrors({
      clientId: {
        show: false,
        message: '',
      },
      clientSecret: {
        show: false,
        message: '',
      },
    })
    setError({
      show: false,
      code: '',
      message: '',
    })
    // update typeform survey
    if (_type !== PMS_TYPE.OTHER) {
      await updateHostSettings({ pmsUsing: _type })
    }
  }

  const onSubmit = async () => {
    if (isUseDtravelApi) {
      // go to site settings
      moveToStep(ONBOARDING_STEP.SITE_DETAILS_SITE_NAME)
    } else if (pmsTypeSelected === PMS_TYPE.OTHER) {
      // handle other
      handleRequestOtherPms()
    } else {
      // HA, Uplisting, GuestSmile
      handleConnectPms()
    }
  }

  const handleRequestOtherPms = async () => {
    try {
      setIsLoading(true)
      dispatch(updateOnboardingData({ pmsUsing: otherPmsName }))
      await updateHostSettings({ pmsUsing: otherPmsName })
      moveToStep(ONBOARDING_STEP.PMS_NOT_SUPPORTED)
    } catch (err) {
      handleErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }

  const moveToStep = async (step: string) => {
    dispatch(handleNextOnboardingStep(step))
  }

  const handleConnectPms = async () => {
    try {
      setIsLoading(true)
      let bodyData: any = {}
      let sucMsg = ''
      if (pmsTypeSelected === PMS_TYPE.HOSTAWAY) {
        bodyData = { clientId: data.clientId, clientSecret: data.clientSecret }
        sucMsg = 'Hostaway connected successfully'
      }
      if (pmsTypeSelected === PMS_TYPE.UPLISTING) {
        bodyData = { clientSecret: data.clientSecret }
        sucMsg = 'Uplisting connected successfully'
      }
      if (pmsTypeSelected === PMS_TYPE.GUEST_SMILES || pmsTypeSelected === PMS_TYPE.NEXTPAX) {
        bodyData = { clientId: data.clientId }
        sucMsg = 'Nextpax via GuestSmiles connected successfully'
      }
      await connectPms({ type: pmsTypeSelected, ...bodyData })
      const resImport = await importListing(pmsTypeSelected) // call import listing after connected
      dispatch(setTotalPropertyAfterImport(resImport?.data?.result || 0))
      // dispatch(setImportRequestId(resImport?.data?.requestId || ''))
      dispatch(setToast({ show: true, message: sucMsg, type: 'success' }))
      dispatch(setPmsTypeConnected(pmsTypeSelected)) // set pms connected
      // next step
      await moveToStep(ONBOARDING_STEP.PMS_CONNECT_SUCCESS)
    } catch (err: any) {
      let errMsg = err.error.message
      let errCdoe = err.error.code || err.error.statusCode
      if (err.error.code === 'H002' || err.error.code === 'H003') {
        errCdoe = 'Incorrect credentials'
      }
      if (err.error.code === 'H003') {
        if (pmsTypeSelected === PMS_TYPE.HOSTAWAY) {
          errMsg = 'Please add the correct account number and API key'
        }
        if (pmsTypeSelected === PMS_TYPE.UPLISTING) {
          errMsg = 'Please add the correct API key'
        }
        if (pmsTypeSelected === PMS_TYPE.GUEST_SMILES) {
          errMsg = 'Please add the correct GuestSmiles API Identifier'
        }
      }
      setError({
        show: true,
        code: errCdoe,
        message: errMsg,
      })
      setHavingTrouble(true)
    } finally {
      setIsLoading(false)
    }
  }

  const renderSubmitBtn = () => {
    return (
      <div className={'flex items-center gap-[16px]'}>
        {pmsTypeConnected ? (
          <BasicButton
            variant={'contained'}
            isRadius100={true}
            color={'red'}
            size={'xl'}
            onClick={() => moveToStep(ONBOARDING_STEP.DISTRIBUTION_CHANNELS)}
          >
            Continue
          </BasicButton>
        ) : (
          <BasicButton
            variant={'contained'}
            isRadius100={true}
            color={'red'}
            size={'xl'}
            onClick={onSubmit}
            disabled={isDisabled}
            loading={isLoading}
          >
            {isUseDtravelApi ? 'Continue' : 'Continue'}
          </BasicButton>
        )}

        {havingTrouble && (
          <div
            className={
              'flex items-start md:items-center flex-col md:flex-row font-maison-neue text-14-20 text-neutral-500'
            }
          >
            <span>Having trouble?&nbsp;</span>
            <button className={'underline'} onClick={() => moveToStep(ONBOARDING_STEP.NEED_SUPPORT)}>
              Let our team help you setup
            </button>
          </div>
        )}
      </div>
    )
  }

  return (
    <LayoutOnboarding headerTitle={'Listings'} rightContent={<RightContentPmsCm />}>
      <div className={'mt-[38px] lg:mt-[70px] w-full mx-auto md:max-w-[524px]'}>
        <h2 className={'font-maison-neue text-24-36 text-neutral-800 mb-[16px]'}>
          Let’s sync your listings from your PMS or CM.
        </h2>
        <p className={'font-maison-neue text-16-24 text-neutral-800'}>
          Which provider are you using to manage your inventory?
        </p>

        <div className={'mt-[24px]'}>
          {pmsTypeConnected ? (
            <button
              className={clsx(
                'h-[48px] rounded-[12px] w-full flex items-center gap-[8px] px-[14px]',
                'font-maison-neue text-16-20',
                'disabled:cursor-not-allowed disabled:text-neutral-500 disabled:bg-neutral-50'
              )}
              disabled={true}
            >
              <span>{renderPmsLabel(pmsTypeConnected)}</span>
              <span className="font-maison-neue-medium text-forest-6 uppercase text-12-16 bg-forest-1 px-2 py-[1px] rounded-[100px] tracking-[.04em]">
                Connected
              </span>
            </button>
          ) : (
            <SelectPms placeholder={'Select from...'} onSelect={onSelectPms} pmsSelected={pmsTypeSelected} />
          )}
        </div>

        {/* Note */}
        {[
          PMS_TYPE.HOSTAWAY,
          PMS_TYPE.UPLISTING,
          PMS_TYPE.GUEST_SMILES,
          PMS_TYPE.NEXTPAX,
          PMS_TYPE.GUESTY,
          PMS_TYPE.RENTAL_WISE,
        ].includes(pmsTypeSelected) && (
          <div className={'p-[16px] mt-[24px] font-maison-neue text-14-20 text-black rounded-[8px] bg-red-100'}>
            <p className={'whitespace-pre-line'}>
              {CONFIG_PMS[pmsTypeSelected].description}{' '}
              {CONFIG_PMS[pmsTypeSelected]?.link && (
                <a className={'underline'} target={'_blank'} rel="noreferrer" href={CONFIG_PMS[pmsTypeSelected].link}>
                  Learn how to connect.
                </a>
              )}
            </p>
          </div>
        )}

        {!pmsTypeConnected && (
          <div className={'mt-[24px]'}>
            {[PMS_TYPE.GUESTY, PMS_TYPE.RENTAL_WISE].includes(pmsTypeSelected) && <OnboardingDtravelApi />}

            {[PMS_TYPE.HOSTAWAY, PMS_TYPE.UPLISTING, PMS_TYPE.GUEST_SMILES, PMS_TYPE.NEXTPAX].includes(
              pmsTypeSelected
            ) && (
              <div className={'grid grid-cols-1 gap-[24px]'}>
                {[PMS_TYPE.HOSTAWAY, PMS_TYPE.GUEST_SMILES, PMS_TYPE.NEXTPAX].includes(pmsTypeSelected) && (
                  <div>
                    <BasicInput
                      label={
                        [PMS_TYPE.GUEST_SMILES, PMS_TYPE.NEXTPAX].includes(pmsTypeSelected)
                          ? 'GuestSmiles API Identifier'
                          : 'Account ID'
                      }
                      pattern="[0-9]*"
                      inputMode="numeric"
                      type="password"
                      name="clientId"
                      autoComplete="false"
                      showIconPassword
                      showPasswordFirstTime
                      value={data.clientId}
                      onChange={(event) => handleChangeData('clientId', event.target.value)}
                      error={errors.clientId}
                      disabled={!!pmsTypeConnected}
                    />
                  </div>
                )}
                {[PMS_TYPE.HOSTAWAY, PMS_TYPE.UPLISTING].includes(pmsTypeSelected) && (
                  <div>
                    <BasicInput
                      label={'API key'}
                      type="password"
                      name="clientSecret"
                      autoComplete="false"
                      showIconPassword
                      showPasswordFirstTime
                      value={data.clientSecret}
                      onChange={(event) => handleChangeData('clientSecret', event.target.value)}
                      error={errors.clientSecret}
                      disabled={!!pmsTypeConnected}
                    />
                  </div>
                )}

                {error.show && (
                  <div className={'p-4 bg-red-1 rounded-xl flex gap-3'}>
                    <span>
                      <ErrorOutlineOutlinedIcon sx={{ color: '#A32A30' }} />
                    </span>
                    <div className={'w-full break-all'}>
                      <p className={'text-16-20 text-red-6'}>{error.code}</p>
                      <p className={'text-12-16 text-neutral-700 '}>{error.message}</p>
                    </div>
                  </div>
                )}
              </div>
            )}

            {PMS_TYPE.OTHER === pmsTypeSelected && (
              <BasicInput
                label={'PMS name'}
                placeholder={'Add your PMS name...'}
                value={otherPmsName}
                onChange={(event) => setOtherPmsName(event.target.value)}
              />
            )}
          </div>
        )}

        <div className={'mt-[24px] hidden lg:block'}>{renderSubmitBtn()}</div>
      </div>

      <div
        className={clsx(
          'fixed lg:hidden bottom-0 left-0 bg-white lg:bg-tr py-6 w-full',
          'border-t border-neutral-300 px-4 md:px-8',
          'shadow-footer'
        )}
      >
        {renderSubmitBtn()}
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingPmsSelect
