import { getCalendarHost } from '../../api/listings'
import { getICalCalendar } from '../../api/native-listing'
import ic_arrow_right from '../../assets/icons/ic_arrow_right.svg'
import ic_check_big from '../../assets/icons/ic_check_big.svg'
import { DATE_FORMAT, PMS_TYPE } from '../../contants/common'
import { PROPERTY_STATUS } from '../../contants/native'
import { PropertyDetailProps } from '../../interfaces/listing'
import { renderPropertyInfo } from '../../utils/property'
import RenderPrice from '../common/RenderPrice'
import BlockUnavailableDates from '../native-listing/availability/BlockUnavailableDates'
import ExportICalCalendar from '../native-listing/availability/ExportICalCalendar'
import ICalListing from '../native-listing/availability/ICalListing'
import ImportICalCalendar from '../native-listing/availability/ImportICalCalendar'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import BasicCardV2 from 'src/components/common/BasicCardV2'

interface Props {
  data: PropertyDetailProps
  onClickEdit: () => void
  avgPrice?: number
  userId?: string
}

const DetailListingNative: React.FC<Props> = ({ data, onClickEdit, userId }) => {
  const [listICal, setListICal] = useState<any[]>([])
  const [isViewMore, setIsViewMore] = useState<boolean>(true)
  const [avgPrice, setAvgPrice] = useState<number | null>(null)
  const [dateRanges, setDateRanges] = useState<any[]>([])
  const isHospitable = data?.pmsType === PMS_TYPE.HOSPITABLE

  useEffect(() => {
    if (data && data.id) {
      fetchICalCalendar(String(data.id))
    }
  }, [data.id])

  useEffect(() => {
    async function fetchAvgPriceCalendar(userId: string, propertyId: string, currency: string) {
      const startDate = moment().format(DATE_FORMAT)
      const endDate = moment().add(30, 'day').format(DATE_FORMAT)
      const res = await getCalendarHost(userId, propertyId, startDate, endDate, currency)
      if (Array.isArray(res.data) && res.data.length > 0) {
        const resFilter = res.data
          .filter((item: any) => item.price > 0)
          .slice(0, 7)
          .map((item: any) => item.price)
        const sum = resFilter.reduce((acc: any, price: number) => {
          return acc + price
        }, 0)
        setAvgPrice(sum / resFilter.length)
      }
    }

    if (data && data.propertyPrice && userId) {
      if (Number(data.propertyPrice?.basePrice) === -1) {
        // fetch calendar to calculate avg price
        fetchAvgPriceCalendar(userId, String(data.id), data.propertyPrice.currency)
      }
    }
  }, [data, userId])

  const updateDateRanges = (newDateRanges: any[]) => {
    setDateRanges(newDateRanges)
  }

  async function fetchICalCalendar(_propertyId: string) {
    const res = await getICalCalendar(_propertyId)
    setListICal(res.data)
  }

  const handleToggleViewMore = () => {
    setIsViewMore((prevState) => !prevState)
  }

  const getDefaultName = () => {
    if (data) {
      const { propertyTypeName, address } = data
      return `${propertyTypeName} in ${address?.city}`
    }
    return ''
  }

  return (
    <BasicCardV2
      title={'Listing details'}
      extraTitle={
        <button
          className={'flex items-center text-14-18 font-maison-neue-medium text-neutral-600 hover:underline'}
          onClick={() => onClickEdit()}
        >
          <span>Edit</span>
          <span className={'ml-[4px]'}>
            <img src={ic_arrow_right} alt="" style={{ width: 16, height: 16 }} />
          </span>
        </button>
      }
    >
      <div className={'flex flex-col md:flex-row justify-between items-start md:items-center gap-[16px]'}>
        <div>
          <p className={'font-maison-neue text-20-32 text-neutral-800'}>{data.internalName || getDefaultName()}</p>
          <p className={'font-maison-neue text-16-24 text-neutral-800'}>{renderPropertyInfo(data)} &nbsp;</p>
        </div>
        <div className="flex flex-col">
          <p className="text-12-16 text-neutral-800 font-maison-neue text-left md:text-right whitespace-nowrap">
            Starting from
          </p>
          <p className="text-20-32 text-neutral-800 font-maison-neue text-left md:text-right whitespace-nowrap">
            {Number(data.propertyPrice?.basePrice) >= 0 || avgPrice !== null ? (
              <RenderPrice
                price={
                  Number(data.propertyPrice?.basePrice) >= 0 ? Number(data.propertyPrice?.basePrice) : avgPrice || 0
                }
                currency={data.propertyPrice?.currency || 'USD'}
                isRounded={true}
                isUseSymbol={true}
              />
            ) : (
              '--'
            )}
            /night
          </p>
        </div>
      </div>
      {/*<div className={'mt-[24px]'}>{data.description}</div>*/}
      <div className={'mt-[24px] whitespace-pre-line'} dangerouslySetInnerHTML={{ __html: data.description }} />
      {Array.isArray(data.amenities) && data.amenities.length > 0 && (
        <div className={'mt-[24px]'}>
          <p className={'font-maison-neue text-16-20 text-neutral-800 mb-[8px]'}>Includes:</p>
          <div className={'mt-[8px] grid grid-cols-1 md:grid-cols-2 gap-[8px]'}>
            {data.amenities.map((item, index) => {
              if (isViewMore && index > 8) {
                return null
              }
              return (
                <div
                  key={item.id}
                  className={'font-maison-neue text-16-20 text-neutral-800 flex items-center gap-[8px]'}
                >
                  <span className={'min-w-[16px]'}>
                    <img src={ic_check_big} alt={'ic_check_big'} width={'16px'} height={'16px'} />
                  </span>
                  <span>{item.name}</span>
                </div>
              )
            })}
            {Array.isArray(data.amenities) && data.amenities.length > 9 && (
              <div>
                <button
                  className={'font-maison-neue text-16-20 text-neutral-800 underline'}
                  onClick={handleToggleViewMore}
                >
                  {isViewMore ? `and ${data.amenities.length - 9} more` : 'Less'}
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {isHospitable ? (
        ''
      ) : (
        <>
          <div className={'my-[24px] bg-neutral-300 h-[1px] w-full'} />
          <div className={'flex flex-col md:flex-row items-start gap-[24px] md:gap-[16px]'}>
            <div className={'flex flex-col gap-[8px] w-full'}>
              <p className={'font-maison-neue-medium text-16-20 text-neutral-800 pb-[8px]'}>Sync via a calendar</p>
              {/* List iCal */}
              {Array.isArray(listICal) && listICal.length > 0 && (
                <div className={''}>
                  <ICalListing propertyId={String(data.id)} listICal={listICal} fetchICal={fetchICalCalendar} />
                </div>
              )}

              <div className={'flex flex-col lg:flex-row gap-[8px]'}>
                <ImportICalCalendar propertyId={String(data.id)} fetchICal={fetchICalCalendar} />
                <ExportICalCalendar propertyId={String(data.id)} />
              </div>
            </div>
            <div className={'flex flex-col gap-[8px] w-full'}>
              <p className={'font-maison-neue-medium text-16-20 text-neutral-800 pb-[8px]'}>Block unavailable dates</p>
              <div>
                <BlockUnavailableDates
                  propertyId={String(data.id)}
                  hasViewMore={true}
                  dateRanges={dateRanges}
                  updateDateRanges={updateDateRanges}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {/* ---Warning message for native listing draft and pending review--- */}
      {[PROPERTY_STATUS.PENDING_REVIEW, PROPERTY_STATUS.DRAFT].includes(data.status) && (
        <div className={'mt-[24px] px-[24px] py-[16px] bg-sun-50 rounded-[16px]'}>
          <p className={'font-maison-neue-medium text-14-18 text-sun-700'}>
            {data.status === PROPERTY_STATUS.PENDING_REVIEW && <span>Your listing is being reviewed by Dtravel.</span>}
            {data.status === PROPERTY_STATUS.DRAFT && (
              <span>
                Your listing is currently not active on your Dtravel direct booking site. Click Edit to complete all
                details and request access to the beta.
              </span>
            )}
          </p>
        </div>
      )}
    </BasicCardV2>
  )
}

export default DetailListingNative
