import Switch, { SwitchProps } from '@mui/material/Switch'
import { styled } from '@mui/material/styles'
import React from 'react'

const ReservationCustomSwitch = styled((props: SwitchProps) => (
  <Switch
    checkedIcon={
      <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} viewBox="0 0 25 24" fill="white">
        <g filter="white">
          <circle cx={13} cy={12} r={10} fill="white" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0485 8.02241C19.2926 8.26649 19.2926 8.66222 19.0485 8.9063L11.9775 15.9774C11.7334 16.2214 11.3377 16.2214 11.0936 15.9774L7.55806 12.4418C7.31398 12.1978 7.31398 11.802 7.55806 11.5579C7.80214 11.3139 8.19786 11.3139 8.44194 11.5579L11.5355 14.6515L18.1647 8.02241C18.4087 7.77834 18.8045 7.77834 19.0485 8.02241Z"
          fill="#292926"
        />
        <defs>
          <filter
            id="filter0_dd_3161_8144"
            x={0}
            y={0}
            width={26}
            height={26}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy={1} />
            <feGaussianBlur stdDeviation={1} />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3161_8144" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy={1} />
            <feGaussianBlur stdDeviation="1.5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
            <feBlend mode="normal" in2="effect1_dropShadow_3161_8144" result="effect2_dropShadow_3161_8144" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3161_8144" result="shape" />
          </filter>
        </defs>
      </svg>
    }
    icon={
      <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} viewBox="0 0 25 24" fill="white">
        <g filter="white">
          <circle cx={12} cy={12} r={10} fill="white" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.22481 8.22456C8.46888 7.98048 8.86461 7.98048 9.10869 8.22456L15.7753 14.8912C16.0194 15.1353 16.0194 15.531 15.7753 15.7751C15.5313 16.0192 15.1355 16.0192 14.8915 15.7751L8.22481 9.10845C7.98073 8.86437 7.98073 8.46864 8.22481 8.22456Z"
          fill="#CFCFCB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7754 8.22456C16.0195 8.46864 16.0195 8.86437 15.7754 9.10845L9.10869 15.7751C8.86461 16.0192 8.46888 16.0192 8.22481 15.7751C7.98073 15.5311 7.98073 15.1353 8.22481 14.8912L14.8915 8.22456C15.1356 7.98048 15.5313 7.98048 15.7754 8.22456Z"
          fill="#CFCFCB"
        />
        <defs>
          <filter
            id="filter0_dd_3161_8149"
            x={-1}
            y={0}
            width={26}
            height={26}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy={1} />
            <feGaussianBlur stdDeviation={1} />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3161_8149" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy={1} />
            <feGaussianBlur stdDeviation="1.5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
            <feBlend mode="normal" in2="effect1_dropShadow_3161_8149" result="effect2_dropShadow_3161_8149" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3161_8149" result="shape" />
          </filter>
        </defs>
      </svg>
    }
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    // margin: 2,
    transitionDuration: '300ms',
    backgroundColor: 'rgba(25, 118, 210, 0.04)',
    '&:hover': {
      backgroundColor: 'rgba(25, 118, 210, 0.04)'
    },
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      backgroundColor: 'rgba(25, 118, 210, 0.04)',
      '&:hover': {
        backgroundColor: 'rgba(25, 118, 210, 0.04)'
      },
      '& + .MuiSwitch-track': {
        backgroundColor: '#292926',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    // '&.Mui-disabled .MuiSwitch-thumb': {
    //   color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    // },
    // '&.Mui-disabled + .MuiSwitch-track': {
    //   opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    // },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.04)',
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    backgroundColor: '#E3E3DE' /* Neutral/300 */,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

export default ReservationCustomSwitch
