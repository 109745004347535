import { ReservationStatusEnum } from '../../interfaces/reservations'
import React from 'react'

interface Props {
  status: string
  isHaveDot?: boolean
}

const RenderReservationStatus: React.FC<Props> = ({ status, isHaveDot }) => {
  const renderClassBackGround = (type: 'bg' | 'text' | 'dot') => {
    if (status === ReservationStatusEnum.NEW || status === ReservationStatusEnum.MODIFIED) {
      return type === 'bg' ? 'bg-forest-50' : type === 'text' ? 'text-forest-700' : 'bg-forest-700'
    }
    if (
      status === ReservationStatusEnum.CANCELLED ||
      status === ReservationStatusEnum.FAILED ||
      status === ReservationStatusEnum.MANUAL_CANCELLED
    ) {
      return type === 'bg' ? 'bg-red-50' : type === 'text' ? 'text-red-700' : 'bg-red-700'
    }
    if (
      status === ReservationStatusEnum.AWAITING_PAYMENT ||
      status === ReservationStatusEnum.INQUIRY ||
      status === ReservationStatusEnum.EXPIRED
    ) {
      return type === 'bg' ? 'bg-sun-50' : type === 'text' ? 'text-sun-700' : 'bg-sun-700'
    }
    if (status === ReservationStatusEnum.COMPLETED) {
      return type === 'bg' ? 'bg-neutral-100' : type === 'text' ? 'text-neutral-700' : 'bg-neutral-700'
    }
    return ''
  }

  const mapStatus = (_stt: string) => {
    switch (_stt) {
      case ReservationStatusEnum.NEW:
        return 'confirmed'
      case ReservationStatusEnum.AWAITING_PAYMENT:
        return 'processing'
      case ReservationStatusEnum.INQUIRY:
        return 'awaiting payment'
      case ReservationStatusEnum.MANUAL_CANCELLED:
        return 'cancelled'
      default:
        return _stt
    }
  }

  return (
    <div
      className={`${renderClassBackGround(
        'bg'
      )} rounded-[100px] px-[10px] py-[2px] inline-block w-fit flex gap-[8px] items-center`}
    >
      {isHaveDot ? <div className={`w-[6px] h-[6px] rounded-full ${renderClassBackGround('dot')}`}></div> : null}
      <span className={`${renderClassBackGround('text')} uppercase text-12-16 whitespace-nowrap`}>
        {mapStatus(status)}
      </span>
    </div>
  )
}

export default RenderReservationStatus
