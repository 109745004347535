import { IconFacebook, IconInstagram, IconLinkedIn, IconTiktok, IconTwitterBlue, IconYoutube } from '../../common/Icons'
import React, { useEffect, useState } from 'react'
import { updateHostSettings } from 'src/api/user'
import ic_close from 'src/assets/icons/ic_close_md.svg'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import BasicInput from 'src/components/ui/BasicInput'
import { SETUP_GUIDE_V2 } from 'src/contants/common'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { setSettingData } from 'src/redux/slices/user'
import { capitalizeString, handleErrorMessage, isEmpty } from 'src/utils/common'

const defaultSocialMedia: any = {
  google: '',
  airbnb: '',
  instagram: '',
  facebook: '',
  twitter: '',
  linkedin: '',
  tiktok: '',
  youtube: '',
}

const LIST_SOCIAL_MEDIA: string[] = ['twitter', 'facebook', 'instagram', 'linkedin', 'tiktok', 'youtube']

const SettingSocialMedia = () => {
  const dispatch = useAppDispatch()
  const { settingData } = useAppSelector((state) => state.user)
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [socialMedial, setSocialMedial] = useState<any>(defaultSocialMedia)
  const [selectedSocial, setSelectedSocial] = useState<string[]>([])

  const isEdit =
    !isEmpty(settingData?.instagram) ||
    !isEmpty(settingData?.facebook) ||
    !isEmpty(settingData?.twitter) ||
    !isEmpty(settingData?.linkedin) ||
    !isEmpty(settingData?.google) ||
    !isEmpty(settingData?.airbnb) ||
    !isEmpty(settingData?.youtube)

  useEffect(() => {
    if (open) {
      setSocialMedial({
        instagram: settingData?.instagram || defaultSocialMedia?.instagram,
        facebook: settingData?.facebook || defaultSocialMedia?.facebook,
        twitter: settingData?.twitter || defaultSocialMedia?.twitter,
        linkedin: settingData?.linkedin || defaultSocialMedia?.linkedin,
        tiktok: settingData?.tiktok || defaultSocialMedia?.tiktok,
        google: settingData?.google || defaultSocialMedia?.google,
        airbnb: settingData?.airbnb || defaultSocialMedia?.airbnb,
        youtube: settingData?.youtube || defaultSocialMedia?.youtube,
      })
    }
  }, [open, settingData])

  useEffect(() => {
    let _selected: string[] = []
    if (open && settingData) {
      LIST_SOCIAL_MEDIA.forEach((item) => {
        if (settingData[item]) {
          _selected.push(item)
        }
      })
      setSelectedSocial(_selected)
    }
  }, [open, settingData])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onClickSocialMediaLogo = (type: string) => {
    setSelectedSocial((prevState) => [...prevState, type])
  }

  const onClickCloseOneRow = (type: string) => {
    setSelectedSocial((prevState) => {
      return prevState.filter((item) => item !== type)
    })
    setSocialMedial((prevState: any) => {
      return { ...prevState, [type]: '' }
    })
  }

  const convertValue = (value: string) => {
    let inputText = value
    if (value !== '') {
      const prefixString = 'https://'
      const dounblePrefixString = prefixString + prefixString
      if (!inputText.startsWith(prefixString)) {
        inputText = prefixString + inputText
      } else if (inputText.startsWith(dounblePrefixString)) {
        inputText = prefixString + inputText.replace(dounblePrefixString, '')
      }
    }
    return inputText
  }

  const onSubmit = async () => {
    try {
      let data = { ...socialMedial }
      if (!isEmpty(socialMedial)) {
        Object.keys(socialMedial).forEach((key: string) => {
          data[key] = convertValue(socialMedial[key])
        })
      }
      setLoading(true)
      const res = await updateHostSettings(data)
      if (res.success && res.data) {
        dispatch(setToastSuccess({ message: `Update success!` }))
        dispatch(setSettingData({ ...settingData, ...res.data }))
        handleClose()
      }
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setLoading(false)
    }
  }

  const renderLogo = (type: string) => {
    switch (type) {
      case 'twitter':
        return <IconTwitterBlue />
      case 'facebook':
        return <IconFacebook />
      case 'instagram':
        return <IconInstagram />
      case 'linkedin':
        return <IconLinkedIn />
      case 'tiktok':
        return <IconTiktok />
      case 'youtube':
        return <IconYoutube />
    }
  }

  const renderPlaceholder = (type: string) => {
    switch (type) {
      case 'google':
        return 'https://maps.app.goo.gl/XXXXXXXXX'
      case 'airbnb':
        return 'https://airbnb.com/users/show/12345678'
      case 'twitter':
        return 'https://twitter.com/john.doe'
      case 'facebook':
        return 'https://facebook.com/john.doe'
      case 'instagram':
        return 'https://instagram.com/john.doe'
      case 'linkedin':
        return 'https://linkedin.com/john.doe'
      case 'tiktok':
        return 'https://tiktok.com/@john.doe'
      case 'youtube':
        return 'https://youtube.com/@john.doe'
    }
  }
  const handlChangeInput = (e: any, type: string) => {
    let inputText = e.target.value.trim()
    setSocialMedial({ ...socialMedial, [type]: inputText })
  }
  const _moreSocialMedia = LIST_SOCIAL_MEDIA.filter((item) => !selectedSocial.includes(item))
  return (
    <div
      className={'flex flex-col md:flex-row justify-between items-start gap-[12px]'}
      id={SETUP_GUIDE_V2.ADD_EXTERNAL_ACCOUNTS}
    >
      <div>
        <p className={'font-maison-neue-medium text-neutral-800 text-16-20'}>External Accounts</p>
        <p className={'font-maison-neue text-neutral-600 text-14-18 mt-[4px]'}>
          Add your Google Business, Airbnb, and social media accounts to increase trust and grow your brand’s online
          presence.
        </p>
      </div>
      <BasicButton size={'md'} variant={'outlined'} clases={'w-full md:w-auto'} isRadius100 onClick={handleOpen}>
        {isEdit ? 'Update' : 'Add'}
      </BasicButton>
      <BasicDialog
        isOpen={open}
        title={'External Accounts'}
        extraTitle={
          'Add your Google Business, Airbnb, and social media accounts to increase trust and grow your brand’s online presence.'
        }
        onClose={handleClose}
        allowBackdropClick
        titleAlign={'center'}
      >
        <div className="flex flex-col gap-[24px]">
          {[
            { name: 'Google Business', type: 'google' },
            { name: 'Airbnb', type: 'airbnb' },
          ].map((el: any, idx: number) => {
            return (
              <div className="flex flex-col gap-[8px]" key={idx}>
                <span className="text-14-18 font-maison-neue-medium text-neutral-800">{el.name}</span>
                <BasicInput
                  placeholder={renderPlaceholder(el.type)}
                  value={socialMedial[el.type] || ''}
                  onChange={(e: any) => handlChangeInput(e, el.type)}
                />
                {el.type === 'google' && (
                  <p className={'font-maison-neue text-14-18 text-neutral-500'}>
                    Learn more{' '}
                    <a
                      target={'_blank'}
                      rel="noreferrer"
                      href={'https://dtravel.com/help/google-business'}
                      className={'font-maison-neue-medium text-14-18 text-neutral-900'}
                    >
                      here
                    </a>
                  </p>
                )}
              </div>
            )
          })}

          {selectedSocial.length > 0 && (
            <div className={'flex flex-col gap-[24px]'}>
              {selectedSocial.map((type) => {
                return (
                  <div key={type} className={'flex gap-[8px] items-end'}>
                    <BasicInput
                      label={capitalizeString(type)}
                      placeholder={renderPlaceholder(type)}
                      value={socialMedial[type] || ''}
                      onChange={(e: any) => handlChangeInput(e, type)}
                    />
                    <button
                      className={
                        'flex items-center justify-center w-[48px] h-[48px] min-w-[48px] rounded-full border border-neutral-300'
                      }
                      onClick={() => onClickCloseOneRow(type)}
                    >
                      <img src={ic_close} alt={'ic_close'} />
                    </button>
                  </div>
                )
              })}
            </div>
          )}

          {Array.isArray(_moreSocialMedia) && _moreSocialMedia.length > 0 && (
            <div>
              <p className="text-14-18 font-maison-neue-medium text-neutral-800">
                And more social media accounts to add
              </p>
              <div className={'flex gap-[8px] mt-[8px] overflow-x-auto pb-[12px] md:pb-0 '}>
                {_moreSocialMedia.map((type) => {
                  return (
                    <button
                      key={type}
                      className={
                        'flex items-center justify-center w-[48px] h-[48px] min-w-[48px] rounded-full border border-neutral-300 ' +
                        'hover:bg-neutral-200 active:bg-neutral-200'
                      }
                      onClick={() => onClickSocialMediaLogo(type)}
                    >
                      {renderLogo(type)}
                    </button>
                  )
                })}
              </div>
            </div>
          )}

          <BasicButton
            variant={'contained'}
            size={'xl'}
            isRadius100={true}
            color="red"
            loading={loading}
            onClick={onSubmit}
          >
            Save
          </BasicButton>
        </div>
      </BasicDialog>
    </div>
  )
}

export default SettingSocialMedia
