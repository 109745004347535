import { FormError } from '../../../interfaces'
import BasicInputPrice from '../../ui/BasicInputPrice'
import BasicSelect from '../../ui/BasicSelect'
import BasicSwitch from '../../ui/BasicSwitch'
import React from 'react'

interface Props {
  cleaningFee: any
  toggleFee: (checked: boolean) => void
  onChangeCleaningFee: (field: 'value' | 'appliedPer', value: number | string | undefined) => void
  error?: FormError
  currency: string
  isEnable?: boolean
  hiddenTitle?: boolean
}

const RenderCleaningFee: React.FC<Props> = ({
  cleaningFee,
  onChangeCleaningFee,
  error,
  currency,
  isEnable,
  toggleFee,
  hiddenTitle,
}) => {
  return (
    <div className="p-[24px] bg-white w-full mb-[32px] rounded-[16px]">
      {!hiddenTitle && (
        <>
          <h3 className={'font-maison-neue text-20-32 text-neutral-800'}>Cleaning fee</h3>
          <p className={'font-maison-neue text-14-20 text-neutral-600 mt-[4px]'}>
            Add cleaning fee to guest reservations
          </p>
        </>
      )}

      <div className={'w-full flex flex-col md:flex-row gap-[16px] items-start my-[16px]'}>
        <div className={'w-full md:w-[58%]'}>
          <label htmlFor={'cleaning_fee'} className={'flex items-center gap-[16px] h-[48px]'}>
            <BasicSwitch
              id={'cleaning_fee'}
              checkedColor="#292926"
              checked={Boolean(isEnable)}
              onChange={(event) => toggleFee(event.target.checked)}
            />
            <span className={'font-maison-neue text-16-20 text-neutral-800'}>Cleaning fee</span>
          </label>
        </div>
        <div className={'flex justify-between items-start gap-[16px]'}>
          <div className={'w-1/2 md:min-w-[160px] md:max-w-[160px]'}>
            <BasicInputPrice
              currency={currency}
              value={cleaningFee.value}
              onValueChange={(values) => {
                let _val: number | string = values.value.replaceAll('-', '').replaceAll(' ', '')
                _val = _val === '' ? _val : Number(_val)
                onChangeCleaningFee('value', _val)
              }}
              disabled={!isEnable}
              error={error}
            />
          </div>
          <div className={'w-1/2 md:min-w-[128px] md:w-full'}>
            <BasicSelect
              options={[
                { label: 'per stay', value: 'reservation' },
                { label: 'per night', value: 'night' },
              ]}
              classes={'w-full'}
              value={cleaningFee.appliedPer}
              onChange={(event) => onChangeCleaningFee('appliedPer', event.target.value)}
              disabled={!isEnable}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RenderCleaningFee
