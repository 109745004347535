import React from 'react'

interface Props {
  title?: string
  extraTitle?: string | React.ReactNode | React.ReactChild | React.ReactElement
  children?: any
}

const BasicCard: React.FC<Props> = ({ title, extraTitle, children }) => {
  return (
    <div className={'px-[16px] py-[32px] md:px-[32px] mb-[16px] bg-white rounded-[16px] lg:rounded-3xl'}>
      {title && (
        <div className={'flex justify-between items-center mb-6'}>
          <div className={'text-24-32 font-editorial-new text-neutral-700'}>{title}</div>
          <div>{extraTitle}</div>
        </div>
      )}
      <div>{children}</div>
    </div>
  )
}

export default BasicCard
