import ApplyPercentageFeeOn from './ApplyPercentageFeeOn'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import CurrencyFormat, { Values } from 'react-currency-format'
import Error from 'src/components/common/Error'
import ApplyFlatFeeOn from 'src/components/reservation-manual/ApplyFlatFeeOn'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialog from 'src/components/ui/BasicDialog'
import BasicSelect from 'src/components/ui/BasicSelect'
import { DISCOUNT_TYPE } from 'src/contants/common'
import { FormError } from 'src/interfaces'
import { CustomFeeAndDiscount, PriceType } from 'src/interfaces/reservations'

interface Props {
  editIndex?: number
  editData?: CustomFeeAndDiscount | null
  addType: PriceType
  currency: string
  isOpen: boolean
  onClose: () => void
  onConfirm?: (data: { isPercent: boolean; name: string; value: number; type: PriceType }, index?: number) => void
  customFee: CustomFeeAndDiscount[]
  customDiscount: CustomFeeAndDiscount[]
  propertyTax: any[]
}

const AddTaxFeeOrDiscount: React.FC<Props> = ({
  addType,
  isOpen,
  onClose,
  onConfirm,
  currency,
  editIndex,
  editData,
  customFee,
  customDiscount,
  propertyTax,
}) => {
  const [isCheckedFlatFeeApply, setIsCheckedFlatFeeApply] = useState<boolean>(false)
  const [flatFeeApplyOnTaxes, setFlatFeeApplyOnTaxes] = useState<string[]>([])
  const [percentageFeeApplyOn, setPercentageFeeApplyOn] = useState<string>('BASE_PRICE')
  const [isPercent, setIsPercent] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [amount, setAmount] = useState<string>('')
  const [error, setError] = useState<{ name: FormError; amount: FormError; flatFee: FormError }>({
    name: { show: false, message: '' },
    amount: { show: false, message: '' },
    flatFee: { show: false, message: '' },
  })

  useEffect(() => {
    if (editData) {
      setIsPercent(editData.isPercent || false)
      setName(editData.name)
      setAmount(String(Math.abs(Number(editData.value))))
      const configFee = editData.configFee
      if (configFee) {
        setIsCheckedFlatFeeApply(configFee.isCheckedFlatFeeApply)
        setFlatFeeApplyOnTaxes(configFee.flatFeeApplyOnTaxes)
        setPercentageFeeApplyOn(configFee.percentageFeeApplyOn)
      }
    } else {
      clearData()
    }
  }, [editData, isOpen])

  const clearData = () => {
    setIsPercent(false)
    setName('')
    setAmount('')
    setIsCheckedFlatFeeApply(false)
    setFlatFeeApplyOnTaxes([])
    setPercentageFeeApplyOn('BASE_PRICE')
  }

  const onChangeType = (value: string) => {
    setIsPercent(value === DISCOUNT_TYPE.PERCENT)
  }

  const onChangeName = (value: string) => {
    setName(value)
    setError((prevState) => ({ ...prevState, name: { show: false, message: '' } }))
  }

  const onChangeAmount = (values: Values) => {
    setAmount(values.value)
    setError((prevState) => ({ ...prevState, amount: { show: false, message: '' } }))
  }

  const handleClose = () => {
    onClose()
    // clear error
    setError({
      name: { show: false, message: '' },
      amount: { show: false, message: '' },
      flatFee: { show: false, message: '' },
    })
  }

  const handleConfirm = () => {
    const currentData = addType === 'fee' ? customFee : customDiscount
    const nameExisted = currentData.filter((_, index) => index !== editIndex).map((item) => item.name.trim())
    if (name && nameExisted.includes(name.trim())) {
      setError((prevState) => ({
        ...prevState,
        name: { show: true, message: `This ${addType === 'fee' ? 'tax/fee' : 'discount'}  name already exists.` },
      }))
      return
    }
    // Validate amount:
    // For Flat amount type, the value range 0 < x < unlimited (security/tech consult), 2 decimals supported
    // For Percentage type, the value range 0 < x <= 100%
    if (amount && Number(amount) <= 0) {
      setError((prevState) => ({ ...prevState, amount: { show: true, message: 'Amount must be greater than 0' } }))
      return
    }
    const amountSplit = String(amount).split('.')
    if (amountSplit[1] && amountSplit[1].length > 2) {
      setError((prevState) => ({ ...prevState, amount: { show: true, message: 'Only 2 decimal digits are allowed.' } }))
      return
    }
    if (isPercent && Number(amount) > 100) {
      setError((prevState) => ({ ...prevState, amount: { show: true, message: 'Amount must be between 1 - 100%.' } }))
      return
    }
    if (addType === 'fee' && isCheckedFlatFeeApply && flatFeeApplyOnTaxes.length === 0) {
      setError((prevState) => ({ ...prevState, flatFee: { show: true, message: 'Please select at least one tax.' } }))
      return
    }

    if (typeof onConfirm === 'function') {
      const data: any = {
        isPercent,
        type: addType,
        name: name.trim(),
        value: Number(amount) || 0,
      }
      if (addType === 'fee') {
        data.configFee = {
          isCheckedFlatFeeApply,
          flatFeeApplyOnTaxes,
          percentageFeeApplyOn,
        }
      }
      onConfirm(data, editIndex)
    }
    handleClose()
  }

  const onCheckedFlatFeeApply = (checked: boolean) => {
    if (!checked) {
      setFlatFeeApplyOnTaxes([]) // clear taxes checked
    }
    setIsCheckedFlatFeeApply(checked)
    setError((prevState) => ({ ...prevState, flatFee: { show: false, message: '' } }))
  }

  const onChangePercentageFeeApply = (type: string) => {
    setPercentageFeeApplyOn(type)
  }

  const onCheckedTax = (cheked: boolean, taxName: string) => {
    setFlatFeeApplyOnTaxes((prevState) => {
      if (cheked) {
        return [...prevState, taxName]
      } else {
        return prevState.filter((item) => item !== taxName)
      }
    })
    setError((prevState) => ({ ...prevState, flatFee: { show: false, message: '' } }))
  }

  const renderTitle = () => (
    <p className={'font-maison-neue text-24-28 text-grayscale-800 text-center'}>
      {addType === 'fee' ? 'Add Custom Fee' : 'Add Discount'}{' '}
    </p>
  )

  return (
    <BasicDialog isOpen={isOpen} onClose={handleClose} title={renderTitle()}>
      <div className={'flex flex-col gap-[24px]'}>
        <div>
          <BasicSelect
            label={'Type'}
            options={[
              { value: DISCOUNT_TYPE.FLAT, label: 'Flat amount' },
              { value: DISCOUNT_TYPE.PERCENT, label: 'Percentage' },
            ]}
            value={isPercent ? DISCOUNT_TYPE.PERCENT : DISCOUNT_TYPE.FLAT}
            onChange={(event) => onChangeType(event.target.value)}
          />
        </div>

        <div>
          <label
            htmlFor={'fee_name'}
            className={'inline-block mb-[8px] font-maison-neue-medium text-14-18 text-neutral-900'}
          >
            Name
          </label>
          <input
            id={'fee_name'}
            placeholder={addType === 'fee' ? 'Additional cleaning fee' : 'Weekly discount'}
            className={clsx(
              'w-full h-[48px] text-16-20 px-[16px] rounded-[12px] text-neutral-900',
              'placeholder:text-neutral-500',
              'border border-black-0.05 hover:border-neutral-900',
              'focus:border-neutral-900 focus:outline-none'
            )}
            maxLength={30}
            value={name}
            onChange={(event) => onChangeName(event.target.value)}
          />
          {error && <Error {...error.name} classes={'text-14-20'} />}
        </div>

        <div className={'relative'}>
          <label
            htmlFor={'fee_amount'}
            className={'inline-block mb-[8px] font-maison-neue-medium text-14-18 text-neutral-900'}
          >
            Amount
          </label>
          <CurrencyFormat
            id={'fee_amount'}
            className={clsx(
              { 'pl-[32px]': !isPercent && currency === 'USD' },
              { 'pl-[60px]': !isPercent && currency !== 'USD' },
              'w-full h-[48px] text-16-20 px-[16px] rounded-[12px] text-neutral-900',
              'placeholder:text-neutral-500',
              'focus:border-neutral-900 focus:outline-none',
              'inputNumberHideArrow',
              {
                ['text-red-6 border border-red-6 hover:border-red-6 focus:border-red-6']: error.amount.show,
                ['border border-black-0.05 hover:border-neutral-900']: !error.amount.show,
              }
            )}
            placeholder={'Add your amount here'}
            thousandSeparator={true}
            inputMode="numeric"
            min={0}
            max={DISCOUNT_TYPE.PERCENT ? 100 : undefined}
            value={amount}
            onValueChange={(values) => onChangeAmount(values)}
          />
          {!isPercent && (
            <>
              <span className={'absolute left-[16px] top-[40px] my-auto font-maison-neue text-16-20 text-neutral-900'}>
                {currency === 'USD' ? '$' : currency}
              </span>
            </>
          )}
          {isPercent && (
            <span className={'absolute right-[16px] top-[40px] font-maison-neue text-16-20 text-neutral-900'}>%</span>
          )}

          {error && <Error {...error.amount} classes={'text-14-20'} />}
        </div>

        {addType === 'fee' && (
          <div>
            {isPercent ? (
              <ApplyPercentageFeeOn value={percentageFeeApplyOn} onChange={onChangePercentageFeeApply} />
            ) : (
              <ApplyFlatFeeOn
                propertyTax={propertyTax}
                taxesChecked={flatFeeApplyOnTaxes}
                onCheckedTax={onCheckedTax}
                isCheckedFlatFeeApply={isCheckedFlatFeeApply}
                onCheckedFlatFeeApply={onCheckedFlatFeeApply}
                error={error.flatFee}
              />
            )}
          </div>
        )}

        <div>
          <BasicButton
            variant={'contained'}
            color={'red'}
            isRadius100={true}
            clases={'w-full'}
            disabled={!name || !amount}
            onClick={handleConfirm}
          >
            Confirm
          </BasicButton>
        </div>
      </div>
    </BasicDialog>
  )
}

export default AddTaxFeeOrDiscount
