import { IconEmptyActivity } from '../common/Icons'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getActivities } from 'src/api/activity'
import ic_account from 'src/assets/icons/ic_account.svg'
import ic_activity_failed from 'src/assets/icons/ic_activity_failed.svg'
import ic_activity_success from 'src/assets/icons/ic_activity_success.svg'
import ic_activity_warning from 'src/assets/icons/ic_activity_warning.svg'
import ic_check_filled from 'src/assets/icons/ic_check_filled.svg'
import BasicCardV2 from 'src/components/common/BasicCardV2'
import BasicButton from 'src/components/ui/BasicButton'
import { ACTIVITIES } from 'src/contants/common'
import { useAppSelector } from 'src/redux/hooks'
import { isEmpty } from 'src/utils/common'

interface Props {
  propertyId?: string
}

const DetailActivities: React.FC<Props> = ({ propertyId }) => {
  const { profile } = useAppSelector((state) => state.user)
  const [activities, setActivities] = useState<any[]>([])

  useEffect(() => {
    async function fetchActivities() {
      try {
        const res = await getActivities({ propertyId, page: 1, pageSize: 5 })
        if (res.success) {
          setActivities(res.data)
        }
      } catch (err) {
        console.log(err)
      }
    }

    fetchActivities()
  }, [propertyId, profile])

  const renderIcons = (type: string) => {
    switch (type) {
      case ACTIVITIES.ACCOUNT_CREATED:
        return <img src={ic_account} alt={'ic_activities'} />
      case ACTIVITIES.NEW_RESERVATION:
        return <img src={ic_activity_success} alt={'ic_activities'} />
      case ACTIVITIES.CANCEL_RESERVATION_REQUESTED:
        return <img src={ic_activity_warning} alt={'ic_activities'} />
      case ACTIVITIES.CANCEL_RESERVATION_CONFIRMED:
        return <img src={ic_activity_failed} alt={'ic_activities'} />
      default:
        return <img src={ic_check_filled} alt={'ic_activities'} />
    }
  }

  return (
    <BasicCardV2 title={'Activity'}>
      {isEmpty(activities) && (
        <div className={'w-full h-[228px] lg:h-[402px] flex items-center justify-center flex-col gap-[8px]'}>
          <IconEmptyActivity />
          <p className={'font-maison-neue text-16-20 text-neutral-400'}>No Activity yet</p>
        </div>
      )}
      {!isEmpty(activities) &&
        activities.map((item, index) => {
          const isLastItem = index === activities.length - 1
          return (
            <div
              key={item.id}
              className={`flex items-center ${!isLastItem ? 'border-b border-neutral-200 pb-[20px] mb-[20px]' : ''}`}
            >
              <span className={'mr-3'}>{renderIcons(item.type)}</span>
              <div>
                <p className={'text-neutral-700 text-16-20'}>{item.title}</p>
                <p className={'text-neutral-600 text-12-16'}>{moment(item.createdAt).format('MMM D, YYYY • h:mm A')}</p>
              </div>
              <div className={'ml-auto'}>
                {[ACTIVITIES.ACCOUNT_CREATED, ACTIVITIES.WALLET_CONNECTED, ACTIVITIES.EMAIL_VERIFIED].includes(
                  item.type
                ) ? (
                  ''
                ) : (
                  <BasicButton size={'sm'} href={'/reservations/' + item.reservationId} isRadius100={true}>
                    <span className={'mr-2 font-maison-neue-medium text-14-20 text-neutral-800'}>Details</span>
                    <ArrowForwardIcon
                      fontSize={'small'}
                      sx={{ color: '#32302D' }}
                      style={{ width: 16, height: 16, fontSize: 16 }}
                    />
                  </BasicButton>
                )}
              </div>
            </div>
          )
        })}
    </BasicCardV2>
  )
}

export default DetailActivities
