import { getUserProfile } from '../../../api/user'
import { handleErrorMessage, isEmpty } from '../../../utils/common'
import moment, { Moment } from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { getCalendar } from 'src/api/listings'
import { updateAvailability, updateBulkProperties } from 'src/api/native-listing'
import BasicCalendar from 'src/components/common/BasicCalendar'
import BasicButton from 'src/components/ui/BasicButton'
import BasicDialogCalendar from 'src/components/ui/BasicDialogForCalendar'
import { DATE_FORMAT, MONTH_FORMAT } from 'src/contants/common'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { useAppDispatch } from 'src/redux/hooks'
import { setToastSuccess } from 'src/redux/slices/common'
import { getAllDatesInRange } from 'src/utils/property'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import EditBulkConfirmDialog from '../EditBulkConfirmDialog'

interface Props {
  propertyId: string
  isOpen: boolean
  onClose: () => void
  dateRanges: any[]
  updateDateRanges: (newDateRanges: any[]) => void
  isResetCalendar?: boolean
  fetchStatusStep?: any
  handleCloseEdit?: any
}

const AddBlockDates: React.FC<Props> = ({
  propertyId,
  isOpen,
  onClose,
  dateRanges,
  updateDateRanges,
  isResetCalendar,
  fetchStatusStep,
  handleCloseEdit
}) => {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const parseQuery = queryString.parse(search)
  const isEditBulk = !isEmpty(parseQuery?.ids)
  const windowDimensions = useWindowDimensions()
  const isMobile = windowDimensions.width < 768
  const [startDate, setStartDate] = useState<Moment | null>(null)
  const [endDate, setEndDate] = useState<Moment | null>(null)
  const [monthsFetched, setMonthFetched] = useState<string[]>([])
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [isLoadingCalendar, setIsLoadingCalendar] = useState<boolean>(false)
  const [dataCalendar, setDataCalendar] = useState<any>({})
  const [hostAddress, setHostAddress] = useState<string>('')
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)

  async function fetchCalendar(hostAddress: string, listingId: string, fromDate: string, toDate: string) {
    if (isEditBulk) return;
    try {
      setIsLoadingCalendar(true)
      const res = await getCalendar(hostAddress, listingId, fromDate, toDate)
      if (Array.isArray(res.data)) {
        let _dataCalendar: any = {}
        for (let item of res.data) {
          _dataCalendar[item.date] = item
        }
        setDataCalendar((prevState: any) => ({ ...prevState, ..._dataCalendar }))
      }
    } finally {
      setIsLoadingCalendar(false)
    }
  }

  async function fetchCurrentAndNextMonthCalendar(hostAddress: string, listingId: string) {
    const currentDay: Moment = moment()
    const endDayOfNextMonth: Moment = moment().add(1, 'months').endOf('months')
    await fetchCalendar(hostAddress, listingId, currentDay.format(DATE_FORMAT), endDayOfNextMonth.format(DATE_FORMAT))
    setMonthFetched((prevState) => [
      ...prevState,
      currentDay.format(MONTH_FORMAT),
      endDayOfNextMonth.format(MONTH_FORMAT),
    ])
  }

  useEffect(() => {
    if (propertyId && hostAddress) {
      // first time fetch calendar
      fetchCurrentAndNextMonthCalendar(hostAddress, propertyId)
    }
  }, [propertyId, hostAddress])

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const res: any = await getUserProfile()
        setHostAddress(res?.data?.user?.id || '')
      } catch (err) {
        console.log(err)
      }
    }
    fetchUserProfile()
  }, [])

  useEffect(() => {
    if (propertyId && hostAddress && isOpen && isResetCalendar) {
      // for case: delete date range => need call api calendar again
      // when delete a date range => set isResetCalendar = true
      setDataCalendar({})
      setMonthFetched([])
      fetchCurrentAndNextMonthCalendar(hostAddress, propertyId)
    }
  }, [isOpen, isResetCalendar, propertyId, hostAddress])

  const handleNextAndPrevCalendar = async (type: 'next' | 'prev', newMonth: Moment) => {
    const monthFormated: string = moment(newMonth).format(MONTH_FORMAT)
    if (!monthsFetched.includes(monthFormated)) {
      const from: string = moment(newMonth).startOf('months').format(DATE_FORMAT)
      const to: string = moment(newMonth).endOf('months').format(DATE_FORMAT)
      await fetchCalendar(hostAddress, propertyId, from, to)
      setMonthFetched((prevState) => [...prevState, monthFormated])
    }
    const nextOrPrevMonth = moment(newMonth).add(type === 'next' ? 1 : -1, 'months')
    const nextOrPrevMonthFormated = nextOrPrevMonth.format(MONTH_FORMAT)
    if (!monthsFetched.includes(nextOrPrevMonthFormated)) {
      const from: string = moment(nextOrPrevMonth).startOf('months').format(DATE_FORMAT)
      const to: string = moment(nextOrPrevMonth).endOf('months').format(DATE_FORMAT)
      await fetchCalendar(hostAddress, propertyId, from, to)
      setMonthFetched((prevState) => [...prevState, nextOrPrevMonthFormated])
    }
  }

  const onDatesChange = (_startDate: Moment | null, _endDate: Moment | null) => {
    setStartDate(_startDate)
    setEndDate(_endDate)
  }

  // update blocked date on UI after submit (without recall api)
  const updateBlockedDates = (fromDate: string, toDate: string) => {
    const datesInRange = getAllDatesInRange(fromDate, toDate)
    setDataCalendar((prevState: any) => {
      let clone = { ...prevState }
      datesInRange.forEach((el: string) => {
        if (clone[el]) {
          clone[el].isAvailable = false
          if (clone[el].status === 'available') {
            clone[el].status = 'blocked'
          }
        } else {
          clone = { ...clone, [el]: { isAvailable: false, status: 'blocked' } }
        }
      })
      // for (let i = 0; i < datesInRange.length; i++) {
      //   clone[datesInRange[i]].isAvailable = false
      //   if (clone[datesInRange[i]].status === 'available') {
      //     clone[datesInRange[i]].status = 'blocked'
      //   }
      // }
      return clone
    })
  }

  const onClosePopup = () => {
    onClose()
    onClearDates() // clear start date, end date
  }

  const onClearDates = () => {
    setStartDate(null)
    setEndDate(null)
  }

  const onSubmit = async () => {
    if (!startDate || !endDate) {
      return
    }
    const startDateFormated = startDate.format(DATE_FORMAT)
    const endDateFormated = endDate.format(DATE_FORMAT)
    try {
      setIsLoadingSubmit(true)
      const newItem = {
        startDate: startDateFormated,
        endDate: endDateFormated,
        isAvailable: false,
      }
      if (isEditBulk) {
        const newDateRanges = [{ ...newItem }]
        const propertyIds = ((parseQuery?.ids as string)?.split(',') || []).map((v: string) => Number(v))
        let dataDTO: any = {
          propertyIds,
          availabilitySettings: {
            dateRanges: newDateRanges,
            insert: { ...newItem },
          },
        }
        await updateBulkProperties(dataDTO)
        updateDateRanges(newDateRanges)
        if (handleCloseEdit) handleCloseEdit()
      } else {
        const newDateRanges = [...dateRanges, { ...newItem }]
        await updateAvailability({
          listingId: Number(propertyId),
          dateRanges: newDateRanges,
          insert: { ...newItem },
        })
        updateDateRanges(newDateRanges)
      }
      updateBlockedDates(startDateFormated, endDateFormated) // update block dates on UI realtime (for calendar)
      dispatch(setToastSuccess({ message: 'Update calendar successfully' }))
      // dispatch(setTriggerResetCalendar(+new Date()))
      onClosePopup()
      if (fetchStatusStep) fetchStatusStep()
    } catch (err: any) {
      handleErrorMessage(err)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  return (
    <>
      <BasicDialogCalendar
        isOpen={isOpen}
        onClose={onClosePopup}
        title={'Block unavailable dates'}
        extraTitle={'Select dates to manually block'}
      >
        <div className={'h-[382px] p-[24px] bg-neutral-100 shadow-sm flex items-start justify-center'}>
          <BasicCalendar
            isShowDoubleMonth={!isMobile}
            startDate={startDate}
            endDate={endDate}
            onDatesChange={onDatesChange}
            dataCalendar={dataCalendar}
            onNextAndPrev={handleNextAndPrevCalendar}
            isLoading={isLoadingCalendar}
          />
        </div>

        {/*  Footer*/}
        <div
          className={'flex flex-col md:flex-row items-center gap-[16px] justify-between p-[24px] md:h-[92px] bg-white'}
        >
          <div className={'w-full md:w-auto'}>
            <span className={'font-maison-neue text-16-20 text-neutral-500'}>Selected dates:</span>&nbsp;
            <span className={'font-maison-neue text-16-20 text-neutral-800'}>
              {startDate ? moment(startDate).format('D MMMM YYYY') : ''} {startDate && endDate ? '-' : ''}{' '}
              {endDate ? moment(endDate).format('D MMMM YYYY') : ''}
            </span>
          </div>
          <div className={'flex items-center justify-between gap-[16px] w-full md:w-auto'}>
            <BasicButton variant={'outlined'} isRadius100={true} onClick={onClearDates} disabled={!startDate && !endDate}>
              Clear dates
            </BasicButton>
            <BasicButton
              variant={'contained'}
              isRadius100={true}
              color={'red'}
              onClick={() => {
                if (isEditBulk) {
                  setOpenConfirm(true)
                  onClose()
                }
                else onSubmit()
              }}
              loading={isLoadingSubmit}
              disabled={!startDate || !endDate}
            >
              Block dates
            </BasicButton>
          </div>
        </div>
      </BasicDialogCalendar>
      <EditBulkConfirmDialog
        openConfirm={openConfirm}
        handleClose={() => {
          setOpenConfirm(false)
          onClosePopup()
        }}
        handleSubmit={onSubmit}
      />
    </>
  )
}

export default AddBlockDates
