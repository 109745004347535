import { ADDON_PRICE_STRUCTURE } from 'src/contants/common'

export interface GuestInfo {
  name: string
  email: string
  phone: string
  lastName: string
  firstName: string
}

export interface Address {
  lat: string
  lng: string
  city: string
  state: string
  street: string
  address: string
  country: string
  zipcode?: any
  countryCode: string
  publicAddress: string
}

export interface Contact {
  language?: any
  contactName?: any
  contactEmail?: any
  contactPhone1?: any
  contactPhone2?: any
  contactAddress?: any
  contactSurName?: any
  contactLanguage?: any
}

export interface ListingInfo {
  beds: number
  status: string
  address: Address
  pmsType: string
  bedrooms: number
  doorCode?: any
  roomType: string
  walletId: string
  bathrooms: number
  checkinType?: any
  bathRoomType: string
  thumbnailUrl: any
  pmsPropertyId: string
  guestsIncluded: number
  personCapacity: number
  propertyImages: any
  name?: string
  externalName?: string
  internalName?: string
  defaultCheckInTime: string
  defaultCheckOutTime: string | null
}

export interface PmsReservationInfo {
  id: number
  cvc?: any
  pets?: any
  phone: string
  adults: number
  ccName?: any
  isPaid?: any
  nights: number
  source: string
  status: string
  comment?: any
  infants?: any
  ccNumber?: any
  children?: any
  currency: string
  doorCode?: any
  hostNote?: any
  isPinned: number
  channelId: number
  guestCity?: any
  guestName: string
  guestNote?: any
  guestWork?: any
  isInitial: number
  isStarred: number
  taxAmount?: any
  updatedOn: string
  guestEmail: string
  guestTrips: number
  insertedOn: string
  isArchived: number
  totalPrice: number
  arrivalDate: string
  cancelledBy?: any
  channelName: string
  checkInTime: number
  cleaningFee?: any
  guestLocale?: any
  isProcessed: number
  listingName: string
  checkOutTime?: any
  guestAddress?: any
  guestCountry?: any
  guestPicture?: any
  guestZipCode?: any
  listingMapId: number
  departureDate: string
  guestAuthHash: string
  guestLastName: string
  reservationId: string
  stripeGuestId?: any
  stripeMessage?: any
  assigneeUserId?: any
  customerIcalId?: any
  customerUserId?: any
  doorCodeVendor?: any
  externalUnitId?: any
  guestFirstName: string
  guestPortalUrl: string
  numberOfGuests: number
  isInstantBooked: number
  reservationDate: string
  reservationFees: any[]
  reservationUnit: any[]
  braintreeGuestId?: any
  braintreeMessage?: any
  cancellationDate?: any
  ccExpirationYear?: any
  confirmationCode?: any
  customerIcalName?: any
  latestActivityOn: string
  ccExpirationMonth?: any
  customFieldValues: any[]
  isManuallyChecked: number
  pendingExpireDate?: any
  externalPropertyId?: any
  isDatesUnspecified: number
  localeForMessaging?: any
  securityDepositFee: number
  doorCodeInstruction?: any
  previousArrivalDate?: any
  reservationCouponId?: any
  airbnbListingHostFee?: any
  ccNumberEndingDigits?: any
  channelReservationId: string
  guestPortalRevampUrl?: any
  guestRecommendations: number
  airbnbTotalPaidAmount?: any
  hostawayReservationId: string
  previousDepartureDate?: any
  airbnbListingBasePrice?: any
  guestExternalAccountId?: any
  isGuestVerifiedByEmail: number
  isGuestVerifiedByPhone: number
  channelCommissionAmount?: any
  isGuestIdentityVerified: number
  airbnbCancellationPolicy?: any
  airbnbListingCleaningFee?: any
  hostawayCommissionAmount?: any
  isGuestVerifiedByReviews: number
  localeForMessagingSource?: any
  isGuestVerifiedByFacebook: number
  airbnbExpectedPayoutAmount?: any
  airbnbListingSecurityPrice?: any
  isGuestVerifiedByWorkEmail: number
  isGuestVerifiedByGovernmentId: number
  airbnbListingCancellationPayout?: any
  airbnbListingCancellationHostFee?: any
  airbnbOccupancyTaxAmountPaidToHost?: any
  airbnbTransientOccupancyTaxPaidAmount?: any
}

export interface ReservationInfo {
  id: number
  reservationId: string
  source: string
  pmsType: string
  status: string
  payoutStatus: string
  checkinDate: Date
  checkoutDate: Date
  checkinTime?: any
  checkoutTime?: any
  isInstantBook: boolean
  notes?: any
  keyCode?: any
  checkinInstruction?: any
  nights: number
  guestCount: number
  adults: number
  children: number
  infants: number
  pets: number
  guestId: string
  guestWallet: string
  guestInfo: GuestInfo
  accountId: string
  hostId: string
  hostWallet: string
  hostInfo?: any
  listingId?: any
  pmsListingId: string
  listingInfo: ListingInfo
  finalPrice: string
  priceInfo?: any
  priceInfoV2?: any
  currency: string
  paymentMethod: string
  paymentStatus?: any
  pmsReservationId: string
  pmsReservationInfo: PmsReservationInfo
  cancellationPolicy?: any
  cancellationReason?: any
  cancelledBy?: any
  confirmedBy?: any
  canceledAt?: any
  confirmedAt?: any
  createdAt: Date
  updatedAt?: any
  timezone: string
  transactions: any
  customPriceComponents?: Array<any>
  priceComponents?: Array<any>
}

export interface ParamsReservationList {
  page?: number
  pageSize?: number
}

export enum ReservationStatusEnum {
  AWAITING_PAYMENT = 'awaiting_payment',
  NEW = 'new', // mean reservation is successful created, payment is paid
  MODIFIED = 'modified', // After a NEW reservation is create, if you change some info, status must update to modified
  CANCELLED = 'cancelled', //Cancelled by host/guest
  COMPLETED = 'completed', // == CLOSED, when current date is pass the checkout
  FAILED = 'failed',
  EXPIRED = 'expired',
  MANUAL_CANCELLED = 'manual_cancelled',
  INQUIRY = 'inquiry',
}

export interface Price {
  name: string
  title: string
  isIncludedInTotalPrice: boolean
  isOverriddenByUser: boolean
  value: number
}

export type PriceType = 'price' | 'tax' | 'fee' | 'discount'

export interface Fee {
  name: string
  title: string
  isIncludedInTotalPrice: boolean
  isOverriddenByUser: boolean
  value: number
  convertedValue: any
  isMandatory: boolean
  isQuantitySelectable?: boolean
  quantity?: number
  id?: number
}

export interface PropertySettingFee {
  amount: number
  amountType: string
  enabled: boolean
  feeTitle: string
  feeType: string
  guests_included: number
  id: string
  isIncludedInTotalPrice: boolean
  label: string
  name: string
}

export interface CustomFeeAndDiscount {
  name: string
  title: string
  feeTitle?: string
  isIncludedInTotalPrice: boolean
  value: number
  convertedValue?: any
  isMandatory?: boolean
  isQuantitySelectable?: boolean
  quantity?: number
  id?: number
  type: PriceType
  isCustom?: boolean
  isPercent?: boolean
  configAmount: number
  listingFeeSettingId?: number | null
  isOverriddenByUser?: boolean
  configFee?: any
}

export interface Discount {
  name: string
  title: string
  isIncludedInTotalPrice: boolean
  isOverriddenByUser: boolean
  value: number
  isMandatory: boolean
  convertedValue: any
}

export interface PriceDetail {
  price: Price[]
  fee?: Fee[]
  discount?: Discount[]
  tax?: any[]
  taxAndFee?: any
  totalDiscount?: any
}

export interface ReservationAvailabilityData {
  checkinDate: string
  checkoutDate: string
  listingId: number
  guestCount: number
  adults: number
  children: number
  infants: number
  pets: number
  currency: string
  components?: any[]
  // components?: {
  //   listingFeeSettingId: number
  //   name: string //'petFee'
  //   isOverriddenByUser: number // 1 | 0
  //   value: number //500
  //   type: string
  // }[]
}

export interface PriceReservation {
  isAvail: boolean
  price: number
  totalPrice: number
  priceDetail: PriceDetail
  pricePerNight: number
  basePricePerNight: number
  convertedBasePricePerNight: any
  convertedPricePerNight: any
  convertedTotalPrice: any
  platformFee: any
  convertedPrice: any
  finalPrice: any
  calendar: Array<any>
  nights: number
}

export type AddOnApplyPer = typeof ADDON_PRICE_STRUCTURE.PER_DAY | typeof ADDON_PRICE_STRUCTURE.PER_RESERVATION

export interface AddOn {
  name: string
  price: number
  description: string
  images: {
    slug: string
  }[]
  applyPer: AddOnApplyPer
  isIncludedInTotalPrice?: boolean
}
