import React, { useState, useEffect } from 'react'
import LayoutOnboarding from '../LayoutOnboarding'
import { getHostSettings } from 'src/api/user'
import { setSettingData } from 'src/redux/slices/user'
import { useAppDispatch } from 'src/redux/hooks'
import OnboardingSiteDetailsContact from './OnboardingSiteDetailsContact'
import clsx from 'clsx'
import ic_close from 'src/assets/icons/ic_close.svg'
import ic_email from 'src/assets/icons/ic_email.svg'
import ic_phone from 'src/assets/icons/ic_phone.svg'
import ic_whatsapp from 'src/assets/icons/ic_whatsapp.svg'
import ic_telegram from 'src/assets/icons/ic_telegram.svg'

const OnboardingSiteDetailsContactContainer = () => {
  const dispatch = useAppDispatch()
  const [dataPreview, setDataPreview] = useState<any>({
    email: '',
    phone: '',
    whatsApp: '',
    telegram: ''
  })
  const fetchHostSettings = async () => {
    try {
      const res = await getHostSettings()
      if (res.success) {
        dispatch(setSettingData(res.data || {}))
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchHostSettings()
  }, [])

  const renderContactInfoItem = (icon: any, type: string) => {
    const value = dataPreview[type]
    // const actionText = type === 'email' || type === 'phone' ? 'Copy' : 'Message'
    return (
      <div
        className={clsx(
          'flex items-center justify-between py-3',
          type !== 'telegram' && 'border-b border-neutral-300'
        )}
      >
        <div className='flex items-center gap-4'>
          <img src={icon} width={24} height={24} alt="" />
          {value ?
            <p className='text-16-20 font-inter-500 text-neutral-800 text-ellipsis overflow-hidden whitespace-nowrap w-[292px]'>{value}</p> :
            <div className='w-[132px] h-[20px] bg-neutral-400 rounded-[120px]' />
          }
        </div>
        {/* <div className='h-[32px] border border-neutral-300 rounded-[8px] px-[14px] flex items-center justify-center'>
          <span className='text-14-18 font-inter-500 text-neutral-900'>{actionText}</span>
        </div> */}
      </div>
    )
  }
  const renderRightContent = () => {
    return (
      <div className="h-full flex pt-[300px] overflow-hidden">
        <div
          className={clsx(
            'w-[400px] h-[868px] pb-[228px] overflow-hidden',
            "border-[8px] border-black rounded-[40px]",
            "flex flex-col items-center bg-white"
          )}
        >
          <div
            className='w-full flex items-center justify-between p-6'
            style={{ boxShadow: "0px 1.01539px 0px 0px #E9E9E4" }}
          >
            <span className='text-20-32 font-inter-500 text-neutral-900'>Contact us</span>
            <img src={ic_close} width={20} height={20} alt="" />
          </div>
          <div className='p-6'>
            <p className='mb-2 text-neutral-600 text-16-20 font-maison-neue'>Let us know how we can help you book your next stay.</p>
            {renderContactInfoItem(ic_email, 'email')}
            {renderContactInfoItem(ic_phone, 'phone')}
            {renderContactInfoItem(ic_whatsapp, 'whatsApp')}
            {renderContactInfoItem(ic_telegram, 'telegram')}
          </div>
        </div>
      </div>
    )
  }

  return (
    <LayoutOnboarding rightContent={renderRightContent()} headerTitle={"Contact info"}>
      <div className="pb-[136px] lg:pb-[64px] pt-[38px] lg:pt-[64px] max-w-[524px] mx-auto overflow-auto">
        <OnboardingSiteDetailsContact
          handleUpdatePreview={(values: any) => setDataPreview({ ...dataPreview, ...values })}
          fetchHostSettings={fetchHostSettings}
        />
      </div>
    </LayoutOnboarding>
  )
}

export default OnboardingSiteDetailsContactContainer
